import { Heading, Text } from '@udacity/veritas-components';
import React, { useEffect } from 'react';
import {
  getFromLocalStorage,
  useLocalStorage,
} from 'app/hooks/use-local-storage';

import { Container } from '@emc/elements/container';

export const chunkFailedMessage = /chunk/i;
export const lastRefreshLocalStorageKey = 'lastForceRefresh';

export function shouldForceRefreshForChunk(errMessage?: string) {
  const lastForceRefresh = getFromLocalStorage<number>(
    lastRefreshLocalStorageKey,
    0
  );

  if (errMessage && chunkFailedMessage.test(errMessage)) {
    const now = Date.now();
    if (now - lastForceRefresh > 120000 /* more than 2 minutes ago */) {
      return true;
    }
  }

  return false;
}

export const ErrorOccurred: React.FC<{ error: Error }> = ({ error }) => {
  const [, setLastForceRefresh] = useLocalStorage<number>(
    lastRefreshLocalStorageKey,
    0
  );
  useEffect(() => {
    if (shouldForceRefreshForChunk(error?.message)) {
      setLastForceRefresh(Date.now());
      window.location.reload();
    }
  }, [error?.message, setLastForceRefresh]);

  return (
    <Container type="small">
      <Heading align="center" size="h3">
        An Error Occurred
      </Heading>
      <Text align="center" full>
        Please refresh the page to try again.
      </Text>
      <Text align="center" full size="sm" color="silver">
        {error?.message || ''}
      </Text>
    </Container>
  );
};
