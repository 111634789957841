import {
  Assessment,
  AssessmentCohort,
  AssessmentModelType,
  AssessmentResult,
  AssessmentWhitelistedProgram,
  CompanyGraphLearningPath,
  SelfReportedSubjects,
  V2AssessmentResult,
  V2AssessmentResultOverviewSubject,
  V2Evaluation,
} from 'app/types/generated/emc';
import {
  IAssessmentSubjects,
  IFormattedResults,
  ISkillRatio,
  ISkillResults,
} from 'app/types/assessments';
import {
  isEmpty,
  mergeDeepRight,
  pathOr,
  pick,
  prop,
  sortBy,
  uniqBy,
} from 'ramda';

import { FormikErrors } from 'formik';
import { IOption } from 'app/types/options';
import { Normalized } from 'app/types/shared';
import { Program } from 'app/types/generated/mind-probe';
import { ProgramInputRest } from 'app/actions/assessments/assessments';
import dayjs from 'dayjs';
import { getDurationFromMinutes } from '../time';
import { getMissingFields } from '../errors';
import { idMatches } from '../resources/uuid';

export function getTotalStats({
  totalQuestionsAnswered,
  totalQuestionsCorrect,
}: IFormattedResults) {
  const percentageCorrect = `${Math.round(
    (totalQuestionsCorrect / (totalQuestionsAnswered || 1)) * 100
  )}%`;

  return {
    totalQuestionsAnswered,
    percentCorrect: `${totalQuestionsCorrect}/${totalQuestionsAnswered} | ${percentageCorrect}`,
  };
}

export function getV2Props({
  result,
  subjects,
}: {
  result?: { evaluationObject?: V2Evaluation | null } | null;
  subjects: IAssessmentSubjects;
}): ISkillResults {
  if (!result) return {} as ISkillResults;
  const skillRatios = result.evaluationObject?.subjects || [];

  const formattedResults = skillRatios.reduce<IFormattedResults>(
    (totals, ratio) => {
      const total = ratio?.questionsAnswered || 0;
      const value = ratio?.questionsCorrect || 0;
      if (!total) {
        return totals;
      }

      const skillRatio = {
        title: (subjects && subjects[ratio?.subjectId || 0]?.name) || 'Subject',
        value,
        total,
        percentage: Math.round((value / total) * 100),
      };
      totals.skillData.push(skillRatio);
      totals.totalQuestionsAnswered += total;
      totals.totalQuestionsCorrect += value;

      return totals;
    },
    {
      totalQuestionsAnswered: 0,
      totalQuestionsCorrect: 0,
      skillData: [],
    }
  );

  return {
    ...getTotalStats(formattedResults),
    skillData: sortBy(prop('title'))(formattedResults.skillData),
  };
}

export function getSelfReportedSkillData(
  subjects?: SelfReportedSubjects[] | null
): ISkillRatio[] {
  return (subjects || []).map((subject) => ({
    title: subject.subjectName,
    percentage: subject.selfReportedMastery,
  }));
}

export function getTimeToComplete(
  result:
    | V2AssessmentResult
    | { result: V2AssessmentResult | AssessmentResult | null | undefined }
    | undefined
    | null,
  resultDatePath = ['createDate'],
  sessionDatePath = ['assessmentLabSession', 'createDate']
): string {
  const completeDate = pathOr('', resultDatePath)(result);
  const startDate = pathOr('', sessionDatePath)(result);
  const minutes = dayjs(completeDate).diff(dayjs(startDate), 'minute');

  return getDurationFromMinutes(minutes);
}

// assessment paths

export type AssessmentRouterParams = {
  companySlug: string;
  assessmentId: string;
  cohortKey: string;
  assessmentSessionId?: string;
};

export const getAssessmentPath = ({
  companySlug,
  assessmentId,
  cohortKey,
}: AssessmentRouterParams): string =>
  `/c/${companySlug}/a/${assessmentId}/cohorts/${cohortKey}`;

export const getAssessmentSubjectsPath = (
  routerParams: AssessmentRouterParams
): string => `${getAssessmentPath(routerParams)}/subjects`;

export const getAssessmentSelfReportPath = ({
  assessmentSessionId,
  ...routerParams
}: AssessmentRouterParams): string =>
  `${getAssessmentPath(routerParams)}/self-report`;

export const getAssessmentQuizPath = ({
  assessmentSessionId,
  ...routerParams
}: AssessmentRouterParams): string =>
  `${getAssessmentPath(routerParams)}/sessions/${assessmentSessionId}`;

export const getAssessmentLearnerResultsPath = (
  routerParams: AssessmentRouterParams
): string => `${getAssessmentQuizPath(routerParams)}/results`;

export type AssessmentFormData = Pick<
  Assessment,
  | 'companyGraphLearningPathId'
  | 'label'
  | 'showsProgramPreference'
  | 'showsProgramReadiness'
  | 'enableSubjectSelfReport'
  | 'showsScore'
  | 'assessmentModel'
> & {
  programs: IOption<string>[];
  allowsRetakes: boolean;
  maxAttempts: number;
  unlimitedRetakes: boolean;
};

export function getCompanyLearningPathProgramKeys(
  companyLearningPath: CompanyGraphLearningPath | undefined,
  mindProbePrograms: Normalized<Program>,
  includeIntroductory = true
): string[] | undefined {
  return (
    companyLearningPath &&
    (companyLearningPath.learningPath?.nodes || [])
      .map((node) => node?.label as string)
      .filter(
        (programKey) =>
          includeIntroductory ||
          programIsAdvanced(programKey, mindProbePrograms)
      )
  );
}

export type LpValidationErrors = {
  lpName: string;
  notWhitelisted: string[];
  notAvailable: string[];
};

// valid lps have all whitelisted, available programs
export function getLearningPathAssessmentErrors(
  learningPaths: CompanyGraphLearningPath[],
  mindProbePrograms: Normalized<Program & { name: string }>,
  whitelist: Normalized<AssessmentWhitelistedProgram>,
  availableResources: (string | number | boolean)[]
): Record<string, LpValidationErrors> {
  return learningPaths.reduce((invalidLps, lp) => {
    const programKeys = getCompanyLearningPathProgramKeys(
      lp,
      mindProbePrograms
    ) as string[];
    const notWhitelisted = programKeys.filter((key) => !whitelist[key]);
    const notAvailable = programKeys.filter(
      (key) => !availableResources.includes(key)
    );
    const hasErrors = !isEmpty(notWhitelisted) || !isEmpty(notAvailable);

    const errors = hasErrors
      ? {
          lpName: lp.learningPath?.name,
          notWhitelisted,
          notAvailable,
        }
      : null;

    return mergeDeepRight(invalidLps, { [lp.id]: errors });
  }, {});
}

export function programIsAdvanced(
  programKey: string,
  mindProbePrograms: Normalized<Program>
) {
  const program = mindProbePrograms[programKey];
  return program && program.isIntroductory === false;
}

export function validateNewAssessment(
  learningPaths: CompanyGraphLearningPath[],
  mindProbePrograms: Normalized<Program>,
  includeIntroductory?: boolean
): (values: AssessmentFormData) => FormikErrors<AssessmentFormData> {
  return (values: AssessmentFormData): FormikErrors<AssessmentFormData> => {
    const errors = getMissingFields(values, ['label']);
    const { companyGraphLearningPathId, programs, assessmentModel } = values;
    const isPostAssessment = assessmentModel === AssessmentModelType.Post;
    const learningPath = learningPaths.find(
      idMatches(companyGraphLearningPathId || '')
    );

    // check program skills and isIntroductory prop
    const programKeys =
      getCompanyLearningPathProgramKeys(
        learningPath,
        mindProbePrograms,
        includeIntroductory
      ) || programs.map((program) => program.value);

    const allAreIntroductory = programKeys.every(
      (programKey) => mindProbePrograms[programKey]?.isIntroductory === true
    );

    let errorMsg = '';

    const hasNoSkillsAttained = programKeys.every((programKey) =>
      isEmpty(mindProbePrograms[programKey]?.attainedSkillIds || [])
    );

    if (isPostAssessment && hasNoSkillsAttained) {
      errorMsg =
        'No Skills Attained are associated with the selected programs. This assessment would have no questions.';
    }

    if (allAreIntroductory && !includeIntroductory && !isPostAssessment) {
      errorMsg =
        'All selected programs are introductory. No assessment is needed.';
    }

    if (!companyGraphLearningPathId && isEmpty(programs)) {
      if (learningPaths.length) {
        errorMsg = 'Must choose either a learning path or programs to assess.';
      } else {
        errorMsg = 'Must choose programs to assess.';
      }
    }

    if (errorMsg) {
      if (learningPath) {
        errors.companyGraphLearningPathId = errorMsg;
      } else {
        errors.programs = errorMsg;
      }
    }
    return errors;
  };
}

type ProgramRest = ProgramInputRest & {
  title: string;
};

export function getProgramInputs(assessment?: Assessment): ProgramInputRest[] {
  if (!assessment?.programs) {
    return [];
  }
  const programs: ProgramRest[] = JSON.parse(assessment.programs);
  return programs.map((program) =>
    pick(['program_key', 'program_type'], program)
  );
}

export function getAssessmentCohortSubjects(
  cohort: AssessmentCohort | undefined
): V2AssessmentResultOverviewSubject[] {
  return (cohort?.v2AssessmentPrograms || []).reduce((allSubjects, program) => {
    const programSubjects =
      program.subjects as V2AssessmentResultOverviewSubject[];
    return uniqBy(prop('subjectId'), [...allSubjects, ...programSubjects]);
  }, [] as V2AssessmentResultOverviewSubject[]);
}
