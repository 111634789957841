import { CatalogAccessStatus } from 'app/types/generated/emc';
import { addCatalogAccessDetails } from 'app/actions/me';

export type CatalogAccessState = Partial<CatalogAccessStatus>;
export default function catalogAccess(
  state: CatalogAccessState = {},
  action: ReturnType<typeof addCatalogAccessDetails>
): CatalogAccessState {
  switch (action.type) {
    case 'ADD_CATALOG_ACCESS_DETAILS': {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
