import { ActionType } from 'app/types/actions';
import { Normalized } from 'app/types/shared';
import { mergeDeepRight } from 'ramda';

export interface ISimpleAction<AT extends ActionType> {
  type: AT;
}
export interface IAction<AT extends ActionType, PayloadType> {
  type: AT;
  payload: PayloadType;
}

export function createAction<AT extends ActionType, PayloadType>(
  type: AT,
  payload: PayloadType
): IAction<AT, PayloadType> {
  return { type, payload };
}

export function createSimpleAction<AT extends ActionType>(
  type: AT
): ISimpleAction<AT> {
  return { type };
}

export function makeStandardMergeReducer<
  T,
  A extends IAction<ActionType, Normalized<T>>
>(actionType: ActionType, initialState = {} as Normalized<T>) {
  return (state = initialState, action: A) => {
    switch (action.type) {
      case actionType: {
        return mergeDeepRight(state, action.payload) as Normalized<T>;
      }

      default: {
        return state;
      }
    }
  };
}
