import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';

import auth from '@udacity/ureact-hoth';
import axiosRetry from 'axios-retry';
import https from 'https';
import { isTesting } from 'app/test/utils';

axiosRetry(axios, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    return error?.response?.status === 503;
  },
});

const REQUEST_TIMEOUT_MS = 30000;

export const api = {
  request({
    includeAuth = true,
    ...axiosOptions
  }: AxiosRequestConfig & { includeAuth?: boolean }): AxiosPromise {
    const jwt = auth.getJWT();

    const axiosConfig = {
      timeout: REQUEST_TIMEOUT_MS,
      ...axiosOptions,
    };

    if (isTesting) {
      axiosConfig.adapter = require('axios/lib/adapters/http');
      axiosConfig.httpsAgent = new https.Agent({
        rejectUnauthorized: false,
      });
    }
    if (includeAuth && jwt) {
      axiosConfig.headers = {
        ...axiosConfig.headers,
        Authorization: `Bearer ${jwt}`,
      };
    }
    return axios(axiosConfig);
  },
};
