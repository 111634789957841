import { IconCourse, IconNanodegree, IconProps } from '@udacity/veritas-icons';
import React, { ReactNode } from 'react';
import { Resource, ResourceType } from 'app/types/generated/emc';
import { pathOr, pipe, replace } from 'ramda';

import { ClassroomCatalog } from 'app/types/programs';
import { classroomCatalogSelector } from 'app/selectors/catalog';
import { store } from '@emc/store';

export const stripNanodegreeFromTitle = replace(/\sNanodegree/, '');

export function getClassroomCatalog(): ClassroomCatalog {
  return classroomCatalogSelector(store.getState());
}

export function getProgramTitle(
  programKey: string,
  showKey = false,
  version?: string | null
): string {
  const classroomCatalog = getClassroomCatalog();
  return formatProgramTitleFromKey(
    classroomCatalog,
    showKey,
    !!version
  )(programKey, version);
}

export function getProgramTitleWithIcon(
  programKey: string,
  showKey = false
): ReactNode {
  const classroomCatalog = getClassroomCatalog();
  return formatProgramTitleWithIcon(classroomCatalog, showKey)(programKey);
}

export const formatProgramTitleFromKey =
  (
    classroomCatalog: ClassroomCatalog,
    showKey = true,
    includeVersion = false
  ) =>
  (
    key: string,
    version?: string | number | null,
    locale?: string | null
  ): string => {
    const title = pipe(
      pathOr('', [key, 'title']),
      replace(/\sand\s/g, ' & '),
      stripNanodegreeFromTitle
    )(classroomCatalog);

    let formattedTitle = key;

    if (title && showKey) {
      formattedTitle = `${key}: ${title}`;
    } else if (title) {
      formattedTitle = title;
    }

    return includeVersion && version
      ? `${formattedTitle} (v${version}${getLocaleString(locale)})`
      : formattedTitle;
  };

export function getResourceIcon(
  resourceType: ResourceType | undefined,
  color?: IconProps['color'] | undefined
) {
  if (!resourceType) return resourceType;
  return resourceType === ResourceType.Degree ? (
    <IconNanodegree
      size="sm"
      color={color || 'green'}
      title="Nanodegree"
      hidden
    />
  ) : (
    <IconCourse size="sm" color={color || 'blue'} title="Course" hidden />
  );
}

export const formatProgramTitleWithIcon = (
  classroomCatalog: ClassroomCatalog,
  showKey = true,
  color?: IconProps['color']
) => {
  const ProgramWithIcon = (key: string): JSX.Element => {
    const program = classroomCatalog[key];
    const titleStr = formatProgramTitleFromKey(classroomCatalog, showKey)(key);
    const icon = getResourceIcon(program?.semanticType, color);

    return (
      <>
        {icon} {titleStr}
      </>
    );
  };
  return ProgramWithIcon;
};

export function getProgramKeyFromResource(resource: { data: string }) {
  return resource?.data;
}

export const formatProgramTitleFromResource = (
  resource: Resource,
  classroomCatalog: ClassroomCatalog,
  showKey?: boolean,
  includeVersion?: boolean
) => {
  const key = getProgramKeyFromResource(resource);
  const version = resource?.programUrn?.programMajor || resource?.version || '';

  return formatProgramTitleFromKey(
    classroomCatalog,
    showKey,
    includeVersion
  )(key, version);
};

export const formatProgramTitleWithIconFromResource = (
  resource: Resource,
  classroomCatalog: ClassroomCatalog,
  showKey?: boolean,
  includeVersion?: boolean
) => {
  const key = getProgramKeyFromResource(resource);
  const version = resource?.programUrn?.programMajor || resource?.version || '';
  const locale = resource?.programUrn?.programLocale;
  const program = classroomCatalog[key];

  const icon = getResourceIcon(program?.semanticType);
  const titleStr = formatProgramTitleFromKey(
    classroomCatalog,
    showKey,
    includeVersion
  )(key, version, locale);

  return (
    <>
      {icon} {titleStr}
    </>
  );
};

export function getLocaleString(locale?: string | null) {
  return locale && locale !== 'en-us' ? ` ${locale}` : '';
}
