import { addMe } from 'app/actions/me';

export type EmcMeState = {
  learners: string[];
};

const initialState = {
  learners: [],
};

export default function emc(
  state: EmcMeState = initialState,
  action: ReturnType<typeof addMe>
): EmcMeState {
  switch (action.type) {
    case 'ADD_ME': {
      const ids = action.payload.learners?.map((learner) => learner?.id);
      return { learners: ids || [] };
    }

    default: {
      return state;
    }
  }
}
