import {
  removeAutoEnrollmentProgress,
  updateAutoEnrollmentStatus,
} from 'app/actions/enrollments/auto-enrollment';

import { omit } from 'ramda';

export type AutoEnrollmentStatus = {
  [learnerId: string]: {
    [programId: string]: boolean;
  };
};

const initialState = {};

export default function autoEnrollment(
  state: AutoEnrollmentStatus = initialState,
  action: ReturnType<
    typeof updateAutoEnrollmentStatus | typeof removeAutoEnrollmentProgress
  >
): AutoEnrollmentStatus {
  switch (action.type) {
    case 'UPDATE_AUTO_ENROLLMENT': {
      const learnerEnrollment = action.payload;
      const learnerId = Object.keys(learnerEnrollment)[0];
      return {
        ...state,
        [learnerId]: {
          ...state[learnerId],
          ...learnerEnrollment[learnerId],
        },
      };
    }

    case 'REMOVE_AUTO_ENROLLMENT_PROGRESS': {
      const { learnerId, programId } = action.payload;
      return {
        ...state,
        [learnerId]: omit([programId])(state[learnerId]),
      };
    }

    default: {
      return state;
    }
  }
}
