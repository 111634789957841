import { GqlError } from 'app/types/shared';
import auth from '@udacity/ureact-hoth';
import { datadogRum } from '@datadog/browser-rum';
import i18n from 'i18next';

export function getGraphQLErrorMessage(
  errors: GqlError[] | undefined,
  defaultErrMessage = 'Whoops! Something went wrong.'
) {
  if (!errors) return '';
  return errors[0]?.message || defaultErrMessage;
}

export function getGraphQLErrorCode(errors: GqlError[] | undefined) {
  return (errors || [])[0]?.extensions?.error_code;
}

export const initDatadog = () => {
  const isLocalDev = window.location.hostname === 'dev.udacity.com';
  if (isLocalDev) return;

  datadogRum.init({
    applicationId: '5008afb1-62b9-4e42-8e99-b36f18abd488',
    clientToken: 'pubf90a4253c206ee45045bdb5930dd62a4',
    site: 'datadoghq.com',
    service: 'emc-web',
    sessionSampleRate: 20,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    env: process.env.REACT_APP_ENV,
    version: '1.0.0',
    allowedTracingUrls: [
      {
        match: /emc.*\.udacity\.com\/api\//,
        propagatorTypes: ['tracecontext'],
      },
      { match: /api.*\.udacity\.com/, propagatorTypes: ['tracecontext'] },
    ],
  });

  datadogRum.setUser({ id: auth.getCurrentUserId() });

  datadogRum.startSessionReplayRecording();
};

export type FormErrors = { [key: string]: string };

export const getMissingFields = <K extends string>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Partial<Record<K, any>>,
  requiredFields: K[],
  errorMsg?: string
): FormErrors => {
  const errors: FormErrors = {};
  requiredFields.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = errorMsg || i18n.t('common.requiredFieldError');
    }
  });
  return errors;
};
