import { GroupsReduxState, LearnersReduxState } from 'app/reducer/data/types';

import { Learner } from 'app/types/generated/emc';
import { groupNormSchema } from './group';
import { schema } from 'normalizr';

export const managerNormSchema = new schema.Entity<Learner>('managers');

export type LearnerNormalizedEntities = {
  learners?: LearnersReduxState;
  groups?: GroupsReduxState;
};

export const learnerNormSchema = new schema.Entity<Learner>('learners', {
  groups: [groupNormSchema],
});
