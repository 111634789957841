import { AppThunk, IGqlResponse } from 'app/types/shared';
import {
  BatchProgressKeys,
  updateBatchProgress,
  updatePollingProperty,
} from '../polling';
import {
  CreateLearnersFromCsvUploadMutation,
  CreateLearnersMutation,
  NewLearnerInput,
} from 'app/types/generated/emc';
import { concat, pathOr } from 'ramda';
import { startLoading, stopLoading } from '../loading';

import { INewLearnerData } from 'app/types/learners';
import { addServerError } from '../flash';
import { batch } from 'react-redux';
import { companyPollingSelector } from 'app/selectors/companies/companies';
import { createAction } from 'app/utils/actions';
import { emcService } from '@emc/services';
import { fetchCompanyGroups } from '../groups';
import { fetchCompanyLearners } from './fetch';
import { fetchEMCLearnersForCurrentUser } from '../me';
import { fetchLearnerBatchProgress } from 'app/actions/enrollments/learner-lifecycles';
import { getGraphQLErrorMessage } from 'app/utils/errors';
import { getQueryString } from 'app/utils/graphql/serializer';
import { history } from '../../history';
import { isTesting } from 'app/test/utils';
import { loader } from 'graphql.macro';
import { newLearnersSessionSelector } from 'app/selectors/learners/new-learners';
import { pluralizeEnCount } from 'app/utils/format/plurals';
import { poll } from '../util';

export function newLearnersUpdateProperty(
  companyId: string,
  property: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
) {
  return createAction('NEW_LEARNERS_UPDATE_PROPERTY', {
    companyId,
    property,
    value,
  });
}

export function newLearnersAddSingleLearner(
  companyId: string,
  learner: NewLearnerInput
) {
  return createAction('NEW_LEARNERS_ADD_SINGLE_LEARNER', {
    companyId,
    learner,
  });
}

export function newLearnersAddMultipleLearners(
  companyId: string,
  learners: NewLearnerInput[]
) {
  return createAction('NEW_LEARNERS_ADD_MULTIPLE_LEARNERS', {
    companyId,
    learners,
  });
}

export function newLearnersRemoveLearner(companyId: string, idx: number) {
  return createAction('NEW_LEARNERS_REMOVE_LEARNER', { companyId, idx });
}

export function newLearnersUpdateLearnerProperty(
  companyId: string,
  idx: number,
  key: string,
  value: string
) {
  return createAction('NEW_LEARNERS_UPDATE_LEARNER_PROPERTY', {
    companyId,
    idx,
    key,
    value,
  });
}

export function resetNewLearnerUpload(companyId: string): AppThunk {
  return (dispatch) => {
    batch(() => {
      dispatch(newLearnersUpdateProperty(companyId, 'uploadedLearners', []));
      dispatch(newLearnersUpdateProperty(companyId, 'success', undefined));
      dispatch(newLearnersUpdateProperty(companyId, 'filenames', null));
      dispatch(newLearnersUpdateProperty(companyId, 'upload_ids', []));
      dispatch(newLearnersUpdateProperty(companyId, 'groupId', undefined));
      dispatch(newLearnersUpdateProperty(companyId, 'errors', undefined));
      dispatch(newLearnersUpdateProperty(companyId, 'error_lines', []));
      dispatch(newLearnersUpdateProperty(companyId, 'validation_errors', []));
    });
  };
}

export type NewLearnerUploadResponse = {
  filenames: string[];
  learners: INewLearnerData[];
  success: boolean;
  upload_id: string;
  errors: { email: number; first_name: number; last_name: number };
  error_lines: string[];
  validation_errors: { [lineNum: number]: string[] };
};
export function hydrateNewLearnerUpload(
  companyId: string,
  files: File[]
): AppThunk {
  return (dispatch, getState) => {
    dispatch(startLoading('LEARNERS'));

    return Promise.all(
      files.map((file) => {
        const data = new FormData();
        data.append('csv_file', file);
        return emcService.post(
          `/companies/${companyId}/learners/upload`,
          data,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
      })
    )
      .then((res: { data: NewLearnerUploadResponse }[]) => {
        const newLearners = newLearnersSessionSelector(getState());
        const success = newLearners.success as boolean;
        const errors = newLearners.errors || {
          email: 0,
          firstName: 0,
          lastName: 0,
        };
        const error_lines = newLearners.error_lines || [];
        const filenames = newLearners.filenames || [];
        const upload_ids = newLearners.upload_ids || [];
        const validation_errors = newLearners.validation_errors || [];

        const data = res.reduce(
          (combined, result, idx) => {
            const filename = files[idx].name;
            const learners = convertToNewLearnerInput(result.data.learners);
            const uploadId = result.data.upload_id;
            combined.filenames = concat(combined.filenames, [filename]);
            combined.learners = concat(combined.learners, learners);
            combined.upload_ids = concat(combined.upload_ids, [uploadId]);
            combined.error_lines = concat(
              combined.error_lines,
              result.data.error_lines.map((line) => `${filename} line ${line}`)
            );
            const validationErrors = result.data.validation_errors || [];
            const validationErrorLines = Object.keys(validationErrors);
            combined.validation_errors = concat(
              combined.validation_errors,
              validationErrorLines.map(
                (errorLine) =>
                  `${filename} line ${errorLine}: ${(
                    validationErrors[errorLine] || []
                  ).join(', ')}`
              )
            );
            combined.success =
              combined.success === undefined
                ? result.data.success
                : combined.success && result.data.success;
            const newErrors = result.data.errors;
            combined.errors.email += newErrors.email;
            combined.errors.firstName += newErrors.first_name;
            combined.errors.lastName += newErrors.last_name;
            return combined;
          },
          {
            success,
            filenames,
            learners: [] as NewLearnerInput[],
            upload_ids,
            errors,
            error_lines,
            validation_errors,
          }
        );

        batch(() => {
          dispatch(newLearnersAddMultipleLearners(companyId, data.learners));

          dispatch(
            newLearnersUpdateProperty(companyId, 'success', data.success)
          );
          dispatch(
            newLearnersUpdateProperty(companyId, 'filenames', data.filenames)
          );
          dispatch(
            newLearnersUpdateProperty(companyId, 'upload_ids', data.upload_ids)
          );
          dispatch(newLearnersUpdateProperty(companyId, 'errors', data.errors));
          dispatch(
            newLearnersUpdateProperty(
              companyId,
              'error_lines',
              data.error_lines
            )
          );
          dispatch(
            newLearnersUpdateProperty(
              companyId,
              'validation_errors',
              data.validation_errors
            )
          );
        });
      })
      .catch((err) => {
        const reason = pathOr('', ['response', 'data', 'error'])(err);
        return dispatch(
          addServerError({
            message: `Something went wrong! Please verify the csv file or download the csv template.${
              reason && ` (${reason})`
            }`,
          })
        );
      })
      .then(() => dispatch(stopLoading('LEARNERS')));
  };
}

const convertToNewLearnerInput = (
  learners: INewLearnerData[]
): NewLearnerInput[] => {
  return learners.map((learner) => ({
    email: learner.email,
    firstName: learner.first_name,
    lastName: learner.last_name,
    roleId: learner.role_id?.toString(),
    employeeId: learner.employee_id,
    groupId: learner.group_id,
    enrollmentCohortId: learner.enrollment_cohort_id,
  }));
};

type CreateAssessmentLearnerResult = {
  data: {
    company_id: string;
    delete_date: null;
    email: string;
    employee_id: null;
    id: string;
    user_key: string;
    success?: boolean;
    reason?: string;
  };
};
export function createAssessmentLearner(
  companySlug: string,
  data: { cohort_key: string },
  redirectionPathname: string
): AppThunk {
  return (dispatch) => {
    dispatch(startLoading('LEARNER'));

    return emcService
      .post(
        `/companies_by_slug/${companySlug}/assessment_cohorts/learner`,
        data
      )
      .then((res: CreateAssessmentLearnerResult) => {
        const isFailure = !res?.data?.id;

        if (isFailure) {
          throw new Error(`Something went wrong. ${res.data.reason}`);
        }

        dispatch(fetchEMCLearnersForCurrentUser(true)).then(() =>
          history.replace({ pathname: redirectionPathname })
        );
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('LEARNER')));
  };
}

export function createLearners(
  companyId: string,
  learners: NewLearnerInput[]
): AppThunk<Promise<{ isSuccess: boolean; message: string }>> {
  return (dispatch) => {
    dispatch(startLoading('LEARNER'));
    const areAddedToGroup = Boolean(learners[0].groupId);

    return createEMCLearners(companyId, learners)
      .then((res) => {
        if (res.error) {
          throw new Error('Learner(s) Not Added');
        }
        const batchId = res.batchId || '';

        batch(() => {
          dispatch(resetNewLearnerUpload(companyId));
          dispatch(newLearnersUpdateProperty(companyId, 'learners', []));
        });

        if (areAddedToGroup) {
          dispatch(fetchCompanyGroups(companyId));
        }
        const learnerQuantity = learners.length;
        const message = `${pluralizeEnCount(
          learnerQuantity,
          ' new learner'
        )} submitted. Check the History tab to track progress and view completed changes`;

        dispatch(pollForNewLearnersProgress(companyId, batchId));

        return { isSuccess: true, message };
      })
      .catch((error) => {
        dispatch(addServerError(error, false));
        return error;
      })
      .then((result) => {
        dispatch(stopLoading('LEARNER'));
        return result;
      });
  };
}

export function createLearnersFromCsvUpload(
  companyId: string,
  uploadIds: string[],
  groupId?: string
): AppThunk<Promise<{ isSuccess: boolean; message: string }>> {
  return (dispatch, getState) => {
    dispatch(startLoading('LEARNER'));

    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/create-learners-from-csv-upload.gql')
        ),
        { companyId, groupId, uploadIds }
      )
      .then((res: IGqlResponse<CreateLearnersFromCsvUploadMutation>) => {
        if (!res.data?.data?.createLearnersFromCsvUpload?.ok) {
          throw new Error('Learner(s) Not Added');
        }
        const batchId = res.data.data.createLearnersFromCsvUpload.batchId || '';

        const newLearners = newLearnersSessionSelector(getState());
        const uploadedLearners = newLearners.uploadedLearners || [];

        const message = `${pluralizeEnCount(
          uploadedLearners?.length,
          ' new learner'
        )} submitted. Check the History tab to track progress and view completed changes`;

        batch(() => {
          dispatch(resetNewLearnerUpload(companyId));
          dispatch(newLearnersUpdateProperty(companyId, 'learners', []));
          if (groupId) {
            dispatch(fetchCompanyGroups(companyId));
          }
          dispatch(pollForNewLearnersProgress(companyId, batchId));
        });

        return { isSuccess: true, message };
      })
      .catch((error) => {
        dispatch(addServerError(error, false));
        return error;
      })
      .then((result) => {
        dispatch(stopLoading('LEARNER'));
        return result;
      });
  };
}

function pollForNewLearnersProgress(
  companyId: string,
  batchId: string
): AppThunk {
  return (dispatch, getState) => {
    const key = BatchProgressKeys.learners;
    const state = getState();

    const isPolling = companyPollingSelector(state)[key];

    if (isPolling) {
      return;
    }

    poll(
      () => {
        return dispatch(fetchLearnerBatchProgress(batchId))
          .then((batchProgress) => {
            const isComplete =
              (batchProgress.all || 1) <=
              (batchProgress.completed || 0) + (batchProgress.errors || 0);
            dispatch(updateBatchProgress(companyId, key, batchProgress));
            if (isComplete) {
              dispatch(updatePollingProperty(companyId, key, false));
              dispatch(fetchCompanyLearners(companyId));
              setTimeout(
                () => {
                  dispatch(updateBatchProgress(companyId, key, null));
                },
                isTesting ? 1000 : 3000
              );
            }
            return isComplete;
          })
          .catch((err) => {
            dispatch(addServerError(err));
            return false;
          });
      },
      undefined,
      500
    );
  };
}

export function createEMCLearners(
  companyId: string,
  learners: NewLearnerInput[]
) {
  return emcService
    .gql(getQueryString(loader('@emc/queries/emc/create-learners.gql')), {
      companyId,
      learners,
    })
    .then((res: IGqlResponse<CreateLearnersMutation>) => {
      if (res.data.errors) {
        return {
          error: getGraphQLErrorMessage(res.data.errors),
          success: false,
        };
      }
      const batchId = res.data.data.createLearners?.batchId;
      return { error: '', success: true, batchId };
    });
}
