import { Redirect, Route, RouteProps } from 'react-router-dom';

import { FullPageLoading } from '@emc/elements/loading/full-page';
import React from 'react';
import { isAuthorizingSelector } from 'app/selectors/me';
import { isLoadingCompanySelector } from 'app/selectors/companies/companies';
import { useSelector } from 'react-redux';

export type AuthorizedRouteProps = RouteProps & {
  isAuthorized?: boolean;
  redirectionRoute?: string;
};

export const AuthorizedRoute: React.FC<AuthorizedRouteProps> = (props) => {
  const isLoadingCompany = useSelector(isLoadingCompanySelector);
  const isAuthorizing = useSelector(isAuthorizingSelector);
  const { isAuthorized, redirectionRoute = '/unauthorized', location } = props;

  if (isAuthorized) {
    return <Route {...props} />;
  }

  if (isAuthorizing) {
    return <FullPageLoading />;
  }

  if (isLoadingCompany) {
    return <FullPageLoading />;
  }

  return (
    <Redirect
      to={{
        pathname: redirectionRoute,
        state: { from: location },
      }}
    />
  );
};
