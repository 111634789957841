import { Company } from 'app/types/generated/emc';
import { addActiveCompany } from 'app/actions/companies';

export interface ActiveCompanyState {
  [companySlug: string]: Company;
}
const initialState = {};

export default function activeCompany(
  state: ActiveCompanyState = initialState,
  action: ReturnType<typeof addActiveCompany>
): ActiveCompanyState {
  switch (action.type) {
    case 'ADD_ACTIVE_COMPANY': {
      const companySlug = action.payload.companySlug;

      return {
        ...state,
        [companySlug]: {
          ...state[companySlug],
          ...action.payload.data,
        },
      };
    }

    default: {
      return state;
    }
  }
}
