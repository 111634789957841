import {
  PaginationData,
  paginationDataSelector,
  paginationSelector,
} from '../pagination';
import {
  PaginationState,
  PaginationTypes,
} from '@emc/reducer/session/pagination';
import {
  flatten,
  identity,
  pathOr,
  pickBy,
  pipe,
  pluck,
  propOr,
  reduce,
  uniq,
  values,
} from 'ramda';

import { GroupsReduxState } from 'app/reducer/data/types';
import { IOption } from 'app/types/options';
import { IRootStore } from '@emc/reducer/types';
import { ISelectedLearner } from 'app/types/learners';
import { LoadingState } from '@emc/reducer/session/loading';
import { actionBarSelectedLearnersSelector } from '../learners/learners';
import { createSelector } from 'reselect';
import { currentCompanyIdSelector } from '../companies/companies';
import { getQueryParams } from 'app/utils/router/query-params';
import { loadingSelector } from '../session';
import { sortByLabel } from 'app/utils/format/select-options';

export const groupsSelector = (state: IRootStore) => state.data.groups;

export const companyGroupsSelector = createSelector<
  [(state: IRootStore) => string, (state: IRootStore) => GroupsReduxState],
  GroupsReduxState
>(currentCompanyIdSelector, groupsSelector, (companyId, groups) => {
  return pickBy((group) => group.companyId === companyId, groups);
});

export const companyGroupOptionsSelector = createSelector(
  companyGroupsSelector,
  (groups) => {
    return sortByLabel<IOption<string>>(
      'label',
      values(groups).map((option) => ({
        id: `learner_group_${option.id}`,
        label: option.name,
        value: option.id,
      }))
    );
  }
);

export const selectedLearnerGroupsSelector = createSelector<
  [
    (state: IRootStore) => GroupsReduxState,
    (state: IRootStore) => ISelectedLearner[]
  ],
  GroupsReduxState
>(
  companyGroupsSelector,
  actionBarSelectedLearnersSelector,
  (groups, learners) => {
    return pipe(
      pluck('groupIds'),
      flatten,
      uniq,
      reduce((acc, id) => {
        acc[id] = groups[id];
        return acc;
      }, {})
    )(learners);
  }
);

export const isLoadingGroupsSelector = createSelector<
  [(state: IRootStore) => LoadingState],
  boolean
>(loadingSelector, propOr(false, 'GROUPS'));

export const paginatedGroupIdsSelector = createSelector<
  [(state: IRootStore) => PaginationState],
  string[]
>(paginationSelector, pathOr([], [PaginationTypes.companyGroups, 'pageIds']));

export const companyGroupsPaginationSelector = createSelector<
  [(state: IRootStore) => IRootStore],
  PaginationData & { search?: string }
>(identity, (state) => {
  const params = getQueryParams();

  return {
    ...paginationDataSelector(state, {
      defaultSizePerPage: 10000,
      tableName: PaginationTypes.companyGroups,
    }),
    search: params.searchGroup,
  };
});
