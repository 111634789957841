import { keys, lensPath, mergeDeepRight, set } from 'ramda';

import { ContractsReduxState } from './types';
import { addContracts } from 'app/actions/contracts';
import { updateContractEnrollmentCounts } from 'app/actions/enrollments/enrollment-queue';

export default function contracts(
  state: ContractsReduxState = {},
  action:
    | ReturnType<typeof addContracts>
    | ReturnType<typeof updateContractEnrollmentCounts>
): ContractsReduxState {
  switch (action.type) {
    case 'ADD_CONTRACTS': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return mergeDeepRight(state, action.payload as any);
    }

    case 'UPDATE_CONTRACT_ENROLLMENT_COUNTS': {
      const newCountsByContract = action.payload;
      const contractIds = keys(newCountsByContract);
      let newState = state;
      contractIds.forEach((contractId) => {
        const currentCounts =
          state[contractId]?.enrollmentCounts?.activeCounts || 0;
        const newCounts = currentCounts + newCountsByContract[contractId];
        newState = set(
          lensPath([contractId, 'enrollmentCounts', 'activeCounts']),
          newCounts,
          newState
        );
      });

      return newState;
    }

    default: {
      return state;
    }
  }
}
