import { EmcReferrals } from 'app/types/generated/experience';
import auth from '@udacity/ureact-hoth';
import { updateUserExperience } from 'app/actions/me';

export type UdacityMeState = {
  isAuthenticated: boolean;
  roles: string[];
  referrals: EmcReferrals | null;
};

const initialState = {
  isAuthenticated: auth.isAuthenticated(),
  roles: auth.getCurrentUser()?.roles || [],
  referrals: null,
};

export default function udacity(
  state: UdacityMeState = initialState,
  action: ReturnType<typeof updateUserExperience>
): UdacityMeState {
  switch (action.type) {
    case 'UPDATE_USER_EXPERIENCE': {
      return {
        ...state,
        referrals: action.payload?.emc?.referrals ?? null,
      };
    }

    default: {
      return state;
    }
  }
}
