import { combineReducers } from 'redux';
import emc from './emc';
import udacity from './udacity';

const me = combineReducers({
  udacity,
  emc,
});

export type MeState = ReturnType<typeof me>;

export default me;
