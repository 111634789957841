import { lensPath, set } from 'ramda';

import { updatePagination } from 'app/actions/reports';

export type PaginationInfo = {
  totalCount: number;
  pageIds: (string | number)[];
};
export type PaginationState = {
  [key in PaginationTypes]?: PaginationInfo;
};
const initialState = {};

export default function pagination(
  state: PaginationState = initialState,
  action: ReturnType<typeof updatePagination>
): PaginationState {
  switch (action.type) {
    case 'UPDATE_PAGINATION': {
      const { tableName, totalCount, pageIds } = action.payload;
      return set(lensPath([tableName]), { totalCount, pageIds }, state);
    }

    default: {
      return state;
    }
  }
}

export enum PaginationTypes {
  assessmentParticipants = 'assessmentParticipants',
  companyGraphLearningPaths = 'companyGraphLearningPaths',
  companyGroups = 'companyGroups',
  companyLearners = 'companyLearners',
  contractTermEnrollments = 'contractTermEnrollments',
  contractTermsOverview = 'contractTermsOverview',
  contractsOverview = 'contractsOverview',
  enrollableCompanyGraphLearningPaths = 'enrollableCompanyGraphLearningPaths',
  enrollmentCohortLearners = 'enrollmentCohortLearners',
  graphLearningPaths = 'graphLearningPaths',
  interestList = 'interestList',
  learningPathsInterestList = 'learningPathsInterestList',
  projects = 'projects',
  roster = 'roster',
  resources = 'resources',
}
