import { map, pathOr, pipe, sum } from 'ramda';

import { ALL } from 'app/utils/constants';
import { ALL_TIME_ISO } from 'app/utils/dates/format';
import { IBucketsData } from 'app/types/charts';
import { ICompanyDashboardChartState } from '@emc/reducer/session/charts';
import fill from 'lodash/fill';
import { getDateStreamChartData } from 'app/utils/dates/graph';
import random from 'lodash/random';

const getRandomNumbers = () =>
  pipe(map(() => random(100, 300)))(fill(Array(100), 1));

export enum LearnerActivityChartTypes {
  completedProjects = 'Completed Projects',
  graduations = 'Graduations',
  enrollments = 'Enrollments',
  assessments = 'Assessments',
}

export enum DateRangeTypes {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
  all = 'all',
  custom = 'custom',
}

export enum LearnerActivityTimestamps {
  earliestAssessmentResultCreatedAt = 'earliestAssessmentResultCreatedAt',
  earliestProjectPassedAt = 'earliestProjectPassedAt',
  earliestEnrolledAt = 'earliestEnrolledAt',
  earliestGraduatedAt = 'earliestGraduatedAt',
}

const randomNumbers = {
  [LearnerActivityChartTypes.completedProjects]: getRandomNumbers(),
  [LearnerActivityChartTypes.graduations]: getRandomNumbers(),
  [LearnerActivityChartTypes.enrollments]: getRandomNumbers(),
  [LearnerActivityChartTypes.assessments]: getRandomNumbers(),
};

export function formatAllTimeTimestamps(key: LearnerActivityTimestamps) {
  return (dashboardData: ICompanyDashboardChartState | undefined) => {
    return pipe(pathOr(ALL_TIME_ISO, [key]), (earliestTimestamp) => [
      earliestTimestamp,
      new Date().toISOString(),
    ])(dashboardData);
  };
}

export interface IDateRangeData {
  dateRangeValue: number | string;
  dateRangeType: DateRangeTypes;
}

export function getDateData(
  allTimetimestamps: string[],
  bucketType: IDateRangeData
) {
  const isAllTime = bucketType.dateRangeValue === ALL;
  const timestamps = isAllTime ? allTimetimestamps : [];

  return getDateStreamChartData(timestamps, bucketType);
}

export type LearnerActivityChartData = {
  userCount: number;
  chartKeys: LearnerActivityChartTypes[];
  chartData: { [key: string]: number }[];
  total: number;
};
export function getLearnerActivityChartData(
  chartKey: LearnerActivityChartTypes,
  dataKey: 'data' | 'user_count' = 'data'
) {
  return (
    dashboardSessionData: IBucketsData = {} as IBucketsData,
    hasNoData: boolean
  ): LearnerActivityChartData => {
    const dateRangeData = dashboardSessionData[dataKey] || [];
    const userCount = sum(dashboardSessionData?.user_count || []);

    const chartAnalysis = dateRangeData.reduce(
      (analysis, count, i) => {
        const formattedCount = hasNoData ? randomNumbers[chartKey][i] : count;
        const total = analysis.total + formattedCount;
        const chartData = [
          ...analysis.chartData,
          { [chartKey]: formattedCount },
        ];

        return {
          total,
          chartData,
        };
      },
      { total: 0, chartData: [] as { [chartKey: string]: number }[] }
    );

    return {
      userCount,
      chartKeys: [chartKey],
      chartData: chartAnalysis?.chartData,
      total: chartAnalysis?.total,
    };
  };
}
