import {
  BulkSelectionFilterOp,
  BulkSelectionFilterValueAggregation,
  LearnerBulkSelectionFilter,
  LearnerBulkSelectionFilterField,
  LearnerInterestBulkSelectionFilter,
  LearnerInterestBulkSelectionFilterField,
  LearnerInterestStatus,
  QuestionResponses,
} from 'app/types/generated/emc';

import { INTEREST_FILTER_QUESTION_SUFFIX } from '../constants';
import { filterOutAllTimeQueryParam } from '../dates/defaults';
import { getIsoAgoString } from '../dates/format';
import { getQueryParams } from '../router/query-params';

export function getLearnerIdsSelectionFilter(
  learnerIds: string[]
): LearnerBulkSelectionFilter[] {
  return [
    {
      op: BulkSelectionFilterOp.In,
      field: LearnerBulkSelectionFilterField.Id,
      value: learnerIds.join(','),
    },
  ];
}

export function getLearnerBulkFilters(
  selectedLearnerIds: string[]
): LearnerBulkSelectionFilter[] {
  const batchFilters: LearnerBulkSelectionFilter[] = [];
  const queryParams = getQueryParams();
  if (!queryParams.batch) {
    return getLearnerIdsSelectionFilter(selectedLearnerIds);
  }
  if (filterOutAllTimeQueryParam(queryParams.learnerGroup as string)) {
    batchFilters.push({
      op: BulkSelectionFilterOp.Eq,
      field: LearnerBulkSelectionFilterField.GroupsId,
      value: queryParams.learnerGroup as string,
    });
  }

  if (
    filterOutAllTimeQueryParam(queryParams.companyGraphLearningPathId as string)
  ) {
    batchFilters.push({
      op: BulkSelectionFilterOp.Eq,
      field: LearnerBulkSelectionFilterField.CompanyGraphLearningPathsId,
      value: queryParams.companyGraphLearningPathId as string,
    });
  }

  if (filterOutAllTimeQueryParam(queryParams.createdAfter as string)) {
    batchFilters.push({
      op: BulkSelectionFilterOp.Gte,
      field: LearnerBulkSelectionFilterField.CreateDate,
      value: getIsoAgoString(queryParams.createdAfter as string) as string,
    });
  }

  if (queryParams.hasAssessmentResultAfter) {
    const date = getIsoAgoString(queryParams.hasAssessmentResultAfter);
    batchFilters.push({
      op: BulkSelectionFilterOp.Gte,
      field: LearnerBulkSelectionFilterField.V2AssessmentResultsCreateDate,
      fieldAgg: BulkSelectionFilterValueAggregation.Max,
      value: date as string,
    });
  }

  // no filters selected: dont filter at all
  if (batchFilters.length === 0) {
    batchFilters.push({
      op: BulkSelectionFilterOp.Gte,
      field: LearnerBulkSelectionFilterField.Id,
      value: '0',
    });
  }

  return batchFilters;
}

export function getCohortLearnersBulkFilters(
  enrollmentCohortId: string,
  selectedLearnerIds: string[]
): LearnerBulkSelectionFilter[] {
  const queryParams = getQueryParams();
  if (!queryParams.batch) {
    return getLearnerIdsSelectionFilter(selectedLearnerIds);
  }
  const batchFilters: LearnerBulkSelectionFilter[] = [
    {
      op: BulkSelectionFilterOp.Eq,
      field: LearnerBulkSelectionFilterField.EnrollmentCohortId,
      value: enrollmentCohortId,
    },
  ];

  return batchFilters;
}

export enum AssessmentParticipantCompletionState {
  Invited = 'invited',
  Started = 'started',
  Completed = 'completed',
}

export function getAssessmentCohortParticipantFilters(cohortKey: string): {
  selectionFilters: LearnerBulkSelectionFilter[];
  exclusionFilters: LearnerBulkSelectionFilter[];
}[] {
  const queryParams = getQueryParams();
  const invitedNotStartedFilters = {
    selectionFilters: [
      {
        op: BulkSelectionFilterOp.Eq,
        field: LearnerBulkSelectionFilterField.AssessmentInviteCohortsCohortKey,
        value: cohortKey,
      },
    ],
    exclusionFilters: [
      {
        op: BulkSelectionFilterOp.Eq,
        field: LearnerBulkSelectionFilterField.AssessmentLabSessionsCohortKey,
        value: cohortKey,
      },
    ],
  };
  const startedNotCompletedFilters = {
    selectionFilters: [
      {
        op: BulkSelectionFilterOp.Eq,
        field: LearnerBulkSelectionFilterField.AssessmentLabSessionsCohortKey,
        value: cohortKey,
      },
    ],
    exclusionFilters: [
      {
        op: BulkSelectionFilterOp.Eq,
        field:
          LearnerBulkSelectionFilterField.V2AssessmentResultsAssessmentLabSessionCohortKey,
        value: cohortKey,
      },
    ],
  };

  const completedFilters = {
    selectionFilters: [
      {
        op: BulkSelectionFilterOp.Eq,
        field: LearnerBulkSelectionFilterField.AssessmentLabSessionsCohortKey,
        value: cohortKey,
      },
    ],
    exclusionFilters: [
      {
        op: BulkSelectionFilterOp.Eq,
        field: LearnerBulkSelectionFilterField.AssessmentLabSessionsCohortKey,
        value: cohortKey,
      },
      {
        op: BulkSelectionFilterOp.Eq,
        field: LearnerBulkSelectionFilterField.V2AssessmentResultsCreateDate,
        fieldAgg: BulkSelectionFilterValueAggregation.Count,
        value: '0',
      },
    ],
  };

  if (
    queryParams.completionState === AssessmentParticipantCompletionState.Invited
  ) {
    return [invitedNotStartedFilters];
  }

  if (
    queryParams.completionState === AssessmentParticipantCompletionState.Started
  ) {
    return [startedNotCompletedFilters];
  }

  if (
    queryParams.completionState ===
    AssessmentParticipantCompletionState.Completed
  ) {
    return [completedFilters];
  }

  return [invitedNotStartedFilters, startedNotCompletedFilters];
}

export function getLearnerInterestIdsSelectionFilter(
  learnerInterestIds: string[]
): LearnerInterestBulkSelectionFilter[] {
  return [
    {
      op: BulkSelectionFilterOp.In,
      field: LearnerInterestBulkSelectionFilterField.Id,
      value: learnerInterestIds.join(','),
    },
  ];
}

export function getLearnerInterestBulkFilters(
  isBulkSelecting = true,
  learnerInterestIds?: string[]
): LearnerInterestBulkSelectionFilter[] {
  if (!isBulkSelecting && learnerInterestIds)
    return getLearnerInterestIdsSelectionFilter(learnerInterestIds);
  const batchFilters: LearnerInterestBulkSelectionFilter[] = [];
  const queryParams = getQueryParams();
  const status = (queryParams.status as string) || LearnerInterestStatus.Open;

  const { programKey, companyGraphLearningPathId, eligibility } = queryParams;
  if (status) {
    batchFilters.push({
      op: BulkSelectionFilterOp.In,
      field: LearnerInterestBulkSelectionFilterField.Status,
      value: status.toLowerCase(),
    });
  }

  if (companyGraphLearningPathId) {
    batchFilters.push({
      op: BulkSelectionFilterOp.In,
      field: LearnerInterestBulkSelectionFilterField.CompanyGraphLearningPathId,
      value: companyGraphLearningPathId,
    });
  }

  if (programKey) {
    batchFilters.push({
      op: BulkSelectionFilterOp.In,
      field: LearnerInterestBulkSelectionFilterField.ProgramKey,
      value: programKey,
    });
  }

  if (eligibility) {
    batchFilters.push({
      op: BulkSelectionFilterOp.In,
      field: LearnerInterestBulkSelectionFilterField.Eligibility,
      value: eligibility,
    });
  }

  if (queryParams.scoreLower) {
    batchFilters.push({
      op: BulkSelectionFilterOp.Gte,
      field: LearnerInterestBulkSelectionFilterField.AssessmentScore,
      value: queryParams.scoreLower,
    });
  }

  if (queryParams.scoreUpper) {
    batchFilters.push({
      op: BulkSelectionFilterOp.Lte,
      field: LearnerInterestBulkSelectionFilterField.AssessmentScore,
      value: queryParams.scoreUpper,
    });
  }

  if (queryParams.submittedAfter) {
    batchFilters.push({
      op: BulkSelectionFilterOp.Gte,
      field: LearnerInterestBulkSelectionFilterField.SubmissionDate,
      value: getIsoAgoString(queryParams.submittedAfter) as string,
    });
  }

  if (queryParams.submittedBefore) {
    batchFilters.push({
      op: BulkSelectionFilterOp.Lte,
      field: LearnerInterestBulkSelectionFilterField.SubmissionDate,
      value: getIsoAgoString(queryParams.submittedBefore, false) as string,
    });
  }
  return batchFilters;
}

export function getQuestionsAnsweredFilters() {
  const queryParams = getQueryParams();
  const questionsAnswered: QuestionResponses[] = [];
  const filterRegex = new RegExp(`${INTEREST_FILTER_QUESTION_SUFFIX}$`);
  for (const key in queryParams) {
    if (filterRegex.test(key)) {
      questionsAnswered.push({
        questionId: key.replace(INTEREST_FILTER_QUESTION_SUFFIX, ''),
        responses: queryParams[key].split(','),
      });
    }
  }
  return questionsAnswered;
}

export function getLearnerIdsFromFilters(
  filters: LearnerBulkSelectionFilter[]
): string[] {
  let result: string[] = [];
  filters
    .filter((item) => item.field === LearnerBulkSelectionFilterField.Id)
    .forEach((idFilt) => {
      result = result.concat(idFilt?.value?.split(',') || []);
    });
  return result;
}
