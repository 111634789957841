import { lensPath, set } from 'ramda';

import { AssessmentWhitelistedProgram } from 'app/types/generated/emc';
import { IQuestion } from 'app/types/assessments';
import { Normalized } from 'app/types/shared';
import { Program } from 'app/types/generated/mind-probe';
import { sessionMindProbeUpdateProperty } from 'app/actions/assessments/mind-probe';

export interface IMindProbeSessionState {
  questionsV2?: IQuestion[];
  programs?: Normalized<Program>;
  whitelist?: Normalized<AssessmentWhitelistedProgram>;
}

export default function mindProbe(
  state: IMindProbeSessionState = {},
  action: ReturnType<typeof sessionMindProbeUpdateProperty>
): IMindProbeSessionState {
  switch (action.type) {
    case 'SESSION_MIND_PROBE_UPDATE_PROPERTY': {
      const { key, value } = action.payload;

      return set(lensPath([key]), value, state);
    }

    default: {
      return state;
    }
  }
}
