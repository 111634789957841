import { BatchProgressKeys, updateBatchProgress } from 'app/actions/polling';
import { lensPath, omit, set } from 'ramda';

import { PollingProgress } from 'app/types/shared';

export type BatchProgressState = {
  [companyId: string]: {
    [key in BatchProgressKeys]?: PollingProgress;
  };
};

const initialState = {};

export default function batchProgress(
  state: BatchProgressState = initialState,
  action: ReturnType<typeof updateBatchProgress>
): BatchProgressState {
  switch (action.type) {
    case 'UPDATE_BATCH_PROGRESS': {
      const { companyId, key, pollingProgress } = action.payload;

      if (pollingProgress) {
        return set(lensPath([companyId, key]), pollingProgress, state);
      }
      return {
        ...state,
        [companyId]: omit([key], state[companyId]),
      };
    }

    default: {
      return state;
    }
  }
}
