import {
  AddCompanyGroupMutation,
  Company,
  GetCompanyGroupsQuery,
  Group,
  RemoveLearnersFromGroupMutation,
} from 'app/types/generated/emc';
import { AppThunk, IGqlResponse } from 'app/types/shared';
import {
  CompanyNormalizedEntities,
  companyNormSchema,
} from 'app/utils/normalize/company';
import { addFlashMessage, addServerError } from '../flash';
import {
  companyGroupsPaginationSelector,
  isLoadingGroupsSelector,
  paginatedGroupIdsSelector,
} from 'app/selectors/groups';
import {
  getPaginatedItems,
  getQueryString,
} from 'app/utils/graphql/serializer';
import { length, path } from 'ramda';
import { startLoading, stopLoading } from '../loading';

import { GroupsReduxState } from 'app/reducer/data/types';
import { PaginationTypes } from '@emc/reducer/session/pagination';
import { batch } from 'react-redux';
import { createAction } from 'app/utils/actions';
import { emcService } from '@emc/services';
import { getLearnerBulkFilters } from 'app/utils/format/batch-filter';
import { loader } from 'graphql.macro';
import noop from 'lodash/noop';
import { normalize } from 'normalizr';
import { track } from 'app/utils/analytics';
import { updatePagination } from '../reports';

// Sync

export function addGroups(groups: GroupsReduxState) {
  return createAction('ADD_GROUPS', groups);
}

export function removeGroup(groupId: string) {
  return createAction('REMOVE_GROUP', groupId);
}

export function addGroupToLearners(learnerIds: string[], groupId: string) {
  return createAction('ADD_GROUP_TO_LEARNERS', {
    learnerIds,
    groupId,
  });
}

export function removedLearnersFromGroup(
  groupId: string,
  learnerIds: string[]
) {
  return createAction('REMOVED_LEARNERS_FROM_GROUP', {
    learnerIds,
    groupId,
  });
}

// Async

export function fetchCompanyGroups(companyId: string): AppThunk {
  return (dispatch, getState) => {
    const state = getState();
    const isLoadingCompanyGroups = isLoadingGroupsSelector(state);

    if (isLoadingCompanyGroups) {
      return Promise.resolve();
    }

    dispatch(startLoading('GROUPS'));

    const paginationData = companyGroupsPaginationSelector(state);
    const { orderBy, order, search } = paginationData;

    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/get-company-groups.gql')), {
        companyId,
        orderBy,
        order,
        search,
      })
      .then((res: IGqlResponse<GetCompanyGroupsQuery>) => {
        const normalizedData = normalize<Company, CompanyNormalizedEntities>(
          res.data.data.company || {},
          companyNormSchema
        );
        const groups = normalizedData.entities.groups || {};
        const company = normalizedData.entities.companies[companyId];
        const groupIds = getPaginatedItems(company?.groups);
        const totalGroupCount = company?.groups?.totalCount || 0;

        batch(() => {
          dispatch(addGroups(groups));
          dispatch(
            updatePagination(
              PaginationTypes.companyGroups,
              totalGroupCount,
              groupIds
            )
          );
        });
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('GROUPS')));
  };
}

export function addCompanyGroup(
  companyId: string,
  groupName: string,
  shouldShowFlashMessage = false,
  onSuccess = noop
): AppThunk<Promise<string>> {
  return (dispatch, getState) => {
    dispatch(startLoading('CREATING_GROUP'));

    track('CreateCompanyGroup', {
      companyId,
      groupName,
    });

    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/add-company-group.gql')), {
        companyId,
        groupName,
      })
      .then((res: IGqlResponse<AddCompanyGroupMutation>) => {
        const result = res.data.data?.createGroup;

        if (shouldShowFlashMessage && result?.ok) {
          dispatch(addFlashMessage('success', 'Group Created'));
        }
        const group = result?.group;
        const groupId = group?.id;
        if (groupId) {
          const groupIds = paginatedGroupIdsSelector(getState());

          onSuccess(groupId);
          dispatch(addGroups({ [groupId]: group as Group }));
          dispatch(
            updatePagination(
              PaginationTypes.companyGroups,
              groupIds.length + 1,
              [groupId, ...groupIds]
            )
          );
        }
        return groupId || '';
      })
      .catch((error) => {
        dispatch(addServerError(error));
        return '';
      })
      .then((groupId) => {
        dispatch(stopLoading('CREATING_GROUP'));
        return groupId;
      });
  };
}

export function addLearnersToNewGroup(
  companyId: string,
  name: string,
  learnerIds: string[]
): AppThunk<Promise<void>> {
  return (dispatch): Promise<void> => {
    return dispatch(addCompanyGroup(companyId, name))
      .then((groupId: string) => {
        if (groupId && length(learnerIds)) {
          dispatch(addLearnersToGroup(companyId, groupId, learnerIds));
        }
      })
      .catch((error) => dispatch(addServerError(error)));
  };
}

export function addLearnersToGroup(
  companyId: string,
  groupId: string,
  learnerIds: string[],
  showFlash = false
): AppThunk<Promise<void>> {
  return (dispatch) => {
    dispatch(startLoading('UPDATING_GROUP'));

    return emcService
      .gql(
        getQueryString(loader('@emc/queries/emc/add-learners-to-group.gql')),
        {
          groupId,
          learnerIdsFilters: getLearnerBulkFilters(learnerIds),
        }
      )
      .then((res) => {
        const isSuccess = path(['data', 'data', 'addLearnersToGroup', 'ok']);

        if (!isSuccess) {
          throw new Error(
            `Learner Not Added to Group - ${path([
              'data',
              'errors',
              0,
              'message',
            ])(res)}`
          );
        }

        dispatch(fetchCompanyGroups(companyId));
        return dispatch(addGroupToLearners(learnerIds, groupId));
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => {
        batch(() => {
          dispatch(stopLoading('UPDATING_GROUP'));
          showFlash && dispatch(addFlashMessage('success', 'Learners added'));
        });
      });
  };
}

export function removeLearnersFromGroup(
  groupId: string,
  learnerIds: string[]
): AppThunk<Promise<void>> {
  return (dispatch) => {
    dispatch(startLoading('UPDATING_GROUP'));

    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/remove-learners-from-group.gql')
        ),
        {
          groupId,
          learnerIdsFilters: getLearnerBulkFilters(learnerIds),
        }
      )
      .then((res: IGqlResponse<RemoveLearnersFromGroupMutation>) => {
        const isSuccess = res.data.data?.removeLearnersFromGroup?.ok;

        if (!isSuccess) {
          throw new Error('Learner Not Removed from Group');
        }

        dispatch(removedLearnersFromGroup(groupId, learnerIds));
      })
      .catch((error) => {
        dispatch(addServerError(error));
      })
      .then(() => {
        dispatch(stopLoading('UPDATING_GROUP'));
      });
  };
}
