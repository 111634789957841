import { adjust, append, concat, lensPath, remove, set } from 'ramda';
import {
  newLearnersAddMultipleLearners,
  newLearnersAddSingleLearner,
  newLearnersRemoveLearner,
  newLearnersUpdateLearnerProperty,
  newLearnersUpdateProperty,
} from 'app/actions/learners/create';

import { NewLearnerInput } from 'app/types/generated/emc';
import { Normalized } from 'app/types/shared';

export interface INewLearnersSessionState {
  learners?: NewLearnerInput[];
  groupId?: string;
  uploadedLearners?: NewLearnerInput[];
  upload_ids?: string[];
  filenames?: string[];
  errors?: {
    email: number;
    firstName: number;
    lastName: number;
  };
  error_lines?: string[];
  success?: boolean;
  validation_errors?: string[];
}
const initialState = {};

type NewLearnerActions =
  | ReturnType<typeof newLearnersUpdateProperty>
  | ReturnType<typeof newLearnersUpdateLearnerProperty>
  | ReturnType<typeof newLearnersRemoveLearner>
  | ReturnType<typeof newLearnersAddSingleLearner>
  | ReturnType<typeof newLearnersAddMultipleLearners>;

export default function newLearners(
  state: Normalized<INewLearnersSessionState> = initialState,
  action: NewLearnerActions
): Normalized<INewLearnersSessionState> {
  switch (action.type) {
    case 'NEW_LEARNERS_ADD_SINGLE_LEARNER': {
      const companyId = action.payload.companyId;
      const learners = getNewLearnersForCompany(companyId, state);

      return {
        ...state,
        [companyId]: {
          ...state[companyId],
          learners: append(action.payload.learner)(learners),
        },
      };
    }

    case 'NEW_LEARNERS_ADD_MULTIPLE_LEARNERS': {
      const companyId = action.payload.companyId;
      const learners = state[companyId]?.uploadedLearners || [];

      return {
        ...state,
        [companyId]: {
          ...state[companyId],
          uploadedLearners: concat(learners)(action.payload.learners),
        },
      };
    }

    case 'NEW_LEARNERS_REMOVE_LEARNER': {
      const companyId = action.payload.companyId;
      const learners = getNewLearnersForCompany(companyId, state);

      return {
        ...state,
        [companyId]: {
          ...state[companyId],
          learners: remove(action.payload.idx, 1, learners),
        },
      };
    }

    case 'NEW_LEARNERS_UPDATE_LEARNER_PROPERTY': {
      const { companyId, key, idx, value } = action.payload;
      const learners = getNewLearnersForCompany(companyId, state);

      return {
        ...state,
        [companyId]: {
          ...state[companyId],
          learners: adjust(
            idx,
            (learner) => ({
              ...learner,
              [key]: value,
            }),
            learners
          ),
        },
      };
    }

    case 'NEW_LEARNERS_UPDATE_PROPERTY': {
      const { companyId, property, value } = action.payload;

      return set(lensPath([companyId, property]), value, state);
    }

    default: {
      return state;
    }
  }
}

// Util

function getNewLearnersForCompany(
  companyId: string,
  state: Normalized<INewLearnersSessionState>
): NewLearnerInput[] {
  return state[companyId]?.learners || [];
}
