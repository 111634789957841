import dayjs from 'dayjs';
import i18n from 'i18next';

export const getTimeZone = () => {
  const { DateTimeFormat } = Intl;
  const dateTimeFormat = new DateTimeFormat();
  return dateTimeFormat.resolvedOptions().timeZone;
};

export const getTimeStamp = () => dayjs().format('YYYY-MM-DDTHH:mm:ss');

export function fromNowOrNow(createDate: string) {
  const momentCreated = dayjs.utc(createDate);
  if (dayjs().diff(momentCreated) < 1000) {
    return 'just now';
  }

  return momentCreated.fromNow();
}

export function getMonthsFromDays(days: number): number {
  const roundMonths = (days / 30).toFixed(0);
  return parseInt(roundMonths);
}

export function getDurationFromMinutes(
  minutes: number,
  largestDenominator?: 'minutes' | 'hours'
) {
  if (minutes < 60 || largestDenominator === 'minutes') {
    return i18n.t('common.timeInMinutes', {
      count: Math.floor(minutes),
    });
  }

  const hours = minutes / 60;
  if (hours < 24 || largestDenominator === 'hours') {
    return i18n.t('common.timeInHours', {
      count: parseFloat(hours.toFixed(1)),
    });
  }

  const days = hours / 24;
  return i18n.t('common.timeInDays', {
    count: parseFloat(days.toFixed(1)),
  });
}
