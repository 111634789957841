import { Assessment, AssessmentCohort } from 'app/types/generated/emc';
import {
  AssessmentCohortsReduxState,
  AssessmentsReduxState,
} from 'app/reducer/data/types';
import { LearnerNormalizedEntities, learnerNormSchema } from './learner';

import { schema } from 'normalizr';

export const assessmentCohortNormSchema = new schema.Entity<AssessmentCohort>(
  'assessmentCohorts',
  { learners: { edges: [{ node: learnerNormSchema }] } },
  { idAttribute: 'cohortKey' }
);

export type AssessmentCohortNormalizedEntities = LearnerNormalizedEntities & {
  assessmentCohorts?: AssessmentCohortsReduxState;
};

export const assessmentNormSchema = new schema.Entity<Assessment>(
  'assessments',
  {
    assessmentCohorts: [assessmentCohortNormSchema],
  }
);

export type AssessmentNormalizedEntities =
  AssessmentCohortNormalizedEntities & {
    assessments: AssessmentsReduxState;
  };
