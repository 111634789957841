import {
  Contract,
  EnrollmentStatusEnum,
  Status,
} from 'app/types/generated/emc';
import { IFilter, IOption } from 'app/types/options';
import { getProgramTypesFilter, isItemExpired } from 'app/utils/data-types';
import { prop, uniqBy } from 'ramda';

import { RosterOnTrackStatus } from 'app/types/reports';
import { getFilterPillDateRangeOptions } from 'app/utils/dates/ranges';
import { sortByLabel } from 'app/utils/format/select-options';

export const DELTA_START_DEFAULT = '7';

function getBaseFilterOptions(
  groupFilterMenuOptions: IOption[],
  programs: IOption<string>[],
  showsProgramKey: boolean
) {
  const uniqPrograms = uniqBy(prop('value'), programs);

  const programFilterMenuOptions = uniqPrograms.map((option) => ({
    id: `program_${option.value}`,
    label: showsProgramKey ? `${option.label} (${option.value})` : option.label,
    value: option.value,
  }));

  const groupFilter = {
    id: 'learnerGroup',
    label: 'Group',
    multi: true,
    description: 'Learner Group',
    options: groupFilterMenuOptions,
  };

  const programFilter = {
    id: 'programKey',
    label: 'Program',
    multi: true,
    description: 'Program',
    options: programFilterMenuOptions,
  };

  const filters: IFilter[] = [];

  if (groupFilterMenuOptions.length > 0) filters.push(groupFilter);
  if (programs.length > 0) filters.push(programFilter);
  filters.push(getProgramTypesFilter());
  return filters;
}

export const getRosterFilterMenuOptions = (
  enrollmentCohorts: IOption<string>[],
  groupFilterMenuOptions: IOption[],
  programs: IOption<string>[],
  showsProgramKey: boolean,
  graphLearningPathsFilter: IFilter,
  handleSelectGraphLearningPath: (learningPathId: string) => void,
  isCompanyAutoEnrollEnabled: boolean,
  contracts: Contract[]
): IFilter[] => {
  const filters = getBaseFilterOptions(
    groupFilterMenuOptions,
    programs,
    showsProgramKey
  );

  const optionsWithoutAllTime = getFilterPillDateRangeOptions();
  optionsWithoutAllTime.splice(5, 1); // removes All Time
  optionsWithoutAllTime.splice(6, 1); // removes Unset

  if ((graphLearningPathsFilter.options?.length || 0) > 0)
    filters.push({
      ...graphLearningPathsFilter,
      onSelect: handleSelectGraphLearningPath,
    });
  const rosterFilters: IFilter[] = [
    {
      id: 'enrollmentState',
      label: 'Status',
      multi: true,
      description: 'Enrollment status',
      options: [
        {
          id: 'STATUS_ENROLLED',
          label: 'Enrolled',
          value: EnrollmentStatusEnum.Enrolled,
        },
        {
          id: 'STATUS_GRADUATED',
          label: 'Graduated',
          value: EnrollmentStatusEnum.Graduated,
        },
        {
          id: 'STATUS_UNENROLLED',
          label: 'Unenrolled',
          value: EnrollmentStatusEnum.Unenrolled,
        },
      ],
    },
    {
      id: 'onTrackStatus',
      label: 'On Track Status',
      multi: true,
      options: getOnTrackStatusOptions(),
    },
    {
      id: 'deltaStart',
      label: 'Recent Progress',
      options: optionsWithoutAllTime,
      defaultValue: DELTA_START_DEFAULT,
    },
    {
      id: 'activeAfter',
      label: 'Active',
      complementId: 'activeBefore',
      description: 'Date Active',
      options: getFilterPillDateRangeOptions('activeBefore'),
    },
    {
      id: 'activeBefore',
      label: 'Inactive',
      options: getFilterPillDateRangeOptions(),
    },
    {
      id: 'projectsPassedLower',
      label: 'Projects Passed %',
      description: 'Percentage of Projects Passed',
      numberRangeOptions: {
        defaultMin: '0',
        defaultMax: '1.0',
        step: '0.1',
      },
      complementId: 'projectsPassedUpper',
    },
    {
      id: 'graduatedAfter',
      label: 'Date Graduated',
      complementId: 'graduatedBefore',
      description: 'Date Graduated',
      options: getFilterPillDateRangeOptions('graduatedBefore'),
    },
    {
      id: 'enrollmentStart',
      label: 'Enrollment Date',
      complementId: 'enrollmentEnd',
      description: 'Date Learner Enrolled',
      options: getFilterPillDateRangeOptions('enrollmentEnd'),
    },
    {
      id: 'enrollmentChangeStart',
      label: 'Enrollment Changed',
      complementId: 'enrollmentChangeEnd',
      description: 'Date Enrollment Changed',
      options: getFilterPillDateRangeOptions('enrollmentChangeEnd'),
    },
    {
      id: 'contractId',
      label: 'Contract',
      description: 'Contract Association',
      options: getContractIdOptions(contracts),
    },
  ];
  if (enrollmentCohorts.length > 0) {
    const cohortFilterOptions = enrollmentCohorts.map((cohort) => ({
      id: `enrollment_cohort_${cohort.value}`,
      label: cohort.label as string,
      value: cohort.value as string,
    }));

    const cohortsFilter = {
      id: 'enrollmentCohortIds',
      label: 'Cohort',
      multi: true,
      description: 'Enrollment Cohort',
      options: cohortFilterOptions,
    };
    rosterFilters.unshift(cohortsFilter);
  }
  if (isCompanyAutoEnrollEnabled) {
    const enrollmentTypeFilterOptions = {
      id: 'isAutoEnrolled',
      label: 'Enrollment Type',
      complementId: 'isManuallyEnrolled',
      description: 'Auto or Manual Enrollment',
      options: [
        {
          dateRange: false,
          id: 'Auto',
          label: 'Auto Enrolled',
          value: 'Auto',
        },
        {
          dateRange: false,
          id: 'Manual',
          label: 'Manually Enrolled',
          value: 'Manual',
        },
      ],
    };
    rosterFilters.push(enrollmentTypeFilterOptions);
  }
  return [...filters, ...rosterFilters];
};

function getOnTrackStatusOptions() {
  return [
    {
      id: 'STATUS_ON_TRACK',
      label: RosterOnTrackStatus.OnTrack,
      value: RosterOnTrackStatus.OnTrack,
    },
    {
      id: 'STATUS_MONITOR',
      label: RosterOnTrackStatus.Monitor,
      value: RosterOnTrackStatus.Monitor,
    },
    {
      id: 'STATUS_OFF_TRACK',
      label: RosterOnTrackStatus.OffTrack,
      value: RosterOnTrackStatus.OffTrack,
    },
    {
      id: 'STATUS_READY',
      label: RosterOnTrackStatus.ReadyToGraduate,
      value: RosterOnTrackStatus.ReadyToGraduate,
    },
    {
      id: 'STATUS_COMPLETED',
      label: RosterOnTrackStatus.Completed,
      value: RosterOnTrackStatus.Completed,
    },
  ];
}

export const getProjectFilterMenuOptions = (
  projectNames: string[],
  groupFilterMenuOptions: IOption[],
  programs: IOption<string>[],
  showsProgramKey: boolean
): IFilter[] => {
  const filters = getBaseFilterOptions(
    groupFilterMenuOptions,
    programs,
    showsProgramKey
  );

  const projectNameFilterMenuOptions = sortByLabel<IOption>(
    'label',
    projectNames.map((projectName) => ({
      id: projectName,
      label: projectName,
      value: projectName,
    }))
  );

  return [
    ...filters,
    {
      id: 'projectName',
      label: 'Project',
      description: 'Project Name',
      options: projectNameFilterMenuOptions,
    },
    {
      id: 'projectStatus',
      label: 'Status',
      description: 'Project Status',
      options: [
        {
          id: 'STATUS_PASSED',
          label: 'Passed',
          value: 'Passed',
        },
        {
          id: 'STATUS_REVIEW',
          label: 'Attempted',
          value: 'In Review / Changes Requested',
        },
      ],
    },
    {
      id: 'attemptsMadeLower',
      label: 'Attempts',
      numberRangeOptions: {
        defaultMin: '1',
        defaultMax: '10',
      },
      complementId: 'attemptsMadeUpper',
      description: 'Number of attempts made to complete project',
    },
    {
      id: 'lastAttemptStart',
      label: 'Last Attempt',
      complementId: 'lastAttemptEnd',
      description: 'Date of last attempted project submission',
      options: getFilterPillDateRangeOptions('lastAttemptEnd'),
    },
    {
      id: 'passDateStart',
      label: 'Passed',
      complementId: 'passDateEnd',
      description: 'Date of project completion',
      options: getFilterPillDateRangeOptions('passDateEnd'),
    },
  ];
};

type ContractsByStatus = {
  active: Contract[];
  expired: Contract[];
  suspended: Contract[];
};

// order contracts: active, suspended, then expired
const sortContractsByStatus = (contracts: Contract[]) => {
  const contractsByStatus: ContractsByStatus = {
    active: [],
    expired: [],
    suspended: [],
  };
  const { active, expired, suspended } = contracts.reduce(
    (allContracts, contract) => {
      const status = contract.status?.toUpperCase();
      if (status === Status.Suspended) {
        allContracts.suspended.push(contract);
      } else if (contract && isItemExpired(contract)) {
        allContracts.expired.push(contract);
      } else {
        allContracts.active.push(contract);
      }
      return allContracts;
    },
    contractsByStatus
  );

  return [...active, ...suspended, ...expired];
};

const getContractIdOptions = (contracts: Contract[]) => {
  const sortedContracts = sortContractsByStatus(contracts);
  return sortedContracts.map((contract) => ({
    id: contract.id,
    label: contract.title,
    value: contract.id,
  }));
};
