import { CompanyProgramsReduxState } from './types';
import { addCompanyProgram } from 'app/actions/programs';

const initialState = {};

export default function companyPrograms(
  state: CompanyProgramsReduxState = initialState,
  action: ReturnType<typeof addCompanyProgram>
): CompanyProgramsReduxState {
  switch (action.type) {
    case 'ADD_COMPANY_PROGRAM': {
      const programKey = action.payload.programKey;

      return {
        ...state,
        [programKey]: {
          ...state[programKey],
          ...action.payload.data,
        },
      };
    }

    default: {
      return state;
    }
  }
}
