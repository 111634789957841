import { Heading, Text } from '@udacity/veritas-components';
import React, { useEffect } from 'react';

import CONFIG from '@emc/config';
import { Container } from '@emc/elements/container';
import { Section } from '@emc/elements/section';
import styles from '../not-found/styles.module.scss';

export const Unauthorized: React.FC = () => {
  useEffect(() => {
    document.title = 'Unauthorized';
  }, []);
  return (
    <Container type="small">
      <Section className={styles.card}>
        <Heading align="center" size="h1">
          Unauthorized
        </Heading>
        <Text align="center">
          Try logging out and back in at the{' '}
          <a
            href={`${CONFIG.REACT_APP_SIGN_IN_URL}?next=${CONFIG.REACT_APP_EMC_WEB_URL}`}
          >
            Udacity Login-Page.
          </a>
        </Text>
        <Text align="center">
          If this problem persists, please contact your customer success manager
          to have them help you sort it out.
        </Text>
      </Section>
    </Container>
  );
};
