import {
  IconMenu,
  IconSidePanelClose,
  IconSidePanelOpen,
} from '@udacity/veritas-icons';

import React from 'react';
import i18n from 'i18next';
import styles from './styles.module.scss';

export const NavSwitch: React.FC<{
  handleToggleClick: () => void;
  isMobile: boolean;
  isExpanded: boolean;
}> = ({ handleToggleClick, isMobile, isExpanded }) => {
  const isRtl = i18n.dir() === 'rtl';
  const title = isRtl
    ? i18n.t('common.toggleRightNavigation')
    : i18n.t('common.toggleLeftNavigation');

  return (
    <button
      onClick={handleToggleClick}
      className={styles.toggleButton}
      aria-expanded={isExpanded}
    >
      {isMobile ? (
        <IconMenu size="lg" />
      ) : isExpanded ? (
        <IconSidePanelClose size="md" title={title} />
      ) : (
        <IconSidePanelOpen size="md" title={title} />
      )}
    </button>
  );
};
