import {
  emcLearnerForCurrentCompanySelector,
  hasLearnerManagerPermissionsOrHigherSelector,
} from 'app/selectors/me';

import { AuthorizedRoute } from './authorized-route';
import React from 'react';
import { RouteProps } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { useSelector } from 'react-redux';

export const IndividualLearnerRoute: React.FC<RouteProps> = (props) => {
  const me = useSelector(emcLearnerForCurrentCompanySelector);
  const isCompanyLearner = !isEmpty(me);
  const hasPermissions = useSelector(
    hasLearnerManagerPermissionsOrHigherSelector
  );
  const augmentedProps = {
    ...props,
    isAuthorized: hasPermissions || isCompanyLearner,
  };
  return <AuthorizedRoute {...augmentedProps} />;
};
