import { Heading, Text } from '@udacity/veritas-components';

import { Container } from '@emc/elements/container';
import React from 'react';

type Props = {
  error: { reason: string };
};

export const Maintenance: React.FC<Props> = ({ error }) => (
  <Container type="small">
    <Heading align="center" size="h3">
      The Enterprise Management Console (EMC) is undergoing scheduled
      maintenance. Thank you for your patience.
    </Heading>
    <Text align="center" full>
      {error.reason || ''}
    </Text>
  </Container>
);
