import CONFIG from 'app/config';
import { authenticateCurrentUser } from 'app/actions/me';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export function useIframeCommunication() {
  const routeHistory = useHistory();
  useEffect(() => {
    const listener = (evt: MessageEvent) => {
      if (evt.origin !== (CONFIG.REACT_APP_MANAGE_WEB_URL as string)) return;
      if (evt.data.type === 'nav') {
        routeHistory.push(evt.data.path);
      }
      if (evt.data.type === 'auth') {
        authenticateCurrentUser();
      }
      if (evt.data.type === 'reload') {
        window.location.reload();
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [routeHistory]);
}
