import axios, { AxiosRequestConfig, Method } from 'axios';
import { entEngineerId, getRolesByUserKey } from '../data/users';
import { indexBy, isEmpty, prop } from 'ramda';

import { IGqlResponse } from 'app/types/shared';
import { IQuestionAdmin } from 'app/types/assessments';
import { Role } from 'app/types/generated/emc';
import https from 'https';
import jwt from 'jsonwebtoken';

export const localTestDbUrl = 'http://dev.udacity.com:8090';
export const localTestQmApiUrl = 'http://dev.udacity.com:8091';

export function makeJWT(userKey: string) {
  const jwtPayload = {
    exp: 1535153414,
    iat: 1532561414,
    kid: 'a7e89ed21',
    uid: userKey,
    admin: false,
    roles: getRolesByUserKey(userKey),
  };
  const token = jwt.sign(jwtPayload, 'secret secret so so secret do not leak');
  return token;
}

function request(
  method: Method,
  path: string,
  data?: unknown,
  options?: Partial<AxiosRequestConfig>,
  overrideUserKey?: string
) {
  const config: AxiosRequestConfig = {
    url: `${localTestDbUrl}${path}`,
    method,
    data,
    timeout: 5000,
    adapter: require('axios/lib/adapters/http'),
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
    ...options,
    headers: {
      Authorization: `Bearer ${makeJWT(overrideUserKey || entEngineerId)}`,
      ...options?.headers,
    },
  };

  return axios(config);
}

export function seedGql<T>(
  query: string,
  variables?: Record<string, unknown>,
  overrideUserKey?: string
) {
  return request(
    'POST',
    '/gql/query/public',
    { query, variables },
    {},
    overrideUserKey
  ).then((res: IGqlResponse<T>) => {
    const errors = res.data.errors;
    if (errors) {
      // eslint-disable-next-line no-console
      console.log('errors on seed query', {
        errors,
        query: query.split('\n')[0],
        variables,
        data: res.data.data,
      });
    }
    return res.data.data;
  });
}

export function seedMindProbe<T>(
  query: string,
  variables?: Record<string, unknown>,
  overrideUserKey?: string
) {
  return request(
    'POST',
    '/mind-probe/api/v4/graphql',
    { query, variables },
    {},
    overrideUserKey
  ).then((res: IGqlResponse<T>) => {
    const errors = res.data.errors;
    if (errors) {
      // eslint-disable-next-line no-console
      console.log('errors on seed query', { errors, query, variables });
    }
    return res.data.data;
  });
}

export function seedQuestionManagerApi({
  method = 'POST',
  path,
  data,
  options,
  overrideUserKey,
}: {
  method?: Method;
  path: string;
  data?: unknown;
  options?: Partial<AxiosRequestConfig>;
  overrideUserKey?: string;
}) {
  const config: AxiosRequestConfig = {
    url: `${localTestQmApiUrl}${path}`,
    method,
    data,
    timeout: 5000,
    adapter: require('axios/lib/adapters/http'),
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
    ...options,
    headers: {
      Authorization: `Bearer ${makeJWT(overrideUserKey || entEngineerId)}`,
      ...options?.headers,
    },
  };

  return axios(config);
}

export function seedFetch(path: string) {
  return request('GET', path);
}

export function seedPost(
  path: string,
  data: unknown,
  options?: Partial<AxiosRequestConfig>,
  overrideUserKey?: string
) {
  return request('POST', path, data, options, overrideUserKey);
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class TestIdHolder {
  questionsById = {};
  managerRoles = {};

  constructor() {
    this.questionsById = {};
    this.managerRoles = {};
  }
  async getManagerRoles() {
    if (isEmpty(this.managerRoles)) {
      const rolesRes: { data: Role[] } = await seedFetch(`/roles/all`);
      this.managerRoles = rolesRes.data.reduce((roleIdsByName, role) => {
        roleIdsByName[role.name] = role.id;
        return roleIdsByName;
      }, {});
    }
    return this.managerRoles as Record<string, string>;
  }
  async getQuestionsById() {
    if (isEmpty(this.questionsById)) {
      const allQuestionsRes: { data: { questions: IQuestionAdmin[] } } =
        await seedPost('/mind-probe/api/v3/admin/get-questions', {
          max_questions: 500,
        });
      this.questionsById = indexBy(
        prop('question_id'),
        allQuestionsRes.data.questions || []
      );
    }
    return this.questionsById as Record<string, IQuestionAdmin>;
  }
}
export const testIds = new TestIdHolder();
