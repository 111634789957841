import { lensPath, set } from 'ramda';

import { updatePollingProperty } from 'app/actions/polling';

export interface IPollingState {
  [companyId: string]: {
    learners?: boolean;
    learnerLifecycles?: boolean;
    enrollments?: boolean;
    unenrollments?: boolean;
  };
}

const initialState = {};

export default function polling(
  state: IPollingState = initialState,
  action: ReturnType<typeof updatePollingProperty>
): IPollingState {
  switch (action.type) {
    case 'UPDATE_POLLING_PROPERTY': {
      const { companyId, key, value } = action.payload;

      return set(lensPath([companyId, key]), value, state);
    }

    default: {
      return state;
    }
  }
}
