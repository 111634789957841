import { ChartName, IChartKeys } from 'app/types/charts';
import { path, pipe } from 'ramda';
import { startLoading, stopLoading } from '../../loading';

import { AppThunk } from 'app/types/shared';
import { IOption } from 'app/types/options';
import { addServerError } from 'app/actions/flash';
import { batch } from 'react-redux';
import { cacheCompanyPrograms } from '../../cache';
import { createAction } from 'app/utils/actions';
import { emcService } from '@emc/services';
import { isCacheValid } from 'app/utils/cache/is_valid';
import { loadingSelector } from 'app/selectors/session';

// Sync

export function updateChartProperty<N extends ChartName>(
  companyId: string,
  chartName: N,
  key: IChartKeys<N>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
) {
  return createAction('UPDATE_CHART_PROPERTY', {
    companyId,
    chartName,
    key,
    value,
  });
}

// Async

export function fetchChartPrograms(companyId: string): AppThunk {
  return (dispatch, getState) => {
    const isLoadingPrograms = pipe(
      loadingSelector,
      path(['COMPANY_PROGRAMS'])
    )(getState());

    if (
      isCacheValid(getState, ['cache', 'companyPrograms', companyId]) ||
      isLoadingPrograms
    ) {
      return null;
    }

    dispatch(startLoading('COMPANY_PROGRAMS'));

    return emcService
      .fetch(`/companies/${companyId}/programs/all`)
      .then((res) => {
        const data: IOption[] = res?.data;
        batch(() => {
          dispatch(updateChartProperty(companyId, 'general', 'programs', data));
          dispatch(cacheCompanyPrograms(companyId));
        });
      })
      .catch(() =>
        dispatch(addServerError({ message: 'Error Fetching Programs' }))
      )
      .then(() => dispatch(stopLoading('COMPANY_PROGRAMS')));
  };
}
