import 'dayjs/locale/fr';
import 'dayjs/locale/ar';

import dayjs, { Dayjs } from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import { daysSince } from './ranges';
import isoWeek from 'dayjs/plugin/isoWeek';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(isoWeek);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);

export const ALL_TIME_ISO = '2011-01-01T00:00:00Z';

export const SHORT_DATE_FORMAT = 'll';

class DateFormatter {
  constructor(locale: string) {
    this.resetLocale(locale);
  }

  resetLocale(locale: string) {
    dayjs.locale(locale);
  }

  dayOfTheWeek(dayIndex: number) {
    return dayjs().day(dayIndex).format('dddd');
  }

  long = (
    dateString: string | Dayjs | undefined | null,
    defaultReturn = '-'
  ) => {
    return dateString ? dayjs(dateString).format('LL') : defaultReturn;
  };

  monthAndYear = (
    dateString: string | Dayjs | undefined,
    defaultReturn = '-'
  ) => {
    return dateString ? dayjs(dateString).format('MMMM YYYY') : defaultReturn;
  };

  monthDayUTC = (
    dateString: string | Date | undefined,
    defaultReturn = '-'
  ) => {
    return dateString ? dayjs.utc(dateString).format('MMM D') : defaultReturn;
  };

  monthShort = (dateString: string | Date | undefined, defaultReturn = '-') => {
    return dateString ? dayjs(dateString).format('MMM') : defaultReturn;
  };

  numeric = (
    dateString: string | Date | Dayjs | undefined | null,
    defaultReturn = '-'
  ) => {
    return dateString ? dayjs(dateString).format('l') : defaultReturn;
  };

  numericUTC = (
    dateString: string | Dayjs | undefined,
    defaultReturn = '-'
  ) => {
    return dateString ? dayjs.utc(dateString).format('l') : defaultReturn;
  };

  short = (
    dateString: string | Date | Dayjs | undefined,
    defaultReturn = '-'
  ) => {
    return dateString
      ? dayjs(dateString).format(SHORT_DATE_FORMAT)
      : defaultReturn;
  };

  shortUTC = (
    dateString: string | Dayjs | Date | undefined | null,
    defaultReturn = '-'
  ) => {
    return dateString
      ? dayjs.utc(dateString).format(SHORT_DATE_FORMAT)
      : defaultReturn;
  };

  date = (
    dateString: string | Date | Dayjs | undefined,
    defaultReturn = '-'
  ) => {
    return dateString
      ? dayjs.utc(dateString).format('YYYY-MM-DD')
      : defaultReturn;
  };

  datetime = (dateString: string | Dayjs | undefined, defaultReturn = '-') => {
    return dateString ? dayjs(dateString).format('lll') : defaultReturn;
  };

  localTime = (dateString: string | undefined, defaultReturn = '-') => {
    if (dateString) {
      const date = new Date(dateString);
      if (date.toLocaleTimeString) {
        return date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
      }
    }
    return defaultReturn;
  };

  relativeTime = (
    dateString: string | Dayjs | Date | undefined,
    defaultReturn = '-'
  ) => {
    return dateString ? dayjs(dateString).fromNow() : defaultReturn;
  };
  longMonthDateAndYear = (
    dateString: string | Dayjs | Date | undefined,
    defaultReturn = '-'
  ) => {
    return dateString
      ? dayjs(dateString).format('MMMM D, YYYY')
      : defaultReturn;
  };
}

export const dateFormatter = new DateFormatter('en');

export function getDateFromUTC(dateStr: string | Date | undefined) {
  if (!dateStr) {
    return;
  }

  const utcDate = new Date(dateStr);

  return new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate()
  );
}

export function getIsoAgoString(daysAgo?: number | string, startOfDay = true) {
  if (!daysAgo) {
    return;
  }

  daysAgo = daysSince(daysAgo);

  let date = dayjs().utc().subtract(daysAgo, 'day');

  if (startOfDay) {
    date = date.startOf('day');
  }

  return date.toISOString();
}

export function getStartEndFromDateRange(
  duration: number,
  type: 'days' | 'weeks' | 'months'
) {
  const now = new Date();
  const startDate = dayjs(now)
    .subtract(duration, type)
    .startOf('day')
    .toISOString();

  const endDate = now.toISOString();

  return {
    startDate,
    endDate,
  };
}

export function isValidISOString(isoString: string | number | undefined) {
  return dayjs(isoString, 'YYYY-MM-DDTHH:mm:ss.SSSZ').isValid();
}

export function getDaysFromMilliseconds(milliseconds: number): number {
  const aDayInMs = 24 * 60 * 60 * 1000;
  return milliseconds / aDayInMs;
}
