import { ContractTerm, ResourceType, Status } from 'app/types/generated/emc';
import {
  ContractTermNormalizedEntities,
  contractTermNormSchema,
} from 'app/utils/normalize/contract';
import {
  ContractTermsReduxState,
  ContractsReduxState,
  EnterpriseCatalogReduxState,
  ResourcesReduxState,
} from 'app/reducer/data/types';
import {
  PaginationData,
  paginationDataSelector,
  paginationSelector,
} from '../pagination';
import {
  PaginationState,
  PaginationTypes,
} from '@emc/reducer/session/pagination';
import { identity, indexBy } from 'ramda';

import { IRootStore } from '@emc/reducer/types';
import { allResourcesSelector } from '../resources';
import { contractsSelector } from '../contracts';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { enterpriseCatalogSelector } from '../catalog';
import { filterOutAllTimeQueryParam } from 'app/utils/dates/defaults';
import { getQueryParams } from 'app/utils/router/query-params';

export const contractTermsSelector = (state: IRootStore) =>
  state.data.contractTerms;

export type ContractTermsSelectorResult = Record<string, ContractTerm>;
export const denormalizedContractTermsSelector = createSelector<
  [
    (state: IRootStore) => ContractTermsReduxState,
    (state: IRootStore) => ResourcesReduxState['all'],
    (state: IRootStore) => EnterpriseCatalogReduxState,
    (state: IRootStore) => ContractsReduxState
  ],
  ContractTermsSelectorResult
>(
  contractTermsSelector,
  allResourcesSelector,
  enterpriseCatalogSelector,
  contractsSelector,
  (allContractTerms, allResources, catalogPrograms, contracts) => {
    const entities: ContractTermNormalizedEntities = {
      contractTerms: allContractTerms,
      contracts,
      catalogPrograms,
      resources: allResources,
    };
    const allTermIds = Object.keys(allContractTerms);
    const denormalizedContractTerms = denormalize(
      allTermIds,
      [contractTermNormSchema],
      entities
    );
    return indexBy((term) => term.id, denormalizedContractTerms);
  }
);

export const contractTermsPaginationSelector = createSelector<
  [(state: IRootStore) => IRootStore],
  PaginationData & {
    status?: Status;
    companyId?: string;
    companyTypeId?: string;
    isExpired: boolean;
    programType?: ResourceType;
  }
>(identity, (state) => {
  const { companyId, companyTypeId, status, programType } = getQueryParams();

  const isExpired = status === 'expired';
  const statusParam = isExpired
    ? undefined
    : (filterOutAllTimeQueryParam(status) as Status);

  return {
    ...paginationDataSelector(state, {
      tableName: PaginationTypes.contractTermsOverview,
      defaultSizePerPage: 20,
      defaultOrderBy: 'title',
      defaultOrder: 'ASC',
    }),
    companyId: filterOutAllTimeQueryParam(companyId),
    companyTypeId: filterOutAllTimeQueryParam(companyTypeId),
    status: statusParam,
    isExpired,
    programType: programType
      ? (filterOutAllTimeQueryParam(programType) as ResourceType)
      : undefined,
  };
});

export const paginatedContractTermsSelector = createSelector<
  [
    (state: IRootStore) => ContractTermsSelectorResult,
    (state: IRootStore) => PaginationState
  ],
  ContractTerm[]
>(
  denormalizedContractTermsSelector,
  paginationSelector,
  (contractTerms, pagination) => {
    const contractTermIds =
      pagination[PaginationTypes.contractTermsOverview]?.pageIds || [];
    return contractTermIds.map(
      (contractTermId) => contractTerms[contractTermId]
    );
  }
);
