import {
  emcUserLearnersSelector,
  isUdacityStaffSelector,
} from 'app/selectors/me';
import { head, path, pipe } from 'ramda';

import { AuthorizedRoute } from './authorized-route';
import React from 'react';
import { RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const RedirectRoute: React.FC<RouteProps> = (props) => {
  const isUdacityStaff = useSelector(isUdacityStaffSelector);
  const userLearners = useSelector(emcUserLearnersSelector);
  const companySlug = pipe(head, path(['company', 'slug']))(userLearners);
  let redirectionRoute = '/unauthorized';

  if (companySlug) {
    redirectionRoute = `/c/${companySlug}`;
  }

  if (isUdacityStaff) {
    redirectionRoute = '/admin';
  }

  const augmentedProps = {
    ...props,
    redirectionRoute,
  };

  return <AuthorizedRoute {...augmentedProps} />;
};
