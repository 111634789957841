import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  onClick(e: React.MouseEvent<HTMLButtonElement>): void;
  disabled?: boolean;
  testId?: string;
  full?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

export const UnstyledButton: React.FC<Props> = ({
  children,
  className,
  onClick,
  testId,
  full,
  ...otherProps
}) => {
  return (
    <button
      className={classNames(styles['unstyled-btn'], className, {
        [styles.full]: full,
      })}
      onClick={onClick}
      type="button"
      data-testid={testId}
      {...otherProps}
    >
      {children}
    </button>
  );
};
