import {
  CompaniesReduxState,
  ContractTermsReduxState,
  ContractsReduxState,
  ResourcesReduxState,
} from 'app/reducer/data/types';
import { CompanyType, Contract } from 'app/types/generated/emc';
import {
  ContractNormalizedEntities,
  contractNormSchema,
} from 'app/utils/normalize/contract';
import {
  companiesSelector,
  companyTypesSelector,
  currentCompanyIdSelector,
} from '../companies/companies';
import {
  formatAndSortSelectOptions,
  getCompaniesFilter,
} from 'app/utils/format/select-options';
import { pathOr, values } from 'ramda';

import { IFilter } from 'app/types/options';
import { IRootStore } from '@emc/reducer/types';
import { PaginationTypes } from '@emc/reducer/session/pagination';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { loadingSelector } from '../session';
import { paginationSelector } from '../pagination';
import upperCase from 'lodash/upperCase';
import { validStatuses } from 'app/utils/data-types';

export const contractsSelector = (state: IRootStore) => state.data.contracts;

export const isLoadingContractsSelector = createSelector(
  loadingSelector,
  pathOr(false, ['CONTRACTS'])
);

export const companyContractsSelector = createSelector<
  [
    (state: IRootStore) => string,
    (state: IRootStore) => ContractsReduxState,
    (state: IRootStore) => ContractTermsReduxState,
    (state: IRootStore) => ResourcesReduxState['all']
  ],
  Contract[]
>(
  currentCompanyIdSelector,
  contractsSelector,
  (state) => state.data.contractTerms,
  (state) => state.data.resources.all,
  (companyId, contracts, contractTerms, resources) => {
    const contractsList = values(contracts);
    const contractIds: string[] = [];
    contractsList.forEach((c) => {
      if (c.companyId === companyId) {
        contractIds.push(c.id);
      }
    });
    const entities: ContractNormalizedEntities = {
      contracts,
      contractTerms,
      resources,
    };
    return denormalize(contractIds, [contractNormSchema], entities);
  }
);

export const paginatedContractsSelector = createSelector(
  contractsSelector,
  paginationSelector,
  (contracts, pagination) => {
    const contractIds =
      pagination[PaginationTypes.contractsOverview]?.pageIds || [];
    return contractIds.map((id) => contracts[id]);
  }
);

const statusFilterOption: IFilter = {
  id: 'status',
  label: 'Status',
  description: 'Status',
  options: validStatuses.map((status) => {
    return {
      label: status.label,
      id: status.value,
      value: upperCase(status.value),
    };
  }),
};

export const contractsFilterOptionsSelector = createSelector<
  [
    (state: IRootStore) => CompaniesReduxState,
    (state: IRootStore) => CompanyType[]
  ],
  IFilter[]
>(companiesSelector, companyTypesSelector, (companies, companyTypes) => {
  return [
    statusFilterOption,
    getCompaniesFilter(companies),
    {
      id: 'companyTypeId',
      label: 'Company Type',
      description: 'Company Type',
      options: formatAndSortSelectOptions('id', 'name')(companyTypes),
    },
  ];
});
