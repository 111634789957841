import { addGroups, removeGroup } from 'app/actions/groups';
import { mergeDeepRight, omit } from 'ramda';

import { GroupsReduxState } from './types';

export default function groups(
  state: GroupsReduxState = {},
  action: ReturnType<typeof addGroups> | ReturnType<typeof removeGroup>
): GroupsReduxState {
  switch (action.type) {
    case 'ADD_GROUPS': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return mergeDeepRight(state, action.payload as any);
    }

    case 'REMOVE_GROUP': {
      return omit([String(action.payload)])(state);
    }

    default: {
      return state;
    }
  }
}
