import { IAction } from 'app/utils/actions';
import { ISessionState } from './types';
import activeAssessments from './active-assessments';
import activeCompany from './active-company';
import autoEnrollment from './auto-enrollment';
import batchProgress from './batch-progress';
import catalogAccess from './catalog-access';
import charts from './charts';
import companyLearners from './company-learners';
import contracts from './contracts';
import enrollableContractTerms from './enrollable-contract-terms';
import flash from './flash';
import loading from './loading';
import mindProbe from './mind-probe';
import newLearners from './new-learners';
import pagination from './pagination';
import { persistCombineReducers } from 'redux-persist';
import polling from './polling';
import selections from './selections';
import storage from 'redux-persist/es/storage';
import visibility from './visibility';

const persistConfig = {
  key: 'session',
  storage,
  whitelist: [
    'activeAssessments',
    'charts',
    'newLearners',
    'batchProgress',
    'visibility',
  ],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default persistCombineReducers<ISessionState, IAction<any, any>>(
  persistConfig,
  {
    activeCompany,
    activeAssessments,
    autoEnrollment,
    batchProgress,
    catalogAccess,
    charts,
    companyLearners,
    contracts,
    enrollableContractTerms,
    flash,
    loading,
    mindProbe,
    newLearners,
    pagination,
    polling,
    selections,
    visibility,
  }
);
