import React, { useEffect, useState } from 'react';

import CONFIG from 'app/config';
import { isTesting } from 'app/test/utils';
import { isUdacityStaffSelector } from 'app/selectors/me';
import { useCurrentCompanySlug } from 'app/hooks/use-current-company-slug';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const MANAGE_WEB_IFRAME_ID = 'manage-web-iframe';
const BLANK_ROUTE = '/blank';

export const ManageWeb: React.FC = () => {
  const routeHistory = useHistory();
  const isStaff = useSelector(isUdacityStaffSelector);
  const [url, setUrl] = useState<string | null>(null);
  const [height, setHeight] = useState('1px');
  const [width, setWidth] = useState('1px');
  const currentCompanySlug = useCurrentCompanySlug();
  const { route, iframeHeight, iframeWidth } = getManageWebRoute(
    routeHistory.location.pathname,
    currentCompanySlug,
    isStaff
  );

  useEffect(() => {
    routeToManageWeb(route);
    setHeight(iframeHeight);
    setWidth(iframeWidth);
    if (url === null && route !== BLANK_ROUTE) {
      setUrl(`${CONFIG.REACT_APP_MANAGE_WEB_URL}${route}?navigation=off`);
    }
  }, [route, iframeHeight, iframeWidth, url]);

  if (isTesting || !url) return null;

  return (
    <iframe
      id={MANAGE_WEB_IFRAME_ID}
      title="Embedded EMC"
      src={url}
      style={{ height, width, border: 0 }}
      allow="clipboard-write"
    ></iframe>
  );
};

function routeToManageWeb(path: string) {
  const emcFrame = document.getElementById(
    MANAGE_WEB_IFRAME_ID
  ) as HTMLIFrameElement;
  emcFrame?.contentWindow?.postMessage(
    { type: 'nav', path },
    CONFIG.REACT_APP_MANAGE_WEB_URL as string
  );
}

const inactiveRoute = {
  route: BLANK_ROUTE,
  iframeHeight: '1px',
  iframeWidth: '1px',
};

/**
 * Check if view exists in manage-web and if it does, provide the equivalent url
 * @param {string} path - the path in emc-web to check if there is an equivalent
 * @param {NormalizedCompany} company - the company object if the url requires it
 * @returns {string | null} if null, it means company information needs to be
 * loaded before being able to provide the equivalent url
 */
function getManageWebRoute(
  path: string,
  companySlug: string | undefined,
  isStaff: boolean
): {
  route: string;
  iframeHeight: string;
  iframeWidth: string;
} {
  if (path.match(/\/admin\/companies\/.+\/contracts\/add/)) {
    if (companySlug)
      return {
        route: `/c/${companySlug}/settings/contracts/create`,
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    return inactiveRoute;
  }

  if (path.match(/\/admin\/companies\/.+\/settings/)) {
    if (companySlug)
      return {
        route: `/c/${companySlug}/admin/company-settings`,
        iframeHeight: 'calc(99% - 200px)',
        iframeWidth: '100%',
      };
    return inactiveRoute;
  }

  if (path.match(/\/admin\/companies\/[0-9]+$/)) {
    if (companySlug)
      return {
        route: `/c/${companySlug}/settings/contracts`,
        iframeHeight: 'calc(99% - 200px)',
        iframeWidth: '100%',
      };
    return inactiveRoute;
  }

  if (path.match(/\/admin\/companies\/.+\/catalog-settings/)) {
    if (companySlug)
      return {
        route: `/c/${companySlug}/admin/catalog`,
        iframeHeight: 'calc(99% - 200px)',
        iframeWidth: '100%',
      };
    return inactiveRoute;
  }

  const contractEditMatch = path.match(
    /\/admin\/companies\/.+\/contracts\/([0-9]+)\/edit/
  );
  if (contractEditMatch) {
    const contractId = contractEditMatch[1];
    if (companySlug)
      return {
        route: `/c/${companySlug}/settings/contracts/${contractId}/edit`,
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    return inactiveRoute;
  }
  const contractTermsAddMatch = path.match(
    /\/admin\/companies\/.+\/contracts\/(.+)\/add-contract-terms/
  );
  if (contractTermsAddMatch) {
    const contractId = contractTermsAddMatch[1];
    if (companySlug)
      return {
        route: `/c/${companySlug}/settings/contracts/${contractId}/add-contract-terms`,
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    return inactiveRoute;
  }

  const parentContractEditMatch = path.match(
    /\/pc\/.+\/contracts\/([0-9]+)\/edit/
  );
  if (parentContractEditMatch) {
    const contractId = parentContractEditMatch[1];
    if (companySlug)
      return {
        route: `/pc/${companySlug}/contracts/${contractId}/edit`,
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    return inactiveRoute;
  }

  const parentContractTermsAddMatch = path.match(
    /\/pc\/.+\/contracts\/(.+)\/add-contract-terms/
  );
  if (parentContractTermsAddMatch) {
    const contractId = parentContractTermsAddMatch[1];
    if (companySlug)
      return {
        route: `/pc/${companySlug}/contracts/${contractId}/add-contract-terms`,
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    return inactiveRoute;
  }

  const companyEditMatch = path.match(/\/admin\/companies\/[0-9]+\/edit/);
  if (companyEditMatch) {
    if (companySlug)
      return {
        route: `/c/${companySlug}/edit`,
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    return inactiveRoute;
  }

  const assessmentEditMatch = path.match(
    new RegExp(`/c/${companySlug}/assessments/([0-9]+)/edit`)
  );
  if (assessmentEditMatch) {
    const assessmentId = assessmentEditMatch[1];
    return {
      route: `/c/${companySlug}/admin/assessments/${assessmentId}/edit`,
      iframeHeight: '99%',
      iframeWidth: '100%',
    };
  }

  const cohortSettingsMatch = path.match(
    new RegExp(`/c/${companySlug}/cohorts/([0-9]+)/view/settings`)
  );
  if (cohortSettingsMatch) {
    const cohortId = cohortSettingsMatch[1];
    return {
      route: `/c/${companySlug}/manage/cohorts/${cohortId}/settings`,
      iframeHeight: 'calc(100% - 250px)',
      iframeWidth: '100%',
    };
  }

  const cohortProgramsMatch = path.match(
    new RegExp(`/c/${companySlug}/cohorts/([0-9]+)/view/programs`)
  );
  if (cohortProgramsMatch) {
    const cohortId = cohortProgramsMatch[1];
    return {
      route: `/c/${companySlug}/manage/cohorts/${cohortId}/programs`,
      iframeHeight: 'calc(100% - 250px)',
      iframeWidth: '100%',
    };
  }

  const enrollmentBatchMatch = path.match(/\/learners\/batches\/(.+)/);
  if (enrollmentBatchMatch) {
    const batchId = enrollmentBatchMatch[1];
    return {
      route: `/c/${companySlug}/manage/history/batches/${batchId}`,
      iframeHeight: 'calc(100% - 300px)',
      iframeWidth: '100%',
    };
  }

  switch (path) {
    case '/admin/roles': {
      return {
        route: '/admin/admin-roles',
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    }
    case '/admin/companies/all': {
      return {
        route: '/admin/companies',
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    }
    case '/admin/parent-companies/all': {
      return {
        route: '/admin/parent-companies',
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    }
    case `/pc/${companySlug}/contracts`: {
      return {
        route: `/pc/${companySlug}/contracts`,
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    }
    case `/pc/${companySlug}/contracts/create`: {
      return {
        route: `/pc/${companySlug}/contracts/create`,
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    }
    case `/pc/${companySlug}/user-permissions`: {
      return {
        route: `/pc/${companySlug}/user-permissions`,
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    }
    case `/c/${companySlug}/user-permissions`: {
      return {
        route: `/c/${companySlug}/settings/user-permissions`,
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    }

    case `/c/${companySlug}/assessments`: {
      return {
        route: `/c/${companySlug}/admin/assessments`,
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    }

    case `/c/${companySlug}/cohorts`: {
      return {
        route: `/c/${companySlug}/manage/cohorts`,
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    }

    case `/c/${companySlug}/cohorts/add`: {
      return {
        route: `/c/${companySlug}/manage/cohorts/add`,
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    }

    case `/c/${companySlug}/cohorts/add-learners`: {
      return {
        route: `/c/${companySlug}/manage/cohorts/add-learners`,
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    }

    case `/c/${companySlug}/learners/groups`: {
      return {
        route: `/c/${companySlug}/manage/groups`,
        iframeHeight: 'calc(100% - 300px)',
        iframeWidth: '100%',
      };
    }

    case `/c/${companySlug}/learners/history`: {
      return {
        route: `/c/${companySlug}/manage/history`,
        iframeHeight: 'calc(100% - 300px)',
        iframeWidth: '100%',
      };
    }

    case `/c/${companySlug}/settings/contracts`: {
      return {
        route: `/c/${companySlug}/settings/contracts`,
        iframeHeight: 'calc(100% - 248px)',
        iframeWidth: '100%',
      };
    }

    case `/c/${companySlug}/settings/sso`: {
      if (isStaff) {
        return {
          route: `/c/${companySlug}/admin/sso`,
          iframeHeight: 'calc(100% - 248px)',
          iframeWidth: '100%',
        };
      }
      return {
        route: `/c/${companySlug}/settings/sso`,
        iframeHeight: 'calc(100% - 248px)',
        iframeWidth: '100%',
      };
    }

    case `/c/${companySlug}/settings/tokens`: {
      return {
        route: `/c/${companySlug}/settings/api-tokens`,
        iframeHeight: 'calc(100% - 248px)',
        iframeWidth: '100%',
      };
    }

    case '/admin/companies/add':
    case '/admin/parent-companies/add':
    case `/pc/${companySlug}/home`:
    case `/pc/${companySlug}/edit`:
    case '/admin/configurations':
    case '/admin/discourse':
    case '/admin/enterprise-catalog': {
      return {
        route: path,
        iframeHeight: '99%',
        iframeWidth: '100%',
      };
    }
    default:
      return inactiveRoute;
  }
}
