import {
  CatalogProgram,
  Contract,
  ContractTerm,
  ContractTermEnrollmentCount,
  Resource,
} from 'app/types/generated/emc';
import {
  ContractTermsReduxState,
  ContractsReduxState,
  EnterpriseCatalogReduxState,
  ResourcesReduxState,
} from 'app/reducer/data/types';

import { Normalized } from 'app/types/shared';
import { schema } from 'normalizr';

export const catalogProgramNormSchema = new schema.Entity<CatalogProgram>(
  'catalogPrograms',
  {},
  { idAttribute: 'programKey' }
);

export const resourceNormSchema = new schema.Entity<Resource>('resources', {
  catalogProgram: catalogProgramNormSchema,
});

export type ResourceNormalizedEntities = {
  resources?: ResourcesReduxState['all'];
  catalogPrograms?: EnterpriseCatalogReduxState;
};

export const contractTermContractNormSchema = new schema.Entity<Contract>(
  'contracts'
);

export const contractTermNormSchema = new schema.Entity<ContractTerm>(
  'contractTerms',
  {
    contract: contractTermContractNormSchema,
    resource: resourceNormSchema,
  }
);

export const contractTermEnrollmentCountsNormSchema =
  new schema.Entity<ContractTermEnrollmentCount>(
    'contractTermEnrollmentCounts',
    {},
    { idAttribute: 'contractTermId' }
  );

export const enrollableContractTermNormSchema = new schema.Entity<ContractTerm>(
  'enrollableContractTerms',
  { resource: resourceNormSchema }
);

export type ContractTermNormalizedEntities = ResourceNormalizedEntities & {
  contractTerms?: ContractTermsReduxState;
  contracts?: ContractsReduxState;
};

export type ContractNormalizedEntities = ContractTermNormalizedEntities & {
  enrollableContractTerms?: ContractTermsReduxState;
  contractTermEnrollmentCounts?: Normalized<ContractTermEnrollmentCount>;
};

export const contractNormSchema = new schema.Entity<Contract>('contracts', {
  contractTerms: { edges: [{ node: contractTermNormSchema }] },
  draftTerms: { edges: [{ node: contractTermNormSchema }] },
  suspendedTerms: { edges: [{ node: contractTermNormSchema }] },
  enrollmentCounts: { breakdown: [contractTermEnrollmentCountsNormSchema] },
  enrollableContractTerms: [enrollableContractTermNormSchema],
});
