import React, { useCallback } from 'react';
import {
  getQueryParams,
  updateQueryParam,
} from 'app/utils/router/query-params';

import { Heading } from '@udacity/veritas-components';
import classNames from 'classnames';
import { currentCompanySelector } from 'app/selectors/companies/companies';
import styles from './styles.module.scss';
import { track } from 'app/utils/analytics';
import { useSelector } from 'react-redux';

export type NavOption = {
  label: string;
  queryLabel: string;
  LabelComponent?: React.FC;
  child?: React.ReactNode;
};

type Props = {
  name?: string;
  navOptions?: NavOption[];
  className?: string;
  removePadding?: boolean;
  footer?: React.ReactNode;
};

export const Section: React.FC<Props> = ({
  name = 'section',
  navOptions = [],
  children,
  className,
  removePadding,
  footer,
}) => {
  const company = useSelector(currentCompanySelector);
  const queryParams = getQueryParams();

  const handleNavClick = useCallback(
    (queryLabel, label) => (): void => {
      track('SectionNavigationClicked', {
        companyId: company.id,
        companyName: company.name,
        sectionName: name,
        navigationLabel: label,
      });
      updateQueryParam(name, queryLabel);
    },
    [name, company.id, company.name]
  );

  return (
    <div
      className={classNames(className, styles.section, {
        [styles['remove-padding']]: removePadding || navOptions.length,
      })}
    >
      <div>
        {navOptions.map((navOption, idx) => {
          const { label, queryLabel, LabelComponent } = navOption;
          const queryParam = queryParams[name];
          const isActive =
            queryParam === queryLabel || (!queryParam && idx === 0);

          return (
            <button
              className={classNames(styles['nav-option-container'], {
                [styles['active-nav-option-container']]: isActive,
              })}
              key={idx}
              onClick={handleNavClick(queryLabel, label)}
            >
              <Heading color={isActive ? 'black' : 'silver'} size="h6">
                {label}
              </Heading>
              {LabelComponent && (
                <div key={label}>
                  <LabelComponent />
                </div>
              )}
            </button>
          );
        })}
      </div>
      {children}
      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  );
};
