import { lensPath, set } from 'ramda';

import { Status } from 'app/types/generated/emc';
import { sessionContractUpdateProperty } from 'app/actions/contracts';

export interface IContractsSessionState {
  [contractId: string]: {
    [key in Status]: {
      totalContractTermCount: number;
      contractTermIds: string[];
    };
  };
}

export default function contracts(
  state: IContractsSessionState = {},
  action: ReturnType<typeof sessionContractUpdateProperty>
): IContractsSessionState {
  switch (action.type) {
    case 'SESSION_CONTRACT_UPDATE_PROPERTY': {
      const { contractId, status, key, value } = action.payload;

      return set(lensPath([contractId, status, key]), value, state);
    }

    default: {
      return state;
    }
  }
}
