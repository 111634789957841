import { AppThunk, IGqlResponse } from 'app/types/shared';
import { Company, EnrollableContractTermsQuery } from 'app/types/generated/emc';
import {
  CompanyNormalizedEntities,
  companyNormSchema,
} from 'app/utils/normalize/company';
import {
  emcLearnerForCurrentCompanySelector,
  hasCompanyAdminPermissionsOrHigherSelector,
} from 'app/selectors/me';
import { startLoading, stopLoading } from '../loading';

import { addContractTerms } from './contract-terms';
import { addContracts } from '../contracts';
import { addResources } from '../resources';
import { addServerError } from '../flash';
import { batch } from 'react-redux';
import { cacheEnrollableContractTerms } from '../cache';
import { createAction } from 'app/utils/actions';
import { emcService } from '@emc/services';
import { getGraphQLErrorMessage } from 'app/utils/errors';
import { getQueryString } from 'app/utils/graphql/serializer';
import { isCacheValid } from 'app/utils/cache/is_valid';
import { keys } from 'ramda';
import { loader } from 'graphql.macro';
import { loadingSelector } from 'app/selectors/session';
import { normalize } from 'normalizr';

// Sync
export function setEnrollableContractTermIds(
  companyId: string,
  contractTermIds: string[]
) {
  return createAction('SET_ENROLLABLE_CONTRACT_TERMS', {
    companyId,
    contractTermIds,
  });
}

// Async

export function fetchEnrollableContractTerms(
  companyId: string,
  skipCache?: boolean
): AppThunk {
  return (dispatch, getState) => {
    const alreadyCached =
      !skipCache &&
      isCacheValid(getState, ['cache', 'enrollableContractTerms', companyId]);

    if (loadingSelector(getState())['CONTRACT_TERM'] || alreadyCached) {
      return Promise.resolve();
    }
    dispatch(startLoading('CONTRACT_TERM'));

    const isCurrentCompanyLearner = Boolean(
      emcLearnerForCurrentCompanySelector(getState())
    );
    const isStaffOrAdmin = hasCompanyAdminPermissionsOrHigherSelector(
      getState()
    );
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/get-enrollable-contract-terms.gql')
        ),
        {
          companyId,
          isAuthorized: isStaffOrAdmin || isCurrentCompanyLearner,
        }
      )
      .then((res: IGqlResponse<EnrollableContractTermsQuery>) => {
        const company = res.data.data?.company;
        if (!company) {
          const error = getGraphQLErrorMessage(res.data.errors);
          throw new Error(
            `Enrollable Contract Terms could not be fetched - ${
              error ? error : 'unknown error'
            }`
          );
        }
        const normalizedData = normalize<Company, CompanyNormalizedEntities>(
          company,
          companyNormSchema
        );

        const {
          entities: { contracts, enrollableContractTerms, resources },
        } = normalizedData;

        batch(() => {
          if (contracts) {
            dispatch(addContracts(contracts));
          }
          if (enrollableContractTerms) {
            dispatch(addContractTerms(enrollableContractTerms));
            dispatch(
              setEnrollableContractTermIds(
                companyId,
                keys(enrollableContractTerms)
              )
            );
            dispatch(cacheEnrollableContractTerms(companyId));
            if (resources) {
              dispatch(addResources(companyId, resources));
            }
          } else {
            dispatch(setEnrollableContractTermIds(companyId, []));
          }
        });
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('CONTRACT_TERM')));
  };
}
