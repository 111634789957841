import { ALL, CUSTOM } from '../../constants';
import { ALL_TIME_ISO, isValidISOString } from '../format';
import {
  DateRangeTypes,
  IDateRangeData,
} from 'app/selectors/charts/learner-activity/util';
import { SEVEN_DAYS, THIRTY_DAYS, defaultDateRanges } from '../defaults';
import {
  defaultDateLabel,
  getDateStreamChartData,
  getDynamicDateRange,
  getDynamicDateRangeFromDaysAgo,
} from '../graph';
import { head, map, prop } from 'ramda';

import { IChartData } from 'app/types/charts';
import { ICompanyDashboardChartState } from 'app/reducer/session/charts';
import { IOption } from 'app/types/options';
import dayjs from 'dayjs';

export function formatDateRangeOptions(
  selectedOption: 'all' | 'custom' | number = SEVEN_DAYS,
  dateRanges = defaultDateRanges
) {
  const options = map(formatDateRangeOption)(dateRanges);
  const selected = formatDateRangeOption(selectedOption);

  return {
    options,
    selected,
  };
}

export function formatDateRangeOption(value: number | 'all' | 'custom') {
  const { dateRangeValue, dateRangeType } = isValidISOString(value)
    ? getDynamicDateRange([new Date(value), new Date()])
    : getDynamicDateRangeFromDaysAgo(value);

  const chartData: IChartData[] = prop('chartData')(
    getDateStreamChartData([], { dateRangeValue, dateRangeType })
  );

  return formatOption(value, dateRangeValue, dateRangeType, chartData);
}

export type DateRangeOption = {
  value: string | number;
  shortLabel: string;
  label: string;
  minIso?: string;
  maxIso: string;
  minDate: string;
  maxDate: string;
  bucketType: IDateRangeData;
};

function formatOption(
  value: string | number,
  dateRangeValue: string | number,
  dateRangeType: DateRangeTypes,
  chartData: IChartData[]
): DateRangeOption {
  const minValue = head(chartData);
  const minIso = minValue?.iso;
  const maxIso = dayjs().toISOString();
  const minDate = getDateLabel(value, minIso);
  const maxDate = getDateLabel(value, maxIso);
  const label = getLabelFromDaysAgo(value);
  const shortLabel = getShortLabelFromDaysAgo(value);

  return {
    value,
    shortLabel,
    label,
    minIso,
    maxIso,
    minDate,
    maxDate,
    bucketType: { dateRangeValue, dateRangeType },
  };
}

const getDateLabel = (value: number | string, iso?: string) => {
  if (value === ALL || value === CUSTOM) {
    return '';
  }

  return dayjs(iso).format(defaultDateLabel);
};

function getShortLabelFromDaysAgo(daysAgo: string | number) {
  if (daysAgo === ALL) {
    return ALL;
  }

  if (daysAgo === CUSTOM) {
    return CUSTOM;
  }

  daysAgo = daysSince(daysAgo);

  return `${daysAgo} days`;
}

function getLabelFromDaysAgo(daysAgo: string | number) {
  if (daysAgo === ALL) {
    return 'All Time';
  }

  if (daysAgo === CUSTOM) {
    return 'Custom...';
  }

  daysAgo = daysSince(daysAgo);

  return `Last ${daysAgo} Days`;
}

export function getFilterPillDateRangeOptions(
  complementId?: string
): IOption[] {
  return defaultDateRanges.map((daysAgo) => {
    const isCustom = daysAgo === CUSTOM;

    return {
      id: `date-range-${daysAgo}`,
      label: getLabelFromDaysAgo(daysAgo),
      complementId,
      value: String(daysAgo),
      shouldIncludeComplement: Boolean(complementId && isCustom),
      dateRange: isCustom,
    };
  });
}

export function getISOFromDaysAgo(daysAgo: string | number): string {
  if (isValidISOString(daysAgo)) {
    return daysAgo as string;
  }

  if (daysAgo === ALL) {
    return ALL_TIME_ISO;
  }

  if (daysAgo === 'now') {
    return dayjs().toISOString();
  }

  return dayjs()
    .subtract(daysAgo as number, 'day')
    .startOf('day')
    .toISOString();
}

export function daysSince(daysAgo: string | number) {
  if (Number.isFinite(Number(daysAgo))) {
    return Number(daysAgo);
  }

  daysAgo = getISOFromDaysAgo(daysAgo);

  const durationInMilliseconds =
    dayjs().startOf('day').valueOf() - dayjs(daysAgo).startOf('day').valueOf();

  const durationAsDays = durationInMilliseconds / 86_400_000;
  return Math.ceil(durationAsDays);
}

export enum ChartDateRanges {
  learnerActivityDateRange = 'learnerActivityDateRange',
  programHealthDateRange = 'programHealthDateRange',
  programsOverviewDateRange = 'programsOverviewDateRange',
  projectCompletionsDateRange = 'projectCompletionsDateRange',
  projectsOverviewDateRange = 'projectsOverviewDateRange',
}

export function getDefaultDateValue(
  key: ChartDateRanges,
  defaultValue: 'all' | 'custom' | number = THIRTY_DAYS
) {
  return (data: ICompanyDashboardChartState | undefined) => {
    const value = (data && data[key]) || defaultValue;
    const isValidValue =
      value === ALL ||
      value === CUSTOM ||
      Number.isFinite(Number(value)) ||
      isValidISOString(value);

    return isValidValue ? value : defaultValue;
  };
}
