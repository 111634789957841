import {
  ICompanyLearnersSession,
  ILearnersSessionState,
} from '@emc/reducer/session/company-learners';
import {
  PaginationData,
  paginationDataSelector,
  paginationSelector,
} from '../pagination';
import {
  PaginationInfo,
  PaginationState,
  PaginationTypes,
} from '@emc/reducer/session/pagination';

import { IRootStore } from '@emc/reducer/types';
import { createSelector } from 'reselect';
import { currentCompanyIdSelector } from './companies';
import { propOr } from 'ramda';

export const allCompaniesLearnersSessionSelector = (state: IRootStore) =>
  state.session.companyLearners;

export const learnerPaginationSessionSelector = createSelector<
  [(state: IRootStore) => PaginationState],
  PaginationInfo
>(paginationSelector, propOr({}, PaginationTypes.companyLearners));

export const companyLearnersSessionDataSelector = createSelector<
  [(state: IRootStore) => ILearnersSessionState, (state: IRootStore) => string],
  ICompanyLearnersSession
>(
  allCompaniesLearnersSessionSelector,
  currentCompanyIdSelector,
  (companyLearnerSessionData, companyId) => {
    return companyLearnerSessionData[companyId] || {};
  }
);

export const companyLearnersPaginationSelector = createSelector<
  [
    (
      state: IRootStore,
      queryParams: Record<string, string>
    ) => { state: IRootStore; queryParams: Record<string, string> }
  ],
  PaginationData & {
    hasAssessmentResultAfter?: string;
    learningPathId?: string;
    companyGraphLearningPathId?: string;
    groupId?: string;
    nominationType?: string;
  }
>(
  (state, queryParams) => ({
    state,
    queryParams,
  }),
  ({ state, queryParams }) => {
    return {
      ...paginationDataSelector(state, {
        tableName: PaginationTypes.companyLearners,
      }),
      hasAssessmentResultAfter: queryParams.hasAssessmentResultAfter,
      learningPathId: queryParams.learningPathId,
      companyGraphLearningPathId: queryParams.companyGraphLearningPathId,
      groupId: queryParams.learnerGroup,
      nominationType: queryParams.nominationType,
    };
  }
);
