import { AppThunk, IGqlResponse } from 'app/types/shared';
import {
  Company,
  GetEnrollableResourcesQuery,
  GetResourceByIdQuery,
  GetTermResourcesForContractQuery,
  UpdateResourceMutation,
  UpdateResourceMutationVariables,
} from 'app/types/generated/emc';
import {
  CompanyNormalizedEntities,
  companyNormSchema,
} from 'app/utils/normalize/company';
import { PaginationProps, getPaginationData } from 'app/selectors/pagination';
import { addFlashMessage, addServerError } from './flash';
import {
  getPaginatedItems,
  getQueryString,
} from 'app/utils/graphql/serializer';
import { startLoading, stopLoading } from './loading';

import { NormalizedResource } from 'app/types/contracts';
import { PaginationTypes } from '@emc/reducer/session/pagination';
import { ResourcesReduxState } from 'app/reducer/data/types';
import { batch } from 'react-redux';
import { createAction } from 'app/utils/actions';
import { emcService } from '@emc/services';
import { fetchProgramByKey } from './programs';
import { getGraphQLErrorMessage } from 'app/utils/errors';
import { loader } from 'graphql.macro';
import { normalize } from 'normalizr';
import { updatePagination } from './reports';

// Sync

export function addResources(
  companyId: string,
  resources: ResourcesReduxState['all']
) {
  return createAction('ADD_RESOURCES', { companyId, resources });
}

// Async

export function updateResource(
  companyId: string,
  data: UpdateResourceMutationVariables
): AppThunk {
  return (dispatch): Promise<void> => {
    dispatch(startLoading('RESOURCES'));
    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/update-resource.gql')), data)
      .then((res: IGqlResponse<UpdateResourceMutation>) => {
        const resourceRes = res.data.data?.updateResource;
        const resource = resourceRes?.resource;

        if (!resource || resourceRes?.ok !== true) {
          throw new Error(getGraphQLErrorMessage(res.data.errors));
        }
        const resourceId = resource.id;

        const resources = {
          [resourceId]: resource as NormalizedResource,
        };

        batch(() => {
          dispatch(addResources(companyId, resources));
          dispatch(fetchProgramByKey(companyId, resource.data));
          dispatch(addFlashMessage('success', 'Resource successfully updated'));
          dispatch(stopLoading('RESOURCES'));
        });
      })
      .catch((error) => {
        dispatch(stopLoading('RESOURCES'));
        dispatch(addServerError(error));
      });
  };
}

export function fetchEnrollableResources(
  companyId: string,
  paginationProps?: PaginationProps
): AppThunk<Promise<Record<string, NormalizedResource>>> {
  return (dispatch): Promise<Record<string, NormalizedResource>> => {
    dispatch(startLoading('RESOURCES'));
    const paginationParams = paginationProps
      ? getPaginationData(paginationProps)
      : {};
    return emcService
      .gql(
        getQueryString(loader('@emc/queries/emc/get-enrollable-resources.gql')),
        {
          companyId,
          ...paginationParams,
          skipResourceData: !paginationProps,
        }
      )
      .then((res: IGqlResponse<GetEnrollableResourcesQuery>) => {
        const normalizedData = normalize<Company, CompanyNormalizedEntities>(
          res.data.data?.company,
          companyNormSchema
        );
        const company = (normalizedData.entities.companies || {})[companyId];

        const resourceIds = getPaginatedItems(company.resources);
        const resources = normalizedData.entities.resources || {};

        batch(() => {
          if (paginationProps)
            dispatch(
              updatePagination(
                PaginationTypes.resources,
                company?.resources?.totalCount || 0,
                resourceIds
              )
            );
          dispatch(addResources(companyId, resources));
          dispatch(stopLoading('RESOURCES'));
        });
        return resources;
      })
      .catch((error) => {
        dispatch(stopLoading('RESOURCES'));
        dispatch(addServerError(error));
        return {};
      });
  };
}

export function fetchTermResourcesForContract(contractId: string) {
  return emcService
    .gql(
      getQueryString(
        loader('@emc/queries/emc/get-term-resources-for-contract.gql')
      ),
      { contractId }
    )
    .then((res: IGqlResponse<GetTermResourcesForContractQuery>) => {
      const contractTerms = getPaginatedItems(
        res.data.data?.contract?.contractTerms
      );

      return contractTerms;
    });
}

export function fetchResourceById(id: string): AppThunk<Promise<void>> {
  return (dispatch): Promise<void> => {
    dispatch(startLoading('RESOURCES'));
    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/get-resource.gql')), { id })
      .then((res: IGqlResponse<GetResourceByIdQuery>) => {
        const resource = res.data.data?.resource;

        batch(() => {
          if (resource) {
            dispatch(
              addResources('all', { [id]: resource as NormalizedResource })
            );
          }
          dispatch(stopLoading('RESOURCES'));
        });
      })
      .catch((error) => {
        dispatch(stopLoading('RESOURCES'));
        dispatch(addServerError(error));
      });
  };
}
