import { EnrollmentCohortsReduxState } from './types';
import { addEnrollmentCohorts } from 'app/actions/enrollment-cohorts';
import { mergeDeepRight } from 'ramda';

export default function enrollmentCohorts(
  state: EnrollmentCohortsReduxState = {},
  action: ReturnType<typeof addEnrollmentCohorts>
): EnrollmentCohortsReduxState {
  switch (action.type) {
    case 'ADD_ENROLLMENT_COHORTS': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return mergeDeepRight(state, action.payload as any);
    }

    default: {
      return state;
    }
  }
}
