import { ProgressBarProps, Text, Tooltip } from '@udacity/veritas-components';

import React from 'react';
import { isNil } from 'ramda';
import styles from './styles.module.scss';

type Props = {
  percentage: number;
  id: string;
  label: string;
  hiddenLabel?: boolean;
  variant?: ProgressBarProps['variant'];
  total?: number;
  value?: number;
};

export const ProgressBar: React.FC<Props> = ({
  percentage,
  value,
  total,
  label,
  hiddenLabel,
  variant = 'information',
  id,
}) => {
  return (
    <div>
      {!isNil(value) && !isNil(total) ? (
        <Tooltip
          content={
            <div className={styles.tooltip}>
              <Text size="sm" spacing="half">
                {value}/{total}
              </Text>
              <Text size="xs">{label}</Text>
            </div>
          }
          inverse
          trigger={
            <VeritasStyledProgressBar
              id={id}
              value={percentage}
              variant={variant}
              label={label}
              hiddenLabel={hiddenLabel}
            />
          }
        />
      ) : (
        <VeritasStyledProgressBar
          id={id}
          value={percentage}
          variant={variant}
          label={label}
          hiddenLabel={hiddenLabel}
        />
      )}
    </div>
  );
};

const VeritasStyledProgressBar: React.FC<{
  id: string;
  value: number;
  variant: ProgressBarProps['variant'];
  hiddenLabel?: boolean;
  label: string;
}> = ({ variant, value, label, hiddenLabel, id }) => {
  return (
    <div
      className={`vds-progress-bar vds-progress-bar--center vds-progress-bar--sm vds-progress-bar--${variant}`}
    >
      <progress
        value={value}
        max="100"
        id={id}
        aria-label={hiddenLabel ? label : undefined}
        tabIndex={0}
      />
      {!hiddenLabel && (
        <label htmlFor={id} className="vds-progress-bar__label">
          {label}
        </label>
      )}
    </div>
  );
};
