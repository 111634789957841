import { checkCacheValid } from 'redux-cache';
import { path } from 'ramda';

export const isCacheValid = (getState, reducerKey = []) =>
  checkCacheValid(getState, reducerKey, { accessStrategy });

// Util

function accessStrategy(state, reducerKey = [], cacheKey) {
  return path([...reducerKey, cacheKey])(state);
}
