import {
  BatchProgressKeys,
  updateBatchProgress,
  updatePollingProperty,
} from 'app/actions/polling';
import { Heading, Loading, Text } from '@udacity/veritas-components';
import { IconChecked, IconClose, IconWarning } from '@udacity/veritas-icons';
import React, { useEffect } from 'react';
import { batch, useSelector } from 'react-redux';
import {
  companyBatchProgressSelector,
  companyPollingSelector,
  currentCompanyIdSelector,
} from 'app/selectors/companies/companies';

import { PollingProgress } from 'app/types/shared';
import { ProgressBar } from '.';
import { UnstyledButton } from '../unstyled-button';
import { pluralizeEnCount } from 'app/utils/format/plurals';
import { restartProgressFetch } from 'app/actions/batch-progress';
import styles from './styles.module.scss';
import { useAppDispatch } from 'app/hooks/use-app-dispatch';

export const BatchProgressCards: React.FC = () => {
  const progressStates = useSelector(companyBatchProgressSelector);
  const activeProgressStates = Object.keys(progressStates);

  return activeProgressStates.length ? (
    <>
      {activeProgressStates.map((pollingKey) => {
        const progress = progressStates[pollingKey];
        return (
          <BatchProgressCard
            key={pollingKey}
            progressKey={pollingKey as BatchProgressKeys}
            progress={progress}
          />
        );
      })}
    </>
  ) : null;
};

type CardProps = {
  progress: PollingProgress;
  progressKey: BatchProgressKeys;
};

export const BatchProgressCard: React.FC<CardProps> = ({
  progress,
  progressKey,
}) => {
  const companyId = useSelector(currentCompanyIdSelector);
  const isPolling = useSelector(companyPollingSelector)[progressKey];
  const dispatch = useAppDispatch();
  const errorCount = progress.errors || 0;
  const completedCount = progress.completed || 0;
  const finished = completedCount + errorCount;
  const percentage = (finished / (progress.all || 1)) * 100;
  const totalCount =
    progressKey === BatchProgressKeys.transfers
      ? (progress.all || 0) / 2
      : progress.all || 0;
  const title = getTitleForProgressKey(progressKey, totalCount);
  useEffect(() => {
    if (!isPolling && percentage < 100) {
      dispatch(restartProgressFetch(progress.batchIds, progressKey));
    }
  }, [isPolling, progressKey, progress.batchIds, dispatch, percentage]);
  function handleCloseProgressCard() {
    batch(() => {
      dispatch(updateBatchProgress(companyId, progressKey, null));
      dispatch(updatePollingProperty(companyId, progressKey, false));
    });
  }

  return (
    <div className={styles.card}>
      <UnstyledButton
        className={styles.closeCardButton}
        onClick={handleCloseProgressCard}
      >
        <IconClose color="silver" />
      </UnstyledButton>
      <Heading size="h5">{title}</Heading>
      {isPolling && <Loading size="sm" />}
      <ProgressBar
        label="batch action progress"
        hiddenLabel
        id="batch-progress"
        percentage={percentage}
      />
      {completedCount > 0 && (
        <Text size="xs" color="silver" spacing="half">
          <IconChecked color="green" size="sm" />{' '}
          {progressKey === BatchProgressKeys.transfers
            ? completedCount / 2
            : completedCount}{' '}
          completed
        </Text>
      )}
      {errorCount > 0 && (
        <Text size="xs" color="silver">
          <IconWarning color="red" size="sm" />{' '}
          {pluralizeEnCount(errorCount, 'error')}
        </Text>
      )}
    </div>
  );
};

function getTitleForProgressKey(key: BatchProgressKeys, totalCount: number) {
  switch (key) {
    case BatchProgressKeys.enrollments:
      return `${pluralizeEnCount(totalCount, 'Enrollment')} in progress`;
    case BatchProgressKeys.unenrollments:
      return `${pluralizeEnCount(totalCount, 'Unenrollment')} in progress`;
    case BatchProgressKeys.transfers:
      return `${pluralizeEnCount(totalCount, 'Transfer')} in progress`;
    case BatchProgressKeys.learners:
      return `${pluralizeEnCount(totalCount, 'New Learner')} in progress`;
    default:
      return `${pluralizeEnCount(totalCount, 'Action')} in progress`;
  }
}
