import { AppThunk, IDataResultRest, IGqlResponse } from 'app/types/shared';
import {
  BulkSelectionFilterOp,
  Company,
  CompanyLearnersQuery,
  Learner,
  LearnerBulkSelectionFilter,
  LearnerBulkSelectionFilterField,
  LearnerIdsByUserKeysQuery,
  LearnerInterest,
  LearnerInterestApplication,
  LearnerInterestsAndEnrollmentsQuery,
  LearnerQuery,
} from 'app/types/generated/emc';
import {
  CompanyNormalizedEntities,
  companyNormSchema,
} from 'app/utils/normalize/company';
import {
  LearnerNormalizedEntities,
  learnerNormSchema,
} from 'app/utils/normalize/learner';
import { addServerError, flashGraphqlError } from '../flash';
import { createAction, createSimpleAction } from 'app/utils/actions';
import { deselectAllReportItems, updatePagination } from '../reports';
import {
  getPaginatedItems,
  getQueryString,
} from 'app/utils/graphql/serializer';
import { groupBy, isEmpty, isNil, omit, pathOr } from 'ramda';
import { startLoading, stopLoading } from '../loading';

import { IPaginationData } from 'app/types/pagination';
import { LearnersReduxState } from 'app/reducer/data/types';
import { NONE_OPTION } from 'app/types/options';
import { PaginationTypes } from '@emc/reducer/session/pagination';
import { addGroups } from '../groups';
import { batch } from 'react-redux';
import { companyLearnersPaginationSelector } from 'app/selectors/companies/learners';
import { emcService } from '@emc/services';
import { filterOutAllTimeQueryParam } from 'app/utils/dates/defaults';
import { getIsoAgoString } from 'app/utils/dates/format';
import { getQueryParams } from 'app/utils/router/query-params';
import { hasViewOnlyPermissionsOrHigherSelector } from 'app/selectors/me';
import { loader } from 'graphql.macro';
import { normalize } from 'normalizr';

// Sync
export function companyLearnersUpdateProperty(
  companyId: string,
  property: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
) {
  return createAction('COMPANY_LEARNERS_UPDATE_PROPERTY', {
    companyId,
    property,
    value,
  });
}

export function addLearners(learners: LearnersReduxState = {}) {
  return createAction('ADD_LEARNERS', learners);
}

export function selectLearnerIds(learnerIds: string[]) {
  return createAction('SELECT_LEARNERS', learnerIds);
}

export function deselectLearner(learnerId: string) {
  return createAction('DESELECT_LEARNER', learnerId);
}

export function deselectAllLearners() {
  return createSimpleAction('DESELECT_ALL_LEARNERS');
}

export function addApplicationsEligibility(
  learnerId: string,
  applicationsEligibility: LearnerInterestApplication[]
) {
  return createAction('ADD_APPLICATIONS_ELIGIBILITY', {
    learnerId,
    applicationsEligibility,
  });
}

export function deselectAll(): AppThunk {
  return (dispatch): void => {
    batch(() => {
      dispatch(deselectAllLearners());
      dispatch(deselectAllReportItems());
    });
  };
}

// Async

export function fetchCompanyLearners(companyId: string): AppThunk {
  return (dispatch, getState) => {
    const state = getState();
    const paginationData = companyLearnersPaginationSelector(
      state,
      getQueryParams()
    );
    const createdAfter = getIsoAgoString(paginationData?.createdAfter);
    const createdBefore = getIsoAgoString(paginationData?.createdBefore, false);
    const groupId = filterOutAllTimeQueryParam(paginationData?.groupId);
    const learningPathId = filterOutAllTimeQueryParam(
      paginationData?.learningPathId
    );
    const hasAssessmentResultAfter = getIsoAgoString(
      paginationData?.hasAssessmentResultAfter
    );
    const selectionFilters: LearnerBulkSelectionFilter[] = [];
    if (paginationData.nominationType) {
      const nominationTypes = new Set(paginationData.nominationType.split(','));

      if (nominationTypes.has(NONE_OPTION)) {
        nominationTypes.delete(NONE_OPTION);
        selectionFilters.push({
          op: BulkSelectionFilterOp.Eq,
          field:
            LearnerBulkSelectionFilterField.NominationsReceivedNominationType,
          // value is undefined here since we want non-nominated learners
        });
      }

      if (nominationTypes.size) {
        const otherTypes = Array.from(nominationTypes).join(',');

        selectionFilters.push({
          op: BulkSelectionFilterOp.In, // multiselect enabled
          field:
            LearnerBulkSelectionFilterField.NominationsReceivedNominationType,
          value: otherTypes,
        });
      }
    }

    dispatch(startLoading('LEARNERS'));

    const queryParams = {
      companyId,
      ...omit(['nominationType'])(paginationData),
      learningPathId,
      groupId,
      createdAfter,
      createdBefore,
      hasAssessmentResultAfter,
      selectionFilters,
    };

    return fetchEMCLearners(queryParams)
      .then((res) => {
        const normalizedData = normalize<Company, CompanyNormalizedEntities>(
          res.data.data?.company || {},
          companyNormSchema
        );
        const learners = normalizedData.entities.learners;
        if (isNil(learners) || isEmpty(learners)) {
          batch(() => {
            dispatch(stopLoading('LEARNERS'));
            dispatch(updatePagination(PaginationTypes.companyLearners, 0, []));
          });
          return;
        }

        const { companies } = normalizedData.entities;
        const company = companies[companyId];

        const totalLearnersCount = company?.learners?.totalCount || 0;
        const learnerIds = getPaginatedItems(company.learners) || [];

        batch(() => {
          dispatch(addLearners(learners));
          dispatch(
            updatePagination(
              PaginationTypes.companyLearners,
              totalLearnersCount,
              learnerIds
            )
          );
        });
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('LEARNERS')));
  };
}

export function fetchLearner(
  userKey: string,
  companyId: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    if (!userKey) return;
    dispatch(startLoading('LEARNER'));
    const isManager = hasViewOnlyPermissionsOrHigherSelector(getState());

    try {
      const gqlResult: IGqlResponse<LearnerQuery> = await emcService.gql(
        getQueryString(loader('@emc/queries/emc/get-learner.gql')),
        { userKey, companyId, isManager }
      );
      const rawLearner = (gqlResult.data.data?.learnersByUserKey || [])[0];
      if (!rawLearner) {
        dispatch(stopLoading('LEARNER'));
        return;
      }

      const normalizedData = normalize<Learner, LearnerNormalizedEntities>(
        rawLearner,
        learnerNormSchema
      );
      const normalizedLearners = normalizedData.entities.learners || {};
      const groups = normalizedData.entities.groups || {};

      batch(() => {
        if (!isEmpty(groups)) {
          dispatch(addGroups(groups));
        }
        if (!isEmpty(normalizedLearners)) {
          dispatch(addLearners(normalizedLearners));
        }
      });
    } catch (error) {
      dispatch(addServerError(error as Error));
    }
    dispatch(stopLoading('LEARNER'));
  };
}

export type CertificatesResponse = {
  certificates: { uuid: string; node_key: string }[];
};

export type LearnerLookupResponse = {
  email: string;
  learner_id?: string;
  error?: string;
};
export function lookupLearnersCsv(
  companyId: string,
  file: File
): AppThunk<
  Promise<{
    learners?: LearnerLookupResponse[];
    errors?: LearnerLookupResponse[];
  }>
> {
  return (dispatch) => {
    const data = new FormData();
    data.append('csv_file', file);
    return emcService
      .post(`/companies/${companyId}/learners/csv-lookup`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response: IDataResultRest<LearnerLookupResponse[]>) =>
        groupBy<LearnerLookupResponse>((row) =>
          row.learner_id ? 'learners' : 'errors'
        )(response.data?.result || [])
      )
      .catch((err) => {
        const reason = pathOr('', ['response', 'data', 'error'])(err);
        dispatch(
          addServerError({
            message: `Something went wrong! Please verify the csv file or download the csv template.${
              reason && ` (${reason})`
            }`,
          })
        );
        return {};
      });
  };
}

// Util

export function fetchEMCLearners(
  queryParams: Partial<IPaginationData>
): Promise<IGqlResponse<CompanyLearnersQuery>> {
  return emcService.gql(
    getQueryString(loader('@emc/queries/emc/get-company-learners.gql')),
    { ...queryParams }
  );
}

export function fetchLearnerInterestsForAssessment(
  userKey: string,
  companyId: string,
  cohortKey: string
): AppThunk<Promise<LearnerInterest[] | undefined>> {
  return async (dispatch) => {
    dispatch(startLoading('LEARNER'));

    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/get-learner-interests-and-enrollments.gql')
        ),
        {
          userKey,
          companyId,
          includeEnrollments: false,
        }
      )
      .then((res: IGqlResponse<LearnerInterestsAndEnrollmentsQuery>) => {
        const companyLearner = (res.data.data.learnersByUserKey || [])[0];
        const rawLearnerInterests = companyLearner?.interests;
        const applications = companyLearner?.applications;
        if (!isEmpty(applications || [])) {
          dispatch(
            addApplicationsEligibility(
              companyLearner?.id as string,
              applications as LearnerInterestApplication[]
            )
          );
        }
        if (!rawLearnerInterests) {
          dispatch(stopLoading('LEARNER'));
          return undefined;
        }

        const interestsWithAssessmentCohort = rawLearnerInterests?.filter(
          (interest) => interest.assessmentCohortKey === cohortKey
        );
        if (res.data.errors) {
          dispatch(flashGraphqlError(res.data.errors));
        }
        dispatch(stopLoading('LEARNER'));
        return interestsWithAssessmentCohort as LearnerInterest[];
      });
  };
}

export async function fetchLearnerIdsByUserKeys(
  userKeys: string[],
  companyId: string
): Promise<string[]> {
  const gqlResult: IGqlResponse<LearnerIdsByUserKeysQuery> =
    await emcService.gql(
      getQueryString(
        loader('@emc/queries/emc/get-learner-ids-by-user-keys.gql')
      ),
      { companyId, userKeys: userKeys.join(',') }
    );
  return (gqlResult.data.data.learnerIdsByFilter as string[]) || [];
}
