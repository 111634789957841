import {
  emcLearnerForCurrentCompanySelector,
  isUdacityStaffSelector,
} from 'app/selectors/me';

import { AuthorizedRoute } from './authorized-route';
import React from 'react';
import { RouteProps } from 'react-router-dom';
import { companyHasPublicCatalog } from 'app/utils/settings';
import { currentCompanySelector } from 'app/selectors/companies/companies';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';

export const CompanyLearnerRoute: React.FC<RouteProps> = (props) => {
  const learner = useSelector(emcLearnerForCurrentCompanySelector);
  const company = useSelector(currentCompanySelector);
  const isUdacityStaff = useSelector(isUdacityStaffSelector);
  const companyCatalogIsPublic = companyHasPublicCatalog(
    company?.companySettings || []
  );
  const isAuthorized =
    !isNil(learner) || isUdacityStaff || companyCatalogIsPublic;
  const augmentedProps = { ...props, isAuthorized };
  return <AuthorizedRoute {...augmentedProps} />;
};
