import {
  EmcErrorData,
  FlashAction,
  addMaintenanceMessage,
} from 'app/actions/flash';

export type FlashState = {
  type: 'success' | 'error' | null;
  message: string | null;
  maintenanceReason: EmcErrorData | null;
};

const initialState = {
  type: null,
  message: null,
  maintenanceReason: null,
};

export default function errors(
  state: FlashState = initialState,
  action: FlashAction | ReturnType<typeof addMaintenanceMessage>
): FlashState {
  switch (action.type) {
    case 'ADD_FLASH_MESSAGE': {
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.message,
      };
    }

    case 'REMOVE_FLASH_MESSAGE': {
      return {
        ...state,
        type: null,
        message: null,
      };
    }

    case 'ADD_MAINTENANCE': {
      return {
        ...state,
        maintenanceReason: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
