import { IconCancel, IconChecked } from '@udacity/veritas-icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Banner } from '@udacity/veritas-components';
import classNames from 'classnames';
import { flashSelector } from 'app/selectors/session';
import { removeFlashMessage } from 'app/actions/flash';
import styles from './styles.module.scss';
import { useAppDispatch } from 'app/hooks/use-app-dispatch';
import { useSelector } from 'react-redux';

const variantIcons = {
  success: <IconChecked />,
  error: <IconCancel />,
};

const visibleDuration = process.env.REACT_APP_ENV === 'testing' ? 10 : 4000;

export const Flash: React.FC = () => {
  const [isVisible, setVisible] = useState(false);
  const { message, type } = useSelector(flashSelector);
  const dispatch = useAppDispatch();
  const timeoutPointers = useRef<NodeJS.Timeout[]>([]);

  const handleClose = useCallback(
    (shouldForce?: boolean) => {
      const duration = shouldForce ? 0 : visibleDuration;

      timeoutPointers.current = [
        setTimeout(() => setVisible(false), duration),
        setTimeout(() => dispatch(removeFlashMessage()), duration + 250),
      ];
    },
    [dispatch]
  );

  useEffect(() => {
    if (!message) return;

    const timeout = setTimeout(() => setVisible(true), 0);
    handleClose();

    return (): void => {
      clearTimeout(timeout);
      timeoutPointers.current.forEach(clearTimeout);
    };
  }, [message, handleClose]);

  const handleClick = useCallback(() => handleClose(true), [handleClose]);

  if (!message || !type) {
    return null;
  }

  return (
    <div
      className={classNames(styles.flashBanner, {
        [styles.visible]: isVisible,
      })}
    >
      <Banner
        closeable
        icon={variantIcons[type]}
        onClose={handleClick}
        variant={type}
      >
        {message}
      </Banner>
    </div>
  );
};
