import { updatePseudolocalizationVisibility } from 'app/actions/visibility';

export type VisibilityReduxState = {
  pseudolocalization: boolean;
};

const initialState: VisibilityReduxState = {
  pseudolocalization: false,
};

export default function visibility(
  state = initialState,
  action: ReturnType<typeof updatePseudolocalizationVisibility>
): VisibilityReduxState {
  switch (action.type) {
    case 'UPDATE_PSEUDOLOCALIZATION_VISIBILITY': {
      const { isVisible } = action.payload;

      return {
        ...state,
        pseudolocalization: isVisible,
      };
    }

    default: {
      return state;
    }
  }
}
