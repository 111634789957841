import {
  addEnterpriseCatalog,
  removeCatalogProgram,
} from 'app/actions/catalog';
import { mergeDeepRight, omit } from 'ramda';

import { EnterpriseCatalogReduxState } from './types';

export default function enterpriseCatalog(
  state: EnterpriseCatalogReduxState = {},
  action:
    | ReturnType<typeof addEnterpriseCatalog>
    | ReturnType<typeof removeCatalogProgram>
): EnterpriseCatalogReduxState {
  switch (action.type) {
    case 'ADD_ENTERPRISE_CATALOG': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return mergeDeepRight(state, action.payload as any);
    }

    case 'REMOVE_CATALOG_PROGRAM': {
      return omit([String(action.payload)])(state);
    }

    default: {
      return state;
    }
  }
}
