import { AppThunk, Normalized } from 'app/types/shared';
import { EnrollmentStatusEnum, Learner } from 'app/types/generated/emc';
import { PaginationProps, getPaginationData } from 'app/selectors/pagination';
import { ReportProject, ReportRoster } from 'app/types/reports';
import { addFlashMessage, addServerError } from './flash';
import { addLearners, fetchLearner, selectLearnerIds } from './learners/fetch';
import { createAction, createSimpleAction } from 'app/utils/actions';
import {
  formatProjectQueryParams,
  formatRosterQueryParams,
} from 'app/utils/format/reports';
import { indexBy, pipe, pluck, prop } from 'ramda';
import { startLoading, stopLoading } from './loading';

import { DELTA_START_DEFAULT } from '@emc/company-views/reports/search/constants';
import { IProjectNameReport } from 'app/types/charts';
import { NormalizedLearner } from 'app/types/learners';
import { PaginationTypes } from '@emc/reducer/session/pagination';
import { batch } from 'react-redux';
import { emcService } from '@emc/services';
import { getIsProjectReport } from 'app/utils/router/paths';
import { getQueryParams } from 'app/utils/router/query-params';
import { getQueryString } from 'app/utils/graphql/serializer';
import { loader } from 'graphql.macro';
import { updateChartProperty } from './charts/dashboard';

// Async

export function updatePagination(
  tableName: PaginationTypes,
  totalCount: number,
  pageIds: (string | number)[]
) {
  return createAction('UPDATE_PAGINATION', {
    tableName,
    totalCount,
    pageIds,
  });
}

export function addRosterData(data: Normalized<ReportRoster>) {
  return createAction('ADD_ROSTER_REPORT_DATA', data);
}

export function addProjectsReportData(data: Normalized<ReportProject>) {
  return createAction('ADD_PROJECTS_REPORT_DATA', data);
}

export function selectReportItemAction(itemId: string) {
  return createAction('SELECT_REPORT_ITEM', itemId);
}

export function selectReportItem(
  itemId: string,
  learnerObj: Partial<NormalizedLearner>
): AppThunk {
  return (dispatch) => {
    const learnerId = learnerObj.id;
    if (learnerId) {
      batch(() => {
        dispatch(addLearners({ [learnerId]: learnerObj as NormalizedLearner }));
        dispatch(selectLearnerIds([learnerId]));
        dispatch(selectReportItemAction(itemId));
      });
    }
  };
}

export function deselectReportItem(itemId: string) {
  return createAction('DESELECT_REPORT_ITEM', itemId);
}

export function deselectAllReportItems() {
  return createSimpleAction('DESELECT_ALL_REPORT_ITEMS');
}

// Async

export function fetchReports(companyId: string): AppThunk {
  return (dispatch) => {
    const isProject = getIsProjectReport();

    if (isProject) {
      return dispatch(fetchProjectsReport(companyId));
    }

    return dispatch(fetchRosterReport(companyId));
  };
}

export type RosterReportResponse = {
  data: {
    data: ReportRoster[];
    resultset_total: number;
    last_updated_at: string;
    consumed_stu_count: number;
    pending_stu_count: number;
  };
};
export const rosterPaginationProps: PaginationProps = {
  tableName: PaginationTypes.roster,
  defaultSizePerPage: 50,
};
export function fetchRosterReport(companyId: string): AppThunk {
  return (dispatch) => {
    dispatch(startLoading('ROSTER_REPORT'));

    const queryParams = getQueryParams();
    const isShowingGraduatesOnly =
      queryParams.enrollmentState === EnrollmentStatusEnum.Graduated ||
      Boolean(queryParams.graduatedAfter);
    const paginationData = {
      ...queryParams,
      deltaStart: queryParams.deltaStart || DELTA_START_DEFAULT,
      ...getPaginationData({
        ...rosterPaginationProps,
        defaultOrderBy: isShowingGraduatesOnly ? 'graduated_at' : 'last_seen',
      }),
    };

    return emcService
      .fetch(
        `/companies/${companyId}/roster`,
        {
          ...formatRosterQueryParams(paginationData),
        },
        { timeout: 45000 }
      )
      .then((res: RosterReportResponse) => {
        const data = res.data;
        const roster = data?.data || [];
        const total = data?.resultset_total || 0;

        batch(() => {
          dispatch(
            updatePagination(PaginationTypes.roster, total, pluck('rc')(roster))
          );
          dispatch(addRosterData(indexBy(pipe(prop('rc'), String), roster)));
          dispatch(
            updateChartProperty(companyId, 'dashboard', 'stuCounts', {
              consumed: data.consumed_stu_count,
              pending: data.pending_stu_count,
            })
          );
        });
      })
      .catch((err) => dispatch(addServerError(err)))
      .then(() => dispatch(stopLoading('ROSTER_REPORT')));
  };
}

export type ProjectReportResponse = {
  data: {
    data: ReportProject[];
    resultset_total: number;
    last_updated_at: string;
  };
};

export const projectsPaginationProps: PaginationProps = {
  tableName: PaginationTypes.projects,
  defaultSizePerPage: 50,
  defaultOrderBy: 'last_attempt',
};

export function fetchProjectsReport(companyId: string): AppThunk {
  return (dispatch, getState) => {
    dispatch(startLoading('PROJECTS_REPORT'));

    const queryParams = getQueryParams();

    const paginationData = {
      ...queryParams,
      ...getPaginationData(projectsPaginationProps),
    };

    return emcService
      .fetch(
        `/companies/${companyId}/projects`,
        formatProjectQueryParams(paginationData)
      )
      .then((res: ProjectReportResponse) => {
        const data = res.data;
        const projects = data?.data || [];
        const total = data?.resultset_total || 0;

        batch(() => {
          dispatch(
            updatePagination(
              PaginationTypes.projects,
              total,
              pluck('rc')(projects)
            )
          );
          dispatch(
            addProjectsReportData(indexBy(pipe(prop('rc'), String), projects))
          );
        });
      })
      .catch((err) => dispatch(addServerError(err)))
      .then(() => dispatch(stopLoading('PROJECTS_REPORT')));
  };
}

export function fetchCompanyProjectNames(companyId: string): AppThunk {
  return (dispatch) => {
    return emcService
      .fetch(`/companies/${companyId}/project-names`)
      .then((res: { data: IProjectNameReport[] }) => {
        const projectNames = pluck('project_name', res.data || []);

        dispatch(
          updateChartProperty(
            companyId,
            'projects',
            'projectNames',
            projectNames
          )
        );
      })
      .catch((err) => dispatch(addServerError(err)));
  };
}

export function recomputeLearnerReports(learner: Learner): AppThunk<void> {
  return (dispatch) => {
    dispatch(addFlashMessage('success', 'Reports are being recomputed'));
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/recompute-learner-real-time-reports.gql')
        ),
        { learnerIds: [learner.id] }
      )
      .catch((err) => dispatch(addServerError(err)))
      .then(() => {
        dispatch(fetchLearner(learner.userKey, learner.companyId));
      });
  };
}

export function recomputeCompanyReports(companyId: string): AppThunk<void> {
  return (dispatch) => {
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/recompute-company-real-time-reports.gql')
        ),
        { companyId }
      )
      .catch((err) => {
        dispatch(addServerError(err));
      })
      .then(() => {
        dispatch(
          addFlashMessage(
            'success',
            'Reports are being recomputed. Please check back in a few minutes.'
          )
        );
        dispatch(fetchReports(companyId));
      });
  };
}
