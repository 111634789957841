import {
  addApplicationsEligibility,
  addLearners,
} from 'app/actions/learners/fetch';
import {
  addGroupToLearners,
  removedLearnersFromGroup,
} from 'app/actions/groups';
import {
  addLearnerAssessmentLabSession,
  updateLearnerAssessmentLabSession,
} from 'app/actions/assessments/assessments';
import {
  addLearnerInterest,
  removeLearnerInterest,
  setLearnerInterests,
  updateLearnerInterest,
} from 'app/actions/interest';
import { addMe, updateMyAdmissionsWindow } from 'app/actions/me';
import { forEach, indexBy, mergeDeepRight, reduce, reject } from 'ramda';

import { LearnerGraphLearningPath } from 'app/types/generated/emc';
import { LearnersReduxState } from './types';
import { addLearnerEnrollments } from 'app/actions/enrollments/auto-enrollment';
import { removeFromLearningPath } from 'app/actions/learning-paths/manage';

type LearnersActions =
  | ReturnType<typeof addMe>
  | ReturnType<typeof addLearners>
  | ReturnType<typeof addLearnerInterest>
  | ReturnType<typeof removeLearnerInterest>
  | ReturnType<typeof setLearnerInterests>
  | ReturnType<typeof updateLearnerInterest>
  | ReturnType<typeof removeFromLearningPath>
  | ReturnType<typeof addGroupToLearners>
  | ReturnType<typeof addLearnerAssessmentLabSession>
  | ReturnType<typeof updateLearnerAssessmentLabSession>
  | ReturnType<typeof removedLearnersFromGroup>
  | ReturnType<typeof addLearnerEnrollments>
  | ReturnType<typeof addApplicationsEligibility>
  | ReturnType<typeof updateMyAdmissionsWindow>;

export default function learners(
  state: LearnersReduxState = {},
  action: LearnersActions
): LearnersReduxState {
  switch (action.type) {
    case 'ADD_LEARNERS': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return mergeDeepRight(state, action.payload as any);
    }

    case 'ADD_LEARNER_ENROLLMENTS': {
      const { allEnrollments, learnerId } = action.payload;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return mergeDeepRight(state, { [learnerId]: { allEnrollments } } as any);
    }

    case 'ADD_LEARNER_INTEREST': {
      const newInterest = action.payload;
      const { learnerId } = newInterest;
      const learner = state[learnerId];

      // If learner has an old interest in this program, that means we want to create a new interest and replace old
      const oldInterestIndex = learner.interests?.findIndex(
        (existingInterest) =>
          (existingInterest.programKey &&
            existingInterest.programKey === newInterest.programKey) ||
          (existingInterest.companyGraphLearningPathId &&
            existingInterest.companyGraphLearningPathId ===
              newInterest.companyGraphLearningPathId)
      );

      let interests = learner.interests || [];
      if (oldInterestIndex && oldInterestIndex !== -1) {
        interests[oldInterestIndex] = newInterest;
      } else {
        interests = [...interests, newInterest];
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return mergeDeepRight(state, { [learnerId]: { interests } } as any);
    }

    case 'ADD_APPLICATIONS_ELIGIBILITY': {
      const { learnerId, applicationsEligibility } = action.payload;
      const learner = state[learnerId];

      return {
        ...state,
        [learnerId]: {
          ...learner,
          applications: applicationsEligibility,
        },
      };
    }

    case 'REMOVE_LEARNER_INTEREST': {
      const { learnerId, interestId } = action.payload;
      const learner = state[learnerId];
      const interests = (learner.interests || []).filter(
        (interest) => interest.id !== interestId
      );
      const newState = mergeDeepRight(state, {
        [learnerId]: { interests },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
      return newState;
    }

    case 'SET_LEARNER_INTERESTS': {
      const { learnerId, interests } = action.payload;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return mergeDeepRight(state, { [learnerId]: { interests } } as any);
    }

    case 'UPDATE_LEARNER_INTEREST': {
      const { learnerId, learnerInterest } = action.payload;
      const learner = state[learnerId];
      const interests = (learner.interests || []).map((interest) => {
        if (interest.id === learnerInterest.id) {
          return { ...interest, ...learnerInterest };
        }
        return interest;
      });
      const newState = mergeDeepRight(state, {
        [learnerId]: { interests },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
      return newState;
    }

    case 'ADD_LEARNER_ASSESSMENT_LAB_SESSION': {
      const { learnerId, session } = action.payload;
      const learner = state[learnerId];

      return {
        ...state,
        [learnerId]: {
          ...learner,
          assessmentLabSessions: [session],
        },
      };
    }

    case 'UPDATE_LEARNER_ASSESSMENT_LAB_SESSION': {
      const { learnerId, session } = action.payload;
      const learner = state[learnerId];

      return {
        ...state,
        [learnerId]: {
          ...learner,
          assessmentLabSessions: learner.assessmentLabSessions?.map(
            (labSession) => {
              if (labSession.sessionId === session.sessionId) {
                return { ...labSession, ...session };
              }
              return labSession;
            }
          ),
        },
      };
    }

    case 'REMOVE_LEARNERS_FROM_LEARNING_PATH': {
      const { learningPathId, learnerIds } = action.payload;
      const updatedLearners = reduce((acc, id: string) => {
        const learner = state[id];
        acc[id] = {
          ...learner,
          glpAssociations: reject(
            (pathAssociation: LearnerGraphLearningPath) =>
              pathAssociation?.companyGraphLearningPath?.id === learningPathId
          )((learner.glpAssociations as LearnerGraphLearningPath[]) || []),
        };
        return acc;
      }, {})(learnerIds);

      return {
        ...state,
        ...updatedLearners,
      };
    }

    case 'ADD_GROUP_TO_LEARNERS': {
      const { groupId, learnerIds } = action.payload;
      const learnersWithGroup = {};
      forEach((learnerId: string) => {
        const learner = state[learnerId];
        if (learner) {
          learnersWithGroup[learnerId] = {
            ...learner,
            groups: [...(learner.groups || []), groupId],
          };
        }
      })(learnerIds);

      return { ...state, ...learnersWithGroup };
    }

    case 'REMOVED_LEARNERS_FROM_GROUP': {
      const { groupId, learnerIds } = action.payload;
      const updatedLearners = reduce((acc, id: string) => {
        const learner = state[id];
        acc[id] = {
          ...learner,
          groups: reject((id) => id === groupId)(learner.groups || []),
        };
        return acc;
      }, {})(learnerIds);

      return {
        ...state,
        ...updatedLearners,
      };
    }

    case 'ADD_ME': {
      const { learners } = action.payload;
      const meLearners = indexBy((l) => l?.id, learners);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return mergeDeepRight(state, meLearners as any);
    }

    case 'UPDATE_MY_ADMISSIONS_WINDOW': {
      const {
        learnerId,
        admissionsWindow: { hasReachedApplicationsLimit },
      } = action.payload;

      const updatedLearner = {
        [learnerId]: { admissionsWindow: { hasReachedApplicationsLimit } },
      };

      return mergeDeepRight(state, updatedLearner);
    }

    default: {
      return state;
    }
  }
}
