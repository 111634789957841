import Downshift, {
  ControllerStateAndHelpers,
  DownshiftProps,
  DownshiftState,
  StateChangeOptions,
} from 'downshift';
// taken from example https://codesandbox.io/s/github/kentcdodds/downshift-examples/tree/master/?module=%2Fsrc%2Fordered-examples%2F04-multi-select.js&moduleview=1
import React, { Component } from 'react';

import { IOption } from 'app/types/options';

type Props = DownshiftProps<IOption> & {
  defaultSelectedItems?: IOption[];
  // itemToString={(item: IOption) => (item ? item.label : '')}
  onChange?(options: IOption[]): void;
};

type State = {
  selectedItems?: IOption[];
  highlightedIndex?: number;
};

class MultiDownshift extends Component<Props, State> {
  state = { selectedItems: this.props.defaultSelectedItems || [] };

  stateReducer = (
    state: DownshiftState<IOption>,
    changes: StateChangeOptions<IOption>
  ) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: true,
          inputValue: '',
        };
      default:
        return changes;
    }
  };

  callOnChange = () => {
    const { onChange } = this.props;
    const { selectedItems } = this.state;
    if (onChange) {
      onChange(selectedItems);
    }
  };

  handleSelection = (selectedItem: IOption | null) => {
    if (!selectedItem) return;

    if (this.state.selectedItems.includes(selectedItem)) {
      this.removeItem(selectedItem, this.callOnChange);
    } else {
      this.addSelectedItem(selectedItem, this.callOnChange);
    }
  };

  removeItem = (item: IOption, cb: () => void) => {
    this.setState(({ selectedItems }) => {
      return {
        selectedItems: (selectedItems || []).filter((i) => i !== item),
      };
    }, cb);
  };

  addSelectedItem(item: IOption, cb: () => void) {
    this.setState(
      ({ selectedItems }) => ({
        selectedItems: [...(selectedItems || []), item],
      }),
      cb
    );
  }

  getRemoveButtonProps = ({ item }: { item: IOption }) => {
    return {
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();
        this.removeItem(item, this.callOnChange);
      },
    };
  };

  getStateAndHelpers(downshift: ControllerStateAndHelpers<IOption>) {
    const { selectedItems } = this.state;
    const { getRemoveButtonProps, removeItem } = this;
    return {
      getRemoveButtonProps,
      removeItem,
      selectedItems,
      ...downshift,
    };
  }

  render() {
    const { children, ...props } = this.props;
    if (!children) return null;

    return (
      <Downshift
        {...props}
        onChange={this.handleSelection}
        selectedItem={null}
        stateReducer={this.stateReducer}
      >
        {(downshift) => children(this.getStateAndHelpers(downshift))}
      </Downshift>
    );
  }
}

export default MultiDownshift;
