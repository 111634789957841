import React, { useEffect, useRef } from 'react';
import { find, includes, isNil, pathOr, pluck, propEq, toLower } from 'ramda';

import { ControllerStateAndHelpers } from 'downshift';
import { IOption } from 'app/types/options';
import { IconClose } from '@udacity/veritas-icons';
import { UnstyledButton } from '../unstyled-button';
import classNames from 'classnames';
import styles from './styles.module.scss';

function getToggleText(
  style: 'capslock' | 'italic',
  selectedItem?: IOption,
  placeholder?: string
) {
  if (selectedItem?.label) {
    return selectedItem.label;
  }
  return (
    (selectedItem && selectedItem.label) || (
      <span className={classNames(styles.placeholder, styles[style])}>
        {placeholder}
      </span>
    )
  );
}

export function getCurrentItem(
  options: IOption[] = [],
  value?: string | number | IOption | IOption[],
  defaultValue?: string | number,
  selectedItem?: IOption | null
): IOption | undefined {
  if (!isNil(value)) {
    const val = pathOr(value, ['value'])(value);
    return find(propEq('value', val))(
      options as IOption<string | number>[]
    ) as IOption;
  }
  if (!isNil(selectedItem)) {
    return find(propEq('value', selectedItem?.value))(options) as IOption;
  }
  if (!isNil(defaultValue)) {
    const val = pathOr(defaultValue, ['value'])(defaultValue);
    return find(propEq('value', val))(
      options as IOption<string | number>[]
    ) as IOption;
  }
  return;
}

export function optionDoesMatch(
  inputValue: string | null,
  option: IOption,
  multi: boolean,
  multiValues: IOption<string | number | boolean>[]
): boolean {
  const searchMatchesValue = includes(
    toLower(inputValue || ''),
    toLower(String(pathOr('', ['value'])(option)))
  );
  const searchMatchesLabel = includes(
    toLower(inputValue || ''),
    toLower(pathOr('', ['label'])(option))
  );
  const searchMatchesTitle = includes(
    toLower(inputValue || ''),
    toLower(pathOr('', ['title'])(option))
  );
  const optionNotInMulti = multi
    ? !includes(option.value, pluck('value', multiValues))
    : true;

  return Boolean(
    (optionNotInMulti &&
      (searchMatchesValue || searchMatchesLabel || searchMatchesTitle)) ||
      option.alwaysShow
  );
}

export const IconArrow = (
  <i
    aria-hidden="true"
    className={classNames(
      'vds-icon',
      'vds-icon--sm',
      styles.selectIconArrow,
      styles.arrow
    )}
    role="img"
  >
    <svg viewBox="0 0 32 32">
      <path
        d="M8.16 11.411l7.13 10.175c.297.422.903.541 1.356.265a.947.947 0 0 0 .292-.276l6.91-10.175c.29-.425.153-.99-.304-1.259A1.033 1.033 0 0 0 23.02 10H8.98c-.542 0-.98.409-.98.912 0 .178.055.351.16.5z"
        fillRule="evenodd"
      />
    </svg>
  </i>
);

type GetInputPropsType = ControllerStateAndHelpers<IOption>['getInputProps'];
const Input: React.FC<ReturnType<GetInputPropsType>> = (props) => {
  const { onChange } = props;
  const memoizedOnChange = useRef(onChange);
  // usingRef because Formik onChange was causing infinite loops when using
  // Select in a form
  useEffect(() => {
    memoizedOnChange.current &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      memoizedOnChange.current({ target: { value: '' } } as any);
  }, []);

  return <input autoFocus {...props} />;
};

export const MutliItem: React.FC<{
  val: IOption;
  disabled?: boolean;
  getRemoveButtonProps(opts: { item: IOption }): {
    onClick(evt: React.MouseEvent): void;
  };
}> = ({ val, disabled, getRemoveButtonProps }) => (
  <span className={styles.multi}>
    {val.component || val.label}
    <UnstyledButton
      disabled={disabled}
      {...getRemoveButtonProps({ item: val })}
    >
      <IconClose size="sm" title={`Remove ${val.label} option`} />
    </UnstyledButton>
  </span>
);

export const OpenSelectToggle: React.FC<{
  position: 'top' | 'bottom';
  onKeyDown?(evt: React.KeyboardEvent<HTMLInputElement>): void;
  getInputProps: GetInputPropsType;
  id?: string;
}> = ({ position, getInputProps, onKeyDown, id }) => (
  <div className={classNames(styles.select__toggle, styles[position])}>
    <Input
      {...getInputProps({
        className: styles.filter,
        onKeyDown,
        role: 'combobox',
        'aria-expanded': true,
        'aria-controls': `${id}-listbox`,
      })}
    />
  </div>
);

export const ClosedSelectToggle: React.FC<{
  position: 'top' | 'bottom';
  disabled?: boolean;
  currentItem?: IOption;
  placeholder?: string;
  onBlur?(): void;
  onFocus?(): void;
  getToggleButtonProps: ControllerStateAndHelpers<IOption>['getToggleButtonProps'];
  id: string;
  testID?: string;
  placeholderStyle: 'capslock' | 'italic';
}> = ({
  position,
  disabled,
  currentItem,
  placeholder,
  onBlur,
  onFocus,
  getToggleButtonProps,
  id,
  testID,
  placeholderStyle,
}) => (
  <button
    {...getToggleButtonProps({
      className: classNames(
        styles[`select__toggle`],
        styles[position],
        disabled && styles.disabled
      ),
      disabled,
      id,
      onBlur,
      onFocus,
      role: 'combobox',
      'aria-expanded': false,
      'aria-controls': `${id}-listbox`,
    })}
    data-testid={testID}
  >
    <span className={styles.ellipse}>
      {getToggleText(placeholderStyle, currentItem, placeholder)}
    </span>
  </button>
);
