import {
  IBucketsData,
  IFormattedPrograms,
  IProjectCompletionOverview,
} from 'app/types/charts';
import { lensPath, set } from 'ramda';

import { IOption } from 'app/types/options';
import { updateChartProperty } from 'app/actions/charts/dashboard';

export interface ICompanyDashboardChartState {
  assessmentCompletions: number;
  assessmentBuckets: IBucketsData;
  averageProjectCompletion: number;
  completedProjectBuckets: IBucketsData;
  enrollmentBuckets: IBucketsData;
  graduationBuckets: IBucketsData;
  learnerActivityDateRange: number;
  learnerActivitySelectedGroup: string;
  learnerActivitySelectedProgram: string;
  programHealth: IFormattedPrograms;
  programHealthDateRange?: number;
  programsOverview: IFormattedPrograms;
  programsOverviewDateRange: number;
  programsOverviewSelectedGroup: string;
  programsOverviewSelectedProgram: string;
  projectCompletions: number;
  projectCompletionsDateRange: number;
  projectCompletionsSelectedGroup: string;
  projectsOverview: IProjectCompletionOverview[];
  projectsOverviewDateRange: number;
  projectsOverviewSelectedGroup: string;
  seatUtilization: { occupiedSeats: number; totalSeats: number };
  stuCounts: { consumed: number; pending: number };
  totalActiveEnrollments: number;
}

export interface ICompanyChartsState {
  dashboard: ICompanyDashboardChartState;
  general: {
    programs: IOption[];
  };
  projects: {
    projectNames: string[];
  };
}

export interface IChartSessionState {
  [companyId: string]: ICompanyChartsState;
}
const initialState = {};

export default function charts(
  state: IChartSessionState = initialState,
  action: ReturnType<typeof updateChartProperty>
): IChartSessionState {
  switch (action.type) {
    case 'UPDATE_CHART_PROPERTY': {
      const { companyId, chartName, key, value } = action.payload;

      return set(lensPath([companyId, chartName, key]), value, state);
    }

    default: {
      return state;
    }
  }
}
