import { AppThunk, GqlError } from 'app/types/shared';
import { createAction, createSimpleAction } from 'app/utils/actions';

import { getGraphQLErrorMessage } from 'app/utils/errors';

// Sync

export function addMaintenanceMessage(maintenanceResponse: EmcErrorData) {
  return createAction('ADD_MAINTENANCE', maintenanceResponse);
}

type AddFlash = ReturnType<typeof addFlashMessage>;
export function addFlashMessage(type: 'success' | 'error', message: string) {
  return createAction('ADD_FLASH_MESSAGE', { type, message });
}

type RemoveFlash = ReturnType<typeof removeFlashMessage>;
export function removeFlashMessage() {
  return createSimpleAction('REMOVE_FLASH_MESSAGE');
}

export type FlashAction = AddFlash | RemoveFlash;

export type EmcErrorData = {
  error_code: string;
  reason: string;
  error: string;
};
export type EmcError = {
  response?: {
    statusText?: string;
    status: number;
    data: EmcErrorData;
  };
  message?: string;
};
export function addServerError(
  error: EmcError,
  shouldDisplayFlash = true
): AppThunk {
  return (dispatch) => {
    let formattedError = 'Server Timed Out';
    const errorResponse = error?.response;
    const isDownForMaintenance = checkIfDownForMaintenance(errorResponse);
    if (isDownForMaintenance) {
      dispatch(addMaintenanceMessage(errorResponse?.data as EmcErrorData));
      return;
    }
    const humanErrorMessage =
      errorResponse?.data?.reason || errorResponse?.data?.error;
    const errorMessage = error?.message;

    if (humanErrorMessage) {
      formattedError = humanErrorMessage;
    } else if (errorResponse) {
      formattedError = `${errorResponse.status} ${errorResponse.statusText}`;
    } else if (errorMessage) {
      formattedError = errorMessage;
    }

    if (shouldDisplayFlash) {
      dispatch(addFlashMessage('error', formattedError));
    }
  };
}

export function flashGraphqlError(
  errors: GqlError[] | undefined,
  defaultErrMessage?: string
): AppThunk {
  return (dispatch) => {
    const errMsg = getGraphQLErrorMessage(errors, defaultErrMessage);
    dispatch(addFlashMessage('error', errMsg));
  };
}

export enum EmcErrorCodes {
  maintenance = 'maintenance',
}

function checkIfDownForMaintenance(res: EmcError['response']) {
  return (
    res?.status === 503 && res?.data?.error_code === EmcErrorCodes.maintenance
  );
}
