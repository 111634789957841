import { PaginationData } from 'app/selectors/pagination';
import { ResourceType } from 'app/types/generated/emc';
import { filterOutAllTimeQueryParam } from '../dates/defaults';
import { getIsoAgoString } from '../dates/format';
import { pickBy } from 'ramda';

export const truthyOnly = <T>(obj: T): Partial<T> => pickBy(Boolean, obj);

export type ProjectReportPaginationData = PaginationData & {
  programKey: string;
  learnerGroup: string;
  projectStatus: string;
  projectName: string;
  passDateStart: string;
  passDateEnd: string;
  lastAttemptStart: string;
  lastAttemptEnd: string;
  attemptsMadeLower: string;
  attemptsMadeUpper: string;
};

export const formatProjectQueryParams = (
  queryParams: Partial<ProjectReportPaginationData> = {}
) => {
  return truthyOnly({
    order: queryParams.order,
    offset: queryParams.offset,
    limit: queryParams.first,
    program_key: filterOutAllTimeQueryParam(queryParams.programKey),
    learner_group: filterOutAllTimeQueryParam(queryParams.learnerGroup),
    project_status: filterOutAllTimeQueryParam(queryParams.projectStatus),
    project_name: filterOutAllTimeQueryParam(queryParams.projectName),
    search_string: encodeURIComponent(queryParams.search || ''),
    order_by: queryParams.orderBy,
    pass_date_start: getIsoAgoString(queryParams.passDateStart),
    pass_date_end: getIsoAgoString(queryParams.passDateEnd, false),
    last_attempt_start: getIsoAgoString(queryParams.lastAttemptStart),
    last_attempt_end: getIsoAgoString(queryParams.lastAttemptEnd, false),
    // yes, this looks like it's backwards. That's how the API works :(
    attempts_lower: queryParams.attemptsMadeUpper,
    attempts_upper: queryParams.attemptsMadeLower,
  });
};

export type RosterReportPaginationData = PaginationData & {
  programKey: string;
  programType: ResourceType;
  learnerGroup: string;
  learningPathId: string;
  companyGraphLearningPathId: string;
  enrollmentState: string;
  activeAfter: string;
  activeBefore: string;
  refKeys: string;
  graduatedAfter: string;
  projectsPassedLower: string;
  projectsPassedUpper: string;
  graduatedBefore: string;
  enrollmentChangeStart: string;
  enrollmentChangeEnd: string;
  enrollmentCohortIds: string;
  enrollmentStart: string;
  enrollmentEnd: string;
  onTrackStatus: string;
  deltaStart: string;
  isAutoEnrolled: string;
  contractId: string;
};

export const formatRosterQueryParams = (
  queryParams: Partial<RosterReportPaginationData> = {}
) => {
  return {
    ...truthyOnly({
      order: queryParams.order,
      offset: queryParams.offset,
      program_key: filterOutAllTimeQueryParam(queryParams.programKey),
      program_type: filterOutAllTimeQueryParam(queryParams.programType),
      learner_group: filterOutAllTimeQueryParam(queryParams.learnerGroup),
      learning_path_id: filterOutAllTimeQueryParam(queryParams.learningPathId),
      company_graph_learning_path_id: filterOutAllTimeQueryParam(
        queryParams.companyGraphLearningPathId
      ),
      enrollment_state: filterOutAllTimeQueryParam(queryParams.enrollmentState),
      search_string: encodeURIComponent(queryParams.search || ''),
      limit: queryParams.first,
      order_by: queryParams.orderBy,
      ref_keys: queryParams.refKeys,
      on_track_status: filterOutAllTimeQueryParam(queryParams.onTrackStatus),
      deltas_start_time: getIsoAgoString(queryParams.deltaStart),
      projects_passed_lower: queryParams.projectsPassedLower,
      projects_passed_upper: queryParams.projectsPassedUpper,
      last_seen_after: getIsoAgoString(queryParams.activeAfter),
      last_seen_before: getIsoAgoString(queryParams.activeBefore),
      graduated_at_start: getIsoAgoString(queryParams.graduatedAfter),
      graduated_at_end: getIsoAgoString(queryParams.graduatedBefore, false),
      enrollment_changed_start: getIsoAgoString(
        queryParams.enrollmentChangeStart
      ),
      enrollment_changed_end: getIsoAgoString(
        queryParams.enrollmentChangeEnd,
        false
      ),
      cohort_ids: filterOutAllTimeQueryParam(queryParams.enrollmentCohortIds),
      enrollment_start: getIsoAgoString(queryParams.enrollmentStart),
      enrollment_end: getIsoAgoString(queryParams.enrollmentEnd, false),
      contract_id: filterOutAllTimeQueryParam(queryParams.contractId),
    }),
    ...(queryParams.isAutoEnrolled !== undefined
      ? {
          is_auto_enrolled: Boolean(queryParams.isAutoEnrolled === 'Auto'),
        }
      : {}),
  };
};
