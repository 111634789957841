import { ISelectedLearner, NormalizedLearner } from 'app/types/learners';
import { pathOr, pluck, uniq } from 'ramda';

import { EnrollmentStatusEnum } from 'app/types/generated/emc';
import { IRootStore } from '@emc/reducer/types';
import { LearnersReduxState } from 'app/reducer/data/types';
import { SelectedItemsType } from '@emc/reducer/session/selections';
import { createSelector } from 'reselect';
import { currentCompanyIdSelector } from '../companies/companies';
import { emcUserLearnersSelector } from '../me';
import { getEmail } from 'app/utils/format/strings';
import { loadingSelector } from '../session';

export const learnersSelector = (state: IRootStore): LearnersReduxState =>
  state.data.learners;

export const isLoadingLearnerSelector = createSelector(
  loadingSelector,
  pathOr(false, ['LEARNER'])
);

export const companyMeSelector = createSelector<
  [
    (state: IRootStore) => NormalizedLearner[],
    (state: IRootStore) => string,
    (state: IRootStore) => LearnersReduxState
  ],
  NormalizedLearner | undefined
>(
  emcUserLearnersSelector,
  currentCompanyIdSelector,
  learnersSelector,
  (myLearners, companyId, learners) => {
    const companyMe = myLearners.find(
      (learner) => learner.companyId === companyId
    );
    const learnerId = companyMe?.id;
    if (learnerId) {
      return learners[learnerId];
    }
    return;
  }
);

export const selectedLearnerIdsSelector = (
  state: IRootStore
): SelectedItemsType => state.session.selections.learnerIds;

export const selectedLearnersSelector = createSelector<
  [(state: IRootStore) => LearnersReduxState, (state: IRootStore) => string[]],
  NormalizedLearner[]
>(
  learnersSelector,
  selectedLearnerIdsSelector,
  (allLearners, selectedLearnerIds) => {
    return selectedLearnerIds.map((id) => allLearners[id]);
  }
);

export const actionBarSelectedLearnersSelector = createSelector<
  [(state: IRootStore) => NormalizedLearner[]],
  ISelectedLearner[]
>(selectedLearnersSelector, (learners) => {
  return learners.map(formatSelectedLearner);
});

function formatSelectedLearner(learner: NormalizedLearner): ISelectedLearner {
  const enrollments = learner?.allEnrollments?.currentEnrollments || [];
  const roster = learner.roster || [];
  const enrollmentContracTermIds = pluck(
    'contractTermId',
    enrollments
  ) as string[];
  const rosterContractTermIds = roster.reduce<string[]>(
    (termIds, enrollment) => {
      if (
        enrollment.enrollmentStatus === EnrollmentStatusEnum.Enrolled &&
        enrollment.contractTermId
      ) {
        termIds.push(enrollment.contractTermId);
      }
      return termIds;
    },
    []
  );
  const enrolledContractTermIds = uniq([
    ...enrollmentContracTermIds,
    ...rosterContractTermIds,
  ]);
  const enrollmentIds = pluck('enrollmentId', enrollments);
  const enrollmentCohortAssociations =
    learner?.associations?.reduce((cohortAssociations, association) => {
      const cohortId = association?.enrollmentCohortId;
      const cohortContractTerms = cohortId
        ? cohortAssociations[cohortId] || []
        : [];
      return {
        ...cohortAssociations,
        [cohortId || 0]: [...cohortContractTerms, association?.contractTermId],
      };
    }, {}) || {};

  return {
    learnerId: learner.id,
    email: getEmail(learner),
    enrolledContractTermIds,
    enrollmentIds,
    enrollmentCohortAssociations,
    learningPathIds:
      learner.glpAssociations?.map(
        (glpAssociation) =>
          glpAssociation?.companyGraphLearningPath?.id as string
      ) || [],
    groupIds: learner.groups || [],
  };
}
