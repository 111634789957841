import { lensIndex, match, pipe, view } from 'ramda';

import { Company } from 'app/types/generated/emc';
import { ReportsSection } from 'app/types/reports';
import { history } from '@emc/history';

export const getNextPathSection = (
  fullPath: string,
  pathSection: string
): string => {
  return pipe(
    match(new RegExp(`${pathSection}/(.+)`)),
    view(lensIndex(1))
  )(fullPath);
};

export const getCompanySlugFromPathname = (pathname = ''): string => {
  const companyPattern = /\/c\/([A-Za-z0-9-_]+)\/?/;
  const parentCompanyPattern = /\/pc\/([A-Za-z0-9-_]+)\/?/;
  const match =
    pathname.match(companyPattern) ||
    pathname.match(parentCompanyPattern) ||
    [];
  return match[1];
};

export const getCompanyIdFromAdminPathname = (pathname = ''): string => {
  const matchArr = pathname.match(/\/admin\/companies\/(\w+)\/?/) || [];
  return matchArr[1];
};

export function getCompanyPath(company: Pick<Company, 'slug'>): string {
  return `/c/${company?.slug}`;
}

export function getCompanyAdminPath(companyId: string): string {
  return `/admin/companies/${companyId}`;
}

export function routeToCompanyAdmin(companyId: string): void {
  history.push(getCompanyAdminPath(companyId));
}

export function getIsProjectReport(): boolean {
  return getReportsSection() === ReportsSection.Projects;
}

export function getReportsSection(): ReportsSection {
  const { pathname } = window.location;
  return (
    (getNextPathSection(pathname, 'reports') as ReportsSection) ||
    ReportsSection.Reports
  );
}

export function isUdacityUrl(urlString: string): boolean {
  try {
    const redirectionUrl = new URL(urlString);

    if (redirectionUrl.hostname.match(/(\.|^)udacity\.com$/)) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
}

export const updateWindowHref = (redirectionRoute: string): boolean => {
  try {
    // verify the redirection route is a udacity subdomain url,
    // not a phishing attack redirect
    // nor a `javascript:` path to avoid xss security attack

    // returns a "success" boolean to allow the caller to perform an error
    // handling action

    if (!isUdacityUrl(redirectionRoute)) return false;

    window.location.href = redirectionRoute;
    return true;
  } catch {
    // return false, probably a test or a bad url
    return false;
  }
};
