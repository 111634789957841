import { addProjectsReportData, addRosterData } from 'app/actions/reports';
import { lensPath, set, values } from 'ramda';

import { ReportsReduxState } from './types';
import { updateEnrollmentStuCount } from 'app/actions/stu';

const initialState: ReportsReduxState = {
  roster: {},
  projects: {},
};

export default function reports(
  state: ReportsReduxState = initialState,
  action:
    | ReturnType<typeof addRosterData>
    | ReturnType<typeof addProjectsReportData>
    | ReturnType<typeof updateEnrollmentStuCount>
): ReportsReduxState {
  switch (action.type) {
    case 'ADD_ROSTER_REPORT_DATA': {
      return set(
        lensPath(['roster']),
        { ...state.roster, ...action.payload },
        state
      );
    }

    case 'ADD_PROJECTS_REPORT_DATA': {
      return set(
        lensPath(['projects']),
        { ...state.projects, ...action.payload },
        state
      );
    }

    case 'UPDATE_ENROLLMENT_STU_COUNT': {
      const { enrollmentIds, stuCountOverride } = action.payload;
      const rosterIds = values(state.roster)
        .filter((enrollment) =>
          (enrollmentIds as string[]).includes(enrollment.enrollment_id)
        )
        .map((val) => val.rc);
      return {
        ...state,
        roster: {
          ...state.roster,
          ...rosterIds.reduce((result, rosterId) => {
            result[rosterId] = {
              ...state.roster[rosterId],
              [state.roster[rosterId].pending_stu_count
                ? 'pending_stu_count'
                : 'consumed_stu_count']: stuCountOverride,
            };
            return result;
          }, {}),
        },
      };
    }

    default: {
      return state;
    }
  }
}
