import React, { useCallback } from 'react';

import { IconLogout } from '@udacity/veritas-icons';
import { UnstyledButton } from '@emc/elements/unstyled-button';
import auth from '@udacity/ureact-hoth';
import classNames from 'classnames';
import i18n from 'i18next';
import styles from './styles.module.scss';

export const Logout: React.FC<{ isExpanded: boolean }> = ({ isExpanded }) => {
  const handleSignout = useCallback(() => auth.signOut(), []);
  if (!auth.isAuthenticated()) return null;

  return (
    <UnstyledButton onClick={handleSignout}>
      <div className={classNames(styles.navItem, styles.logoutButtonContainer)}>
        {isExpanded && <span>{i18n.t('common.logOut')} </span>}
        <IconLogout size="lg" />
      </div>
    </UnstyledButton>
  );
};
