import { ManageWeb } from '@emc/elements/manage-web/embed';
import { Navigation } from './navigation';
import React from 'react';
import { UdacityLogo } from 'app/icons/udacity-logo';
import styles from './styles.module.scss';
import { useIframeCommunication } from 'app/hooks/use-iframe-communication';

export const RouteContainer: React.FC = ({ children }) => {
  useIframeCommunication();
  return (
    <>
      <div className={styles.colorBlock}>
        <UdacityLogo height={30} width={70} viewBox="0 0 120 190" inverted />
      </div>
      <div className={styles.appContainer}>
        <Navigation />
        <main className={styles.pageContent}>
          {children}
          <ManageWeb />
        </main>
      </div>
    </>
  );
};

export default RouteContainer;
