import {
  AllContractsQuery,
  AllContractsQueryVariables,
  Company,
  GetCompanyContractsQuery,
  Status,
  UpdateContractMutation,
  UpdateContractMutationVariables,
} from 'app/types/generated/emc';
import { AppThunk, IGqlResponse } from 'app/types/shared';
import {
  CompanyNormalizedEntities,
  companyNormSchema,
} from 'app/utils/normalize/company';
import { PaginationProps, getPaginationData } from 'app/selectors/pagination';
import { addFlashMessage, addServerError } from '../flash';
import {
  getPaginatedItems,
  getQueryString,
} from 'app/utils/graphql/serializer';
import { startLoading, stopLoading } from '../loading';

import { ContractsReduxState } from 'app/reducer/data/types';
import { LoadingType } from '@emc/loading-types';
import { NormalizedContract } from 'app/types/contracts';
import { PaginationTypes } from '@emc/reducer/session/pagination';
import { addContractTermEnrollmentCounts } from '../companies';
import { addContractTerms } from '../contract-terms/contract-terms';
import { addResources } from '../resources';
import { batch } from 'react-redux';
import { createAction } from 'app/utils/actions';
import { emcService } from '@emc/services';
import { filterOutAllTimeQueryParam } from 'app/utils/dates/defaults';
import { getGraphQLErrorMessage } from 'app/utils/errors';
import { getQueryParams } from 'app/utils/router/query-params';
import { loader } from 'graphql.macro';
import { normalize } from 'normalizr';
import { updatePagination } from '../reports';

// Sync

export function addContracts(contracts: ContractsReduxState) {
  return createAction('ADD_CONTRACTS', contracts);
}

export function sessionContractUpdateProperty(
  contractId: string,
  status: Status,
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
) {
  return createAction('SESSION_CONTRACT_UPDATE_PROPERTY', {
    contractId,
    status,
    key,
    value,
  });
}

// Async

export function fetchCompanyContracts(companySlug: string): AppThunk {
  return (dispatch) => {
    dispatch(startLoading('CONTRACTS'));

    return emcService
      .gql(
        getQueryString(loader('@emc/queries/emc/get-company-contracts.gql')),
        {
          companySlug,
        }
      )
      .then((res: IGqlResponse<GetCompanyContractsQuery>) => {
        const company = res.data.data?.companyBySlug;
        if (!company) {
          throw new Error(`${companySlug} could not be fetched`);
        }

        const normalizedData = normalize<Company, CompanyNormalizedEntities>(
          company,
          companyNormSchema
        );

        const {
          entities: {
            contracts = {},
            contractTerms = {},
            resources = {},
            contractTermEnrollmentCounts = {},
          },
        } = normalizedData;
        dispatch(addContractTermEnrollmentCounts(contractTermEnrollmentCounts));
        dispatch(addContracts(contracts));
        dispatch(addContractTerms(contractTerms));
        dispatch(addResources(company.id, resources));
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('CONTRACTS')));
  };
}

export function fetchContracts(paginationProps: PaginationProps): AppThunk {
  return (dispatch) => {
    dispatch(startLoading('CONTRACTS'));

    const paginationData = getPaginationData(paginationProps);
    const { companyId, companyTypeId, status } = getQueryParams();

    const variables: AllContractsQueryVariables = {
      ...paginationData,
      status: filterOutAllTimeQueryParam(status) as Status,
      companyId: filterOutAllTimeQueryParam(companyId),
      companyTypeId: filterOutAllTimeQueryParam(companyTypeId),
    };

    return emcService
      .gql(
        getQueryString(loader('@emc/queries/emc/all-contracts.gql')),
        variables
      )
      .then((res: IGqlResponse<AllContractsQuery>) => {
        const allContracts = res.data.data?.allContracts;
        const totalContractCount = allContracts?.totalCount || 0;
        const normalizedData = normalize<Company, CompanyNormalizedEntities>(
          { id: 'all', contracts: allContracts },
          companyNormSchema
        );
        const contracts = normalizedData?.entities?.contracts || {};
        const contractIds =
          getPaginatedItems(
            normalizedData?.entities?.companies?.all.contracts
          ) || [];

        batch(() => {
          dispatch(addContracts(contracts));
          dispatch(
            updatePagination(
              PaginationTypes.contractsOverview,
              totalContractCount,
              contractIds
            )
          );
        });

        return null;
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('CONTRACTS')));
  };
}

type UpdateContractResponse = IGqlResponse<UpdateContractMutation>;
export function updateContract(
  contractId: string,
  variables: UpdateContractMutationVariables
): AppThunk<Promise<{ isSuccess: boolean; isMigrating: boolean }>> {
  return (dispatch) => {
    const loadingKey = `CONTRACT_${contractId}`;
    dispatch(startLoading(loadingKey as LoadingType));

    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/update-contract.gql')), {
        ...variables,
        // TODO: simplify upon backend enum update as status will be Status enum
        status: variables.status?.toLowerCase(),
      })
      .then((res: UpdateContractResponse) => {
        const data = res.data.data?.updateContract;
        if (data?.ok !== true) {
          // If not STU set on term, redirect to update STU Price page to allow contract migration
          if (
            res.data.errors &&
            res.data.errors[0].message.match(/pricing not available for/)
          ) {
            dispatch(stopLoading(loadingKey as LoadingType));
            return { isSuccess: false, isMigrating: true };
          } else {
            throw new Error(getGraphQLErrorMessage(res.data.errors));
          }
        }

        const contract = data?.contract;

        if (contract) {
          batch(() => {
            dispatch(
              addContracts({ [contractId]: contract as NormalizedContract })
            );
            dispatch(addFlashMessage('success', 'Contract Updated'));
          });
        }
        return { isSuccess: true, isMigrating: false };
      })
      .catch((error) => {
        dispatch(addServerError(error));
        return { isSuccess: false, isMigrating: false };
      })
      .then((isSuccess) => {
        dispatch(stopLoading(loadingKey as LoadingType));
        return isSuccess;
      });
  };
}
