import {
  addContractablePrograms,
  decreaseInterestCount,
  increaseInterestCount,
} from 'app/actions/programs';

import { ContractableProgramsReduxState } from './types';

const initialState = {};

export default function companyPrograms(
  state: ContractableProgramsReduxState = initialState,
  action:
    | ReturnType<typeof addContractablePrograms>
    | ReturnType<typeof increaseInterestCount>
    | ReturnType<typeof decreaseInterestCount>
): ContractableProgramsReduxState {
  switch (action.type) {
    case 'ADD_CONTRACTABLE_PROGRAMS': {
      const { companyId, programs } = action.payload;
      return {
        [companyId]: programs,
      };
    }

    case 'INCREASE_INTEREST_COUNT': {
      const { programKey, companyId } = action.payload;
      const programs = state[companyId];
      return {
        [companyId]: programs.map((program) => {
          if (program.catalogProgram?.programKey === programKey) {
            return {
              ...program,
              interestCount: program.interestCount + 1,
            };
          }
          return program;
        }),
      };
    }

    case 'DECREASE_INTEREST_COUNT': {
      const { programKey, companyId } = action.payload;
      const programs = state[companyId];
      return {
        [companyId]: programs.map((program) => {
          if (program.catalogProgram?.programKey === programKey) {
            return {
              ...program,
              interestCount: program.interestCount - 1,
            };
          }
          return program;
        }),
      };
    }

    default: {
      return state;
    }
  }
}
