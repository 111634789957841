import { Action } from 'redux';
import { ActionType } from 'app/types/actions';
import { DEFAULT_KEY } from 'redux-cache';
import { IAction } from '../actions';
import { generateCacheTTL } from 'redux-cache';

const ONE_HOUR_MS = 3600000;

export const generateTTL = () => generateCacheTTL(ONE_HOUR_MS);

const initialState = {
  [DEFAULT_KEY]: null,
};

export function makeCacheReducer(actionType: string) {
  return (state = initialState, action: Action) => {
    switch (action.type) {
      case actionType: {
        return {
          ...state,
          [DEFAULT_KEY]: generateTTL(),
        };
      }

      default: {
        return state;
      }
    }
  };
}

export function makeIndexedCacheReducer(
  actionType: ActionType,
  invalidateActionType?: ActionType
) {
  return (state = {}, action: IAction<ActionType, string>) => {
    if (action.type === actionType) {
      return {
        ...state,
        [action.payload]: {
          [DEFAULT_KEY]: generateTTL(),
        },
      };
    }

    if (invalidateActionType && action.type === invalidateActionType) {
      return {
        ...state,
        [action.payload]: {
          [DEFAULT_KEY]: null,
        },
      };
    }

    return state;
  };
}
