import { CompanyTypesReduxState } from './types';
import { addCompanyTypes } from 'app/actions/companies';

export default function companyTypes(
  state: CompanyTypesReduxState = [],
  action: ReturnType<typeof addCompanyTypes>
): CompanyTypesReduxState {
  switch (action.type) {
    case 'SET_COMPANY_TYPES': {
      return action.payload;
    }

    default: {
      return state;
    }
  }
}
