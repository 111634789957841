import { useEffect, useRef } from 'react';

import styles from './styles.module.scss';

type Props = {
  shouldPreventScroll: boolean;
};

export const NoScroll: React.FC<Props> = ({ shouldPreventScroll }) => {
  const htmlEl = useRef(document.querySelector('html, body'));

  useEffect(() => {
    if (!htmlEl.current) return;
    const body = htmlEl.current;

    if (shouldPreventScroll) {
      body.classList.add(styles['no-scroll']);
    } else {
      body.classList.remove(styles['no-scroll']);
    }
    return () => {
      body.classList.remove(styles['no-scroll']);
    };
  }, [shouldPreventScroll]);

  return null;
};
