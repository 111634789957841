import { Loading } from '@udacity/veritas-components';
import React from 'react';
import { isMobileWidth } from 'app/utils/component';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

export const FullPageLoading: React.FC = () => {
  const isMobile = isMobileWidth();
  const { t } = useTranslation();

  return (
    <div aria-live="polite">
      <Loading busy>
        <div className={styles.base}>{isMobile ? '' : t('common.loading')}</div>
      </Loading>
    </div>
  );
};
