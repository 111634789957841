import {
  ErrorOccurred,
  shouldForceRefreshForChunk,
} from '@emc/error-views/error-occurred';
import React, { Component } from 'react';

import { datadogRum } from '@datadog/browser-rum';

type Props = {
  children: JSX.Element;
  showChildren?: boolean;
};

type State = {
  hasError: boolean;
  error: Error;
};

export class ErrorBoundary extends Component<Props, State> {
  state = { hasError: false, error: new Error() };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (!shouldForceRefreshForChunk(error?.message)) {
      datadogRum.addError(error);
    }
  }

  render() {
    if (this.state.hasError && !this.props.showChildren) {
      return <ErrorOccurred error={this.state.error} />;
    }

    return this.props.children || null;
  }
}
