import { ALL, CUSTOM } from '../constants';

export const SEVEN_DAYS = 7;
export const FOURTEEN_DAYS = 14;
export const THIRTY_DAYS = 30;
export const NINETY_DAYS = 90;
export const ONE_HUNDRED_EIGHTY_DAYS = 180;

const daysAgo: number[] = [
  SEVEN_DAYS,
  FOURTEEN_DAYS,
  THIRTY_DAYS,
  NINETY_DAYS,
  ONE_HUNDRED_EIGHTY_DAYS,
];

export const defaultDateRanges: (number | 'all' | 'custom')[] = [
  ...daysAgo,
  ALL,
  CUSTOM,
];

export function filterOutAllTimeQueryParam(value: string | undefined) {
  return value === ALL ? undefined : value;
}
