import { concat, mergeDeepRight } from 'ramda';

import { ResourcesReduxState } from './types';
import { addResources } from 'app/actions/resources';

const initialState: ResourcesReduxState = {
  all: {},
  byCompany: {},
};

export default function resources(
  state: ResourcesReduxState = initialState,
  action: ReturnType<typeof addResources>
): ResourcesReduxState {
  switch (action.type) {
    case 'ADD_RESOURCES': {
      const { resources, companyId } = action.payload;
      const resourceIds = Object.keys(resources);
      const existingCompanyResourceIds = state.byCompany[companyId] || [];
      const companyResourceIds = new Set(
        concat(resourceIds, existingCompanyResourceIds)
      );

      return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        all: mergeDeepRight(state.all, resources as any),
        byCompany: {
          ...state.byCompany,
          [companyId]: Array.from(companyResourceIds),
        },
      };
    }

    default: {
      return state;
    }
  }
}
