import { AxiosPromise, AxiosRequestConfig } from 'axios';

import CONFIG from '@emc/config';
import { api } from './api-service';

export const emcService = {
  delete(path: string): AxiosPromise {
    return api.request({
      method: 'DELETE',
      url: `${CONFIG.REACT_APP_EMC_CONTENT_URL}${path}`,
      headers: this.headers,
    });
  },
  fetch(
    path: string,
    params?: { [key: string]: string | number | boolean | undefined },
    options?: AxiosRequestConfig
  ): AxiosPromise {
    return api.request({
      method: 'GET',
      url: `${CONFIG.REACT_APP_EMC_CONTENT_URL}${path}`,
      params,
      ...options,
      headers: {
        ...this.headers,
        ...options?.headers,
      },
    });
  },
  patch(path: string, data?: unknown): AxiosPromise {
    return api.request({
      method: 'PATCH',
      url: `${CONFIG.REACT_APP_EMC_CONTENT_URL}${path}`,
      headers: this.headers,
      data,
    });
  },
  post(
    path: string,
    data?: unknown,
    options?: AxiosRequestConfig
  ): AxiosPromise {
    return api.request({
      method: 'POST',
      url: `${CONFIG.REACT_APP_EMC_CONTENT_URL}${path}`,
      data,
      ...options,
      headers: {
        ...this.headers,
        ...options?.headers,
      },
    });
  },
  put(path: string, data?: unknown): AxiosPromise {
    return api.request({
      method: 'PUT',
      url: `${CONFIG.REACT_APP_EMC_CONTENT_URL}${path}`,
      headers: this.headers,
      data,
    });
  },
  gql(
    queryString: string,
    variables?: Record<string, unknown>,
    options?: AxiosRequestConfig
  ): AxiosPromise {
    return this.post(
      '/gql/query/public',
      { query: queryString, variables },
      options
    );
  },
  gqlCsv(
    queryString: string,
    variables: Record<string, unknown>,
    row_query: string[],
    column_queries: CsvColumnQuery[]
  ): AxiosPromise {
    return this.post(
      '/gql/query/public',
      {
        query: queryString,
        variables,
        row_query,
        column_queries,
      },
      {
        headers: { Accept: 'text/csv' },
      }
    );
  },
  headers: {},
  setRequestHeaders(newHeaders: Record<string, string>) {
    this.headers = {
      ...this.headers,
      ...newHeaders,
    };
  },
};

export type CsvColumnQuery = {
  title: string;
  lookup: (string | number)[];
};
