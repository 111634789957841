import { ALL, CUSTOM } from '../../constants';
import {
  DateRangeTypes,
  IDateRangeData,
} from 'app/selectors/charts/learner-activity/util';
import dayjs, { Dayjs } from 'dayjs';
import {
  filter,
  groupBy,
  head,
  isEmpty,
  isNil,
  last,
  length,
  pipe,
  pluck,
  prop,
  range,
  reduce,
  sortBy,
} from 'ramda';

import { ALL_TIME_ISO } from '../format';
import { IChartData } from 'app/types/charts';
import { Normalized } from 'app/types/shared';
import _map from 'lodash/map';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import duration from 'dayjs/plugin/duration';

dayjs.extend(dayOfYear);
dayjs.extend(duration);

const daysInYear = 365;
const weeksInYear = 52;
const monthsInYear = 12;

export const defaultDateLabel = 'ddd, MMM D';

const getSortedTimeStamps = (timestamps: (string | Date)[]) =>
  timestamps.map((ts) => new Date(ts).getTime()).sort();
/*
Given an array of timestamps, a date range type and a date range value, returns
sorted values and labels for charting.

Args:
  timestamps (array): List of time stamps (optional)
  dateRangeOptions (object): {
    dateRangeType (str): Type of time interval ["day", "week", "month", "year", "all", "custom"]
    dateRangeValue (int): n-1 x-axis values
  }
  chartKey (string): Name of the chart
  endDate (timestamp): Timestamp that intervals are relative to (defaults to now)

Returns:
  dateStreamChartData (object)
    chartData (array): [{
      [chartKey] (int): Value corresponding to label in xAxisLabels[n]
      key (int): key representing the unique time value / data point
      iso (timestamp): timestamp representing the unique time value / data point
    }]
    chartKeys (array): Array containing the given chartKey (formatted for stream chart) [chartKey]
    xAxisLabels (array): Array containing sorted x axis labels

Example:

getDateStreamChartData([], {dateRangeValue: 6, dateRangeType: 'days'})

{
  chartData: [
    {Data: 0, key: 736509, iso: "2018-10-31T07:00:00.000Z", label: "Wed, Oct 31"},
    {Data: 0, key: 736510, iso: "2018-11-01T07:00:00.000Z", label: "Thu, Nov 1"},
    {Data: 0, key: 736511, iso: "2018-11-02T07:00:00.000Z", label: "Fri, Nov 2"},
    {Data: 0, key: 736512, iso: "2018-11-03T07:00:00.000Z", label: "Sat, Nov 3"},
    {Data: 0, key: 736513, iso: "2018-11-04T07:00:00.000Z", label: "Sun, Nov 4"},
    {Data: 0, key: 736514, iso: "2018-11-05T08:00:00.000Z", label: "Mon, Nov 5"}
    {Data: 0, key: 736515, iso: "2018-11-06T08:00:00.000Z", label: "Tue, Nov 6"}
  ]
  chartKeys: ["Data"],
  xAxisLables: ["Wed, Oct 31", "Thu, Nov 1", "Fri, Nov 2", "Sat, Nov 3", "Sun, Nov 4", "Mon, Nov 5", "Tue, Nov 6"]
}
*/
type TimeLabelData = {
  createDate: string;
  isoString: string;
  label: string;
};
export function getDateStreamChartData(
  timestamps: string[] = [],
  dateRangeOptions: IDateRangeData,
  chartKey = 'Data'
) {
  let { dateRangeValue, dateRangeType } = dateRangeOptions;
  let endDate = dayjs();

  if (dateRangeType === ALL) {
    const allTimeTimeStamps = isEmpty(timestamps)
      ? getAllTimeTimeStamps()
      : timestamps;
    const dynamicDateRangeData = getDynamicDateRange(allTimeTimeStamps);
    const latestTimestamp = last(getSortedTimeStamps(timestamps));

    dateRangeValue = dynamicDateRangeData.dateRangeValue;
    dateRangeType = dynamicDateRangeData.dateRangeType;
    endDate = dayjs(latestTimestamp);
  }

  let groupFunc: (
      data: Normalized<TimeLabelData[]>,
      dateRangeValue: number,
      endDate: Dayjs
    ) => Normalized<
      (TimeLabelData & {
        iso: string;
      })[]
    >,
    getGroupByKeyFunc: (date: Dayjs) => { index: number },
    getLabelFunc: (date: Dayjs, dateRangeValue?: number | string) => string;
  switch (dateRangeType) {
    case DateRangeTypes.day:
      groupFunc = groupByDays;
      getGroupByKeyFunc = getDayKeys;
      getLabelFunc = getDayLabel;
      break;
    case DateRangeTypes.week:
      groupFunc = groupByWeeks;
      getGroupByKeyFunc = getWeekKeys;
      getLabelFunc = getWeekLabel;
      break;
    case DateRangeTypes.month:
      groupFunc = groupByMonths;
      getGroupByKeyFunc = getMonthKeys;
      getLabelFunc = getMonthLabel;
      break;
    case DateRangeTypes.year:
    default:
      groupFunc = groupByYears;
      getGroupByKeyFunc = getYearKeys;
      getLabelFunc = getYearLabel;
  }

  const timeLabels = timestamps.map((createDate) => {
    const date = dayjs(createDate);

    return {
      dayjsDate: date,
      createDate,
      isoString: date.toISOString(),
      label: getLabelFunc(date, dateRangeValue),
    };
  });
  const groupedByKey = groupBy((data) => {
    const { dayjsDate } = data;
    return String(getGroupByKeyFunc(dayjsDate).index);
  }, timeLabels);
  const groupedData = groupFunc(
    groupedByKey,
    dateRangeValue as number,
    endDate
  );
  const formattedData = _map(groupedData, formatChartData(chartKey));
  const groupedChartData = sortBy(prop('key'))(formattedData);
  const totalCount = reduce(
    (total, data) => {
      return total + data[chartKey] || 0;
    },
    0,
    groupedChartData
  );
  return {
    totalCount,
    chartData: groupedChartData,
    xAxisLabels: pluck('label', groupedChartData),
    chartKeys: [chartKey],
  };
}

function getDayKeys(date: Dayjs) {
  const previousYear = date.year() - 1;
  const currentDayOfYear = date.dayOfYear(); // 1 to 366
  const daysUpToCurrentYear = previousYear * daysInYear;
  const index = daysUpToCurrentYear + currentDayOfYear;

  return {
    daysUpToCurrentYear,
    index,
  };
}

function getDayLabel(date: Dayjs, dateRangeValue?: number | string) {
  if (dateRangeValue || 0 <= 7) {
    return date.format('ddd');
  }

  return date.format(defaultDateLabel);
}

function groupByDays(
  data: Normalized<TimeLabelData[]>,
  dateRangeValue: number,
  endDate: Dayjs
): Normalized<(TimeLabelData & { iso: string })[]> {
  const dayKeys = getDayKeys(endDate);
  const currentDayIndex = dayKeys.index;
  const daysUpToCurrentYear = dayKeys.daysUpToCurrentYear;
  const rangeKeys = range(
    currentDayIndex - dateRangeValue,
    currentDayIndex + 1
  );

  return rangeKeys.reduce<Normalized<(TimeLabelData & { iso: string })[]>>(
    (formattedData, dayIndex) => {
      const dayOfYear = dayIndex - daysUpToCurrentYear;
      const day = endDate.dayOfYear(dayOfYear).startOf('day');

      const iso = day.toISOString();
      const label = getDayLabel(day, dateRangeValue);
      const existingData = data[dayIndex];

      if (existingData) {
        const dataWithLabels = _map(existingData, (date) => ({
          ...date,
          iso,
        }));
        formattedData[String(dayIndex)] = dataWithLabels;
        return formattedData;
      }

      formattedData[String(dayIndex)] = [
        { label, iso } as TimeLabelData & { iso: string },
      ];
      return formattedData;
    },
    {}
  );
}

function getWeekKeys(date: Dayjs) {
  const previousYear = date.year() - 1;
  const currentWeekOfYear = date.isoWeek(); // 1 to 53
  const weeksUpToCurrentYear = previousYear * weeksInYear;
  const index = weeksUpToCurrentYear + currentWeekOfYear;

  return {
    weeksUpToCurrentYear,
    index,
  };
}

function getWeekLabel(date: Dayjs) {
  return date.startOf('week').add(1, 'days').format(defaultDateLabel);
}

function groupByWeeks(
  data: Normalized<TimeLabelData[]>,
  dateRangeValue: number,
  endDate: Dayjs
) {
  const weekKeys = getWeekKeys(endDate);
  const currentWeekIndex = weekKeys.index;
  const weeksUpToCurrentYear = weekKeys.weeksUpToCurrentYear;
  const rangeKeys = range(
    currentWeekIndex - dateRangeValue,
    currentWeekIndex + 1
  );

  return rangeKeys.reduce<Normalized<(TimeLabelData & { iso: string })[]>>(
    (formattedData, weekIndex) => {
      const weekOfYear = weekIndex - weeksUpToCurrentYear;
      const date = endDate.isoWeek(weekOfYear);
      const formattedStartOfWeek = date.startOf('week').add(1, 'days');
      const iso = formattedStartOfWeek.toISOString();
      const label = getWeekLabel(formattedStartOfWeek);
      const existingData = data[weekIndex];

      if (existingData) {
        const dataWithLabels = _map(existingData, (date) => ({
          ...date,
          iso,
        }));
        formattedData[String(weekIndex)] = dataWithLabels;
        return formattedData;
      }

      formattedData[String(weekIndex)] = [
        { label, iso } as TimeLabelData & { iso: string },
      ];
      return formattedData;
    },
    {}
  );
}

function getMonthKeys(date: Dayjs) {
  const previousYear = date.year() - 1;
  const currentMonthOfYear = date.month() + 1; // 1 to 12
  const monthsUpToCurrentYear = previousYear * monthsInYear;
  const index = monthsUpToCurrentYear + currentMonthOfYear;

  return {
    monthsUpToCurrentYear,
    index,
  };
}

function getMonthLabel(date: Dayjs) {
  return date.startOf('month').format("MMM 'YY");
}

function groupByMonths(
  data: Normalized<TimeLabelData[]>,
  dateRangeValue: number,
  endDate: Dayjs
) {
  const monthKeys = getMonthKeys(endDate);
  const currentMonthIndex = monthKeys.index;
  const monthsUpToCurrentYear = monthKeys.monthsUpToCurrentYear;
  const rangeKeys = range(
    currentMonthIndex - dateRangeValue,
    currentMonthIndex + 1
  );

  return rangeKeys.reduce<Normalized<(TimeLabelData & { iso: string })[]>>(
    (formattedData, monthIndex) => {
      const monthOfYear = monthIndex - monthsUpToCurrentYear - 1;
      const formattedMonthOfYear = endDate.month(monthOfYear).startOf('month');
      const iso = formattedMonthOfYear.toISOString();
      const label = getMonthLabel(formattedMonthOfYear);
      const existingData = data[monthIndex];

      if (existingData) {
        const dataWithLabels = _map(existingData, (date) => ({
          ...date,
          iso,
        }));
        formattedData[String(monthIndex)] = dataWithLabels;
        return formattedData;
      }

      formattedData[String(monthIndex)] = [
        { label, iso } as TimeLabelData & { iso: string },
      ];
      return formattedData;
    },
    {}
  );
}

function getYearKeys(date: Dayjs) {
  return {
    index: date.year(),
  };
}

function getYearLabel(date: Dayjs) {
  return String(date.year());
}

function groupByYears(
  data: Normalized<TimeLabelData[]>,
  dateRangeValue: number,
  endDate: Dayjs
) {
  const currentYearIndex = getYearKeys(endDate).index;
  const rangeKeys = range(
    currentYearIndex - dateRangeValue,
    currentYearIndex + 1
  );

  return rangeKeys.reduce<Normalized<(TimeLabelData & { iso: string })[]>>(
    (formattedData, year) => {
      const iso = endDate.year(year).startOf('year').toISOString();

      const existingData = data[year];

      if (existingData) {
        const dataWithLabels = _map(existingData, (date) => ({
          ...date,
          iso,
        }));
        formattedData[String(year)] = dataWithLabels;
        return formattedData;
      }

      formattedData[String(year)] = [
        { label: String(year), iso } as TimeLabelData & { iso: string },
      ];
      return formattedData;
    },
    {}
  );
}

function formatChartData(chartKey: string) {
  return (
    data: { iso: string; label: string; createDate: string }[],
    key: string
  ): IChartData => {
    const value = pipe(
      pluck('createDate'),
      filter((createDate) => !isNil(createDate)),
      length
    )(data);
    const firstValue = head(data);
    const iso = firstValue?.iso || '';
    const label = firstValue?.label || '';

    return {
      [chartKey]: value,
      key: Number(key),
      iso,
      label,
    };
  };
}

export function getAllTimeTimeStamps() {
  return [ALL_TIME_ISO, dayjs().toISOString()];
}

export function getDynamicDateRangeFromDaysAgo(
  daysAgo: number | string
): IDateRangeData {
  if (daysAgo === ALL) {
    return { dateRangeValue: ALL, dateRangeType: DateRangeTypes.all };
  }

  if (daysAgo === CUSTOM) {
    return { dateRangeValue: CUSTOM, dateRangeType: DateRangeTypes.custom };
  }

  const now = dayjs();
  const timestamps = [
    now.subtract(daysAgo as number, 'day').toISOString(),
    now.toISOString(),
  ];
  return getDynamicDateRange(timestamps);
}

export function getDynamicDateRange(
  timestamps: (string | Date)[]
): IDateRangeData {
  const hasOneValueOrLess = length(timestamps) <= 1;
  const sortedTimeStamps = getSortedTimeStamps(timestamps);
  const earliestStamp = head(sortedTimeStamps) || 0;
  const latestStamp = last(sortedTimeStamps) || 0;

  if (hasOneValueOrLess) {
    return { dateRangeValue: 1, dateRangeType: DateRangeTypes.year };
  }

  const durationInMilliseconds = latestStamp - earliestStamp;
  const duration = dayjs.duration(durationInMilliseconds);
  const durationAsYears = duration.asYears();
  const durationAsMonths = duration.asMonths();
  const durationAsWeeks = duration.asWeeks();
  const durationAsDays = duration.asDays();

  if (durationAsDays < 28) {
    return {
      dateRangeValue: Math.ceil(durationAsDays),
      dateRangeType: DateRangeTypes.day,
    };
  }

  if (durationAsWeeks <= 26) {
    return {
      dateRangeValue: Math.ceil(durationAsWeeks),
      dateRangeType: DateRangeTypes.week,
    };
  }

  if (durationAsMonths <= 60) {
    return {
      dateRangeValue: Math.ceil(durationAsMonths),
      dateRangeType: DateRangeTypes.month,
    };
  }

  return {
    dateRangeValue: Math.ceil(durationAsYears),
    dateRangeType: DateRangeTypes.year,
  };
}
