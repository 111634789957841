import { IAction } from 'app/utils/actions';
import { IDataState } from './types';
import assessmentCohorts from './assessment-cohorts';
import assessments from './assessments';
import catalog from './catalog';
import companies from './companies';
import companyGraphLearningPaths from './company-graph-learning-paths';
import companyPrograms from './company-programs';
import companyTypes from './company-types';
import contractTerms from './contract-terms';
import contractablePrograms from './contractable-programs';
import contracts from './contracts';
import enrollmentCohorts from './enrollment-cohorts';
import enrollments from './enrollments';
import enterpriseCatalog from './enterprise-catalog';
import graphLearningPaths from './graph-learning-paths';
import groups from './groups';
import interest from './interest';
import learners from './learners';
import parentCompanies from './parent-companies';
import { persistCombineReducers } from 'redux-persist';
import questionSets from './question-sets';
import reports from './reports';
import resources from './resources';
import serviceModel from './service-models';
import storage from 'redux-persist/es/storage';

const persistConfig = {
  key: 'data',
  storage,
  whitelist: ['catalog', 'companyTypes'],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default persistCombineReducers<IDataState, IAction<any, any>>(
  persistConfig,
  {
    assessmentCohorts,
    assessments,
    catalog,
    companies,
    companyGraphLearningPaths,
    companyPrograms,
    companyTypes,
    contracts,
    contractTerms,
    contractablePrograms,
    enrollmentCohorts,
    enrollments,
    enterpriseCatalog,
    graphLearningPaths,
    groups,
    interest,
    learners,
    parentCompanies,
    questionSets,
    reports,
    resources,
    serviceModel,
  }
);
