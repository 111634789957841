import { EnterpriseCatalogReduxState } from 'app/reducer/data/types';
import { IRootStore } from '@emc/reducer/types';
import { LoadingState } from '@emc/reducer/session/loading';
import { createSelector } from 'reselect';
import { loadingSelector } from '../session';
import { map } from 'ramda';

export const isLoadingCatalogSelector = createSelector<
  [(state: IRootStore) => LoadingState],
  boolean
>(loadingSelector, (loadingState) => !!loadingState.CATALOG);

export const classroomCatalogSelector = (state: IRootStore) =>
  state.data.catalog.classroom;

export const enterpriseCatalogSelector = (state: IRootStore) =>
  state.data.enterpriseCatalog;

export const syllabusUrlsByProgramSelector = createSelector<
  [(state: IRootStore) => EnterpriseCatalogReduxState],
  { [key: string]: string | null | undefined }
>([enterpriseCatalogSelector], (enterpriseCatalog) => {
  return getCatalogUrls(enterpriseCatalog);
});

const getCatalogUrls = (
  catalog: EnterpriseCatalogReduxState
): { [key: string]: string | null | undefined } => {
  return map((program) => program.catalogUrl, catalog);
};
