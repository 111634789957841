/* eslint @typescript-eslint/no-use-before-define: 0, @typescript-eslint/no-explicit-any: 0 */

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * Replacement for graphene.String to safely prepare text for database storage.  Currently, we
   * just strip away NUL characters, which will cause Postgres errors.
   *
   * See https://stackoverflow.com/questions/57371164/django-postgres-a-string-literal-cannot-contain-nul-0x00-characters
   */
  SafeString: any;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
  /** DateTime formatted like so: "2019-11-26T01:17:46.000000Z" */
  ZuluDateTime: any;
};

/** Accept the Terms of Use, either as yourself or on a user's behalf. */
export type AcceptTermsOfUseVersion = {
  __typename?: 'AcceptTermsOfUseVersion';
  ok?: Maybe<Scalars['Boolean']>;
};

/**
 * From the provided Enrollments, it adds the Contract Terms to the Enrollment Cohort, adds the
 * the Learners to the Enrollment Cohort, and associates the Learners with the Contract Terms. It
 * then updates the claims in Registrar.
 */
export type AddEnrollmentsToEnrollmentCohort = {
  __typename?: 'AddEnrollmentsToEnrollmentCohort';
  enrollmentCohort?: Maybe<EnrollmentCohort>;
};

export type AddLearnersToContract = {
  __typename?: 'AddLearnersToContract';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddLearnersToGroup = {
  __typename?: 'AddLearnersToGroup';
  ok?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Array<LearnerGroup>>;
};

export type AddLearnersToLearningPath = {
  __typename?: 'AddLearnersToLearningPath';
  ok?: Maybe<Scalars['Boolean']>;
};

export type AddRoleToLearner = {
  __typename?: 'AddRoleToLearner';
  learner?: Maybe<Learner>;
};

export type AddTalentPlanLink = {
  __typename?: 'AddTalentPlanLink';
  talentPlan?: Maybe<TalentPlan>;
};

export type AdmissionQuestion = {
  __typename?: 'AdmissionQuestion';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  status?: Maybe<Scalars['String']>;
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  questionSetId?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  order: Scalars['Int'];
  required: Scalars['Boolean'];
  priority: Scalars['Boolean'];
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  correctAnswers?: Maybe<Array<Maybe<Scalars['String']>>>;
  filterLabel?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  type: Scalars['String'];
  questionSet?: Maybe<QuestionSet>;
  responses?: Maybe<Array<Maybe<AdmissionQuestionResponse>>>;
};

export type AdmissionQuestionInput = {
  id?: Maybe<Scalars['ID']>;
  label: Scalars['String'];
  type?: Maybe<AdmissionQuestionType>;
  required?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<Scalars['String']>>;
  correctAnswers?: Maybe<Array<Scalars['String']>>;
  filterLabel?: Maybe<Scalars['String']>;
  clearFilterLabel?: Maybe<Scalars['Boolean']>;
};

export type AdmissionQuestionResponse = {
  __typename?: 'AdmissionQuestionResponse';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  admissionQuestionId?: Maybe<Scalars['String']>;
  questionSetId?: Maybe<Scalars['String']>;
  learnerId?: Maybe<Scalars['String']>;
  learnerInterestId?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  isCorrectResponse?: Maybe<Scalars['Boolean']>;
  admissionQuestion?: Maybe<AdmissionQuestion>;
  learnerInterest?: Maybe<LearnerInterest>;
};

export type AdmissionQuestionResponseInput = {
  questionSetId: Scalars['String'];
  admissionQuestionId: Scalars['String'];
  response?: Maybe<Scalars['SafeString']>;
};

export enum AdmissionQuestionType {
  Checkbox = 'CHECKBOX',
  Text = 'TEXT',
  TextArea = 'TEXT_AREA',
  Radio = 'RADIO',
  Select = 'SELECT',
  Section = 'SECTION',
  Country = 'COUNTRY',
  PhoneNumber = 'PHONE_NUMBER',
  Url = 'URL',
  Email = 'EMAIL',
  Confirmation = 'CONFIRMATION',
}

export type AdmissionsWindow = {
  __typename?: 'AdmissionsWindow';
  settings: AdmissionsWindowSettings;
  applicationStartDate?: Maybe<Scalars['DateTime']>;
  applicationEndDate?: Maybe<Scalars['DateTime']>;
  enrollmentStartDate?: Maybe<Scalars['DateTime']>;
  enrollmentEndDate?: Maybe<Scalars['DateTime']>;
};

export type AdmissionsWindowSettings = {
  __typename?: 'AdmissionsWindowSettings';
  concurrentApplicationsEnabled: Scalars['Boolean'];
  concurrentApplicationsLimit?: Maybe<Scalars['Int']>;
};

export type AggregateSubjectCount = {
  __typename?: 'AggregateSubjectCount';
  subjectId?: Maybe<Scalars['String']>;
  subjectName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type AggregatedContractDetails = {
  __typename?: 'AggregatedContractDetails';
  totalActiveContracts: Scalars['Int'];
  usedStuCount: Scalars['Int'];
  totalStuCount: Scalars['Int'];
  usedSeatCount: Scalars['Int'];
  totalSeatCount: Scalars['Int'];
  usedLicenseCount: Scalars['Int'];
  totalLicenseCount: Scalars['Int'];
};

export type AggregatedSelfReportedSubjects = {
  __typename?: 'AggregatedSelfReportedSubjects';
  subjectId: Scalars['String'];
  subjectName: Scalars['String'];
  averageSelfReportedMastery: Scalars['Float'];
};

export type AllEnrollments = {
  __typename?: 'AllEnrollments';
  currentEnrollments?: Maybe<Array<Enrollment>>;
  unenrollments?: Maybe<Array<Enrollment>>;
  graduations?: Maybe<Array<Enrollment>>;
};

export type ArchiveEnrollmentCohorts = {
  __typename?: 'ArchiveEnrollmentCohorts';
  enrollmentCohorts?: Maybe<Array<EnrollmentCohort>>;
  learnersRemovalBatchIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type Assessment = EmcNode & {
  __typename?: 'Assessment';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  label: Scalars['String'];
  assessmentType: Scalars['String'];
  /** If true, the learner can see the full results of this v1 Assessment. Ignored for v2 assessments, but see shows_score. */
  showsResults?: Maybe<Scalars['Boolean']>;
  /** If false, the learner cannot see their score for this assessment - they know that they've completed it, but don't know anything about how well they did (not even the scores for individual skills). */
  showsScore?: Maybe<Scalars['Boolean']>;
  /** @deprecated no more surveys */
  biSurvey?: Maybe<Scalars['Boolean']>;
  allowsRetakes?: Maybe<Scalars['Boolean']>;
  /** The number of times a learner can retake an assessment, will be -1 if the assessment has unlimited retakes */
  maxRetakes: Scalars['Int'];
  assessmentVersion: Scalars['Int'];
  programs?: Maybe<Scalars['JSONString']>;
  locale?: Maybe<Scalars['String']>;
  companyGraphLearningPathId?: Maybe<Scalars['String']>;
  /** If true, we 'v2ify' this assessment - as long as the v2ify flag is true and it's really v1, it should appear to the user as a v2 assessment tied to a learning path with nd002-ent, nd009t, and nd898 (but you can undo that by setting it back to v2ify=false) */
  v2ify: Scalars['Boolean'];
  showsProgramPreference?: Maybe<Scalars['Boolean']>;
  showsProgramReadiness: Scalars['Boolean'];
  enableSubjectSelfReport: Scalars['Boolean'];
  assessmentModel: Scalars['String'];
  assessmentCohorts?: Maybe<Array<Maybe<AssessmentCohort>>>;
  companyGraphLearningPath?: Maybe<CompanyGraphLearningPath>;
  programKeys?: Maybe<Array<Scalars['String']>>;
  programUrns?: Maybe<Array<Maybe<ProgramUrn>>>;
  nonV2ifiedAssessmentVersion?: Maybe<Scalars['Int']>;
};

export type AssessmentCohort = {
  __typename?: 'AssessmentCohort';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  labId: Scalars['ID'];
  companyId: Scalars['ID'];
  assessmentId: Scalars['ID'];
  cohortKey: Scalars['String'];
  archiveDate?: Maybe<Scalars['DateTime']>;
  assessment?: Maybe<Assessment>;
  learners?: Maybe<LearnerPaginatedConnection>;
  assessmentLabSessions?: Maybe<Array<Maybe<AssessmentLabSession>>>;
  assessmentInvites?: Maybe<Array<Maybe<AssessmentInvite>>>;
  assessmentResults?: Maybe<AssessmentResultPaginatedConnection>;
  v2AssessmentResults?: Maybe<V2AssessmentResultPaginatedConnection>;
  v2AssessmentResultOverviewPrograms?: Maybe<
    Array<V2AssessmentResultOverviewProgram>
  >;
  v2AssessmentPrograms?: Maybe<Array<V2AssessmentResultOverviewProgram>>;
};

export type AssessmentCohortLearnersArgs = {
  hasCompletedAssessment?: Maybe<Scalars['Boolean']>;
  preparedForProgramKey?: Maybe<Scalars['String']>;
  preparedForProgramType?: Maybe<ResourceType>;
  hasStartedAssessment?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['ID']>;
  learningPathId?: Maybe<Scalars['ID']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  selectionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AssessmentCohortAssessmentResultsArgs = {
  shouldIncludeOnlyMostRecentPerUser?: Maybe<Scalars['Boolean']>;
  enrollmentStatus?: Maybe<EnrollmentStatus>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<AssessmentResultSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AssessmentCohortV2AssessmentResultsArgs = {
  shouldIncludeOnlyMostRecentPerUser?: Maybe<Scalars['Boolean']>;
  enrollmentStatus?: Maybe<EnrollmentStatus>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<V2AssessmentResultSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AssessmentCohortPaginatedConnection = TotalCount & {
  __typename?: 'AssessmentCohortPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssessmentCohortPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AssessmentCohortPaginated` and its cursor. */
export type AssessmentCohortPaginatedEdge = {
  __typename?: 'AssessmentCohortPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<AssessmentCohort>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum AssessmentCohortSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LabIdAsc = 'LAB_ID_ASC',
  LabIdDesc = 'LAB_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  AssessmentIdAsc = 'ASSESSMENT_ID_ASC',
  AssessmentIdDesc = 'ASSESSMENT_ID_DESC',
  CohortKeyAsc = 'COHORT_KEY_ASC',
  CohortKeyDesc = 'COHORT_KEY_DESC',
  ArchiveDateAsc = 'ARCHIVE_DATE_ASC',
  ArchiveDateDesc = 'ARCHIVE_DATE_DESC',
}

export type AssessmentInvite = {
  __typename?: 'AssessmentInvite';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  assessmentCohortId: Scalars['ID'];
  learnerId: Scalars['ID'];
  createdBy: Scalars['String'];
  assessmentCohort: AssessmentCohort;
};

export type AssessmentLabSession = {
  __typename?: 'AssessmentLabSession';
  createDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  cohortKey: Scalars['String'];
  sessionId: Scalars['String'];
  learnerId: Scalars['ID'];
  userKey: Scalars['String'];
  programPreference?: Maybe<Scalars['String']>;
  cohort?: Maybe<AssessmentCohort>;
  selfReportedSubjects?: Maybe<Array<SelfReportedSubjects>>;
  assessmentResult?: Maybe<AssessmentResult>;
  v2AssessmentResult?: Maybe<V2AssessmentResult>;
  learner?: Maybe<Learner>;
  assessment: Assessment;
  assessmentCohort: AssessmentCohort;
  assessmentResults?: Maybe<AssessmentResultPaginatedConnection>;
  v2AssessmentResults?: Maybe<V2AssessmentResultPaginatedConnection>;
};

export type AssessmentLabSessionAssessmentResultsArgs = {
  shouldIncludeOnlyMostRecentPerUser?: Maybe<Scalars['Boolean']>;
  enrollmentStatus?: Maybe<EnrollmentStatus>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<AssessmentResultSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AssessmentLabSessionV2AssessmentResultsArgs = {
  shouldIncludeOnlyMostRecentPerUser?: Maybe<Scalars['Boolean']>;
  enrollmentStatus?: Maybe<EnrollmentStatus>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<V2AssessmentResultSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum AssessmentModelType {
  Pre = 'PRE',
  Post = 'POST',
}

export type AssessmentPaginatedConnection = TotalCount & {
  __typename?: 'AssessmentPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssessmentPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AssessmentPaginated` and its cursor. */
export type AssessmentPaginatedEdge = {
  __typename?: 'AssessmentPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<Assessment>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type AssessmentResult = EmcNode & {
  __typename?: 'AssessmentResult';
  createDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  learnerId: Scalars['ID'];
  assessmentId: Scalars['ID'];
  sessionId: Scalars['String'];
  response: Scalars['String'];
  suggestedNd: Scalars['String'];
  assessment?: Maybe<Assessment>;
  assessmentLabSession?: Maybe<AssessmentLabSession>;
  learner?: Maybe<Learner>;
};

export type AssessmentResultConnection = {
  __typename?: 'AssessmentResultConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssessmentResultEdge>>;
};

/** A Relay edge containing a `AssessmentResult` and its cursor. */
export type AssessmentResultEdge = {
  __typename?: 'AssessmentResultEdge';
  /** The item at the end of the edge */
  node?: Maybe<AssessmentResult>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type AssessmentResultPaginatedConnection = TotalCount & {
  __typename?: 'AssessmentResultPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssessmentResultPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AssessmentResultPaginated` and its cursor. */
export type AssessmentResultPaginatedEdge = {
  __typename?: 'AssessmentResultPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<AssessmentResult>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum AssessmentResultSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LearnerIdAsc = 'LEARNER_ID_ASC',
  LearnerIdDesc = 'LEARNER_ID_DESC',
  AssessmentIdAsc = 'ASSESSMENT_ID_ASC',
  AssessmentIdDesc = 'ASSESSMENT_ID_DESC',
  SessionIdAsc = 'SESSION_ID_ASC',
  SessionIdDesc = 'SESSION_ID_DESC',
  ResponseAsc = 'RESPONSE_ASC',
  ResponseDesc = 'RESPONSE_DESC',
  SuggestedNdAsc = 'SUGGESTED_ND_ASC',
  SuggestedNdDesc = 'SUGGESTED_ND_DESC',
}

export enum AssessmentSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  AssessmentTypeAsc = 'ASSESSMENT_TYPE_ASC',
  AssessmentTypeDesc = 'ASSESSMENT_TYPE_DESC',
  ShowsResultsAsc = 'SHOWS_RESULTS_ASC',
  ShowsResultsDesc = 'SHOWS_RESULTS_DESC',
  ShowsScoreAsc = 'SHOWS_SCORE_ASC',
  ShowsScoreDesc = 'SHOWS_SCORE_DESC',
  BiSurveyAsc = 'BI_SURVEY_ASC',
  BiSurveyDesc = 'BI_SURVEY_DESC',
  AllowsRetakesAsc = 'ALLOWS_RETAKES_ASC',
  AllowsRetakesDesc = 'ALLOWS_RETAKES_DESC',
  MaxRetakesAsc = 'MAX_RETAKES_ASC',
  MaxRetakesDesc = 'MAX_RETAKES_DESC',
  AssessmentVersionAsc = 'ASSESSMENT_VERSION_ASC',
  AssessmentVersionDesc = 'ASSESSMENT_VERSION_DESC',
  ProgramsAsc = 'PROGRAMS_ASC',
  ProgramsDesc = 'PROGRAMS_DESC',
  LocaleAsc = 'LOCALE_ASC',
  LocaleDesc = 'LOCALE_DESC',
  CompanyGraphLearningPathIdAsc = 'COMPANY_GRAPH_LEARNING_PATH_ID_ASC',
  CompanyGraphLearningPathIdDesc = 'COMPANY_GRAPH_LEARNING_PATH_ID_DESC',
  V2IfyAsc = 'V2IFY_ASC',
  V2IfyDesc = 'V2IFY_DESC',
  ShowsProgramPreferenceAsc = 'SHOWS_PROGRAM_PREFERENCE_ASC',
  ShowsProgramPreferenceDesc = 'SHOWS_PROGRAM_PREFERENCE_DESC',
  ShowsProgramReadinessAsc = 'SHOWS_PROGRAM_READINESS_ASC',
  ShowsProgramReadinessDesc = 'SHOWS_PROGRAM_READINESS_DESC',
  EnableSubjectSelfReportAsc = 'ENABLE_SUBJECT_SELF_REPORT_ASC',
  EnableSubjectSelfReportDesc = 'ENABLE_SUBJECT_SELF_REPORT_DESC',
  AssessmentModelAsc = 'ASSESSMENT_MODEL_ASC',
  AssessmentModelDesc = 'ASSESSMENT_MODEL_DESC',
}

export type AssessmentWhitelistedProgram = {
  __typename?: 'AssessmentWhitelistedProgram';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  programKey: Scalars['String'];
  programType: Scalars['String'];
  program: ProgramData;
};

export type AuthToken = {
  __typename?: 'AuthToken';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  id: Scalars['ID'];
  companyId: Scalars['Int'];
  token: Scalars['String'];
  name: Scalars['String'];
  isValid?: Maybe<Scalars['Boolean']>;
  createdBy: Scalars['String'];
  updatedBy?: Maybe<Scalars['String']>;
};

/** The auto-enrollment settings for this associated program. */
export type AutoEnrollSettingInput = {
  /** Determines whether auto-enroll is enabled for this program. */
  enabled: Scalars['Boolean'];
  /** Version number for resource locking */
  version?: Maybe<Scalars['Int']>;
  /** Is successful completion of the associated question set required for auto-enrollment? */
  criteriaQuestionSetRequired?: Maybe<Scalars['Boolean']>;
  /** Is completion of the associated assessment required for auto-enrollment? */
  criteriaAssessmentResultRequired?: Maybe<Scalars['Boolean']>;
  /** Use the default preparedness threshold for this assessment */
  criteriaAssessmentDefaultThreshold?: Maybe<Scalars['Boolean']>;
  /** Determines the assessment score minimum required for auto-enrollment */
  criteriaAssessmentOverrideThreshold?: Maybe<Scalars['Float']>;
  /** Is there a limit to the number of enrollments this program can auto-consume? */
  criteriaEnrollmentLimitEnabled?: Maybe<Scalars['Boolean']>;
  /** Determines the maximum number of enrollments that this program can auto-consume. */
  criteriaEnrollmentLimitCount?: Maybe<Scalars['Int']>;
};

export type AutoEnrollmentEligibility = {
  __typename?: 'AutoEnrollmentEligibility';
  eligible?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
};

/** BulkSelectionFilter supported operators. */
export enum BulkSelectionFilterOp {
  Eq = 'EQ',
  Ne = 'NE',
  Lt = 'LT',
  Lte = 'LTE',
  Gt = 'GT',
  Gte = 'GTE',
  In = 'IN',
  Ni = 'NI',
}

/**
 * BulkSelectionFilterValueAggregation is an enum of supported aggregation functions that
 * can be applied to a filter value.
 */
export enum BulkSelectionFilterValueAggregation {
  Min = 'MIN',
  Max = 'MAX',
  Count = 'COUNT',
}

export enum CatalogAccess {
  AllAccess = 'ALL_ACCESS',
  DiscoveryAndFluency = 'DISCOVERY_AND_FLUENCY',
}

export type CatalogAccessStatus = {
  __typename?: 'CatalogAccessStatus';
  hasAccess: Scalars['Boolean'];
  requestId: Scalars['String'];
  userDetails?: Maybe<CatalogAccessUserDetails>;
  tokenDetails?: Maybe<CatalogAccessTokenDetails>;
  legacyTokenDetails?: Maybe<CatalogAccessTokenDetails>;
};

export type CatalogAccessTokenDetails = {
  __typename?: 'CatalogAccessTokenDetails';
  isValid: Scalars['Boolean'];
  isMalformed: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isLegacy: Scalars['Boolean'];
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export type CatalogAccessUserDetails = {
  __typename?: 'CatalogAccessUserDetails';
  isLoggedIn: Scalars['Boolean'];
  isCompanyMember: Scalars['Boolean'];
};

export type CatalogContent = {
  __typename?: 'CatalogContent';
  programs: Array<UdacityCatalogProgram>;
  filters: Array<Scalars['String']>;
};

export type CatalogProgram = {
  __typename?: 'CatalogProgram';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  programKey: Scalars['String'];
  programType: Scalars['String'];
  catalogUrl?: Maybe<Scalars['String']>;
  isStandard: Scalars['Boolean'];
  stuPricing?: Maybe<Scalars['Int']>;
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  tags?: Maybe<Array<CatalogTag>>;
  createdByUser?: Maybe<User>;
  updatedByUser?: Maybe<User>;
  program: ProgramData;
  latestMajorVersion: Scalars['Int'];
  companyCatalogSection?: Maybe<CatalogProgramSectionType>;
  metadata?: Maybe<CatalogProgramMetadata>;
};

export type CatalogProgramCompanyCatalogSectionArgs = {
  companyId?: Maybe<Scalars['ID']>;
};

export enum CatalogProgramContentLevel {
  Discovery = 'DISCOVERY',
  Fluency = 'FLUENCY',
  Beginner = 'BEGINNER',
  Intermediate = 'INTERMEDIATE',
  Advanced = 'ADVANCED',
}

export type CatalogProgramMetadata = {
  __typename?: 'CatalogProgramMetadata';
  isIncludedInEntPlatform: Scalars['Boolean'];
  contentLevel?: Maybe<CatalogProgramContentLevel>;
};

export enum CatalogProgramSectionType {
  FullPrograms = 'FULL_PROGRAMS',
  IntermediatePrograms = 'INTERMEDIATE_PROGRAMS',
  IntroductoryPrograms = 'INTRODUCTORY_PROGRAMS',
  Discovery = 'DISCOVERY',
  Fluency = 'FLUENCY',
  FutureOfferings = 'FUTURE_OFFERINGS',
}

export type CatalogTag = {
  __typename?: 'CatalogTag';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  id: Scalars['ID'];
  category: Scalars['String'];
  value: Scalars['String'];
  colorScheme: Scalars['String'];
};

export enum CatalogTagCategory {
  School = 'SCHOOL',
}

export enum CatalogTagColorScheme {
  ColorScheme_1 = 'COLOR_SCHEME_1',
  ColorScheme_2 = 'COLOR_SCHEME_2',
  ColorScheme_3 = 'COLOR_SCHEME_3',
  ColorScheme_4 = 'COLOR_SCHEME_4',
  ColorScheme_5 = 'COLOR_SCHEME_5',
  ColorScheme_6 = 'COLOR_SCHEME_6',
  ColorScheme_7 = 'COLOR_SCHEME_7',
  ColorScheme_8 = 'COLOR_SCHEME_8',
}

export type CertificationAssessment = {
  __typename?: 'CertificationAssessment';
  omacAssessmentGroupId: Scalars['String'];
  assessmentCloseDate: Scalars['DateTime'];
  maxDuration: Scalars['Int'];
  minDuration: Scalars['Int'];
  assessmentDuration: Scalars['Int'];
  attempts: Scalars['Int'];
  required: Scalars['Boolean'];
};

export type Cohort = {
  __typename?: 'Cohort';
  id?: Maybe<Scalars['ID']>;
  cohortId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['ZuluDateTime']>;
  endsAt?: Maybe<Scalars['ZuluDateTime']>;
  cohortType?: Maybe<Scalars['String']>;
};

export type Company = EmcNode & {
  __typename?: 'Company';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  status?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  dbaName?: Maybe<Scalars['String']>;
  primaryContact?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  companyTypeId: Scalars['ID'];
  slug: Scalars['String'];
  isSso?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<Scalars['String']>;
  enableAutoUnenroll?: Maybe<Scalars['Boolean']>;
  idpId?: Maybe<Scalars['String']>;
  idpPolicyId?: Maybe<Scalars['String']>;
  idpRuleId?: Maybe<Scalars['String']>;
  questionSetId?: Maybe<Scalars['String']>;
  nominationQuestionSetId?: Maybe<Scalars['String']>;
  discourseConfigurationId?: Maybe<Scalars['String']>;
  containerId?: Maybe<Scalars['String']>;
  contracts?: Maybe<PaginatedContractPaginatedConnection>;
  activeContracts?: Maybe<Array<Maybe<Contract>>>;
  groups?: Maybe<PaginatedGroupPaginatedConnection>;
  termsOfUseVersions?: Maybe<TermsOfUseVersionPaginatedConnection>;
  resources?: Maybe<ResourcePaginatedConnection>;
  questionSet?: Maybe<QuestionSet>;
  nominationQuestionSet?: Maybe<QuestionSet>;
  selfServeTokens?: Maybe<Array<Maybe<SelfServeToken>>>;
  catalogTokens?: Maybe<Array<CompanyCatalogToken>>;
  discourseConfiguration?: Maybe<DiscourseConfiguration>;
  companyType?: Maybe<CompanyType>;
  learners?: Maybe<LearnerPaginatedConnection>;
  managers?: Maybe<LearnerPaginatedConnection>;
  learnerInterests?: Maybe<Array<LearnerInterest>>;
  paginatedLearnerInterests?: Maybe<PaginatedLearnerInterestPaginatedConnection>;
  companyTypes?: Maybe<Array<Maybe<CompanyType>>>;
  reportsPaginatedSubjectsAttained: ReportsPaginatedSubjectsAttained;
  /** shared_contracts provides data on all Contracts that are shared with the Company. */
  sharedContracts?: Maybe<PaginatedContractPaginatedConnection>;
  reportsSubjectMastery?: Maybe<ReportsSubjectMastery>;
  assessments?: Maybe<AssessmentPaginatedConnection>;
  assessmentCohorts?: Maybe<AssessmentCohortPaginatedConnection>;
  companySettings?: Maybe<Array<CompanySettingObject>>;
  relationships?: Maybe<CompanyRelationships>;
  aggregatedContractDetails?: Maybe<AggregatedContractDetails>;
  programs?: Maybe<CompanyProgramPaginatedConnection>;
  talentPlans?: Maybe<TalentPlanPaginatedConnection>;
  suppressEmails?: Maybe<Scalars['Boolean']>;
  enrollmentCohorts?: Maybe<EnrollmentCohortPaginatedConnection>;
  companyGraphLearningPaths?: Maybe<CompanyGraphLearningPathPaginatedConnection>;
  contractablePrograms?: Maybe<Array<ContractableProgram>>;
  enrollableCompanyGraphLearningPaths?: Maybe<CompanyGraphLearningPathPaginatedConnection>;
  totalStuCount?: Maybe<Scalars['Int']>;
  consumedStuCount?: Maybe<Scalars['Int']>;
  pendingStuCount?: Maybe<Scalars['Int']>;
  totalActiveEnrollments?: Maybe<Scalars['Int']>;
  averageProjectCompletion?: Maybe<Scalars['Float']>;
  graduationRate?: Maybe<Scalars['Float']>;
  authTokens?: Maybe<Array<AuthToken>>;
  useLogoOnCertificate?: Maybe<Scalars['Boolean']>;
  certificateLogoUrl?: Maybe<Scalars['String']>;
  invitedLearners?: Maybe<LearnerInvitationPaginatedConnection>;
  ssoLaunchLink?: Maybe<Scalars['String']>;
  idpConfiguration?: Maybe<IdpConfiguration>;
};

export type CompanyContractsArgs = {
  contractType?: Maybe<ContractType>;
  status?: Maybe<Status>;
  companyId?: Maybe<Scalars['ID']>;
  companyTypeId?: Maybe<Scalars['ID']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  startsBefore?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<PaginatedContractSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyGroupsArgs = {
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<PaginatedGroupSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyTermsOfUseVersionsArgs = {
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<TermsOfUseVersionSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyResourcesArgs = {
  isEnrollable?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<ResourceSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyLearnersArgs = {
  hasAssessmentResultAfter?: Maybe<Scalars['DateTime']>;
  groupId?: Maybe<Scalars['ID']>;
  learningPathId?: Maybe<Scalars['ID']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  selectionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyManagersArgs = {
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyPaginatedLearnerInterestsArgs = {
  companyId?: Maybe<Scalars['ID']>;
  learnerId?: Maybe<Scalars['ID']>;
  programKey?: Maybe<Scalars['String']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  programType?: Maybe<LearnerInterestProgramType>;
  selectionFilters?: Maybe<Array<LearnerInterestBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerInterestBulkSelectionFilter>>;
  status?: Maybe<LearnerInterestStatus>;
  questionsAnswered?: Maybe<Array<QuestionResponses>>;
  programTypes?: Maybe<Array<LearnerInterestProgramType>>;
  nominationTypes?: Maybe<Array<NominationType>>;
  contracted?: Maybe<Scalars['Boolean']>;
  cohortIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<PaginatedLearnerInterestSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyReportsPaginatedSubjectsAttainedArgs = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  enrollmentCohortId?: Maybe<Scalars['ID']>;
  groupId?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type CompanySharedContractsArgs = {
  contractType?: Maybe<ContractType>;
  status?: Maybe<Status>;
  companyId?: Maybe<Scalars['ID']>;
  companyTypeId?: Maybe<Scalars['ID']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  startsBefore?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<PaginatedContractSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyAssessmentsArgs = {
  learningPathId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<AssessmentSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyAssessmentCohortsArgs = {
  isArchived?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<AssessmentCohortSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyProgramsArgs = {
  programKey?: Maybe<Scalars['String']>;
  programType?: Maybe<Scalars['String']>;
  programKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<CompanyProgramSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyTalentPlansArgs = {
  resourceType?: Maybe<Array<TalentPlanResourceType>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<TalentPlanSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyEnrollmentCohortsArgs = {
  state?: Maybe<EnrollmentCohortState>;
  companyId?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<EnrollmentCohortSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyCompanyGraphLearningPathsArgs = {
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<CompanyGraphLearningPathSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyEnrollableCompanyGraphLearningPathsArgs = {
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<CompanyGraphLearningPathSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyConsumedStuCountArgs = {
  createdAfter?: Maybe<Scalars['DateTime']>;
  search?: Maybe<Scalars['String']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  groupId?: Maybe<Scalars['ID']>;
  hasAssessmentsResultsAfter?: Maybe<Scalars['DateTime']>;
};

export type CompanyPendingStuCountArgs = {
  createdAfter?: Maybe<Scalars['DateTime']>;
  search?: Maybe<Scalars['String']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  groupId?: Maybe<Scalars['ID']>;
  hasAssessmentsResultsAfter?: Maybe<Scalars['DateTime']>;
};

export type CompanyGraduationRateArgs = {
  programKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  cohortIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type CompanyAuthTokensArgs = {
  tokenId?: Maybe<Scalars['ID']>;
};

export type CompanyInvitedLearnersArgs = {
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerInvitationSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanySsoLaunchLinkArgs = {
  programKey: Scalars['String'];
  programLocale?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['ID']>;
};

export type CompanyCatalogToken = {
  __typename?: 'CompanyCatalogToken';
  companyId: Scalars['ID'];
  token: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  tokenEncoded: Scalars['String'];
};

export type CompanyGraphLearningPath = EmcNode & {
  __typename?: 'CompanyGraphLearningPath';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  learningPathId: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  assessmentCohortId?: Maybe<Scalars['ID']>;
  questionSetId?: Maybe<Scalars['String']>;
  learningPath?: Maybe<GraphLearningPath>;
  learners?: Maybe<LearnerPaginatedConnection>;
  enrollmentCohorts?: Maybe<EnrollmentCohortPaginatedConnection>;
  assessmentCohort?: Maybe<AssessmentCohort>;
  questionSet?: Maybe<QuestionSet>;
  isEnrollable?: Maybe<Scalars['Boolean']>;
};

export type CompanyGraphLearningPathLearnersArgs = {
  groupId?: Maybe<Scalars['ID']>;
  learningPathId?: Maybe<Scalars['ID']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  selectionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyGraphLearningPathEnrollmentCohortsArgs = {
  state?: Maybe<EnrollmentCohortState>;
  companyId?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<EnrollmentCohortSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyGraphLearningPathConnection = {
  __typename?: 'CompanyGraphLearningPathConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyGraphLearningPathEdge>>;
};

/** A Relay edge containing a `CompanyGraphLearningPath` and its cursor. */
export type CompanyGraphLearningPathEdge = {
  __typename?: 'CompanyGraphLearningPathEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompanyGraphLearningPath>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CompanyGraphLearningPathPaginatedConnection = TotalCount & {
  __typename?: 'CompanyGraphLearningPathPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyGraphLearningPathPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CompanyGraphLearningPathPaginated` and its cursor. */
export type CompanyGraphLearningPathPaginatedEdge = {
  __typename?: 'CompanyGraphLearningPathPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompanyGraphLearningPath>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum CompanyGraphLearningPathSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  LearningPathIdAsc = 'LEARNING_PATH_ID_ASC',
  LearningPathIdDesc = 'LEARNING_PATH_ID_DESC',
  IsAvailableAsc = 'IS_AVAILABLE_ASC',
  IsAvailableDesc = 'IS_AVAILABLE_DESC',
  AssessmentCohortIdAsc = 'ASSESSMENT_COHORT_ID_ASC',
  AssessmentCohortIdDesc = 'ASSESSMENT_COHORT_ID_DESC',
  QuestionSetIdAsc = 'QUESTION_SET_ID_ASC',
  QuestionSetIdDesc = 'QUESTION_SET_ID_DESC',
}

export type CompanyLearningPartner = {
  __typename?: 'CompanyLearningPartner';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  partnerName: Scalars['String'];
};

export type CompanyPaginatedConnection = TotalCount & {
  __typename?: 'CompanyPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CompanyPaginated` and its cursor. */
export type CompanyPaginatedEdge = {
  __typename?: 'CompanyPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<Company>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CompanyProgram = {
  __typename?: 'CompanyProgram';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  companyId: Scalars['ID'];
  programKey: Scalars['String'];
  programType: Scalars['String'];
  assessmentCohortId?: Maybe<Scalars['ID']>;
  durationOverride?: Maybe<Scalars['Int']>;
  questionSetId?: Maybe<Scalars['String']>;
  isChallengeProgram: Scalars['Boolean'];
  questionSet?: Maybe<QuestionSet>;
  assessmentCohortKey?: Maybe<Scalars['ID']>;
  certificationAssessment?: Maybe<CertificationAssessment>;
  autoEnrollSetting?: Maybe<CompanySettingAutoEnroll>;
  resourceData?: Maybe<ResourceData>;
};

export type CompanyProgramPaginatedConnection = TotalCount & {
  __typename?: 'CompanyProgramPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyProgramPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CompanyProgramPaginated` and its cursor. */
export type CompanyProgramPaginatedEdge = {
  __typename?: 'CompanyProgramPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompanyProgram>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum CompanyProgramSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ProgramKeyAsc = 'PROGRAM_KEY_ASC',
  ProgramKeyDesc = 'PROGRAM_KEY_DESC',
  ProgramTypeAsc = 'PROGRAM_TYPE_ASC',
  ProgramTypeDesc = 'PROGRAM_TYPE_DESC',
  AssessmentCohortIdAsc = 'ASSESSMENT_COHORT_ID_ASC',
  AssessmentCohortIdDesc = 'ASSESSMENT_COHORT_ID_DESC',
  DurationOverrideAsc = 'DURATION_OVERRIDE_ASC',
  DurationOverrideDesc = 'DURATION_OVERRIDE_DESC',
  QuestionSetIdAsc = 'QUESTION_SET_ID_ASC',
  QuestionSetIdDesc = 'QUESTION_SET_ID_DESC',
  IsChallengeProgramAsc = 'IS_CHALLENGE_PROGRAM_ASC',
  IsChallengeProgramDesc = 'IS_CHALLENGE_PROGRAM_DESC',
}

export type CompanyRelationships = {
  __typename?: 'CompanyRelationships';
  parent?: Maybe<Company>;
  children?: Maybe<Array<Company>>;
};

export type CompanySetting = {
  __typename?: 'CompanySetting';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  settingId: Scalars['ID'];
  overrideValue: Scalars['String'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  setting?: Maybe<Setting>;
};

export type CompanySettingAutoEnroll = {
  __typename?: 'CompanySettingAutoEnroll';
  id: Scalars['String'];
  version: Scalars['Int'];
  /** Determines whether auto-enroll is enabled for this program. */
  enabled: Scalars['Boolean'];
  /** Is successful completion of the associated question set required for auto-enrollment? */
  criteriaQuestionSetRequired: Scalars['Boolean'];
  /** Is completion of the associated assessment required for auto-enrollment? */
  criteriaAssessmentResultRequired: Scalars['Boolean'];
  /** Use the default preparedness threshold for this assessment */
  criteriaAssessmentDefaultThreshold?: Maybe<Scalars['Boolean']>;
  /** Determines the assessment score minimum required for auto-enrollment */
  criteriaAssessmentOverrideThreshold?: Maybe<Scalars['Float']>;
  /** Is there a limit to the number of enrollments this program can auto-consume? */
  criteriaEnrollmentLimitEnabled: Scalars['Boolean'];
  /** Determines the maximum number of enrollments that this program can auto-consume. */
  criteriaEnrollmentLimitCount?: Maybe<Scalars['Int']>;
};

export type CompanySettingObject = {
  __typename?: 'CompanySettingObject';
  companySettingId?: Maybe<Scalars['ID']>;
  settingId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['String']>;
  valueType?: Maybe<Scalars['String']>;
};

export enum CompanySortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DbaNameAsc = 'DBA_NAME_ASC',
  DbaNameDesc = 'DBA_NAME_DESC',
  PrimaryContactAsc = 'PRIMARY_CONTACT_ASC',
  PrimaryContactDesc = 'PRIMARY_CONTACT_DESC',
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  TaxIdAsc = 'TAX_ID_ASC',
  TaxIdDesc = 'TAX_ID_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  LogoUrlAsc = 'LOGO_URL_ASC',
  LogoUrlDesc = 'LOGO_URL_DESC',
  CompanyTypeIdAsc = 'COMPANY_TYPE_ID_ASC',
  CompanyTypeIdDesc = 'COMPANY_TYPE_ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  IsSsoAsc = 'IS_SSO_ASC',
  IsSsoDesc = 'IS_SSO_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  EnableAutoUnenrollAsc = 'ENABLE_AUTO_UNENROLL_ASC',
  EnableAutoUnenrollDesc = 'ENABLE_AUTO_UNENROLL_DESC',
  IdpIdAsc = 'IDP_ID_ASC',
  IdpIdDesc = 'IDP_ID_DESC',
  IdpPolicyIdAsc = 'IDP_POLICY_ID_ASC',
  IdpPolicyIdDesc = 'IDP_POLICY_ID_DESC',
  IdpRuleIdAsc = 'IDP_RULE_ID_ASC',
  IdpRuleIdDesc = 'IDP_RULE_ID_DESC',
  QuestionSetIdAsc = 'QUESTION_SET_ID_ASC',
  QuestionSetIdDesc = 'QUESTION_SET_ID_DESC',
  NominationQuestionSetIdAsc = 'NOMINATION_QUESTION_SET_ID_ASC',
  NominationQuestionSetIdDesc = 'NOMINATION_QUESTION_SET_ID_DESC',
  DiscourseConfigurationIdAsc = 'DISCOURSE_CONFIGURATION_ID_ASC',
  DiscourseConfigurationIdDesc = 'DISCOURSE_CONFIGURATION_ID_DESC',
  ContainerIdAsc = 'CONTAINER_ID_ASC',
  ContainerIdDesc = 'CONTAINER_ID_DESC',
}

export type CompanyType = {
  __typename?: 'CompanyType';
  id: Scalars['ID'];
  name: Scalars['String'];
  r2ClaimType?: Maybe<Scalars['String']>;
  serviceModelId?: Maybe<Scalars['String']>;
  testFieldVisibleOnlyToEntEng?: Maybe<Scalars['Boolean']>;
};

export type ConnectSeries = {
  __typename?: 'ConnectSeries';
  id: Scalars['String'];
  connectCohortId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  maxSize: Scalars['Int'];
  userCount: Scalars['Int'];
  createdAt: Scalars['ZuluDateTime'];
  updatedAt: Scalars['ZuluDateTime'];
  deletedAt?: Maybe<Scalars['ZuluDateTime']>;
  assignmentType: Scalars['String'];
};

export type Contract = {
  __typename?: 'Contract';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  status?: Maybe<Scalars['String']>;
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  title: Scalars['String'];
  isSubscription?: Maybe<Scalars['Boolean']>;
  /**
   * If true, all changes to this contract's approved terms requires
   * approval from a user with the legal-finance role (but
   * enterprise-program-manager users can still create draft terms). If
   * false, then once this contract is approved, any user with the
   * enterprise-program-manager role can add, approve, and suspend terms on this
   * contract.
   */
  strictManagement?: Maybe<Scalars['Boolean']>;
  seatModel?: Maybe<Scalars['String']>;
  maxActiveSeats?: Maybe<Scalars['Int']>;
  demo?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  stuCount?: Maybe<Scalars['Int']>;
  stuContractGracePeriodInDays?: Maybe<Scalars['Int']>;
  contractType: Scalars['String'];
  learnerManagement: Scalars['Boolean'];
  subscriptionId?: Maybe<Scalars['String']>;
  totalLicenses?: Maybe<Scalars['Int']>;
  /** Catalog made available to added learners via the main website Catalog */
  catalogAccess?: Maybe<Array<Maybe<CatalogAccess>>>;
  /** The URL to the initiative's logo that will be displayed on graduation certificates */
  contractInitiativeLogo?: Maybe<Scalars['String']>;
  /** The URL to the client's signature that will be displayed on graduation certificates */
  signatureUrl?: Maybe<Scalars['String']>;
  /** The name of the person whose signature will be displayed on graduation certificates */
  signatureName?: Maybe<Scalars['String']>;
  /** The title of the person whose signature will be displayed on graduation certificates */
  signatureTitle?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  contractTerms?: Maybe<PaginatedContractTermPaginatedConnection>;
  enrollableContractTerms?: Maybe<Array<ContractTerm>>;
  learners?: Maybe<LearnerConnection>;
  enrollmentCounts?: Maybe<ContractEnrollmentCount>;
  consumedStuCount?: Maybe<Scalars['Int']>;
  consumedStuProperties?: Maybe<Array<EnrollmentHistoryProperties>>;
  pendingStuCount?: Maybe<Scalars['Int']>;
  pendingStuProperties?: Maybe<Array<EnrollmentHistoryProperties>>;
  /** @deprecated Use paginatedLearners instead */
  paginatedAllAccessLearners?: Maybe<LearnerPaginatedConnection>;
  paginatedLearners?: Maybe<LearnerPaginatedConnection>;
  /** All of the Company IDs that the Contract is shared with */
  sharedCompanyIds?: Maybe<Array<Scalars['ID']>>;
  /** A list of Companies that the Contract is shared with */
  sharedCompanies?: Maybe<Array<Maybe<ContractCompanyShares>>>;
  /**
   * Whether the contract is a STU contract
   * @deprecated Use contractType instead
   */
  isStuContract?: Maybe<Scalars['Boolean']>;
};

export type ContractContractTermsArgs = {
  programType?: Maybe<ResourceType>;
  status?: Maybe<Status>;
  companyId?: Maybe<Scalars['ID']>;
  companyTypeId?: Maybe<Scalars['ID']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  startsBefore?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<PaginatedContractTermSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ContractLearnersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ContractPaginatedAllAccessLearnersArgs = {
  groupId?: Maybe<Scalars['ID']>;
  learningPathId?: Maybe<Scalars['ID']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  selectionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ContractPaginatedLearnersArgs = {
  groupId?: Maybe<Scalars['ID']>;
  learningPathId?: Maybe<Scalars['ID']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  selectionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ContractCompanyShares = {
  __typename?: 'ContractCompanyShares';
  company?: Maybe<Company>;
};

export type ContractEnrollmentCount = EnrollmentCount & {
  __typename?: 'ContractEnrollmentCount';
  activeCounts?: Maybe<Scalars['Int']>;
  enrollmentCounts?: Maybe<Scalars['Int']>;
  breakdown?: Maybe<Array<Maybe<ContractTermEnrollmentCount>>>;
};

export type ContractTerm = {
  __typename?: 'ContractTerm';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  status: Scalars['String'];
  id: Scalars['ID'];
  contractId: Scalars['ID'];
  /** @deprecated use the contract end date */
  startDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated use the contract end date */
  endDate?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  customSeatSupport?: Maybe<Scalars['Boolean']>;
  stuPricingOverride?: Maybe<Scalars['Int']>;
  contract?: Maybe<Contract>;
  resources?: Maybe<Array<Resource>>;
  company?: Maybe<Company>;
  enrollmentCohorts?: Maybe<EnrollmentCohortConnection>;
  learnerEnrollmentCohorts?: Maybe<EnrollmentCohortConnection>;
  /** @deprecated use the contract end date */
  enrollmentEndDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated unused */
  enrollmentWindowOffset?: Maybe<Scalars['Int']>;
  /** @deprecated unused */
  windowOffsetType?: Maybe<Scalars['String']>;
  /** @deprecated unused */
  duration?: Maybe<Scalars['Int']>;
  resource?: Maybe<Resource>;
  enrollmentCounts?: Maybe<ContractTermEnrollmentCount>;
  consumedStuCount?: Maybe<Scalars['Int']>;
  pendingStuCount?: Maybe<Scalars['Int']>;
};

export type ContractTermEnrollmentCohortsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ContractTermLearnerEnrollmentCohortsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ContractTermEnrollmentCount = EnrollmentCount & {
  __typename?: 'ContractTermEnrollmentCount';
  activeCounts?: Maybe<Scalars['Int']>;
  enrollmentCounts?: Maybe<Scalars['Int']>;
  contractTermId?: Maybe<Scalars['ID']>;
};

export type ContractTermInput = {
  title: Scalars['String'];
  programKey: Scalars['String'];
  programVersion?: Maybe<Scalars['String']>;
  programType?: Maybe<ResourceType>;
  programMajor?: Maybe<Scalars['Int']>;
  programLocale?: Maybe<Scalars['String']>;
  stuPricingOverride?: Maybe<Scalars['Int']>;
};

export type ContractTermUpdateInput = {
  id: Scalars['ID'];
  stuPricingOverride?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export enum ContractType {
  Seat = 'SEAT',
  Stu = 'STU',
  License = 'LICENSE',
}

export type ContractableProgram = {
  __typename?: 'ContractableProgram';
  companyId: Scalars['ID'];
  programKey?: Maybe<Scalars['String']>;
  programType?: Maybe<Scalars['String']>;
  catalogProgram?: Maybe<CatalogProgram>;
  interestCount: Scalars['Int'];
};

export type CreateAssessment = {
  __typename?: 'CreateAssessment';
  assessment?: Maybe<Assessment>;
};

export type CreateAssessmentWhitelistedProgram = {
  __typename?: 'CreateAssessmentWhitelistedProgram';
  ok?: Maybe<Scalars['Boolean']>;
  programKey?: Maybe<Scalars['String']>;
  programType?: Maybe<ResourceType>;
};

export type CreateAuthToken = {
  __typename?: 'CreateAuthToken';
  authToken?: Maybe<AuthToken>;
};

export type CreateCatalogProgram = {
  __typename?: 'CreateCatalogProgram';
  catalogProgram?: Maybe<CatalogProgram>;
};

export type CreateCertificationAssessment = {
  __typename?: 'CreateCertificationAssessment';
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateCompany = {
  __typename?: 'CreateCompany';
  ok?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
};

export type CreateCompanyGraphLearningPath = {
  __typename?: 'CreateCompanyGraphLearningPath';
  companyGraphLearningPath?: Maybe<CompanyGraphLearningPath>;
};

export type CreateCompanySetting = {
  __typename?: 'CreateCompanySetting';
  companySetting?: Maybe<CompanySetting>;
};

/** Create a company-specific terms of use version. */
export type CreateCompanyTermsOfUseVersion = {
  __typename?: 'CreateCompanyTermsOfUseVersion';
  termsOfUseVersion?: Maybe<TermsOfUseVersion>;
};

export type CreateContract = {
  __typename?: 'CreateContract';
  contract?: Maybe<Contract>;
};

export type CreateDiscourseConfiguration = {
  __typename?: 'CreateDiscourseConfiguration';
  discourseConfiguration?: Maybe<DiscourseConfiguration>;
};

export type CreateEnrollmentCohorts = {
  __typename?: 'CreateEnrollmentCohorts';
  enrollmentCohorts?: Maybe<Array<EnrollmentCohort>>;
};

export type CreateGraphLearningPath = {
  __typename?: 'CreateGraphLearningPath';
  graphLearningPath?: Maybe<GraphLearningPath>;
};

export type CreateGraphLearningPathCollection = {
  __typename?: 'CreateGraphLearningPathCollection';
  graphLearningPathCollection?: Maybe<GraphLearningPathCollection>;
};

export type CreateGroup = {
  __typename?: 'CreateGroup';
  ok?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Group>;
};

export type CreateLearners = {
  __typename?: 'CreateLearners';
  ok?: Maybe<Scalars['Boolean']>;
  batchId?: Maybe<Scalars['String']>;
};

/** Process the creation of learners from one or more uploaded learner csvs. */
export type CreateLearnersFromCsvUpload = {
  __typename?: 'CreateLearnersFromCSVUpload';
  ok?: Maybe<Scalars['Boolean']>;
  batchId?: Maybe<Scalars['String']>;
};

export type CreateLearningPath = {
  __typename?: 'CreateLearningPath';
  learningPath?: Maybe<LearningPath>;
};

export type CreateSetting = {
  __typename?: 'CreateSetting';
  setting?: Maybe<Setting>;
};

export type CsodProjectCompletionHistory = {
  __typename?: 'CsodProjectCompletionHistory';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  learnerId: Scalars['ID'];
  loid: Scalars['String'];
  csodResp: Scalars['String'];
};

export type DeleteAssessmentWhitelistedProgram = {
  __typename?: 'DeleteAssessmentWhitelistedProgram';
  ok?: Maybe<Scalars['Boolean']>;
  programKey?: Maybe<Scalars['String']>;
  programType?: Maybe<ResourceType>;
};

export type DeleteCatalogProgram = {
  __typename?: 'DeleteCatalogProgram';
  catalogProgram?: Maybe<CatalogProgram>;
};

export type DeleteCatalogTag = {
  __typename?: 'DeleteCatalogTag';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteCertificationAssessment = {
  __typename?: 'DeleteCertificationAssessment';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteCompanyGraphLearningPath = {
  __typename?: 'DeleteCompanyGraphLearningPath';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteCompanySetting = {
  __typename?: 'DeleteCompanySetting';
  companySetting?: Maybe<CompanySetting>;
};

export type DeleteDiscourseConfiguration = {
  __typename?: 'DeleteDiscourseConfiguration';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteEnrollmentCohort = {
  __typename?: 'DeleteEnrollmentCohort';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteGraphLearningPathCollection = {
  __typename?: 'DeleteGraphLearningPathCollection';
  graphLearningPathCollection?: Maybe<GraphLearningPathCollection>;
};

export type DeleteGroup = {
  __typename?: 'DeleteGroup';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteIntroductoryProgramsInAssessment = {
  __typename?: 'DeleteIntroductoryProgramsInAssessment';
  ok?: Maybe<Scalars['Boolean']>;
  deletedPrograms?: Maybe<Array<Maybe<DeletedProgram>>>;
};

export type DeleteLearner = {
  __typename?: 'DeleteLearner';
  ok?: Maybe<Scalars['Boolean']>;
  batchId?: Maybe<Scalars['String']>;
};

export type DeleteLearnerInterest = {
  __typename?: 'DeleteLearnerInterest';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteSettingAndOverrides = {
  __typename?: 'DeleteSettingAndOverrides';
  setting?: Maybe<Setting>;
};

export type DeleteTalentPlan = {
  __typename?: 'DeleteTalentPlan';
  ok: Scalars['Boolean'];
};

export type DeleteTeamInvitation = {
  __typename?: 'DeleteTeamInvitation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeletedProgram = {
  __typename?: 'DeletedProgram';
  assessmentId?: Maybe<Scalars['ID']>;
  programKey?: Maybe<Scalars['String']>;
};

export type DenormalizedLearnerInterest = {
  __typename?: 'DenormalizedLearnerInterest';
  id: Scalars['Float'];
  companyId: Scalars['Float'];
  learnerId: Scalars['Float'];
  learnerInterestId: Scalars['Float'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  userKey?: Maybe<Scalars['String']>;
  submissionDate?: Maybe<Scalars['DateTime']>;
  createDate?: Maybe<Scalars['DateTime']>;
  updateDate?: Maybe<Scalars['DateTime']>;
  applicationVersion: Scalars['Int'];
  status: Scalars['String'];
  programKey?: Maybe<Scalars['String']>;
  programType?: Maybe<Scalars['String']>;
  programTitle?: Maybe<Scalars['String']>;
  companyGraphLearningPathId?: Maybe<Scalars['String']>;
  learningPathName?: Maybe<Scalars['String']>;
  assessmentScore?: Maybe<Scalars['Float']>;
  subjectScores?: Maybe<Scalars['JSONString']>;
  eligibility?: Maybe<Scalars['String']>;
  eligibilityReason?: Maybe<Scalars['String']>;
  nominatorLearnerId?: Maybe<Scalars['Float']>;
  nominatorFirstName?: Maybe<Scalars['String']>;
  nominatorLastName?: Maybe<Scalars['String']>;
  nominatorEmail?: Maybe<Scalars['String']>;
  nominationType?: Maybe<Scalars['String']>;
  assessmentReadiness?: Maybe<Scalars['String']>;
  assessmentEligiblePrograms?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
};

export type DiscourseConfiguration = {
  __typename?: 'DiscourseConfiguration';
  name: Scalars['String'];
  discourseConnectSecret: Scalars['String'];
  validProgramKeys: Array<Scalars['String']>;
  companies?: Maybe<Array<Company>>;
};

export type EmcNode = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type EnrollEnterpriseUserSynchronous = {
  __typename?: 'EnrollEnterpriseUserSynchronous';
  ok?: Maybe<Scalars['Boolean']>;
};

export type EnrollLearners = {
  __typename?: 'EnrollLearners';
  ok?: Maybe<Scalars['Boolean']>;
  batchId?: Maybe<Scalars['String']>;
};

/**
 * This mutation adds Learners to the Cohort, adds the Contract Term to the Cohort, and
 * enrolls the Learners in the Contract Term.
 */
export type EnrollLearnersIntoCohort = {
  __typename?: 'EnrollLearnersIntoCohort';
  ok?: Maybe<Scalars['Boolean']>;
  enrollmentBatchId?: Maybe<Scalars['String']>;
};

export type EnrollLearnersIntoLearningPath = {
  __typename?: 'EnrollLearnersIntoLearningPath';
  ok?: Maybe<Scalars['Boolean']>;
  enrollmentBatchId?: Maybe<Scalars['String']>;
};

export type Enrollment = {
  __typename?: 'Enrollment';
  enrollmentId: Scalars['String'];
  userKey: Scalars['String'];
  programKey?: Maybe<Scalars['String']>;
  programLocale?: Maybe<Scalars['String']>;
  programType?: Maybe<Scalars['String']>;
  programVersion?: Maybe<Scalars['String']>;
  enrollmentDate?: Maybe<Scalars['String']>;
  enrollmentStatus?: Maybe<Scalars['String']>;
  contractTermId?: Maybe<Scalars['ID']>;
  claimStatus?: Maybe<Scalars['String']>;
  stateChangeDate?: Maybe<Scalars['String']>;
  program: ProgramData;
  programUrn?: Maybe<ProgramUrn>;
};

export type EnrollmentCohort = EmcNode & {
  __typename?: 'EnrollmentCohort';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  registrarClaimRefKey: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  cohortType: Scalars['String'];
  serviceModelId?: Maybe<Scalars['String']>;
  certificateTemplateId?: Maybe<Scalars['Int']>;
  registrarCohortId?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  learners?: Maybe<LearnerPaginatedConnection>;
  learnerContractTerms?: Maybe<Array<Maybe<ContractTerm>>>;
  contractTerms?: Maybe<Array<ContractTerm>>;
  learningPaths?: Maybe<CompanyGraphLearningPathConnection>;
  associations?: Maybe<Array<Maybe<EnrollmentCohortLearnerContractTerm>>>;
  stats?: Maybe<EnrollmentCohortStats>;
  paginatedRosterData: PaginatedRosterData;
  glpAssociations?: Maybe<Array<Maybe<EnrollmentCohortGraphLearningPath>>>;
  connectCohortId?: Maybe<Scalars['UUID']>;
  projectDeadlines?: Maybe<Array<Maybe<ProjectDeadline>>>;
};

export type EnrollmentCohortLearnersArgs = {
  rosterFilters?: Maybe<RosterFilters>;
  connectSeriesId?: Maybe<Scalars['String']>;
  hasConnectSeries?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['ID']>;
  learningPathId?: Maybe<Scalars['ID']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  selectionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type EnrollmentCohortLearningPathsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type EnrollmentCohortStatsArgs = {
  daysInactiveMonitor?: Maybe<Scalars['Int']>;
  daysInactiveOffTrack?: Maybe<Scalars['Int']>;
  snapshotDatetime?: Maybe<Scalars['ZuluDateTime']>;
};

export type EnrollmentCohortPaginatedRosterDataArgs = {
  onTrackStatus?: Maybe<Scalars['String']>;
  enrollmentState?: Maybe<EnrollmentStatusEnum>;
  withProjectsSubmitted?: Maybe<Scalars['Boolean']>;
  withProjectsPassed?: Maybe<Scalars['Boolean']>;
  searchString?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum EnrollmentCohortCohortType {
  Standard = 'STANDARD',
  Uhome = 'UHOME',
  Connect = 'CONNECT',
}

export type EnrollmentCohortConnection = {
  __typename?: 'EnrollmentCohortConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EnrollmentCohortEdge>>;
};

/** A Relay edge containing a `EnrollmentCohort` and its cursor. */
export type EnrollmentCohortEdge = {
  __typename?: 'EnrollmentCohortEdge';
  /** The item at the end of the edge */
  node?: Maybe<EnrollmentCohort>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type EnrollmentCohortGraphLearningPath = EmcNode & {
  __typename?: 'EnrollmentCohortGraphLearningPath';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  enrollmentCohortId: Scalars['ID'];
  companyGraphLearningPathId: Scalars['String'];
  companyGraphLearningPath?: Maybe<CompanyGraphLearningPath>;
};

export type EnrollmentCohortLearnerContractTerm = {
  __typename?: 'EnrollmentCohortLearnerContractTerm';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  enrollmentCohortId: Scalars['ID'];
  learnerId: Scalars['ID'];
  contractTermId: Scalars['ID'];
  companyId: Scalars['ID'];
  learner: Learner;
  contractTerm: ContractTerm;
};

export type EnrollmentCohortPaginatedConnection = TotalCount & {
  __typename?: 'EnrollmentCohortPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EnrollmentCohortPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `EnrollmentCohortPaginated` and its cursor. */
export type EnrollmentCohortPaginatedEdge = {
  __typename?: 'EnrollmentCohortPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<EnrollmentCohort>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum EnrollmentCohortSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  RegistrarClaimRefKeyAsc = 'REGISTRAR_CLAIM_REF_KEY_ASC',
  RegistrarClaimRefKeyDesc = 'REGISTRAR_CLAIM_REF_KEY_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  CohortTypeAsc = 'COHORT_TYPE_ASC',
  CohortTypeDesc = 'COHORT_TYPE_DESC',
  ServiceModelIdAsc = 'SERVICE_MODEL_ID_ASC',
  ServiceModelIdDesc = 'SERVICE_MODEL_ID_DESC',
  CertificateTemplateIdAsc = 'CERTIFICATE_TEMPLATE_ID_ASC',
  CertificateTemplateIdDesc = 'CERTIFICATE_TEMPLATE_ID_DESC',
  RegistrarCohortIdAsc = 'REGISTRAR_COHORT_ID_ASC',
  RegistrarCohortIdDesc = 'REGISTRAR_COHORT_ID_DESC',
}

export enum EnrollmentCohortState {
  Planned = 'PLANNED',
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Archived = 'ARCHIVED',
}

export type EnrollmentCohortStats = {
  __typename?: 'EnrollmentCohortStats';
  numOnTrack?: Maybe<Scalars['Int']>;
  numOffTrack?: Maybe<Scalars['Int']>;
  numMonitor?: Maybe<Scalars['Int']>;
  numProjectsSubmitted?: Maybe<Scalars['Int']>;
  numProjectsSubmittedLastWeek?: Maybe<Scalars['Int']>;
  numProjectsSubmittedThisWeek?: Maybe<Scalars['Int']>;
  numProjectsPassed?: Maybe<Scalars['Int']>;
  numProjectsPassedLastWeek?: Maybe<Scalars['Int']>;
  numProjectsPassedThisWeek?: Maybe<Scalars['Int']>;
  numGraduations?: Maybe<Scalars['Int']>;
  numGraduationsLastWeek?: Maybe<Scalars['Int']>;
  numGraduationsThisWeek?: Maybe<Scalars['Int']>;
};

export type EnrollmentCount = {
  activeCounts?: Maybe<Scalars['Int']>;
  enrollmentCounts?: Maybe<Scalars['Int']>;
};

export type EnrollmentHistory = {
  __typename?: 'EnrollmentHistory';
  enrollmentStatus?: Maybe<EnrollmentStatusEnum>;
  date?: Maybe<Scalars['ZuluDateTime']>;
};

export type EnrollmentHistoryProperties = {
  __typename?: 'EnrollmentHistoryProperties';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  enrollmentId: Scalars['String'];
  userKey: Scalars['String'];
  nodeKey: Scalars['String'];
  companyId: Scalars['Int'];
  contractId: Scalars['Int'];
  contractTermId: Scalars['Int'];
  learnerId: Scalars['Int'];
  enrollmentState: Scalars['String'];
  stuCount?: Maybe<Scalars['Int']>;
  isStuCountException?: Maybe<Scalars['Boolean']>;
  isAutoEnrolled: Scalars['Boolean'];
  stateChangeDate: Scalars['DateTime'];
  startDate?: Maybe<Scalars['DateTime']>;
  longestEnrollmentWindowStart?: Maybe<Scalars['DateTime']>;
  longestEnrollmentWindowEnd?: Maybe<Scalars['DateTime']>;
  longestEnrollmentWindowDelta?: Maybe<Scalars['JSONString']>;
};

export type EnrollmentInfo = {
  enrollmentId: Scalars['String'];
  learnerId: Scalars['ID'];
  contractTermId: Scalars['ID'];
};

export enum EnrollmentStatus {
  Enrolled = 'ENROLLED',
  NotEnrolled = 'NOT_ENROLLED',
  All = 'ALL',
}

export enum EnrollmentStatusEnum {
  Enrolled = 'ENROLLED',
  Unenrolled = 'UNENROLLED',
  Graduated = 'GRADUATED',
  StaticAccess = 'STATIC_ACCESS',
}

export type EnrollmentStuCountInput = {
  enrollmentId: Scalars['String'];
  contractId: Scalars['ID'];
  contractTermId?: Maybe<Scalars['ID']>;
  stuCountOverride: Scalars['Int'];
};

export type EnrollmentTaskArgs = {
  __typename?: 'EnrollmentTaskArgs';
  learnerLifecycleId: Scalars['ID'];
  learnerId: Scalars['ID'];
  learnerUserKey: Scalars['String'];
  companyId: Scalars['ID'];
  companyName: Scalars['String'];
  companyType: Scalars['String'];
  companyIsSso: Scalars['Boolean'];
  contractId: Scalars['ID'];
  contractTermId: Scalars['ID'];
  claimType: Scalars['String'];
  r2RefKey?: Maybe<Scalars['String']>;
  nodeKey: Scalars['String'];
  programTitle: Scalars['String'];
  contentVersion: Scalars['String'];
  contentLocale?: Maybe<Scalars['String']>;
  programType?: Maybe<Scalars['String']>;
  serviceModelId: Scalars['String'];
  authorizedBy: Scalars['String'];
  utcStartDatetime?: Maybe<Scalars['String']>;
  utcEndDate?: Maybe<Scalars['String']>;
  enrollmentCohortRefKey?: Maybe<Scalars['String']>;
  resourceDurationOverride?: Maybe<Scalars['Int']>;
  hideDeadlines?: Maybe<Scalars['Boolean']>;
  suppressEmails: Scalars['Boolean'];
  stuCount?: Maybe<Scalars['Int']>;
  registrarCohortId?: Maybe<Scalars['String']>;
  /** @deprecated No longer used */
  releaseTag?: Maybe<Scalars['String']>;
  /** @deprecated Use registrar_cohort_id instead */
  cohortId?: Maybe<Scalars['ID']>;
};

export enum ErrorCode {
  AuthCheckFailed = 'AUTH_CHECK_FAILED',
  CatalogNominationsOff = 'CATALOG_NOMINATIONS_OFF',
  NominatorIsNotManager = 'NOMINATOR_IS_NOT_MANAGER',
  PublicCatalogOff = 'PUBLIC_CATALOG_OFF',
  UserIsNotLoggedIn = 'USER_IS_NOT_LOGGED_IN',
  NotAllAccessUser = 'NOT_ALL_ACCESS_USER',
  ContractUnenrollable = 'CONTRACT_UNENROLLABLE',
  LearnerNotFound = 'LEARNER_NOT_FOUND',
  MultipleLearnersFound = 'MULTIPLE_LEARNERS_FOUND',
}

export type GenerateLearnerInterestCsv = {
  __typename?: 'GenerateLearnerInterestCsv';
  batchId?: Maybe<Scalars['String']>;
  s3ObjectKey?: Maybe<Scalars['String']>;
};

export type GraphLearningPath = EmcNode & {
  __typename?: 'GraphLearningPath';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  status: GraphLearningPathStatus;
  description?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  nodes?: Maybe<Array<Maybe<LpNode>>>;
  tags?: Maybe<Array<CatalogTag>>;
  edges?: Maybe<Array<Maybe<LpEdge>>>;
  companyGraphLearningPaths?: Maybe<Array<Maybe<CompanyGraphLearningPath>>>;
};

export type GraphLearningPathCollection = EmcNode & {
  __typename?: 'GraphLearningPathCollection';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  learningPaths?: Maybe<GraphLearningPathPaginatedConnection>;
};

export type GraphLearningPathCollectionLearningPathsArgs = {
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<GraphLearningPathSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type GraphLearningPathCollectionPaginatedConnection = TotalCount & {
  __typename?: 'GraphLearningPathCollectionPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GraphLearningPathCollectionPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `GraphLearningPathCollectionPaginated` and its cursor. */
export type GraphLearningPathCollectionPaginatedEdge = {
  __typename?: 'GraphLearningPathCollectionPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<GraphLearningPathCollection>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum GraphLearningPathCollectionSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
}

export type GraphLearningPathPaginatedConnection = TotalCount & {
  __typename?: 'GraphLearningPathPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GraphLearningPathPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `GraphLearningPathPaginated` and its cursor. */
export type GraphLearningPathPaginatedEdge = {
  __typename?: 'GraphLearningPathPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<GraphLearningPath>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum GraphLearningPathSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
}

export enum GraphLearningPathStatus {
  Draft = 'DRAFT',
  Approved = 'APPROVED',
  Archived = 'ARCHIVED',
}

export type Group = {
  __typename?: 'Group';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  companyId: Scalars['ID'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  company?: Maybe<Company>;
  learners?: Maybe<LearnerPaginatedConnection>;
};

export type GroupLearnersArgs = {
  groupId?: Maybe<Scalars['ID']>;
  learningPathId?: Maybe<Scalars['ID']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  selectionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type HandleEnrollmentCohortGlpAssociations = {
  __typename?: 'HandleEnrollmentCohortGLPAssociations';
  companyGraphLearningPath?: Maybe<CompanyGraphLearningPath>;
};

export type HandleGlpCollectionItems = {
  __typename?: 'HandleGLPCollectionItems';
  learningPathCollection?: Maybe<GraphLearningPathCollection>;
};

export type HandleLearnerGlpAssociations = {
  __typename?: 'HandleLearnerGLPAssociations';
  companyGraphLearningPath?: Maybe<CompanyGraphLearningPath>;
};

export type IdpCertificate = {
  __typename?: 'IdpCertificate';
  createdAt?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  kid?: Maybe<Scalars['String']>;
  lastUpdatedAt?: Maybe<Scalars['String']>;
  rfc4514DistName?: Maybe<Scalars['String']>;
};

export type IdpConfiguration = {
  __typename?: 'IdpConfiguration';
  certificate?: Maybe<IdpCertificate>;
  id?: Maybe<Scalars['String']>;
  metadata?: Maybe<IdpMetadata>;
};

export type IdpMetadata = {
  __typename?: 'IdpMetadata';
  destinationUrl?: Maybe<Scalars['String']>;
  domainList?: Maybe<Array<Maybe<Scalars['String']>>>;
  enableJit?: Maybe<Scalars['Boolean']>;
  idpUsername?: Maybe<Scalars['String']>;
  issuerUri?: Maybe<Scalars['String']>;
  kid?: Maybe<Scalars['String']>;
  ssoUrl?: Maybe<Scalars['String']>;
};

export type InitializeRealTimeReportsPartitionManager = {
  __typename?: 'InitializeRealTimeReportsPartitionManager';
  ok?: Maybe<Scalars['Boolean']>;
};

export type InvalidateCatalogTokens = {
  __typename?: 'InvalidateCatalogTokens';
  ok?: Maybe<Scalars['Boolean']>;
};

export type InviteLearnersToAssessment = {
  __typename?: 'InviteLearnersToAssessment';
  batchId?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type InviteTeamMembers = {
  __typename?: 'InviteTeamMembers';
  ok?: Maybe<Scalars['Boolean']>;
};

export type LpCourseNode = EmcNode &
  LpNode & {
    __typename?: 'LPCourseNode';
    createDate: Scalars['DateTime'];
    updateDate: Scalars['DateTime'];
    id: Scalars['ID'];
    nodeType: Scalars['String'];
    courseKey: Scalars['String'];
    label: Scalars['String'];
  };

export type LpEdge = EmcNode & {
  __typename?: 'LPEdge';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  learningPathId: Scalars['String'];
  parentNodeId: Scalars['String'];
  childNodeId: Scalars['String'];
};

export type LpNdNode = EmcNode &
  LpNode & {
    __typename?: 'LPNdNode';
    createDate: Scalars['DateTime'];
    updateDate: Scalars['DateTime'];
    id: Scalars['ID'];
    nodeType: Scalars['String'];
    ndKey: Scalars['String'];
    label: Scalars['String'];
  };

export type LpNode = {
  id: Scalars['ID'];
  label: Scalars['String'];
};

/** Enrolls all of the Learners in their associated Contract Terms for the given Enrollment Cohort. */
export type LaunchEnrollmentCohort = {
  __typename?: 'LaunchEnrollmentCohort';
  enrollmentCohort?: Maybe<EnrollmentCohort>;
  batchId?: Maybe<Scalars['String']>;
};

export type Learner = EmcNode & {
  __typename?: 'Learner';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  userKey: Scalars['String'];
  employeeId?: Maybe<Scalars['String']>;
  companyId: Scalars['ID'];
  email: Scalars['String'];
  /** If true, signifies that the learner was generally nominated. */
  wasNominated?: Maybe<Scalars['Boolean']>;
  nominatedByLearnerId?: Maybe<Scalars['Int']>;
  managerLearnerId?: Maybe<Scalars['ID']>;
  company?: Maybe<Company>;
  assessmentResults?: Maybe<AssessmentResultConnection>;
  v2AssessmentResults?: Maybe<V2AssessmentResultConnection>;
  assessmentLabSessions?: Maybe<Array<AssessmentLabSession>>;
  assessmentInviteCohorts?: Maybe<Array<Maybe<AssessmentCohort>>>;
  groups?: Maybe<Array<Maybe<Group>>>;
  learningPaths?: Maybe<LearningPathConnection>;
  companyGraphLearningPaths?: Maybe<CompanyGraphLearningPathConnection>;
  contracts?: Maybe<Array<Maybe<Contract>>>;
  roles?: Maybe<Array<Role>>;
  learnerLifecycles?: Maybe<Array<Maybe<LearnerLifecycle>>>;
  learnerInterests?: Maybe<Array<Maybe<LearnerInterest>>>;
  enrollmentCohorts?: Maybe<EnrollmentCohortConnection>;
  nominationsReceived?: Maybe<Array<Maybe<Nomination>>>;
  nominationsGiven?: Maybe<Array<Maybe<Nomination>>>;
  interests?: Maybe<Array<LearnerInterest>>;
  user?: Maybe<User>;
  allEnrollments?: Maybe<AllEnrollments>;
  canRetakeAssessment: Scalars['Boolean'];
  /** Roster will be empty if filtered by a planned cohort with specified cohort ID */
  roster?: Maybe<Array<Roster>>;
  projects?: Maybe<Array<Project>>;
  applications?: Maybe<Array<LearnerInterestApplication>>;
  admissionsWindow: LearnerAdmissionsWindow;
  licenseContracts?: Maybe<Array<Contract>>;
  /** Filters the list of catalog_content programs by the available_for_enrollment field. */
  catalogContent?: Maybe<CatalogContent>;
  associations?: Maybe<Array<Maybe<EnrollmentCohortLearnerContractTerm>>>;
  assessmentInvites?: Maybe<Array<Maybe<AssessmentInvite>>>;
  projectSkills?: Maybe<Array<ProjectSkill>>;
  glpAssociations?: Maybe<Array<Maybe<LearnerGraphLearningPath>>>;
  /** NEW Connect Series memberships */
  connectSeries?: Maybe<Array<Maybe<ConnectSeries>>>;
  hasAllAccess?: Maybe<Scalars['Boolean']>;
};

export type LearnerAssessmentResultsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LearnerV2AssessmentResultsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LearnerAssessmentLabSessionsArgs = {
  cohortKey?: Maybe<Scalars['String']>;
  shouldIncludeOnlyMostRecentPerCohort?: Maybe<Scalars['Boolean']>;
};

export type LearnerLearningPathsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LearnerCompanyGraphLearningPathsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LearnerEnrollmentCohortsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LearnerNominationsReceivedArgs = {
  nominationType?: Maybe<NominationType>;
};

export type LearnerNominationsGivenArgs = {
  nominationType?: Maybe<NominationType>;
};

export type LearnerCanRetakeAssessmentArgs = {
  cohortKey: Scalars['String'];
};

export type LearnerRosterArgs = {
  cohortId?: Maybe<Scalars['ID']>;
  activeCohortId?: Maybe<Scalars['ID']>;
};

export type LearnerProjectsArgs = {
  cohortId?: Maybe<Scalars['ID']>;
};

export type LearnerCatalogContentArgs = {
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type LearnerAssessmentInvitesArgs = {
  cohortKey?: Maybe<Scalars['String']>;
  onlyActive?: Maybe<Scalars['Boolean']>;
  onlyNotStarted?: Maybe<Scalars['Boolean']>;
};

export type LearnerConnectSeriesArgs = {
  enrollmentCohortId: Scalars['ID'];
};

export type LearnerActivity = {
  __typename?: 'LearnerActivity';
  lastSeen?: Maybe<Scalars['ZuluDateTime']>;
  submissions?: Maybe<Array<Maybe<Submission>>>;
  mandatoryProjectsPassed?: Maybe<Scalars['Int']>;
  electiveProjectsPassed?: Maybe<Scalars['Int']>;
  totalProjectsPassed?: Maybe<Scalars['Int']>;
  /** Number of concepts viewed by the learner in the classroom */
  conceptsViewed?: Maybe<Scalars['Int']>;
  /** Number of quiz questions answered by the learner in the classroom */
  questionsAnswered?: Maybe<Scalars['Int']>;
  enrollmentHistory?: Maybe<Array<Maybe<EnrollmentHistory>>>;
  enrolledAt?: Maybe<Scalars['ZuluDateTime']>;
  graduatedAt?: Maybe<Scalars['ZuluDateTime']>;
  unenrolledAt?: Maybe<Scalars['ZuluDateTime']>;
  isAutoEnrolled?: Maybe<Scalars['Boolean']>;
  estimatedEndDate?: Maybe<Scalars['ZuluDateTime']>;
  onTrackStatus?: Maybe<Scalars['String']>;
  onTrackStatusUpdatedAt?: Maybe<Scalars['ZuluDateTime']>;
  /** Used to calculate On-Track status - if 1, contributes to `Monitor`, if more, contributes to `Off Track` */
  projectsOverdue?: Maybe<Scalars['Int']>;
  /** Used to calculate On-Track status together with company settings for `days_inactive_monitor` and `days_inactive_off_track */
  daysInactive?: Maybe<Scalars['Int']>;
  /** Used to calculate On-Track status - contributes to `Monitor` status if the most recent project was submitted late by a margin of at least 50% the duration of the next project. */
  mostRecentProjectWasVeryLate?: Maybe<Scalars['Boolean']>;
  /** Used to calculate On-Track status - Off Track if the estimated end date of the program has passed */
  isBeyondEndDate?: Maybe<Scalars['Boolean']>;
};

export type LearnerAdmissionsWindow = {
  __typename?: 'LearnerAdmissionsWindow';
  companyId: Scalars['Int'];
  learnerId: Scalars['Int'];
  admissionsWindow: AdmissionsWindow;
  applications?: Maybe<Array<LearnerInterestApplication>>;
  enrollments?: Maybe<AllEnrollments>;
  hasReachedApplicationsLimit: Scalars['Boolean'];
};

export type LearnerBulkSelectionFilter = {
  op: BulkSelectionFilterOp;
  field: LearnerBulkSelectionFilterField;
  fieldAgg?: Maybe<BulkSelectionFilterValueAggregation>;
  /** If null, will use sql NULL semantics, which are unlikely to be what you want for any operator except EQ and NE. */
  value?: Maybe<Scalars['String']>;
  /** Required if the BulkSelectionFilterField ends with '->' */
  key?: Maybe<Scalars['String']>;
};

/** __ denotes a field on a relationship, i.e. GROUPS__ID is equivalent to groups.id */
export enum LearnerBulkSelectionFilterField {
  Id = 'ID',
  UserKey = 'USER_KEY',
  Email = 'EMAIL',
  CompanyId = 'COMPANY_ID',
  EmployeeId = 'EMPLOYEE_ID',
  CreateDate = 'CREATE_DATE',
  ContractsId = 'CONTRACTS__ID',
  GroupsId = 'GROUPS__ID',
  GroupsName = 'GROUPS__NAME',
  AssessmentResultsCreateDate = 'ASSESSMENT_RESULTS__CREATE_DATE',
  V2AssessmentResultsCreateDate = 'V2_ASSESSMENT_RESULTS__CREATE_DATE',
  V2AssessmentResultsAssessmentLabSessionCohortKey = 'V2_ASSESSMENT_RESULTS__ASSESSMENT_LAB_SESSION__COHORT_KEY',
  AssessmentInviteCohortsCohortKey = 'ASSESSMENT_INVITE_COHORTS__COHORT_KEY',
  AssessmentLabSessionsCohortKey = 'ASSESSMENT_LAB_SESSIONS__COHORT_KEY',
  LearningPathsId = 'LEARNING_PATHS__ID',
  LearningPathsName = 'LEARNING_PATHS__NAME',
  CompanyGraphLearningPathsId = 'COMPANY_GRAPH_LEARNING_PATHS__ID',
  EnrollmentCohortId = 'ENROLLMENT_COHORT__ID',
  NominationsReceivedNominationType = 'NOMINATIONS_RECEIVED__NOMINATION_TYPE',
  NominationsGivenNominationType = 'NOMINATIONS_GIVEN__NOMINATION_TYPE',
  RolesName = 'ROLES__NAME',
}

export type LearnerConnection = {
  __typename?: 'LearnerConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LearnerEdge>>;
};

/** A Relay edge containing a `Learner` and its cursor. */
export type LearnerEdge = {
  __typename?: 'LearnerEdge';
  /** The item at the end of the edge */
  node?: Maybe<Learner>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type LearnerEnrollmentsType = {
  __typename?: 'LearnerEnrollmentsType';
  enrollmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  learnerId?: Maybe<Scalars['ID']>;
  userKey?: Maybe<Scalars['ID']>;
};

export type LearnerGraphLearningPath = EmcNode & {
  __typename?: 'LearnerGraphLearningPath';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  learnerId: Scalars['ID'];
  companyGraphLearningPathId: Scalars['String'];
  companyGraphLearningPath?: Maybe<CompanyGraphLearningPath>;
};

export type LearnerGroup = {
  __typename?: 'LearnerGroup';
  groupId: Scalars['ID'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  learnerId: Scalars['ID'];
};

export type LearnerInterest = {
  __typename?: 'LearnerInterest';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  applicationVersion: Scalars['Int'];
  learnerId: Scalars['ID'];
  programKey?: Maybe<Scalars['String']>;
  companyGraphLearningPathId?: Maybe<Scalars['String']>;
  contractTermId?: Maybe<Scalars['ID']>;
  programType?: Maybe<Scalars['String']>;
  customData?: Maybe<Scalars['JSONString']>;
  note?: Maybe<Scalars['String']>;
  noteUpdatedAt?: Maybe<Scalars['DateTime']>;
  noteUpdatedBy?: Maybe<Scalars['String']>;
  status?: Maybe<LearnerInterestStatus>;
  submissionDate?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedBy?: Maybe<Scalars['String']>;
  eligibility?: Maybe<LearnerInterestEligibility>;
  eligibilityReason?: Maybe<LearnerInterestEligibilityReason>;
  suggestedProgram?: Maybe<Scalars['String']>;
  assessmentScore?: Maybe<Scalars['Float']>;
  subjectScores?: Maybe<Scalars['JSONString']>;
  enrollmentHistory?: Maybe<Array<RegistrarEnrollment>>;
  plagiarismHistory?: Maybe<Array<PlagiarismCase>>;
  assessmentResultId?: Maybe<Scalars['Int']>;
  nominatedByLearnerId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyGraphLearningPath?: Maybe<CompanyGraphLearningPath>;
  contractTerm?: Maybe<ContractTerm>;
  assessmentResult?: Maybe<V2AssessmentResult>;
  responses?: Maybe<Array<Maybe<AdmissionQuestionResponse>>>;
  learners?: Maybe<Learner>;
  contentTitle?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  learner?: Maybe<Learner>;
  assessmentCohortKey?: Maybe<Scalars['ID']>;
  assessmentReadiness?: Maybe<Scalars['String']>;
  assessmentEligiblePrograms?: Maybe<Scalars['String']>;
  /** The company's current QuestionSet. The learner may or may not have actually answered any of the questions on this set. */
  companyQuestionSet?: Maybe<QuestionSet>;
  /** The CGLP/program's current QuestionSet. The learner may or may not have actually answered any of the questions on this set. */
  specificQuestionSet?: Maybe<QuestionSet>;
  /** The company's current QuestionSet for nominations. The learner may or may not have actually answered any of the questions on this set. */
  nominationQuestionSet?: Maybe<QuestionSet>;
  /** Contains the answers to the current questions on the current company QuestionSet, in order. Any questions that have not been answered will be null - the array is guaranteed to have exactly as many elements as there are questions on the current company QuestionSet. Note that this may NOT be the QuestionSet that they actually answered! */
  companyQuestionSetResponses?: Maybe<Array<Maybe<AdmissionQuestionResponse>>>;
  /** Contains the answers to the current questions on the current specific QuestionSet, in order. Any questions that have not been answered will be null - the array is guaranteed to have exactly as many elements as there are questions on the current specific QuestionSet. Note that this may NOT be the QuestionSet that they actually answered! */
  specificQuestionSetResponses?: Maybe<Array<Maybe<AdmissionQuestionResponse>>>;
  /** Contains the answers to the current questions on the current nomination QuestionSet, in order. Any questions that have not been answered will be null - the array is guaranteed to have exactly as many elements as there are questions on the current nomination QuestionSet. Note that this may NOT be the QuestionSet that they actually answered! */
  nominationQuestionSetResponses?: Maybe<
    Array<Maybe<AdmissionQuestionResponse>>
  >;
  program?: Maybe<ProgramData>;
  resource?: Maybe<Resource>;
  /** Contains a list of SubjectScores corresponding to an inputted list of subject ids. For subjects that were not evaluated on this assessment, the SubjectScore will be null. */
  subjectScoresForIds?: Maybe<Array<Maybe<SubjectScore>>>;
  nominations?: Maybe<Array<Maybe<Nomination>>>;
  contracted: Scalars['Boolean'];
};

export type LearnerInterestSubjectScoresForIdsArgs = {
  subjectIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum LearnerInterestAction {
  AddToCohort = 'ADD_TO_COHORT',
  CloseWithNote = 'CLOSE_WITH_NOTE',
  EnrollInRequestedProgram = 'ENROLL_IN_REQUESTED_PROGRAM',
  EnrollInSuggestedProgram = 'ENROLL_IN_SUGGESTED_PROGRAM',
}

export type LearnerInterestApplication = {
  __typename?: 'LearnerInterestApplication';
  companyId: Scalars['Int'];
  learnerId: Scalars['Int'];
  programKey?: Maybe<Scalars['String']>;
  programType?: Maybe<Scalars['String']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  currentId?: Maybe<Scalars['Int']>;
  current?: Maybe<LearnerInterest>;
  eligibleActions?: Maybe<Array<Maybe<LearnerInterestApplicationAction>>>;
};

export enum LearnerInterestApplicationAction {
  New = 'NEW',
  Delete = 'DELETE',
  Submit = 'SUBMIT',
}

export type LearnerInterestBulkSelectionFilter = {
  op: BulkSelectionFilterOp;
  field?: Maybe<LearnerInterestBulkSelectionFilterField>;
  fieldAgg?: Maybe<BulkSelectionFilterValueAggregation>;
  /** If null, will use sql NULL semantics, which are unlikely to be what you want for any operator except EQ and NE. */
  value?: Maybe<Scalars['String']>;
  /** Required if the BulkSelectionFilterField ends with '->' */
  key?: Maybe<Scalars['String']>;
};

/** __ denotes a field on a relationship, i.e. GROUPS__ID is equivalent to groups.id */
export enum LearnerInterestBulkSelectionFilterField {
  AssessmentScore = 'ASSESSMENT_SCORE',
  CompanyId = 'COMPANY_ID',
  CompanyGraphLearningPathId = 'COMPANY_GRAPH_LEARNING_PATH_ID',
  ContractTermId = 'CONTRACT_TERM_ID',
  Eligibility = 'ELIGIBILITY',
  Id = 'ID',
  LearnerId = 'LEARNER_ID',
  ProgramKey = 'PROGRAM_KEY',
  ProgramType = 'PROGRAM_TYPE',
  Status = 'STATUS',
  SubjectScores = 'SUBJECT_SCORES',
  SubmissionDate = 'SUBMISSION_DATE',
  SuggestedProgram = 'SUGGESTED_PROGRAM',
}

export type LearnerInterestCsvAuth = {
  __typename?: 'LearnerInterestCsvAuth';
  isAuthorized?: Maybe<Scalars['Boolean']>;
  attachmentFilename?: Maybe<Scalars['String']>;
};

export enum LearnerInterestCsvType {
  Program = 'PROGRAM',
  LearningPath = 'LEARNING_PATH',
}

export enum LearnerInterestEligibility {
  Eligible = 'ELIGIBLE',
  Ineligible = 'INELIGIBLE',
  Uncertain = 'UNCERTAIN',
}

export enum LearnerInterestEligibilityReason {
  MissingRequiredQuestionSetAnswer = 'MISSING_REQUIRED_QUESTION_SET_ANSWER',
  WrongRequiredQuestionSetAnswer = 'WRONG_REQUIRED_QUESTION_SET_ANSWER',
  InsufficientAssessmentScore = 'INSUFFICIENT_ASSESSMENT_SCORE',
  NoAssessmentResult = 'NO_ASSESSMENT_RESULT',
  Plagiarism = 'PLAGIARISM',
  PriorEnrollment = 'PRIOR_ENROLLMENT',
  Okay = 'OKAY',
}

export enum LearnerInterestProgramType {
  Nanodegree = 'NANODEGREE',
  LearningPath = 'LEARNING_PATH',
  Part = 'PART',
  Course = 'COURSE',
}

export enum LearnerInterestStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Closed = 'CLOSED',
  Enrolled = 'ENROLLED',
  Interested = 'INTERESTED',
  Rejected = 'REJECTED',
}

export type LearnerInvitation = EmcNode & {
  __typename?: 'LearnerInvitation';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  inviteToken?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['ZuluDateTime']>;
};

export type LearnerInvitationPaginatedConnection = TotalCount & {
  __typename?: 'LearnerInvitationPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LearnerInvitationPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `LearnerInvitationPaginated` and its cursor. */
export type LearnerInvitationPaginatedEdge = {
  __typename?: 'LearnerInvitationPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<LearnerInvitation>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum LearnerInvitationSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  InviteTokenAsc = 'INVITE_TOKEN_ASC',
  InviteTokenDesc = 'INVITE_TOKEN_DESC',
}

export type LearnerLifecycle = {
  __typename?: 'LearnerLifecycle';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  learnerId: Scalars['ID'];
  contractId: Scalars['ID'];
  contractTermId: Scalars['ID'];
  taskArgs?: Maybe<LearnerLifecycleTaskArgs>;
  lifecycleFlag: Scalars['String'];
  actionFlag: Scalars['String'];
  errorDetails?: Maybe<Scalars['String']>;
  batchId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  learner?: Maybe<Learner>;
  contract?: Maybe<Contract>;
  contractTerm?: Maybe<ContractTerm>;
  resources?: Maybe<Array<Maybe<Resource>>>;
  cohortId?: Maybe<Scalars['ID']>;
};

export type LearnerLifecycleBatch = {
  __typename?: 'LearnerLifecycleBatch';
  batchId: Scalars['String'];
  contractTermId: Scalars['ID'];
  companyId: Scalars['ID'];
  completed?: Maybe<Scalars['Int']>;
  all?: Maybe<Scalars['Int']>;
  contractId: Scalars['ID'];
  actionFlag: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['DateTime']>;
  cohortId?: Maybe<Scalars['ID']>;
  contractTerm?: Maybe<ContractTerm>;
  company?: Maybe<Company>;
  contract?: Maybe<Contract>;
  cohortDbId?: Maybe<Scalars['ID']>;
  errors?: Maybe<Scalars['Int']>;
  learnerLifecycles?: Maybe<PaginatedLearnerLifecyclePaginatedConnection>;
  createdByUser?: Maybe<User>;
};

export type LearnerLifecycleBatchLearnerLifecyclesArgs = {
  lifecycleFlags?: Maybe<Array<Maybe<LifecycleFlag>>>;
  batchId?: Maybe<Scalars['String']>;
  contractTermId?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<PaginatedLearnerLifecycleSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LearnerLifecycleBatchPaginatedConnection = TotalCount & {
  __typename?: 'LearnerLifecycleBatchPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LearnerLifecycleBatchPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `LearnerLifecycleBatchPaginated` and its cursor. */
export type LearnerLifecycleBatchPaginatedEdge = {
  __typename?: 'LearnerLifecycleBatchPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<LearnerLifecycleBatch>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum LearnerLifecycleBatchSortEnum {
  BatchIdAsc = 'BATCH_ID_ASC',
  BatchIdDesc = 'BATCH_ID_DESC',
  ContractTermIdAsc = 'CONTRACT_TERM_ID_ASC',
  ContractTermIdDesc = 'CONTRACT_TERM_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompletedAsc = 'COMPLETED_ASC',
  CompletedDesc = 'COMPLETED_DESC',
  AllAsc = 'ALL_ASC',
  AllDesc = 'ALL_DESC',
  ContractIdAsc = 'CONTRACT_ID_ASC',
  ContractIdDesc = 'CONTRACT_ID_DESC',
  ActionFlagAsc = 'ACTION_FLAG_ASC',
  ActionFlagDesc = 'ACTION_FLAG_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  CohortIdAsc = 'COHORT_ID_ASC',
  CohortIdDesc = 'COHORT_ID_DESC',
}

export type LearnerLifecyclePaginatedConnection = TotalCount & {
  __typename?: 'LearnerLifecyclePaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LearnerLifecyclePaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `LearnerLifecyclePaginated` and its cursor. */
export type LearnerLifecyclePaginatedEdge = {
  __typename?: 'LearnerLifecyclePaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<LearnerLifecycle>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum LearnerLifecycleSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  LearnerIdAsc = 'LEARNER_ID_ASC',
  LearnerIdDesc = 'LEARNER_ID_DESC',
  ContractIdAsc = 'CONTRACT_ID_ASC',
  ContractIdDesc = 'CONTRACT_ID_DESC',
  ContractTermIdAsc = 'CONTRACT_TERM_ID_ASC',
  ContractTermIdDesc = 'CONTRACT_TERM_ID_DESC',
  TaskArgsAsc = 'TASK_ARGS_ASC',
  TaskArgsDesc = 'TASK_ARGS_DESC',
  LifecycleFlagAsc = 'LIFECYCLE_FLAG_ASC',
  LifecycleFlagDesc = 'LIFECYCLE_FLAG_DESC',
  ActionFlagAsc = 'ACTION_FLAG_ASC',
  ActionFlagDesc = 'ACTION_FLAG_DESC',
  ErrorDetailsAsc = 'ERROR_DETAILS_ASC',
  ErrorDetailsDesc = 'ERROR_DETAILS_DESC',
  BatchIdAsc = 'BATCH_ID_ASC',
  BatchIdDesc = 'BATCH_ID_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
}

export type LearnerLifecycleTaskArgs =
  | EnrollmentTaskArgs
  | UnenrollmentTaskArgs;

export type LearnerPaginatedConnection = TotalCount & {
  __typename?: 'LearnerPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LearnerPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `LearnerPaginated` and its cursor. */
export type LearnerPaginatedEdge = {
  __typename?: 'LearnerPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<Learner>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum LearnerSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UserKeyAsc = 'USER_KEY_ASC',
  UserKeyDesc = 'USER_KEY_DESC',
  EmployeeIdAsc = 'EMPLOYEE_ID_ASC',
  EmployeeIdDesc = 'EMPLOYEE_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  WasNominatedAsc = 'WAS_NOMINATED_ASC',
  WasNominatedDesc = 'WAS_NOMINATED_DESC',
  NominatedByLearnerIdAsc = 'NOMINATED_BY_LEARNER_ID_ASC',
  NominatedByLearnerIdDesc = 'NOMINATED_BY_LEARNER_ID_DESC',
  ManagerLearnerIdAsc = 'MANAGER_LEARNER_ID_ASC',
  ManagerLearnerIdDesc = 'MANAGER_LEARNER_ID_DESC',
}

export type LearnerUploadHistory = {
  __typename?: 'LearnerUploadHistory';
  createDate?: Maybe<Scalars['ZuluDateTime']>;
  updateDate: Scalars['DateTime'];
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  companyUploadId?: Maybe<Scalars['ID']>;
  learnersCreated: Scalars['Int'];
  learnersUploaded: Scalars['Int'];
  errors: Scalars['Int'];
  createdBy: Scalars['String'];
  csvDownloadUrl?: Maybe<Scalars['String']>;
  createdByUser?: Maybe<User>;
};

export type LearnersEnrollmentsType = {
  __typename?: 'LearnersEnrollmentsType';
  countEnrollments?: Maybe<Scalars['Int']>;
  countLearners?: Maybe<Scalars['Int']>;
  enrollmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  learnerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  learnersEnrollments?: Maybe<Array<Maybe<LearnerEnrollmentsType>>>;
  userKeys?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type LearningPath = EmcNode & {
  __typename?: 'LearningPath';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  status?: Maybe<Scalars['String']>;
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  ndKeys: Array<Maybe<Scalars['String']>>;
  createdBy: Scalars['String'];
  learners?: Maybe<LearnerPaginatedConnection>;
  assessments?: Maybe<AssessmentPaginatedConnection>;
};

export type LearningPathLearnersArgs = {
  groupId?: Maybe<Scalars['ID']>;
  learningPathId?: Maybe<Scalars['ID']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  selectionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LearningPathAssessmentsArgs = {
  learningPathId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<AssessmentSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LearningPathConnection = {
  __typename?: 'LearningPathConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LearningPathEdge>>;
};

/** A Relay edge containing a `LearningPath` and its cursor. */
export type LearningPathEdge = {
  __typename?: 'LearningPathEdge';
  /** The item at the end of the edge */
  node?: Maybe<LearningPath>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type LearningPathEdgeInput = {
  /** Id of the node from which this edge originates */
  parentNodeId: Scalars['ID'];
  /** Id of the node in which this edge ends */
  childNodeId: Scalars['ID'];
};

export type LearningPathNodeInput = {
  /** A UUID e.g. 123e4567-e89b-12d3-a456-426614174000 */
  id: Scalars['ID'];
  key: Scalars['String'];
  nodeType?: Maybe<LearningPathNodeType>;
};

export enum LearningPathNodeType {
  Nanodegree = 'NANODEGREE',
  Part = 'PART',
}

export enum LifecycleFlag {
  Scheduled = 'SCHEDULED',
  Discarded = 'DISCARDED',
  Pending = 'PENDING',
  Complete = 'COMPLETE',
}

export type MarkRecentPartnerLearnerCompletions = {
  __typename?: 'MarkRecentPartnerLearnerCompletions';
  ok: Scalars['Boolean'];
  partnerName: Scalars['String'];
  timeWindowSeconds: Scalars['Int'];
  batchId: Scalars['String'];
  progressSummaryLink: Scalars['String'];
};

export type NewEnrollmentCohortInput = {
  name: Scalars['String'];
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  cohortType?: Maybe<EnrollmentCohortCohortType>;
  contractTermIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Only valid if cohort_type is CONNECT. */
  serviceModelId?: Maybe<Scalars['String']>;
  /** Template ID for a custom graduation certificate. Only valid if cohort_type is CONNECT. */
  certificateTemplateId?: Maybe<Scalars['String']>;
};

export type NewLearnerInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** The ID of a User License Contract that the Learner will be added to. */
  contractId?: Maybe<Scalars['ID']>;
  roleId?: Maybe<Scalars['ID']>;
  employeeId?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['ID']>;
  enrollmentCohortId?: Maybe<Scalars['ID']>;
  isNominatorManager?: Maybe<Scalars['Boolean']>;
};

export type Nomination = {
  __typename?: 'Nomination';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  learnerId: Scalars['ID'];
  nominationType: Scalars['String'];
  nominatorLearnerId?: Maybe<Scalars['ID']>;
  isNominatorManager: Scalars['Boolean'];
  /** @deprecated See program_key / company_graph_learning_path_id */
  learnerInterestId?: Maybe<Scalars['ID']>;
  programKey?: Maybe<Scalars['String']>;
  companyGraphLearningPathId?: Maybe<Scalars['String']>;
  learner?: Maybe<Learner>;
  nominatorLearner?: Maybe<Learner>;
  learnerInterest?: Maybe<LearnerInterest>;
  companyGraphLearningPath?: Maybe<CompanyGraphLearningPath>;
  nomineeUser?: Maybe<User>;
  nominatorUser?: Maybe<User>;
};

export enum NominationType {
  GeneralNomination = 'GENERAL_NOMINATION',
  SpecificNomination = 'SPECIFIC_NOMINATION',
  GeneralReferral = 'GENERAL_REFERRAL',
  SpecificReferral = 'SPECIFIC_REFERRAL',
}

export type OutOfDateProgram = {
  __typename?: 'OutOfDateProgram';
  programKey: Scalars['String'];
  oldProgramType?: Maybe<Scalars['String']>;
  newProgramType?: Maybe<Scalars['String']>;
  updated: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type PaginatedCatalogTag = EmcNode & {
  __typename?: 'PaginatedCatalogTag';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  category: Scalars['String'];
  value: Scalars['String'];
  colorScheme: Scalars['String'];
};

export type PaginatedCatalogTagPaginatedConnection = TotalCount & {
  __typename?: 'PaginatedCatalogTagPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaginatedCatalogTagPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PaginatedCatalogTagPaginated` and its cursor. */
export type PaginatedCatalogTagPaginatedEdge = {
  __typename?: 'PaginatedCatalogTagPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<PaginatedCatalogTag>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum PaginatedCatalogTagSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CategoryAsc = 'CATEGORY_ASC',
  CategoryDesc = 'CATEGORY_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC',
  ColorSchemeAsc = 'COLOR_SCHEME_ASC',
  ColorSchemeDesc = 'COLOR_SCHEME_DESC',
}

export type PaginatedContract = EmcNode & {
  __typename?: 'PaginatedContract';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  status?: Maybe<Scalars['String']>;
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  title: Scalars['String'];
  isSubscription?: Maybe<Scalars['Boolean']>;
  /**
   * If true, all changes to this contract's approved terms requires
   * approval from a user with the legal-finance role (but
   * enterprise-program-manager users can still create draft terms). If
   * false, then once this contract is approved, any user with the
   * enterprise-program-manager role can add, approve, and suspend terms on this
   * contract.
   */
  strictManagement?: Maybe<Scalars['Boolean']>;
  seatModel?: Maybe<Scalars['String']>;
  maxActiveSeats?: Maybe<Scalars['Int']>;
  demo?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  stuCount?: Maybe<Scalars['Int']>;
  stuContractGracePeriodInDays?: Maybe<Scalars['Int']>;
  contractType: Scalars['String'];
  learnerManagement: Scalars['Boolean'];
  subscriptionId?: Maybe<Scalars['String']>;
  totalLicenses?: Maybe<Scalars['Int']>;
  /** Catalog made available to added learners via the main website Catalog */
  catalogAccess?: Maybe<Array<Maybe<CatalogAccess>>>;
  /** The URL to the initiative's logo that will be displayed on graduation certificates */
  contractInitiativeLogo?: Maybe<Scalars['String']>;
  /** The URL to the client's signature that will be displayed on graduation certificates */
  signatureUrl?: Maybe<Scalars['String']>;
  /** The name of the person whose signature will be displayed on graduation certificates */
  signatureName?: Maybe<Scalars['String']>;
  /** The title of the person whose signature will be displayed on graduation certificates */
  signatureTitle?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  contractTerms?: Maybe<PaginatedContractTermPaginatedConnection>;
  enrollableContractTerms?: Maybe<Array<ContractTerm>>;
  learners?: Maybe<LearnerConnection>;
  enrollmentCounts?: Maybe<ContractEnrollmentCount>;
  consumedStuCount?: Maybe<Scalars['Int']>;
  consumedStuProperties?: Maybe<Array<EnrollmentHistoryProperties>>;
  pendingStuCount?: Maybe<Scalars['Int']>;
  pendingStuProperties?: Maybe<Array<EnrollmentHistoryProperties>>;
  /** @deprecated Use paginatedLearners instead */
  paginatedAllAccessLearners?: Maybe<LearnerPaginatedConnection>;
  paginatedLearners?: Maybe<LearnerPaginatedConnection>;
  /** All of the Company IDs that the Contract is shared with */
  sharedCompanyIds?: Maybe<Array<Scalars['ID']>>;
  /** A list of Companies that the Contract is shared with */
  sharedCompanies?: Maybe<Array<Maybe<ContractCompanyShares>>>;
  /**
   * Whether the contract is a STU contract
   * @deprecated Use contractType instead
   */
  isStuContract?: Maybe<Scalars['Boolean']>;
};

export type PaginatedContractContractTermsArgs = {
  programType?: Maybe<ResourceType>;
  status?: Maybe<Status>;
  companyId?: Maybe<Scalars['ID']>;
  companyTypeId?: Maybe<Scalars['ID']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  startsBefore?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<PaginatedContractTermSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PaginatedContractLearnersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PaginatedContractPaginatedAllAccessLearnersArgs = {
  groupId?: Maybe<Scalars['ID']>;
  learningPathId?: Maybe<Scalars['ID']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  selectionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PaginatedContractPaginatedLearnersArgs = {
  groupId?: Maybe<Scalars['ID']>;
  learningPathId?: Maybe<Scalars['ID']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  selectionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PaginatedContractPaginatedConnection = TotalCount & {
  __typename?: 'PaginatedContractPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaginatedContractPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PaginatedContractPaginated` and its cursor. */
export type PaginatedContractPaginatedEdge = {
  __typename?: 'PaginatedContractPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<PaginatedContract>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum PaginatedContractSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  IsSubscriptionAsc = 'IS_SUBSCRIPTION_ASC',
  IsSubscriptionDesc = 'IS_SUBSCRIPTION_DESC',
  StrictManagementAsc = 'STRICT_MANAGEMENT_ASC',
  StrictManagementDesc = 'STRICT_MANAGEMENT_DESC',
  SeatModelAsc = 'SEAT_MODEL_ASC',
  SeatModelDesc = 'SEAT_MODEL_DESC',
  MaxActiveSeatsAsc = 'MAX_ACTIVE_SEATS_ASC',
  MaxActiveSeatsDesc = 'MAX_ACTIVE_SEATS_DESC',
  DemoAsc = 'DEMO_ASC',
  DemoDesc = 'DEMO_DESC',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  StuCountAsc = 'STU_COUNT_ASC',
  StuCountDesc = 'STU_COUNT_DESC',
  StuContractGracePeriodInDaysAsc = 'STU_CONTRACT_GRACE_PERIOD_IN_DAYS_ASC',
  StuContractGracePeriodInDaysDesc = 'STU_CONTRACT_GRACE_PERIOD_IN_DAYS_DESC',
  ContractTypeAsc = 'CONTRACT_TYPE_ASC',
  ContractTypeDesc = 'CONTRACT_TYPE_DESC',
  LearnerManagementAsc = 'LEARNER_MANAGEMENT_ASC',
  LearnerManagementDesc = 'LEARNER_MANAGEMENT_DESC',
  SubscriptionIdAsc = 'SUBSCRIPTION_ID_ASC',
  SubscriptionIdDesc = 'SUBSCRIPTION_ID_DESC',
  TotalLicensesAsc = 'TOTAL_LICENSES_ASC',
  TotalLicensesDesc = 'TOTAL_LICENSES_DESC',
  CatalogAccessAsc = 'CATALOG_ACCESS_ASC',
  CatalogAccessDesc = 'CATALOG_ACCESS_DESC',
  ContractInitiativeLogoAsc = 'CONTRACT_INITIATIVE_LOGO_ASC',
  ContractInitiativeLogoDesc = 'CONTRACT_INITIATIVE_LOGO_DESC',
  SignatureUrlAsc = 'SIGNATURE_URL_ASC',
  SignatureUrlDesc = 'SIGNATURE_URL_DESC',
  SignatureNameAsc = 'SIGNATURE_NAME_ASC',
  SignatureNameDesc = 'SIGNATURE_NAME_DESC',
  SignatureTitleAsc = 'SIGNATURE_TITLE_ASC',
  SignatureTitleDesc = 'SIGNATURE_TITLE_DESC',
}

export type PaginatedContractTerm = EmcNode & {
  __typename?: 'PaginatedContractTerm';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  status: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  contractId: Scalars['ID'];
  /** @deprecated use the contract end date */
  startDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated use the contract end date */
  endDate?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  customSeatSupport?: Maybe<Scalars['Boolean']>;
  stuPricingOverride?: Maybe<Scalars['Int']>;
  contract?: Maybe<Contract>;
  resources?: Maybe<Array<Resource>>;
  company?: Maybe<Company>;
  enrollmentCohorts?: Maybe<EnrollmentCohortConnection>;
  learnerEnrollmentCohorts?: Maybe<EnrollmentCohortConnection>;
  /** @deprecated use the contract end date */
  enrollmentEndDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated unused */
  enrollmentWindowOffset?: Maybe<Scalars['Int']>;
  /** @deprecated unused */
  windowOffsetType?: Maybe<Scalars['String']>;
  /** @deprecated unused */
  duration?: Maybe<Scalars['Int']>;
  resource?: Maybe<Resource>;
  enrollmentCounts?: Maybe<ContractTermEnrollmentCount>;
  consumedStuCount?: Maybe<Scalars['Int']>;
  pendingStuCount?: Maybe<Scalars['Int']>;
};

export type PaginatedContractTermEnrollmentCohortsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PaginatedContractTermLearnerEnrollmentCohortsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PaginatedContractTermPaginatedConnection = TotalCount & {
  __typename?: 'PaginatedContractTermPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaginatedContractTermPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PaginatedContractTermPaginated` and its cursor. */
export type PaginatedContractTermPaginatedEdge = {
  __typename?: 'PaginatedContractTermPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<PaginatedContractTerm>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum PaginatedContractTermSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ContractIdAsc = 'CONTRACT_ID_ASC',
  ContractIdDesc = 'CONTRACT_ID_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  CustomSeatSupportAsc = 'CUSTOM_SEAT_SUPPORT_ASC',
  CustomSeatSupportDesc = 'CUSTOM_SEAT_SUPPORT_DESC',
  StuPricingOverrideAsc = 'STU_PRICING_OVERRIDE_ASC',
  StuPricingOverrideDesc = 'STU_PRICING_OVERRIDE_DESC',
}

export type PaginatedGroup = EmcNode & {
  __typename?: 'PaginatedGroup';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  companyId: Scalars['ID'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  company?: Maybe<Company>;
  learners?: Maybe<LearnerPaginatedConnection>;
};

export type PaginatedGroupLearnersArgs = {
  groupId?: Maybe<Scalars['ID']>;
  learningPathId?: Maybe<Scalars['ID']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  selectionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PaginatedGroupPaginatedConnection = TotalCount & {
  __typename?: 'PaginatedGroupPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaginatedGroupPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PaginatedGroupPaginated` and its cursor. */
export type PaginatedGroupPaginatedEdge = {
  __typename?: 'PaginatedGroupPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<PaginatedGroup>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum PaginatedGroupSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
}

export type PaginatedLearnerInterest = EmcNode & {
  __typename?: 'PaginatedLearnerInterest';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  applicationVersion: Scalars['Int'];
  learnerId: Scalars['ID'];
  programKey?: Maybe<Scalars['String']>;
  companyGraphLearningPathId?: Maybe<Scalars['String']>;
  contractTermId?: Maybe<Scalars['ID']>;
  programType?: Maybe<Scalars['String']>;
  customData?: Maybe<Scalars['JSONString']>;
  note?: Maybe<Scalars['String']>;
  noteUpdatedAt?: Maybe<Scalars['DateTime']>;
  noteUpdatedBy?: Maybe<Scalars['String']>;
  status?: Maybe<LearnerInterestStatus>;
  submissionDate?: Maybe<Scalars['DateTime']>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']>;
  statusUpdatedBy?: Maybe<Scalars['String']>;
  eligibility?: Maybe<LearnerInterestEligibility>;
  eligibilityReason?: Maybe<LearnerInterestEligibilityReason>;
  suggestedProgram?: Maybe<Scalars['String']>;
  assessmentScore?: Maybe<Scalars['Float']>;
  subjectScores?: Maybe<Scalars['JSONString']>;
  enrollmentHistory?: Maybe<Array<RegistrarEnrollment>>;
  plagiarismHistory?: Maybe<Array<PlagiarismCase>>;
  assessmentResultId?: Maybe<Scalars['Int']>;
  nominatedByLearnerId?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyGraphLearningPath?: Maybe<CompanyGraphLearningPath>;
  contractTerm?: Maybe<ContractTerm>;
  assessmentResult?: Maybe<V2AssessmentResult>;
  responses?: Maybe<Array<Maybe<AdmissionQuestionResponse>>>;
  learners?: Maybe<Learner>;
  contentTitle?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  learner?: Maybe<Learner>;
  assessmentCohortKey?: Maybe<Scalars['ID']>;
  assessmentReadiness?: Maybe<Scalars['String']>;
  assessmentEligiblePrograms?: Maybe<Scalars['String']>;
  /** The company's current QuestionSet. The learner may or may not have actually answered any of the questions on this set. */
  companyQuestionSet?: Maybe<QuestionSet>;
  /** The CGLP/program's current QuestionSet. The learner may or may not have actually answered any of the questions on this set. */
  specificQuestionSet?: Maybe<QuestionSet>;
  /** The company's current QuestionSet for nominations. The learner may or may not have actually answered any of the questions on this set. */
  nominationQuestionSet?: Maybe<QuestionSet>;
  /** Contains the answers to the current questions on the current company QuestionSet, in order. Any questions that have not been answered will be null - the array is guaranteed to have exactly as many elements as there are questions on the current company QuestionSet. Note that this may NOT be the QuestionSet that they actually answered! */
  companyQuestionSetResponses?: Maybe<Array<Maybe<AdmissionQuestionResponse>>>;
  /** Contains the answers to the current questions on the current specific QuestionSet, in order. Any questions that have not been answered will be null - the array is guaranteed to have exactly as many elements as there are questions on the current specific QuestionSet. Note that this may NOT be the QuestionSet that they actually answered! */
  specificQuestionSetResponses?: Maybe<Array<Maybe<AdmissionQuestionResponse>>>;
  /** Contains the answers to the current questions on the current nomination QuestionSet, in order. Any questions that have not been answered will be null - the array is guaranteed to have exactly as many elements as there are questions on the current nomination QuestionSet. Note that this may NOT be the QuestionSet that they actually answered! */
  nominationQuestionSetResponses?: Maybe<
    Array<Maybe<AdmissionQuestionResponse>>
  >;
  program?: Maybe<ProgramData>;
  resource?: Maybe<Resource>;
  /** Contains a list of SubjectScores corresponding to an inputted list of subject ids. For subjects that were not evaluated on this assessment, the SubjectScore will be null. */
  subjectScoresForIds?: Maybe<Array<Maybe<SubjectScore>>>;
  nominations?: Maybe<Array<Maybe<Nomination>>>;
  contracted: Scalars['Boolean'];
};

export type PaginatedLearnerInterestSubjectScoresForIdsArgs = {
  subjectIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PaginatedLearnerInterestPaginatedConnection = TotalCount & {
  __typename?: 'PaginatedLearnerInterestPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaginatedLearnerInterestPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PaginatedLearnerInterestPaginated` and its cursor. */
export type PaginatedLearnerInterestPaginatedEdge = {
  __typename?: 'PaginatedLearnerInterestPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<PaginatedLearnerInterest>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum PaginatedLearnerInterestSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ApplicationVersionAsc = 'APPLICATION_VERSION_ASC',
  ApplicationVersionDesc = 'APPLICATION_VERSION_DESC',
  LearnerIdAsc = 'LEARNER_ID_ASC',
  LearnerIdDesc = 'LEARNER_ID_DESC',
  ProgramKeyAsc = 'PROGRAM_KEY_ASC',
  ProgramKeyDesc = 'PROGRAM_KEY_DESC',
  CompanyGraphLearningPathIdAsc = 'COMPANY_GRAPH_LEARNING_PATH_ID_ASC',
  CompanyGraphLearningPathIdDesc = 'COMPANY_GRAPH_LEARNING_PATH_ID_DESC',
  ContractTermIdAsc = 'CONTRACT_TERM_ID_ASC',
  ContractTermIdDesc = 'CONTRACT_TERM_ID_DESC',
  ProgramTypeAsc = 'PROGRAM_TYPE_ASC',
  ProgramTypeDesc = 'PROGRAM_TYPE_DESC',
  CustomDataAsc = 'CUSTOM_DATA_ASC',
  CustomDataDesc = 'CUSTOM_DATA_DESC',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  NoteUpdatedAtAsc = 'NOTE_UPDATED_AT_ASC',
  NoteUpdatedAtDesc = 'NOTE_UPDATED_AT_DESC',
  NoteUpdatedByAsc = 'NOTE_UPDATED_BY_ASC',
  NoteUpdatedByDesc = 'NOTE_UPDATED_BY_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SubmissionDateAsc = 'SUBMISSION_DATE_ASC',
  SubmissionDateDesc = 'SUBMISSION_DATE_DESC',
  StatusUpdatedAtAsc = 'STATUS_UPDATED_AT_ASC',
  StatusUpdatedAtDesc = 'STATUS_UPDATED_AT_DESC',
  StatusUpdatedByAsc = 'STATUS_UPDATED_BY_ASC',
  StatusUpdatedByDesc = 'STATUS_UPDATED_BY_DESC',
  EligibilityAsc = 'ELIGIBILITY_ASC',
  EligibilityDesc = 'ELIGIBILITY_DESC',
  EligibilityReasonAsc = 'ELIGIBILITY_REASON_ASC',
  EligibilityReasonDesc = 'ELIGIBILITY_REASON_DESC',
  SuggestedProgramAsc = 'SUGGESTED_PROGRAM_ASC',
  SuggestedProgramDesc = 'SUGGESTED_PROGRAM_DESC',
  AssessmentScoreAsc = 'ASSESSMENT_SCORE_ASC',
  AssessmentScoreDesc = 'ASSESSMENT_SCORE_DESC',
  SubjectScoresAsc = 'SUBJECT_SCORES_ASC',
  SubjectScoresDesc = 'SUBJECT_SCORES_DESC',
  EnrollmentHistoryAsc = 'ENROLLMENT_HISTORY_ASC',
  EnrollmentHistoryDesc = 'ENROLLMENT_HISTORY_DESC',
  PlagiarismHistoryAsc = 'PLAGIARISM_HISTORY_ASC',
  PlagiarismHistoryDesc = 'PLAGIARISM_HISTORY_DESC',
  AssessmentResultIdAsc = 'ASSESSMENT_RESULT_ID_ASC',
  AssessmentResultIdDesc = 'ASSESSMENT_RESULT_ID_DESC',
  NominatedByLearnerIdAsc = 'NOMINATED_BY_LEARNER_ID_ASC',
  NominatedByLearnerIdDesc = 'NOMINATED_BY_LEARNER_ID_DESC',
}

export type PaginatedLearnerLifecycle = EmcNode & {
  __typename?: 'PaginatedLearnerLifecycle';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  learnerId: Scalars['ID'];
  contractId: Scalars['ID'];
  contractTermId: Scalars['ID'];
  taskArgs?: Maybe<LearnerLifecycleTaskArgs>;
  lifecycleFlag: Scalars['String'];
  actionFlag: Scalars['String'];
  errorDetails?: Maybe<Scalars['String']>;
  batchId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  learner?: Maybe<Learner>;
  contract?: Maybe<Contract>;
  contractTerm?: Maybe<ContractTerm>;
  resources?: Maybe<Array<Maybe<Resource>>>;
  cohortId?: Maybe<Scalars['ID']>;
};

export type PaginatedLearnerLifecyclePaginatedConnection = TotalCount & {
  __typename?: 'PaginatedLearnerLifecyclePaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaginatedLearnerLifecyclePaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PaginatedLearnerLifecyclePaginated` and its cursor. */
export type PaginatedLearnerLifecyclePaginatedEdge = {
  __typename?: 'PaginatedLearnerLifecyclePaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<PaginatedLearnerLifecycle>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum PaginatedLearnerLifecycleSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  LearnerIdAsc = 'LEARNER_ID_ASC',
  LearnerIdDesc = 'LEARNER_ID_DESC',
  ContractIdAsc = 'CONTRACT_ID_ASC',
  ContractIdDesc = 'CONTRACT_ID_DESC',
  ContractTermIdAsc = 'CONTRACT_TERM_ID_ASC',
  ContractTermIdDesc = 'CONTRACT_TERM_ID_DESC',
  TaskArgsAsc = 'TASK_ARGS_ASC',
  TaskArgsDesc = 'TASK_ARGS_DESC',
  LifecycleFlagAsc = 'LIFECYCLE_FLAG_ASC',
  LifecycleFlagDesc = 'LIFECYCLE_FLAG_DESC',
  ActionFlagAsc = 'ACTION_FLAG_ASC',
  ActionFlagDesc = 'ACTION_FLAG_DESC',
  ErrorDetailsAsc = 'ERROR_DETAILS_ASC',
  ErrorDetailsDesc = 'ERROR_DETAILS_DESC',
  BatchIdAsc = 'BATCH_ID_ASC',
  BatchIdDesc = 'BATCH_ID_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
}

export type PaginatedLearningPath = EmcNode & {
  __typename?: 'PaginatedLearningPath';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  status?: Maybe<Scalars['String']>;
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  ndKeys: Array<Maybe<Scalars['String']>>;
  createdBy: Scalars['String'];
  learners?: Maybe<LearnerPaginatedConnection>;
  assessments?: Maybe<AssessmentPaginatedConnection>;
};

export type PaginatedLearningPathLearnersArgs = {
  groupId?: Maybe<Scalars['ID']>;
  learningPathId?: Maybe<Scalars['ID']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  selectionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PaginatedLearningPathAssessmentsArgs = {
  learningPathId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<AssessmentSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PaginatedLearningPathPaginatedConnection = TotalCount & {
  __typename?: 'PaginatedLearningPathPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaginatedLearningPathPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PaginatedLearningPathPaginated` and its cursor. */
export type PaginatedLearningPathPaginatedEdge = {
  __typename?: 'PaginatedLearningPathPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<PaginatedLearningPath>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum PaginatedLearningPathSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  NdKeysAsc = 'ND_KEYS_ASC',
  NdKeysDesc = 'ND_KEYS_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
}

export type PaginatedRosterData = {
  __typename?: 'PaginatedRosterData';
  data?: Maybe<Array<Maybe<Roster>>>;
  lastUpdatedAt?: Maybe<Scalars['ZuluDateTime']>;
  resultsetTotal?: Maybe<Scalars['Int']>;
};

export type PlagiarismCase = {
  __typename?: 'PlagiarismCase';
  enrollmentId?: Maybe<Scalars['String']>;
  nodeKey?: Maybe<Scalars['String']>;
  submissionRubricId?: Maybe<Scalars['String']>;
  plagiarismSourceUrl?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated, use `isDemerit` and `ungradeableTag` to check for plagiarism */
  plagiarismJudgement?: Maybe<Scalars['String']>;
  isDemerit?: Maybe<Scalars['Boolean']>;
  ungradeableTag?: Maybe<Scalars['String']>;
};

/** Bulk processing for learner interests. */
export type ProcessLearnerInterests = {
  __typename?: 'ProcessLearnerInterests';
  ok?: Maybe<Scalars['Boolean']>;
  batchId?: Maybe<Scalars['String']>;
};

export type ProcessTeamInvitation = {
  __typename?: 'ProcessTeamInvitation';
  ok?: Maybe<Scalars['Boolean']>;
  learner?: Maybe<Learner>;
};

export type Program = {
  programKey: Scalars['String'];
  programType: ResourceType;
  title: Scalars['String'];
};

export type ProgramData = {
  __typename?: 'ProgramData';
  programKey?: Maybe<Scalars['String']>;
  programType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  attainedSkillIds?: Maybe<Array<Scalars['ID']>>;
  skillIds?: Maybe<Array<Scalars['ID']>>;
  preparednessThreshold?: Maybe<Scalars['Float']>;
  passThreshold?: Maybe<Scalars['Float']>;
};

export type ProgramDetails = {
  __typename?: 'ProgramDetails';
  title?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  heroImageUrl?: Maybe<Scalars['String']>;
};

export type ProgramInfo = {
  __typename?: 'ProgramInfo';
  programKey?: Maybe<Scalars['String']>;
  programVersion?: Maybe<Scalars['String']>;
  programType?: Maybe<Scalars['String']>;
  contentLocale?: Maybe<Scalars['String']>;
  totalMandatoryProjects?: Maybe<Scalars['Int']>;
  totalElectiveProjects?: Maybe<Scalars['Int']>;
  totalConcepts?: Maybe<Scalars['Int']>;
  totalQuestions?: Maybe<Scalars['Int']>;
};

export type ProgramToRemove = {
  programKey: Scalars['String'];
  programType: ResourceType;
};

export type ProgramUrn = {
  __typename?: 'ProgramURN';
  programType?: Maybe<ResourceType>;
  programKey: Scalars['String'];
  programLocale: Scalars['String'];
  programMajor: Scalars['Int'];
  /** Canonical representation of this program */
  urn: Scalars['String'];
  programDetails: ProgramDetails;
};

export type ProgressSummary = {
  __typename?: 'ProgressSummary';
  companyId: Scalars['ID'];
  batchId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
  succeeded: Scalars['Int'];
  failed: Scalars['Int'];
  inProgress: Scalars['Int'];
  errors?: Maybe<Array<ProgressSummaryError>>;
};

export type ProgressSummaryError = {
  __typename?: 'ProgressSummaryError';
  taskKey: Scalars['String'];
  taskError: Scalars['String'];
};

export type Project = {
  __typename?: 'Project';
  userKey?: Maybe<Scalars['String']>;
  learnerId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  enrollmentId?: Maybe<Scalars['String']>;
  programKey?: Maybe<Scalars['String']>;
  programVersion?: Maybe<Scalars['String']>;
  programType?: Maybe<Scalars['String']>;
  contentLocale?: Maybe<Scalars['String']>;
  projectOrder?: Maybe<Scalars['Int']>;
  /** Number of times the learner has submitted this project for review */
  attemptCount?: Maybe<Scalars['Int']>;
  firstAttemptedAt?: Maybe<Scalars['ZuluDateTime']>;
  lastAttempt?: Maybe<Scalars['ZuluDateTime']>;
  passDate?: Maybe<Scalars['ZuluDateTime']>;
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  projectType?: Maybe<ProjectType>;
  contractTermId?: Maybe<Scalars['ID']>;
  rubricId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  submissionDeadline?: Maybe<Scalars['ZuluDateTime']>;
  nextProjectDurationInDays?: Maybe<Scalars['Int']>;
  isOptional?: Maybe<Scalars['Boolean']>;
  isCareer?: Maybe<Scalars['Boolean']>;
};

export type ProjectCompletionDownCheck = {
  __typename?: 'ProjectCompletionDownCheck';
  alertTriggered?: Maybe<Scalars['Boolean']>;
};

export type ProjectDeadline = {
  __typename?: 'ProjectDeadline';
  id?: Maybe<Scalars['ID']>;
  dueAt?: Maybe<Scalars['String']>;
  projectKey?: Maybe<Scalars['String']>;
  projectTitle?: Maybe<Scalars['String']>;
  scheduleId?: Maybe<Scalars['String']>;
};

export type ProjectSkill = {
  __typename?: 'ProjectSkill';
  skillId: Scalars['String'];
  subjectId: Scalars['String'];
  skillName: Scalars['String'];
  subjectName: Scalars['String'];
  projectIds: Array<Scalars['ID']>;
};

export enum ProjectType {
  Core = 'CORE',
  Elective = 'ELECTIVE',
}

export type PublicMutation = {
  __typename?: 'PublicMutation';
  /** Transfers learners between programs/contract-terms */
  transferLearners?: Maybe<TransferLearners>;
  triggerAutoUnenroll?: Maybe<TriggerAutoUnenroll>;
  updateEnrollmentDates?: Maybe<UpdateEnrollmentDates>;
  triggerAutoUnenrollLearnersPastEstimatedEndDate?: Maybe<TriggerAutoUnenrollLearnersPastEstimatedEndDate>;
  enrollLearners?: Maybe<EnrollLearners>;
  unenrollLearners?: Maybe<UnenrollLearners>;
  enrollLearnersIntoLearningPath?: Maybe<EnrollLearnersIntoLearningPath>;
  /**
   * Adds learner to a cohort with corresponding contract term
   *          association. If the cohort is planned, enrollment will happen at cohort launch.
   *          If the cohort is active, an enrollment is processed immediately.
   */
  enrollLearnersIntoCohort?: Maybe<EnrollLearnersIntoCohort>;
  updateStuCount?: Maybe<UpdateStuCount>;
  enrollEnterpriseUserSynchronous?: Maybe<EnrollEnterpriseUserSynchronous>;
  addLearnersToGroup?: Maybe<AddLearnersToGroup>;
  removeLearnersFromGroup?: Maybe<RemoveLearnersFromGroup>;
  createGroup?: Maybe<CreateGroup>;
  updateGroup?: Maybe<UpdateGroup>;
  deleteGroup?: Maybe<DeleteGroup>;
  /** Process the creation of learners from one or more uploaded learner csvs. */
  createLearnersFromCsvUpload?: Maybe<CreateLearnersFromCsvUpload>;
  deleteLearner?: Maybe<DeleteLearner>;
  createLearnerSelfServe?: Maybe<Learner>;
  createLearners?: Maybe<CreateLearners>;
  updateLearner?: Maybe<Learner>;
  /** Ensure that all Learner's with this user_key have an up-to-date email based on their Udacity user account. */
  syncLearnerEmail?: Maybe<SyncLearnerEmail>;
  createLearningPath?: Maybe<CreateLearningPath>;
  updateLearningPath?: Maybe<UpdateLearningPath>;
  addLearnersToLearningPath?: Maybe<AddLearnersToLearningPath>;
  removeLearnersFromLearningPath?: Maybe<RemoveLearnersFromLearningPath>;
  /** Accept the Terms of Use, either as yourself or on a user's behalf. */
  acceptTermsOfUseVersion?: Maybe<AcceptTermsOfUseVersion>;
  /** Create a company-specific terms of use version. */
  createCompanyTermsOfUseVersion?: Maybe<CreateCompanyTermsOfUseVersion>;
  createAssessment?: Maybe<CreateAssessment>;
  updateAssessment?: Maybe<UpdateAssessment>;
  deleteIntroductoryProgramsInAssessment?: Maybe<DeleteIntroductoryProgramsInAssessment>;
  updateAssessmentCohort?: Maybe<UpdateAssessmentCohort>;
  createAssessmentLabSession?: Maybe<AssessmentLabSession>;
  updateAssessmentLabSession?: Maybe<AssessmentLabSession>;
  createCompany?: Maybe<CreateCompany>;
  updateCompany?: Maybe<UpdateCompany>;
  createContractTerms?: Maybe<Array<ContractTerm>>;
  updateContractTerms?: Maybe<UpdateContractTerms>;
  addRoleToLearner?: Maybe<AddRoleToLearner>;
  removeRoleFromLearner?: Maybe<RemoveRoleFromLearner>;
  updateManagerRole?: Maybe<UpdateManagerRole>;
  removeManagerRoles?: Maybe<RemoveManagerRoles>;
  generateLearnerInterestCsv?: Maybe<GenerateLearnerInterestCsv>;
  /** Adds new learner interest, unless one exists for learner ID and program key pair, then update changed fields. */
  createLearnerInterest?: Maybe<LearnerInterest>;
  /** Updates an existing LearnerInterest */
  updateLearnerInterest?: Maybe<LearnerInterest>;
  /** Staff-only mutation to trigger re-evaluation of eligibility for a learner interest */
  recomputeLearnerInterestEligibility?: Maybe<LearnerInterest>;
  /** Sets deleteDate on an existing LearnerInterest */
  deleteLearnerInterest?: Maybe<DeleteLearnerInterest>;
  /** Processes a batch of LearnerInterests */
  processLearnerInterests?: Maybe<ProcessLearnerInterests>;
  /** Adds new learner interest as a nomination. Creates a new learner for nominee if nominee does not already exist in EMC. */
  nominateLearnerInterest?: Maybe<LearnerInterest>;
  /** Express interest in a program, but do not begin a formal application */
  showInterestInProgram?: Maybe<LearnerInterest>;
  /** Remove interest in a program that was previously submitted */
  removeInterestInProgram?: Maybe<RemoveInterestInProgram>;
  /** Backfills existing learner interests to denormalized table. */
  recomputeDenormalizedLearnerInterests?: Maybe<RecomputeDenormalizedLearnerInterests>;
  /** Backfills existing learner interests of a company to denormalized table. */
  recomputeCompanyDenormalizedLearnerInterests?: Maybe<RecomputeCompanyDenormalizedLearnerInterests>;
  createAssessmentWhitelistedProgram?: Maybe<CreateAssessmentWhitelistedProgram>;
  deleteAssessmentWhitelistedProgram?: Maybe<DeleteAssessmentWhitelistedProgram>;
  synchronizeAssessmentWhitelistedPrograms?: Maybe<SynchronizeAssessmentWhitelistedPrograms>;
  createContract?: Maybe<CreateContract>;
  updateContract?: Maybe<UpdateContract>;
  updateResource?: Maybe<UpdateResource>;
  updateStaleResourceData?: Maybe<UpdateStaleResourceData>;
  createSetting?: Maybe<CreateSetting>;
  updateSetting?: Maybe<UpdateSetting>;
  deleteSettingAndOverrides?: Maybe<DeleteSettingAndOverrides>;
  createCompanySetting?: Maybe<CreateCompanySetting>;
  updateCompanySetting?: Maybe<UpdateCompanySetting>;
  deleteCompanySetting?: Maybe<DeleteCompanySetting>;
  createCatalogProgram?: Maybe<CreateCatalogProgram>;
  deleteCatalogProgram?: Maybe<DeleteCatalogProgram>;
  updateCatalogProgramUrl?: Maybe<UpdateCatalogProgramUrl>;
  updateCatalogProgram?: Maybe<UpdateCatalogProgram>;
  createCatalogTag?: Maybe<CatalogTag>;
  updateCatalogTag?: Maybe<CatalogTag>;
  deleteCatalogTag?: Maybe<DeleteCatalogTag>;
  recomputeLearnerRealTimeReports?: Maybe<RecomputeLearnerRealTimeReports>;
  recomputeUserRealTimeReports?: Maybe<RecomputeUserRealTimeReports>;
  recomputeCompanyRealTimeReports?: Maybe<RecomputeCompanyRealTimeReports>;
  initializeRealTimeReportsPartitionManager?: Maybe<InitializeRealTimeReportsPartitionManager>;
  recomputeRealTimeReportsPartition?: Maybe<RecomputeRealTimeReportsPartition>;
  retryLearnerLifecycles?: Maybe<RetryLearnerLifecycles>;
  recomputeOnTrackStatusForCompany?: Maybe<RecomputeOnTrackStatusForCompany>;
  recomputeOnTrackStatusForActiveEnrollments?: Maybe<RecomputeOnTrackStatusForActiveEnrollments>;
  createEnrollmentCohorts?: Maybe<CreateEnrollmentCohorts>;
  updateEnrollmentCohort?: Maybe<UpdateEnrollmentCohort>;
  /** Add or remove Learners from a Contract Term in a given Enrollment Cohort. */
  updateCohortLearnersAssociation?: Maybe<UpdateCohortLearnersAssociation>;
  /** Enrolls all of the Learners in their associated Contract Terms for the given Enrollment Cohort. */
  launchEnrollmentCohort?: Maybe<LaunchEnrollmentCohort>;
  deleteEnrollmentCohort?: Maybe<DeleteEnrollmentCohort>;
  archiveEnrollmentCohorts?: Maybe<ArchiveEnrollmentCohorts>;
  /**
   * From the provided Enrollments, it adds the Contract Terms to the Enrollment Cohort, adds the
   * the Learners to the Enrollment Cohort, and associates the Learners with the Contract Terms. It
   * then updates the claims in Registrar.
   */
  addEnrollmentsToEnrollmentCohort?: Maybe<AddEnrollmentsToEnrollmentCohort>;
  inviteLearnersToAssessment?: Maybe<InviteLearnersToAssessment>;
  createGraphLearningPath?: Maybe<CreateGraphLearningPath>;
  updateGraphLearningPath?: Maybe<UpdateGraphLearningPath>;
  createCompanyGraphLearningPath?: Maybe<CreateCompanyGraphLearningPath>;
  updateCompanyGraphLearningPath?: Maybe<UpdateCompanyGraphLearningPath>;
  deleteCompanyGraphLearningPath?: Maybe<DeleteCompanyGraphLearningPath>;
  handleLearnerGlpAssociations?: Maybe<HandleLearnerGlpAssociations>;
  handleEnrollmentCohortGlpAssociations?: Maybe<HandleEnrollmentCohortGlpAssociations>;
  createGraphLearningPathCollection?: Maybe<CreateGraphLearningPathCollection>;
  updateGraphLearningPathCollection?: Maybe<UpdateGraphLearningPathCollection>;
  deleteGraphLearningPathCollection?: Maybe<DeleteGraphLearningPathCollection>;
  handleGlpCollectionItems?: Maybe<HandleGlpCollectionItems>;
  sendFakeProjectCompletionToCsod?: Maybe<SendFakeProjectCompletionToCsod>;
  /**
   * Endpoint useful in testing the auto enrollment flow.  Can be triggered on a specific
   * `learner_interest_id`, only available to enterprise engineers.
   */
  triggerAutoEnroll?: Maybe<TriggerAutoEnroll>;
  projectCompletionDownCheck?: Maybe<ProjectCompletionDownCheck>;
  createQuestionSet?: Maybe<QuestionSet>;
  updateQuestionSet?: Maybe<QuestionSet>;
  deleteQuestionSet?: Maybe<QuestionSet>;
  createSelfServeToken?: Maybe<SelfServeToken>;
  deleteSelfServeToken?: Maybe<SelfServeToken>;
  answerAdmissionQuestions?: Maybe<Array<AdmissionQuestionResponse>>;
  updateProgram?: Maybe<UpdateProgram>;
  turnOnCompanyPartnerIntegration?: Maybe<CompanyLearningPartner>;
  recomputePartnerProgramsForCompany?: Maybe<RecomputePartnerProgramsForCompany>;
  recomputePartnerPrograms?: Maybe<RecomputePartnerPrograms>;
  resetAllPartnerLearnerCompletions?: Maybe<ResetAllPartnerLearnerCompletions>;
  markRecentPartnerLearnerCompletions?: Maybe<MarkRecentPartnerLearnerCompletions>;
  updateEnrollmentHistoryProperties?: Maybe<UpdateEnrollmentHistoryProperties>;
  regenerateCatalogToken?: Maybe<RegenerateCatalogToken>;
  invalidateCatalogTokens?: Maybe<InvalidateCatalogTokens>;
  addTalentPlanLink?: Maybe<AddTalentPlanLink>;
  deleteTalentPlan?: Maybe<DeleteTalentPlan>;
  updateTalentPlan?: Maybe<UpdateTalentPlan>;
  sendAllLearnerProgramCompletions?: Maybe<SendAllLearnerProgramCompletions>;
  sendSpecificLearnerProgramCompletions?: Maybe<SendSpecificLearnerProgramCompletions>;
  sendAllCompanyMonthlyDigests?: Maybe<SendAllCompanyMonthlyDigests>;
  recomputeAllOutdatedAssessmentResults?: Maybe<RecomputeAllOutdatedAssessmentResults>;
  recomputeCompanyOutdatedAssessmentResults?: Maybe<RecomputeCompanyOutdatedAssessmentResults>;
  createCertificationAssessment?: Maybe<CreateCertificationAssessment>;
  updateCertificationAssessment?: Maybe<UpdateCertificationAssessment>;
  deleteCertificationAssessment?: Maybe<DeleteCertificationAssessment>;
  createDiscourseConfiguration?: Maybe<CreateDiscourseConfiguration>;
  updateDiscourseConfiguration?: Maybe<UpdateDiscourseConfiguration>;
  deleteDiscourseConfiguration?: Maybe<DeleteDiscourseConfiguration>;
  createAuthToken?: Maybe<CreateAuthToken>;
  updateAuthToken?: Maybe<UpdateAuthToken>;
  addLearnersToContract?: Maybe<AddLearnersToContract>;
  removeLearnersFromContract?: Maybe<RemoveLearnersFromContract>;
  inviteTeamMembers?: Maybe<InviteTeamMembers>;
  deleteTeamInvitation?: Maybe<DeleteTeamInvitation>;
  processTeamInvitation?: Maybe<ProcessTeamInvitation>;
};

export type PublicMutationTransferLearnersArgs = {
  action?: Maybe<TransferLearnersAction>;
  destinationContractId?: Maybe<Scalars['ID']>;
  destinationContractTermId?: Maybe<Scalars['ID']>;
  destinationEnrollmentCohortId?: Maybe<Scalars['ID']>;
  learnerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  selectionFilters?: Maybe<Array<Maybe<LearnerBulkSelectionFilter>>>;
  sourceContractId?: Maybe<Scalars['ID']>;
  sourceContractTermId?: Maybe<Scalars['ID']>;
};

export type PublicMutationTriggerAutoUnenrollArgs = {
  dryRun?: Maybe<Scalars['Boolean']>;
};

export type PublicMutationUpdateEnrollmentDatesArgs = {
  companyId: Scalars['ID'];
  enrollmentIds: Array<Maybe<Scalars['String']>>;
  utcEndDate?: Maybe<Scalars['String']>;
  utcStartDate?: Maybe<Scalars['String']>;
};

export type PublicMutationTriggerAutoUnenrollLearnersPastEstimatedEndDateArgs =
  {
    dryRun?: Maybe<Scalars['Boolean']>;
  };

export type PublicMutationEnrollLearnersArgs = {
  cohortId?: Maybe<Scalars['ID']>;
  contractTermId: Scalars['ID'];
  enrollmentCohortId?: Maybe<Scalars['ID']>;
  selectionFilters: Array<Maybe<LearnerBulkSelectionFilter>>;
  utcEndDate?: Maybe<Scalars['ZuluDateTime']>;
};

export type PublicMutationUnenrollLearnersArgs = {
  allContractTerms?: Maybe<Scalars['Boolean']>;
  allContracts?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['ID']>;
  contractTermId?: Maybe<Scalars['ID']>;
  selectionFilters: Array<Maybe<LearnerBulkSelectionFilter>>;
  unenrollmentReason?: Maybe<UnenrollmentReason>;
};

export type PublicMutationEnrollLearnersIntoLearningPathArgs = {
  companyGraphLearningPathId: Scalars['ID'];
  contractTermId: Scalars['ID'];
  enrollmentCohortId?: Maybe<Scalars['ID']>;
  learnerIds: Array<Scalars['ID']>;
};

export type PublicMutationEnrollLearnersIntoCohortArgs = {
  contractTermId: Scalars['ID'];
  enrollmentCohortId: Scalars['ID'];
  learnerIds: Array<Scalars['ID']>;
};

export type PublicMutationUpdateStuCountArgs = {
  enrollmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  enrollmentStuUpdates?: Maybe<Array<EnrollmentStuCountInput>>;
  stuCountOverride?: Maybe<Scalars['Int']>;
};

export type PublicMutationEnrollEnterpriseUserSynchronousArgs = {
  companyId?: Maybe<Scalars['ID']>;
  programKey: Scalars['String'];
  programLocale: Scalars['String'];
  programVersion: Scalars['String'];
  userKey: Scalars['String'];
};

export type PublicMutationAddLearnersToGroupArgs = {
  groupId: Scalars['ID'];
  learnerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  learnerIdsFilters?: Maybe<Array<Maybe<LearnerBulkSelectionFilter>>>;
};

export type PublicMutationRemoveLearnersFromGroupArgs = {
  groupId: Scalars['ID'];
  learnerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  learnerIdsFilters?: Maybe<Array<Maybe<LearnerBulkSelectionFilter>>>;
};

export type PublicMutationCreateGroupArgs = {
  companyId: Scalars['ID'];
  name: Scalars['String'];
};

export type PublicMutationUpdateGroupArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type PublicMutationDeleteGroupArgs = {
  id: Scalars['ID'];
};

export type PublicMutationCreateLearnersFromCsvUploadArgs = {
  companyId: Scalars['ID'];
  groupId?: Maybe<Scalars['ID']>;
  uploadIds: Array<Maybe<Scalars['ID']>>;
};

export type PublicMutationDeleteLearnerArgs = {
  forceUnenroll?: Maybe<Scalars['Boolean']>;
  learnerId: Scalars['ID'];
};

export type PublicMutationCreateLearnerSelfServeArgs = {
  tokenId: Scalars['ID'];
};

export type PublicMutationCreateLearnersArgs = {
  companyId: Scalars['ID'];
  learners?: Maybe<Array<NewLearnerInput>>;
  nominationType?: Maybe<NominationType>;
};

export type PublicMutationUpdateLearnerArgs = {
  learnerId: Scalars['ID'];
  managerLearnerId?: Maybe<Scalars['ID']>;
  removeManagerLearnerId?: Maybe<Scalars['Boolean']>;
};

export type PublicMutationSyncLearnerEmailArgs = {
  dryRun?: Maybe<Scalars['Boolean']>;
  userKey: Scalars['String'];
};

export type PublicMutationCreateLearningPathArgs = {
  companyId?: Maybe<Scalars['ID']>;
  createdBy: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ndKeys: Array<Maybe<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
};

export type PublicMutationUpdateLearningPathArgs = {
  description?: Maybe<Scalars['String']>;
  learningPathId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  ndKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
};

export type PublicMutationAddLearnersToLearningPathArgs = {
  learnerIds: Array<Maybe<Scalars['ID']>>;
  learningPathId: Scalars['ID'];
};

export type PublicMutationRemoveLearnersFromLearningPathArgs = {
  learnerIds: Array<Maybe<Scalars['ID']>>;
  learningPathId: Scalars['ID'];
};

export type PublicMutationAcceptTermsOfUseVersionArgs = {
  termsOfUseVersionId: Scalars['ID'];
  userKey?: Maybe<Scalars['String']>;
};

export type PublicMutationCreateCompanyTermsOfUseVersionArgs = {
  companyId: Scalars['ID'];
  contents: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  debounceTimeout?: Maybe<Scalars['Int']>;
};

export type PublicMutationCreateAssessmentArgs = {
  assessmentModel?: Maybe<AssessmentModelType>;
  assessmentType: Scalars['String'];
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  createAssessmentCohort?: Maybe<Scalars['Boolean']>;
  enableSubjectSelfReport?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  maxRetakes?: Maybe<Scalars['Int']>;
  programs: Array<Maybe<Program>>;
  showsProgramPreference?: Maybe<Scalars['Boolean']>;
  showsProgramReadiness?: Maybe<Scalars['Boolean']>;
  showsScore?: Maybe<Scalars['Boolean']>;
};

export type PublicMutationUpdateAssessmentArgs = {
  assessmentId: Scalars['ID'];
  assessmentType?: Maybe<Scalars['String']>;
  enableSubjectSelfReport?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  maxRetakes?: Maybe<Scalars['Int']>;
  programsToAdd?: Maybe<Array<Maybe<Program>>>;
  programsToRemove?: Maybe<Array<Maybe<ProgramToRemove>>>;
  showsProgramPreference?: Maybe<Scalars['Boolean']>;
  showsProgramReadiness?: Maybe<Scalars['Boolean']>;
  showsScore?: Maybe<Scalars['Boolean']>;
  v2ify?: Maybe<Scalars['Boolean']>;
};

export type PublicMutationDeleteIntroductoryProgramsInAssessmentArgs = {
  programKeysToRemove?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PublicMutationUpdateAssessmentCohortArgs = {
  archive?: Maybe<Scalars['Boolean']>;
  cohortKey: Scalars['String'];
  delete?: Maybe<Scalars['Boolean']>;
};

export type PublicMutationCreateAssessmentLabSessionArgs = {
  cohortKey: Scalars['ID'];
  learnerId: Scalars['ID'];
  programPreference?: Maybe<Scalars['String']>;
};

export type PublicMutationUpdateAssessmentLabSessionArgs = {
  id: Scalars['ID'];
  programPreference?: Maybe<Scalars['String']>;
  selfReportedSubjects?: Maybe<Array<SelfReportedSubjectInput>>;
};

export type PublicMutationCreateCompanyArgs = {
  address?: Maybe<Scalars['String']>;
  childCompanyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companyTypeId?: Maybe<Scalars['ID']>;
  country: Scalars['String'];
  dbaName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enableAutoUnenroll?: Maybe<Scalars['Boolean']>;
  isSso?: Maybe<Scalars['Boolean']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentCompanyId?: Maybe<Scalars['ID']>;
  primaryContact?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  useLogoOnCertificate?: Maybe<Scalars['Boolean']>;
};

export type PublicMutationUpdateCompanyArgs = {
  address?: Maybe<Scalars['String']>;
  childCompanyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  country?: Maybe<Scalars['String']>;
  dbaName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enableAutoUnenroll?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isSso?: Maybe<Scalars['Boolean']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nominationQuestionSetId?: Maybe<Scalars['ID']>;
  parentCompanyId?: Maybe<Scalars['ID']>;
  primaryContact?: Maybe<Scalars['String']>;
  questionSetId?: Maybe<Scalars['ID']>;
  removeNominationQuestionSet?: Maybe<Scalars['Boolean']>;
  removeQuestionSet?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  useLogoOnCertificate?: Maybe<Scalars['Boolean']>;
};

export type PublicMutationCreateContractTermsArgs = {
  contractId: Scalars['ID'];
  contractTerms?: Maybe<Array<Maybe<ContractTermInput>>>;
  status?: Maybe<Scalars['String']>;
};

export type PublicMutationUpdateContractTermsArgs = {
  contractTermIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contractTermUpdates?: Maybe<Array<Maybe<ContractTermUpdateInput>>>;
  status?: Maybe<Scalars['String']>;
};

export type PublicMutationAddRoleToLearnerArgs = {
  learnerId: Scalars['ID'];
  roleId: Scalars['ID'];
};

export type PublicMutationRemoveRoleFromLearnerArgs = {
  learnerId: Scalars['ID'];
  roleId: Scalars['ID'];
};

export type PublicMutationUpdateManagerRoleArgs = {
  companyId: Scalars['ID'];
  learnerId: Scalars['ID'];
  roleId: Scalars['ID'];
};

export type PublicMutationRemoveManagerRolesArgs = {
  companyId: Scalars['ID'];
  learnerId: Scalars['ID'];
};

export type PublicMutationGenerateLearnerInterestCsvArgs = {
  assessmentScoreLower?: Maybe<Scalars['Float']>;
  assessmentScoreUpper?: Maybe<Scalars['Float']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  contentTypes?: Maybe<Array<Maybe<LearnerInterestProgramType>>>;
  eligibility?: Maybe<LearnerInterestEligibility>;
  learnerInterestCsvType?: Maybe<LearnerInterestCsvType>;
  nominationTypes?: Maybe<Array<NominationType>>;
  programKey?: Maybe<Scalars['String']>;
  programType?: Maybe<ResourceType>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<LearnerInterestStatus>;
  submittedAfter?: Maybe<Scalars['DateTime']>;
  submittedBefore?: Maybe<Scalars['DateTime']>;
};

export type PublicMutationCreateLearnerInterestArgs = {
  applicationVersion?: Maybe<Scalars['Int']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  customData?: Maybe<Scalars['JSONString']>;
  learnerId: Scalars['ID'];
  programKey?: Maybe<Scalars['String']>;
  programType?: Maybe<ResourceType>;
  reapply?: Maybe<Scalars['Boolean']>;
  status?: Maybe<LearnerInterestStatus>;
};

export type PublicMutationUpdateLearnerInterestArgs = {
  assessmentResultId?: Maybe<Scalars['ID']>;
  companyQuestionSetId?: Maybe<Scalars['ID']>;
  customData?: Maybe<Scalars['JSONString']>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  specificQuestionSetId?: Maybe<Scalars['ID']>;
  status?: Maybe<LearnerInterestStatus>;
};

export type PublicMutationRecomputeLearnerInterestEligibilityArgs = {
  id: Scalars['ID'];
};

export type PublicMutationDeleteLearnerInterestArgs = {
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  learnerId: Scalars['ID'];
  programKey?: Maybe<Scalars['String']>;
  programType?: Maybe<ResourceType>;
};

export type PublicMutationProcessLearnerInterestsArgs = {
  action: LearnerInterestAction;
  companyId: Scalars['ID'];
  enrollmentCohortId?: Maybe<Scalars['ID']>;
  exclusionFilters?: Maybe<Array<LearnerInterestBulkSelectionFilter>>;
  note?: Maybe<Scalars['String']>;
  selectionFilters: Array<LearnerInterestBulkSelectionFilter>;
};

export type PublicMutationNominateLearnerInterestArgs = {
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  isNominatorManager?: Maybe<Scalars['Boolean']>;
  learnerEmail: Scalars['String'];
  learnerFirstName: Scalars['String'];
  learnerLastName: Scalars['String'];
  nominationType?: Maybe<NominationType>;
  programKey?: Maybe<Scalars['String']>;
  programType?: Maybe<ResourceType>;
};

export type PublicMutationShowInterestInProgramArgs = {
  companyId: Scalars['ID'];
  nextUrl: Scalars['String'];
  programKey: Scalars['String'];
  programType: ResourceType;
};

export type PublicMutationRemoveInterestInProgramArgs = {
  companyId: Scalars['ID'];
  programKey: Scalars['String'];
  programType: ResourceType;
};

export type PublicMutationRecomputeCompanyDenormalizedLearnerInterestsArgs = {
  companyId: Scalars['ID'];
};

export type PublicMutationCreateAssessmentWhitelistedProgramArgs = {
  programKey: Scalars['String'];
};

export type PublicMutationDeleteAssessmentWhitelistedProgramArgs = {
  programKey: Scalars['String'];
};

export type PublicMutationSynchronizeAssessmentWhitelistedProgramsArgs = {
  dryRun?: Scalars['Boolean'];
};

export type PublicMutationCreateContractArgs = {
  catalogAccess?: Maybe<Array<Maybe<CatalogAccess>>>;
  companyId: Scalars['ID'];
  contractInitiativeLogo?: Maybe<Scalars['String']>;
  contractType?: Maybe<ContractType>;
  demo?: Maybe<Scalars['Boolean']>;
  endDate: Scalars['DateTime'];
  isStuContract?: Maybe<Scalars['Boolean']>;
  maxActiveSeats?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  sharedCompanyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  signatureName?: Maybe<Scalars['String']>;
  signatureTitle?: Maybe<Scalars['String']>;
  signatureUrl?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status?: Maybe<Scalars['String']>;
  strictManagement?: Maybe<Scalars['Boolean']>;
  stuContractGracePeriodInDays?: Maybe<Scalars['Int']>;
  stuCount?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  totalLicenses?: Maybe<Scalars['Int']>;
};

export type PublicMutationUpdateContractArgs = {
  contractInitiativeLogo?: Maybe<Scalars['String']>;
  contractType?: Maybe<ContractType>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isStuContract?: Maybe<Scalars['Boolean']>;
  maxActiveSeats?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  sharedCompanyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  signatureName?: Maybe<Scalars['String']>;
  signatureTitle?: Maybe<Scalars['String']>;
  signatureUrl?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  strictManagement?: Maybe<Scalars['Boolean']>;
  stuContractGracePeriodInDays?: Maybe<Scalars['Int']>;
  stuCount?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  totalLicenses?: Maybe<Scalars['Int']>;
};

export type PublicMutationUpdateResourceArgs = {
  assessmentCohortKey?: Maybe<Scalars['ID']>;
  autoEnrollSetting?: Maybe<AutoEnrollSettingInput>;
  data?: Maybe<Scalars['String']>;
  datatype?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  durationOverride?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  removeAssessmentCohort?: Maybe<Scalars['Boolean']>;
  removeDurationOverride?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['String']>;
};

export type PublicMutationUpdateStaleResourceDataArgs = {
  staleTtlSeconds?: Maybe<Scalars['Int']>;
};

export type PublicMutationCreateSettingArgs = {
  defaultValue: Scalars['String'];
  name: Scalars['String'];
  valueType: Scalars['String'];
};

export type PublicMutationUpdateSettingArgs = {
  defaultValue?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  valueType?: Maybe<Scalars['String']>;
};

export type PublicMutationDeleteSettingAndOverridesArgs = {
  id: Scalars['ID'];
};

export type PublicMutationCreateCompanySettingArgs = {
  companyId: Scalars['ID'];
  overrideValue: Scalars['String'];
  settingId: Scalars['ID'];
};

export type PublicMutationUpdateCompanySettingArgs = {
  id: Scalars['ID'];
  overrideValue: Scalars['String'];
};

export type PublicMutationDeleteCompanySettingArgs = {
  id: Scalars['ID'];
};

export type PublicMutationCreateCatalogProgramArgs = {
  catalogUrl?: Maybe<Scalars['String']>;
  isStandard?: Maybe<Scalars['Boolean']>;
  programKey: Scalars['String'];
  programType: ResourceType;
  stuPricing?: Maybe<Scalars['Int']>;
  tagIds?: Maybe<Array<Scalars['ID']>>;
};

export type PublicMutationDeleteCatalogProgramArgs = {
  programKey: Scalars['String'];
  programType: ResourceType;
};

export type PublicMutationUpdateCatalogProgramUrlArgs = {
  catalogUrl: Scalars['String'];
  programKey: Scalars['String'];
  programType: ResourceType;
};

export type PublicMutationUpdateCatalogProgramArgs = {
  catalogUrl?: Maybe<Scalars['String']>;
  isStandard?: Maybe<Scalars['Boolean']>;
  programKey: Scalars['String'];
  programType: ResourceType;
  stuPricing?: Maybe<Scalars['Int']>;
  tagIds?: Maybe<Array<Scalars['ID']>>;
};

export type PublicMutationCreateCatalogTagArgs = {
  category: CatalogTagCategory;
  colorScheme: CatalogTagColorScheme;
  value: Scalars['String'];
};

export type PublicMutationUpdateCatalogTagArgs = {
  category?: Maybe<CatalogTagCategory>;
  colorScheme?: Maybe<CatalogTagColorScheme>;
  id: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
};

export type PublicMutationDeleteCatalogTagArgs = {
  id: Scalars['ID'];
};

export type PublicMutationRecomputeLearnerRealTimeReportsArgs = {
  learnerIds?: Maybe<Array<Scalars['ID']>>;
};

export type PublicMutationRecomputeUserRealTimeReportsArgs = {
  userKeys?: Maybe<Array<Scalars['ID']>>;
};

export type PublicMutationRecomputeCompanyRealTimeReportsArgs = {
  companyId: Scalars['ID'];
};

export type PublicMutationInitializeRealTimeReportsPartitionManagerArgs = {
  partitionTotal?: Maybe<Scalars['Int']>;
};

export type PublicMutationRetryLearnerLifecyclesArgs = {
  learnerLifecycleIds: Array<Maybe<Scalars['ID']>>;
};

export type PublicMutationRecomputeOnTrackStatusForCompanyArgs = {
  companyId: Scalars['ID'];
};

export type PublicMutationCreateEnrollmentCohortsArgs = {
  companyId: Scalars['ID'];
  enrollmentCohorts?: Maybe<Array<NewEnrollmentCohortInput>>;
};

export type PublicMutationUpdateEnrollmentCohortArgs = {
  contractTermIdsToAdd?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contractTermIdsToRemove?: Maybe<Array<Maybe<Scalars['ID']>>>;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  learnerIdsToAdd?: Maybe<Array<Maybe<Scalars['ID']>>>;
  learnerIdsToAddFilters?: Maybe<Array<Maybe<LearnerBulkSelectionFilter>>>;
  learnerIdsToRemove?: Maybe<Array<Maybe<Scalars['ID']>>>;
  learnerIdsToRemoveFilters?: Maybe<Array<Maybe<LearnerBulkSelectionFilter>>>;
  name?: Maybe<Scalars['String']>;
  projectDeadlines?: Maybe<Array<Maybe<UpdateProjectDeadlineInput>>>;
  shouldUnenrollLearnersBeingRemoved?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  unenrollmentReason?: Maybe<UnenrollmentReason>;
};

export type PublicMutationUpdateCohortLearnersAssociationArgs = {
  contractTermId: Scalars['ID'];
  enrollmentCohortId: Scalars['ID'];
  learnerIdsToAdd?: Maybe<Array<Maybe<Scalars['ID']>>>;
  learnerIdsToAddFilters?: Maybe<Array<Maybe<LearnerBulkSelectionFilter>>>;
  learnerIdsToRemove?: Maybe<Array<Maybe<Scalars['ID']>>>;
  learnerIdsToRemoveFilters?: Maybe<Array<Maybe<LearnerBulkSelectionFilter>>>;
};

export type PublicMutationLaunchEnrollmentCohortArgs = {
  enrollmentCohortId: Scalars['ID'];
  updateStartDate?: Maybe<Scalars['Boolean']>;
};

export type PublicMutationDeleteEnrollmentCohortArgs = {
  id: Scalars['ID'];
};

export type PublicMutationArchiveEnrollmentCohortsArgs = {
  companyId: Scalars['ID'];
  enrollmentCohortIds: Array<Maybe<Scalars['ID']>>;
};

export type PublicMutationAddEnrollmentsToEnrollmentCohortArgs = {
  id: Scalars['ID'];
  enrollments: Array<Maybe<EnrollmentInfo>>;
};

export type PublicMutationInviteLearnersToAssessmentArgs = {
  assessmentCohortId?: Maybe<Scalars['ID']>;
  assessmentCohortKey?: Maybe<Scalars['String']>;
  exclusionFilters?: Maybe<Array<Maybe<LearnerBulkSelectionFilter>>>;
  learnerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  selectionFilters?: Maybe<Array<Maybe<LearnerBulkSelectionFilter>>>;
};

export type PublicMutationCreateGraphLearningPathArgs = {
  allNodes: Array<LearningPathNodeInput>;
  description: Scalars['String'];
  edges?: Maybe<Array<Maybe<LearningPathEdgeInput>>>;
  name: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  statusEnum?: Maybe<GraphLearningPathStatus>;
  tagIds?: Maybe<Array<Scalars['ID']>>;
};

export type PublicMutationUpdateGraphLearningPathArgs = {
  allNodes?: Maybe<Array<LearningPathNodeInput>>;
  description?: Maybe<Scalars['String']>;
  edges?: Maybe<Array<Maybe<LearningPathEdgeInput>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusEnum?: Maybe<GraphLearningPathStatus>;
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type PublicMutationCreateCompanyGraphLearningPathArgs = {
  assessmentCohortKey?: Maybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  learningPathId: Scalars['ID'];
  questionSetId?: Maybe<Scalars['ID']>;
};

export type PublicMutationUpdateCompanyGraphLearningPathArgs = {
  assessmentCohortKey?: Maybe<Scalars['ID']>;
  companyGraphLearningPathId: Scalars['ID'];
  isAvailable: Scalars['Boolean'];
  questionSetId?: Maybe<Scalars['ID']>;
  removeAssessmentCohort?: Maybe<Scalars['Boolean']>;
  removeQuestionSet?: Maybe<Scalars['Boolean']>;
};

export type PublicMutationDeleteCompanyGraphLearningPathArgs = {
  companyGraphLearningPathId: Scalars['ID'];
};

export type PublicMutationHandleLearnerGlpAssociationsArgs = {
  companyGraphLearningPathId: Scalars['ID'];
  learnerIdsToAdd?: Maybe<Array<Maybe<Scalars['ID']>>>;
  learnerIdsToRemove?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type PublicMutationHandleEnrollmentCohortGlpAssociationsArgs = {
  companyGraphLearningPathId: Scalars['ID'];
  enrollmentCohortIdsToAdd?: Maybe<Array<Maybe<Scalars['ID']>>>;
  enrollmentCohortIdsToRemove?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type PublicMutationCreateGraphLearningPathCollectionArgs = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type PublicMutationUpdateGraphLearningPathCollectionArgs = {
  description?: Maybe<Scalars['String']>;
  graphLearningPathCollectionId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type PublicMutationDeleteGraphLearningPathCollectionArgs = {
  graphLearningPathCollectionId: Scalars['ID'];
};

export type PublicMutationHandleGlpCollectionItemsArgs = {
  learningPathCollectionId: Scalars['ID'];
  learningPathIdsToAdd?: Maybe<Array<Scalars['ID']>>;
  learningPathIdsToRemove?: Maybe<Array<Scalars['ID']>>;
};

export type PublicMutationSendFakeProjectCompletionToCsodArgs = {
  learnerId: Scalars['ID'];
  ndKey: Scalars['String'];
  rubricId: Scalars['Int'];
};

export type PublicMutationTriggerAutoEnrollArgs = {
  learnerInterestId: Scalars['Int'];
};

export type PublicMutationProjectCompletionDownCheckArgs = {
  targetDatetime?: Maybe<Scalars['ZuluDateTime']>;
};

export type PublicMutationCreateQuestionSetArgs = {
  companyId: Scalars['ID'];
  questionSet: QuestionSetInput;
};

export type PublicMutationUpdateQuestionSetArgs = {
  confirmationMessage?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  inactiveStatusMessage?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  questions?: Maybe<Array<AdmissionQuestionInput>>;
  rejectionMessage?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
};

export type PublicMutationDeleteQuestionSetArgs = {
  id: Scalars['ID'];
};

export type PublicMutationCreateSelfServeTokenArgs = {
  companyId: Scalars['ID'];
};

export type PublicMutationDeleteSelfServeTokenArgs = {
  tokenId: Scalars['ID'];
};

export type PublicMutationAnswerAdmissionQuestionsArgs = {
  learnerInterestId: Scalars['String'];
  responses?: Maybe<Array<AdmissionQuestionResponseInput>>;
};

export type PublicMutationUpdateProgramArgs = {
  assessmentCohortKey?: Maybe<Scalars['ID']>;
  autoEnrollSetting?: Maybe<AutoEnrollSettingInput>;
  companyId: Scalars['ID'];
  durationOverride?: Maybe<Scalars['Int']>;
  isChallengeProgram?: Maybe<Scalars['Boolean']>;
  programKey: Scalars['String'];
  programType?: Maybe<ResourceType>;
  questionSetId?: Maybe<Scalars['ID']>;
  removeAssessmentCohort?: Maybe<Scalars['Boolean']>;
  removeDurationOverride?: Maybe<Scalars['Boolean']>;
};

export type PublicMutationTurnOnCompanyPartnerIntegrationArgs = {
  apiUrl: Scalars['String'];
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  companyId: Scalars['ID'];
  learningPartnerName: Scalars['String'];
  tokenUrl: Scalars['String'];
};

export type PublicMutationRecomputePartnerProgramsForCompanyArgs = {
  companyId: Scalars['ID'];
};

export type PublicMutationResetAllPartnerLearnerCompletionsArgs = {
  companyId?: Maybe<Scalars['Int']>;
  partnerName: Scalars['String'];
};

export type PublicMutationMarkRecentPartnerLearnerCompletionsArgs = {
  companyId?: Maybe<Scalars['Int']>;
  partnerName: Scalars['String'];
  timeWindowSeconds: Scalars['Int'];
};

export type PublicMutationRegenerateCatalogTokenArgs = {
  companyId: Scalars['ID'];
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export type PublicMutationInvalidateCatalogTokensArgs = {
  companyId: Scalars['ID'];
};

export type PublicMutationAddTalentPlanLinkArgs = {
  companyId: Scalars['ID'];
  link: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type PublicMutationDeleteTalentPlanArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
};

export type PublicMutationUpdateTalentPlanArgs = {
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type PublicMutationSendAllLearnerProgramCompletionsArgs = {
  completedFromUtcDate?: Maybe<Scalars['DateTime']>;
  completedToUtcDate?: Maybe<Scalars['DateTime']>;
  programTypes?: Maybe<Array<Maybe<ResourceType>>>;
};

export type PublicMutationSendSpecificLearnerProgramCompletionsArgs = {
  programCompletionIds: Array<Maybe<Scalars['ID']>>;
};

export type PublicMutationSendAllCompanyMonthlyDigestsArgs = {
  yearMonthDate?: Maybe<Scalars['DateTime']>;
};

export type PublicMutationRecomputeCompanyOutdatedAssessmentResultsArgs = {
  companyId: Scalars['ID'];
};

export type PublicMutationCreateCertificationAssessmentArgs = {
  assessmentCloseDate: Scalars['DateTime'];
  assessmentDuration?: Maybe<Scalars['Int']>;
  attempts?: Maybe<Scalars['Int']>;
  companyId: Scalars['ID'];
  maxDuration: Scalars['Int'];
  minDuration?: Maybe<Scalars['Int']>;
  omacAssessmentGroupId: Scalars['String'];
  programKey: Scalars['String'];
  programType: ResourceType;
  required?: Maybe<Scalars['Boolean']>;
};

export type PublicMutationUpdateCertificationAssessmentArgs = {
  assessmentCloseDate?: Maybe<Scalars['DateTime']>;
  assessmentDuration?: Maybe<Scalars['Int']>;
  attempts?: Maybe<Scalars['Int']>;
  companyId: Scalars['ID'];
  maxDuration?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['Int']>;
  omacAssessmentGroupId?: Maybe<Scalars['String']>;
  programKey: Scalars['String'];
  programType: ResourceType;
  required?: Maybe<Scalars['Boolean']>;
};

export type PublicMutationDeleteCertificationAssessmentArgs = {
  companyId: Scalars['ID'];
  programKey: Scalars['String'];
  programType: ResourceType;
};

export type PublicMutationCreateDiscourseConfigurationArgs = {
  companyIds?: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  validProgramKeys?: Maybe<Array<Scalars['String']>>;
};

export type PublicMutationUpdateDiscourseConfigurationArgs = {
  companyIds?: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  validProgramKeys?: Maybe<Array<Scalars['String']>>;
};

export type PublicMutationDeleteDiscourseConfigurationArgs = {
  name: Scalars['String'];
};

export type PublicMutationCreateAuthTokenArgs = {
  companyId: Scalars['ID'];
  name: Scalars['String'];
};

export type PublicMutationUpdateAuthTokenArgs = {
  companyId: Scalars['ID'];
  isValid: Scalars['Boolean'];
  tokenId: Scalars['ID'];
};

export type PublicMutationAddLearnersToContractArgs = {
  companyId?: Maybe<Scalars['ID']>;
  contractId: Scalars['ID'];
  selectionFilters: Array<Maybe<LearnerBulkSelectionFilter>>;
};

export type PublicMutationRemoveLearnersFromContractArgs = {
  companyId?: Maybe<Scalars['ID']>;
  contractId: Scalars['ID'];
  selectionFilters: Array<Maybe<LearnerBulkSelectionFilter>>;
};

export type PublicMutationInviteTeamMembersArgs = {
  companyId: Scalars['ID'];
  learnerEmails: Array<Scalars['String']>;
};

export type PublicMutationDeleteTeamInvitationArgs = {
  companyId: Scalars['ID'];
  learnerEmail: Scalars['String'];
};

export type PublicMutationProcessTeamInvitationArgs = {
  userKey: Scalars['String'];
};

export type PublicQuery = {
  __typename?: 'PublicQuery';
  invitation?: Maybe<LearnerInvitation>;
  roles?: Maybe<Array<Maybe<Role>>>;
  allDiscourseConfigurations?: Maybe<Array<DiscourseConfiguration>>;
  discourseConfiguration?: Maybe<DiscourseConfiguration>;
  catalogAccessStatus?: Maybe<CatalogAccessStatus>;
  learnerInterestCsvAuth?: Maybe<LearnerInterestCsvAuth>;
  denormalizedLearnerInterest?: Maybe<DenormalizedLearnerInterest>;
  tableauTicket?: Maybe<Scalars['String']>;
  subscriptionRedirect?: Maybe<SubscriptionRedirect>;
  progressSummary?: Maybe<ProgressSummary>;
  enrollmentHistoryProperties?: Maybe<
    Array<Maybe<EnrollmentHistoryProperties>>
  >;
  questionSets?: Maybe<QuestionSetPaginatedConnection>;
  questionSet?: Maybe<QuestionSet>;
  csodHistory?: Maybe<Array<Maybe<CsodProjectCompletionHistory>>>;
  graphLearningPaths?: Maybe<GraphLearningPathPaginatedConnection>;
  graphLearningPath?: Maybe<GraphLearningPath>;
  graphLearningPathCollections?: Maybe<GraphLearningPathCollectionPaginatedConnection>;
  companyGraphLearningPath?: Maybe<CompanyGraphLearningPath>;
  enrollmentCohorts?: Maybe<EnrollmentCohortPaginatedConnection>;
  enrollmentCohort?: Maybe<EnrollmentCohort>;
  transferableLearnersEnrollments?: Maybe<LearnersEnrollmentsType>;
  autoEnrollmentEligibility?: Maybe<AutoEnrollmentEligibility>;
  ping?: Maybe<Scalars['Boolean']>;
  echo?: Maybe<Scalars['String']>;
  authError?: Maybe<Scalars['String']>;
  acceptedTermsOfUseVersions?: Maybe<Array<Maybe<TermsOfUseVersion>>>;
  termsOfUseVersionsToAccept?: Maybe<Array<Maybe<TermsOfUseVersion>>>;
  /** @deprecated Global TermsOfUseVersion's no longer supported */
  globalTermsOfUseVersions?: Maybe<TermsOfUseVersionPaginatedConnection>;
  resource?: Maybe<Resource>;
  learningPaths?: Maybe<PaginatedLearningPathPaginatedConnection>;
  learnerUploads?: Maybe<Array<LearnerUploadHistory>>;
  learnerLifecycles?: Maybe<LearnerLifecyclePaginatedConnection>;
  learnerLifecycleBatches?: Maybe<LearnerLifecycleBatchPaginatedConnection>;
  learner?: Maybe<Learner>;
  learnersByUserKey?: Maybe<Array<Maybe<Learner>>>;
  learnerByEmail?: Maybe<Learner>;
  learnerCount?: Maybe<Scalars['Int']>;
  learnerIdsByFilter?: Maybe<Array<Maybe<Scalars['ID']>>>;
  group?: Maybe<Group>;
  contractTerm?: Maybe<ContractTerm>;
  allCompanyTypes?: Maybe<Array<CompanyType>>;
  company?: Maybe<Company>;
  companyBySlug?: Maybe<Company>;
  contract?: Maybe<Contract>;
  contractBySubscriptionId?: Maybe<Contract>;
  allCatalogPrograms?: Maybe<Array<CatalogProgram>>;
  catalogProgram?: Maybe<CatalogProgram>;
  myUserRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  allAssessmentWhitelistedPrograms?: Maybe<
    Array<Maybe<AssessmentWhitelistedProgram>>
  >;
  assessment?: Maybe<Assessment>;
  assessmentCohort?: Maybe<AssessmentCohort>;
  assessmentLabSession?: Maybe<AssessmentLabSession>;
  allCompanies?: Maybe<Array<Company>>;
  allCompaniesPaginated?: Maybe<CompanyPaginatedConnection>;
  allContracts?: Maybe<PaginatedContractPaginatedConnection>;
  allLearners?: Maybe<LearnerPaginatedConnection>;
  allResources?: Maybe<Array<Maybe<Resource>>>;
  allContractTerms?: Maybe<PaginatedContractTermPaginatedConnection>;
  allLearnerInterests?: Maybe<PaginatedLearnerInterestPaginatedConnection>;
  allCatalogTags?: Maybe<PaginatedCatalogTagPaginatedConnection>;
  companyType?: Maybe<CompanyType>;
  learnerInterests?: Maybe<Array<Maybe<LearnerInterest>>>;
  allSettings?: Maybe<Array<Maybe<Setting>>>;
  allCompanySettings?: Maybe<Array<Maybe<CompanySetting>>>;
  companyAnnotatedSsoLink?: Maybe<Scalars['String']>;
};

export type PublicQueryInvitationArgs = {
  userKey: Scalars['String'];
};

export type PublicQueryDiscourseConfigurationArgs = {
  name: Scalars['String'];
};

export type PublicQueryLearnerInterestCsvAuthArgs = {
  userJwt: Scalars['String'];
  s3Filename: Scalars['String'];
};

export type PublicQueryDenormalizedLearnerInterestArgs = {
  learnerInterestId: Scalars['ID'];
};

export type PublicQueryTableauTicketArgs = {
  companyId: Scalars['ID'];
};

export type PublicQuerySubscriptionRedirectArgs = {
  ref: Scalars['String'];
};

export type PublicQueryProgressSummaryArgs = {
  batchId: Scalars['String'];
};

export type PublicQueryEnrollmentHistoryPropertiesArgs = {
  enrollmentId?: Maybe<Scalars['String']>;
};

export type PublicQueryQuestionSetsArgs = {
  companyId?: Maybe<Scalars['ID']>;
  status?: Maybe<Status>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<QuestionSetSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PublicQueryQuestionSetArgs = {
  id: Scalars['ID'];
};

export type PublicQueryCsodHistoryArgs = {
  learnerId?: Maybe<Scalars['ID']>;
  loid?: Maybe<Scalars['String']>;
};

export type PublicQueryGraphLearningPathsArgs = {
  companyId?: Maybe<Scalars['ID']>;
  learningPathCollectionId?: Maybe<Scalars['ID']>;
  programKey?: Maybe<Scalars['String']>;
  programType?: Maybe<LearningPathNodeType>;
  status?: Maybe<Array<GraphLearningPathStatus>>;
  search?: Maybe<Scalars['String']>;
  tagId?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<GraphLearningPathSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PublicQueryGraphLearningPathArgs = {
  id: Scalars['ID'];
};

export type PublicQueryGraphLearningPathCollectionsArgs = {
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<GraphLearningPathCollectionSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PublicQueryCompanyGraphLearningPathArgs = {
  id: Scalars['ID'];
};

export type PublicQueryEnrollmentCohortsArgs = {
  companyId?: Maybe<Scalars['ID']>;
  state?: Maybe<EnrollmentCohortState>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<EnrollmentCohortSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PublicQueryEnrollmentCohortArgs = {
  id: Scalars['ID'];
};

export type PublicQueryTransferableLearnersEnrollmentsArgs = {
  action?: Maybe<TransferLearnersAction>;
  destinationContractId?: Maybe<Scalars['ID']>;
  sourceContractId?: Maybe<Scalars['ID']>;
};

export type PublicQueryAutoEnrollmentEligibilityArgs = {
  learnerInterestId: Scalars['ID'];
};

export type PublicQueryEchoArgs = {
  s: Scalars['String'];
};

export type PublicQueryAcceptedTermsOfUseVersionsArgs = {
  userKey?: Maybe<Scalars['String']>;
};

export type PublicQueryTermsOfUseVersionsToAcceptArgs = {
  userKey: Scalars['String'];
};

export type PublicQueryGlobalTermsOfUseVersionsArgs = {
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<TermsOfUseVersionSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PublicQueryResourceArgs = {
  id: Scalars['ID'];
};

export type PublicQueryLearningPathsArgs = {
  status?: Maybe<Status>;
  companyId?: Maybe<Scalars['ID']>;
  learnerId?: Maybe<Scalars['ID']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<PaginatedLearningPathSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PublicQueryLearnerUploadsArgs = {
  companyId: Scalars['ID'];
};

export type PublicQueryLearnerLifecyclesArgs = {
  companyId: Scalars['ID'];
  batchId?: Maybe<Scalars['String']>;
  contractTermId?: Maybe<Scalars['ID']>;
  lifecycleFlags?: Maybe<Array<Maybe<LifecycleFlag>>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerLifecycleSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PublicQueryLearnerLifecycleBatchesArgs = {
  companyId: Scalars['ID'];
  batchId?: Maybe<Scalars['String']>;
  contractTermId?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerLifecycleBatchSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PublicQueryLearnerArgs = {
  id: Scalars['ID'];
};

export type PublicQueryLearnersByUserKeyArgs = {
  userKey: Scalars['String'];
  companyId?: Maybe<Scalars['ID']>;
  companySlug?: Maybe<Scalars['String']>;
};

export type PublicQueryLearnerByEmailArgs = {
  email: Scalars['String'];
  companyId?: Maybe<Scalars['ID']>;
};

export type PublicQueryLearnerCountArgs = {
  companyId: Scalars['ID'];
  selectionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
};

export type PublicQueryLearnerIdsByFilterArgs = {
  companyId: Scalars['ID'];
  selectionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
};

export type PublicQueryGroupArgs = {
  id: Scalars['ID'];
};

export type PublicQueryContractTermArgs = {
  id: Scalars['ID'];
};

export type PublicQueryCompanyArgs = {
  id: Scalars['ID'];
};

export type PublicQueryCompanyBySlugArgs = {
  slug: Scalars['String'];
};

export type PublicQueryContractArgs = {
  id: Scalars['ID'];
};

export type PublicQueryContractBySubscriptionIdArgs = {
  subscriptionId: Scalars['String'];
};

export type PublicQueryAllCatalogProgramsArgs = {
  programType?: Maybe<ResourceType>;
  isStandard?: Maybe<Scalars['Boolean']>;
};

export type PublicQueryCatalogProgramArgs = {
  programKey: Scalars['String'];
  programType: ResourceType;
};

export type PublicQueryAssessmentArgs = {
  id: Scalars['ID'];
};

export type PublicQueryAssessmentCohortArgs = {
  cohortKey: Scalars['String'];
};

export type PublicQueryAssessmentLabSessionArgs = {
  id: Scalars['ID'];
};

export type PublicQueryAllCompaniesArgs = {
  status?: Maybe<Status>;
  country?: Maybe<Scalars['String']>;
  companyTypeId?: Maybe<Scalars['ID']>;
  isParent?: Maybe<Scalars['Boolean']>;
};

export type PublicQueryAllCompaniesPaginatedArgs = {
  status?: Maybe<Status>;
  country?: Maybe<Scalars['String']>;
  companyTypeId?: Maybe<Scalars['ID']>;
  isParent?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<CompanySortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PublicQueryAllContractsArgs = {
  contractType?: Maybe<ContractType>;
  status?: Maybe<Status>;
  companyId?: Maybe<Scalars['ID']>;
  companyTypeId?: Maybe<Scalars['ID']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  startsBefore?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<PaginatedContractSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PublicQueryAllLearnersArgs = {
  groupId?: Maybe<Scalars['ID']>;
  learningPathId?: Maybe<Scalars['ID']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  selectionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerBulkSelectionFilter>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<LearnerSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PublicQueryAllContractTermsArgs = {
  programType?: Maybe<ResourceType>;
  status?: Maybe<Status>;
  companyId?: Maybe<Scalars['ID']>;
  companyTypeId?: Maybe<Scalars['ID']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  startsBefore?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<PaginatedContractTermSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PublicQueryAllLearnerInterestsArgs = {
  programKey?: Maybe<Scalars['String']>;
  companyGraphLearningPathId?: Maybe<Scalars['String']>;
  programType?: Maybe<LearnerInterestProgramType>;
  programTypes?: Maybe<Array<LearnerInterestProgramType>>;
  selectionFilters?: Maybe<Array<LearnerInterestBulkSelectionFilter>>;
  exclusionFilters?: Maybe<Array<LearnerInterestBulkSelectionFilter>>;
  companyId?: Maybe<Scalars['ID']>;
  learnerId?: Maybe<Scalars['ID']>;
  status?: Maybe<LearnerInterestStatus>;
  questionsAnswered?: Maybe<Array<QuestionResponses>>;
  nominationTypes?: Maybe<Array<NominationType>>;
  contracted?: Maybe<Scalars['Boolean']>;
  cohortIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<PaginatedLearnerInterestSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PublicQueryAllCatalogTagsArgs = {
  category?: Maybe<CatalogTagCategory>;
  search?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<PaginatedCatalogTagSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PublicQueryCompanyTypeArgs = {
  id: Scalars['ID'];
};

export type PublicQueryLearnerInterestsArgs = {
  companyId?: Maybe<Scalars['ID']>;
  learnerId?: Maybe<Scalars['ID']>;
};

export type PublicQueryAllSettingsArgs = {
  name?: Maybe<Scalars['String']>;
};

export type PublicQueryAllCompanySettingsArgs = {
  companyId?: Maybe<Scalars['ID']>;
  settingId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PublicQueryCompanyAnnotatedSsoLinkArgs = {
  companySlug: Scalars['String'];
  next: Scalars['String'];
  idpId?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type QuestionResponses = {
  questionId: Scalars['String'];
  responses: Array<Scalars['String']>;
};

export type QuestionSet = {
  __typename?: 'QuestionSet';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  status?: Maybe<Scalars['String']>;
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  companyId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  confirmationMessage?: Maybe<Scalars['String']>;
  inactiveStatusMessage?: Maybe<Scalars['String']>;
  rejectionMessage?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  admissionQuestions?: Maybe<Array<Maybe<AdmissionQuestion>>>;
  learningPaths?: Maybe<CompanyGraphLearningPathConnection>;
  companyPrograms?: Maybe<Array<Maybe<CompanyProgram>>>;
};

export type QuestionSetLearningPathsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QuestionSetInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  confirmationMessage?: Maybe<Scalars['String']>;
  inactiveStatusMessage?: Maybe<Scalars['String']>;
  rejectionMessage?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  questions?: Maybe<Array<AdmissionQuestionInput>>;
};

export type QuestionSetPaginatedConnection = TotalCount & {
  __typename?: 'QuestionSetPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<QuestionSetPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `QuestionSetPaginated` and its cursor. */
export type QuestionSetPaginatedEdge = {
  __typename?: 'QuestionSetPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<QuestionSet>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum QuestionSetSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  ConfirmationMessageAsc = 'CONFIRMATION_MESSAGE_ASC',
  ConfirmationMessageDesc = 'CONFIRMATION_MESSAGE_DESC',
  InactiveStatusMessageAsc = 'INACTIVE_STATUS_MESSAGE_ASC',
  InactiveStatusMessageDesc = 'INACTIVE_STATUS_MESSAGE_DESC',
  RejectionMessageAsc = 'REJECTION_MESSAGE_ASC',
  RejectionMessageDesc = 'REJECTION_MESSAGE_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
}

export type RecomputeAllOutdatedAssessmentResults = {
  __typename?: 'RecomputeAllOutdatedAssessmentResults';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RecomputeCompanyDenormalizedLearnerInterests = {
  __typename?: 'RecomputeCompanyDenormalizedLearnerInterests';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RecomputeCompanyOutdatedAssessmentResults = {
  __typename?: 'RecomputeCompanyOutdatedAssessmentResults';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RecomputeCompanyRealTimeReports = {
  __typename?: 'RecomputeCompanyRealTimeReports';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RecomputeDenormalizedLearnerInterests = {
  __typename?: 'RecomputeDenormalizedLearnerInterests';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RecomputeLearnerRealTimeReports = {
  __typename?: 'RecomputeLearnerRealTimeReports';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RecomputeOnTrackStatusForActiveEnrollments = {
  __typename?: 'RecomputeOnTrackStatusForActiveEnrollments';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RecomputeOnTrackStatusForCompany = {
  __typename?: 'RecomputeOnTrackStatusForCompany';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RecomputePartnerPrograms = {
  __typename?: 'RecomputePartnerPrograms';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RecomputePartnerProgramsForCompany = {
  __typename?: 'RecomputePartnerProgramsForCompany';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RecomputeRealTimeReportsPartition = {
  __typename?: 'RecomputeRealTimeReportsPartition';
  ok?: Maybe<Scalars['Boolean']>;
  partitionIndex?: Maybe<Scalars['Int']>;
  partitionTotal?: Maybe<Scalars['Int']>;
  allPartitionsConsumed?: Maybe<Scalars['Boolean']>;
};

export type RecomputeUserRealTimeReports = {
  __typename?: 'RecomputeUserRealTimeReports';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RegenerateCatalogToken = {
  __typename?: 'RegenerateCatalogToken';
  ok?: Maybe<Scalars['Boolean']>;
  catalogToken?: Maybe<CompanyCatalogToken>;
};

export type RegistrarEnrollment = {
  __typename?: 'RegistrarEnrollment';
  id: Scalars['String'];
  userKey: Scalars['String'];
  nodeKey: Scalars['String'];
  serviceModelId?: Maybe<Scalars['String']>;
  contentVersion?: Maybe<Scalars['String']>;
  contentLocale?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['String']>;
  state?: Maybe<EnrollmentStatusEnum>;
  stateChangedAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  claims?: Maybe<Array<RegistrarEnrollmentClaim>>;
};

export type RegistrarEnrollmentClaim = {
  __typename?: 'RegistrarEnrollmentClaim';
  id: Scalars['String'];
  refKey: Scalars['String'];
  state: RosterEnrollmentClaimStatus;
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
};

export type RemoveInterestInProgram = {
  __typename?: 'RemoveInterestInProgram';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RemoveLearnersFromContract = {
  __typename?: 'RemoveLearnersFromContract';
  ok?: Maybe<Scalars['Boolean']>;
  batchId?: Maybe<Scalars['String']>;
};

export type RemoveLearnersFromGroup = {
  __typename?: 'RemoveLearnersFromGroup';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RemoveLearnersFromLearningPath = {
  __typename?: 'RemoveLearnersFromLearningPath';
  ok?: Maybe<Scalars['Boolean']>;
};

export type RemoveManagerRoles = {
  __typename?: 'RemoveManagerRoles';
  learner?: Maybe<Learner>;
};

export type RemoveRoleFromLearner = {
  __typename?: 'RemoveRoleFromLearner';
  learner?: Maybe<Learner>;
};

export type ReportsPaginatedSubjectsAttained = {
  __typename?: 'ReportsPaginatedSubjectsAttained';
  data?: Maybe<Array<Maybe<AggregateSubjectCount>>>;
  resultsetTotal?: Maybe<Scalars['Int']>;
};

export type ReportsSubjectMastery = {
  __typename?: 'ReportsSubjectMastery';
  data?: Maybe<Array<Maybe<SubjectMastery>>>;
  resultsetTotal?: Maybe<Scalars['Int']>;
};

export type ResetAllPartnerLearnerCompletions = {
  __typename?: 'ResetAllPartnerLearnerCompletions';
  ok: Scalars['Boolean'];
  partnerName: Scalars['String'];
  batchId: Scalars['String'];
  progressSummaryLink: Scalars['String'];
};

export type Resource = {
  __typename?: 'Resource';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  id: Scalars['ID'];
  programMajor?: Maybe<Scalars['Int']>;
  /** @deprecated Use program_type instead */
  datatype: Scalars['String'];
  /** @deprecated Use program_key instead */
  data: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use program_major instead */
  version?: Maybe<Scalars['String']>;
  durationOverride?: Maybe<Scalars['Int']>;
  companyId: Scalars['ID'];
  contractTerms?: Maybe<Array<Maybe<ContractTerm>>>;
  programUrn: ProgramUrn;
  catalogProgram?: Maybe<CatalogProgram>;
  resourceData?: Maybe<ResourceData>;
  cohorts?: Maybe<Array<Cohort>>;
  /** @deprecated Please use resource.companyProgram.assessmentCohortId instead, this is not efficient */
  assessmentCohortId?: Maybe<Scalars['ID']>;
  /** @deprecated Please use resource.companyProgram.assessmentCohort instead, this is not efficient */
  assessmentCohort?: Maybe<AssessmentCohort>;
  autoEnrollSetting?: Maybe<CompanySettingAutoEnroll>;
};

export type ResourceCohortsArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type ResourceData = {
  __typename?: 'ResourceData';
  /** The expected duration to complete this program (content and projects combined). */
  defaultDurationInDays?: Maybe<Scalars['Int']>;
  /** The combined duration for all lessons and projects in this program. */
  contentDurationInMinutes?: Maybe<Scalars['Int']>;
  numRequiredProjects?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  semanticType?: Maybe<Scalars['String']>;
  heroImageUrl?: Maybe<Scalars['String']>;
};

export type ResourcePaginatedConnection = TotalCount & {
  __typename?: 'ResourcePaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ResourcePaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ResourcePaginated` and its cursor. */
export type ResourcePaginatedEdge = {
  __typename?: 'ResourcePaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<Resource>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum ResourceSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProgramMajorAsc = 'PROGRAM_MAJOR_ASC',
  ProgramMajorDesc = 'PROGRAM_MAJOR_DESC',
  DatatypeAsc = 'DATATYPE_ASC',
  DatatypeDesc = 'DATATYPE_DESC',
  DataAsc = 'DATA_ASC',
  DataDesc = 'DATA_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC',
  DurationOverrideAsc = 'DURATION_OVERRIDE_ASC',
  DurationOverrideDesc = 'DURATION_OVERRIDE_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
}

export enum ResourceType {
  Degree = 'DEGREE',
  Part = 'PART',
  Course = 'COURSE',
}

export type RetryLearnerLifecycles = {
  __typename?: 'RetryLearnerLifecycles';
  ok?: Maybe<Scalars['Boolean']>;
  enrollmentBatchId?: Maybe<Scalars['String']>;
  unenrollmentBatchId?: Maybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
  label: Scalars['String'];
  learners?: Maybe<LearnerConnection>;
};

export type RoleLearnersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type Roster = {
  __typename?: 'Roster';
  learnerId?: Maybe<Scalars['ID']>;
  userKey?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  contractTermId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['ID']>;
  enrollmentId?: Maybe<Scalars['String']>;
  enrollmentStatus?: Maybe<EnrollmentStatusEnum>;
  learnerActivity?: Maybe<LearnerActivity>;
  programInfo?: Maybe<ProgramInfo>;
  activeCohortRefKeys?: Maybe<Array<Scalars['String']>>;
  pendingStuCount?: Maybe<Scalars['Int']>;
  consumedStuCount?: Maybe<Scalars['Int']>;
};

export enum RosterEnrollmentClaimStatus {
  ClaimStateUnknown = 'CLAIM_STATE_UNKNOWN',
  Valid = 'VALID',
  Revoked = 'REVOKED',
  Inactive = 'INACTIVE',
  Expired = 'EXPIRED',
  Transferred = 'TRANSFERRED',
  Completed = 'COMPLETED',
  Enrolled = 'ENROLLED',
  Dropped = 'DROPPED',
}

export type RosterFilters = {
  /** Only include roster items with on_track_status = on_track_status */
  onTrackStatus?: Maybe<Scalars['String']>;
  enrollmentState?: Maybe<EnrollmentStatusEnum>;
  /** Only include roster items with project submissions */
  withProjectsSubmitted?: Maybe<Scalars['Boolean']>;
  /** Only include roster items with passed projects */
  withProjectsPassed?: Maybe<Scalars['Boolean']>;
  /** Only include roster items with matching program_key */
  programKey?: Maybe<Scalars['String']>;
  /** Only include learners who made progress between deltas_start_time and now */
  deltasStartTime?: Maybe<Scalars['ZuluDateTime']>;
  /** Only include learners who were last seen between last_seen_after and now */
  lastSeenAfter?: Maybe<Scalars['ZuluDateTime']>;
  /** Only include learners who were last seen before last_seen_before */
  lastSeenBefore?: Maybe<Scalars['ZuluDateTime']>;
  /** Lower bound on projects passed %, a floating point number between 0.0 and 1.0 */
  projectsPassedLower?: Maybe<Scalars['Float']>;
  /** Upper bound on projects passed %, a floating point number between 0.0 and 1.0 */
  projectsPassedUpper?: Maybe<Scalars['Float']>;
  /** Only include graduated roster items between graduated_at_start and now */
  graduatedAtStart?: Maybe<Scalars['ZuluDateTime']>;
  /** Only include roster items between enrollment_start and now */
  enrollmentStart?: Maybe<Scalars['ZuluDateTime']>;
  /** Only include roster items whose enrollment state changed between enrollment_changed_start and now */
  enrollmentChangedStart?: Maybe<Scalars['ZuluDateTime']>;
  /** Only include roster items where the learner has an active cohort claim for the provided cohort_ref_key */
  activeCohortRefKey?: Maybe<Scalars['String']>;
};

export type SelfReportedSubjectInput = {
  subjectId: Scalars['String'];
  subjectName: Scalars['String'];
  selfReportedMastery: Scalars['Float'];
};

export type SelfReportedSubjects = {
  __typename?: 'SelfReportedSubjects';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  subjectId: Scalars['String'];
  subjectName: Scalars['String'];
  selfReportedMastery: Scalars['Float'];
  learnerId: Scalars['Int'];
  assessmentLabSessionId: Scalars['Int'];
};

export type SelfServeToken = {
  __typename?: 'SelfServeToken';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  companyId: Scalars['Int'];
  company?: Maybe<Company>;
};

export type SendAllCompanyMonthlyDigests = {
  __typename?: 'SendAllCompanyMonthlyDigests';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SendAllLearnerProgramCompletions = {
  __typename?: 'SendAllLearnerProgramCompletions';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SendFakeProjectCompletionToCsod = {
  __typename?: 'SendFakeProjectCompletionToCsod';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SendSpecificLearnerProgramCompletions = {
  __typename?: 'SendSpecificLearnerProgramCompletions';
  ok?: Maybe<Scalars['Boolean']>;
};

export type Setting = {
  __typename?: 'Setting';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  defaultValue: Scalars['String'];
  valueType: Scalars['String'];
  createdBy: Scalars['String'];
  updatedBy: Scalars['String'];
  companySettings?: Maybe<Array<Maybe<CompanySetting>>>;
};

export enum Status {
  Draft = 'DRAFT',
  Approved = 'APPROVED',
  Suspended = 'SUSPENDED',
}

export type SubjectMastery = {
  __typename?: 'SubjectMastery';
  subjectId?: Maybe<Scalars['String']>;
  subjectName?: Maybe<Scalars['String']>;
  learnerId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  enrollmentCohortIds?: Maybe<Array<Scalars['String']>>;
  tagIds?: Maybe<Array<Scalars['String']>>;
  lastSkillAttainedDate?: Maybe<Scalars['DateTime']>;
};

export type SubjectScore = {
  __typename?: 'SubjectScore';
  /** The fraction of questions on this subject the learner got correct across the entire assessment. Contains values from 0 to 1, inclusive. */
  mastery?: Maybe<Scalars['Float']>;
  /** The percentage of correct answers. Contains values from 0 to 100, inclusive. */
  percentage?: Maybe<Scalars['Int']>;
  /** The percentage of correct answers as a string, e.g. '95%' */
  percentageStr?: Maybe<Scalars['String']>;
};

export type Submission = {
  __typename?: 'Submission';
  attemptCount?: Maybe<Scalars['Int']>;
  projectType?: Maybe<ProjectType>;
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  projectOrder?: Maybe<Scalars['Int']>;
  rubricId?: Maybe<Scalars['String']>;
  submissionId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  submissionDate?: Maybe<Scalars['ZuluDateTime']>;
  submissionDeadline?: Maybe<Scalars['ZuluDateTime']>;
  isOptional?: Maybe<Scalars['Boolean']>;
  isCareer?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionRedirect = {
  __typename?: 'SubscriptionRedirect';
  redirect: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

/** Ensure that all Learner's with this user_key have an up-to-date email based on their Udacity user account. */
export type SyncLearnerEmail = {
  __typename?: 'SyncLearnerEmail';
  dryRun: Scalars['Boolean'];
  results: Array<Maybe<SyncLearnerEmailResult>>;
};

export type SyncLearnerEmailResult = {
  __typename?: 'SyncLearnerEmailResult';
  companyId: Scalars['ID'];
  updatedBy: Scalars['String'];
  learnerUserKey: Scalars['String'];
  learnerId: Scalars['Int'];
  previousEmail: Scalars['String'];
  updatedEmail: Scalars['String'];
};

export type SynchronizeAssessmentWhitelistedPrograms = {
  __typename?: 'SynchronizeAssessmentWhitelistedPrograms';
  outOfDatePrograms?: Maybe<Array<OutOfDateProgram>>;
};

export type TalentPlan = {
  __typename?: 'TalentPlan';
  createDate: Scalars['DateTime'];
  updatedBy: Scalars['String'];
  updateDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  companyId: Scalars['ID'];
  name: Scalars['String'];
  createdBy: Scalars['String'];
  updatedByUser: User;
  createdByUser: User;
  resource?: Maybe<TalentPlanResource>;
};

export type TalentPlanDocumentResource = {
  __typename?: 'TalentPlanDocumentResource';
  uploadId: Scalars['ID'];
  contentType: Scalars['String'];
  contentLength: Scalars['Int'];
  contentMd5: Scalars['String'];
  link: Scalars['String'];
};

export type TalentPlanPaginatedConnection = TotalCount & {
  __typename?: 'TalentPlanPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TalentPlanPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TalentPlanPaginated` and its cursor. */
export type TalentPlanPaginatedEdge = {
  __typename?: 'TalentPlanPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<TalentPlan>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TalentPlanResource =
  | TalentPlanDocumentResource
  | TalentPlanUrlResource;

export enum TalentPlanResourceType {
  Document = 'DOCUMENT',
  Url = 'URL',
}

export enum TalentPlanSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
}

export type TalentPlanUrlResource = {
  __typename?: 'TalentPlanURLResource';
  link: Scalars['String'];
};

export type TermsOfUseVersion = EmcNode & {
  __typename?: 'TermsOfUseVersion';
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  /** The ID of the object. */
  id: Scalars['ID'];
  versionId: Scalars['String'];
  companyId?: Maybe<Scalars['ID']>;
  /** The text of the ToU. Expected to be arbitrary Markdown. No sanitization whatsoever is done on the server - clients shouldn't trust that it's nice Markdown.  */
  contents: Scalars['String'];
  /** user_key of the Udacity user who created this TermsOfUseVersion */
  createdBy?: Maybe<Scalars['String']>;
};

export type TermsOfUseVersionPaginatedConnection = TotalCount & {
  __typename?: 'TermsOfUseVersionPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TermsOfUseVersionPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TermsOfUseVersionPaginated` and its cursor. */
export type TermsOfUseVersionPaginatedEdge = {
  __typename?: 'TermsOfUseVersionPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<TermsOfUseVersion>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum TermsOfUseVersionSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  UpdateDateAsc = 'UPDATE_DATE_ASC',
  UpdateDateDesc = 'UPDATE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  VersionIdAsc = 'VERSION_ID_ASC',
  VersionIdDesc = 'VERSION_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ContentsAsc = 'CONTENTS_ASC',
  ContentsDesc = 'CONTENTS_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
}

/**
 * The graphene Connection class was written in such a way that you can't easily add new fields.
 * Originally, we copy-pasted the entire __init_subclass_with_meta__() function in order to do so.
 * However, it turns out we can just add the new field on an Interface and add that Interface
 * to the Connection!
 * This doesn't quite make sense, but is much less ugly than the alternative.
 */
export type TotalCount = {
  totalCount?: Maybe<Scalars['Int']>;
};

/** Transfers learners between programs/contract-terms */
export type TransferLearners = {
  __typename?: 'TransferLearners';
  ok?: Maybe<Scalars['Boolean']>;
  batchId?: Maybe<Scalars['String']>;
};

export enum TransferLearnersAction {
  ContractTermToContractTerm = 'CONTRACT_TERM_TO_CONTRACT_TERM',
  ContractTermToUlc = 'CONTRACT_TERM_TO_ULC',
  ContractToUlc = 'CONTRACT_TO_ULC',
  UlcToUlc = 'ULC_TO_ULC',
}

/**
 * Endpoint useful in testing the auto enrollment flow.  Can be triggered on a specific
 * `learner_interest_id`, only available to enterprise engineers.
 */
export type TriggerAutoEnroll = {
  __typename?: 'TriggerAutoEnroll';
  ok?: Maybe<Scalars['Boolean']>;
  batchId?: Maybe<Scalars['String']>;
};

export type TriggerAutoUnenroll = {
  __typename?: 'TriggerAutoUnenroll';
  numUnenrollments?: Maybe<Scalars['Int']>;
};

export type TriggerAutoUnenrollLearnersPastEstimatedEndDate = {
  __typename?: 'TriggerAutoUnenrollLearnersPastEstimatedEndDate';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UdacityCatalogProgram = {
  __typename?: 'UdacityCatalogProgram';
  programKey: Scalars['String'];
  availableForEnrollment: Scalars['Boolean'];
};

export type UnenrollLearners = {
  __typename?: 'UnenrollLearners';
  ok?: Maybe<Scalars['Boolean']>;
  batchId?: Maybe<Scalars['String']>;
};

export enum UnenrollmentReason {
  Revoked = 'REVOKED',
  CohortEnded = 'COHORT_ENDED',
  CohortChanged = 'COHORT_CHANGED',
  Withdrawl = 'WITHDRAWL',
  Plagiarism = 'PLAGIARISM',
  Blocked = 'BLOCKED',
}

export type UnenrollmentTaskArgs = {
  __typename?: 'UnenrollmentTaskArgs';
  learnerLifecycleId: Scalars['ID'];
  learnerId: Scalars['ID'];
  learnerUserKey: Scalars['String'];
  companyId: Scalars['ID'];
  companyType?: Maybe<Scalars['String']>;
  contractId: Scalars['ID'];
  contractTermId: Scalars['ID'];
  claimType: Scalars['String'];
  r2RefKey?: Maybe<Scalars['String']>;
  nodeKey: Scalars['String'];
  contentVersion: Scalars['String'];
  authorizedBy: Scalars['String'];
  cohortIdFromWhichLearnersAreRemoved?: Maybe<Scalars['ID']>;
  unenrollmentReason?: Maybe<Scalars['String']>;
};

export type UpdateAssessment = {
  __typename?: 'UpdateAssessment';
  assessment?: Maybe<Assessment>;
};

export type UpdateAssessmentCohort = {
  __typename?: 'UpdateAssessmentCohort';
  assessmentCohort?: Maybe<AssessmentCohort>;
};

export type UpdateAuthToken = {
  __typename?: 'UpdateAuthToken';
  authToken?: Maybe<AuthToken>;
};

export type UpdateCatalogProgram = {
  __typename?: 'UpdateCatalogProgram';
  catalogProgram?: Maybe<CatalogProgram>;
};

export type UpdateCatalogProgramUrl = {
  __typename?: 'UpdateCatalogProgramUrl';
  catalogProgram?: Maybe<CatalogProgram>;
};

export type UpdateCertificationAssessment = {
  __typename?: 'UpdateCertificationAssessment';
  ok?: Maybe<Scalars['Boolean']>;
};

/** Add or remove Learners from a Contract Term in a given Enrollment Cohort. */
export type UpdateCohortLearnersAssociation = {
  __typename?: 'UpdateCohortLearnersAssociation';
  enrollmentCohort?: Maybe<EnrollmentCohort>;
};

export type UpdateCompany = {
  __typename?: 'UpdateCompany';
  company?: Maybe<Company>;
};

export type UpdateCompanyGraphLearningPath = {
  __typename?: 'UpdateCompanyGraphLearningPath';
  companyGraphLearningPath?: Maybe<CompanyGraphLearningPath>;
};

export type UpdateCompanySetting = {
  __typename?: 'UpdateCompanySetting';
  companySetting?: Maybe<CompanySetting>;
};

export type UpdateContract = {
  __typename?: 'UpdateContract';
  ok?: Maybe<Scalars['Boolean']>;
  contract?: Maybe<Contract>;
  batchId?: Maybe<Scalars['String']>;
};

export type UpdateContractTerms = {
  __typename?: 'UpdateContractTerms';
  ok?: Maybe<Scalars['Boolean']>;
  contractTerms?: Maybe<Array<ContractTerm>>;
};

export type UpdateDiscourseConfiguration = {
  __typename?: 'UpdateDiscourseConfiguration';
  discourseConfiguration?: Maybe<DiscourseConfiguration>;
};

export type UpdateEnrollmentCohort = {
  __typename?: 'UpdateEnrollmentCohort';
  enrollmentCohort?: Maybe<EnrollmentCohort>;
  learnersRemovalBatchId?: Maybe<Scalars['String']>;
};

export type UpdateEnrollmentDates = {
  __typename?: 'UpdateEnrollmentDates';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateEnrollmentHistoryProperties = {
  __typename?: 'UpdateEnrollmentHistoryProperties';
  ok?: Maybe<Scalars['Boolean']>;
  batchId?: Maybe<Scalars['String']>;
};

export type UpdateGraphLearningPath = {
  __typename?: 'UpdateGraphLearningPath';
  graphLearningPath?: Maybe<GraphLearningPath>;
};

export type UpdateGraphLearningPathCollection = {
  __typename?: 'UpdateGraphLearningPathCollection';
  graphLearningPathCollection?: Maybe<GraphLearningPathCollection>;
};

export type UpdateGroup = {
  __typename?: 'UpdateGroup';
  group?: Maybe<Group>;
};

export type UpdateLearningPath = {
  __typename?: 'UpdateLearningPath';
  learningPath?: Maybe<LearningPath>;
};

export type UpdateManagerRole = {
  __typename?: 'UpdateManagerRole';
  learner?: Maybe<Learner>;
};

export type UpdateProgram = {
  __typename?: 'UpdateProgram';
  ok: Scalars['Boolean'];
  program: CompanyProgram;
};

export type UpdateProjectDeadlineInput = {
  id?: Maybe<Scalars['ID']>;
  dueAt?: Maybe<Scalars['String']>;
};

export type UpdateResource = {
  __typename?: 'UpdateResource';
  ok?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<Resource>;
};

export type UpdateSetting = {
  __typename?: 'UpdateSetting';
  setting?: Maybe<Setting>;
};

export type UpdateStaleResourceData = {
  __typename?: 'UpdateStaleResourceData';
  ok: Scalars['Boolean'];
  batchId: Scalars['String'];
};

export type UpdateStuCount = {
  __typename?: 'UpdateStuCount';
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateTalentPlan = {
  __typename?: 'UpdateTalentPlan';
  talentPlan?: Maybe<TalentPlan>;
};

export type User = {
  __typename?: 'User';
  userKey?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  isTest?: Maybe<Scalars['Boolean']>;
  avatarUrl?: Maybe<Scalars['String']>;
  discourseUsername?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type V2AssessmentResult = EmcNode & {
  __typename?: 'V2AssessmentResult';
  createDate: Scalars['DateTime'];
  deleteDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  learnerId: Scalars['ID'];
  sessionId: Scalars['String'];
  outdated: Scalars['Boolean'];
  assessmentLabSession?: Maybe<AssessmentLabSession>;
  learner?: Maybe<Learner>;
  evaluationObject: V2Evaluation;
  assessment?: Maybe<Assessment>;
};

export type V2AssessmentResultConnection = {
  __typename?: 'V2AssessmentResultConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<V2AssessmentResultEdge>>;
};

/** A Relay edge containing a `V2AssessmentResult` and its cursor. */
export type V2AssessmentResultEdge = {
  __typename?: 'V2AssessmentResultEdge';
  /** The item at the end of the edge */
  node?: Maybe<V2AssessmentResult>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type V2AssessmentResultOverviewProgram = {
  __typename?: 'V2AssessmentResultOverviewProgram';
  programKey: Scalars['String'];
  programType: Scalars['String'];
  subjects?: Maybe<Array<V2AssessmentResultOverviewSubject>>;
  preparedLearnerCount?: Maybe<Scalars['Int']>;
  program: ProgramData;
  selfReportedSubjects?: Maybe<Array<AggregatedSelfReportedSubjects>>;
};

export type V2AssessmentResultOverviewSkill = {
  __typename?: 'V2AssessmentResultOverviewSkill';
  skillId: Scalars['String'];
  subjectId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type V2AssessmentResultOverviewSubject = {
  __typename?: 'V2AssessmentResultOverviewSubject';
  subjectId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  questionsAnswered?: Maybe<Scalars['Int']>;
  questionsCorrect?: Maybe<Scalars['Int']>;
  skills?: Maybe<Array<V2AssessmentResultOverviewSkill>>;
};

export type V2AssessmentResultPaginatedConnection = TotalCount & {
  __typename?: 'V2AssessmentResultPaginatedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<V2AssessmentResultPaginatedEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `V2AssessmentResultPaginated` and its cursor. */
export type V2AssessmentResultPaginatedEdge = {
  __typename?: 'V2AssessmentResultPaginatedEdge';
  /** The item at the end of the edge */
  node?: Maybe<V2AssessmentResult>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum V2AssessmentResultSortEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  DeleteDateAsc = 'DELETE_DATE_ASC',
  DeleteDateDesc = 'DELETE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LearnerIdAsc = 'LEARNER_ID_ASC',
  LearnerIdDesc = 'LEARNER_ID_DESC',
  SessionIdAsc = 'SESSION_ID_ASC',
  SessionIdDesc = 'SESSION_ID_DESC',
  OutdatedAsc = 'OUTDATED_ASC',
  OutdatedDesc = 'OUTDATED_DESC',
}

export type V2Evaluation = {
  __typename?: 'V2Evaluation';
  programs?: Maybe<Array<Maybe<V2EvaluationProgram>>>;
  subjects?: Maybe<Array<V2EvaluationSubject>>;
};

export type V2EvaluationProgram = {
  __typename?: 'V2EvaluationProgram';
  programKey: Scalars['String'];
  programType: Scalars['String'];
  preparedness: Scalars['Float'];
  preparednessThreshold: Scalars['Float'];
  isPrepared: Scalars['Boolean'];
  subjects: Array<V2EvaluationSubject>;
  skills: Array<V2EvaluationProgramSkill>;
  program: ProgramData;
};

export type V2EvaluationProgramSkill = {
  __typename?: 'V2EvaluationProgramSkill';
  skillId: Scalars['String'];
  name: Scalars['String'];
  questionsAnswered: Scalars['Int'];
  questionsCorrect: Scalars['Int'];
};

export type V2EvaluationSubject = {
  __typename?: 'V2EvaluationSubject';
  subjectId: Scalars['String'];
  name: Scalars['String'];
  mastery: Scalars['Float'];
  questionsAnswered: Scalars['Int'];
  questionsCorrect: Scalars['Int'];
};

export type AddCompanyGroupMutationVariables = Exact<{
  companyId: Scalars['ID'];
  groupName: Scalars['String'];
}>;

export type AddCompanyGroupMutation = { __typename?: 'PublicMutation' } & {
  createGroup?: Maybe<
    { __typename?: 'CreateGroup' } & Pick<CreateGroup, 'ok'> & {
        group?: Maybe<
          { __typename?: 'Group' } & Pick<
            Group,
            'companyId' | 'createDate' | 'id' | 'name' | 'updateDate'
          > & {
              learners?: Maybe<
                { __typename?: 'LearnerPaginatedConnection' } & Pick<
                  LearnerPaginatedConnection,
                  'totalCount'
                >
              >;
            }
        >;
      }
  >;
};

export type AddEnrollmentsToEnrollmentCohortMutationVariables = Exact<{
  enrollmentCohortId: Scalars['ID'];
  enrollments: Array<Maybe<EnrollmentInfo>> | Maybe<EnrollmentInfo>;
}>;

export type AddEnrollmentsToEnrollmentCohortMutation = {
  __typename?: 'PublicMutation';
} & {
  addEnrollmentsToEnrollmentCohort?: Maybe<
    { __typename?: 'AddEnrollmentsToEnrollmentCohort' } & {
      enrollmentCohort?: Maybe<
        { __typename?: 'EnrollmentCohort' } & Pick<
          EnrollmentCohort,
          'id' | 'name'
        >
      >;
    }
  >;
};

export type AddLearnersToGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
  learnerIdsFilters?: Maybe<
    Array<Maybe<LearnerBulkSelectionFilter>> | Maybe<LearnerBulkSelectionFilter>
  >;
}>;

export type AddLearnersToGroupMutation = { __typename?: 'PublicMutation' } & {
  addLearnersToGroup?: Maybe<
    { __typename?: 'AddLearnersToGroup' } & Pick<AddLearnersToGroup, 'ok'>
  >;
};

export type AddTalentPlanLinkMutationVariables = Exact<{
  companyId: Scalars['ID'];
  link: Scalars['String'];
  name?: Maybe<Scalars['String']>;
}>;

export type AddTalentPlanLinkMutation = { __typename?: 'PublicMutation' } & {
  addTalentPlanLink?: Maybe<
    { __typename?: 'AddTalentPlanLink' } & {
      talentPlan?: Maybe<
        { __typename?: 'TalentPlan' } & Pick<TalentPlan, 'id'>
      >;
    }
  >;
};

export type AddWhitelistedProgramMutationVariables = Exact<{
  programKey: Scalars['String'];
}>;

export type AddWhitelistedProgramMutation = {
  __typename?: 'PublicMutation';
} & {
  createAssessmentWhitelistedProgram?: Maybe<
    { __typename?: 'CreateAssessmentWhitelistedProgram' } & Pick<
      CreateAssessmentWhitelistedProgram,
      'ok' | 'programType'
    >
  >;
};

export type EnterpriseCatalogQueryVariables = Exact<{
  isStandard?: Maybe<Scalars['Boolean']>;
}>;

export type EnterpriseCatalogQuery = { __typename?: 'PublicQuery' } & {
  allCatalogPrograms?: Maybe<
    Array<
      { __typename?: 'CatalogProgram' } & Pick<
        CatalogProgram,
        | 'programKey'
        | 'programType'
        | 'catalogUrl'
        | 'isStandard'
        | 'stuPricing'
      > & {
          tags?: Maybe<
            Array<
              { __typename?: 'CatalogTag' } & Pick<
                CatalogTag,
                'id' | 'category' | 'value' | 'colorScheme'
              >
            >
          >;
        }
    >
  >;
};

export type AllCompaniesAndTypesQueryVariables = Exact<{
  status?: Maybe<Status>;
}>;

export type AllCompaniesAndTypesQuery = { __typename?: 'PublicQuery' } & {
  allCompanies?: Maybe<
    Array<
      { __typename?: 'Company' } & Pick<
        Company,
        | 'id'
        | 'name'
        | 'slug'
        | 'status'
        | 'country'
        | 'companyTypeId'
        | 'createDate'
        | 'updateDate'
        | 'logoUrl'
      >
    >
  >;
  parentCompanies?: Maybe<
    Array<{ __typename?: 'Company' } & Pick<Company, 'id' | 'name' | 'slug'>>
  >;
  allCompanyTypes?: Maybe<
    Array<
      { __typename?: 'CompanyType' } & Pick<
        CompanyType,
        'id' | 'name' | 'r2ClaimType'
      >
    >
  >;
};

export type AllCompaniesQueryVariables = Exact<{
  status?: Maybe<Status>;
}>;

export type AllCompaniesQuery = { __typename?: 'PublicQuery' } & {
  allCompanies?: Maybe<
    Array<
      { __typename?: 'Company' } & Pick<
        Company,
        | 'id'
        | 'name'
        | 'slug'
        | 'status'
        | 'country'
        | 'companyTypeId'
        | 'createDate'
        | 'updateDate'
        | 'logoUrl'
      >
    >
  >;
};

export type AllContractTermsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  companyId?: Maybe<Scalars['ID']>;
  companyTypeId?: Maybe<Scalars['ID']>;
}>;

export type AllContractTermsQuery = { __typename?: 'PublicQuery' } & {
  allContractTerms?: Maybe<
    { __typename?: 'PaginatedContractTermPaginatedConnection' } & Pick<
      PaginatedContractTermPaginatedConnection,
      'totalCount'
    > & {
        edges: Array<
          Maybe<
            { __typename?: 'PaginatedContractTermPaginatedEdge' } & {
              node?: Maybe<
                { __typename?: 'PaginatedContractTerm' } & Pick<
                  PaginatedContractTerm,
                  | 'contractId'
                  | 'title'
                  | 'id'
                  | 'endDate'
                  | 'startDate'
                  | 'status'
                > & {
                    company?: Maybe<
                      { __typename?: 'Company' } & Pick<
                        Company,
                        'name' | 'id'
                      > & {
                          companyType?: Maybe<
                            { __typename?: 'CompanyType' } & Pick<
                              CompanyType,
                              'name'
                            >
                          >;
                        }
                    >;
                    contract?: Maybe<
                      { __typename?: 'Contract' } & Pick<
                        Contract,
                        | 'demo'
                        | 'id'
                        | 'isSubscription'
                        | 'title'
                        | 'status'
                        | 'strictManagement'
                      >
                    >;
                    resource?: Maybe<
                      { __typename?: 'Resource' } & Pick<
                        Resource,
                        'id' | 'data' | 'version' | 'datatype'
                      >
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type AllContractsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  companyId?: Maybe<Scalars['ID']>;
  companyTypeId?: Maybe<Scalars['ID']>;
}>;

export type AllContractsQuery = { __typename?: 'PublicQuery' } & {
  allContracts?: Maybe<
    { __typename?: 'PaginatedContractPaginatedConnection' } & Pick<
      PaginatedContractPaginatedConnection,
      'totalCount'
    > & {
        edges: Array<
          Maybe<
            { __typename?: 'PaginatedContractPaginatedEdge' } & {
              node?: Maybe<
                { __typename?: 'PaginatedContract' } & Pick<
                  PaginatedContract,
                  | 'id'
                  | 'demo'
                  | 'endDate'
                  | 'isSubscription'
                  | 'isStuContract'
                  | 'contractType'
                  | 'stuContractGracePeriodInDays'
                  | 'stuCount'
                  | 'consumedStuCount'
                  | 'pendingStuCount'
                  | 'maxActiveSeats'
                  | 'startDate'
                  | 'status'
                  | 'title'
                > & {
                    company?: Maybe<
                      { __typename?: 'Company' } & Pick<
                        Company,
                        'name' | 'id'
                      > & {
                          companyType?: Maybe<
                            { __typename?: 'CompanyType' } & Pick<
                              CompanyType,
                              'name'
                            >
                          >;
                        }
                    >;
                    enrollmentCounts?: Maybe<
                      { __typename?: 'ContractEnrollmentCount' } & Pick<
                        ContractEnrollmentCount,
                        'activeCounts'
                      >
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type AnswerAdmissionQuestionsMutationVariables = Exact<{
  learnerInterestId: Scalars['String'];
  responses?: Maybe<
    Array<AdmissionQuestionResponseInput> | AdmissionQuestionResponseInput
  >;
}>;

export type AnswerAdmissionQuestionsMutation = {
  __typename?: 'PublicMutation';
} & {
  answerAdmissionQuestions?: Maybe<
    Array<
      { __typename?: 'AdmissionQuestionResponse' } & Pick<
        AdmissionQuestionResponse,
        'response' | 'admissionQuestionId' | 'id' | 'learnerInterestId'
      > & {
          learnerInterest?: Maybe<
            { __typename?: 'LearnerInterest' } & Pick<
              LearnerInterest,
              'status' | 'id' | 'learnerId'
            > & {
                companyQuestionSetResponses?: Maybe<
                  Array<
                    Maybe<
                      {
                        __typename?: 'AdmissionQuestionResponse';
                      } & AdmissionsResponseFragment
                    >
                  >
                >;
                specificQuestionSetResponses?: Maybe<
                  Array<
                    Maybe<
                      {
                        __typename?: 'AdmissionQuestionResponse';
                      } & AdmissionsResponseFragment
                    >
                  >
                >;
              }
          >;
        }
    >
  >;
};

export type CreateAssessmentMutationVariables = Exact<{
  companyId: Scalars['ID'];
  label: Scalars['String'];
  assessmentType: Scalars['String'];
  programs: Array<Maybe<Program>> | Maybe<Program>;
  showsScore?: Maybe<Scalars['Boolean']>;
  showsProgramPreference?: Maybe<Scalars['Boolean']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  showsProgramReadiness?: Maybe<Scalars['Boolean']>;
  enableSubjectSelfReport?: Maybe<Scalars['Boolean']>;
  createAssessmentCohort?: Maybe<Scalars['Boolean']>;
  assessmentModel?: Maybe<AssessmentModelType>;
  maxRetakes?: Maybe<Scalars['Int']>;
}>;

export type CreateAssessmentMutation = { __typename?: 'PublicMutation' } & {
  createAssessment?: Maybe<
    { __typename?: 'CreateAssessment' } & {
      assessment?: Maybe<
        { __typename?: 'Assessment' } & Pick<
          Assessment,
          | 'id'
          | 'companyId'
          | 'programKeys'
          | 'programs'
          | 'label'
          | 'createDate'
          | 'assessmentModel'
          | 'maxRetakes'
        > & {
            programUrns?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ProgramURN' } & Pick<
                    ProgramUrn,
                    'programKey'
                  > & {
                      programDetails: { __typename?: 'ProgramDetails' } & Pick<
                        ProgramDetails,
                        'title'
                      >;
                    }
                >
              >
            >;
            assessmentCohorts?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'AssessmentCohort' } & Pick<
                    AssessmentCohort,
                    'id' | 'companyId' | 'assessmentId' | 'cohortKey'
                  >
                >
              >
            >;
          }
      >;
    }
  >;
};

export type CreateCatalogProgramMutationVariables = Exact<{
  programKey: Scalars['String'];
  programType: ResourceType;
  isStandard: Scalars['Boolean'];
  stuPricing?: Maybe<Scalars['Int']>;
}>;

export type CreateCatalogProgramMutation = { __typename?: 'PublicMutation' } & {
  createCatalogProgram?: Maybe<
    { __typename?: 'CreateCatalogProgram' } & {
      catalogProgram?: Maybe<
        { __typename?: 'CatalogProgram' } & Pick<
          CatalogProgram,
          'programKey' | 'isStandard' | 'programType' | 'stuPricing'
        >
      >;
    }
  >;
};

export type CreateCatalogTagMutationVariables = Exact<{
  value: Scalars['String'];
  category: CatalogTagCategory;
  colorScheme: CatalogTagColorScheme;
}>;

export type CreateCatalogTagMutation = { __typename?: 'PublicMutation' } & {
  createCatalogTag?: Maybe<
    { __typename?: 'CatalogTag' } & Pick<
      CatalogTag,
      'id' | 'value' | 'category' | 'colorScheme' | 'createDate' | 'updateDate'
    >
  >;
};

export type CreateCertificationAssessmentMutationVariables = Exact<{
  companyId: Scalars['ID'];
  programKey: Scalars['String'];
  programType: ResourceType;
  assessmentCloseDate: Scalars['DateTime'];
  omacAssessmentGroupId: Scalars['String'];
  maxDuration: Scalars['Int'];
  minDuration?: Maybe<Scalars['Int']>;
  assessmentDuration?: Maybe<Scalars['Int']>;
  attempts?: Maybe<Scalars['Int']>;
  required?: Maybe<Scalars['Boolean']>;
}>;

export type CreateCertificationAssessmentMutation = {
  __typename?: 'PublicMutation';
} & {
  createCertificationAssessment?: Maybe<
    { __typename?: 'CreateCertificationAssessment' } & Pick<
      CreateCertificationAssessment,
      'ok'
    >
  >;
};

export type CreateCompanySettingMutationVariables = Exact<{
  companyId: Scalars['ID'];
  overrideValue: Scalars['String'];
  settingId: Scalars['ID'];
}>;

export type CreateCompanySettingMutation = { __typename?: 'PublicMutation' } & {
  createCompanySetting?: Maybe<
    { __typename?: 'CreateCompanySetting' } & {
      companySetting?: Maybe<
        { __typename?: 'CompanySetting' } & Pick<
          CompanySetting,
          'companyId' | 'overrideValue' | 'settingId' | 'id'
        >
      >;
    }
  >;
};

export type CreateCompanyMutationVariables = Exact<{
  name: Scalars['String'];
  country: Scalars['String'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  companyTypeId: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  isSso?: Maybe<Scalars['Boolean']>;
  enableAutoUnenroll?: Maybe<Scalars['Boolean']>;
}>;

export type CreateCompanyMutation = { __typename?: 'PublicMutation' } & {
  createCompany?: Maybe<
    { __typename?: 'CreateCompany' } & Pick<CreateCompany, 'ok'> & {
        company?: Maybe<
          { __typename?: 'Company' } & Pick<Company, 'id' | 'slug'>
        >;
      }
  >;
};

export type CreateContractTermsMutationVariables = Exact<{
  contractId: Scalars['ID'];
  contractTerms?: Maybe<Array<ContractTermInput> | ContractTermInput>;
  status?: Maybe<Scalars['String']>;
}>;

export type CreateContractTermsMutation = { __typename?: 'PublicMutation' } & {
  createContractTerms?: Maybe<
    Array<
      { __typename?: 'ContractTerm' } & Pick<
        ContractTerm,
        'title' | 'id' | 'contractId' | 'status'
      >
    >
  >;
};

export type CreateContractMutationVariables = Exact<{
  companyId: Scalars['ID'];
  title: Scalars['String'];
  maxActiveSeats: Scalars['Int'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  notes?: Maybe<Scalars['String']>;
  demo?: Maybe<Scalars['Boolean']>;
  strictManagement?: Maybe<Scalars['Boolean']>;
  isStuContract?: Maybe<Scalars['Boolean']>;
  stuCount?: Maybe<Scalars['Int']>;
  stuContractGracePeriodInDays?: Maybe<Scalars['Int']>;
}>;

export type CreateContractMutation = { __typename?: 'PublicMutation' } & {
  createContract?: Maybe<
    { __typename?: 'CreateContract' } & {
      contract?: Maybe<
        { __typename?: 'Contract' } & Pick<
          Contract,
          'id' | 'title' | 'companyId'
        >
      >;
    }
  >;
};

export type CreateEnrollmentCohortsMutationVariables = Exact<{
  companyId: Scalars['ID'];
  enrollmentCohorts?: Maybe<
    Array<NewEnrollmentCohortInput> | NewEnrollmentCohortInput
  >;
}>;

export type CreateEnrollmentCohortsMutation = {
  __typename?: 'PublicMutation';
} & {
  createEnrollmentCohorts?: Maybe<
    { __typename?: 'CreateEnrollmentCohorts' } & {
      enrollmentCohorts?: Maybe<
        Array<
          { __typename?: 'EnrollmentCohort' } & Pick<
            EnrollmentCohort,
            | 'id'
            | 'companyId'
            | 'name'
            | 'startDate'
            | 'endDate'
            | 'state'
            | 'cohortType'
            | 'serviceModelId'
            | 'certificateTemplateId'
          > & {
              learners?: Maybe<
                { __typename?: 'LearnerPaginatedConnection' } & Pick<
                  LearnerPaginatedConnection,
                  'totalCount'
                >
              >;
            }
        >
      >;
    }
  >;
};

export type CreateGraphLearningPathMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
  edges?: Maybe<
    Array<Maybe<LearningPathEdgeInput>> | Maybe<LearningPathEdgeInput>
  >;
  allNodes: Array<LearningPathNodeInput> | LearningPathNodeInput;
  tagIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type CreateGraphLearningPathMutation = {
  __typename?: 'PublicMutation';
} & {
  createGraphLearningPath?: Maybe<
    { __typename?: 'CreateGraphLearningPath' } & {
      graphLearningPath?: Maybe<
        { __typename?: 'GraphLearningPath' } & GraphLearningPathFragment
      >;
    }
  >;
};

export type CreateLearnerInterestMutationVariables = Exact<{
  learnerId: Scalars['ID'];
  companyId: Scalars['ID'];
  programKey?: Maybe<Scalars['String']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  customData?: Maybe<Scalars['JSONString']>;
  status?: Maybe<LearnerInterestStatus>;
  isManager: Scalars['Boolean'];
  programType?: Maybe<ResourceType>;
  reapply?: Maybe<Scalars['Boolean']>;
}>;

export type CreateLearnerInterestMutation = {
  __typename?: 'PublicMutation';
} & {
  createLearnerInterest?: Maybe<
    { __typename?: 'LearnerInterest' } & Pick<
      LearnerInterest,
      | 'id'
      | 'learnerId'
      | 'createDate'
      | 'companyGraphLearningPathId'
      | 'companyId'
      | 'programKey'
      | 'customData'
      | 'status'
      | 'assessmentCohortKey'
    > & {
        companyGraphLearningPath?: Maybe<
          { __typename?: 'CompanyGraphLearningPath' } & Pick<
            CompanyGraphLearningPath,
            'id'
          > & {
              learningPath?: Maybe<
                { __typename?: 'GraphLearningPath' } & Pick<
                  GraphLearningPath,
                  'id' | 'name'
                >
              >;
            }
        >;
        specificQuestionSet?: Maybe<
          { __typename?: 'QuestionSet' } & DefaultQuestionSetFieldsFragment
        >;
        companyQuestionSetResponses?: Maybe<
          Array<
            Maybe<
              {
                __typename?: 'AdmissionQuestionResponse';
              } & AdmissionsResponseFragment
            >
          >
        >;
        specificQuestionSetResponses?: Maybe<
          Array<
            Maybe<
              {
                __typename?: 'AdmissionQuestionResponse';
              } & AdmissionsResponseFragment
            >
          >
        >;
      }
  >;
};

export type CreateLearnerSelfServeMutationVariables = Exact<{
  tokenId: Scalars['ID'];
}>;

export type CreateLearnerSelfServeMutation = {
  __typename?: 'PublicMutation';
} & {
  createLearnerSelfServe?: Maybe<
    { __typename?: 'Learner' } & Pick<Learner, 'id'>
  >;
};

export type CreateLearnersFromCsvUploadMutationVariables = Exact<{
  companyId: Scalars['ID'];
  groupId?: Maybe<Scalars['ID']>;
  uploadIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;

export type CreateLearnersFromCsvUploadMutation = {
  __typename?: 'PublicMutation';
} & {
  createLearnersFromCsvUpload?: Maybe<
    { __typename?: 'CreateLearnersFromCSVUpload' } & Pick<
      CreateLearnersFromCsvUpload,
      'ok' | 'batchId'
    >
  >;
};

export type CreateLearnersMutationVariables = Exact<{
  companyId: Scalars['ID'];
  nominationType?: Maybe<NominationType>;
  learners?: Maybe<Array<NewLearnerInput> | NewLearnerInput>;
}>;

export type CreateLearnersMutation = { __typename?: 'PublicMutation' } & {
  createLearners?: Maybe<
    { __typename?: 'CreateLearners' } & Pick<CreateLearners, 'ok' | 'batchId'>
  >;
};

export type CreateLearningPathCollectionMutationVariables = Exact<{
  name: Scalars['String'];
}>;

export type CreateLearningPathCollectionMutation = {
  __typename?: 'PublicMutation';
} & {
  createGraphLearningPathCollection?: Maybe<
    { __typename?: 'CreateGraphLearningPathCollection' } & {
      graphLearningPathCollection?: Maybe<
        { __typename?: 'GraphLearningPathCollection' } & Pick<
          GraphLearningPathCollection,
          'id' | 'name'
        >
      >;
    }
  >;
};

export type CreateQuestionSetMutationVariables = Exact<{
  companyId: Scalars['ID'];
  questionSet: QuestionSetInput;
  isManager: Scalars['Boolean'];
}>;

export type CreateQuestionSetMutation = { __typename?: 'PublicMutation' } & {
  createQuestionSet?: Maybe<
    { __typename?: 'QuestionSet' } & DefaultQuestionSetFieldsFragment
  >;
};

export type CreateSelfServeTokenMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type CreateSelfServeTokenMutation = { __typename?: 'PublicMutation' } & {
  createSelfServeToken?: Maybe<
    { __typename?: 'SelfServeToken' } & Pick<SelfServeToken, 'id'>
  >;
};

export type CreateSettingMutationVariables = Exact<{
  name: Scalars['String'];
  defaultValue: Scalars['String'];
  valueType: Scalars['String'];
}>;

export type CreateSettingMutation = { __typename?: 'PublicMutation' } & {
  createSetting?: Maybe<
    { __typename?: 'CreateSetting' } & {
      setting?: Maybe<{ __typename?: 'Setting' } & SettingFragment>;
    }
  >;
};

export type DeleteCertificationAssessmentMutationVariables = Exact<{
  companyId: Scalars['ID'];
  programKey: Scalars['String'];
  programType: ResourceType;
}>;

export type DeleteCertificationAssessmentMutation = {
  __typename?: 'PublicMutation';
} & {
  deleteCertificationAssessment?: Maybe<
    { __typename?: 'DeleteCertificationAssessment' } & Pick<
      DeleteCertificationAssessment,
      'ok'
    >
  >;
};

export type RemoveLearningPathFromCompanyMutationVariables = Exact<{
  learningPathId: Scalars['ID'];
}>;

export type RemoveLearningPathFromCompanyMutation = {
  __typename?: 'PublicMutation';
} & {
  deleteCompanyGraphLearningPath?: Maybe<
    { __typename?: 'DeleteCompanyGraphLearningPath' } & Pick<
      DeleteCompanyGraphLearningPath,
      'ok'
    >
  >;
};

export type DeleteCompanySettingMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCompanySettingMutation = { __typename?: 'PublicMutation' } & {
  deleteCompanySetting?: Maybe<
    { __typename?: 'DeleteCompanySetting' } & {
      companySetting?: Maybe<
        { __typename?: 'CompanySetting' } & Pick<
          CompanySetting,
          'deleteDate' | 'companyId'
        >
      >;
    }
  >;
};

export type DeleteLearnerInterestMutationVariables = Exact<{
  learnerId: Scalars['ID'];
  programKey?: Maybe<Scalars['String']>;
  programType?: Maybe<ResourceType>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
}>;

export type DeleteLearnerInterestMutation = {
  __typename?: 'PublicMutation';
} & {
  deleteLearnerInterest?: Maybe<
    { __typename?: 'DeleteLearnerInterest' } & Pick<DeleteLearnerInterest, 'ok'>
  >;
};

export type DeleteTalentPlanMutationVariables = Exact<{
  companyId: Scalars['ID'];
  id: Scalars['ID'];
}>;

export type DeleteTalentPlanMutation = { __typename?: 'PublicMutation' } & {
  deleteTalentPlan?: Maybe<
    { __typename?: 'DeleteTalentPlan' } & Pick<DeleteTalentPlan, 'ok'>
  >;
};

export type EnrollLearnersInLearningPathMutationVariables = Exact<{
  companyGraphLearningPathId: Scalars['ID'];
  contractTermId: Scalars['ID'];
  enrollmentCohortId?: Maybe<Scalars['ID']>;
  learnerIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type EnrollLearnersInLearningPathMutation = {
  __typename?: 'PublicMutation';
} & {
  enrollLearnersIntoLearningPath?: Maybe<
    { __typename?: 'EnrollLearnersIntoLearningPath' } & Pick<
      EnrollLearnersIntoLearningPath,
      'ok'
    >
  >;
};

export type EnrollLearnersIntoCohortMutationVariables = Exact<{
  contractTermId: Scalars['ID'];
  enrollmentCohortId: Scalars['ID'];
  learnerIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type EnrollLearnersIntoCohortMutation = {
  __typename?: 'PublicMutation';
} & {
  enrollLearnersIntoCohort?: Maybe<
    { __typename?: 'EnrollLearnersIntoCohort' } & Pick<
      EnrollLearnersIntoCohort,
      'ok'
    >
  >;
};

export type EnrollLearnersMutationVariables = Exact<{
  contractTermId: Scalars['ID'];
  selectionFilters:
    | Array<Maybe<LearnerBulkSelectionFilter>>
    | Maybe<LearnerBulkSelectionFilter>;
  enrollmentCohortId?: Maybe<Scalars['ID']>;
  utcEndDate?: Maybe<Scalars['ZuluDateTime']>;
}>;

export type EnrollLearnersMutation = { __typename?: 'PublicMutation' } & {
  enrollLearners?: Maybe<
    { __typename?: 'EnrollLearners' } & Pick<EnrollLearners, 'ok' | 'batchId'>
  >;
};

export type AdmissionsResponseFragment = {
  __typename?: 'AdmissionQuestionResponse';
} & Pick<
  AdmissionQuestionResponse,
  'id' | 'admissionQuestionId' | 'response'
> & {
    admissionQuestion?: Maybe<
      { __typename?: 'AdmissionQuestion' } & Pick<
        AdmissionQuestion,
        'type' | 'label'
      >
    >;
  };

export type ContractTermFieldsFragment = {
  __typename?: 'PaginatedContractTerm';
} & Pick<
  PaginatedContractTerm,
  | 'id'
  | 'contractId'
  | 'endDate'
  | 'status'
  | 'title'
  | 'startDate'
  | 'enrollmentEndDate'
  | 'stuPricingOverride'
  | 'consumedStuCount'
> & {
    resource?: Maybe<
      { __typename?: 'Resource' } & Pick<
        Resource,
        'data' | 'id' | 'version' | 'durationOverride'
      > & {
          programUrn: { __typename?: 'ProgramURN' } & Pick<
            ProgramUrn,
            'programKey' | 'programLocale' | 'programMajor' | 'programType'
          > & {
              programDetails: { __typename?: 'ProgramDetails' } & Pick<
                ProgramDetails,
                'title'
              >;
            };
          resourceData?: Maybe<
            { __typename?: 'ResourceData' } & Pick<
              ResourceData,
              'defaultDurationInDays' | 'numRequiredProjects'
            >
          >;
        }
    >;
    enrollmentCounts?: Maybe<
      { __typename?: 'ContractTermEnrollmentCount' } & Pick<
        ContractTermEnrollmentCount,
        'activeCounts' | 'enrollmentCounts'
      >
    >;
  };

export type ContractTermsByStatusFragment = {
  __typename?: 'PaginatedContract';
} & {
  contractTerms?: Maybe<
    { __typename?: 'PaginatedContractTermPaginatedConnection' } & Pick<
      PaginatedContractTermPaginatedConnection,
      'totalCount'
    > & {
        edges: Array<
          Maybe<
            { __typename?: 'PaginatedContractTermPaginatedEdge' } & {
              node?: Maybe<
                {
                  __typename?: 'PaginatedContractTerm';
                } & ContractTermFieldsFragment
              >;
            }
          >
        >;
      }
  >;
  draftTerms?: Maybe<
    { __typename?: 'PaginatedContractTermPaginatedConnection' } & Pick<
      PaginatedContractTermPaginatedConnection,
      'totalCount'
    > & {
        edges: Array<
          Maybe<
            { __typename?: 'PaginatedContractTermPaginatedEdge' } & {
              node?: Maybe<
                {
                  __typename?: 'PaginatedContractTerm';
                } & ContractTermFieldsFragment
              >;
            }
          >
        >;
      }
  >;
  suspendedTerms?: Maybe<
    { __typename?: 'PaginatedContractTermPaginatedConnection' } & Pick<
      PaginatedContractTermPaginatedConnection,
      'totalCount'
    > & {
        edges: Array<
          Maybe<
            { __typename?: 'PaginatedContractTermPaginatedEdge' } & {
              node?: Maybe<
                {
                  __typename?: 'PaginatedContractTerm';
                } & ContractTermFieldsFragment
              >;
            }
          >
        >;
      }
  >;
};

export type FullAssessmentFragment = { __typename?: 'Assessment' } & Pick<
  Assessment,
  | 'companyId'
  | 'label'
  | 'showsScore'
  | 'showsProgramPreference'
  | 'showsProgramReadiness'
  | 'id'
  | 'programKeys'
  | 'programs'
  | 'v2ify'
  | 'enableSubjectSelfReport'
  | 'assessmentModel'
  | 'maxRetakes'
> & {
    programUrns?: Maybe<
      Array<
        Maybe<
          { __typename?: 'ProgramURN' } & Pick<
            ProgramUrn,
            'programKey' | 'programType'
          > & {
              programDetails: { __typename?: 'ProgramDetails' } & Pick<
                ProgramDetails,
                'title' | 'summary'
              >;
            }
        >
      >
    >;
    companyGraphLearningPath?: Maybe<
      { __typename?: 'CompanyGraphLearningPath' } & Pick<
        CompanyGraphLearningPath,
        'id'
      > & {
          learningPath?: Maybe<
            { __typename?: 'GraphLearningPath' } & Pick<
              GraphLearningPath,
              'id' | 'name'
            > & {
                edges?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'LPEdge' } & Pick<
                        LpEdge,
                        'parentNodeId' | 'childNodeId'
                      >
                    >
                  >
                >;
                nodes?: Maybe<
                  Array<
                    Maybe<
                      | ({ __typename?: 'LPCourseNode' } & Pick<
                          LpCourseNode,
                          'id' | 'label'
                        >)
                      | ({ __typename?: 'LPNdNode' } & Pick<
                          LpNdNode,
                          'id' | 'label'
                        >)
                    >
                  >
                >;
              }
          >;
        }
    >;
  };

export type FullEnrollmentFieldsFragment = { __typename?: 'Enrollment' } & Pick<
  Enrollment,
  | 'programKey'
  | 'programType'
  | 'userKey'
  | 'enrollmentStatus'
  | 'contractTermId'
  | 'enrollmentDate'
  | 'stateChangeDate'
> & { program: { __typename?: 'ProgramData' } & Pick<ProgramData, 'title'> };

export type FullLearnerFragment = { __typename?: 'Learner' } & Pick<
  Learner,
  'id' | 'companyId' | 'createDate' | 'userKey' | 'employeeId' | 'email'
> & {
    allEnrollments?: Maybe<
      { __typename?: 'AllEnrollments' } & {
        currentEnrollments?: Maybe<
          Array<{ __typename?: 'Enrollment' } & FullEnrollmentFieldsFragment>
        >;
        unenrollments?: Maybe<
          Array<{ __typename?: 'Enrollment' } & FullEnrollmentFieldsFragment>
        >;
        graduations?: Maybe<
          Array<{ __typename?: 'Enrollment' } & FullEnrollmentFieldsFragment>
        >;
      }
    >;
    user?: Maybe<
      { __typename?: 'User' } & Pick<User, 'firstName' | 'lastName' | 'email'>
    >;
    groups?: Maybe<
      Array<Maybe<{ __typename?: 'Group' } & Pick<Group, 'id' | 'name'>>>
    >;
    glpAssociations?: Maybe<
      Array<
        Maybe<
          { __typename?: 'LearnerGraphLearningPath' } & Pick<
            LearnerGraphLearningPath,
            'id'
          > & {
              companyGraphLearningPath?: Maybe<
                { __typename?: 'CompanyGraphLearningPath' } & Pick<
                  CompanyGraphLearningPath,
                  'id'
                > & {
                    learningPath?: Maybe<
                      { __typename?: 'GraphLearningPath' } & Pick<
                        GraphLearningPath,
                        'id' | 'name'
                      > & {
                          edges?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'LPEdge' } & Pick<
                                  LpEdge,
                                  'id' | 'parentNodeId' | 'childNodeId'
                                >
                              >
                            >
                          >;
                          nodes?: Maybe<
                            Array<
                              Maybe<
                                | ({ __typename?: 'LPCourseNode' } & Pick<
                                    LpCourseNode,
                                    'id' | 'label'
                                  >)
                                | ({ __typename?: 'LPNdNode' } & Pick<
                                    LpNdNode,
                                    'id' | 'label'
                                  >)
                              >
                            >
                          >;
                        }
                    >;
                  }
              >;
            }
        >
      >
    >;
    interests?: Maybe<
      Array<
        { __typename?: 'LearnerInterest' } & Pick<
          LearnerInterest,
          | 'learnerId'
          | 'programKey'
          | 'contractTermId'
          | 'status'
          | 'id'
          | 'companyId'
          | 'companyGraphLearningPathId'
          | 'createDate'
          | 'updateDate'
        > & {
            companyGraphLearningPath?: Maybe<
              { __typename?: 'CompanyGraphLearningPath' } & Pick<
                CompanyGraphLearningPath,
                'id'
              > & {
                  learningPath?: Maybe<
                    { __typename?: 'GraphLearningPath' } & Pick<
                      GraphLearningPath,
                      'id' | 'name'
                    >
                  >;
                }
            >;
            companyQuestionSetResponses?: Maybe<
              Array<
                Maybe<
                  {
                    __typename?: 'AdmissionQuestionResponse';
                  } & AdmissionsResponseFragment
                >
              >
            >;
            specificQuestionSetResponses?: Maybe<
              Array<
                Maybe<
                  {
                    __typename?: 'AdmissionQuestionResponse';
                  } & AdmissionsResponseFragment
                >
              >
            >;
            nominations?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Nomination' } & Pick<
                    Nomination,
                    'createDate'
                  > & {
                      nominatorUser?: Maybe<
                        { __typename?: 'User' } & Pick<
                          User,
                          'firstName' | 'lastName' | 'email'
                        >
                      >;
                    }
                >
              >
            >;
          }
      >
    >;
    roster?: Maybe<
      Array<
        { __typename?: 'Roster' } & Pick<
          Roster,
          'contractTermId' | 'enrollmentId' | 'enrollmentStatus'
        > & {
            programInfo?: Maybe<
              { __typename?: 'ProgramInfo' } & Pick<
                ProgramInfo,
                | 'totalMandatoryProjects'
                | 'programKey'
                | 'programType'
                | 'programVersion'
                | 'contentLocale'
              >
            >;
            learnerActivity?: Maybe<
              { __typename?: 'LearnerActivity' } & Pick<
                LearnerActivity,
                | 'lastSeen'
                | 'estimatedEndDate'
                | 'enrolledAt'
                | 'graduatedAt'
                | 'unenrolledAt'
                | 'mandatoryProjectsPassed'
              >
            >;
          }
      >
    >;
    nominationsReceived?: Maybe<
      Array<
        Maybe<
          { __typename?: 'Nomination' } & Pick<
            Nomination,
            'createDate' | 'nominationType' | 'learnerInterestId'
          > & {
              nominatorUser?: Maybe<
                { __typename?: 'User' } & Pick<
                  User,
                  'firstName' | 'lastName' | 'email'
                >
              >;
            }
        >
      >
    >;
  };

export type GraphLearningPathFragment = {
  __typename?: 'GraphLearningPath';
} & Pick<
  GraphLearningPath,
  'id' | 'name' | 'description' | 'status' | 'createdBy'
> & {
    nodes?: Maybe<
      Array<
        Maybe<
          | ({ __typename?: 'LPCourseNode' } & Pick<
              LpCourseNode,
              'id' | 'label'
            >)
          | ({ __typename?: 'LPNdNode' } & Pick<LpNdNode, 'id' | 'label'>)
        >
      >
    >;
    edges?: Maybe<
      Array<
        Maybe<
          { __typename?: 'LPEdge' } & Pick<
            LpEdge,
            'parentNodeId' | 'childNodeId'
          >
        >
      >
    >;
    tags?: Maybe<
      Array<
        { __typename?: 'CatalogTag' } & Pick<
          CatalogTag,
          'id' | 'value' | 'category' | 'colorScheme'
        >
      >
    >;
  };

export type LearnerInterestFragment = { __typename?: 'LearnerInterest' } & Pick<
  LearnerInterest,
  | 'learnerId'
  | 'assessmentCohortKey'
  | 'programKey'
  | 'programType'
  | 'id'
  | 'status'
  | 'updateDate'
  | 'companyId'
  | 'companyGraphLearningPathId'
> & {
    companyGraphLearningPath?: Maybe<
      { __typename?: 'CompanyGraphLearningPath' } & Pick<
        CompanyGraphLearningPath,
        'id'
      > & {
          learningPath?: Maybe<
            { __typename?: 'GraphLearningPath' } & Pick<
              GraphLearningPath,
              'id' | 'name'
            >
          >;
        }
    >;
  };

export type DefaultQuestionSetFieldsFragment = {
  __typename?: 'QuestionSet';
} & Pick<
  QuestionSet,
  | 'id'
  | 'status'
  | 'companyId'
  | 'name'
  | 'confirmationMessage'
  | 'rejectionMessage'
  | 'inactiveStatusMessage'
  | 'description'
> & {
    admissionQuestions?: Maybe<
      Array<
        Maybe<
          { __typename?: 'AdmissionQuestion' } & MakeOptional<
            Pick<
              AdmissionQuestion,
              | 'id'
              | 'status'
              | 'label'
              | 'order'
              | 'required'
              | 'priority'
              | 'options'
              | 'correctAnswers'
              | 'type'
              | 'filterLabel'
            >,
            'correctAnswers'
          >
        >
      >
    >;
    companyPrograms?: Maybe<
      Array<
        Maybe<
          { __typename?: 'CompanyProgram' } & Pick<
            CompanyProgram,
            'programKey' | 'programType' | 'companyId'
          >
        >
      >
    >;
  };

export type SettingFragment = { __typename?: 'Setting' } & Pick<
  Setting,
  'id' | 'name' | 'defaultValue' | 'valueType'
> & {
    companySettings?: Maybe<
      Array<
        Maybe<
          { __typename?: 'CompanySetting' } & Pick<
            CompanySetting,
            'companyId' | 'overrideValue'
          >
        >
      >
    >;
  };

export type GenerateLearnerInterestCsvMutationVariables = Exact<{
  companyId: Scalars['ID'];
  assessmentScoreLower?: Maybe<Scalars['Float']>;
  assessmentScoreUpper?: Maybe<Scalars['Float']>;
  eligibility?: Maybe<LearnerInterestEligibility>;
  programKey?: Maybe<Scalars['String']>;
  programType?: Maybe<ResourceType>;
  status?: Maybe<LearnerInterestStatus>;
  contentTypes?: Maybe<
    Array<Maybe<LearnerInterestProgramType>> | Maybe<LearnerInterestProgramType>
  >;
}>;

export type GenerateLearnerInterestCsvMutation = {
  __typename?: 'PublicMutation';
} & {
  generateLearnerInterestCsv?: Maybe<
    { __typename?: 'GenerateLearnerInterestCsv' } & Pick<
      GenerateLearnerInterestCsv,
      'batchId' | 's3ObjectKey'
    >
  >;
};

export type GetAssessmentCohortQueryVariables = Exact<{
  cohortKey: Scalars['String'];
}>;

export type GetAssessmentCohortQuery = { __typename?: 'PublicQuery' } & {
  assessmentCohort?: Maybe<
    { __typename?: 'AssessmentCohort' } & Pick<
      AssessmentCohort,
      'createDate' | 'id' | 'cohortKey' | 'companyId' | 'assessmentId'
    > & {
        v2AssessmentResults?: Maybe<
          { __typename?: 'V2AssessmentResultPaginatedConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'V2AssessmentResultPaginatedEdge' } & {
                  node?: Maybe<
                    { __typename?: 'V2AssessmentResult' } & {
                      evaluationObject: { __typename?: 'V2Evaluation' } & {
                        programs?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'V2EvaluationProgram' } & Pick<
                                V2EvaluationProgram,
                                | 'programKey'
                                | 'preparedness'
                                | 'preparednessThreshold'
                              >
                            >
                          >
                        >;
                      };
                    }
                  >;
                }
              >
            >;
          }
        >;
        v2AssessmentResultOverviewPrograms?: Maybe<
          Array<
            { __typename?: 'V2AssessmentResultOverviewProgram' } & Pick<
              V2AssessmentResultOverviewProgram,
              'programKey' | 'programType' | 'preparedLearnerCount'
            > & {
                program: { __typename?: 'ProgramData' } & Pick<
                  ProgramData,
                  'title'
                >;
                selfReportedSubjects?: Maybe<
                  Array<
                    { __typename?: 'AggregatedSelfReportedSubjects' } & Pick<
                      AggregatedSelfReportedSubjects,
                      'subjectId' | 'subjectName' | 'averageSelfReportedMastery'
                    >
                  >
                >;
                subjects?: Maybe<
                  Array<
                    { __typename?: 'V2AssessmentResultOverviewSubject' } & Pick<
                      V2AssessmentResultOverviewSubject,
                      | 'subjectId'
                      | 'name'
                      | 'questionsAnswered'
                      | 'questionsCorrect'
                    >
                  >
                >;
              }
          >
        >;
        completed?: Maybe<
          { __typename?: 'LearnerPaginatedConnection' } & Pick<
            LearnerPaginatedConnection,
            'totalCount'
          >
        >;
        participants?: Maybe<
          { __typename?: 'LearnerPaginatedConnection' } & Pick<
            LearnerPaginatedConnection,
            'totalCount'
          >
        >;
      }
  >;
};

export type GetCompanyAssessmentCohortsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type GetCompanyAssessmentCohortsQuery = {
  __typename?: 'PublicQuery';
} & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        assessments?: Maybe<
          { __typename?: 'AssessmentPaginatedConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'AssessmentPaginatedEdge' } & {
                  node?: Maybe<
                    { __typename?: 'Assessment' } & Pick<
                      Assessment,
                      | 'id'
                      | 'label'
                      | 'programKeys'
                      | 'assessmentModel'
                      | 'maxRetakes'
                    > & {
                        programUrns?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'ProgramURN' } & Pick<
                                ProgramUrn,
                                'programKey' | 'programType'
                              > & {
                                  programDetails: {
                                    __typename?: 'ProgramDetails';
                                  } & Pick<ProgramDetails, 'title' | 'summary'>;
                                }
                            >
                          >
                        >;
                        assessmentCohorts?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'AssessmentCohort' } & Pick<
                                AssessmentCohort,
                                | 'id'
                                | 'cohortKey'
                                | 'archiveDate'
                                | 'assessmentId'
                                | 'deleteDate'
                              >
                            >
                          >
                        >;
                      }
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type AssessmentInfoPublicQueryVariables = Exact<{
  cohortKey: Scalars['String'];
}>;

export type AssessmentInfoPublicQuery = { __typename?: 'PublicQuery' } & {
  assessmentCohort?: Maybe<
    { __typename?: 'AssessmentCohort' } & Pick<
      AssessmentCohort,
      'cohortKey' | 'archiveDate' | 'deleteDate'
    > & {
        assessment?: Maybe<
          { __typename?: 'Assessment' } & Pick<
            Assessment,
            'label' | 'id' | 'programKeys' | 'assessmentModel' | 'programs'
          > & {
              programUrns?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'ProgramURN' } & Pick<
                      ProgramUrn,
                      'programKey'
                    > & {
                        programDetails: {
                          __typename?: 'ProgramDetails';
                        } & Pick<ProgramDetails, 'title'>;
                      }
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type AssessmentLearnerQueryVariables = Exact<{
  learnerId: Scalars['ID'];
  cohortKey: Scalars['String'];
}>;

export type AssessmentLearnerQuery = { __typename?: 'PublicQuery' } & {
  learner?: Maybe<
    { __typename?: 'Learner' } & Pick<Learner, 'id' | 'userKey'> & {
        assessmentLabSessions?: Maybe<
          Array<
            { __typename?: 'AssessmentLabSession' } & Pick<
              AssessmentLabSession,
              'sessionId' | 'cohortKey' | 'id' | 'programPreference'
            > & {
                selfReportedSubjects?: Maybe<
                  Array<
                    { __typename?: 'SelfReportedSubjects' } & Pick<
                      SelfReportedSubjects,
                      'subjectId' | 'subjectName' | 'selfReportedMastery'
                    >
                  >
                >;
                v2AssessmentResult?: Maybe<
                  { __typename?: 'V2AssessmentResult' } & Pick<
                    V2AssessmentResult,
                    'id'
                  > & {
                      assessment?: Maybe<
                        { __typename?: 'Assessment' } & Pick<Assessment, 'id'>
                      >;
                      evaluationObject: { __typename?: 'V2Evaluation' } & {
                        programs?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'V2EvaluationProgram' } & Pick<
                                V2EvaluationProgram,
                                | 'preparedness'
                                | 'preparednessThreshold'
                                | 'programKey'
                              >
                            >
                          >
                        >;
                        subjects?: Maybe<
                          Array<
                            { __typename?: 'V2EvaluationSubject' } & Pick<
                              V2EvaluationSubject,
                              | 'subjectId'
                              | 'questionsAnswered'
                              | 'questionsCorrect'
                            >
                          >
                        >;
                      };
                    }
                >;
              }
          >
        >;
      }
  >;
};

export type GetAssessmentQueryVariables = Exact<{
  assessmentId: Scalars['ID'];
}>;

export type GetAssessmentQuery = { __typename?: 'PublicQuery' } & {
  assessment?: Maybe<{ __typename?: 'Assessment' } & FullAssessmentFragment>;
};

export type AssessmentCohortOverviewQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type AssessmentCohortOverviewQuery = { __typename?: 'PublicQuery' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        assessments?: Maybe<
          { __typename?: 'AssessmentPaginatedConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'AssessmentPaginatedEdge' } & {
                  node?: Maybe<
                    { __typename?: 'Assessment' } & Pick<
                      Assessment,
                      | 'id'
                      | 'programKeys'
                      | 'label'
                      | 'companyGraphLearningPathId'
                      | 'showsProgramPreference'
                      | 'showsProgramReadiness'
                      | 'enableSubjectSelfReport'
                      | 'assessmentModel'
                      | 'maxRetakes'
                    >
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type GetAutoEnrollmentEligibilityQueryVariables = Exact<{
  learnerInterestId: Scalars['ID'];
}>;

export type GetAutoEnrollmentEligibilityQuery = {
  __typename?: 'PublicQuery';
} & {
  autoEnrollmentEligibility?: Maybe<
    { __typename?: 'AutoEnrollmentEligibility' } & Pick<
      AutoEnrollmentEligibility,
      'eligible' | 'reason'
    >
  >;
};

export type GetBatchProgressQueryVariables = Exact<{
  batchId: Scalars['String'];
}>;

export type GetBatchProgressQuery = { __typename?: 'PublicQuery' } & {
  progressSummary?: Maybe<
    { __typename?: 'ProgressSummary' } & Pick<
      ProgressSummary,
      'batchId' | 'total' | 'succeeded' | 'inProgress' | 'failed'
    > & {
        errors?: Maybe<
          Array<
            { __typename?: 'ProgressSummaryError' } & Pick<
              ProgressSummaryError,
              'taskKey' | 'taskError'
            >
          >
        >;
      }
  >;
};

export type CanRetakeQueryVariables = Exact<{
  learnerId: Scalars['ID'];
  cohortKey: Scalars['String'];
}>;

export type CanRetakeQuery = { __typename?: 'PublicQuery' } & {
  learner?: Maybe<
    { __typename?: 'Learner' } & Pick<Learner, 'canRetakeAssessment'>
  >;
};

export type GetCatalogAccessStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCatalogAccessStatusQuery = { __typename?: 'PublicQuery' } & {
  catalogAccessStatus?: Maybe<
    { __typename?: 'CatalogAccessStatus' } & Pick<
      CatalogAccessStatus,
      'hasAccess' | 'requestId'
    > & {
        userDetails?: Maybe<
          { __typename?: 'CatalogAccessUserDetails' } & Pick<
            CatalogAccessUserDetails,
            'isLoggedIn' | 'isCompanyMember'
          >
        >;
        tokenDetails?: Maybe<
          { __typename?: 'CatalogAccessTokenDetails' } & Pick<
            CatalogAccessTokenDetails,
            | 'isValid'
            | 'isExpired'
            | 'isDeleted'
            | 'isMalformed'
            | 'expiresAt'
            | 'isLegacy'
          >
        >;
        legacyTokenDetails?: Maybe<
          { __typename?: 'CatalogAccessTokenDetails' } & Pick<
            CatalogAccessTokenDetails,
            'isValid' | 'expiresAt'
          >
        >;
      }
  >;
};

export type GetCatalogTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCatalogTagsQuery = { __typename?: 'PublicQuery' } & {
  allCatalogTags?: Maybe<
    { __typename?: 'PaginatedCatalogTagPaginatedConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'PaginatedCatalogTagPaginatedEdge' } & {
            node?: Maybe<
              { __typename?: 'PaginatedCatalogTag' } & Pick<
                PaginatedCatalogTag,
                'id' | 'category' | 'value' | 'colorScheme'
              >
            >;
          }
        >
      >;
    }
  >;
};

export type EnrollmentCohortAssociationsQueryVariables = Exact<{
  cohortId: Scalars['ID'];
}>;

export type EnrollmentCohortAssociationsQuery = {
  __typename?: 'PublicQuery';
} & {
  enrollmentCohort?: Maybe<
    { __typename?: 'EnrollmentCohort' } & Pick<EnrollmentCohort, 'id'> & {
        associations?: Maybe<
          Array<
            Maybe<
              { __typename?: 'EnrollmentCohortLearnerContractTerm' } & Pick<
                EnrollmentCohortLearnerContractTerm,
                'learnerId' | 'contractTermId'
              >
            >
          >
        >;
        learners?: Maybe<
          { __typename?: 'LearnerPaginatedConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'LearnerPaginatedEdge' } & {
                  node?: Maybe<
                    { __typename?: 'Learner' } & Pick<Learner, 'id'>
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type CohortParticipantsQueryVariables = Exact<{
  cohortKey: Scalars['String'];
  hasCompletedAssessment?: Maybe<Scalars['Boolean']>;
  hasStartedAssessment?: Maybe<Scalars['Boolean']>;
  preparedForProgramKey?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
}>;

export type CohortParticipantsQuery = { __typename?: 'PublicQuery' } & {
  assessmentCohort?: Maybe<
    { __typename?: 'AssessmentCohort' } & Pick<
      AssessmentCohort,
      'cohortKey' | 'createDate'
    > & {
        learners?: Maybe<
          { __typename?: 'LearnerPaginatedConnection' } & Pick<
            LearnerPaginatedConnection,
            'totalCount'
          > & {
              edges: Array<
                Maybe<
                  { __typename?: 'LearnerPaginatedEdge' } & {
                    node?: Maybe<
                      { __typename?: 'Learner' } & Pick<
                        Learner,
                        'id' | 'email' | 'userKey'
                      > & {
                          user?: Maybe<
                            { __typename?: 'User' } & Pick<
                              User,
                              'firstName' | 'lastName' | 'email'
                            >
                          >;
                          groups?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'Group' } & Pick<Group, 'id'>
                              >
                            >
                          >;
                          assessmentLabSessions?: Maybe<
                            Array<
                              { __typename?: 'AssessmentLabSession' } & Pick<
                                AssessmentLabSession,
                                'createDate' | 'programPreference'
                              > & {
                                  assessment: {
                                    __typename?: 'Assessment';
                                  } & Pick<Assessment, 'id'>;
                                  v2AssessmentResult?: Maybe<
                                    {
                                      __typename?: 'V2AssessmentResult';
                                    } & Pick<
                                      V2AssessmentResult,
                                      'id' | 'createDate'
                                    > & {
                                        assessmentLabSession?: Maybe<
                                          {
                                            __typename?: 'AssessmentLabSession';
                                          } & Pick<
                                            AssessmentLabSession,
                                            'cohortKey' | 'createDate'
                                          >
                                        >;
                                        evaluationObject: {
                                          __typename?: 'V2Evaluation';
                                        } & {
                                          programs?: Maybe<
                                            Array<
                                              Maybe<
                                                {
                                                  __typename?: 'V2EvaluationProgram';
                                                } & Pick<
                                                  V2EvaluationProgram,
                                                  | 'preparedness'
                                                  | 'preparednessThreshold'
                                                  | 'programKey'
                                                > & {
                                                    program: {
                                                      __typename?: 'ProgramData';
                                                    } & Pick<
                                                      ProgramData,
                                                      'title'
                                                    >;
                                                    subjects: Array<
                                                      {
                                                        __typename?: 'V2EvaluationSubject';
                                                      } & Pick<
                                                        V2EvaluationSubject,
                                                        | 'name'
                                                        | 'subjectId'
                                                        | 'questionsAnswered'
                                                        | 'questionsCorrect'
                                                      >
                                                    >;
                                                  }
                                              >
                                            >
                                          >;
                                          subjects?: Maybe<
                                            Array<
                                              {
                                                __typename?: 'V2EvaluationSubject';
                                              } & Pick<
                                                V2EvaluationSubject,
                                                | 'subjectId'
                                                | 'questionsAnswered'
                                                | 'questionsCorrect'
                                              >
                                            >
                                          >;
                                        };
                                      }
                                  >;
                                  selfReportedSubjects?: Maybe<
                                    Array<
                                      {
                                        __typename?: 'SelfReportedSubjects';
                                      } & Pick<
                                        SelfReportedSubjects,
                                        | 'subjectId'
                                        | 'subjectName'
                                        | 'selfReportedMastery'
                                      >
                                    >
                                  >;
                                }
                            >
                          >;
                          allEnrollments?: Maybe<
                            { __typename?: 'AllEnrollments' } & {
                              currentEnrollments?: Maybe<
                                Array<
                                  { __typename?: 'Enrollment' } & Pick<
                                    Enrollment,
                                    'programKey' | 'enrollmentStatus'
                                  > & {
                                      program: {
                                        __typename?: 'ProgramData';
                                      } & Pick<ProgramData, 'title'>;
                                    }
                                >
                              >;
                              unenrollments?: Maybe<
                                Array<
                                  { __typename?: 'Enrollment' } & Pick<
                                    Enrollment,
                                    'programKey' | 'enrollmentStatus'
                                  > & {
                                      program: {
                                        __typename?: 'ProgramData';
                                      } & Pick<ProgramData, 'title'>;
                                    }
                                >
                              >;
                              graduations?: Maybe<
                                Array<
                                  { __typename?: 'Enrollment' } & Pick<
                                    Enrollment,
                                    'programKey' | 'enrollmentStatus'
                                  > & {
                                      program: {
                                        __typename?: 'ProgramData';
                                      } & Pick<ProgramData, 'title'>;
                                    }
                                >
                              >;
                            }
                          >;
                          interests?: Maybe<
                            Array<
                              { __typename?: 'LearnerInterest' } & Pick<
                                LearnerInterest,
                                'programKey' | 'assessmentCohortKey'
                              > & {
                                  companyQuestionSetResponses?: Maybe<
                                    Array<
                                      Maybe<
                                        {
                                          __typename?: 'AdmissionQuestionResponse';
                                        } & Pick<
                                          AdmissionQuestionResponse,
                                          'id' | 'response'
                                        > & {
                                            admissionQuestion?: Maybe<
                                              {
                                                __typename?: 'AdmissionQuestion';
                                              } & Pick<AdmissionQuestion, 'id'>
                                            >;
                                          }
                                      >
                                    >
                                  >;
                                }
                            >
                          >;
                        }
                    >;
                  }
                >
              >;
            }
        >;
      }
  >;
};

export type EnrollmentCohortOnTrackChartQueryVariables = Exact<{
  cohortId: Scalars['ID'];
  snapshotDatetime?: Maybe<Scalars['ZuluDateTime']>;
  includeProjects: Scalars['Boolean'];
}>;

export type EnrollmentCohortOnTrackChartQuery = {
  __typename?: 'PublicQuery';
} & {
  enrollmentCohort?: Maybe<
    { __typename?: 'EnrollmentCohort' } & {
      stats?: Maybe<
        { __typename?: 'EnrollmentCohortStats' } & MakeOptional<
          Pick<
            EnrollmentCohortStats,
            | 'numOnTrack'
            | 'numMonitor'
            | 'numOffTrack'
            | 'numGraduations'
            | 'numProjectsSubmitted'
            | 'numProjectsPassed'
          >,
          'numProjectsSubmitted' | 'numProjectsPassed'
        >
      >;
    }
  >;
};

export type GetCompanyAdmissionQuestionsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type GetCompanyAdmissionQuestionsQuery = {
  __typename?: 'PublicQuery';
} & {
  questionSets?: Maybe<
    { __typename?: 'QuestionSetPaginatedConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'QuestionSetPaginatedEdge' } & {
            node?: Maybe<
              { __typename?: 'QuestionSet' } & Pick<QuestionSet, 'id'> & {
                  admissionQuestions?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'AdmissionQuestion' } & Pick<
                          AdmissionQuestion,
                          | 'id'
                          | 'options'
                          | 'type'
                          | 'label'
                          | 'priority'
                          | 'filterLabel'
                          | 'order'
                        >
                      >
                    >
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
};

export type CompanyAssessmentsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type CompanyAssessmentsQuery = { __typename?: 'PublicQuery' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        assessments?: Maybe<
          { __typename?: 'AssessmentPaginatedConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'AssessmentPaginatedEdge' } & {
                  node?: Maybe<
                    { __typename?: 'Assessment' } & Pick<
                      Assessment,
                      'createDate'
                    > & {
                        assessmentCohorts?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'AssessmentCohort' } & Pick<
                                AssessmentCohort,
                                | 'id'
                                | 'createDate'
                                | 'archiveDate'
                                | 'deleteDate'
                                | 'assessmentId'
                                | 'cohortKey'
                              >
                            >
                          >
                        >;
                      } & FullAssessmentFragment
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type GetCompanyBySlugQueryVariables = Exact<{
  companySlug: Scalars['String'];
  isStaffOrAdmin: Scalars['Boolean'];
  isCompanyManager: Scalars['Boolean'];
}>;

export type GetCompanyBySlugQuery = { __typename?: 'PublicQuery' } & {
  companyBySlug?: Maybe<
    { __typename?: 'Company' } & MakeOptional<
      Pick<
        Company,
        | 'id'
        | 'name'
        | 'slug'
        | 'logoUrl'
        | 'isSso'
        | 'totalActiveEnrollments'
        | 'averageProjectCompletion'
      >,
      'isSso' | 'totalActiveEnrollments' | 'averageProjectCompletion'
    > & {
        companySettings?: Maybe<
          Array<
            { __typename?: 'CompanySettingObject' } & Pick<
              CompanySettingObject,
              'value' | 'valueType' | 'name' | 'settingId'
            >
          >
        >;
        relationships?: Maybe<
          { __typename?: 'CompanyRelationships' } & {
            parent?: Maybe<
              { __typename?: 'Company' } & Pick<Company, 'id' | 'name' | 'slug'>
            >;
          }
        >;
        expiredContracts?: Maybe<
          { __typename?: 'PaginatedContractPaginatedConnection' } & Pick<
            PaginatedContractPaginatedConnection,
            'totalCount'
          >
        >;
        activeContracts?: Maybe<
          { __typename?: 'PaginatedContractPaginatedConnection' } & Pick<
            PaginatedContractPaginatedConnection,
            'totalCount'
          >
        >;
        suspendedContracts?: Maybe<
          { __typename?: 'PaginatedContractPaginatedConnection' } & Pick<
            PaginatedContractPaginatedConnection,
            'totalCount'
          >
        >;
      }
  >;
};

export type GetCompanyCatalogTokensQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type GetCompanyCatalogTokensQuery = { __typename?: 'PublicQuery' } & {
  company?: Maybe<
    { __typename?: 'Company' } & {
      catalogTokens?: Maybe<
        Array<
          { __typename?: 'CompanyCatalogToken' } & Pick<
            CompanyCatalogToken,
            'companyId' | 'token' | 'expiresAt'
          >
        >
      >;
    }
  >;
};

export type GetCompanyContractTermsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  isStaff: Scalars['Boolean'];
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  isExpired?: Maybe<Scalars['Boolean']>;
  programType?: Maybe<ResourceType>;
}>;

export type GetCompanyContractTermsQuery = { __typename?: 'PublicQuery' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        contracts?: Maybe<
          { __typename?: 'PaginatedContractPaginatedConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'PaginatedContractPaginatedEdge' } & {
                  node?: Maybe<
                    { __typename?: 'PaginatedContract' } & MakeOptional<
                      Pick<
                        PaginatedContract,
                        | 'companyId'
                        | 'createDate'
                        | 'demo'
                        | 'endDate'
                        | 'isSubscription'
                        | 'maxActiveSeats'
                        | 'strictManagement'
                        | 'notes'
                        | 'startDate'
                        | 'status'
                        | 'title'
                        | 'id'
                        | 'isStuContract'
                        | 'contractType'
                        | 'stuCount'
                        | 'consumedStuCount'
                        | 'pendingStuCount'
                        | 'stuContractGracePeriodInDays'
                      >,
                      'notes'
                    > & {
                        enrollmentCounts?: Maybe<
                          { __typename?: 'ContractEnrollmentCount' } & Pick<
                            ContractEnrollmentCount,
                            'activeCounts' | 'enrollmentCounts'
                          > & {
                              breakdown?: Maybe<
                                Array<
                                  Maybe<
                                    {
                                      __typename?: 'ContractTermEnrollmentCount';
                                    } & Pick<
                                      ContractTermEnrollmentCount,
                                      | 'contractTermId'
                                      | 'activeCounts'
                                      | 'enrollmentCounts'
                                    >
                                  >
                                >
                              >;
                            }
                        >;
                      } & ContractTermsByStatusFragment
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type GetCompanyContractsQueryVariables = Exact<{
  companySlug: Scalars['String'];
  status?: Maybe<Status>;
}>;

export type GetCompanyContractsQuery = { __typename?: 'PublicQuery' } & {
  companyBySlug?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        contracts?: Maybe<
          { __typename?: 'PaginatedContractPaginatedConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'PaginatedContractPaginatedEdge' } & {
                  node?: Maybe<
                    { __typename?: 'PaginatedContract' } & Pick<
                      PaginatedContract,
                      | 'companyId'
                      | 'consumedStuCount'
                      | 'createDate'
                      | 'demo'
                      | 'endDate'
                      | 'id'
                      | 'isStuContract'
                      | 'contractType'
                      | 'isSubscription'
                      | 'maxActiveSeats'
                      | 'startDate'
                      | 'status'
                      | 'stuContractGracePeriodInDays'
                      | 'stuCount'
                      | 'title'
                    > & {
                        enrollmentCounts?: Maybe<
                          { __typename?: 'ContractEnrollmentCount' } & Pick<
                            ContractEnrollmentCount,
                            'activeCounts' | 'enrollmentCounts'
                          > & {
                              breakdown?: Maybe<
                                Array<
                                  Maybe<
                                    {
                                      __typename?: 'ContractTermEnrollmentCount';
                                    } & Pick<
                                      ContractTermEnrollmentCount,
                                      | 'contractTermId'
                                      | 'activeCounts'
                                      | 'enrollmentCounts'
                                    >
                                  >
                                >
                              >;
                            }
                        >;
                        contractTerms?: Maybe<
                          {
                            __typename?: 'PaginatedContractTermPaginatedConnection';
                          } & Pick<
                            PaginatedContractTermPaginatedConnection,
                            'totalCount'
                          > & {
                              edges: Array<
                                Maybe<
                                  {
                                    __typename?: 'PaginatedContractTermPaginatedEdge';
                                  } & {
                                    node?: Maybe<
                                      {
                                        __typename?: 'PaginatedContractTerm';
                                      } & ContractTermFieldsFragment
                                    >;
                                  }
                                >
                              >;
                            }
                        >;
                      }
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type CompanyEnrollmentCohortOptionsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type CompanyEnrollmentCohortOptionsQuery = {
  __typename?: 'PublicQuery';
} & {
  enrollmentCohorts?: Maybe<
    { __typename?: 'EnrollmentCohortPaginatedConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'EnrollmentCohortPaginatedEdge' } & {
            node?: Maybe<
              { __typename?: 'EnrollmentCohort' } & Pick<
                EnrollmentCohort,
                'id' | 'name' | 'state' | 'cohortType'
              > & {
                  contractTerms?: Maybe<
                    Array<
                      { __typename?: 'ContractTerm' } & Pick<ContractTerm, 'id'>
                    >
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
};

export type GetCompanyGraphLearningPathIdsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type GetCompanyGraphLearningPathIdsQuery = {
  __typename?: 'PublicQuery';
} & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        companyGraphLearningPaths?: Maybe<
          { __typename?: 'CompanyGraphLearningPathPaginatedConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'CompanyGraphLearningPathPaginatedEdge' } & {
                  node?: Maybe<
                    { __typename?: 'CompanyGraphLearningPath' } & Pick<
                      CompanyGraphLearningPath,
                      'id' | 'learningPathId'
                    >
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type GetCompanyGraphLearningPathQueryVariables = Exact<{
  id: Scalars['ID'];
  isManager: Scalars['Boolean'];
}>;

export type GetCompanyGraphLearningPathQuery = {
  __typename?: 'PublicQuery';
} & {
  companyGraphLearningPath?: Maybe<
    { __typename?: 'CompanyGraphLearningPath' } & Pick<
      CompanyGraphLearningPath,
      | 'id'
      | 'companyId'
      | 'createDate'
      | 'deleteDate'
      | 'isAvailable'
      | 'learningPathId'
      | 'questionSetId'
      | 'updateDate'
    > & {
        assessmentCohort?: Maybe<
          { __typename?: 'AssessmentCohort' } & Pick<
            AssessmentCohort,
            'cohortKey'
          >
        >;
        learningPath?: Maybe<
          { __typename?: 'GraphLearningPath' } & GraphLearningPathFragment
        >;
        learners?: Maybe<
          { __typename?: 'LearnerPaginatedConnection' } & Pick<
            LearnerPaginatedConnection,
            'totalCount'
          >
        >;
        questionSet?: Maybe<
          { __typename?: 'QuestionSet' } & DefaultQuestionSetFieldsFragment
        >;
      }
  >;
};

export type GetCompanyGraphLearningPathsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  isManager: Scalars['Boolean'];
  shouldResolveIsEnrollable: Scalars['Boolean'];
}>;

export type GetCompanyGraphLearningPathsQuery = {
  __typename?: 'PublicQuery';
} & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        companyGraphLearningPaths?: Maybe<
          { __typename?: 'CompanyGraphLearningPathPaginatedConnection' } & Pick<
            CompanyGraphLearningPathPaginatedConnection,
            'totalCount'
          > & {
              edges: Array<
                Maybe<
                  { __typename?: 'CompanyGraphLearningPathPaginatedEdge' } & {
                    node?: Maybe<
                      {
                        __typename?: 'CompanyGraphLearningPath';
                      } & MakeOptional<
                        Pick<
                          CompanyGraphLearningPath,
                          | 'id'
                          | 'companyId'
                          | 'isAvailable'
                          | 'isEnrollable'
                          | 'questionSetId'
                        >,
                        'isEnrollable'
                      > & {
                          learningPath?: Maybe<
                            {
                              __typename?: 'GraphLearningPath';
                            } & GraphLearningPathFragment
                          >;
                          assessmentCohort?: Maybe<
                            { __typename?: 'AssessmentCohort' } & Pick<
                              AssessmentCohort,
                              'cohortKey'
                            >
                          >;
                          questionSet?: Maybe<
                            {
                              __typename?: 'QuestionSet';
                            } & DefaultQuestionSetFieldsFragment
                          >;
                        }
                    >;
                  }
                >
              >;
            }
        >;
      }
  >;
};

export type GetCompanyGroupsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
}>;

export type GetCompanyGroupsQuery = { __typename?: 'PublicQuery' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        groups?: Maybe<
          { __typename?: 'PaginatedGroupPaginatedConnection' } & Pick<
            PaginatedGroupPaginatedConnection,
            'totalCount'
          > & {
              edges: Array<
                Maybe<
                  { __typename?: 'PaginatedGroupPaginatedEdge' } & {
                    node?: Maybe<
                      { __typename?: 'PaginatedGroup' } & Pick<
                        PaginatedGroup,
                        | 'companyId'
                        | 'createDate'
                        | 'id'
                        | 'name'
                        | 'updateDate'
                      > & {
                          learners?: Maybe<
                            {
                              __typename?: 'LearnerPaginatedConnection';
                            } & Pick<LearnerPaginatedConnection, 'totalCount'>
                          >;
                        }
                    >;
                  }
                >
              >;
            }
        >;
      }
  >;
};

export type GetCompanyIdQueryVariables = Exact<{
  companySlug: Scalars['String'];
}>;

export type GetCompanyIdQuery = { __typename?: 'PublicQuery' } & {
  companyBySlug?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id' | 'name'>
  >;
};

export type GetCompanyLearnerInterestsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  selectionFilters?: Maybe<
    | Array<LearnerInterestBulkSelectionFilter>
    | LearnerInterestBulkSelectionFilter
  >;
  nominationTypes?: Maybe<Array<NominationType> | NominationType>;
  subjectIds?: Maybe<
    Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
  >;
  forCsv: Scalars['Boolean'];
  includeHistory: Scalars['Boolean'];
  contracted?: Maybe<Scalars['Boolean']>;
  programTypes?: Maybe<
    Array<LearnerInterestProgramType> | LearnerInterestProgramType
  >;
  questionsAnswered?: Maybe<Array<QuestionResponses> | QuestionResponses>;
}>;

export type GetCompanyLearnerInterestsQuery = { __typename?: 'PublicQuery' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        paginatedLearnerInterests?: Maybe<
          { __typename?: 'PaginatedLearnerInterestPaginatedConnection' } & Pick<
            PaginatedLearnerInterestPaginatedConnection,
            'totalCount'
          > & {
              edges: Array<
                Maybe<
                  { __typename?: 'PaginatedLearnerInterestPaginatedEdge' } & {
                    node?: Maybe<
                      {
                        __typename?: 'PaginatedLearnerInterest';
                      } & MakeOptional<
                        Pick<
                          PaginatedLearnerInterest,
                          | 'id'
                          | 'companyId'
                          | 'createDate'
                          | 'updateDate'
                          | 'deleteDate'
                          | 'submissionDate'
                          | 'programKey'
                          | 'programType'
                          | 'companyGraphLearningPathId'
                          | 'contentTitle'
                          | 'contentType'
                          | 'learnerId'
                          | 'customData'
                          | 'assessmentScore'
                          | 'assessmentReadiness'
                          | 'assessmentEligiblePrograms'
                          | 'status'
                          | 'eligibility'
                          | 'eligibilityReason'
                          | 'statusUpdatedAt'
                          | 'assessmentCohortKey'
                          | 'note'
                        >,
                        'assessmentReadiness' | 'assessmentEligiblePrograms'
                      > & {
                          program?: Maybe<
                            { __typename?: 'ProgramData' } & Pick<
                              ProgramData,
                              'title'
                            >
                          >;
                          companyGraphLearningPath?: Maybe<
                            { __typename?: 'CompanyGraphLearningPath' } & Pick<
                              CompanyGraphLearningPath,
                              'id'
                            > & {
                                learningPath?: Maybe<
                                  { __typename?: 'GraphLearningPath' } & Pick<
                                    GraphLearningPath,
                                    'id' | 'name'
                                  > & {
                                      nodes?: Maybe<
                                        Array<
                                          Maybe<
                                            | ({
                                                __typename?: 'LPCourseNode';
                                              } & Pick<LpCourseNode, 'label'>)
                                            | ({
                                                __typename?: 'LPNdNode';
                                              } & Pick<LpNdNode, 'label'>)
                                          >
                                        >
                                      >;
                                    }
                                >;
                              }
                          >;
                          assessmentResult?: Maybe<
                            { __typename?: 'V2AssessmentResult' } & Pick<
                              V2AssessmentResult,
                              'id'
                            > & {
                                assessment?: Maybe<
                                  { __typename?: 'Assessment' } & Pick<
                                    Assessment,
                                    'assessmentModel'
                                  >
                                >;
                                evaluationObject: {
                                  __typename?: 'V2Evaluation';
                                } & {
                                  subjects?: Maybe<
                                    Array<
                                      {
                                        __typename?: 'V2EvaluationSubject';
                                      } & Pick<
                                        V2EvaluationSubject,
                                        'subjectId' | 'mastery' | 'name'
                                      >
                                    >
                                  >;
                                  programs?: Maybe<
                                    Array<
                                      Maybe<
                                        {
                                          __typename?: 'V2EvaluationProgram';
                                        } & Pick<
                                          V2EvaluationProgram,
                                          | 'programKey'
                                          | 'preparedness'
                                          | 'preparednessThreshold'
                                        > & {
                                            program: {
                                              __typename?: 'ProgramData';
                                            } & Pick<ProgramData, 'title'>;
                                          }
                                      >
                                    >
                                  >;
                                };
                              }
                          >;
                          companyQuestionSetResponses?: Maybe<
                            Array<
                              Maybe<
                                {
                                  __typename?: 'AdmissionQuestionResponse';
                                } & Pick<
                                  AdmissionQuestionResponse,
                                  'response' | 'id'
                                > & {
                                    admissionQuestion?: Maybe<
                                      {
                                        __typename?: 'AdmissionQuestion';
                                      } & Pick<
                                        AdmissionQuestion,
                                        'label' | 'id' | 'correctAnswers'
                                      >
                                    >;
                                  }
                              >
                            >
                          >;
                          specificQuestionSet?: Maybe<
                            { __typename?: 'QuestionSet' } & {
                              admissionQuestions?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'AdmissionQuestion' } & Pick<
                                      AdmissionQuestion,
                                      'label' | 'type' | 'order'
                                    >
                                  >
                                >
                              >;
                            }
                          >;
                          specificQuestionSetResponses?: Maybe<
                            Array<
                              Maybe<
                                {
                                  __typename?: 'AdmissionQuestionResponse';
                                } & Pick<
                                  AdmissionQuestionResponse,
                                  'response' | 'id'
                                > & {
                                    admissionQuestion?: Maybe<
                                      {
                                        __typename?: 'AdmissionQuestion';
                                      } & Pick<
                                        AdmissionQuestion,
                                        'label' | 'id' | 'correctAnswers'
                                      >
                                    >;
                                  }
                              >
                            >
                          >;
                          nominations?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'Nomination' } & Pick<
                                  Nomination,
                                  'createDate'
                                > & {
                                    nominatorUser?: Maybe<
                                      { __typename?: 'User' } & Pick<
                                        User,
                                        'email' | 'firstName' | 'lastName'
                                      >
                                    >;
                                  }
                              >
                            >
                          >;
                          nominationQuestionSetResponses?: Maybe<
                            Array<
                              Maybe<
                                {
                                  __typename?: 'AdmissionQuestionResponse';
                                } & Pick<
                                  AdmissionQuestionResponse,
                                  'response' | 'id'
                                > & {
                                    admissionQuestion?: Maybe<
                                      {
                                        __typename?: 'AdmissionQuestion';
                                      } & Pick<
                                        AdmissionQuestion,
                                        'label' | 'id' | 'correctAnswers'
                                      >
                                    >;
                                  }
                              >
                            >
                          >;
                          enrollmentHistory?: Maybe<
                            Array<
                              { __typename?: 'RegistrarEnrollment' } & Pick<
                                RegistrarEnrollment,
                                | 'id'
                                | 'nodeKey'
                                | 'state'
                                | 'stateChangedAt'
                                | 'updatedAt'
                              >
                            >
                          >;
                          plagiarismHistory?: Maybe<
                            Array<
                              { __typename?: 'PlagiarismCase' } & Pick<
                                PlagiarismCase,
                                'ungradeableTag' | 'nodeKey'
                              >
                            >
                          >;
                          learner?: Maybe<
                            { __typename?: 'Learner' } & Pick<
                              Learner,
                              'id' | 'email' | 'userKey'
                            > & {
                                user?: Maybe<
                                  { __typename?: 'User' } & Pick<
                                    User,
                                    'firstName' | 'lastName' | 'email'
                                  >
                                >;
                                assessmentInviteCohorts?: Maybe<
                                  Array<
                                    Maybe<
                                      {
                                        __typename?: 'AssessmentCohort';
                                      } & Pick<AssessmentCohort, 'cohortKey'>
                                    >
                                  >
                                >;
                                assessmentLabSessions?: Maybe<
                                  Array<
                                    {
                                      __typename?: 'AssessmentLabSession';
                                    } & Pick<
                                      AssessmentLabSession,
                                      'cohortKey'
                                    > & {
                                        assessment: {
                                          __typename?: 'Assessment';
                                        } & Pick<Assessment, 'assessmentModel'>;
                                      }
                                  >
                                >;
                                nominationsReceived?: Maybe<
                                  Array<
                                    Maybe<
                                      { __typename?: 'Nomination' } & Pick<
                                        Nomination,
                                        'createDate' | 'nominationType'
                                      > & {
                                          nominatorUser?: Maybe<
                                            { __typename?: 'User' } & Pick<
                                              User,
                                              'email' | 'firstName' | 'lastName'
                                            >
                                          >;
                                        }
                                    >
                                  >
                                >;
                              }
                          >;
                          subjectScoresForIds?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'SubjectScore' } & Pick<
                                  SubjectScore,
                                  'mastery'
                                >
                              >
                            >
                          >;
                        }
                    >;
                  }
                >
              >;
            }
        >;
      }
  >;
};

export type CompanyLearnersQueryVariables = Exact<{
  companyId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  hasAssessmentResultAfter?: Maybe<Scalars['DateTime']>;
  groupId?: Maybe<Scalars['ID']>;
  learningPathId?: Maybe<Scalars['ID']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  selectionFilters?: Maybe<
    Array<LearnerBulkSelectionFilter> | LearnerBulkSelectionFilter
  >;
}>;

export type CompanyLearnersQuery = { __typename?: 'PublicQuery' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        learners?: Maybe<
          { __typename?: 'LearnerPaginatedConnection' } & Pick<
            LearnerPaginatedConnection,
            'totalCount'
          > & {
              edges: Array<
                Maybe<
                  { __typename?: 'LearnerPaginatedEdge' } & {
                    node?: Maybe<
                      { __typename?: 'Learner' } & FullLearnerFragment
                    >;
                  }
                >
              >;
            }
        >;
      }
  >;
};

export type GetCompanyNominationQuestionSetQueryVariables = Exact<{
  companyId: Scalars['ID'];
  isManager: Scalars['Boolean'];
}>;

export type GetCompanyNominationQuestionSetQuery = {
  __typename?: 'PublicQuery';
} & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        nominationQuestionSet?: Maybe<
          { __typename?: 'QuestionSet' } & DefaultQuestionSetFieldsFragment
        >;
      }
  >;
};

export type GetCompanyProgramQueryVariables = Exact<{
  companyId: Scalars['ID'];
  programKey: Scalars['String'];
  isManager: Scalars['Boolean'];
}>;

export type GetCompanyProgramQuery = { __typename?: 'PublicQuery' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        programs?: Maybe<
          { __typename?: 'CompanyProgramPaginatedConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'CompanyProgramPaginatedEdge' } & {
                  node?: Maybe<
                    { __typename?: 'CompanyProgram' } & Pick<
                      CompanyProgram,
                      | 'programKey'
                      | 'programType'
                      | 'questionSetId'
                      | 'assessmentCohortKey'
                      | 'isChallengeProgram'
                    > & {
                        questionSet?: Maybe<
                          {
                            __typename?: 'QuestionSet';
                          } & DefaultQuestionSetFieldsFragment
                        >;
                        certificationAssessment?: Maybe<
                          { __typename?: 'CertificationAssessment' } & Pick<
                            CertificationAssessment,
                            | 'omacAssessmentGroupId'
                            | 'assessmentCloseDate'
                            | 'maxDuration'
                            | 'minDuration'
                            | 'assessmentDuration'
                            | 'attempts'
                            | 'required'
                          >
                        >;
                      }
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type GetCompanyQuestionSetQueryVariables = Exact<{
  companyId: Scalars['ID'];
  isManager: Scalars['Boolean'];
}>;

export type GetCompanyQuestionSetQuery = { __typename?: 'PublicQuery' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        questionSet?: Maybe<
          { __typename?: 'QuestionSet' } & DefaultQuestionSetFieldsFragment
        >;
      }
  >;
};

export type GetCompanySelfServeTokensQueryVariables = Exact<{
  companySlug: Scalars['String'];
}>;

export type GetCompanySelfServeTokensQuery = { __typename?: 'PublicQuery' } & {
  companyBySlug?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        selfServeTokens?: Maybe<
          Array<
            Maybe<
              { __typename?: 'SelfServeToken' } & Pick<SelfServeToken, 'id'>
            >
          >
        >;
      }
  >;
};

export type GetCompanyStuCountsQueryVariables = Exact<{
  companySlug: Scalars['String'];
}>;

export type GetCompanyStuCountsQuery = { __typename?: 'PublicQuery' } & {
  companyBySlug?: Maybe<
    { __typename?: 'Company' } & Pick<
      Company,
      'id' | 'consumedStuCount' | 'totalStuCount' | 'pendingStuCount'
    >
  >;
};

export type GetCompanyTalentPlansQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type GetCompanyTalentPlansQuery = { __typename?: 'PublicQuery' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        talentPlans?: Maybe<
          { __typename?: 'TalentPlanPaginatedConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'TalentPlanPaginatedEdge' } & {
                  node?: Maybe<
                    { __typename?: 'TalentPlan' } & Pick<
                      TalentPlan,
                      'id' | 'createDate' | 'name'
                    > & {
                        createdByUser: { __typename?: 'User' } & Pick<
                          User,
                          'firstName' | 'lastName' | 'email'
                        >;
                        resource?: Maybe<
                          | ({
                              __typename?: 'TalentPlanDocumentResource';
                            } & Pick<TalentPlanDocumentResource, 'link'>)
                          | ({ __typename?: 'TalentPlanURLResource' } & Pick<
                              TalentPlanUrlResource,
                              'link'
                            >)
                        >;
                      }
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type GetCompanyQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type GetCompanyQuery = { __typename?: 'PublicQuery' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<
      Company,
      | 'companyTypeId'
      | 'country'
      | 'createDate'
      | 'createdBy'
      | 'description'
      | 'enableAutoUnenroll'
      | 'id'
      | 'isSso'
      | 'logoUrl'
      | 'name'
      | 'slug'
      | 'status'
      | 'updateDate'
    > & {
        termsOfUseVersions?: Maybe<
          { __typename?: 'TermsOfUseVersionPaginatedConnection' } & Pick<
            TermsOfUseVersionPaginatedConnection,
            'totalCount'
          > & {
              edges: Array<
                Maybe<
                  { __typename?: 'TermsOfUseVersionPaginatedEdge' } & {
                    node?: Maybe<
                      { __typename?: 'TermsOfUseVersion' } & Pick<
                        TermsOfUseVersion,
                        | 'id'
                        | 'contents'
                        | 'createDate'
                        | 'createdBy'
                        | 'updateDate'
                      >
                    >;
                  }
                >
              >;
            }
        >;
        companySettings?: Maybe<
          Array<
            { __typename?: 'CompanySettingObject' } & Pick<
              CompanySettingObject,
              | 'value'
              | 'valueType'
              | 'name'
              | 'settingId'
              | 'defaultValue'
              | 'companySettingId'
            >
          >
        >;
        catalogTokens?: Maybe<
          Array<
            { __typename?: 'CompanyCatalogToken' } & Pick<
              CompanyCatalogToken,
              'companyId' | 'token' | 'expiresAt'
            >
          >
        >;
        expiredContracts?: Maybe<
          { __typename?: 'PaginatedContractPaginatedConnection' } & Pick<
            PaginatedContractPaginatedConnection,
            'totalCount'
          >
        >;
        activeContracts?: Maybe<
          { __typename?: 'PaginatedContractPaginatedConnection' } & Pick<
            PaginatedContractPaginatedConnection,
            'totalCount'
          >
        >;
        draftContracts?: Maybe<
          { __typename?: 'PaginatedContractPaginatedConnection' } & Pick<
            PaginatedContractPaginatedConnection,
            'totalCount'
          >
        >;
        suspendedContracts?: Maybe<
          { __typename?: 'PaginatedContractPaginatedConnection' } & Pick<
            PaginatedContractPaginatedConnection,
            'totalCount'
          >
        >;
      }
  >;
};

export type GetContractTermQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetContractTermQuery = { __typename?: 'PublicQuery' } & {
  contractTerm?: Maybe<
    { __typename?: 'ContractTerm' } & Pick<
      ContractTerm,
      | 'contractId'
      | 'createDate'
      | 'description'
      | 'endDate'
      | 'enrollmentEndDate'
      | 'id'
      | 'startDate'
      | 'status'
      | 'title'
      | 'updateDate'
    > & {
        resource?: Maybe<
          { __typename?: 'Resource' } & Pick<
            Resource,
            'id' | 'data' | 'description' | 'version'
          >
        >;
        enrollmentCounts?: Maybe<
          { __typename?: 'ContractTermEnrollmentCount' } & Pick<
            ContractTermEnrollmentCount,
            'activeCounts' | 'enrollmentCounts'
          >
        >;
      }
  >;
};

export type GetContractQueryVariables = Exact<{
  contractId: Scalars['ID'];
}>;

export type GetContractQuery = { __typename?: 'PublicQuery' } & {
  contract?: Maybe<
    { __typename?: 'Contract' } & Pick<
      Contract,
      | 'companyId'
      | 'consumedStuCount'
      | 'demo'
      | 'endDate'
      | 'id'
      | 'isSubscription'
      | 'maxActiveSeats'
      | 'notes'
      | 'pendingStuCount'
      | 'startDate'
      | 'status'
      | 'title'
      | 'stuCount'
      | 'isStuContract'
      | 'contractType'
      | 'stuContractGracePeriodInDays'
      | 'strictManagement'
    > & {
        enrollmentCounts?: Maybe<
          { __typename?: 'ContractEnrollmentCount' } & Pick<
            ContractEnrollmentCount,
            'activeCounts' | 'enrollmentCounts'
          > & {
              breakdown?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'ContractTermEnrollmentCount' } & Pick<
                      ContractTermEnrollmentCount,
                      'contractTermId' | 'activeCounts' | 'enrollmentCounts'
                    >
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type GetCompanyContractableProgramsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type GetCompanyContractableProgramsQuery = {
  __typename?: 'PublicQuery';
} & {
  company?: Maybe<
    { __typename?: 'Company' } & {
      contractablePrograms?: Maybe<
        Array<
          { __typename?: 'ContractableProgram' } & Pick<
            ContractableProgram,
            'companyId' | 'programKey' | 'interestCount'
          > & {
              catalogProgram?: Maybe<
                { __typename?: 'CatalogProgram' } & Pick<
                  CatalogProgram,
                  'programKey' | 'programType'
                > & {
                    program: { __typename?: 'ProgramData' } & Pick<
                      ProgramData,
                      'title' | 'summary'
                    >;
                    tags?: Maybe<
                      Array<
                        { __typename?: 'CatalogTag' } & Pick<
                          CatalogTag,
                          'id' | 'category' | 'value' | 'colorScheme'
                        >
                      >
                    >;
                  }
              >;
            }
        >
      >;
    }
  >;
};

export type EnrollableContractTermsQueryVariables = Exact<{
  companyId: Scalars['ID'];
  isAuthorized: Scalars['Boolean'];
}>;

export type EnrollableContractTermsQuery = { __typename?: 'PublicQuery' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        contracts?: Maybe<
          { __typename?: 'PaginatedContractPaginatedConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'PaginatedContractPaginatedEdge' } & {
                  node?: Maybe<
                    { __typename?: 'PaginatedContract' } & Pick<
                      PaginatedContract,
                      | 'id'
                      | 'companyId'
                      | 'isStuContract'
                      | 'contractType'
                      | 'title'
                      | 'startDate'
                      | 'status'
                      | 'endDate'
                      | 'maxActiveSeats'
                      | 'stuCount'
                      | 'consumedStuCount'
                      | 'pendingStuCount'
                    > & {
                        enrollmentCounts?: Maybe<
                          { __typename?: 'ContractEnrollmentCount' } & Pick<
                            ContractEnrollmentCount,
                            'activeCounts'
                          >
                        >;
                        enrollableContractTerms?: Maybe<
                          Array<
                            { __typename?: 'ContractTerm' } & Pick<
                              ContractTerm,
                              | 'id'
                              | 'title'
                              | 'contractId'
                              | 'enrollmentEndDate'
                              | 'startDate'
                              | 'endDate'
                              | 'stuPricingOverride'
                            > & {
                                enrollmentCounts?: Maybe<
                                  {
                                    __typename?: 'ContractTermEnrollmentCount';
                                  } & Pick<
                                    ContractTermEnrollmentCount,
                                    'activeCounts'
                                  >
                                >;
                                resource?: Maybe<
                                  { __typename?: 'Resource' } & Pick<
                                    Resource,
                                    | 'data'
                                    | 'datatype'
                                    | 'id'
                                    | 'version'
                                    | 'durationOverride'
                                  > & {
                                      programUrn: {
                                        __typename?: 'ProgramURN';
                                      } & Pick<
                                        ProgramUrn,
                                        | 'programKey'
                                        | 'programLocale'
                                        | 'programMajor'
                                        | 'programType'
                                      > & {
                                          programDetails: {
                                            __typename?: 'ProgramDetails';
                                          } & Pick<ProgramDetails, 'title'>;
                                        };
                                      resourceData?: Maybe<
                                        { __typename?: 'ResourceData' } & Pick<
                                          ResourceData,
                                          | 'key'
                                          | 'defaultDurationInDays'
                                          | 'numRequiredProjects'
                                        >
                                      >;
                                    }
                                >;
                              }
                          >
                        >;
                      }
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type GetEnrollableResourcesQueryVariables = Exact<{
  companyId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  skipResourceData: Scalars['Boolean'];
}>;

export type GetEnrollableResourcesQuery = { __typename?: 'PublicQuery' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        resources?: Maybe<
          { __typename?: 'ResourcePaginatedConnection' } & Pick<
            ResourcePaginatedConnection,
            'totalCount'
          > & {
              edges: Array<
                Maybe<
                  { __typename?: 'ResourcePaginatedEdge' } & {
                    node?: Maybe<
                      { __typename?: 'Resource' } & Pick<
                        Resource,
                        | 'id'
                        | 'data'
                        | 'datatype'
                        | 'version'
                        | 'durationOverride'
                      > & {
                          programUrn: { __typename?: 'ProgramURN' } & Pick<
                            ProgramUrn,
                            'programKey'
                          > & {
                              programDetails: {
                                __typename?: 'ProgramDetails';
                              } & Pick<ProgramDetails, 'title'>;
                            };
                          resourceData?: Maybe<
                            { __typename?: 'ResourceData' } & Pick<
                              ResourceData,
                              'defaultDurationInDays' | 'numRequiredProjects'
                            >
                          >;
                          assessmentCohort?: Maybe<
                            { __typename?: 'AssessmentCohort' } & Pick<
                              AssessmentCohort,
                              'cohortKey'
                            >
                          >;
                          autoEnrollSetting?: Maybe<
                            { __typename?: 'CompanySettingAutoEnroll' } & Pick<
                              CompanySettingAutoEnroll,
                              | 'enabled'
                              | 'criteriaQuestionSetRequired'
                              | 'criteriaAssessmentResultRequired'
                              | 'criteriaAssessmentDefaultThreshold'
                              | 'criteriaAssessmentOverrideThreshold'
                              | 'criteriaEnrollmentLimitEnabled'
                              | 'criteriaEnrollmentLimitCount'
                              | 'version'
                            >
                          >;
                        }
                    >;
                  }
                >
              >;
            }
        >;
      }
  >;
};

export type GetEnrollmentCohortQueryVariables = Exact<{
  cohortId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  rosterFilters?: Maybe<RosterFilters>;
  isPlannedCohort: Scalars['Boolean'];
}>;

export type GetEnrollmentCohortQuery = { __typename?: 'PublicQuery' } & {
  enrollmentCohort?: Maybe<
    { __typename?: 'EnrollmentCohort' } & Pick<
      EnrollmentCohort,
      | 'id'
      | 'companyId'
      | 'name'
      | 'startDate'
      | 'endDate'
      | 'state'
      | 'cohortType'
      | 'certificateTemplateId'
      | 'serviceModelId'
    > & {
        contractTerms?: Maybe<
          Array<
            { __typename?: 'ContractTerm' } & Pick<ContractTerm, 'id'> & {
                resource?: Maybe<
                  { __typename?: 'Resource' } & Pick<
                    Resource,
                    'data' | 'datatype' | 'id'
                  > & {
                      programUrn: { __typename?: 'ProgramURN' } & Pick<
                        ProgramUrn,
                        'programKey' | 'programMajor' | 'programLocale'
                      > & {
                          programDetails: {
                            __typename?: 'ProgramDetails';
                          } & Pick<ProgramDetails, 'title'>;
                        };
                    }
                >;
                contract?: Maybe<
                  { __typename?: 'Contract' } & Pick<
                    Contract,
                    'id' | 'startDate' | 'endDate'
                  >
                >;
              }
          >
        >;
        learners?: Maybe<
          { __typename?: 'LearnerPaginatedConnection' } & Pick<
            LearnerPaginatedConnection,
            'totalCount'
          > & {
              edges: Array<
                Maybe<
                  { __typename?: 'LearnerPaginatedEdge' } & {
                    node?: Maybe<
                      { __typename?: 'Learner' } & Pick<
                        Learner,
                        'id' | 'email'
                      > & {
                          allEnrollments?: Maybe<
                            { __typename?: 'AllEnrollments' } & {
                              currentEnrollments?: Maybe<
                                Array<
                                  { __typename?: 'Enrollment' } & Pick<
                                    Enrollment,
                                    'contractTermId'
                                  >
                                >
                              >;
                            }
                          >;
                          user?: Maybe<
                            { __typename?: 'User' } & Pick<
                              User,
                              'firstName' | 'lastName' | 'email'
                            >
                          >;
                          roster?: Maybe<
                            Array<
                              { __typename?: 'Roster' } & Pick<
                                Roster,
                                | 'enrollmentId'
                                | 'contractTermId'
                                | 'enrollmentStatus'
                              > & {
                                  learnerActivity?: Maybe<
                                    { __typename?: 'LearnerActivity' } & Pick<
                                      LearnerActivity,
                                      | 'onTrackStatus'
                                      | 'projectsOverdue'
                                      | 'mostRecentProjectWasVeryLate'
                                      | 'daysInactive'
                                      | 'isBeyondEndDate'
                                      | 'mandatoryProjectsPassed'
                                      | 'lastSeen'
                                    >
                                  >;
                                  programInfo?: Maybe<
                                    { __typename?: 'ProgramInfo' } & Pick<
                                      ProgramInfo,
                                      | 'programKey'
                                      | 'programType'
                                      | 'programVersion'
                                      | 'contentLocale'
                                      | 'totalMandatoryProjects'
                                    >
                                  >;
                                }
                            >
                          >;
                          associations?: Maybe<
                            Array<
                              Maybe<
                                {
                                  __typename?: 'EnrollmentCohortLearnerContractTerm';
                                } & Pick<
                                  EnrollmentCohortLearnerContractTerm,
                                  'enrollmentCohortId' | 'contractTermId'
                                >
                              >
                            >
                          >;
                        }
                    >;
                  }
                >
              >;
            }
        >;
      }
  >;
};

export type GetGraphLearningPathEnrollmentStatsQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type GetGraphLearningPathEnrollmentStatsQuery = {
  __typename?: 'PublicQuery';
} & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id'> & {
        enrollableCompanyGraphLearningPaths?: Maybe<
          { __typename?: 'CompanyGraphLearningPathPaginatedConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'CompanyGraphLearningPathPaginatedEdge' } & {
                  node?: Maybe<
                    { __typename?: 'CompanyGraphLearningPath' } & Pick<
                      CompanyGraphLearningPath,
                      'id' | 'companyId'
                    > & {
                        learningPath?: Maybe<
                          { __typename?: 'GraphLearningPath' } & Pick<
                            GraphLearningPath,
                            'id' | 'name' | 'status'
                          > & {
                              nodes?: Maybe<
                                Array<
                                  Maybe<
                                    | ({ __typename?: 'LPCourseNode' } & Pick<
                                        LpCourseNode,
                                        'id' | 'label'
                                      >)
                                    | ({ __typename?: 'LPNdNode' } & Pick<
                                        LpNdNode,
                                        'id' | 'label'
                                      >)
                                  >
                                >
                              >;
                            }
                        >;
                        learners?: Maybe<
                          { __typename?: 'LearnerPaginatedConnection' } & {
                            edges: Array<
                              Maybe<
                                { __typename?: 'LearnerPaginatedEdge' } & {
                                  node?: Maybe<
                                    { __typename?: 'Learner' } & Pick<
                                      Learner,
                                      'id' | 'email'
                                    > & {
                                        allEnrollments?: Maybe<
                                          { __typename?: 'AllEnrollments' } & {
                                            currentEnrollments?: Maybe<
                                              Array<
                                                {
                                                  __typename?: 'Enrollment';
                                                } & Pick<
                                                  Enrollment,
                                                  | 'enrollmentStatus'
                                                  | 'programKey'
                                                  | 'programType'
                                                >
                                              >
                                            >;
                                            unenrollments?: Maybe<
                                              Array<
                                                {
                                                  __typename?: 'Enrollment';
                                                } & Pick<
                                                  Enrollment,
                                                  | 'enrollmentStatus'
                                                  | 'programKey'
                                                  | 'programType'
                                                >
                                              >
                                            >;
                                            graduations?: Maybe<
                                              Array<
                                                {
                                                  __typename?: 'Enrollment';
                                                } & Pick<
                                                  Enrollment,
                                                  | 'enrollmentStatus'
                                                  | 'programKey'
                                                  | 'programType'
                                                >
                                              >
                                            >;
                                          }
                                        >;
                                      }
                                  >;
                                }
                              >
                            >;
                          }
                        >;
                      }
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type GraphLearningPathOptionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GraphLearningPathOptionsQuery = { __typename?: 'PublicQuery' } & {
  graphLearningPaths?: Maybe<
    { __typename?: 'GraphLearningPathPaginatedConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'GraphLearningPathPaginatedEdge' } & {
            node?: Maybe<
              { __typename?: 'GraphLearningPath' } & {
                label: GraphLearningPath['name'];
                value: GraphLearningPath['id'];
              }
            >;
          }
        >
      >;
    }
  >;
};

export type GetGraphLearningPathQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetGraphLearningPathQuery = { __typename?: 'PublicQuery' } & {
  graphLearningPath?: Maybe<
    { __typename?: 'GraphLearningPath' } & {
      companyGraphLearningPaths?: Maybe<
        Array<
          Maybe<
            { __typename?: 'CompanyGraphLearningPath' } & Pick<
              CompanyGraphLearningPath,
              'id' | 'companyId'
            >
          >
        >
      >;
    } & GraphLearningPathFragment
  >;
};

export type GraphLearningPathsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  learningPathCollectionId?: Maybe<Scalars['ID']>;
  programKey?: Maybe<Scalars['String']>;
  programType?: Maybe<LearningPathNodeType>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Array<GraphLearningPathStatus> | GraphLearningPathStatus>;
}>;

export type GraphLearningPathsQuery = { __typename?: 'PublicQuery' } & {
  graphLearningPaths?: Maybe<
    { __typename?: 'GraphLearningPathPaginatedConnection' } & Pick<
      GraphLearningPathPaginatedConnection,
      'totalCount'
    > & {
        edges: Array<
          Maybe<
            { __typename?: 'GraphLearningPathPaginatedEdge' } & {
              node?: Maybe<
                { __typename?: 'GraphLearningPath' } & {
                  companyGraphLearningPaths?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'CompanyGraphLearningPath' } & Pick<
                          CompanyGraphLearningPath,
                          'id' | 'companyId'
                        >
                      >
                    >
                  >;
                } & GraphLearningPathFragment
              >;
            }
          >
        >;
      }
  >;
};

export type GetJitLinkQueryVariables = Exact<{
  companySlug: Scalars['String'];
  next: Scalars['String'];
}>;

export type GetJitLinkQuery = { __typename?: 'PublicQuery' } & Pick<
  PublicQuery,
  'companyAnnotatedSsoLink'
>;

export type LearnerCountQueryVariables = Exact<{
  companyId: Scalars['ID'];
  selectionFilters?: Maybe<
    Array<LearnerBulkSelectionFilter> | LearnerBulkSelectionFilter
  >;
  exclusionFilters?: Maybe<
    Array<LearnerBulkSelectionFilter> | LearnerBulkSelectionFilter
  >;
}>;

export type LearnerCountQuery = { __typename?: 'PublicQuery' } & Pick<
  PublicQuery,
  'learnerCount'
>;

export type LearnerIdsByUserKeysQueryVariables = Exact<{
  companyId: Scalars['ID'];
  userKeys: Scalars['String'];
}>;

export type LearnerIdsByUserKeysQuery = { __typename?: 'PublicQuery' } & Pick<
  PublicQuery,
  'learnerIdsByFilter'
>;

export type LearnerInterestsAndEnrollmentsQueryVariables = Exact<{
  userKey: Scalars['String'];
  companyId?: Maybe<Scalars['ID']>;
  includeEnrollments: Scalars['Boolean'];
}>;

export type LearnerInterestsAndEnrollmentsQuery = {
  __typename?: 'PublicQuery';
} & {
  learnersByUserKey?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Learner' } & Pick<Learner, 'id'> & {
            interests?: Maybe<
              Array<
                { __typename?: 'LearnerInterest' } & LearnerInterestFragment
              >
            >;
            applications?: Maybe<
              Array<
                { __typename?: 'LearnerInterestApplication' } & Pick<
                  LearnerInterestApplication,
                  | 'learnerId'
                  | 'programKey'
                  | 'companyGraphLearningPathId'
                  | 'eligibleActions'
                >
              >
            >;
            nominationsReceived?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Nomination' } & Pick<
                    Nomination,
                    'createDate'
                  > & {
                      nominatorUser?: Maybe<
                        { __typename?: 'User' } & Pick<
                          User,
                          'firstName' | 'lastName' | 'email'
                        >
                      >;
                    }
                >
              >
            >;
            allEnrollments?: Maybe<
              { __typename?: 'AllEnrollments' } & {
                currentEnrollments?: Maybe<
                  Array<
                    { __typename?: 'Enrollment' } & FullEnrollmentFieldsFragment
                  >
                >;
                unenrollments?: Maybe<
                  Array<
                    { __typename?: 'Enrollment' } & FullEnrollmentFieldsFragment
                  >
                >;
                graduations?: Maybe<
                  Array<
                    { __typename?: 'Enrollment' } & FullEnrollmentFieldsFragment
                  >
                >;
              }
            >;
          }
      >
    >
  >;
};

export type LearnerInterestsQueryVariables = Exact<{
  userKey: Scalars['String'];
  companyId?: Maybe<Scalars['ID']>;
}>;

export type LearnerInterestsQuery = { __typename?: 'PublicQuery' } & {
  learnersByUserKey?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Learner' } & Pick<Learner, 'id'> & {
            interests?: Maybe<
              Array<
                { __typename?: 'LearnerInterest' } & LearnerInterestFragment
              >
            >;
          }
      >
    >
  >;
};

export type LearnerQueryVariables = Exact<{
  userKey: Scalars['String'];
  companyId?: Maybe<Scalars['ID']>;
  isManager: Scalars['Boolean'];
}>;

export type LearnerQuery = { __typename?: 'PublicQuery' } & {
  learnersByUserKey?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Learner' } & Pick<
          Learner,
          'id' | 'companyId' | 'createDate' | 'userKey' | 'employeeId' | 'email'
        > & {
            allEnrollments?: Maybe<
              { __typename?: 'AllEnrollments' } & {
                currentEnrollments?: Maybe<
                  Array<
                    { __typename?: 'Enrollment' } & FullEnrollmentFieldsFragment
                  >
                >;
                unenrollments?: Maybe<
                  Array<
                    { __typename?: 'Enrollment' } & FullEnrollmentFieldsFragment
                  >
                >;
                graduations?: Maybe<
                  Array<
                    { __typename?: 'Enrollment' } & FullEnrollmentFieldsFragment
                  >
                >;
              }
            >;
            user?: Maybe<
              { __typename?: 'User' } & Pick<
                User,
                'firstName' | 'lastName' | 'email'
              >
            >;
            projectSkills?: Maybe<
              Array<
                { __typename?: 'ProjectSkill' } & Pick<
                  ProjectSkill,
                  | 'skillName'
                  | 'skillId'
                  | 'subjectId'
                  | 'subjectName'
                  | 'projectIds'
                >
              >
            >;
            projects?: Maybe<
              Array<
                { __typename?: 'Project' } & Pick<
                  Project,
                  | 'projectName'
                  | 'isOptional'
                  | 'projectId'
                  | 'programKey'
                  | 'programType'
                >
              >
            >;
            groups?: Maybe<
              Array<
                Maybe<{ __typename?: 'Group' } & Pick<Group, 'id' | 'name'>>
              >
            >;
            glpAssociations?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'LearnerGraphLearningPath' } & {
                    companyGraphLearningPath?: Maybe<
                      { __typename?: 'CompanyGraphLearningPath' } & Pick<
                        CompanyGraphLearningPath,
                        'id'
                      > & {
                          learningPath?: Maybe<
                            {
                              __typename?: 'GraphLearningPath';
                            } & GraphLearningPathFragment
                          >;
                        }
                    >;
                  }
                >
              >
            >;
            assessmentInvites?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'AssessmentInvite' } & Pick<
                    AssessmentInvite,
                    'createDate'
                  > & {
                      assessmentCohort: {
                        __typename?: 'AssessmentCohort';
                      } & Pick<
                        AssessmentCohort,
                        | 'cohortKey'
                        | 'createDate'
                        | 'archiveDate'
                        | 'deleteDate'
                      > & {
                          assessment?: Maybe<
                            { __typename?: 'Assessment' } & Pick<
                              Assessment,
                              'id' | 'label'
                            >
                          >;
                        };
                    }
                >
              >
            >;
            assessmentLabSessions?: Maybe<
              Array<
                { __typename?: 'AssessmentLabSession' } & Pick<
                  AssessmentLabSession,
                  | 'id'
                  | 'createDate'
                  | 'cohortKey'
                  | 'sessionId'
                  | 'programPreference'
                > & {
                    selfReportedSubjects?: Maybe<
                      Array<
                        { __typename?: 'SelfReportedSubjects' } & Pick<
                          SelfReportedSubjects,
                          'subjectId' | 'subjectName' | 'selfReportedMastery'
                        >
                      >
                    >;
                    assessment: { __typename?: 'Assessment' } & Pick<
                      Assessment,
                      | 'id'
                      | 'label'
                      | 'showsScore'
                      | 'programKeys'
                      | 'maxRetakes'
                      | 'assessmentModel'
                    > & {
                        companyGraphLearningPath?: Maybe<
                          { __typename?: 'CompanyGraphLearningPath' } & {
                            learningPath?: Maybe<
                              { __typename?: 'GraphLearningPath' } & {
                                nodes?: Maybe<
                                  Array<
                                    Maybe<
                                      | ({ __typename?: 'LPCourseNode' } & Pick<
                                          LpCourseNode,
                                          'label'
                                        >)
                                      | ({ __typename?: 'LPNdNode' } & Pick<
                                          LpNdNode,
                                          'label'
                                        >)
                                    >
                                  >
                                >;
                              }
                            >;
                          }
                        >;
                      };
                    assessmentCohort: {
                      __typename?: 'AssessmentCohort';
                    } & Pick<
                      AssessmentCohort,
                      'createDate' | 'archiveDate' | 'deleteDate'
                    >;
                    v2AssessmentResult?: Maybe<
                      { __typename?: 'V2AssessmentResult' } & Pick<
                        V2AssessmentResult,
                        'id' | 'createDate'
                      > & {
                          assessment?: Maybe<
                            { __typename?: 'Assessment' } & Pick<
                              Assessment,
                              'id'
                            >
                          >;
                          evaluationObject: { __typename?: 'V2Evaluation' } & {
                            programs?: Maybe<
                              Array<
                                Maybe<
                                  { __typename?: 'V2EvaluationProgram' } & Pick<
                                    V2EvaluationProgram,
                                    | 'programKey'
                                    | 'preparedness'
                                    | 'preparednessThreshold'
                                  > & {
                                      subjects: Array<
                                        {
                                          __typename?: 'V2EvaluationSubject';
                                        } & Pick<
                                          V2EvaluationSubject,
                                          | 'subjectId'
                                          | 'name'
                                          | 'questionsAnswered'
                                          | 'questionsCorrect'
                                        >
                                      >;
                                      program: {
                                        __typename?: 'ProgramData';
                                      } & Pick<ProgramData, 'title'>;
                                    }
                                >
                              >
                            >;
                            subjects?: Maybe<
                              Array<
                                { __typename?: 'V2EvaluationSubject' } & Pick<
                                  V2EvaluationSubject,
                                  | 'subjectId'
                                  | 'questionsAnswered'
                                  | 'questionsCorrect'
                                >
                              >
                            >;
                          };
                        }
                    >;
                  }
              >
            >;
            interests?: Maybe<
              Array<
                { __typename?: 'LearnerInterest' } & Pick<
                  LearnerInterest,
                  | 'learnerId'
                  | 'programKey'
                  | 'programType'
                  | 'contractTermId'
                  | 'id'
                  | 'status'
                  | 'updateDate'
                  | 'createDate'
                  | 'companyId'
                  | 'companyGraphLearningPathId'
                  | 'assessmentCohortKey'
                > & {
                    program?: Maybe<
                      { __typename?: 'ProgramData' } & Pick<
                        ProgramData,
                        'title'
                      >
                    >;
                    companyGraphLearningPath?: Maybe<
                      { __typename?: 'CompanyGraphLearningPath' } & Pick<
                        CompanyGraphLearningPath,
                        'id'
                      > & {
                          learningPath?: Maybe<
                            { __typename?: 'GraphLearningPath' } & Pick<
                              GraphLearningPath,
                              'id' | 'name' | 'description'
                            >
                          >;
                        }
                    >;
                    specificQuestionSet?: Maybe<
                      {
                        __typename?: 'QuestionSet';
                      } & DefaultQuestionSetFieldsFragment
                    >;
                    companyQuestionSetResponses?: Maybe<
                      Array<
                        Maybe<
                          {
                            __typename?: 'AdmissionQuestionResponse';
                          } & AdmissionsResponseFragment
                        >
                      >
                    >;
                    specificQuestionSetResponses?: Maybe<
                      Array<
                        Maybe<
                          {
                            __typename?: 'AdmissionQuestionResponse';
                          } & AdmissionsResponseFragment
                        >
                      >
                    >;
                    nominationQuestionSetResponses?: Maybe<
                      Array<
                        Maybe<
                          {
                            __typename?: 'AdmissionQuestionResponse';
                          } & AdmissionsResponseFragment
                        >
                      >
                    >;
                    nominations?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'Nomination' } & Pick<
                            Nomination,
                            'createDate'
                          > & {
                              nominatorUser?: Maybe<
                                { __typename?: 'User' } & Pick<
                                  User,
                                  'firstName' | 'lastName' | 'email'
                                >
                              >;
                            }
                        >
                      >
                    >;
                  }
              >
            >;
            roster?: Maybe<
              Array<
                { __typename?: 'Roster' } & Pick<
                  Roster,
                  | 'enrollmentId'
                  | 'contractTermId'
                  | 'consumedStuCount'
                  | 'pendingStuCount'
                  | 'enrollmentStatus'
                  | 'userKey'
                > & {
                    learnerActivity?: Maybe<
                      { __typename?: 'LearnerActivity' } & Pick<
                        LearnerActivity,
                        | 'enrolledAt'
                        | 'unenrolledAt'
                        | 'graduatedAt'
                        | 'estimatedEndDate'
                        | 'conceptsViewed'
                        | 'questionsAnswered'
                        | 'lastSeen'
                        | 'mandatoryProjectsPassed'
                        | 'onTrackStatus'
                        | 'projectsOverdue'
                        | 'daysInactive'
                        | 'mostRecentProjectWasVeryLate'
                        | 'isBeyondEndDate'
                      > & {
                          submissions?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'Submission' } & Pick<
                                  Submission,
                                  | 'attemptCount'
                                  | 'submissionDate'
                                  | 'projectName'
                                  | 'isOptional'
                                  | 'projectOrder'
                                  | 'submissionId'
                                  | 'submissionDeadline'
                                  | 'status'
                                  | 'projectId'
                                >
                              >
                            >
                          >;
                        }
                    >;
                    programInfo?: Maybe<
                      { __typename?: 'ProgramInfo' } & Pick<
                        ProgramInfo,
                        | 'contentLocale'
                        | 'programVersion'
                        | 'programKey'
                        | 'programType'
                        | 'totalMandatoryProjects'
                        | 'totalConcepts'
                        | 'totalQuestions'
                      >
                    >;
                  }
              >
            >;
            nominationsReceived?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Nomination' } & Pick<
                    Nomination,
                    'createDate' | 'nominationType' | 'learnerInterestId'
                  > & {
                      nominatorUser?: Maybe<
                        { __typename?: 'User' } & Pick<
                          User,
                          'firstName' | 'lastName' | 'email'
                        >
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetLearningPathCollectionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetLearningPathCollectionsQuery = { __typename?: 'PublicQuery' } & {
  graphLearningPathCollections?: Maybe<
    { __typename?: 'GraphLearningPathCollectionPaginatedConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'GraphLearningPathCollectionPaginatedEdge' } & {
            node?: Maybe<
              { __typename?: 'GraphLearningPathCollection' } & Pick<
                GraphLearningPathCollection,
                'id' | 'name'
              > & {
                  learningPaths?: Maybe<
                    { __typename?: 'GraphLearningPathPaginatedConnection' } & {
                      edges: Array<
                        Maybe<
                          { __typename?: 'GraphLearningPathPaginatedEdge' } & {
                            node?: Maybe<
                              { __typename?: 'GraphLearningPath' } & Pick<
                                GraphLearningPath,
                                'id' | 'name'
                              >
                            >;
                          }
                        >
                      >;
                    }
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
};

export type MyLearningPathsQueryVariables = Exact<{
  userKey: Scalars['String'];
}>;

export type MyLearningPathsQuery = { __typename?: 'PublicQuery' } & {
  learnersByUserKey?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Learner' } & Pick<Learner, 'id' | 'email'> & {
            company?: Maybe<
              { __typename?: 'Company' } & Pick<
                Company,
                'id' | 'name' | 'slug'
              > & {
                  companySettings?: Maybe<
                    Array<
                      { __typename?: 'CompanySettingObject' } & Pick<
                        CompanySettingObject,
                        'value' | 'valueType' | 'name' | 'settingId'
                      >
                    >
                  >;
                }
            >;
            allEnrollments?: Maybe<
              { __typename?: 'AllEnrollments' } & {
                currentEnrollments?: Maybe<
                  Array<
                    { __typename?: 'Enrollment' } & Pick<
                      Enrollment,
                      'programKey' | 'enrollmentStatus'
                    >
                  >
                >;
                unenrollments?: Maybe<
                  Array<
                    { __typename?: 'Enrollment' } & Pick<
                      Enrollment,
                      'programKey' | 'enrollmentStatus'
                    >
                  >
                >;
                graduations?: Maybe<
                  Array<
                    { __typename?: 'Enrollment' } & Pick<
                      Enrollment,
                      'programKey' | 'enrollmentStatus'
                    >
                  >
                >;
              }
            >;
            interests?: Maybe<
              Array<
                { __typename?: 'LearnerInterest' } & Pick<
                  LearnerInterest,
                  'companyId' | 'programKey' | 'status'
                >
              >
            >;
            glpAssociations?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'LearnerGraphLearningPath' } & {
                    companyGraphLearningPath?: Maybe<
                      { __typename?: 'CompanyGraphLearningPath' } & Pick<
                        CompanyGraphLearningPath,
                        'id' | 'companyId'
                      > & {
                          learningPath?: Maybe<
                            {
                              __typename?: 'GraphLearningPath';
                            } & GraphLearningPathFragment
                          >;
                        }
                    >;
                  }
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetQuestionSetsQueryVariables = Exact<{
  isManager: Scalars['Boolean'];
}>;

export type GetQuestionSetsQuery = { __typename?: 'PublicQuery' } & {
  questionSets?: Maybe<
    { __typename?: 'QuestionSetPaginatedConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'QuestionSetPaginatedEdge' } & {
            node?: Maybe<
              { __typename?: 'QuestionSet' } & DefaultQuestionSetFieldsFragment
            >;
          }
        >
      >;
    }
  >;
};

export type GetResourceByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetResourceByIdQuery = { __typename?: 'PublicQuery' } & {
  resource?: Maybe<
    { __typename?: 'Resource' } & Pick<
      Resource,
      'data' | 'id' | 'version' | 'durationOverride'
    > & {
        resourceData?: Maybe<
          { __typename?: 'ResourceData' } & Pick<
            ResourceData,
            'defaultDurationInDays' | 'numRequiredProjects'
          >
        >;
        programUrn: { __typename?: 'ProgramURN' } & Pick<
          ProgramUrn,
          'programKey'
        > & {
            programDetails: { __typename?: 'ProgramDetails' } & Pick<
              ProgramDetails,
              'title'
            >;
          };
        assessmentCohort?: Maybe<
          { __typename?: 'AssessmentCohort' } & Pick<
            AssessmentCohort,
            'cohortKey'
          >
        >;
        cohorts?: Maybe<
          Array<
            { __typename?: 'Cohort' } & Pick<
              Cohort,
              'name' | 'id' | 'cohortId' | 'endsAt' | 'startsAt'
            >
          >
        >;
        autoEnrollSetting?: Maybe<
          { __typename?: 'CompanySettingAutoEnroll' } & Pick<
            CompanySettingAutoEnroll,
            | 'enabled'
            | 'criteriaQuestionSetRequired'
            | 'criteriaAssessmentResultRequired'
            | 'criteriaAssessmentDefaultThreshold'
            | 'criteriaAssessmentOverrideThreshold'
            | 'criteriaEnrollmentLimitEnabled'
            | 'criteriaEnrollmentLimitCount'
            | 'version'
          >
        >;
      }
  >;
};

export type SeatUtilizationQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type SeatUtilizationQuery = { __typename?: 'PublicQuery' } & {
  company?: Maybe<
    { __typename?: 'Company' } & {
      activeContracts?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Contract' } & Pick<
              Contract,
              | 'isStuContract'
              | 'contractType'
              | 'startDate'
              | 'demo'
              | 'maxActiveSeats'
            > & {
                enrollmentCounts?: Maybe<
                  { __typename?: 'ContractEnrollmentCount' } & Pick<
                    ContractEnrollmentCount,
                    'activeCounts'
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type SettingsQueryVariables = Exact<{ [key: string]: never }>;

export type SettingsQuery = { __typename?: 'PublicQuery' } & {
  allSettings?: Maybe<
    Array<Maybe<{ __typename?: 'Setting' } & SettingFragment>>
  >;
};

export type GetTermResourcesForContractQueryVariables = Exact<{
  contractId: Scalars['ID'];
}>;

export type GetTermResourcesForContractQuery = {
  __typename?: 'PublicQuery';
} & {
  contract?: Maybe<
    { __typename?: 'Contract' } & {
      contractTerms?: Maybe<
        { __typename?: 'PaginatedContractTermPaginatedConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'PaginatedContractTermPaginatedEdge' } & {
                node?: Maybe<
                  { __typename?: 'PaginatedContractTerm' } & Pick<
                    PaginatedContractTerm,
                    'title' | 'id' | 'status' | 'stuPricingOverride'
                  > & {
                      resource?: Maybe<
                        { __typename?: 'Resource' } & Pick<
                          Resource,
                          'data' | 'datatype' | 'id' | 'version'
                        >
                      >;
                    }
                >;
              }
            >
          >;
        }
      >;
    }
  >;
};

export type GetUserAdmissionsWindowQueryVariables = Exact<{
  companyId: Scalars['ID'];
  userKey: Scalars['String'];
}>;

export type GetUserAdmissionsWindowQuery = { __typename?: 'PublicQuery' } & {
  learnersByUserKey?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Learner' } & Pick<Learner, 'id'> & {
            admissionsWindow: { __typename?: 'LearnerAdmissionsWindow' } & Pick<
              LearnerAdmissionsWindow,
              'hasReachedApplicationsLimit'
            >;
          }
      >
    >
  >;
};

export type HandleGraphPathLearnerAssignmentsMutationVariables = Exact<{
  id: Scalars['ID'];
  learnerIdsToAdd?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  learnerIdsToRemove?: Maybe<
    Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>
  >;
}>;

export type HandleGraphPathLearnerAssignmentsMutation = {
  __typename?: 'PublicMutation';
} & {
  handleLearnerGlpAssociations?: Maybe<
    { __typename?: 'HandleLearnerGLPAssociations' } & {
      companyGraphLearningPath?: Maybe<
        { __typename?: 'CompanyGraphLearningPath' } & Pick<
          CompanyGraphLearningPath,
          'id'
        > & {
            learners?: Maybe<
              { __typename?: 'LearnerPaginatedConnection' } & Pick<
                LearnerPaginatedConnection,
                'totalCount'
              > & {
                  edges: Array<
                    Maybe<
                      { __typename?: 'LearnerPaginatedEdge' } & {
                        node?: Maybe<
                          { __typename?: 'Learner' } & Pick<Learner, 'id'> & {
                              glpAssociations?: Maybe<
                                Array<
                                  Maybe<
                                    {
                                      __typename?: 'LearnerGraphLearningPath';
                                    } & Pick<LearnerGraphLearningPath, 'id'> & {
                                        companyGraphLearningPath?: Maybe<
                                          {
                                            __typename?: 'CompanyGraphLearningPath';
                                          } & Pick<
                                            CompanyGraphLearningPath,
                                            'id'
                                          > & {
                                              learningPath?: Maybe<
                                                {
                                                  __typename?: 'GraphLearningPath';
                                                } & Pick<
                                                  GraphLearningPath,
                                                  'id' | 'name'
                                                > & {
                                                    edges?: Maybe<
                                                      Array<
                                                        Maybe<
                                                          {
                                                            __typename?: 'LPEdge';
                                                          } & Pick<
                                                            LpEdge,
                                                            | 'id'
                                                            | 'parentNodeId'
                                                            | 'childNodeId'
                                                          >
                                                        >
                                                      >
                                                    >;
                                                    nodes?: Maybe<
                                                      Array<
                                                        Maybe<
                                                          | ({
                                                              __typename?: 'LPCourseNode';
                                                            } & Pick<
                                                              LpCourseNode,
                                                              'id' | 'label'
                                                            >)
                                                          | ({
                                                              __typename?: 'LPNdNode';
                                                            } & Pick<
                                                              LpNdNode,
                                                              'id' | 'label'
                                                            >)
                                                        >
                                                      >
                                                    >;
                                                  }
                                              >;
                                            }
                                        >;
                                      }
                                  >
                                >
                              >;
                            }
                        >;
                      }
                    >
                  >;
                }
            >;
          }
      >;
    }
  >;
};

export type HandlePathCollectionItemsMutationVariables = Exact<{
  collectionId: Scalars['ID'];
  learningPathIdsToAdd?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  learningPathIdsToRemove?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type HandlePathCollectionItemsMutation = {
  __typename?: 'PublicMutation';
} & {
  handleGlpCollectionItems?: Maybe<
    { __typename?: 'HandleGLPCollectionItems' } & {
      learningPathCollection?: Maybe<
        { __typename?: 'GraphLearningPathCollection' } & Pick<
          GraphLearningPathCollection,
          'id' | 'name'
        > & {
            learningPaths?: Maybe<
              { __typename?: 'GraphLearningPathPaginatedConnection' } & {
                edges: Array<
                  Maybe<
                    { __typename?: 'GraphLearningPathPaginatedEdge' } & {
                      node?: Maybe<
                        { __typename?: 'GraphLearningPath' } & Pick<
                          GraphLearningPath,
                          'id' | 'name'
                        >
                      >;
                    }
                  >
                >;
              }
            >;
          }
      >;
    }
  >;
};

export type HydrateCompanyCatalogPageQueryVariables = Exact<{
  companySlug: Scalars['String'];
  companyId: Scalars['ID'];
  isManager: Scalars['Boolean'];
  userKey: Scalars['String'];
  isNotLoggedIn: Scalars['Boolean'];
}>;

export type HydrateCompanyCatalogPageQuery = { __typename?: 'PublicQuery' } & {
  learnersByUserKey?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Learner' } & Pick<
          Learner,
          'id' | 'companyId' | 'userKey' | 'email'
        > & {
            roles?: Maybe<Array<{ __typename?: 'Role' } & Pick<Role, 'name'>>>;
            allEnrollments?: Maybe<
              { __typename?: 'AllEnrollments' } & {
                currentEnrollments?: Maybe<
                  Array<
                    { __typename?: 'Enrollment' } & FullEnrollmentFieldsFragment
                  >
                >;
                unenrollments?: Maybe<
                  Array<
                    { __typename?: 'Enrollment' } & FullEnrollmentFieldsFragment
                  >
                >;
                graduations?: Maybe<
                  Array<
                    { __typename?: 'Enrollment' } & FullEnrollmentFieldsFragment
                  >
                >;
              }
            >;
            user?: Maybe<
              { __typename?: 'User' } & Pick<
                User,
                'firstName' | 'lastName' | 'email'
              >
            >;
            glpAssociations?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'LearnerGraphLearningPath' } & {
                    companyGraphLearningPath?: Maybe<
                      { __typename?: 'CompanyGraphLearningPath' } & Pick<
                        CompanyGraphLearningPath,
                        'id'
                      > & {
                          learningPath?: Maybe<
                            {
                              __typename?: 'GraphLearningPath';
                            } & GraphLearningPathFragment
                          >;
                        }
                    >;
                  }
                >
              >
            >;
            applications?: Maybe<
              Array<
                { __typename?: 'LearnerInterestApplication' } & Pick<
                  LearnerInterestApplication,
                  | 'learnerId'
                  | 'programKey'
                  | 'companyGraphLearningPathId'
                  | 'eligibleActions'
                >
              >
            >;
            admissionsWindow: { __typename?: 'LearnerAdmissionsWindow' } & Pick<
              LearnerAdmissionsWindow,
              'hasReachedApplicationsLimit'
            >;
            interests?: Maybe<
              Array<
                { __typename?: 'LearnerInterest' } & Pick<
                  LearnerInterest,
                  | 'learnerId'
                  | 'createDate'
                  | 'updateDate'
                  | 'programKey'
                  | 'programType'
                  | 'contractTermId'
                  | 'status'
                  | 'id'
                  | 'companyId'
                  | 'companyGraphLearningPathId'
                > & {
                    companyGraphLearningPath?: Maybe<
                      { __typename?: 'CompanyGraphLearningPath' } & Pick<
                        CompanyGraphLearningPath,
                        'id'
                      > & {
                          learningPath?: Maybe<
                            { __typename?: 'GraphLearningPath' } & Pick<
                              GraphLearningPath,
                              'id' | 'name'
                            >
                          >;
                        }
                    >;
                    companyQuestionSetResponses?: Maybe<
                      Array<
                        Maybe<
                          {
                            __typename?: 'AdmissionQuestionResponse';
                          } & AdmissionsResponseFragment
                        >
                      >
                    >;
                    specificQuestionSetResponses?: Maybe<
                      Array<
                        Maybe<
                          {
                            __typename?: 'AdmissionQuestionResponse';
                          } & AdmissionsResponseFragment
                        >
                      >
                    >;
                    nominations?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'Nomination' } & Pick<
                            Nomination,
                            'createDate'
                          > & {
                              nominatorUser?: Maybe<
                                { __typename?: 'User' } & Pick<
                                  User,
                                  'firstName' | 'lastName' | 'email'
                                >
                              >;
                            }
                        >
                      >
                    >;
                  }
              >
            >;
          }
      >
    >
  >;
  companyBySlug?: Maybe<
    { __typename?: 'Company' } & Pick<
      Company,
      'id' | 'slug' | 'name' | 'logoUrl' | 'questionSetId'
    > & {
        companySettings?: Maybe<
          Array<
            { __typename?: 'CompanySettingObject' } & Pick<
              CompanySettingObject,
              'value' | 'valueType' | 'name' | 'settingId'
            >
          >
        >;
        questionSet?: Maybe<
          { __typename?: 'QuestionSet' } & DefaultQuestionSetFieldsFragment
        >;
        enrollableCompanyGraphLearningPaths?: Maybe<
          { __typename?: 'CompanyGraphLearningPathPaginatedConnection' } & Pick<
            CompanyGraphLearningPathPaginatedConnection,
            'totalCount'
          > & {
              edges: Array<
                Maybe<
                  { __typename?: 'CompanyGraphLearningPathPaginatedEdge' } & {
                    node?: Maybe<
                      { __typename?: 'CompanyGraphLearningPath' } & Pick<
                        CompanyGraphLearningPath,
                        'id' | 'companyId'
                      > & {
                          learningPath?: Maybe<
                            {
                              __typename?: 'GraphLearningPath';
                            } & GraphLearningPathFragment
                          >;
                        }
                    >;
                  }
                >
              >;
            }
        >;
        catalogTokens?: Maybe<
          Array<
            { __typename?: 'CompanyCatalogToken' } & Pick<
              CompanyCatalogToken,
              'token'
            >
          >
        >;
        contracts?: Maybe<
          { __typename?: 'PaginatedContractPaginatedConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'PaginatedContractPaginatedEdge' } & {
                  node?: Maybe<
                    { __typename?: 'PaginatedContract' } & Pick<
                      PaginatedContract,
                      'id'
                    > & {
                        enrollableContractTerms?: Maybe<
                          Array<
                            { __typename?: 'ContractTerm' } & Pick<
                              ContractTerm,
                              'id' | 'contractId'
                            > & {
                                resource?: Maybe<
                                  { __typename?: 'Resource' } & Pick<
                                    Resource,
                                    | 'data'
                                    | 'datatype'
                                    | 'id'
                                    | 'version'
                                    | 'durationOverride'
                                  > & {
                                      resourceData?: Maybe<
                                        { __typename?: 'ResourceData' } & Pick<
                                          ResourceData,
                                          | 'defaultDurationInDays'
                                          | 'numRequiredProjects'
                                          | 'key'
                                          | 'heroImageUrl'
                                        >
                                      >;
                                      programUrn: {
                                        __typename?: 'ProgramURN';
                                      } & Pick<
                                        ProgramUrn,
                                        'programKey' | 'programType'
                                      > & {
                                          programDetails: {
                                            __typename?: 'ProgramDetails';
                                          } & Pick<
                                            ProgramDetails,
                                            'title' | 'summary'
                                          >;
                                        };
                                      catalogProgram?: Maybe<
                                        {
                                          __typename?: 'CatalogProgram';
                                        } & Pick<
                                          CatalogProgram,
                                          | 'programKey'
                                          | 'programType'
                                          | 'catalogUrl'
                                          | 'isStandard'
                                          | 'companyCatalogSection'
                                        > & {
                                            tags?: Maybe<
                                              Array<
                                                {
                                                  __typename?: 'CatalogTag';
                                                } & Pick<
                                                  CatalogTag,
                                                  | 'id'
                                                  | 'category'
                                                  | 'value'
                                                  | 'colorScheme'
                                                >
                                              >
                                            >;
                                          }
                                      >;
                                    }
                                >;
                              }
                          >
                        >;
                      }
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type InviteLearnersToAssessmentMutationVariables = Exact<{
  cohortKey: Scalars['String'];
  selectionFilters:
    | Array<Maybe<LearnerBulkSelectionFilter>>
    | Maybe<LearnerBulkSelectionFilter>;
  exclusionFilters?: Maybe<
    Array<Maybe<LearnerBulkSelectionFilter>> | Maybe<LearnerBulkSelectionFilter>
  >;
}>;

export type InviteLearnersToAssessmentMutation = {
  __typename?: 'PublicMutation';
} & {
  inviteLearnersToAssessment?: Maybe<
    { __typename?: 'InviteLearnersToAssessment' } & Pick<
      InviteLearnersToAssessment,
      'totalCount' | 'batchId'
    >
  >;
};

export type LaunchEnrollmentCohortMutationVariables = Exact<{
  cohortId: Scalars['ID'];
}>;

export type LaunchEnrollmentCohortMutation = {
  __typename?: 'PublicMutation';
} & {
  launchEnrollmentCohort?: Maybe<
    { __typename?: 'LaunchEnrollmentCohort' } & Pick<
      LaunchEnrollmentCohort,
      'batchId'
    > & {
        enrollmentCohort?: Maybe<
          { __typename?: 'EnrollmentCohort' } & Pick<
            EnrollmentCohort,
            'id' | 'startDate'
          >
        >;
      }
  >;
};

export type NominateLearnerInterestMutationVariables = Exact<{
  learnerEmail: Scalars['String'];
  learnerFirstName: Scalars['String'];
  learnerLastName: Scalars['String'];
  companyId: Scalars['ID'];
  programKey?: Maybe<Scalars['String']>;
  companyGraphLearningPathId?: Maybe<Scalars['ID']>;
  isNominatorManager?: Maybe<Scalars['Boolean']>;
  programType?: Maybe<ResourceType>;
  nominationType?: Maybe<NominationType>;
}>;

export type NominateLearnerInterestMutation = {
  __typename?: 'PublicMutation';
} & {
  nominateLearnerInterest?: Maybe<
    { __typename?: 'LearnerInterest' } & Pick<
      LearnerInterest,
      'id' | 'learnerId'
    >
  >;
};

export type ProcessLearnerInterestsMutationVariables = Exact<{
  action: LearnerInterestAction;
  companyId: Scalars['ID'];
  selectionFilters:
    | Array<LearnerInterestBulkSelectionFilter>
    | LearnerInterestBulkSelectionFilter;
  exclusionFilters?: Maybe<
    | Array<LearnerInterestBulkSelectionFilter>
    | LearnerInterestBulkSelectionFilter
  >;
  enrollmentCohortId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
}>;

export type ProcessLearnerInterestsMutation = {
  __typename?: 'PublicMutation';
} & {
  processLearnerInterests?: Maybe<
    { __typename?: 'ProcessLearnerInterests' } & Pick<
      ProcessLearnerInterests,
      'ok' | 'batchId'
    >
  >;
};

export type RecomputeCompanyRealTimeReportsMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type RecomputeCompanyRealTimeReportsMutation = {
  __typename?: 'PublicMutation';
} & {
  recomputeCompanyRealTimeReports?: Maybe<
    { __typename?: 'RecomputeCompanyRealTimeReports' } & Pick<
      RecomputeCompanyRealTimeReports,
      'ok'
    >
  >;
};

export type RecomputeLearnerRealTimeReportsMutationVariables = Exact<{
  learnerIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type RecomputeLearnerRealTimeReportsMutation = {
  __typename?: 'PublicMutation';
} & {
  recomputeLearnerRealTimeReports?: Maybe<
    { __typename?: 'RecomputeLearnerRealTimeReports' } & Pick<
      RecomputeLearnerRealTimeReports,
      'ok'
    >
  >;
};

export type RegenerateCatalogTokenMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type RegenerateCatalogTokenMutation = {
  __typename?: 'PublicMutation';
} & {
  regenerateCatalogToken?: Maybe<
    { __typename?: 'RegenerateCatalogToken' } & Pick<
      RegenerateCatalogToken,
      'ok'
    > & {
        catalogToken?: Maybe<
          { __typename?: 'CompanyCatalogToken' } & Pick<
            CompanyCatalogToken,
            'companyId' | 'token' | 'expiresAt'
          >
        >;
      }
  >;
};

export type RemoveInterestInProgramMutationVariables = Exact<{
  companyId: Scalars['ID'];
  programType: ResourceType;
  programKey: Scalars['String'];
}>;

export type RemoveInterestInProgramMutation = {
  __typename?: 'PublicMutation';
} & {
  removeInterestInProgram?: Maybe<
    { __typename?: 'RemoveInterestInProgram' } & Pick<
      RemoveInterestInProgram,
      'ok'
    >
  >;
};

export type RemoveLearnerMutationVariables = Exact<{
  learnerId: Scalars['ID'];
}>;

export type RemoveLearnerMutation = { __typename?: 'PublicMutation' } & {
  deleteLearner?: Maybe<
    { __typename?: 'DeleteLearner' } & Pick<DeleteLearner, 'ok'>
  >;
};

export type RemoveLearnersFromGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
  learnerIdsFilters?: Maybe<
    Array<Maybe<LearnerBulkSelectionFilter>> | Maybe<LearnerBulkSelectionFilter>
  >;
}>;

export type RemoveLearnersFromGroupMutation = {
  __typename?: 'PublicMutation';
} & {
  removeLearnersFromGroup?: Maybe<
    { __typename?: 'RemoveLearnersFromGroup' } & Pick<
      RemoveLearnersFromGroup,
      'ok'
    >
  >;
};

export type SearchLearnersQueryVariables = Exact<{
  companyId: Scalars['ID'];
  search?: Maybe<Scalars['String']>;
}>;

export type SearchLearnersQuery = { __typename?: 'PublicQuery' } & {
  company?: Maybe<
    { __typename?: 'Company' } & {
      learners?: Maybe<
        { __typename?: 'LearnerPaginatedConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'LearnerPaginatedEdge' } & {
                node?: Maybe<
                  { __typename?: 'Learner' } & Pick<Learner, 'id' | 'email'> & {
                      user?: Maybe<
                        { __typename?: 'User' } & Pick<User, 'email'>
                      >;
                    }
                >;
              }
            >
          >;
        }
      >;
    }
  >;
};

export type ShowInterestInProgramMutationVariables = Exact<{
  companyId: Scalars['ID'];
  programType: ResourceType;
  programKey: Scalars['String'];
  nextUrl: Scalars['String'];
}>;

export type ShowInterestInProgramMutation = {
  __typename?: 'PublicMutation';
} & {
  showInterestInProgram?: Maybe<
    { __typename?: 'LearnerInterest' } & Pick<
      LearnerInterest,
      | 'learnerId'
      | 'programKey'
      | 'programType'
      | 'id'
      | 'status'
      | 'updateDate'
      | 'companyId'
    >
  >;
};

export type TransferLearnersMutationVariables = Exact<{
  sourceContractTermId: Scalars['ID'];
  destinationContractTermId: Scalars['ID'];
  selectionFilters:
    | Array<Maybe<LearnerBulkSelectionFilter>>
    | Maybe<LearnerBulkSelectionFilter>;
  destinationEnrollmentCohortId?: Maybe<Scalars['ID']>;
}>;

export type TransferLearnersMutation = { __typename?: 'PublicMutation' } & {
  transferLearners?: Maybe<
    { __typename?: 'TransferLearners' } & Pick<
      TransferLearners,
      'ok' | 'batchId'
    >
  >;
};

export type UnenrollLearnersMutationVariables = Exact<{
  contractTermId?: Maybe<Scalars['ID']>;
  companyId?: Maybe<Scalars['ID']>;
  allContractTerms?: Maybe<Scalars['Boolean']>;
  selectionFilters:
    | Array<Maybe<LearnerBulkSelectionFilter>>
    | Maybe<LearnerBulkSelectionFilter>;
}>;

export type UnenrollLearnersMutation = { __typename?: 'PublicMutation' } & {
  unenrollLearners?: Maybe<
    { __typename?: 'UnenrollLearners' } & Pick<
      UnenrollLearners,
      'ok' | 'batchId'
    >
  >;
};

export type UpdateAssessmentCohortMutationVariables = Exact<{
  cohortKey: Scalars['String'];
  archive?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
}>;

export type UpdateAssessmentCohortMutation = {
  __typename?: 'PublicMutation';
} & {
  updateAssessmentCohort?: Maybe<
    { __typename?: 'UpdateAssessmentCohort' } & {
      assessmentCohort?: Maybe<
        { __typename?: 'AssessmentCohort' } & Pick<
          AssessmentCohort,
          'cohortKey' | 'archiveDate' | 'deleteDate'
        >
      >;
    }
  >;
};

export type UpdateAssessmentLabSessionMutationVariables = Exact<{
  id: Scalars['ID'];
  programPreference?: Maybe<Scalars['String']>;
  selfReportedSubjects?: Maybe<
    Array<SelfReportedSubjectInput> | SelfReportedSubjectInput
  >;
}>;

export type UpdateAssessmentLabSessionMutation = {
  __typename?: 'PublicMutation';
} & {
  updateAssessmentLabSession?: Maybe<
    { __typename?: 'AssessmentLabSession' } & Pick<
      AssessmentLabSession,
      'id' | 'sessionId' | 'programPreference'
    > & {
        selfReportedSubjects?: Maybe<
          Array<
            { __typename?: 'SelfReportedSubjects' } & Pick<
              SelfReportedSubjects,
              'subjectId' | 'subjectName' | 'selfReportedMastery'
            >
          >
        >;
      }
  >;
};

export type UpdateCatalogProgramMutationVariables = Exact<{
  programKey: Scalars['String'];
  programType: ResourceType;
  catalogUrl: Scalars['String'];
  isStandard?: Maybe<Scalars['Boolean']>;
  tagIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  stuPricing?: Maybe<Scalars['Int']>;
}>;

export type UpdateCatalogProgramMutation = { __typename?: 'PublicMutation' } & {
  updateCatalogProgram?: Maybe<
    { __typename?: 'UpdateCatalogProgram' } & {
      catalogProgram?: Maybe<
        { __typename?: 'CatalogProgram' } & Pick<
          CatalogProgram,
          | 'programKey'
          | 'programType'
          | 'catalogUrl'
          | 'isStandard'
          | 'stuPricing'
        > & {
            tags?: Maybe<
              Array<
                { __typename?: 'CatalogTag' } & Pick<
                  CatalogTag,
                  'id' | 'category' | 'value' | 'colorScheme'
                >
              >
            >;
          }
      >;
    }
  >;
};

export type UpdateCatalogTagMutationVariables = Exact<{
  id: Scalars['ID'];
  category: CatalogTagCategory;
  colorScheme: CatalogTagColorScheme;
  value: Scalars['String'];
}>;

export type UpdateCatalogTagMutation = { __typename?: 'PublicMutation' } & {
  updateCatalogTag?: Maybe<
    { __typename?: 'CatalogTag' } & Pick<
      CatalogTag,
      'id' | 'value' | 'category' | 'colorScheme' | 'createDate' | 'updateDate'
    >
  >;
};

export type UpdateCertificationAssessmentMutationVariables = Exact<{
  companyId: Scalars['ID'];
  programKey: Scalars['String'];
  programType: ResourceType;
  assessmentCloseDate?: Maybe<Scalars['DateTime']>;
  omacAssessmentGroupId?: Maybe<Scalars['String']>;
  maxDuration?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['Int']>;
  assessmentDuration?: Maybe<Scalars['Int']>;
  attempts?: Maybe<Scalars['Int']>;
  required?: Maybe<Scalars['Boolean']>;
}>;

export type UpdateCertificationAssessmentMutation = {
  __typename?: 'PublicMutation';
} & {
  updateCertificationAssessment?: Maybe<
    { __typename?: 'UpdateCertificationAssessment' } & Pick<
      UpdateCertificationAssessment,
      'ok'
    >
  >;
};

export type UpdateCollectionMutationVariables = Exact<{
  collectionId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  learningPathIdsToAdd?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  learningPathIdsToRemove?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type UpdateCollectionMutation = { __typename?: 'PublicMutation' } & {
  updateGraphLearningPathCollection?: Maybe<
    { __typename?: 'UpdateGraphLearningPathCollection' } & {
      graphLearningPathCollection?: Maybe<
        { __typename?: 'GraphLearningPathCollection' } & Pick<
          GraphLearningPathCollection,
          'id' | 'name'
        >
      >;
    }
  >;
  handleGlpCollectionItems?: Maybe<
    { __typename?: 'HandleGLPCollectionItems' } & {
      learningPathCollection?: Maybe<
        { __typename?: 'GraphLearningPathCollection' } & Pick<
          GraphLearningPathCollection,
          'id'
        > & {
            learningPaths?: Maybe<
              { __typename?: 'GraphLearningPathPaginatedConnection' } & {
                edges: Array<
                  Maybe<
                    { __typename?: 'GraphLearningPathPaginatedEdge' } & {
                      node?: Maybe<
                        { __typename?: 'GraphLearningPath' } & Pick<
                          GraphLearningPath,
                          'id' | 'name'
                        >
                      >;
                    }
                  >
                >;
              }
            >;
          }
      >;
    }
  >;
};

export type UpdateCompanyLearningPathMutationVariables = Exact<{
  assessmentCohortKey?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isAvailable: Scalars['Boolean'];
  removeAssessmentCohort?: Maybe<Scalars['Boolean']>;
  questionSetId?: Maybe<Scalars['ID']>;
  isManager: Scalars['Boolean'];
}>;

export type UpdateCompanyLearningPathMutation = {
  __typename?: 'PublicMutation';
} & {
  updateCompanyGraphLearningPath?: Maybe<
    { __typename?: 'UpdateCompanyGraphLearningPath' } & {
      companyGraphLearningPath?: Maybe<
        { __typename?: 'CompanyGraphLearningPath' } & Pick<
          CompanyGraphLearningPath,
          'id' | 'isAvailable' | 'questionSetId'
        > & {
            learningPath?: Maybe<
              { __typename?: 'GraphLearningPath' } & Pick<
                GraphLearningPath,
                'name'
              >
            >;
            assessmentCohort?: Maybe<
              { __typename?: 'AssessmentCohort' } & Pick<
                AssessmentCohort,
                'cohortKey'
              >
            >;
            questionSet?: Maybe<
              { __typename?: 'QuestionSet' } & DefaultQuestionSetFieldsFragment
            >;
          }
      >;
    }
  >;
};

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  enableAutoUnenroll?: Maybe<Scalars['Boolean']>;
  isSso?: Maybe<Scalars['Boolean']>;
  questionSetId?: Maybe<Scalars['ID']>;
  nominationQuestionSetId?: Maybe<Scalars['ID']>;
}>;

export type UpdateCompanyMutation = { __typename?: 'PublicMutation' } & {
  updateCompany?: Maybe<
    { __typename?: 'UpdateCompany' } & {
      company?: Maybe<
        { __typename?: 'Company' } & Pick<
          Company,
          | 'country'
          | 'createDate'
          | 'description'
          | 'enableAutoUnenroll'
          | 'id'
          | 'isSso'
          | 'logoUrl'
          | 'name'
          | 'slug'
          | 'status'
          | 'updateDate'
          | 'questionSetId'
          | 'nominationQuestionSetId'
        >
      >;
    }
  >;
};

export type UpdateContractTermsMutationVariables = Exact<{
  contractTermUpdates?: Maybe<
    Array<Maybe<ContractTermUpdateInput>> | Maybe<ContractTermUpdateInput>
  >;
}>;

export type UpdateContractTermsMutation = { __typename?: 'PublicMutation' } & {
  updateContractTerms?: Maybe<
    { __typename?: 'UpdateContractTerms' } & Pick<UpdateContractTerms, 'ok'> & {
        contractTerms?: Maybe<
          Array<
            { __typename?: 'ContractTerm' } & Pick<
              ContractTerm,
              'id' | 'status' | 'stuPricingOverride'
            >
          >
        >;
      }
  >;
};

export type UpdateContractMutationVariables = Exact<{
  contractId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  maxActiveSeats?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  strictManagement?: Maybe<Scalars['Boolean']>;
  stuContractGracePeriodInDays?: Maybe<Scalars['Int']>;
  stuCount?: Maybe<Scalars['Int']>;
  isStuContract?: Maybe<Scalars['Boolean']>;
}>;

export type UpdateContractMutation = { __typename?: 'PublicMutation' } & {
  updateContract?: Maybe<
    { __typename?: 'UpdateContract' } & Pick<UpdateContract, 'ok'> & {
        contract?: Maybe<
          { __typename?: 'Contract' } & Pick<
            Contract,
            | 'companyId'
            | 'endDate'
            | 'id'
            | 'maxActiveSeats'
            | 'notes'
            | 'startDate'
            | 'status'
            | 'title'
            | 'strictManagement'
            | 'stuContractGracePeriodInDays'
            | 'stuCount'
            | 'isStuContract'
            | 'contractType'
          >
        >;
      }
  >;
};

export type UpdateEnrollmentCohortMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  contractTermIdsToAdd?: Maybe<
    Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>
  >;
  contractTermIdsToRemove?: Maybe<
    Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>
  >;
  shouldUnenrollLearnersBeingRemoved?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  learnerIdsToAddFilters?: Maybe<
    Array<Maybe<LearnerBulkSelectionFilter>> | Maybe<LearnerBulkSelectionFilter>
  >;
  learnerIdsToRemoveFilters?: Maybe<
    Array<Maybe<LearnerBulkSelectionFilter>> | Maybe<LearnerBulkSelectionFilter>
  >;
}>;

export type UpdateEnrollmentCohortMutation = {
  __typename?: 'PublicMutation';
} & {
  updateEnrollmentCohort?: Maybe<
    { __typename?: 'UpdateEnrollmentCohort' } & {
      enrollmentCohort?: Maybe<
        { __typename?: 'EnrollmentCohort' } & Pick<
          EnrollmentCohort,
          'id' | 'name' | 'startDate' | 'endDate' | 'state'
        > & {
            contractTerms?: Maybe<
              Array<{ __typename?: 'ContractTerm' } & Pick<ContractTerm, 'id'>>
            >;
            learners?: Maybe<
              { __typename?: 'LearnerPaginatedConnection' } & Pick<
                LearnerPaginatedConnection,
                'totalCount'
              > & {
                  edges: Array<
                    Maybe<
                      { __typename?: 'LearnerPaginatedEdge' } & {
                        node?: Maybe<
                          { __typename?: 'Learner' } & Pick<
                            Learner,
                            'id' | 'email'
                          > & {
                              user?: Maybe<
                                { __typename?: 'User' } & Pick<
                                  User,
                                  'firstName' | 'lastName' | 'email'
                                >
                              >;
                            }
                        >;
                      }
                    >
                  >;
                }
            >;
          }
      >;
    }
  >;
};

export type UpdateEnrollmentDatesMutationVariables = Exact<{
  companyId: Scalars['ID'];
  enrollmentIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  utcEndDate?: Maybe<Scalars['String']>;
  utcStartDate?: Maybe<Scalars['String']>;
}>;

export type UpdateEnrollmentDatesMutation = {
  __typename?: 'PublicMutation';
} & {
  updateEnrollmentDates?: Maybe<
    { __typename?: 'UpdateEnrollmentDates' } & Pick<UpdateEnrollmentDates, 'ok'>
  >;
};

export type UpdateGraphLearningPathMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  edges?: Maybe<
    Array<Maybe<LearningPathEdgeInput>> | Maybe<LearningPathEdgeInput>
  >;
  allNodes?: Maybe<Array<LearningPathNodeInput> | LearningPathNodeInput>;
  tagIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
}>;

export type UpdateGraphLearningPathMutation = {
  __typename?: 'PublicMutation';
} & {
  updateGraphLearningPath?: Maybe<
    { __typename?: 'UpdateGraphLearningPath' } & {
      graphLearningPath?: Maybe<
        { __typename?: 'GraphLearningPath' } & GraphLearningPathFragment
      >;
    }
  >;
};

export type UpdateLearnerContractTermAssociationsMutationVariables = Exact<{
  enrollmentCohortId: Scalars['ID'];
  contractTermId: Scalars['ID'];
  learnerIdsToAddFilters?: Maybe<
    Array<Maybe<LearnerBulkSelectionFilter>> | Maybe<LearnerBulkSelectionFilter>
  >;
  learnerIdsToRemoveFilters?: Maybe<
    Array<Maybe<LearnerBulkSelectionFilter>> | Maybe<LearnerBulkSelectionFilter>
  >;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  rosterFilters?: Maybe<RosterFilters>;
}>;

export type UpdateLearnerContractTermAssociationsMutation = {
  __typename?: 'PublicMutation';
} & {
  updateCohortLearnersAssociation?: Maybe<
    { __typename?: 'UpdateCohortLearnersAssociation' } & {
      enrollmentCohort?: Maybe<
        { __typename?: 'EnrollmentCohort' } & {
          learners?: Maybe<
            { __typename?: 'LearnerPaginatedConnection' } & {
              edges: Array<
                Maybe<
                  { __typename?: 'LearnerPaginatedEdge' } & {
                    node?: Maybe<
                      { __typename?: 'Learner' } & Pick<
                        Learner,
                        'id' | 'email'
                      > & {
                          associations?: Maybe<
                            Array<
                              Maybe<
                                {
                                  __typename?: 'EnrollmentCohortLearnerContractTerm';
                                } & Pick<
                                  EnrollmentCohortLearnerContractTerm,
                                  'enrollmentCohortId' | 'contractTermId'
                                >
                              >
                            >
                          >;
                        }
                    >;
                  }
                >
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type UpdateLearnerInterestMutationVariables = Exact<{
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  status?: Maybe<LearnerInterestStatus>;
  assessmentResultId?: Maybe<Scalars['ID']>;
}>;

export type UpdateLearnerInterestMutation = {
  __typename?: 'PublicMutation';
} & {
  updateLearnerInterest?: Maybe<
    { __typename?: 'LearnerInterest' } & Pick<
      LearnerInterest,
      'id' | 'customData' | 'note' | 'status' | 'assessmentResultId'
    >
  >;
};

export type UpdateProgramMutationVariables = Exact<{
  companyId: Scalars['ID'];
  programKey: Scalars['String'];
  questionSetId?: Maybe<Scalars['ID']>;
  isChallengeProgram?: Maybe<Scalars['Boolean']>;
}>;

export type UpdateProgramMutation = { __typename?: 'PublicMutation' } & {
  updateProgram?: Maybe<
    { __typename?: 'UpdateProgram' } & Pick<UpdateProgram, 'ok'>
  >;
};

export type UpdateQuestionSetMutationVariables = Exact<{
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  confirmationMessage?: Maybe<Scalars['String']>;
  rejectionMessage?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  questions?: Maybe<Array<AdmissionQuestionInput> | AdmissionQuestionInput>;
  isManager: Scalars['Boolean'];
  inactiveStatusMessage?: Maybe<Scalars['String']>;
}>;

export type UpdateQuestionSetMutation = { __typename?: 'PublicMutation' } & {
  updateQuestionSet?: Maybe<
    { __typename?: 'QuestionSet' } & {
      learningPaths?: Maybe<
        { __typename?: 'CompanyGraphLearningPathConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'CompanyGraphLearningPathEdge' } & {
                node?: Maybe<
                  { __typename?: 'CompanyGraphLearningPath' } & Pick<
                    CompanyGraphLearningPath,
                    'id'
                  >
                >;
              }
            >
          >;
        }
      >;
    } & DefaultQuestionSetFieldsFragment
  >;
};

export type UpdateResourceMutationVariables = Exact<{
  id: Scalars['ID'];
  version?: Maybe<Scalars['String']>;
  durationOverride?: Maybe<Scalars['Int']>;
  removeDurationOverride?: Maybe<Scalars['Boolean']>;
  assessmentCohortKey?: Maybe<Scalars['ID']>;
  removeAssessmentCohort?: Maybe<Scalars['Boolean']>;
  autoEnrollSetting?: Maybe<AutoEnrollSettingInput>;
}>;

export type UpdateResourceMutation = { __typename?: 'PublicMutation' } & {
  updateResource?: Maybe<
    { __typename?: 'UpdateResource' } & Pick<UpdateResource, 'ok'> & {
        resource?: Maybe<
          { __typename?: 'Resource' } & Pick<
            Resource,
            'id' | 'version' | 'durationOverride' | 'data'
          > & {
              assessmentCohort?: Maybe<
                { __typename?: 'AssessmentCohort' } & Pick<
                  AssessmentCohort,
                  'cohortKey'
                >
              >;
              autoEnrollSetting?: Maybe<
                { __typename?: 'CompanySettingAutoEnroll' } & Pick<
                  CompanySettingAutoEnroll,
                  | 'enabled'
                  | 'criteriaQuestionSetRequired'
                  | 'criteriaAssessmentResultRequired'
                  | 'criteriaAssessmentDefaultThreshold'
                  | 'criteriaAssessmentOverrideThreshold'
                  | 'criteriaEnrollmentLimitEnabled'
                  | 'criteriaEnrollmentLimitCount'
                  | 'version'
                >
              >;
            }
        >;
      }
  >;
};

export type UpdateStuCountMutationVariables = Exact<{
  enrollmentIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  stuCountOverride: Scalars['Int'];
}>;

export type UpdateStuCountMutation = { __typename?: 'PublicMutation' } & {
  updateStuCount?: Maybe<
    { __typename?: 'UpdateStuCount' } & Pick<UpdateStuCount, 'ok'>
  >;
};

export type WhitelistedProgramsQueryVariables = Exact<{ [key: string]: never }>;

export type WhitelistedProgramsQuery = { __typename?: 'PublicQuery' } & {
  allAssessmentWhitelistedPrograms?: Maybe<
    Array<
      Maybe<
        { __typename?: 'AssessmentWhitelistedProgram' } & Pick<
          AssessmentWhitelistedProgram,
          'programKey' | 'programType'
        > & {
            program: { __typename?: 'ProgramData' } & Pick<
              ProgramData,
              'title'
            >;
          }
      >
    >
  >;
};
