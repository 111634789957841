export const ALL = 'all';
export const CUSTOM = 'custom';
export const UNSET = 'unset';
export const NOW = 'now';

// user-api roles
export const STAFF_ROLE = 'staff';
export const ENT_PROGRAM_MANAGER_ROLE = 'enterprise-program-manager';
export const QUESTION_CREATOR_ROLE = 'question-creator';
export const ENT_ENGINEER_ROLE = 'enterprise-engineer';
export const ASSESSMENT_MANAGER_ROLE = 'assessment-manager';
export const LEGAL_FINANCE_ROLE = 'legal-finance';

// emc roles
export const COMPANY_ADMIN_ROLE = 'company_admin';
export const LEARNER_MANAGER_ROLE = 'learner_manager';
export const VIEW_ONLY_ROLE = 'view_only';

export enum EnrollmentActionTypes {
  Enroll = 'enroll',
  Unenroll = 'unenroll',
  Transfer = 'transfer',
}

export const knownEmcAutomationUserKeys = [
  'ent-graduation',
  'emc-auto-unenroll',
];

export const REMOVE_ASSESSMENT_COHORT = 'remove_assessment_cohort';

export const INTEREST_FILTER_QUESTION_SUFFIX = '_response';
