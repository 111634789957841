import {
  ClosedSelectToggle,
  IconArrow,
  MutliItem,
  OpenSelectToggle,
  getCurrentItem,
  optionDoesMatch,
} from './utils';
import Downshift, { ControllerStateAndHelpers } from 'downshift';
import React, { ReactNode } from 'react';
import { isEmpty, path } from 'ramda';

import { IOption } from 'app/types/options';
import { IconClose } from '@udacity/veritas-icons';
import MultiDownshift from './multi';
import { UnstyledButton } from '../unstyled-button';
import classNames from 'classnames';
import noop from 'lodash/noop';
import styles from './styles.module.scss';

export type SelectProps = {
  id: string;
  label: ReactNode;
  options: IOption[];
  defaultValue?: string;
  disabled?: boolean;
  hiddenLabel?: boolean;
  isSlim?: boolean;
  multi?: boolean;
  clearable?: boolean;
  onBlur?(): void;
  onKeyDown?(evt: React.KeyboardEvent<HTMLInputElement>): void;
  onChange(
    option: IOption | IOption[] | null,
    stateAndHelpers?: ControllerStateAndHelpers<IOption | IOption[] | null>
  ): void;
  onFocus?(): void;
  required?: boolean;
  testID?: string;
  validation?: JSX.Element;
  value?: string | number | IOption | IOption[];
  position?: 'top' | 'bottom';
  placeholder?: string;
  placeholderStyle?: 'capslock' | 'italic';
  labelWeight?: 'semibold' | 'regular';
  getFilteredOptions?: (
    inputValue: string | null,
    multi: boolean,
    multiValues: IOption<string | number | boolean>[],
    options: IOption[]
  ) => IOption[];
};

export const Select: React.FC<SelectProps> = ({
  clearable,
  defaultValue,
  disabled,
  hiddenLabel,
  id,
  isSlim,
  label,
  multi,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  options = [],
  position = 'bottom',
  placeholder = '',
  testID,
  validation,
  value,
  placeholderStyle = 'capslock',
  labelWeight = 'semibold',
  getFilteredOptions = getFilteredOptionsDefault,
}) => {
  const labelClassName = classNames(
    styles.select__label,
    styles[`select__${labelWeight}`],
    hiddenLabel && `vds-visually-hidden`
  );
  const Ds: React.ElementType = multi ? MultiDownshift : Downshift;
  const multiValues = getMultiValues(value);

  return (
    <Ds
      defaultSelectedItems={multi ? multiValues : undefined}
      itemToString={(item: IOption) => (item ? item.label : '')}
      onChange={onChange || undefined}
      defaultHighlightedIndex={0}
    >
      {({
        clearSelection,
        getRemoveButtonProps,
        getItemProps,
        getLabelProps,
        getInputProps,
        highlightedIndex,
        inputValue,
        getMenuProps,
        getToggleButtonProps,
        selectedItem,
        isOpen,
        closeMenu,
        openMenu,
      }: ControllerStateAndHelpers<IOption> & {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getRemoveButtonProps(ar: any): any;
      }) => {
        const currentItem = getCurrentItem(
          options,
          value,
          defaultValue,
          selectedItem
        );

        function handleClear() {
          clearSelection();
          onChange(null);
        }

        const filteredOptions = getFilteredOptions(
          inputValue,
          Boolean(multi),
          multiValues,
          options
        );

        return (
          <div
            aria-invalid={validation && validation.props.variant === 'error'}
            className={classNames(
              styles.select,
              !isOpen && styles['select--hidden'],
              isSlim && styles.slim
            )}
          >
            <label
              {...getLabelProps({ className: labelClassName, htmlFor: id })}
            >
              {label}
            </label>
            <div className={styles.optionContainer}>
              {multi && !isEmpty(multiValues) && (
                <div
                  className={classNames(styles.multiContainer, {
                    [styles.multiTop]: position === 'top',
                  })}
                >
                  {multiValues.map((val) => (
                    <MutliItem
                      disabled={disabled}
                      getRemoveButtonProps={getRemoveButtonProps}
                      key={val.value as string | number}
                      val={val}
                    />
                  ))}
                </div>
              )}
              <div className={styles[`select__input-container`]}>
                {isOpen ? (
                  <OpenSelectToggle
                    getInputProps={getInputProps}
                    onKeyDown={onKeyDown}
                    position={position}
                    id={id}
                  />
                ) : (
                  <ClosedSelectToggle
                    currentItem={currentItem}
                    disabled={disabled}
                    getToggleButtonProps={getToggleButtonProps}
                    id={id}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    placeholder={placeholder}
                    position={position}
                    testID={testID}
                    placeholderStyle={placeholderStyle}
                  />
                )}
                {clearable && currentItem && (
                  <UnstyledButton
                    className={styles.clear}
                    onClick={handleClear}
                    aria-label="Clear selection"
                  >
                    <IconClose color="red" size="sm" />
                  </UnstyledButton>
                )}
                <UnstyledButton
                  className={styles.arrowWrapper}
                  aria-label={
                    isOpen ? 'Close dropdown menu' : 'open dropdown menu'
                  }
                  onClick={() => {
                    disabled ? noop() : isOpen ? closeMenu() : openMenu();
                  }}
                  tabIndex={-1} // prevent redundant focus, only because dropdown already receives focus
                >
                  {IconArrow}
                </UnstyledButton>
                <ul
                  {...getMenuProps({
                    className: classNames(
                      styles.select__options,
                      styles[position]
                    ),
                    role: 'listbox',
                    id: `${id}-listbox`,
                  })}
                >
                  {isEmpty(filteredOptions) ? (
                    <li
                      title="No Options Found"
                      {...getItemProps({
                        className: classNames(
                          styles[`select__option`],
                          styles.disabled
                        ),
                        disabled: true,
                        item: { label: 'No Options Found', value: '' },
                      })}
                    >
                      No Options Found
                    </li>
                  ) : (
                    filteredOptions.map((item, index) => (
                      <li
                        key={item.value}
                        title={item.title || item.label}
                        {...getItemProps({
                          className: classNames(styles[`select__option`], {
                            [styles.current]:
                              item.value === path(['value'])(currentItem),
                            [styles.disabled]: item.disabled,
                            [styles.highlighted]: index === highlightedIndex,
                          }),
                          item,
                          disabled: item.disabled,
                        })}
                      >
                        {item.component || item.label}
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>
            {validation && (
              <div className={styles.validation}>{validation}</div>
            )}
          </div>
        );
      }}
    </Ds>
  );
};

function getMultiValues(
  value: string | number | IOption | IOption[] | undefined
) {
  if (Array.isArray(value)) {
    return value;
  }
  return [];
}

function getFilteredOptionsDefault(
  inputValue: string | null,
  multi: boolean,
  multiValues: IOption<string | number | boolean>[],
  options: IOption[]
) {
  return options.filter((option) =>
    optionDoesMatch(inputValue, option, multi, multiValues)
  );
}
