import {
  makeCacheReducer,
  makeIndexedCacheReducer,
} from 'app/utils/cache/util';

import { combineReducers } from 'redux';

const cache = combineReducers({
  activeCompanies: makeIndexedCacheReducer('CACHE_ACTIVE_COMPANY'),
  allCompaniesAndTypes: makeCacheReducer('CACHE_COMPANIES'),
  assessmentSkills: makeIndexedCacheReducer('CACHE_ASSESSMENT_SKILLS'),
  classConCatalog: makeIndexedCacheReducer('CACHE_CLASS_CON_CATALOG'),
  catalog: makeIndexedCacheReducer('CACHE_COMPANY_CATALOG'),
  companyPrograms: makeIndexedCacheReducer('CACHE_COMPANY_PROGRAMS'),
  companyQuestionSets: makeIndexedCacheReducer('CACHE_COMPANY_QUESTION_SET'),
  enrollableContractTerms: makeIndexedCacheReducer(
    'CACHE_ENROLLABLE_CONTRACT_TERMS'
  ),
});

export type CacheState = ReturnType<typeof cache>;

export default cache;
