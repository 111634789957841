import { ServiceModelsReduxState } from './types';
import { addServiceModels } from 'app/actions/registrar';

export default function serviceModel(
  state: ServiceModelsReduxState = null,
  action: ReturnType<typeof addServiceModels>
): ServiceModelsReduxState {
  switch (action.type) {
    case 'ADD_SERVICE_MODELS': {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
