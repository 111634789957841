import { LoadingType } from '@emc/loading-types';
import { createAction } from 'app/utils/actions';

// Sync

export type StartLoading = ReturnType<typeof startLoading>;
export function startLoading(type: LoadingType) {
  return createAction('START_LOADING', type);
}

export type StopLoading = ReturnType<typeof stopLoading>;
export function stopLoading(type: LoadingType) {
  return createAction('STOP_LOADING', type);
}

export type LoadingActions = StopLoading | StartLoading;
