import i18n, { Resource } from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import Pseudo from 'i18next-pseudo';
import ar from './locales/ar.json';
import { dateFormatter } from 'app/utils/dates/format';
import en from './locales/en.json';
import fr from './locales/fr.json';
import { initReactI18next } from 'react-i18next';
import ko from './locales/ko.json';

const resources: Resource = {
  ar: { translation: ar },
  en: { translation: en },
  fr: { translation: fr },
  ko: { translation: ko },
};

// All options: https://www.i18next.com/overview/configuration-options
export function initi18n() {
  i18n
    .use(getPseudoPlugin())
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      resources,
      postProcess: ['pseudo'],
      detection: detectionOptions,
      react: { useSuspense: true },
      returnEmptyString: false,
    });

  dateFormatter.resetLocale(i18n.language);
  document.documentElement.lang = i18n.language;
}

const detectionOptions = {
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'navigator',
    'sessionStorage',
    'htmlTag',
  ],
};

function getPseudolocalizationVisibility() {
  const persistedSessionString = localStorage.getItem('persist:session');
  const persistedSessionStore = JSON.parse(persistedSessionString || '{}');
  const persistedVisibility = JSON.parse(
    persistedSessionStore?.visibility || '{}'
  );

  return persistedVisibility?.pseudolocalization;
}

function getPseudoPlugin() {
  return new Pseudo({
    enabled: Boolean(getPseudolocalizationVisibility()),
    languageToPseudo: 'en-US',
  });
}
