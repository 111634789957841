import {
  AllCompaniesAndTypesQuery,
  AllCompaniesQuery,
  Company,
  CompanyCatalogToken,
  CompanyType,
  ContractTermEnrollmentCount,
  ErrorCode,
  GetCompanyBySlugQuery,
  GetCompanyQuery,
  Status,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
} from 'app/types/generated/emc';
import { AppThunk, IGqlResponse } from 'app/types/shared';
import {
  AssessmentRouterParams,
  getAssessmentSubjectsPath,
} from 'app/utils/assessments';
import {
  CompaniesReduxState,
  ParentCompaniesReduxState,
} from 'app/reducer/data/types';
import {
  CompanyNormalizedEntities,
  companyNormSchema,
} from 'app/utils/normalize/company';
import { addFlashMessage, addServerError, flashGraphqlError } from '../flash';
import { cacheActiveCompany, cacheCompanies } from '../cache';
import {
  hasCompanyAdminPermissionsOrHigherSelector,
  hasViewOnlyPermissionsOrHigherSelector,
} from 'app/selectors/me';
import { startLoading, stopLoading } from '../loading';

import { ALL } from 'app/utils/constants';
import { DashboardChartKeys } from 'app/types/charts';
import { NormalizedCompany } from 'app/types/companies';
import { NormalizedParentCompany } from 'app/types/companies';
import { authenticateCurrentUser } from '../me';
import { batch } from 'react-redux';
import { createAction } from 'app/utils/actions';
import { createAssessmentLearner } from '../learners/create';
import { emcService } from '@emc/services';
import { getGraphQLErrorCode } from 'app/utils/errors';
import { getQueryParams } from 'app/utils/router/query-params';
import { getQueryString } from 'app/utils/graphql/serializer';
import { indexBy } from 'ramda';
import { isCacheValid } from 'app/utils/cache/is_valid';
import { loader } from 'graphql.macro';
import { normalize } from 'normalizr';
import { updateChartProperty } from '../charts/dashboard';

// Sync

export function addCompanies(companies: CompaniesReduxState) {
  return createAction('ADD_COMPANIES', companies);
}

export function addParentCompanies(parentCompanies: ParentCompaniesReduxState) {
  return createAction('ADD_PARENT_COMPANIES', parentCompanies);
}

export function addCompanyTypes(types: CompanyType[]) {
  return createAction('SET_COMPANY_TYPES', types);
}

export function addActiveCompany(companySlug: string, data: Partial<Company>) {
  return createAction('ADD_ACTIVE_COMPANY', { companySlug, data });
}

export function addContractTermEnrollmentCounts(
  enrollmentCounts: Partial<ContractTermEnrollmentCount>
) {
  return createAction('ADD_CONTRACT_TERM_ENROLLMENT_COUNTS', enrollmentCounts);
}

export function addCatalogTokens(
  companyId: string,
  tokens: Pick<CompanyCatalogToken, 'token'>[]
) {
  return createAction('ADD_COMPANY_CATALOG_TOKENS', { companyId, tokens });
}

// dashboard data returned in company-by-slug:
const companyDashboardChartKeys: DashboardChartKeys[] = [
  'totalActiveEnrollments',
  'averageProjectCompletion',
];

// Async

export function fetchCompanyBySlug(
  companySlug: string,
  assessmentRouteParams?: AssessmentRouterParams,
  shouldCreateLearner?: boolean
): AppThunk {
  return (dispatch, getState) => {
    if (isCacheValid(getState, ['cache', 'activeCompanies', companySlug])) {
      return null;
    }

    dispatch(startLoading('COMPANY'));

    if (assessmentRouteParams?.cohortKey && shouldCreateLearner) {
      return dispatch(
        createAssessmentLearner(
          companySlug,
          { cohort_key: assessmentRouteParams.cohortKey },
          getAssessmentSubjectsPath(assessmentRouteParams)
        )
      );
    }

    const isStaffOrAdmin = hasCompanyAdminPermissionsOrHigherSelector(
      getState()
    );
    const isCompanyManager = hasViewOnlyPermissionsOrHigherSelector(getState());

    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/get-company-by-slug.gql')), {
        companySlug,
        isStaffOrAdmin,
        isCompanyManager,
      })
      .then((res: IGqlResponse<GetCompanyBySlugQuery>) => {
        const companyData = res?.data?.data?.companyBySlug;
        if (!companyData) {
          const errCode = getGraphQLErrorCode(res.data.errors);
          if (errCode === ErrorCode.PublicCatalogOff) {
            return authenticateCurrentUser();
          }
          return dispatch(addServerError({ message: 'Company not found' }));
        }
        const companyId = companyData.id;

        const company = {
          [companyId]: companyData,
        };

        batch(() => {
          dispatch(addCompanies(company as CompaniesReduxState));
          dispatch(cacheActiveCompany(companySlug));
          dispatch(
            addActiveCompany(companySlug, companyData as Partial<Company>)
          );
          companyDashboardChartKeys.forEach((chartKey) => {
            dispatch(
              updateChartProperty(
                companyId,
                'dashboard',
                chartKey,
                companyData[chartKey]
              )
            );
          });
          dispatch(stopLoading('COMPANY'));
        });
      })
      .catch((error) => {
        dispatch(addServerError(error));
        dispatch(stopLoading('COMPANY'));
      });
  };
}

export function fetchCompany(companyId: string): AppThunk {
  return (dispatch) => {
    dispatch(startLoading('COMPANY'));

    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/get-company.gql')), {
        companyId,
      })
      .then((res: IGqlResponse<GetCompanyQuery>) => {
        const normalizedData = normalize<Company, CompanyNormalizedEntities>(
          res.data?.data?.company || {},
          companyNormSchema
        );

        const company = normalizedData?.entities?.companies || {};
        dispatch(addCompanies(company));
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('COMPANY')));
  };
}

export function fetchCompanies(): AppThunk<Promise<void>> {
  return (dispatch, getState) => {
    if (isCacheValid(getState, ['cache', 'allCompaniesAndTypes'])) {
      return Promise.resolve();
    }

    dispatch(startLoading('COMPANIES'));
    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/all-companies-types.gql')), {
        status: 'APPROVED',
      })
      .then((res: IGqlResponse<AllCompaniesAndTypesQuery>) => {
        const data = res.data?.data;
        const companies = data?.allCompanies || [];
        const parentCompanies = data?.parentCompanies || [];
        const types = data?.allCompanyTypes || [];

        batch(() => {
          dispatch(cacheCompanies());
          dispatch(addCompanyTypes(types));
          dispatch(
            addCompanies(
              indexBy((co) => co.id, companies as NormalizedCompany[])
            )
          );
          dispatch(
            addParentCompanies(
              indexBy(
                (parentCompany) => parentCompany.id,
                parentCompanies as NormalizedParentCompany[]
              )
            )
          );
        });
      })
      .catch((error) => {
        dispatch(addServerError(error));
      })
      .then(() => {
        dispatch(stopLoading('COMPANIES'));
      });
  };
}

export function fetchCompaniesOfStatus(): AppThunk {
  return (dispatch) => {
    dispatch(startLoading('COMPANIES'));
    const queryParams = getQueryParams();
    const status = queryParams.status || Status.Approved;

    if (status === Status.Approved) {
      // already been hydrated
      return dispatch(stopLoading('COMPANIES'));
    }

    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/all-companies.gql')), {
        status: status === ALL ? undefined : status,
      })
      .then((res: IGqlResponse<AllCompaniesQuery>) => {
        const companies = res.data?.data?.allCompanies || [];

        dispatch(
          addCompanies(indexBy((co) => co.id, companies as NormalizedCompany[]))
        );
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('COMPANIES')));
  };
}

export function updateCompany(
  values: UpdateCompanyMutationVariables
): AppThunk {
  return (dispatch) => {
    dispatch(startLoading('COMPANY'));

    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/update-company.gql')), {
        ...values,
        // TODO: simplify upon backend enum update as status will be Status enum
        status: values.status?.toLowerCase(),
      })
      .then((res: IGqlResponse<UpdateCompanyMutation>) => {
        const company = res.data?.data?.updateCompany?.company;

        if (!company) {
          return dispatch(flashGraphqlError(res.data.errors));
        }

        dispatch(addFlashMessage('success', 'Company Updated'));
        dispatch(addCompanies({ [company.id]: company as NormalizedCompany }));
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('COMPANY')));
  };
}
