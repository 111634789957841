import { INewLearnersSessionState } from '@emc/reducer/session/new-learners';
import { IRootStore } from '@emc/reducer/types';
import { Normalized } from 'app/types/shared';
import { createSelector } from 'reselect';
import { currentCompanyIdSelector } from '../companies/companies';
import { pathOr } from 'ramda';

export const newLearnersSelector = (state: IRootStore) =>
  state.session.newLearners;

export const newLearnersSessionSelector = createSelector<
  [
    (state: IRootStore) => Normalized<INewLearnersSessionState>,
    (state: IRootStore) => string
  ],
  INewLearnersSessionState
>(newLearnersSelector, currentCompanyIdSelector, (newLearnersData, companyId) =>
  pathOr({}, [companyId])(newLearnersData)
);
