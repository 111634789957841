import { ContractableProgram } from 'app/types/generated/emc';
import { ContractableProgramsReduxState } from 'app/reducer/data/types';
import { IRootStore } from 'app/reducer/types';
import { createSelector } from 'reselect';
import { currentCompanyIdSelector } from '../companies/companies';

export const companyProgramsSelector = (state: IRootStore) =>
  state.data.companyPrograms;

export const contractableProgramsSelector = (state: IRootStore) =>
  state.data.contractablePrograms;

export const companyContractableProgramsSelector = createSelector<
  [
    (state: IRootStore) => ContractableProgramsReduxState,
    (state: IRootStore) => string
  ],
  ContractableProgram[] | undefined
>(
  contractableProgramsSelector,
  currentCompanyIdSelector,
  (contractableProgramsData, companyId) => {
    return contractableProgramsData[companyId];
  }
);
