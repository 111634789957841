import { AuthorizedRoute, AuthorizedRouteProps } from './authorized-route';
import {
  COMPANY_ADMIN_ROLE,
  LEARNER_MANAGER_ROLE,
  VIEW_ONLY_ROLE,
} from 'app/utils/constants';
import {
  emcUserLearnersSelector,
  isUdacityStaffSelector,
} from 'app/selectors/me';

import React from 'react';
import { RouteProps } from 'react-router-dom';
import { currentCompanyIdSelector } from 'app/selectors/companies/companies';
import { isAuthorizedBasedOnRoles } from 'app/utils/auth/is-authorized-based-on-roles';
import { useSelector } from 'react-redux';

const onlyRolesRoute = (
  authorizedRoles: string[]
): React.FC<AuthorizedRouteProps> => {
  const OnlyRolesRoute: React.FC<AuthorizedRouteProps> = (props) => {
    const companyId = useSelector(currentCompanyIdSelector);
    const learners = useSelector(emcUserLearnersSelector);

    const roles =
      learners
        .find((learner) => learner.companyId === companyId)
        ?.roles?.map((role) => role.name) || [];
    const isAuthorized = isAuthorizedBasedOnRoles(roles, authorizedRoles);
    const isUdacityStaff = useSelector(isUdacityStaffSelector);
    const augmentedProps = {
      ...props,
      isAuthorized: isAuthorized || isUdacityStaff,
    };
    return <AuthorizedRoute {...augmentedProps} />;
  };
  return OnlyRolesRoute;
};

export const CompanyAdminRoute = onlyRolesRoute([COMPANY_ADMIN_ROLE]);

export const LearnerManagerRoute = onlyRolesRoute([
  COMPANY_ADMIN_ROLE,
  LEARNER_MANAGER_ROLE,
]);

export const ViewOnlyRoute = onlyRolesRoute([
  COMPANY_ADMIN_ROLE,
  LEARNER_MANAGER_ROLE,
  VIEW_ONLY_ROLE,
]);

export const StaffRoute: React.FC<RouteProps> = (props) => {
  const isUdacityStaff = useSelector(isUdacityStaffSelector);
  return <AuthorizedRoute isAuthorized={isUdacityStaff} {...props} />;
};
