import { IProgramVersion } from 'app/types/programs';
import { last } from 'ramda';

function sortVersions(vA: IProgramVersion, vB: IProgramVersion) {
  // version string could be a non-number (e.g. 'construction')--these are considered the oldest
  if (isNaN(parseInt(vA.version))) return -1;
  if (isNaN(parseInt(vB.version))) return 1;

  const valsA = vA.version.split('.');
  const valsB = vB.version.split('.');
  for (let i = 0; i < Math.min(valsA.length, valsB.length); i++) {
    const diff = parseInt(valsA[i]) - parseInt(valsB[i]);
    if (diff) return diff;
    continue;
  }

  if (vA.version.length < vB.version.length) return -1;
  return 1;
}

/** check whether any one version is the latest, according to semver
    e.g.: no default, version nums are: '1.0', '1.0.1', '1.1', 'construction'
    => '1.1' isDefactoDefaultVersion
 */
export function getDefactoDefaultVersion(
  versions: IProgramVersion[]
): string | undefined {
  const sortedVersions = [...versions].sort(sortVersions);
  return last(sortedVersions)?.version;
}

export function isDefactoDefaultVersion(
  versions: IProgramVersion[],
  version: string
): boolean {
  return version === getDefactoDefaultVersion(versions);
}
