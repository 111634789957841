import Analytics from '@udacity/ureact-analytics';
import auth from '@udacity/ureact-hoth';

export function track(actionName: string, options = {}) {
  Analytics.track(actionName, {
    userId: auth.getCurrentUserId(),
    ...options,
  });
}

export function page(actionName: string, options = {}) {
  Analytics.page(actionName, {
    userId: auth.getCurrentUserId(),
    ...options,
  });
}
