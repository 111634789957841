import {
  getQueryParams,
  persistUtmParams,
} from 'app/utils/router/query-params';

import { ALL } from 'app/utils/constants';
import auth from '@udacity/ureact-hoth';
import { companyHasPublicCatalog } from 'app/utils/settings';
import { currentCompanySelector } from 'app/selectors/companies/companies';
import { fetchCompanyCatalogTokens } from 'app/actions/learner-self-serve';
import { isNil } from 'ramda';
import { useAppDispatch } from 'app/hooks/use-app-dispatch';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const LEGACY_CATALOG_TOKEN = 'legacy';
export const CATALOG_TOKEN_PLACEHOLDER = 't';
const INVALID_TOKEN_MATCHES = ['i', 'nominate', 'role'];

export const useCatalogPath = () => {
  const company = useSelector(currentCompanySelector);
  const dispatch = useAppDispatch();
  const match = useRouteMatch<{ token?: string }>(
    '/c/:companySlug/catalog/:token?'
  );
  const isLoggedIn = Boolean(auth.getCurrentUserId());

  const shouldFetchTokens =
    company.id &&
    company.id !== ALL &&
    isNil(company.catalogTokens) &&
    isLoggedIn;

  const hasPublicCatalog = companyHasPublicCatalog(
    company.companySettings || []
  );

  useEffect(() => {
    shouldFetchTokens && dispatch(fetchCompanyCatalogTokens(company.id));
  }, [dispatch, company.id, shouldFetchTokens]);

  const token = company.catalogTokens?.find(
    ({ token }) => token !== LEGACY_CATALOG_TOKEN
  );
  const legacyToken = company.catalogTokens?.find(
    ({ token }) => token === LEGACY_CATALOG_TOKEN
  );

  const tokenSlug =
    (hasPublicCatalog && (token?.token || legacyToken?.token)) ||
    getQueryParams()?.token ||
    (!INVALID_TOKEN_MATCHES.includes(match?.params.token || '') &&
      match?.params.token) ||
    CATALOG_TOKEN_PLACEHOLDER;

  const getCatalogPath = (path?: string) => {
    return persistUtmParams(
      `/c/${company.slug}/catalog/${tokenSlug}${path || ''}`
    );
  };

  return { getCatalogPath, tokenSlug };
};
