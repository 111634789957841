import { AppThunk, IGqlResponse } from 'app/types/shared';
import { ClassroomCatalog, IProgram } from 'app/types/programs';
import { isEmpty, values } from 'ramda';

import { CatalogResponse } from 'app/types/classroom';
import { ResourceType } from 'app/types/generated/emc';
import { addClassroomCatalog } from './catalog';
import { classConService } from 'app/services/classroom-content-service';
import { classroomCatalogSelector } from 'app/selectors/catalog';
import { datadogRum } from '@datadog/browser-rum';
import { parseClassConProgramsResponse } from 'app/utils/classroom';

const NDS_QUERY = `
query EMC_Nanodegrees ($key: String, $locale: String) {
	nanodegrees(key: $key, locale: $locale) {
    title
    key
    version
    summary
    is_public
    semantic_type
    id
    hero_image {
      url
      width
      height
    }
	}
}`;

const PART_QUERY = `
query EMC_Part ($key: String, $locale: String) {
	part(key: $key, locale: $locale) {
    title
    key
    version
    summary
    is_public
    semantic_type
    id
    image {
      url
      width
      height
    }
	}
}`;

const COURSES_QUERY = `
query EMC_Courses ($key: String, $locale: String) {
	courses(key: $key, locale: $locale) {
    title
    key
    version
    summary
    is_public
    semantic_type
    id
	}
}`;

export function getClassroomProgram(
  programKey: string,
  programType: ResourceType,
  defaultProgram: IProgram
): AppThunk {
  let query = PART_QUERY;
  if (programType === ResourceType.Degree) {
    query = NDS_QUERY;
  }
  if (programType === ResourceType.Course) {
    query = COURSES_QUERY;
  }

  return (dispatch) => {
    classConService
      .gql(query, { key: programKey, locale: 'en-us' })
      .then((res: CatalogResponse) => {
        const programs = parseClassConProgramsResponse(res);
        if (isEmpty(programs)) {
          datadogRum.addError(
            new Error(
              `program ${programKey} ${programType} does not exist in mocha`
            )
          );
        }

        dispatch(addClassroomCatalog(programs));
      })
      .catch((err) => {
        dispatch(addClassroomCatalog({ [defaultProgram.key]: defaultProgram }));
      });
  };
}

export function fetchProgramTitles(
  programKeys: string[]
): AppThunk<Promise<IProgram[]>> {
  return (dispatch, getState) => {
    const classroomCatalog = classroomCatalogSelector(getState());
    const fetchedPrograms: IProgram[] = [];
    const keysToFetch = programKeys.filter((programKey) => {
      const fetchedProgram = classroomCatalog[programKey];
      if (fetchedProgram) fetchedPrograms.push(fetchedProgram);
      return !fetchedProgram;
    });
    if (keysToFetch.length === 0) return Promise.resolve(fetchedPrograms);

    dispatch(
      addClassroomCatalog(
        keysToFetch.reduce<ClassroomCatalog>((acc, key) => {
          acc[key] = { key } as IProgram;
          return acc;
        }, {})
      )
    );

    const query = `query EMC_ProgramTitles {
    ${keysToFetch.map((programKey) => {
      const sanitizedProgramKey = programKey.replace(/-/g, '');
      return `program_${sanitizedProgramKey}: node(key: "${programKey}", locale: "en-us") {
         title key semantic_type
         ... on Course { summary }
         ... on Part { summary }
         ... on Nanodegree { summary }
        }`;
    })}
  }`;

    return classConService.gql(query).then(
      (
        res: IGqlResponse<
          Record<
            string,
            {
              title: string;
              key: string;
              summary: string | null;
              semantic_type: 'Course' | 'Degree' | 'Part';
            }
          >
        >
      ) => {
        const programs: ClassroomCatalog = {};
        const programMap = res.data.data;
        for (const programToken in programMap) {
          const program = programMap[programToken];
          if (program) {
            programs[program.key] = {
              title: program.title,
              key: program.key,
              summary: program.summary || '',
              versions: [],
              semanticType: ResourceType[program.semantic_type],
            };
          }
        }
        dispatch(addClassroomCatalog(programs));
        return [...fetchedPrograms, ...values(programs)];
      }
    );
  };
}
