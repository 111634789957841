import { LearnerNormalizedEntities, learnerNormSchema } from './learner';

import { LearnerInterest } from 'app/types/generated/emc';
import { LearnerInterestReduxState } from 'app/reducer/data/types';
import { schema } from 'normalizr';

export type LearnerInterestNormalizedEntities = LearnerNormalizedEntities & {
  learnerInterests: LearnerInterestReduxState;
};

export const learnerInterestNormSchema = new schema.Entity<LearnerInterest>(
  'learnerInterests',
  { learner: learnerNormSchema }
);
