import {
  AppThunk,
  IGqlResponse,
  Normalized,
  PollingProgress,
} from 'app/types/shared';
import {
  BatchProgressKeys,
  updateBatchProgress,
  updatePollingProperty,
} from '../polling';
import { GetBatchProgressQuery, PublicQuery } from 'app/types/generated/emc';
import {
  companyPollingSelector,
  currentCompanyIdSelector,
} from 'app/selectors/companies/companies';
import { flatten, values } from 'ramda';
import {
  getPaginatedItems,
  getQueryString,
} from 'app/utils/graphql/serializer';

import { addServerError } from '../flash';
import { emcService } from '@emc/services';
import { fetchEnrollableContractTerms } from '../contract-terms/enrollable-contract-terms';
import { isTesting } from 'app/test/utils';
import { loader } from 'graphql.macro';
import { poll } from '../util';

export enum lifecycleFlagTypes {
  SCHEDULED = 'SCHEDULED',
  DISCARDED = 'DISCARDED',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
}

// Async

function fetchLifecycleBatchProgress(
  companyId: string,
  batchIds: string[]
): AppThunk<Promise<PollingProgress>> {
  return (dispatch) => {
    const queryString = `query LifecycleBatchProgress {
      ${batchIds.map(
        (batchId) =>
          `learnerLifecycleBatches${batchId.replace(
            /-/g,
            ''
          )}: learnerLifecycleBatches(batchId: "${batchId}", companyId: "${companyId}") {
          edges {
            node {
              batchId
              all
              completed
              errors
            }
          }
        }`
      )}
    }`;

    return emcService
      .gql(queryString)
      .then(
        (
          res: IGqlResponse<Normalized<PublicQuery['learnerLifecycleBatches']>>
        ) => {
          const batches = values(res.data.data);
          const batchList = flatten(
            batches.map((batch) => getPaginatedItems(batch))
          );
          const batch = batchList.reduce<PollingProgress>(
            (combinedBatch, batchPart) => {
              combinedBatch.all =
                (combinedBatch.all || 0) + (batchPart?.all || 0);
              combinedBatch.errors =
                (combinedBatch.errors || 0) + (batchPart?.errors || 0);
              combinedBatch.completed =
                (combinedBatch.completed || 0) + (batchPart?.completed || 0);
              return combinedBatch;
            },
            {
              batchIds,
              completed: 0,
              all: 0,
              errors: 0,
            }
          );

          return batch as PollingProgress;
        }
      )
      .catch((err) => {
        dispatch(addServerError(err));
        return {
          batchIds: batchIds,
          completed: 0,
          all: 0,
          errors: 0,
        } as PollingProgress;
      });
  };
}

export function fetchLearnerBatchProgress(
  batchId: string
): AppThunk<Promise<PollingProgress>> {
  return (dispatch) =>
    emcService
      .gql(getQueryString(loader('@emc/queries/emc/get-batch-progress.gql')), {
        batchId,
      })
      .then((res: IGqlResponse<GetBatchProgressQuery>) => {
        const progressSummary = res.data.data.progressSummary;
        return {
          completed: progressSummary?.succeeded || 0,
          all: progressSummary?.total || 0,
          errors: progressSummary?.failed || 0,
          batchIds: [batchId],
        };
      })
      .catch((err) => {
        dispatch(addServerError(err));
        return {
          batchIds: [batchId],
          completed: 0,
          all: 0,
          errors: 0,
        } as PollingProgress;
      });
}

export function pollForLearnerLifecycleProgress(
  batchIds: string[],
  key: BatchProgressKeys = BatchProgressKeys.enrollments,
  onComplete?: () => void
): AppThunk {
  return (dispatch, getState) => {
    const state = getState();
    const companyId = currentCompanyIdSelector(state);

    dispatch(updatePollingProperty(companyId, key, true));
    poll(
      () => {
        return dispatch(fetchLifecycleBatchProgress(companyId, batchIds))
          .then((batchProgress) => {
            const isPolling = companyPollingSelector(getState())[key];
            if (!isPolling) return true;
            const isComplete =
              (batchProgress?.all || 1) <=
              (batchProgress?.completed || 0) + (batchProgress?.errors || 0);

            dispatch(updateBatchProgress(companyId, key, batchProgress));
            if (isComplete) {
              dispatch(updatePollingProperty(companyId, key, false));
              // enable refreshing view, and always update enrollment counts in contract terms
              setTimeout(
                () => {
                  dispatch(updateBatchProgress(companyId, key, null));
                  dispatch(fetchEnrollableContractTerms(companyId, true));
                  onComplete && onComplete();
                },
                isTesting ? 1000 : 3000
              );
            }
            return isComplete;
          })
          .catch((err) => {
            dispatch(addServerError(err));
            return false;
          });
      },
      undefined,
      500
    );
  };
}
