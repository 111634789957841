export function poll(
  getResult: () => Promise<boolean>,
  timeout = 180000,
  interval = 3000
): Promise<boolean> {
  const endTime = Number(new Date()) + timeout;

  const checkCondition = function (
    resolve: (isSatisfied: boolean) => void,
    reject: (err: Error) => void
  ) {
    getResult()
      .then((isSatisfied: boolean) => {
        if (isSatisfied) {
          return resolve(isSatisfied);
        }

        if (Number(new Date()) < endTime) {
          return setTimeout(checkCondition, interval, resolve, reject);
        }

        reject(new Error('Time Out'));
      })
      .catch(() => reject(new Error('API Error')));
  };

  return new Promise(checkCondition);
}
