import { EnrollmentActionTypes } from 'app/utils/constants';
import { companyLearnersUpdateProperty } from 'app/actions/learners/fetch';

export interface ICompanyLearnersSession {
  action: EnrollmentActionTypes;
  groupAction: 'add' | 'remove';
  learningPathAction: 'add' | 'remove';
  learningPathId: string;
  unenrollableContractTermId: string;
  enrollableContractTermId: string;
  groupId: string;
}

export interface ILearnersSessionState {
  [companyId: string]: ICompanyLearnersSession;
}
const initialState = {};

export default function companyLearners(
  state: ILearnersSessionState = initialState,
  action: ReturnType<typeof companyLearnersUpdateProperty>
): ILearnersSessionState {
  switch (action.type) {
    case 'COMPANY_LEARNERS_UPDATE_PROPERTY': {
      const companyId = action.payload.companyId;

      return {
        ...state,
        [companyId]: {
          ...state[companyId],
          [action.payload.property]: action.payload.value,
        },
      };
    }

    default: {
      return state;
    }
  }
}
