import { AppThunk, IGqlResponse } from 'app/types/shared';
import {
  CreateLearnerSelfServeMutation,
  CreateSelfServeTokenMutation,
  GetCompanyCatalogTokensQuery,
  Learner,
} from 'app/types/generated/emc';
import { startLoading, stopLoading } from './loading';

import { addCatalogTokens } from './companies';
import { emcService } from '@emc/services';
import { getGraphQLErrorMessage } from 'app/utils/errors';
import { getQueryString } from 'app/utils/graphql/serializer';
import { loader } from 'graphql.macro';
import { loadingSelector } from 'app/selectors/session';

export async function createSelfServeToken(
  companyId: string
): Promise<{ token?: string; error?: string }> {
  try {
    const result: IGqlResponse<CreateSelfServeTokenMutation> =
      await emcService.gql(
        getQueryString(loader('@emc/queries/emc/create-self-serve-token.gql')),
        {
          companyId,
        }
      );
    const token = result.data.data?.createSelfServeToken?.id || '';
    const error = getGraphQLErrorMessage(result.data.errors);
    return { token, error };
  } catch (err) {
    return { error: (err as Error).message };
  }
}

export async function createLearnerSelfServe(
  tokenId: string
): Promise<{ learner?: Pick<Learner, 'id'>; error?: string }> {
  try {
    const result: IGqlResponse<CreateLearnerSelfServeMutation> =
      await emcService.gql(
        getQueryString(
          loader('@emc/queries/emc/create-learner-self-serve.gql')
        ),
        {
          tokenId,
        }
      );
    const learner = result.data.data.createLearnerSelfServe || undefined;
    const error = (result.data.errors || [{}])[0].message;
    return { learner, error };
  } catch (err) {
    return { error: (err as Error).message };
  }
}

export function fetchCompanyCatalogTokens(companyId: string): AppThunk {
  return (dispatch, getState) => {
    if (loadingSelector(getState())['CATALOG_TOKENS']) {
      return Promise.resolve();
    }
    dispatch(startLoading('CATALOG_TOKENS'));
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/get-company-catalog-tokens.gql')
        ),
        {
          companyId,
        }
      )
      .then((res: IGqlResponse<GetCompanyCatalogTokensQuery>) => {
        const tokens = res.data.data?.company?.catalogTokens;
        if (tokens) {
          dispatch(addCatalogTokens(companyId, tokens));
        }
        dispatch(stopLoading('CATALOG_TOKENS'));
      });
  };
}
