import { lensPath, mergeDeepRight, set } from 'ramda';

import { EnrollmentsReduxState } from './types';
import { addContractTermEnrollmentCounts } from 'app/actions/companies';
import { addEnrollments } from 'app/actions/enrollments/enrollments';

const initialState = {
  all: [],
  countsByContractTerm: {},
};

export default function enrollments(
  state: EnrollmentsReduxState = initialState,
  action:
    | ReturnType<typeof addContractTermEnrollmentCounts>
    | ReturnType<typeof addEnrollments>
): EnrollmentsReduxState {
  switch (action.type) {
    case 'ADD_ENROLLMENTS': {
      const allLens = lensPath(['all']);

      return set(allLens, action.payload, state);
    }

    case 'ADD_CONTRACT_TERM_ENROLLMENT_COUNTS': {
      return set(
        lensPath(['countsByContractTerm']),
        mergeDeepRight(state.countsByContractTerm)(action.payload),
        state
      );
    }

    default: {
      return state;
    }
  }
}
