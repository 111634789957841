import { AppThunk, IGqlResponse } from 'app/types/shared';
import {
  CompanyProgram,
  ContractableProgram,
  GetCompanyContractableProgramsQuery,
  GetCompanyProgramQuery,
  LearnerInterest,
  LearnerInterestsQuery,
  PublicMutationRemoveInterestInProgramArgs,
  PublicMutationShowInterestInProgramArgs,
  QuestionSet,
  RemoveInterestInProgramMutation,
  ShowInterestInProgramMutation,
  UpdateProgramMutation,
  UpdateProgramMutationVariables,
} from 'app/types/generated/emc';
import { addLearnerInterest, setLearnerInterests } from '../interest';
import { addServerError, flashGraphqlError } from '../flash';
import { startLoading, stopLoading } from '../loading';

import { EnterpriseCatalogReduxState } from 'app/reducer/data/types';
import { addEnterpriseCatalog } from '../catalog';
import { addQuestionSets } from '../admissions';
import auth from '@udacity/ureact-hoth';
import { companyProgramsSelector } from 'app/selectors/programs';
import { createAction } from 'app/utils/actions';
import { emcService } from 'app/services';
import { getQueryString } from 'app/utils/graphql/serializer';
import { hasViewOnlyPermissionsOrHigherSelector } from 'app/selectors/me';
import { indexBy } from 'ramda';
import { loader } from 'graphql.macro';

export function addCompanyProgram(programKey: string, data: CompanyProgram) {
  return createAction('ADD_COMPANY_PROGRAM', { programKey, data });
}

export function addContractablePrograms(
  companyId: string,
  programs: ContractableProgram[]
) {
  return createAction('ADD_CONTRACTABLE_PROGRAMS', { companyId, programs });
}

export function increaseInterestCount(companyId: string, programKey: string) {
  return createAction('INCREASE_INTEREST_COUNT', {
    companyId,
    programKey,
  });
}

export function decreaseInterestCount(companyId: string, programKey: string) {
  return createAction('DECREASE_INTEREST_COUNT', {
    companyId,
    programKey,
  });
}

export function updateProgram(
  values: UpdateProgramMutationVariables
): AppThunk<Promise<boolean>> {
  return (dispatch) => {
    dispatch(startLoading('QUESTION_SETS'));

    return emcService
      .gql(
        getQueryString(loader('@emc/queries/emc/update-program.gql')),
        values
      )
      .then((res: IGqlResponse<UpdateProgramMutation>) => {
        const ok = res.data?.data?.updateProgram?.ok;

        if (ok !== true) {
          dispatch(flashGraphqlError(res.data.errors));
          return false;
        }
        dispatch(fetchProgramByKey(values.companyId, values.programKey));
        dispatch(stopLoading('QUESTION_SETS'));
        return true;
      })
      .catch((error) => {
        dispatch(stopLoading('QUESTION_SETS'));
        dispatch(addServerError(error));
        return false;
      });
  };
}

export function fetchProgramByKey(
  companyId: string,
  programKey: string,
  overrideCache?: boolean
): AppThunk {
  return (dispatch, getState) => {
    const state = getState();
    const isManager = hasViewOnlyPermissionsOrHigherSelector(state);
    const hasProgramBeenHydrated = companyProgramsSelector(state)[programKey];
    if (hasProgramBeenHydrated && !overrideCache) return;
    dispatch(startLoading('PROGRAM'));
    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/get-company-program.gql')), {
        programKey,
        companyId,
        isManager,
      })
      .then((res: IGqlResponse<GetCompanyProgramQuery>) => {
        const program = res.data?.data?.company?.programs?.edges[0]?.node;
        const questionSet = program?.questionSet;
        if (program && programKey) {
          dispatch(addCompanyProgram(programKey, program as CompanyProgram));
        }
        const questionSetId = questionSet?.id;
        if (questionSetId) {
          dispatch(
            addQuestionSets({ [questionSetId]: questionSet as QuestionSet })
          );
        }
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('PROGRAM')));
  };
}

export function fetchContractablePrograms(companyId: string): AppThunk {
  return (dispatch) => {
    dispatch(startLoading('CONTRACTABLE_PROGRAMS'));
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/get-contractable-programs.gql')
        ),
        {
          companyId,
        }
      )
      .then((res: IGqlResponse<GetCompanyContractableProgramsQuery>) => {
        const programs = res.data?.data?.company?.contractablePrograms;
        if (res.data.errors) {
          throw (
            (res.data.errors || [])[0] ||
            new Error(
              'An error occured while fetching company contractable programs.'
            )
          );
        }
        const catalogPrograms = indexBy(
          (catalogProgram) => catalogProgram?.programKey || '0',
          programs?.map((program) => program.catalogProgram) || []
        );
        dispatch(
          addEnterpriseCatalog(
            (catalogPrograms as EnterpriseCatalogReduxState) || {}
          )
        );
        dispatch(
          addContractablePrograms(companyId, programs as ContractableProgram[])
        );
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('CONTRACTABLE_PROGRAMS')));
  };
}

export function showInterestInProgram(
  args: PublicMutationShowInterestInProgramArgs
): AppThunk<Promise<string | undefined>> {
  return (dispatch) => {
    dispatch(startLoading('CONTRACTABLE_PROGRAMS'));
    return emcService
      .gql(
        getQueryString(loader('@emc/queries/emc/show-interest-in-program.gql')),
        args
      )
      .then((res: IGqlResponse<ShowInterestInProgramMutation>) => {
        const interest = res.data.data.showInterestInProgram;

        // If BE returns a redirect link then redirect the user to the link
        if ((res.data.errors || [])[0]?.extensions?.redirect_link) {
          return (res.data.errors || [])[0].extensions?.redirect_link;
        } else if (res.data.errors) {
          dispatch(flashGraphqlError(res.data.errors));
        }
        if (interest) {
          dispatch(increaseInterestCount(args.companyId, args.programKey));
          dispatch(addLearnerInterest(interest as LearnerInterest));
        }
        return undefined;
      })
      .catch((error) => dispatch(addServerError(error)))
      .then((redirect_link) => {
        dispatch(stopLoading('CONTRACTABLE_PROGRAMS'));
        return redirect_link || undefined;
      });
  };
}

export function fetchLearnerInterests(companyId: string): AppThunk {
  return (dispatch) => {
    const userKey = auth.getCurrentUserId();
    return emcService
      .gql(
        getQueryString(loader('@emc/queries/emc/get-learner-interests.gql')),
        {
          userKey,
          companyId,
        }
      )
      .then((res: IGqlResponse<LearnerInterestsQuery>) => {
        const rawLearner = (res.data.data?.learnersByUserKey || [])[0];
        const learnerId = rawLearner?.id;
        const interests = rawLearner?.interests;
        if (learnerId && interests) {
          dispatch(
            setLearnerInterests(interests as LearnerInterest[], learnerId)
          );
        }
      })
      .catch((error) => dispatch(addServerError(error)));
  };
}

export function removeInterestInProgram(
  args: PublicMutationRemoveInterestInProgramArgs
): AppThunk {
  return (dispatch) => {
    dispatch(startLoading('CONTRACTABLE_PROGRAMS'));
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/remove-interest-in-program.gql')
        ),
        args
      )
      .then((res: IGqlResponse<RemoveInterestInProgramMutation>) => {
        const isSuccess = res.data.data.removeInterestInProgram?.ok === true;
        if (res.data.errors || !isSuccess) {
          dispatch(flashGraphqlError(res.data.errors));
        }
        dispatch(decreaseInterestCount(args.companyId, args.programKey));
        dispatch(fetchLearnerInterests(args.companyId));
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('CONTRACTABLE_PROGRAMS')));
  };
}
