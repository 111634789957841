import { ParentCompaniesReduxState } from './types';
import { addParentCompanies } from 'app/actions/companies';
import { mergeDeepRight } from 'ramda';

export default function parentCompanies(
  state: ParentCompaniesReduxState = {},
  action: ReturnType<typeof addParentCompanies>
): ParentCompaniesReduxState {
  switch (action.type) {
    case 'ADD_PARENT_COMPANIES': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return mergeDeepRight(state, action.payload as any);
    }

    default: {
      return state;
    }
  }
}
