import { PollingProgress } from 'app/types/shared';
import { createAction } from 'app/utils/actions';

export enum PollingKeys {
  learnerLifecycles = 'learnerLifecycles',
  learnerInterestCSV = 'learnerInterestCSV',
}

export enum BatchProgressKeys {
  enrollments = 'enrollments',
  unenrollments = 'unenrollments',
  transfers = 'transfers',
  learners = 'learners',
}

export function updatePollingProperty(
  companyId: string,
  key: PollingKeys | BatchProgressKeys,
  value: boolean
) {
  return createAction('UPDATE_POLLING_PROPERTY', {
    companyId,
    key,
    value,
  });
}

export function updateBatchProgress(
  companyId: string,
  key: BatchProgressKeys,
  pollingProgress: PollingProgress | null
) {
  return createAction('UPDATE_BATCH_PROGRESS', {
    companyId,
    key,
    pollingProgress,
  });
}
