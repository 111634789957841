import {
  ContractTermNormalizedEntities,
  contractTermNormSchema,
} from './contract';
import {
  EnrollmentCohortsReduxState,
  LearnersReduxState,
} from 'app/reducer/data/types';

import { EnrollmentCohort } from 'app/types/generated/emc';
import { learnerNormSchema } from './learner';
import { schema } from 'normalizr';

export const enrollmentCohortNormSchema = new schema.Entity<EnrollmentCohort>(
  'enrollmentCohorts',
  {
    contractTerms: [contractTermNormSchema],
    learners: { edges: [{ node: learnerNormSchema }] },
  }
);

export type EnrollmentCohortNormalizedEntities =
  ContractTermNormalizedEntities & {
    learners?: LearnersReduxState;
    enrollmentCohorts?: EnrollmentCohortsReduxState;
  };
