import {
  AllContractTermsQuery,
  AllContractTermsQueryVariables,
  Company,
  Contract,
  ContractTerm,
  GetCompanyContractTermsQuery,
  GetContractTermQuery,
  PublicMutationUpdateContractTermsArgs,
  Status,
  UpdateContractTermsMutation,
} from 'app/types/generated/emc';
import { AppThunk, IGqlResponse, Normalized } from 'app/types/shared';
import {
  CompanyNormalizedEntities,
  companyNormSchema,
} from 'app/utils/normalize/company';
import {
  ContractNormalizedEntities,
  ContractTermNormalizedEntities,
  contractNormSchema,
  contractTermNormSchema,
} from 'app/utils/normalize/contract';
import {
  NormalizedContract,
  NormalizedContractTerm,
} from 'app/types/contracts';
import { PaginationProps, getPaginationData } from 'app/selectors/pagination';
import { addContracts, sessionContractUpdateProperty } from '../contracts';
import { addFlashMessage, addServerError, flashGraphqlError } from '../flash';
import {
  getPaginatedItems,
  getQueryString,
} from 'app/utils/graphql/serializer';
import { getTimeStamp, getTimeZone } from 'app/utils/time';
import { indexBy, keys, prop, values } from 'ramda';
import { startLoading, stopLoading } from '../loading';

import { Dispatch } from 'redux';
import { PaginationTypes } from '@emc/reducer/session/pagination';
import { addContractTermEnrollmentCounts } from '../companies';
import { addResources } from '../resources';
import { batch } from 'react-redux';
import { contractTermsPaginationSelector } from 'app/selectors/contract-terms/contract-terms';
import { createAction } from 'app/utils/actions';
import { emcService } from '@emc/services';
import { filterOutAllTimeQueryParam } from 'app/utils/dates/defaults';
import { getQueryParams } from 'app/utils/router/query-params';
import { isUdacityStaffSelector } from 'app/selectors/me';
import { loader } from 'graphql.macro';
import { loadingSelector } from 'app/selectors/session';
import { normalize } from 'normalizr';
import { routeToCompanyAdmin } from 'app/utils/router/paths';
import { updatePagination } from '../reports';

// Sync

export function addContractTerms(
  contractTerms: Normalized<NormalizedContractTerm>
) {
  return createAction('ADD_CONTRACT_TERMS', contractTerms);
}

// Async

export function fetchContractTerm(
  companyId: string,
  contractTermId: string
): AppThunk {
  return (dispatch) => {
    dispatch(startLoading('CONTRACT_TERM'));

    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/get-contract-term.gql')), {
        id: contractTermId,
      })
      .then((res: IGqlResponse<GetContractTermQuery>) => {
        const contractTerm = res.data?.data?.contractTerm;
        const normalizedData = normalize<
          ContractTerm,
          ContractTermNormalizedEntities
        >(contractTerm, contractTermNormSchema);
        const contractTerms = normalizedData?.entities?.contractTerms || {};
        const resources = normalizedData?.entities?.resources || {};

        const enrollmentCounts = contractTerm?.enrollmentCounts || {};

        batch(() => {
          dispatch(
            addContractTermEnrollmentCounts({
              [contractTermId]: enrollmentCounts,
            })
          );
          dispatch(addContractTerms(contractTerms));
          dispatch(addResources(companyId, resources));
        });

        return contractTerms;
      })
      .then(() => dispatch(stopLoading('CONTRACT_TERM')));
  };
}

export function updateContractSessionPagination(
  dispatch: Dispatch,
  status: Status,
  contractData: NormalizedContract
) {
  const contractTermIds = getPaginatedItems(contractData?.contractTerms);
  const contractId = contractData?.id;

  const totalContractTermCount = contractData?.contractTerms?.totalCount;
  dispatch(
    sessionContractUpdateProperty(
      contractId,
      status,
      'totalContractTermCount',
      totalContractTermCount
    )
  );

  dispatch(
    sessionContractUpdateProperty(
      contractId,
      status,
      'contractTermIds',
      contractTermIds
    )
  );
}

export function fetchContractTerms(paginationProps: PaginationProps): AppThunk {
  return (dispatch) => {
    dispatch(startLoading('CONTRACT_TERMS'));
    const { companyId, companyTypeId, status } = getQueryParams();

    const isExpired = status === 'expired';
    const statusParam = isExpired
      ? undefined
      : (filterOutAllTimeQueryParam(status) as Status);

    const paginationData: AllContractTermsQueryVariables = {
      ...getPaginationData(paginationProps),
      companyId: filterOutAllTimeQueryParam(companyId),
      companyTypeId: filterOutAllTimeQueryParam(companyTypeId),
      status: statusParam,
    };

    return emcService
      .gql(
        getQueryString(loader('@emc/queries/emc/all-contract-terms.gql')),
        paginationData
      )
      .then((res: IGqlResponse<AllContractTermsQuery>) => {
        const allContractTerms = res.data.data?.allContractTerms;
        const totalContractTermsCount = allContractTerms?.totalCount || 0;
        const normalizedData = normalize<Contract, ContractNormalizedEntities>(
          { id: 'all', contractTerms: allContractTerms },
          contractNormSchema
        );

        const contractTerms = normalizedData?.entities?.contractTerms || {};
        const contracts = normalizedData?.entities?.contracts || {};
        const resources = normalizedData?.entities?.resources || {};
        const contractTermIds: string[] = getPaginatedItems(
          normalizedData?.entities?.contracts?.all.contractTerms
        );

        batch(() => {
          dispatch(addContractTerms(contractTerms));
          dispatch(addContracts(contracts));
          dispatch(addResources('all', resources));
          dispatch(
            updatePagination(
              PaginationTypes.contractTermsOverview,
              totalContractTermsCount,
              contractTermIds
            )
          );
        });
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('CONTRACT_TERMS')));
  };
}

export function fetchCompanyContractTerms(
  companyId: string,
  fetchAllTerms?: boolean
): AppThunk {
  return (dispatch, getState) => {
    if (loadingSelector(getState())['CONTRACT_TERMS']) {
      return Promise.resolve();
    }
    dispatch(startLoading('CONTRACT_TERMS'));

    const state = getState();
    const paginationData = fetchAllTerms
      ? undefined
      : contractTermsPaginationSelector(state);
    const status =
      paginationData?.status && paginationData?.status.toUpperCase();
    const isUdacityStaff = isUdacityStaffSelector(state);

    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/get-company-contract-terms.gql')
        ),
        { ...paginationData, companyId, status, isStaff: isUdacityStaff }
      )
      .then((res: IGqlResponse<GetCompanyContractTermsQuery>) => {
        const normalizedData = normalize<Company, CompanyNormalizedEntities>(
          res.data.data?.company,
          companyNormSchema
        );
        const contracts = normalizedData?.entities?.contracts || {};
        const contractTerms = normalizedData?.entities?.contractTerms || {};
        const resources = normalizedData?.entities?.resources || {};
        const enrollmentCountsByContractTerm =
          normalizedData?.entities?.contractTermEnrollmentCounts || {};
        const contractIds = keys(contracts);

        batch(() => {
          contractIds.forEach((contractId) => {
            const contract = contracts[contractId];
            updateContractSessionPagination(
              dispatch,
              Status.Approved,
              contract
            );
            updateContractSessionPagination(dispatch, Status.Draft, {
              ...contract,
              contractTerms: contract.draftTerms,
            });
            updateContractSessionPagination(dispatch, Status.Suspended, {
              ...contract,
              contractTerms: contract.suspendedTerms,
            });
          });
          dispatch(
            addContractTermEnrollmentCounts(enrollmentCountsByContractTerm)
          );
          dispatch(addContracts(contracts));
          dispatch(addContractTerms(contractTerms));
          dispatch(addResources(companyId, resources));
        });
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('CONTRACT_TERMS')));
  };
}

export function updateContractTerms(
  variables: PublicMutationUpdateContractTermsArgs,
  shouldShowFlash = true
): AppThunk<Promise<boolean>> {
  return (dispatch): Promise<boolean> => {
    return emcService
      .gql(
        getQueryString(loader('@emc/queries/emc/update-contract-terms.gql')),
        variables
      )
      .then((res: IGqlResponse<UpdateContractTermsMutation>) => {
        const data = res.data.data?.updateContractTerms;
        if (data?.ok !== true) {
          dispatch(flashGraphqlError(res.data.errors));
          return false;
        }
        const contractTerms = indexBy(prop('id'), data?.contractTerms || []);
        batch(() => {
          dispatch(
            addContractTerms(
              contractTerms as Normalized<NormalizedContractTerm>
            )
          );
          if (shouldShowFlash) {
            dispatch(addFlashMessage('success', 'Contract Term Updated'));
          }
        });
        return data?.ok;
      })
      .catch((error) => {
        dispatch(addServerError(error));
        return false;
      });
  };
}

function handleUnenrollResult(
  response: { data: { success: boolean; reason: string } },
  dispatch: Dispatch,
  companyId: string
): void {
  const result = response.data;
  if (!result.success) {
    throw new Error(
      `Something went wrong, learners were not unenrolled: ${result.reason}`
    );
  }
  dispatch(
    addFlashMessage(
      'success',
      'Learners unenrolled! Changes may take a minute to reflect in the UI'
    )
  );
  routeToCompanyAdmin(companyId);
}

export function unenrollContractTerm(
  contractTermId: string,
  companyId: string
): AppThunk {
  return async (dispatch) => {
    dispatch(startLoading('CONTRACT_TERM'));
    try {
      const timestamp = getTimeStamp();
      const timeZone = getTimeZone();
      const response = await emcService.post(
        `/companies/${companyId}/contract-terms/${contractTermId}/enrollments/unenroll?client_timestamp=${timestamp}&client_timezone=${timeZone}`
      );
      handleUnenrollResult(response, dispatch, companyId);
    } catch (err) {
      batch(() => {
        dispatch(addServerError(err as Error));
        dispatch(stopLoading('CONTRACT_TERM'));
      });
    }
  };
}

export function fetchContractTermsByIds(contractTermIds: string[]): AppThunk {
  const queryString = `
  query ContractTermsById {
  ${contractTermIds.reduce((str, termId) => {
    return `${str}
    contractTerm_${termId}: contractTerm(id: ${termId}) {
      id
      contractId
      enrollmentEndDate
      startDate
      endDate
      enrollmentCounts {
        activeCounts
      }
      resource {
        id
        data
        datatype
        version
      }
      resources {
        id
        data
        version
      }
    }`;
  }, '')}
  }`;
  return (dispatch) => {
    dispatch(startLoading('CONTRACT_TERMS'));

    return emcService
      .gql(queryString)
      .then((res: IGqlResponse<Normalized<ContractTerm>>) => {
        const contractTermsList = values(res.data.data || {});
        const normalizedData = normalize<Contract, ContractNormalizedEntities>(
          { enrollableContractTerms: contractTermsList },
          contractNormSchema
        );
        const contractTerms =
          normalizedData?.entities?.enrollableContractTerms || {};
        const resources = normalizedData?.entities?.resources || {};

        batch(() => {
          dispatch(addContractTerms(contractTerms));
          dispatch(addResources('all', resources));
        });
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('CONTRACT_TERMS')));
  };
}
