import { AppThunk, IGqlResponse } from 'app/types/shared';
import {
  CatalogAccessStatus,
  GetCatalogAccessStatusQuery,
  GetUserAdmissionsWindowQuery,
  LearnerAdmissionsWindow,
} from 'app/types/generated/emc';
import { EmcError, addServerError } from './flash';
import { startLoading, stopLoading } from './loading';

import Analytics from '@udacity/ureact-analytics';
import CONFIG from '@emc/config';
import { EmcWeb_GetReferralFlagsQuery } from 'app/types/generated/experience';
import { NormalizedLearner } from 'app/types/learners';
import auth from '@udacity/ureact-hoth';
import { createAction } from 'app/utils/actions';
import { emcService } from '@emc/services';
import { experienceService } from 'app/services/experience-service';
import { getQueryString } from 'app/utils/graphql/serializer';
import { loader } from 'graphql.macro';

// Sync

export function addMe(me: { learners: NormalizedLearner[] }) {
  return createAction('ADD_ME', me);
}

export function updateMyAdmissionsWindow(
  learnerId: string,
  admissionsWindow: Pick<LearnerAdmissionsWindow, 'hasReachedApplicationsLimit'>
) {
  return createAction('UPDATE_MY_ADMISSIONS_WINDOW', {
    learnerId,
    admissionsWindow,
  });
}

export function addCatalogAccessDetails(accessDetails: CatalogAccessStatus) {
  return createAction('ADD_CATALOG_ACCESS_DETAILS', accessDetails);
}

export function updateUserExperience(
  userExperience: EmcWeb_GetReferralFlagsQuery['user']
) {
  return createAction('UPDATE_USER_EXPERIENCE', userExperience);
}

// Async

export function authenticateCurrentUser(isSignUp = false): void {
  auth[isSignUp ? 'signUp' : 'signIn']({
    env: CONFIG.REACT_APP_AUTH_SERVER as 'staging' | 'production',
    returnUrl: window.location.href,
  });
}

export function fetchEMCLearnersForCurrentUser(
  skipCache = false
): AppThunk<Promise<void>> {
  return (dispatch, getState) => {
    if (!skipCache && getState().me?.emc?.learners?.length) {
      return Promise.resolve();
    }
    dispatch(startLoading('ME'));

    return emcService
      .fetch(`/me`)
      .then((res: IGqlResponse<{ learnersByUserKey: NormalizedLearner[] }>) => {
        const learners = res.data.data?.learnersByUserKey;
        dispatch(addMe({ learners }));
        dispatch(stopLoading('ME'));
      })
      .catch((error: EmcError) => {
        dispatch(stopLoading('ME'));
        const res = error.response;
        const isForbidden = res?.status === 403;

        if (isForbidden) {
          return authenticateCurrentUser();
        }
        dispatch(addServerError(error));
      });
  };
}

export function fetchUserExperience(): AppThunk<Promise<void>> {
  return (dispatch) => {
    const id = auth.getCurrentUserId();
    dispatch(startLoading('USER_EXPERIENCE'));
    const emptyReferralFlags = {
      emc: {
        referrals: {
          catalog: false,
          application: false,
        },
      },
    };

    const qs = getQueryString(
      loader('@emc/queries/experience/get-referral-flags.gql')
    );
    return experienceService
      .gql(qs, { id })
      .then((res: IGqlResponse<EmcWeb_GetReferralFlagsQuery>) => {
        const userExperience = res.data.data.user;

        dispatch(updateUserExperience(userExperience || emptyReferralFlags));
        dispatch(stopLoading('USER_EXPERIENCE'));
      })
      .catch((error: EmcError) => {
        dispatch(stopLoading('USER_EXPERIENCE'));
        dispatch(updateUserExperience(emptyReferralFlags));
        dispatch(addServerError(error));
      });
  };
}

export function fetchCatalogAccessStatus(
  catalogToken: string,
  companySlug: string
): AppThunk<Promise<{ hasAccess: boolean; requestId: string }>> {
  return (dispatch) => {
    const headers = {
      'X-Catalog-Token': catalogToken,
      'X-Catalog-Company': companySlug,
    };
    emcService.setRequestHeaders(headers);

    return emcService
      .gql(
        getQueryString(loader('@emc/queries/emc/get-catalog-access-status.gql'))
      )
      .then((res: IGqlResponse<GetCatalogAccessStatusQuery>) => {
        const accessDetails = (res.data.data.catalogAccessStatus ||
          {}) as CatalogAccessStatus;
        const { hasAccess = false, requestId = '' } = accessDetails;
        dispatch(addCatalogAccessDetails(accessDetails));

        return { hasAccess, requestId };
      });
  };
}

export function fetchMyAdmissionsWindow(companyId: string): AppThunk {
  return (dispatch) => {
    const userKey = auth.getCurrentUserId() || '';
    dispatch(startLoading('ME'));
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/get-user-admissions-window.gql')
        ),
        {
          companyId,
          userKey,
        }
      )
      .then((res: IGqlResponse<GetUserAdmissionsWindowQuery>) => {
        const companyLearner = (res.data.data.learnersByUserKey || [])[0];
        if (companyLearner) {
          dispatch(
            updateMyAdmissionsWindow(
              companyLearner.id,
              companyLearner.admissionsWindow
            )
          );
        }
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => {
        dispatch(stopLoading('ME'));
      });
  };
}

// Util

export function identifyCurrentUser(): void {
  const id = auth.getCurrentUserId();
  return Analytics.identify({ id });
}
