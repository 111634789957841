import { AxiosPromise, AxiosRequestConfig } from 'axios';

import CONFIG from '@emc/config';
import { api } from './api-service';

export const experienceService = {
  post(
    path: string,
    data?: unknown,
    options?: AxiosRequestConfig
  ): AxiosPromise {
    return api.request({
      method: 'POST',
      url: `${CONFIG.REACT_APP_EXPERIENCE_API_URL}${path}`,
      data,
      ...options,
    });
  },
  gql(queryString: string, variables?: Record<string, unknown>): AxiosPromise {
    return this.post('/graphql', { query: queryString, variables });
  },
};
