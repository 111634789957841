import { AppThunk, IGqlResponse } from 'app/types/shared';
import {
  Company,
  CompanyGraphLearningPath,
  GetCompanyGraphLearningPathIdsQuery,
  GetCompanyGraphLearningPathQuery,
  GetCompanyGraphLearningPathsQuery,
  GetGraphLearningPathEnrollmentStatsQuery,
  QuestionSet,
  RemoveLearningPathFromCompanyMutation,
  UpdateCompanyLearningPathMutation,
} from 'app/types/generated/emc';
import {
  CompanyNormalizedEntities,
  companyNormSchema,
} from 'app/utils/normalize/company';
import { PaginationProps, getPaginationData } from 'app/selectors/pagination';
import {
  getPaginatedItems,
  getQueryString,
} from 'app/utils/graphql/serializer';
import {
  hasLearnerManagerPermissionsOrHigherSelector,
  hasViewOnlyPermissionsOrHigherSelector,
} from 'app/selectors/me';
import { startLoading, stopLoading } from '../loading';

import { CompanyGraphLearningPathsReduxState } from 'app/reducer/data/types';
import { PaginationTypes } from '@emc/reducer/session/pagination';
import { addFlashMessage } from '../flash';
import { addQuestionSets } from '../admissions';
import { batch } from 'react-redux';
import { createAction } from 'app/utils/actions';
import { emcService } from '@emc/services';
import { fetchProgramTitles } from '../classroom-content';
import { getGraphQLErrorMessage } from 'app/utils/errors';
import { loader } from 'graphql.macro';
import { normalize } from 'normalizr';
import { updatePagination } from '../reports';

export function addCompanyGraphLearningPaths(
  learningPaths: CompanyGraphLearningPathsReduxState
) {
  return createAction('ADD_COMPANY_GRAPH_LEARNING_PATHS', learningPaths);
}

export function updateCompanyGraphLearningPath(
  id: string,
  isAvailable: boolean,
  assessmentCohortKey?: string,
  removeAssessmentCohort?: boolean | null,
  questionSetId?: string
): AppThunk {
  return (dispatch) => {
    dispatch(startLoading('GRAPH_LEARNING_PATHS'));
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/update-company-learning-path.gql')
        ),
        {
          id,
          isAvailable,
          assessmentCohortKey,
          questionSetId,
          removeAssessmentCohort,
          isManager: true,
        }
      )
      .then((res: IGqlResponse<UpdateCompanyLearningPathMutation>) => {
        if (res.data.errors) {
          throw new Error(
            `Error updating learning path: ${res.data.errors[0].message}`
          );
        }
        const companyLearningPath = res.data.data.updateCompanyGraphLearningPath
          ?.companyGraphLearningPath as CompanyGraphLearningPath;
        const learningPathName =
          companyLearningPath?.learningPath?.name || 'Learning Path';
        batch(() => {
          dispatch(
            addCompanyGraphLearningPaths({
              [id]: companyLearningPath,
            })
          );
          dispatch(
            addFlashMessage(
              'success',
              questionSetId
                ? `Question set created for ${learningPathName}.`
                : isAvailable
                ? `${learningPathName} made available for learner enrollment.`
                : `${learningPathName} made unavailable for learner enrollment. Currently assigned learners will remain assigned.`
            )
          );
          dispatch(stopLoading('GRAPH_LEARNING_PATHS'));
        });
      })
      .catch((err) => {
        dispatch(stopLoading('GRAPH_LEARNING_PATHS'));
        dispatch(addFlashMessage('error', err.message));
      });
  };
}

export function fetchCompanyGraphLearningPathIds(
  companyId: string
): Promise<{ id: string; learningPathId: string }[]> {
  return emcService
    .gql(
      getQueryString(
        loader('@emc/queries/emc/get-company-graph-learning-path-ids.gql')
      ),
      { companyId }
    )
    .then((res: IGqlResponse<GetCompanyGraphLearningPathIdsQuery>) => {
      return getPaginatedItems(
        res.data.data.company?.companyGraphLearningPaths
      ) as { id: string; learningPathId: string }[];
    });
}

export function removeLearningPathFromCompany(
  learningPathId: string
): AppThunk<Promise<void>> {
  return (dispatch) => {
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/delete-company-graph-learning-path.gql')
        ),
        { learningPathId }
      )
      .then((res: IGqlResponse<RemoveLearningPathFromCompanyMutation>) => {
        const success = res.data.data?.deleteCompanyGraphLearningPath?.ok;
        if (!success) {
          const errMsg = (res.data.errors || [
            {
              message:
                'Something went wrong removing the learning path from the company',
            },
          ])[0].message;
          throw new Error(errMsg);
        }
        dispatch(
          addFlashMessage('success', 'Learning path removed from company')
        );
      })
      .catch((err) => {
        dispatch(addFlashMessage('error', err.message));
        dispatch(stopLoading('GRAPH_LEARNING_PATHS'));
      });
  };
}

export function fetchCompanyGraphLearningPathEnrollmentStats(
  companyId: string
): AppThunk {
  return (dispatch) => {
    dispatch(startLoading('GRAPH_LEARNING_PATHS'));
    return emcService
      .gql(
        getQueryString(
          loader(
            '@emc/queries/emc/get-graph-learning-path-enrollment-stats.gql'
          )
        ),
        { companyId }
      )
      .then((res: IGqlResponse<GetGraphLearningPathEnrollmentStatsQuery>) => {
        if (res.data.errors) {
          throw new Error(
            `Error fetching learning paths: ${res.data.errors[0].message}`
          );
        }
        const normalizedData = normalize<Company, CompanyNormalizedEntities>(
          res.data.data?.company,
          companyNormSchema
        );
        const learningPaths =
          normalizedData.entities.companyGraphLearningPaths || {};
        const lpProgramKeys = new Set<string>();
        res.data?.data?.company?.enrollableCompanyGraphLearningPaths?.edges?.forEach(
          (companyLpNode) => {
            companyLpNode?.node?.learningPath?.nodes?.forEach((node) => {
              if (node?.label) lpProgramKeys.add(node?.label);
            });
          }
        );
        dispatch(fetchProgramTitles(Array.from(lpProgramKeys)));
        const company = (normalizedData.entities?.companies || {})[companyId];
        const learningPathIds = getPaginatedItems(
          company?.enrollableCompanyGraphLearningPaths
        );
        batch(() => {
          dispatch(addCompanyGraphLearningPaths(learningPaths));
          dispatch(
            updatePagination(
              PaginationTypes.enrollableCompanyGraphLearningPaths,
              learningPathIds.length,
              learningPathIds
            )
          );
          dispatch(stopLoading('GRAPH_LEARNING_PATHS'));
        });
      })
      .catch((err) => {
        dispatch(addFlashMessage('error', err.message));
      })
      .then(() => dispatch(stopLoading('GRAPH_LEARNING_PATHS')));
  };
}

export function fetchCompanyGraphLearningPaths(
  companyId: string,
  paginationProps: PaginationProps
): AppThunk {
  return (dispatch, getState) => {
    const paginationData = getPaginationData(paginationProps);
    dispatch(startLoading('GRAPH_LEARNING_PATHS'));
    const isViewOnlyManagerOrHigher = hasViewOnlyPermissionsOrHigherSelector(
      getState()
    );
    const shouldResolveIsEnrollable =
      hasLearnerManagerPermissionsOrHigherSelector(getState());
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/get-company-graph-learning-paths.gql')
        ),
        {
          companyId,
          ...paginationData,
          isManager: isViewOnlyManagerOrHigher,
          shouldResolveIsEnrollable,
        }
      )
      .then((res: IGqlResponse<GetCompanyGraphLearningPathsQuery>) => {
        if (res.data.errors) {
          throw new Error(
            `Error fetching learning paths: ${res.data.errors[0].message}`
          );
        }
        const companyGraphLearningPaths =
          res.data.data?.company?.companyGraphLearningPaths;
        const normalizedData = normalize<Company, CompanyNormalizedEntities>(
          res.data.data?.company,
          companyNormSchema
        );

        const learningPaths =
          normalizedData.entities.companyGraphLearningPaths || {};
        const questionSets = normalizedData.entities.questionSets || {};
        const company = (normalizedData.entities.companies || {})[companyId];
        const learningPathIds = getPaginatedItems(
          company?.companyGraphLearningPaths
        );

        batch(() => {
          dispatch(addCompanyGraphLearningPaths(learningPaths));
          dispatch(
            updatePagination(
              PaginationTypes.companyGraphLearningPaths,
              companyGraphLearningPaths?.totalCount || 0,
              learningPathIds
            )
          );
          dispatch(addQuestionSets(questionSets));
          dispatch(stopLoading('GRAPH_LEARNING_PATHS'));
        });
      })
      .catch((err) => {
        dispatch(stopLoading('GRAPH_LEARNING_PATHS'));
        dispatch(addFlashMessage('error', err.message));
      });
  };
}
export function fetchCompanyGraphLearningPath(
  learningPathId: string
): AppThunk<Promise<void>> {
  return (dispatch, getState) => {
    dispatch(startLoading('GRAPH_LEARNING_PATHS'));
    const isManager = hasViewOnlyPermissionsOrHigherSelector(getState());
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/get-company-graph-learning-path.gql')
        ),
        { id: learningPathId, isManager }
      )
      .then((res: IGqlResponse<GetCompanyGraphLearningPathQuery>) => {
        const companyGraphLearningPath =
          res.data.data?.companyGraphLearningPath;
        const questionSet = companyGraphLearningPath?.questionSet;
        const questionSetId = questionSet?.id;
        const programKeys = companyGraphLearningPath?.learningPath?.nodes
          ?.map((node) => node?.label)
          .filter(Boolean) as string[];
        dispatch(fetchProgramTitles(programKeys || []));

        if (res.data.errors) {
          dispatch(stopLoading('GRAPH_LEARNING_PATHS'));
          throw new Error(getGraphQLErrorMessage(res.data.errors));
        }
        batch(() => {
          if (companyGraphLearningPath && companyGraphLearningPath?.id) {
            dispatch(
              addCompanyGraphLearningPaths({
                [companyGraphLearningPath?.id]:
                  companyGraphLearningPath as CompanyGraphLearningPath,
              })
            );
          }
          if (questionSet && questionSetId) {
            dispatch(
              addQuestionSets({ [questionSetId]: questionSet as QuestionSet })
            );
          }
          dispatch(stopLoading('GRAPH_LEARNING_PATHS'));
        });
      })
      .catch((err) => {
        dispatch(stopLoading('GRAPH_LEARNING_PATHS'));
        dispatch(addFlashMessage('error', err.message));
      });
  };
}
