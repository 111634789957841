import { AppThunk, IGqlResponse } from 'app/types/shared';
import {
  CatalogProgram,
  Company,
  EnterpriseCatalogQuery,
  ErrorCode,
  GetCompanyIdQuery,
  GetCompanySelfServeTokensQuery,
  HydrateCompanyCatalogPageQuery,
  Learner,
  LearnerInterestApplication,
  QuestionSet,
  ResourceType,
} from 'app/types/generated/emc';
import {
  CompanyNormalizedEntities,
  companyNormSchema,
} from 'app/utils/normalize/company';
import {
  LearnerNormalizedEntities,
  learnerNormSchema,
} from 'app/utils/normalize/learner';
import { addActiveCompany, addCompanies } from './companies';
import { addApplicationsEligibility, addLearners } from './learners/fetch';
import { addMe, authenticateCurrentUser } from './me';
import {
  cacheActiveCompany,
  cacheCompanyCatalog,
  cacheCompanyQuestionSet,
} from './cache';
import {
  getPaginatedItems,
  getQueryString,
} from 'app/utils/graphql/serializer';
import { indexBy, isEmpty, prop, values } from 'ramda';
import { startLoading, stopLoading } from './loading';

import { ClassroomCatalog } from 'app/types/programs';
import { EnterpriseCatalogReduxState } from 'app/reducer/data/types';
import { NormalizedLearner } from 'app/types/learners';
import { PaginationTypes } from 'app/reducer/session/pagination';
import { addCompanyGraphLearningPaths } from './learning-paths/company';
import { addContractTerms } from './contract-terms/contract-terms';
import { addContracts } from './contracts';
import { addQuestionSets } from './admissions';
import { addResources } from './resources';
import { addServerError } from './flash';
import auth from '@udacity/ureact-hoth';
import { batch } from 'react-redux';
import { companyHasPublicCatalog } from 'app/utils/settings';
import { createAction } from 'app/utils/actions';
import { emcService } from '@emc/services';
import { getGraphQLErrorCode } from 'app/utils/errors';
import { isCacheValid } from 'app/utils/cache/is_valid';
import { loader } from 'graphql.macro';
import { loadingSelector } from 'app/selectors/session';
import { normalize } from 'normalizr';
import { setEnrollableContractTermIds } from './contract-terms/enrollable-contract-terms';
import { updatePagination } from './reports';

// Sync

export function addClassroomCatalog(programs: ClassroomCatalog) {
  return createAction('ADD_CLASSROOM_CATALOG', programs);
}

export function addEnterpriseCatalog(catalog: EnterpriseCatalogReduxState) {
  return createAction('ADD_ENTERPRISE_CATALOG', catalog);
}

export function removeCatalogProgram(programId: string) {
  return createAction('REMOVE_CATALOG_PROGRAM', programId);
}

// Async

export enum CatalogProgramType {
  Standard = 'STANDARD',
  NotStandard = 'NOT_STANDARD',
  All = 'ALL',
}

export function fetchEnterpriseCatalog(): AppThunk {
  return (dispatch, getState) => {
    if (loadingSelector(getState())['ENTERPRISE_CATALOG']) {
      return Promise.resolve();
    }
    dispatch(startLoading('ENTERPRISE_CATALOG'));
    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/all-catalog-programs.gql')))
      .then((res: IGqlResponse<EnterpriseCatalogQuery>) => {
        dispatch(
          addEnterpriseCatalog(
            indexBy(
              prop('programKey'),
              (res.data.data?.allCatalogPrograms as CatalogProgram[]) || []
            )
          )
        );
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('ENTERPRISE_CATALOG')));
  };
}

export function hydrateCompanyCatalogPage(companySlug: string): AppThunk {
  return (dispatch, getState) => {
    const isLoadingCatalog = loadingSelector(getState()).COMPANY_CATALOG;
    if (
      isCacheValid(getState, ['cache', 'catalog', companySlug]) ||
      isLoadingCatalog
    ) {
      dispatch(stopLoading('ME'));
      return Promise.resolve();
    }

    dispatch(startLoading('COMPANY_CATALOG'));
    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/get-company-id.gql')), {
        companySlug,
      })
      .then((getCoIdRes: IGqlResponse<GetCompanyIdQuery>) => {
        const companyId = getCoIdRes.data.data.companyBySlug?.id;
        const userKey = auth.getCurrentUserId();
        return emcService
          .gql(
            getQueryString(
              loader('@emc/queries/emc/hydrate-company-catalog-page.gql')
            ),
            {
              companySlug,
              companyId,
              userKey: userKey || '',
              isNotLoggedIn: !userKey,
              isManager: false,
            }
          )
          .then((res: IGqlResponse<HydrateCompanyCatalogPageQuery>) => {
            const errCode = getGraphQLErrorCode(res.data.errors);
            if (errCode === ErrorCode.PublicCatalogOff) {
              return authenticateCurrentUser();
            }
            const data = res.data.data;
            if (data) {
              const companyLearner = (data.learnersByUserKey || [])[0];
              if (companyLearner) {
                const normalizedLearnerData = normalize<
                  Learner,
                  LearnerNormalizedEntities
                >(companyLearner, learnerNormSchema);
                dispatch(addLearners(normalizedLearnerData.entities.learners));
                dispatch(
                  addMe({ learners: [companyLearner as NormalizedLearner] })
                );
                if (!isEmpty(companyLearner.applications || []))
                  dispatch(
                    addApplicationsEligibility(
                      companyLearner.id,
                      companyLearner.applications as LearnerInterestApplication[]
                    )
                  );
              }
              const companyData = data.companyBySlug;
              if (!companyData) {
                return dispatch(
                  addServerError({ message: 'Company not found' })
                );
              }
              const companyCatalogIsPublic = companyHasPublicCatalog(
                companyData.companySettings || []
              );

              // Fetch selfServeTokens only if the company is public and user is not a learner
              const selfServeTokenPromise =
                companyCatalogIsPublic && !companyLearner
                  ? emcService.gql(
                      getQueryString(
                        loader(
                          '@emc/queries/emc/get-company-self-serve-tokens.gql'
                        )
                      ),
                      {
                        companySlug,
                      }
                    )
                  : Promise.resolve(undefined);

              return selfServeTokenPromise.then(
                (
                  selfServeTokensRes:
                    | IGqlResponse<GetCompanySelfServeTokensQuery>
                    | undefined
                ) => {
                  if (selfServeTokensRes) {
                    companyData['selfServeTokens'] =
                      selfServeTokensRes?.data?.data?.companyBySlug?.selfServeTokens;
                  }

                  const normalizedCompanyData = normalize<
                    Company,
                    CompanyNormalizedEntities,
                    string
                  >(companyData, companyNormSchema);
                  const {
                    companies,
                    companyGraphLearningPaths,
                    contracts,
                    enrollableContractTerms,
                    resources,
                    catalogPrograms,
                  } = normalizedCompanyData.entities;
                  const companyId = normalizedCompanyData.result;
                  const company = companies[companyId];
                  batch(() => {
                    dispatch(cacheCompanyQuestionSet(companyId));
                    dispatch(stopLoading('COMPANY_CATALOG'));
                    dispatch(stopLoading('ME'));
                    const questionSet = company?.questionSet;
                    const questionSetId = questionSet?.id;
                    if (questionSetId) {
                      dispatch(
                        addQuestionSets({
                          [questionSetId]: questionSet as QuestionSet,
                        })
                      );
                      dispatch(addCompanies({ [companyId]: company }));
                    }
                    dispatch(
                      addActiveCompany(
                        companySlug,
                        (data.companyBySlug || {}) as Partial<Company>
                      )
                    );
                    dispatch(addCompanies(companies || {}));
                    dispatch(addContracts(contracts || {}));
                    dispatch(addEnterpriseCatalog(catalogPrograms || {}));
                    dispatch(addContractTerms(enrollableContractTerms || {}));
                    dispatch(
                      setEnrollableContractTermIds(
                        companyId,
                        Object.keys(enrollableContractTerms || {}) as string[]
                      )
                    );
                    if (resources) {
                      dispatch(addResources(companyId, resources));
                      const classroomCatalogPrograms = values(
                        resources
                      ).reduce<ClassroomCatalog>((result, resource) => {
                        const programKey = resource?.resourceData?.key || '';
                        result[programKey] = {
                          key: programKey,
                          title: resource?.programUrn?.programDetails?.title,
                          summary:
                            resource?.programUrn?.programDetails?.summary,
                          versions: [],
                          imageUrl: resource?.resourceData?.heroImageUrl,
                          semanticType: resource?.datatype as ResourceType,
                        };
                        return result;
                      }, {});
                      dispatch(addClassroomCatalog(classroomCatalogPrograms));
                    }
                    dispatch(
                      addCompanyGraphLearningPaths(
                        companyGraphLearningPaths || {}
                      )
                    );
                    const learningPathIds = getPaginatedItems(
                      company?.enrollableCompanyGraphLearningPaths
                    );
                    dispatch(
                      updatePagination(
                        PaginationTypes.enrollableCompanyGraphLearningPaths,
                        learningPathIds.length,
                        learningPathIds
                      )
                    );
                    dispatch(cacheCompanyCatalog(companySlug));
                    dispatch(cacheActiveCompany(companySlug));
                  });
                }
              );
            }
          });
      })
      .catch((error) => {
        dispatch(stopLoading('ME'));
        dispatch(stopLoading('COMPANY_CATALOG'));
        dispatch(addServerError(error));
      });
  };
}
