import {
  CatalogLayoutSection,
  CatalogLayoutSectionId,
  CatalogLayoutSetting,
} from 'app/types/settings';

import { CatalogProgramSectionType } from 'app/types/generated/emc';

export const sectionIdToTypeMap: Record<
  CatalogLayoutSectionId,
  CatalogProgramSectionType | null
> = {
  introCourses: CatalogProgramSectionType.IntroductoryPrograms,
  courses: CatalogProgramSectionType.IntermediatePrograms,
  discovery: CatalogProgramSectionType.Discovery,
  fluency: CatalogProgramSectionType.Fluency,
  nanodegrees: CatalogProgramSectionType.FullPrograms,
  futureOfferings: CatalogProgramSectionType.FutureOfferings,
  learningPaths: null,
  hero: null,
  filters: null,
  nominations: null,
  FAQ: null,
};

export const defaultCatalogLayout: CatalogLayoutSetting = {
  sections: [
    { id: 'hero', description: '', visible: true },
    { id: 'filters', description: '', visible: true },
    {
      id: 'learningPaths',
      description: '',
      visible: true,
    },
    {
      id: 'introCourses',
      name: 'Foundations',
      description:
        '• Simple, ramp-up learning programs  • Unlimited access  • 10-15 hours on average to complete',
      visible: false,
    },
    {
      id: 'courses',
      description:
        '• Focused, short-term classes  • One practice project  • Approval required to enroll • 20 hours on average to complete',
      visible: true,
    },
    {
      id: 'discovery',
      description:
        "Get a high-level view into a new technology or capability. If you're completely new to a technology or just want to see what it's all about, this quick course can give you a sense of the skills of tomorrow.",
      visible: true,
    },
    {
      id: 'fluency',
      description:
        'Prepare yourself to work closely with our practitioner partners or prepare yourself to take the next step into the future of practical skills.',
      visible: true,
    },
    {
      id: 'nanodegrees',
      description:
        '• Long-term, in-depth learning  • Series of courses and projects • Approval required to enroll • 80 hours on average to complete',
      visible: true,
    },
    {
      id: 'nominations',
      description: 'Nominate others to learn with Udacity',
      visible: true,
    },
    { id: 'FAQ', description: '', visible: true },
    {
      id: 'futureOfferings',
      name: 'Future Offerings',
      description:
        'Let us know what you want added to the catalog. We&apos;ll let you know as soon as any program you&apos;re interested in becomes available.',
      visible: true,
    },
  ],
  learningPathButtonLabel: 'Start My Application',
  nanodegreeButtonLabel: 'Start My Application',
  courseButtonLabel: 'Start My Application',
  publicCtaButtonLabel: 'Log-in to Continue',
};

function getBackwardsCompatibleSections(
  overrideSections: CatalogLayoutSection[]
): CatalogLayoutSection[] {
  const defaultSections = defaultCatalogLayout.sections;
  const defaultSectionIds = new Set(
    defaultSections.map((section) => section.id)
  );
  const missingSectionIds = new Set(defaultSectionIds);

  // filter out deprecated sections:
  const processedSections = overrideSections.filter((section) => {
    if (defaultSectionIds.has(section.id)) {
      missingSectionIds.delete(section.id);
      return true;
    }
    return false;
  });

  // add in missing sections at default index:
  if (missingSectionIds.size) {
    const missingSections: { section: CatalogLayoutSection; idx: number }[] =
      [];
    defaultSections.forEach((section, idx) => {
      if (missingSectionIds.has(section.id)) {
        missingSections.push({ section, idx });
      }
    });

    missingSections.forEach((missing) => {
      processedSections.splice(missing.idx, 0, missing.section);
    });
  }

  return processedSections;
}

export function getCatalogLayout(overrideValue: string): CatalogLayoutSetting {
  if (!overrideValue) {
    return defaultCatalogLayout;
  }

  const overrideValueObj: CatalogLayoutSetting = JSON.parse(overrideValue);

  const catalogLayout = {
    ...defaultCatalogLayout,
    ...overrideValueObj,
    sections: getBackwardsCompatibleSections(overrideValueObj.sections),
  };
  return catalogLayout;
}
