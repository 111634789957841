import React, { useEffect } from 'react';

import { Container } from '@emc/elements/container';
import { Heading } from '@udacity/veritas-components';
import { Section } from '@emc/elements/section';
import styles from './styles.module.scss';

export const NotFound: React.FC = () => {
  useEffect(() => {
    document.title = 'Page Not Found';
  }, []);

  return (
    <Container>
      <Section className={styles.card}>
        <Heading size="h3">Page Not Found!</Heading>
      </Section>
    </Container>
  );
};
