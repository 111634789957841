export function copyToClipboard(inputEl: HTMLInputElement): void {
  if (inputEl) {
    inputEl.disabled = false;
    inputEl.select();
    inputEl.disabled = true;

    document.execCommand && document.execCommand('copy');
  }
}

export const isMobileWidth = () => window.innerWidth <= 768;

export function downloadCsvString(data: string, filename: string) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}
