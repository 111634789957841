import { CatalogReduxState } from './types';
import { addCatalogTags } from 'app/actions/catalog-tags';
import { addClassroomCatalog } from 'app/actions/catalog';
import { mergeDeepRight } from 'ramda';

const initialState: CatalogReduxState = {
  classroom: {},
  tags: {},
};

export default function catalog(
  state: CatalogReduxState = initialState,
  action: ReturnType<typeof addClassroomCatalog | typeof addCatalogTags>
): CatalogReduxState {
  switch (action.type) {
    case 'ADD_CLASSROOM_CATALOG': {
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        classroom: mergeDeepRight(state.classroom, action.payload as any),
      };
    }

    case 'ADD_CATALOG_TAGS': {
      return {
        ...state,
        tags: { ...state.tags, ...action.payload },
      };
    }

    default: {
      return state;
    }
  }
}
