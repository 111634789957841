import { Action, Store } from 'redux';
import {
  render,
  screen,
  waitFor,
  waitForElementToBeRemoved,
} from '@testing-library/react';

import { IRootStore } from '@emc/reducer/types';
import React from 'react';
import { Routes } from '@emc/routes';
import { ThunkDispatch } from 'redux-thunk';
import auth from '@udacity/ureact-hoth';
import { createStoreWithMiddleWare } from '@emc/store';
import { getRolesByUserKey } from 'mocks/data/users';
import { history } from '@emc/history';
import { makeJWT } from 'mocks/utils/utils';
import { rootReducer } from '@emc/reducer';
import userEvent from '@testing-library/user-event';

export const isTesting = process.env.REACT_APP_ENV === 'testing';

export interface ThunkStore extends Store {
  dispatch: ThunkDispatch<IRootStore, null, Action>;
}
export const makeStore = (userKey: string): ThunkStore => {
  const root = rootReducer(undefined, { type: '@@INIT' });

  const state = {
    ...root,
    ...{
      me: {
        udacity: {
          isAuthenticated: true,
          roles: getRolesByUserKey(userKey),
          referrals: null,
        },
        emc: { learners: [] },
      },
    },
  };

  const store = createStoreWithMiddleWare(rootReducer, state);
  return store;
};

export function debugMain() {
  screen.debug(screen.getByRole('main'), 100000);
}

export function debugActionBar() {
  screen.debug(screen.getByTestId('action-bar'), 100000);
}

export function debugModal() {
  screen.debug(screen.getByRole('dialog', { hidden: true }), 10000);
}

export const renderApp = async (
  {
    path,
    waitForText,
    search,
  }: { path: string; waitForText?: string | RegExp; search?: string },
  userKey: string,
  timeout = 30000
) => {
  const store = makeStore(userKey);
  jest.spyOn(auth, 'getCurrentUserId').mockReturnValue(userKey);
  jest.spyOn(auth, 'getJWT').mockReturnValue(makeJWT(userKey));
  history.push({
    pathname: path,
    search,
  });
  render(<Routes store={store} />);
  if (waitForText) {
    return await waitFor(
      () => {
        return screen.getAllByText(waitForText);
      },
      {
        timeout,
        interval: 250,
        onTimeout: (err) => {
          debugMain();
          return err;
        },
      }
    );
  }
  return Promise.resolve();
};

export async function waitForModalToBeRemoved() {
  const modal = screen.queryByRole('dialog', { hidden: true });
  if (modal) {
    return await waitForElementToBeRemoved(
      screen.queryByRole('dialog', { hidden: true }),
      { timeout: 10000 }
    );
  }
  return Promise.resolve();
}

export function selectTableRow(rowId: string, isSelecting = true) {
  userEvent.click(
    screen.getAllByTitle(`${isSelecting ? 'Select' : 'Deselect'} ${rowId}`)[0]
      .firstChild as Element
  );
}

export function getSelectComponentValue(labelText: string | RegExp) {
  const labelledItems = screen.getAllByLabelText(labelText);
  /*
  item 0 is the entire select wrapper
  item 1 is the button showing the current value
  item 2 is the list of options
  */
  return labelledItems[1].textContent;
}

export async function waitForLoadingToStop() {
  const elem = screen.queryByTestId('background-loading');
  if (elem) {
    return await waitForElementToBeRemoved(
      screen.queryByTestId('background-loading'),
      { timeout: 10000 }
    );
  }
  return Promise.resolve();
}

export async function waitForEnrollmentActionsToComplete() {
  // see progress bar and flash message for enroll / unenroll
  // appear and then disappear

  // currently doesn't work with transfer
  await screen.findByText(
    /Check the History tab to track progress and view completed changes/
  );
  await screen.findByText(/in progress/);
  if (
    screen.queryByText(
      /Check the History tab to track progress and view completed changes/
    )
  ) {
    await waitForElementToBeRemoved(() =>
      screen.queryByText(
        /Check the History tab to track progress and view completed changes/
      )
    );
  }
  if (screen.queryByText(/in progress/)) {
    await waitForElementToBeRemoved(screen.queryByText(/in progress/), {
      timeout: 8000,
    });
  }
  await waitForLoadingToStop();
}

// re-export everything
export * from '@testing-library/react';
