import React, { ReactNode } from 'react';

import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  children: ReactNode;
  removeMargin?: boolean;
  removePadding?: boolean;
  type?: 'default' | 'small' | 'medium' | 'wide';
  marginTop?: 'none' | '1x' | '2x' | '3x' | '4x' | '6x';
  backgroundColor?: 'white' | 'silver';
};

export const Container: React.FC<Props> = ({
  children,
  removeMargin = false,
  removePadding = false,
  type = 'default',
  marginTop = 'none',
  backgroundColor,
}) => (
  <div
    className={classNames(styles[type], styles[`margin-top-${marginTop}`], {
      [styles['remove-margin']]: removeMargin,
      [styles['remove-padding']]: removePadding,
      [styles['white-background']]: backgroundColor === 'white',
      [styles['silver-background']]: backgroundColor === 'silver',
    })}
  >
    {children}
  </div>
);
