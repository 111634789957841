import { Reducer, Store, applyMiddleware, compose, createStore } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import { IRootStore } from '@emc/reducer/types';
import { cacheEnhancer } from 'redux-cache';
import { identity } from 'ramda';
import { persistStore } from 'redux-persist';
import { rootReducer } from '@emc/reducer';

function isNotActualProd(): boolean {
  const isProdEnv = process.env.REACT_APP_ENV === 'production';
  const isLocalDev = window.location.hostname === 'dev.udacity.com';
  return !isProdEnv || isLocalDev;
}

export const createStoreWithMiddleWare = compose<
  (reducer: Reducer, state?: Partial<IRootStore>) => Store<IRootStore>
>(
  applyMiddleware(thunk as ThunkMiddleware<IRootStore>),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && isNotActualProd()
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    : identity,
  cacheEnhancer()
)(createStore);

const store = createStoreWithMiddleWare(rootReducer);
const persistor = persistStore(store);

export { persistor, store };
