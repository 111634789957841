import { ReactNode } from 'react';

export const NONE_OPTION = 'EMC_WEB_NONE';

export interface IOption<V = string | number | boolean> {
  label: string;
  value: V;
  id?: string | number;
  alwaysShow?: boolean;
  disabled?: boolean;
  component?: JSX.Element;
  title?: string;
  shouldIncludeComplement?: boolean;
  dateRange?: boolean;
}

export interface IFilter {
  id: string;
  label: string;
  description?: string;
  complementId?: string;
  multi?: boolean;
  numberRangeOptions?: {
    defaultMin: string;
    defaultMax: string;
    step?: string;
  };
  options?: IOption[];
  defaultValue?: string | number;
  onSelect?(value?: string | number | boolean): void;
  tooltip?: ReactNode;
}
