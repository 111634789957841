import { LoadingActions } from 'app/actions/loading';
import { LoadingType } from '@emc/loading-types';
import { omit } from 'ramda';

export type LoadingState = {
  [key in LoadingType]?: boolean;
};

const initialState = {
  ME: true,
};

export default function loading(
  state: LoadingState = initialState,
  action: LoadingActions
): LoadingState {
  switch (action.type) {
    case 'START_LOADING': {
      return {
        ...state,
        [action.payload]: true,
      };
    }

    case 'STOP_LOADING': {
      return omit([action.payload])(state);
    }

    default: {
      return state;
    }
  }
}
