import {
  activeCompaniesSelector,
  companiesSelector,
} from 'app/selectors/companies/companies';
import {
  getCompanyIdFromAdminPathname,
  getCompanySlugFromPathname,
} from 'app/utils/router/paths';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export function useCurrentCompanySlug(): string {
  const history = useHistory();
  const activeCompanies = useSelector(activeCompaniesSelector);
  const companies = useSelector(companiesSelector);
  const pathname = history.location?.pathname;

  const pathSlug = getCompanySlugFromPathname(pathname);
  const activeCompany = activeCompanies[pathSlug] || {};
  const companyId =
    getCompanyIdFromAdminPathname(pathname) || activeCompany?.id;

  return companies[companyId]?.slug || pathSlug;
}
