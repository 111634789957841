export type RosterSubmission = {
  attempt_count: number;
  duration_in_days: number;
  exempted: boolean;
  exempted_reason: string;
  exempted_at: string;
  is_career: boolean;
  is_optional: boolean;
  mandatory_project_order: number;
  pass_date: string;
  project_id: string;
  project_name: string;
  project_order: string;
  rubric_id: string;
  submission_date: string;
  submission_deadline: string;
  submission_id: string;
  submission_outcome: string;
};

export enum RosterOnTrackStatus {
  OnTrack = 'On Track',
  OffTrack = 'Off Track',
  Monitor = 'Monitor',
  ReadyToGraduate = 'Ready to Graduate',
  Completed = 'Completed',
}

export type ReportItem = {
  email: string;
  enrollment_id: string;
  enrollment_state?: string;
  learner_id: number;
  node_key: string;
  program_type: string;
  rc: number;
  ref_key: string;
  user_key: string;
};

export type ReportRoster = ReportItem & {
  active_cohort_ref_keys: null;
  cohort_names: null;
  company_id: number;
  concept_delta: number;
  concepts: number;
  consumed_stu_count: number | null;
  content_locale: string;
  contract_term_started_at: string;
  days_inactive: number;
  employee_id: string | null;
  enrolled_at: string;
  enrollment_cohort_ids: [];
  enrollment_history: { ENROLLED: string };
  enrollment_state: string;
  estimated_end_date: string | null;
  first_name: string;
  flat_project_status: RosterSubmission[];
  graduated_at: string | null;
  group_counts: number;
  is_all_mandatory_projects_passed: boolean;
  is_auto_enrolled: boolean;
  is_beyond_end_date: boolean;
  last_name: string;
  last_seen: string | null;
  last_updated: string;
  mandatory_projects_passed: string | null;
  on_track_status: RosterOnTrackStatus | null;
  pending_stu_count: number;
  pro_pass: string | null;
  program_title: string;
  program_version: string;
  progress_v2_time_in_classroom_period: number | null;
  progress_v2_time_in_classroom_total: number | null;
  project_delta: number;
  projects_overdue: number;
  questions: number;
  quiz_delta: number;
  tag_ids: string;
  tag_names: string;
  total_concepts: number;
  total_mandatory_projects: string;
  total_questions: number;
  unenrolled_at: string | null;
  uniq: string;
  were_projects_late: boolean | null;
};

export type ReportProject = ReportItem & {
  account_key: string;
  attempted: string;
  cohort_names: string | null;
  company_id: number;
  content_locale: string;
  content_version: string;
  enrollment_cohort_ids: string | null;
  first_attempted_at: string;
  first_name: string;
  group_counts?: number;
  is_career: boolean;
  is_optional: boolean;
  last_attempt: string;
  last_name: string;
  last_updated: string;
  mandatory_project_order: string;
  next_project_duration_in_days: number;
  pass_date?: string;
  program_title: string;
  project_id: string;
  project_name: string;
  rubric_id: string;
  status: string;
  submission_deadline: string;
  tag_ids: string;
  tag_names: string;
  total_mandatory_projects: number;
  uniq: string;
};

export enum ReportsSection {
  Reports = 'reports',
  Projects = 'projects',
  Skills = 'skills',
}
