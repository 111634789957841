import {
  formatProgramTitleFromKey,
  getProgramKeyFromResource,
} from 'app/utils/programs/titles';
import { identity, pathOr, prop, uniq } from 'ramda';

import { ClassroomCatalog } from 'app/types/programs';
import { IOption } from 'app/types/options';
import { IRootStore } from '@emc/reducer/types';
import { PaginationTypes } from '@emc/reducer/session/pagination';
import { ResourcesReduxState } from 'app/reducer/data/types';
import { classroomCatalogSelector } from '../catalog';
import { createSelector } from 'reselect';
import { currentCompanyIdSelector } from 'app/selectors/companies/companies';
import { getQueryParams } from 'app/utils/router/query-params';
import { paginationSelector } from '../pagination';

export const resourcesSelector = createSelector<
  [(state: IRootStore) => ResourcesReduxState],
  ResourcesReduxState
>(pathOr({} as ResourcesReduxState, ['data', 'resources']), identity);

export const allResourcesSelector = createSelector<
  [(state: IRootStore) => ResourcesReduxState],
  ResourcesReduxState['all']
>(resourcesSelector, prop('all'));

export const resourcesByCompanySelector = createSelector<
  [(state: IRootStore) => ResourcesReduxState],
  ResourcesReduxState['byCompany']
>(resourcesSelector, prop('byCompany'));

export const currentCompanyResourcesSelector = createSelector<
  [
    (state: IRootStore) => string,
    (state: IRootStore) => ResourcesReduxState,
    (state: IRootStore) => ClassroomCatalog
  ],
  IOption[]
>(
  currentCompanyIdSelector,
  resourcesSelector,
  classroomCatalogSelector,
  (companyId, resources, classroomCatalog) => {
    const programType = getQueryParams().programType;
    const companyResourceIds = resources.byCompany[companyId] || [];
    const resourceProgramKeys = uniq(
      companyResourceIds.map((id) =>
        getProgramKeyFromResource(resources.all[id])
      )
    );
    const formatTitle = formatProgramTitleFromKey(classroomCatalog);
    return resourceProgramKeys
      .filter(
        (key) =>
          !programType || programType === classroomCatalog[key]?.semanticType
      )
      .map((programKey) => ({
        label: formatTitle(programKey),
        value: programKey,
        id: programKey,
      }));
  }
);

export const paginatedResourcesSelector = createSelector(
  allResourcesSelector,
  paginationSelector,
  (resources, pagination) => {
    const resourceIds = pagination[PaginationTypes.resources]?.pageIds || [];
    return resourceIds?.map((id) => resources[id]);
  }
);
