import {
  addAssessmentCohorts,
  removeAssessmentCohort,
} from 'app/actions/assessments/cohorts';
import { mergeDeepRight, omit } from 'ramda';

import { AssessmentCohortsReduxState } from './types';

export default function assessmentCohorts(
  state: AssessmentCohortsReduxState = {},
  action:
    | ReturnType<typeof addAssessmentCohorts>
    | ReturnType<typeof removeAssessmentCohort>
): AssessmentCohortsReduxState {
  switch (action.type) {
    case 'ADD_ASSESSMENT_COHORTS': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return mergeDeepRight(state, action.payload as any);
    }

    case 'REMOVE_ASSESSMENT_COHORT': {
      const cohortKey = action.payload;
      return omit([cohortKey], state);
    }

    default: {
      return state;
    }
  }
}
