import 'core-js/features/array/from';
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/styles/app.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Routes } from '@emc/routes';
import { initDatadog } from 'app/utils/errors';
import { initi18n } from './i18n';

initi18n();
initDatadog();

ReactDOM.render(<Routes />, document.getElementById('app'));
