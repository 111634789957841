import { IRootStore } from './types';
import cache from './cache';
import { combineReducers } from 'redux';
import data from './data';
import me from './me';
import session from './session';

export const rootReducer = combineReducers<IRootStore>({
  cache,
  data,
  me,
  session,
});
