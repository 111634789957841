import {
  ASSESSMENT_MANAGER_ROLE,
  COMPANY_ADMIN_ROLE,
  ENT_ENGINEER_ROLE,
  ENT_PROGRAM_MANAGER_ROLE,
  LEARNER_MANAGER_ROLE,
  LEGAL_FINANCE_ROLE,
  STAFF_ROLE,
  VIEW_ONLY_ROLE,
} from 'app/utils/constants';
import { anyPass, includes, pluck, prop, propOr } from 'ramda';

import { IRootStore } from '@emc/reducer/types';
import { LearnersReduxState } from 'app/reducer/data/types';
import { LoadingState } from '@emc/reducer/session/loading';
import { NormalizedCompany } from 'app/types/companies';
import { NormalizedLearner } from 'app/types/learners';
import { UdacityMeState } from '@emc/reducer/me/udacity';
import { createSelector } from 'reselect';
import { currentCompanySelector } from '../companies/companies';
import { isAuthorizedBasedOnRoles } from 'app/utils/auth/is-authorized-based-on-roles';
import { loadingSelector } from '../session';

export const meUdacitySelector = (state: IRootStore) => state.me.udacity;

export const emcUserLearnersSelector = createSelector<
  [(state: IRootStore) => string[], (state: IRootStore) => LearnersReduxState],
  NormalizedLearner[]
>(
  (state: IRootStore) => state.me.emc?.learners,
  (state: IRootStore) => state.data.learners,
  (learnerIds, learners) => learnerIds.map((id) => learners[id])
);

export const isAuthorizingSelector = createSelector<
  [(state: IRootStore) => LoadingState],
  boolean
>(
  loadingSelector,
  anyPass([propOr(false, 'ME'), propOr(false, 'UDACITY_ROLES')])
);

export const emcLearnerForCurrentCompanySelector = createSelector<
  [
    (state: IRootStore) => NormalizedCompany,
    (state: IRootStore) => NormalizedLearner[]
  ],
  NormalizedLearner | undefined
>(currentCompanySelector, emcUserLearnersSelector, (company, learners) => {
  const companySlug = company?.slug;
  const companyId = company?.id;
  return learners.find(
    (learner) =>
      learner.companyId === companyId || learner.company?.slug === companySlug
  );
});

export const emcUserRolesForCurrentCompanySelector = createSelector<
  [(state: IRootStore) => NormalizedLearner | undefined],
  string[]
>(emcLearnerForCurrentCompanySelector, (learner) => {
  const roles = learner?.roles || [];
  return pluck('name', roles);
});

export const userApiRolesSelector = createSelector<
  [(state: IRootStore) => UdacityMeState],
  string[]
>(meUdacitySelector, (udacityMe) => udacityMe.roles || []);

export const isUdacityStaffSelector = createSelector<
  [(state: IRootStore) => string[]],
  boolean
>(userApiRolesSelector, includes(STAFF_ROLE));

export const isEnterpriseEngineerSelector = createSelector<
  [(state: IRootStore) => string[]],
  boolean
>(userApiRolesSelector, includes(ENT_ENGINEER_ROLE));

export const isAssessmentManagerSelector = createSelector<
  [(state: IRootStore) => string[]],
  boolean
>(userApiRolesSelector, (roles) =>
  isAuthorizedBasedOnRoles(roles, [ASSESSMENT_MANAGER_ROLE, ENT_ENGINEER_ROLE])
);

export const isLegalFinanceSelector = createSelector<
  [(state: IRootStore) => string[]],
  boolean
>(userApiRolesSelector, (roles) =>
  isAuthorizedBasedOnRoles(roles, [LEGAL_FINANCE_ROLE, ENT_ENGINEER_ROLE])
);

export const isEnterpriseProgramManagerSelector = createSelector<
  [(state: IRootStore) => string[]],
  boolean
>(userApiRolesSelector, (roles) =>
  isAuthorizedBasedOnRoles(roles, [ENT_PROGRAM_MANAGER_ROLE, ENT_ENGINEER_ROLE])
);

export const isEnterpriseAdminSelector = createSelector<
  [(state: IRootStore) => string[]],
  boolean
>(userApiRolesSelector, (roles) =>
  isAuthorizedBasedOnRoles(roles, [
    LEGAL_FINANCE_ROLE,
    ENT_PROGRAM_MANAGER_ROLE,
    ENT_ENGINEER_ROLE,
  ])
);

export const hasCompanyAdminPermissionsOrHigherSelector = createSelector<
  [(state: IRootStore) => string[], (state: IRootStore) => boolean],
  boolean
>(
  emcUserRolesForCurrentCompanySelector,
  isUdacityStaffSelector,
  (roles, isUdacityStaff) =>
    isUdacityStaff || isAuthorizedBasedOnRoles(roles, [COMPANY_ADMIN_ROLE])
);

export const hasLearnerManagerPermissionsOrHigherSelector = createSelector<
  [(state: IRootStore) => string[], (state: IRootStore) => boolean],
  boolean
>(
  emcUserRolesForCurrentCompanySelector,
  isUdacityStaffSelector,
  (roles, isUdacityStaff) =>
    isUdacityStaff ||
    isAuthorizedBasedOnRoles(roles, [COMPANY_ADMIN_ROLE, LEARNER_MANAGER_ROLE])
);

export const hasViewOnlyPermissionsOrHigherSelector = createSelector<
  [(state: IRootStore) => string[], (state: IRootStore) => boolean],
  boolean
>(
  emcUserRolesForCurrentCompanySelector,
  isUdacityStaffSelector,
  (roles, isUdacityStaff) =>
    isUdacityStaff ||
    isAuthorizedBasedOnRoles(roles, [
      COMPANY_ADMIN_ROLE,
      LEARNER_MANAGER_ROLE,
      VIEW_ONLY_ROLE,
    ])
);

export const isAuthenticatedSelector = createSelector<
  [(state: IRootStore) => UdacityMeState],
  boolean
>(meUdacitySelector, prop('isAuthenticated'));

export const referralsSelector = createSelector<
  [(state: IRootStore) => UdacityMeState],
  UdacityMeState['referrals']
>(meUdacitySelector, (udacityMe) => udacityMe.referrals);

export const isLoadingUserExperienceSelector = createSelector<
  [(state: IRootStore) => LoadingState],
  boolean
>(loadingSelector, propOr(false, 'USER_EXPERIENCE'));
