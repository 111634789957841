import {
  AssessmentNormalizedEntities,
  assessmentNormSchema,
} from './assessments';
import {
  CompaniesReduxState,
  CompanyGraphLearningPathsReduxState,
  EnrollmentCohortsReduxState,
  LearnerInterestReduxState,
  LearnersReduxState,
  QuestionSetsReduxState,
} from 'app/reducer/data/types';
import {
  ContractNormalizedEntities,
  contractNormSchema,
  resourceNormSchema,
} from './contract';
import {
  LearnerNormalizedEntities,
  learnerNormSchema,
  managerNormSchema,
} from './learner';

import { Company } from 'app/types/generated/emc';
import { companyGraphLearningPathNormSchema } from './learning-path';
import { enrollmentCohortNormSchema } from './enrollment-cohort';
import { groupNormSchema } from './group';
import { learnerInterestNormSchema } from './learner-interest';
import { schema } from 'normalizr';

export type CompanyNormalizedEntities = ContractNormalizedEntities &
  LearnerNormalizedEntities &
  AssessmentNormalizedEntities & {
    companies: CompaniesReduxState;
    managers?: LearnersReduxState;
    learnerInterests?: LearnerInterestReduxState;
    companyGraphLearningPaths?: CompanyGraphLearningPathsReduxState;
    enrollableCompanyGraphLearningPaths?: CompanyGraphLearningPathsReduxState;
    enrollmentCohorts?: EnrollmentCohortsReduxState;
    questionSets?: QuestionSetsReduxState;
  };

export const companyNormSchema = new schema.Entity<Company>('companies', {
  assessments: { edges: [{ node: assessmentNormSchema }] },
  companyGraphLearningPaths: {
    edges: [{ node: companyGraphLearningPathNormSchema }],
  },
  contracts: { edges: [{ node: contractNormSchema }] },
  enrollableCompanyGraphLearningPaths: {
    edges: [{ node: companyGraphLearningPathNormSchema }],
  },
  enrollmentCohorts: { edges: [{ node: enrollmentCohortNormSchema }] },
  groups: { edges: [{ node: groupNormSchema }] },
  paginatedLearnerInterests: { edges: [{ node: learnerInterestNormSchema }] },
  learners: { edges: [{ node: learnerNormSchema }] },
  managers: { edges: [{ node: managerNormSchema }] },
  resources: { edges: [{ node: resourceNormSchema }] },
});
