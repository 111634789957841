import { CatalogResponse, Course, Nanodegree, Part } from 'app/types/classroom';
import { prop, values } from 'ramda';

import CONFIG from '@emc/config';
import { ClassroomCatalog } from 'app/types/programs';
import { MouseEvent } from 'react';
import { ResourceType } from 'app/types/generated/emc';
import { isDefactoDefaultVersion } from '../programs/versions';
import queryString from 'query-string';
import { track } from 'app/utils/analytics';

export function getClassroomUrl(
  programKey?: string | null,
  programType?: ResourceType | string | null
) {
  // classroom redirects unfound programs to /me by default:
  if (!programKey || !programType)
    return `${CONFIG.REACT_APP_CLASSROOM_URL}/me`;

  let programTypeSlug = 'paid-courses';
  if (programType === ResourceType.Degree) programTypeSlug = 'nanodegrees';
  if (programType === ResourceType.Course) programTypeSlug = 'courses';
  return `${CONFIG.REACT_APP_CLASSROOM_URL}/${programTypeSlug}/${programKey}`;
}

const classroomInvitationsUrl = `${CONFIG.REACT_APP_CLASSROOM_URL}/settings/invitations`;

export enum CtaKey {
  REFERRAL_SUCCESS = 'emc_referral_success',
  POST_APPLICATION = 'emc_post_application',
  PROGRAM_CARD = 'emc_program_card',
  TAGS_FILTER = 'tags_filter',
  FULL_PROGRAMS = 'nanodegree_program',
  INTERMEDIATE_PROGRAMS = 'course',
  INTRODUCTORY_PROGRAMS = 'included_course',
  DISCOVERY = 'discovery',
  FLUENCY = 'fluency',
}

export enum EventName {
  REFERRAL_CTA_CLICKED = 'Referral_Cta_Clicked',
  VIEW_FUTURE_OFFERINGS_CLICKED = 'View_Future_Offerings_Clicked',
}

export const getClassroomInvitationsUrl = (ctaKey: CtaKey): string => {
  return queryString.stringifyUrl({
    url: classroomInvitationsUrl,
    query: {
      src: ctaKey,
    },
  });
};

export const ctaClickHandlerFactory = (
  ctaKey: CtaKey,
  href: string,
  eventName: EventName
) => {
  return (e: MouseEvent) => {
    e.preventDefault();
    track(eventName, { cta_name: ctaKey });
    window.location.href = href;
  };
};

function formatProgramVersions(
  programs: (Nanodegree | Part | Course)[]
): ClassroomCatalog {
  return programs.reduce((programsByKey, program) => {
    const {
      key,
      version,
      title,
      summary,
      semantic_type: semanticType,
    } = program;

    const programData = programsByKey[key] || {
      title,
      key,
      summary,
      semanticType: ResourceType[semanticType],
      versions: [],
    };

    programData.versions = [...programData.versions, { version }];

    const isLatestRelease = isDefactoDefaultVersion(
      programData.versions,
      version
    );

    if (isLatestRelease) {
      programData.title = title;
    }

    if (summary && (!programData.summary || isLatestRelease)) {
      programData.summary = summary;
    }

    if ('hero_image' in program) {
      if (!programData.imageUrl && program.hero_image) {
        programData.imageUrl = program.hero_image?.url;
      }
    }

    if ('image' in program) {
      if (!programData.imageUrl && program.image) {
        programData.imageUrl = program.image?.url;
      }
    }

    programsByKey[key] = programData;

    return programsByKey;
  }, {});
}

export function parseClassConProgramsResponse(res: CatalogResponse) {
  const resNds = res.data.data?.nanodegrees || [];
  const resParts = res.data.data?.parts || {};
  const resPart = res.data.data?.part;
  const resCourses = res.data.data?.courses || [];
  const nanodegrees = resNds.filter(prop('is_public'));
  const parts = values(resParts).filter(prop('is_public'));
  if (resPart) parts.push(resPart);
  const courses = resCourses.filter(prop('is_public'));

  const programs = formatProgramVersions([
    ...nanodegrees,
    ...parts,
    ...courses,
  ]);

  return programs;
}
