import {
  AssessmentSessionQuestions,
  IAssessmentSkills,
  IAssessmentSubjects,
} from 'app/types/assessments';

import { AssessmentModelType } from 'app/types/generated/emc';
import { activeAssessmentUpdateProperty } from 'app/actions/assessments/assessments';

export type AssessmentSkills = {
  subjects: IAssessmentSubjects;
  skills: IAssessmentSkills;
  questions: AssessmentSessionQuestions;
  assessmentModel: AssessmentModelType;
};

export interface IActiveAssessmentState {
  [key: string]: AssessmentSkills;
}

export default function activeAssessments(
  state: IActiveAssessmentState = {},
  action: ReturnType<typeof activeAssessmentUpdateProperty>
): IActiveAssessmentState {
  switch (action.type) {
    case 'ACTIVE_ASSESSMENT_UPDATE_PROPERTY': {
      return {
        ...state,
        [action.payload.assessmentId]: {
          ...state[action.payload.assessmentId],
          [action.payload.property]: action.payload.value,
        },
      };
    }

    default: {
      return state;
    }
  }
}
