import {
  deselectAllLearners,
  deselectLearner,
  selectLearnerIds,
} from 'app/actions/learners/fetch';
import {
  deselectAllReportItems,
  deselectReportItem,
  selectReportItemAction,
} from 'app/actions/reports';

import { combineReducers } from 'redux';

export type SelectedItemsType = string[];
type SelectActions =
  | ReturnType<typeof deselectAllReportItems>
  | ReturnType<typeof deselectAllLearners>
  | ReturnType<typeof deselectLearner>
  | ReturnType<typeof deselectReportItem>
  | ReturnType<typeof selectReportItemAction>
  | ReturnType<typeof selectLearnerIds>;

function generateSelectionReducer(
  selectAction: string,
  deselectAction: string,
  deselectAllAction: string
): (
  state: SelectedItemsType | undefined,
  action: SelectActions
) => SelectedItemsType {
  return (state: SelectedItemsType = [], action) => {
    switch (action.type) {
      case selectAction: {
        if (!('payload' in action)) return state;
        const newState = Array.isArray(action.payload)
          ? new Set([...state, ...action.payload])
          : new Set([...state, action.payload]);
        return Array.from(newState);
      }

      case deselectAction: {
        if (!('payload' in action)) return state;
        if (Array.isArray(action.payload)) return state;
        const newState = new Set(state);
        newState.delete(action.payload);
        return Array.from(newState);
      }

      case deselectAllAction:
        return [];

      default: {
        return state;
      }
    }
  };
}

const reportItemIds = generateSelectionReducer(
  'SELECT_REPORT_ITEM',
  'DESELECT_REPORT_ITEM',
  'DESELECT_ALL_REPORT_ITEMS'
);

const learnerIds = generateSelectionReducer(
  'SELECT_LEARNERS',
  'DESELECT_LEARNER',
  'DESELECT_ALL_LEARNERS'
);

export type SelectionsSessionState = {
  learnerIds: SelectedItemsType;
  reportItemIds: SelectedItemsType;
};

export default combineReducers<SelectionsSessionState>({
  learnerIds,
  reportItemIds,
});
