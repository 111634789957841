import { EnrollmentActionTypes } from '../constants';

const getEnrollmentConfirmationHeading = (
  count: number,
  enrollProgramTitle: string
) => {
  if (count === 1) {
    return `Submit this learner for enrollment into ${enrollProgramTitle}?`;
  }

  return `Submit ${count} learners for enrollment into ${enrollProgramTitle}?`;
};

const getUnenrollmentConfirmationHeading = (
  count: number,
  unenrollProgramTitle: string
) => {
  if (count === 1) {
    return `Submit this learner to be unenrolled from ${unenrollProgramTitle}?`;
  }

  return `Submit ${count} learners to be unenrolled from ${unenrollProgramTitle}?`;
};

const getTransferConfirmationHeading = (
  count: number,
  enrollProgramTitle: string,
  unenrollProgramTitle: string
) => {
  if (count === 1) {
    return `Submit this learner to transfer from ${unenrollProgramTitle} into ${enrollProgramTitle}`;
  }

  return `Submit learners to transfer from ${unenrollProgramTitle} into ${enrollProgramTitle}?`;
};

export const getEnrollmentManagementConfirmationHeading = (
  actionType: EnrollmentActionTypes,
  count: number,
  enrollProgramTitle: string,
  unenrollProgramTitle: string
) => {
  if (actionType === EnrollmentActionTypes.Enroll) {
    return getEnrollmentConfirmationHeading(count, enrollProgramTitle);
  }

  if (actionType === EnrollmentActionTypes.Unenroll) {
    return getUnenrollmentConfirmationHeading(count, unenrollProgramTitle);
  }

  return getTransferConfirmationHeading(
    count,
    enrollProgramTitle,
    unenrollProgramTitle
  );
};

export function pluralizeEn(
  count: number,
  itemSingular: string,
  irregularPlural?: string
) {
  if (count === 1) {
    return itemSingular;
  } else if (irregularPlural) {
    return irregularPlural;
  }
  return `${itemSingular}s`;
}

export function pluralizeEnCount(
  count: number,
  itemSingular: string,
  irregularPlural?: string
) {
  return `${count} ${pluralizeEn(count, itemSingular, irregularPlural)}`;
}
