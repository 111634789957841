import {
  EnrollmentStatusEnum,
  Learner,
  ResourceType,
} from 'app/types/generated/emc';

import CONFIG from '@emc/config';
import i18n from 'i18next';
import { knownEmcAutomationUserKeys } from '../constants';

// util
export function getName(
  first?: string | null,
  last?: string | null,
  userKey?: string | null
): string {
  const name = `${first || ''} ${last || ''}`.trim();
  if (!name) {
    if (knownEmcAutomationUserKeys.includes(userKey || '')) {
      return 'EMC Automation';
    } else {
      return userKey || '';
    }
  }
  return name;
}

export function getEmail(
  learner: Pick<Learner, 'user' | 'email'> | undefined | null,
  showDiscrepancy?: boolean
): string {
  const userEmail = learner?.user?.email;
  const learnerEmail = learner?.email;
  if (!userEmail && learnerEmail) {
    return learnerEmail;
  }

  let emailStr = userEmail;

  if (userEmail !== learnerEmail && showDiscrepancy) {
    emailStr += ` (${learnerEmail})`;
  }

  return emailStr || '-';
}

export function getSelfServeLinkUrl(
  token: string,
  next: string,
  fullPath = true
) {
  const pathname = `/link/${token}?next=${encodeURIComponent(next)}`;
  return fullPath ? `${CONFIG.REACT_APP_EMC_WEB_URL}${pathname}` : pathname;
}

export function getReadableProgramType(type: ResourceType | undefined): string {
  switch (type) {
    case ResourceType.Course:
      return i18n.t('learner.foundationalCourse');
    case ResourceType.Part:
      return i18n.t('common.course');
    case ResourceType.Degree:
      return i18n.t('common.nanodegreeProgram');
    default:
      return '';
  }
}

export function getReadableEnrollmentStatus(
  status: EnrollmentStatusEnum | null | undefined
): string {
  switch (status) {
    case EnrollmentStatusEnum.Enrolled:
      return i18n.t('learner.statusEnrolled');
    case EnrollmentStatusEnum.Unenrolled:
      return i18n.t('learner.statusUnenrolled');
    case EnrollmentStatusEnum.Graduated:
      return i18n.t('learner.statusGraduated');
    case EnrollmentStatusEnum.StaticAccess:
      return i18n.t('learner.statusStaticAccess');
    default:
      return '-';
  }
}
