import { Loading } from '@udacity/veritas-components';
import React from 'react';
import { loadingSelector } from 'app/selectors/session';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

export const BackgroundLoading: React.FC = () => {
  const loadingState = useSelector(loadingSelector);

  const isSomethingLoading = Object.keys(loadingState).length > 0;

  if (isSomethingLoading) {
    return (
      <div
        className={styles.backgroundLoading}
        data-testid="background-loading"
      >
        <Loading size="sm" />
      </div>
    );
  }
  return null;
};
