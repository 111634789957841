import { CompaniesReduxState } from 'app/reducer/data/types';
import { IOption } from 'app/types/options';
import map from 'lodash/map';
import { sortBy } from 'ramda';

const defaultValueProp = 'value';
const defaultLabelProp = 'label';

export const formatSelectOptions =
  <T>(
    valueProp: string = defaultValueProp,
    labelProp: string = defaultLabelProp
  ) =>
  (options: T[] = []): (T & IOption<string>)[] => {
    return map(options, (option) => {
      const value: string = option[valueProp];
      return {
        ...option,
        value,
        label: option[labelProp] || value,
      };
    });
  };

export function sortByLabel<T>(labelProp: string, list: T[]): T[] {
  return sortBy<T>((obj) =>
    (obj[labelProp] as unknown as string).toLowerCase()
  )(list) as T[];
}

export const formatAndSortSelectOptions =
  <T>(
    valueProp: string = defaultValueProp,
    labelProp: string = defaultLabelProp
  ) =>
  (options: T[] = []): (T & IOption<string>)[] => {
    const formattedOptions = formatSelectOptions<T>(
      valueProp,
      labelProp
    )(options);
    return sortByLabel(defaultLabelProp, formattedOptions);
  };

export function getCompaniesFilter(companies: CompaniesReduxState) {
  const companyIds = Object.keys(companies);
  return {
    id: 'companyId',
    label: 'Company Name',
    description: 'Company Name',
    options: sortBy(
      (co) => co.label,
      companyIds.map((companyId) => {
        return {
          label: companies[companyId].name,
          id: companyId,
          value: companyId,
        };
      })
    ),
  };
}
