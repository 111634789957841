import React from 'react';

export const UdacityLogo: React.FC<{
  width?: number;
  height?: number;
  showLabel?: boolean;
  viewBox?: string;
  inverted?: boolean;
}> = ({
  showLabel = false,
  width = 150,
  height = 100,
  viewBox = '0 0 220 160',
  inverted,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
  >
    <title>Udacity Logo</title>
    <path
      d="M1,29.6V95.9a55.8,55.8,0,0,0,55.5,55.8h.2a56,56,0,0,0,27.7-7.4L125,121a50.5,50.5,0,0,0,27.3-44.9V7a.5.5,0,0,0-.3-.5h0L142.1.7h-.6l-40.7,23a.5.5,0,0,0-.3.5v72a44.1,44.1,0,0,1-.9,8.9,48.1,48.1,0,0,1-2.6,8.4,10.1,10.1,0,0,1-.7,1.5v.2H96a9,9,0,0,1-2.4-.4h-.3a41.6,41.6,0,0,1-13.9-6,39.4,39.4,0,0,1-5.9-4.9A37.9,37.9,0,0,1,68.7,98a44.2,44.2,0,0,1-3.6-6.8,35.4,35.4,0,0,1-2.3-7.4,42.6,42.6,0,0,1-.6-7.8V6.9c0-.2-.1-.3-.3-.4L52,.4h-.6L1.3,29.2C1.1,29.3,1,29.4,1,29.6Zm107.6,84.7a54.3,54.3,0,0,0,3.3-18.1V30.3l29-16.6V76.1a40.9,40.9,0,0,1-.8,8,39.6,39.6,0,0,1-2.3,7.4,40.7,40.7,0,0,1-8.5,12.5,38.7,38.7,0,0,1-5.9,4.8,43.2,43.2,0,0,1-6.7,3.6,33.7,33.7,0,0,1-7.4,2.3h-.9Zm-96-78.6L50.8,13.4V75.8a51.1,51.1,0,0,0,38.4,49.4h.5l-.3.4a14.6,14.6,0,0,1-1.6,1.9,48.6,48.6,0,0,1-6.6,5.4,42.8,42.8,0,0,1-7.6,4.1h0a49.8,49.8,0,0,1-8.3,2.6,41.1,41.1,0,0,1-8.4.8h-.3a45.8,45.8,0,0,1-9-.9,49.8,49.8,0,0,1-8.3-2.6,41.9,41.9,0,0,1-7.5-4.1,43.8,43.8,0,0,1-6.6-5.5,45,45,0,0,1-9.5-14.2,49.8,49.8,0,0,1-2.6-8.3,47.9,47.9,0,0,1-.7-9V35.8Z"
      fill={inverted ? '#ffffff' : '#2015ff'}
    />
    {showLabel && (
      <>
        <path
          d="M276.4,84.3c0,11.6-9.9,20.7-22.6,20.7s-22.3-8.9-22.3-20.7V37.5H220.1V84.4c0,18,14.1,31.1,33.7,31.1s34-13.1,34-31.1v-47H276.4Z"
          fill={'#ffffff'}
        />
        <path
          d="M347.6,37.5H322.1v77.2h25.4c23.4,0,38.6-15.2,38.6-38.6S371,37.5,347.6,37.5ZM374.7,76c0,17.6-10.4,28.1-27.8,28.1H333.5V47.9h13.4C364.3,47.9,374.7,58.4,374.7,76Z"
          fill={'#ffffff'}
        />
        <polygon
          points="786.3 37.4 763.9 69.8 741.3 37.5 741.3 37.4 728.4 37.4 758.2 81.7 758.2 114.6 769.6 114.6 769.6 81.7 798.8 37.7 799 37.4 786.3 37.4"
          fill={'#ffffff'}
        />
        <polygon
          points="645.1 47.9 671.4 47.9 671.4 114.6 682.8 114.6 682.8 47.9 709.1 47.9 709.1 37.4 645.1 37.4 645.1 47.9"
          fill={'#ffffff'}
        />
        <rect x="603.3" y="37.4" width="11.4" height="77.13" fill={'#ffffff'} />
        <path
          d="M561,95.9a32.5,32.5,0,0,1-22.1,9.1c-15.8,0-27.7-12.7-27.7-29.5S523,47,538.7,47a31.5,31.5,0,0,1,20.7,7.6l1.7,1.5,6-8.4L566,46.5c-.3-.4-9.5-10-27.5-10-22,0-38.6,16.8-38.6,39.1s16.7,40,38.9,40c18.5,0,28.8-11.5,29.2-12l1.1-1.2-6.6-8.1Z"
          fill={'#ffffff'}
        />
        <path
          d="M447.9,37.5H437.4l-31.2,77.1h12.1l6.9-17L457.5,91l9.5,23.5h12.1L448,37.6Zm5.2,42.7-22.7,4.7,12.3-30.3Z"
          fill={'#ffffff'}
        />
      </>
    )}
  </svg>
);
