import {
  PaginationState,
  PaginationTypes,
} from '@emc/reducer/session/pagination';

import { IRootStore } from '@emc/reducer/types';
import { createSelector } from 'reselect';
import { getQueryParams } from 'app/utils/router/query-params';

export type PaginationData = {
  activeIndex: number;
  orderBy: string;
  order: 'DESC' | 'ASC';
  search: string;
  first: number;
  createdAfter: string;
  createdBefore: string;
  offset: number;
  total: number;
};

export type BasicPaginationData = Omit<PaginationData, 'total'>;

export const getPaginationData = ({
  suffix = '',
  defaultSizePerPage = 10,
  defaultOrderBy = 'id',
  defaultOrder = 'DESC',
}): BasicPaginationData => {
  const paginationQueryData = getQueryParams();
  const activeIndex = Number(paginationQueryData[`activeIndex${suffix}`]) || 1;
  const first =
    Number(paginationQueryData[`first${suffix}`]) || defaultSizePerPage;
  const orderBy = paginationQueryData[`orderBy${suffix}`] || defaultOrderBy;
  const order =
    (paginationQueryData[`order${suffix}`] as 'ASC' | 'DESC') || defaultOrder;
  const search = paginationQueryData.search || '';
  const createdAfter = paginationQueryData.createdAfter;
  const createdBefore = paginationQueryData.createdBefore;

  return {
    activeIndex,
    orderBy,
    order,
    search,
    first,
    createdAfter,
    createdBefore,
    offset: first * (activeIndex - 1),
  };
};

export const paginationSelector = (state: IRootStore) =>
  state.session.pagination;

export type PaginationProps = {
  suffix?: string;
  defaultSizePerPage?: number;
  defaultOrderBy?: string;
  defaultOrder?: 'DESC' | 'ASC';
  tableName: PaginationTypes;
};

export const paginationDataSelector = createSelector<
  [
    (state: IRootStore, paginationProps: PaginationProps) => PaginationProps,
    (state: IRootStore) => PaginationState
  ],
  PaginationData
>(
  (x, dataPaginationProps) => dataPaginationProps,
  paginationSelector,
  (dataPaginationProps, pagination) => {
    const {
      tableName,
      suffix,
      defaultSizePerPage,
      defaultOrderBy,
      defaultOrder,
    } = dataPaginationProps;
    const total = pagination[tableName]?.totalCount || 0;
    return {
      ...getPaginationData({
        suffix,
        defaultSizePerPage,
        defaultOrderBy,
        defaultOrder,
      }),
      total,
    };
  }
);

export const totalResourceCountSelector = createSelector(
  paginationSelector,
  (paginatedData) => {
    return paginatedData.resources?.totalCount;
  }
);
