// Sync

import { createAction, createSimpleAction } from 'app/utils/actions';

export function cacheActiveCompany(companySlug: string) {
  return createAction('CACHE_ACTIVE_COMPANY', companySlug);
}

export function cacheAssessmentSkills(assessmentId: string) {
  return createAction('CACHE_ASSESSMENT_SKILLS', assessmentId);
}

export function cacheCompanies() {
  return createSimpleAction('CACHE_COMPANIES');
}

export function cacheClassConCatalog() {
  return createSimpleAction('CACHE_CLASS_CON_CATALOG');
}

export function cacheCompanyCatalog(companySlug: string) {
  return createAction('CACHE_COMPANY_CATALOG', companySlug);
}

export function cacheCompanyPrograms(companyId: string) {
  return createAction('CACHE_COMPANY_PROGRAMS', companyId);
}

export function cacheCompanyQuestionSet(companyId: string) {
  return createAction('CACHE_COMPANY_QUESTION_SET', companyId);
}

export function cacheEnrollableContractTerms(companyId: string) {
  return createAction('CACHE_ENROLLABLE_CONTRACT_TERMS', companyId);
}
