import {
  ASSESSMENT_MANAGER_ROLE,
  ENT_ENGINEER_ROLE,
  ENT_PROGRAM_MANAGER_ROLE,
  LEGAL_FINANCE_ROLE,
  STAFF_ROLE,
} from 'app/utils/constants';

export const entEngineerId = 'uTEST12345';
export const udacityStaffOnlyId = 'uTEST12348';
export const legalFinanceId = 'uTEST12349';
export const entProgramManagerId = 'uTEST12350';

export function getRolesByUserKey(userKey: string) {
  switch (userKey) {
    case entEngineerId:
      return [STAFF_ROLE, ENT_ENGINEER_ROLE, ASSESSMENT_MANAGER_ROLE];
    case udacityStaffOnlyId:
      return [STAFF_ROLE];
    case legalFinanceId:
      return [STAFF_ROLE, LEGAL_FINANCE_ROLE];
    case entProgramManagerId:
      return [STAFF_ROLE, ENT_PROGRAM_MANAGER_ROLE];
    default:
      return [];
  }
}
