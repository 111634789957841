import {
  IconBookOpen,
  IconCareers,
  IconClipboard,
  IconCohort,
  IconCompass,
  IconFile,
  IconGauge,
  IconInfo,
  IconNotebook,
  IconReports,
  IconReviews,
  IconSettings,
  IconStudent,
} from '@udacity/veritas-icons';
import React, { useCallback, useEffect, useRef } from 'react';
import {
  companyIsEmc2BetaTester,
  companyShowsCatalog,
  companyShowsExplorer,
  companyShowsReports,
  companyShowsTalentPlans,
} from 'app/utils/settings';
import {
  emcLearnerForCurrentCompanySelector,
  hasCompanyAdminPermissionsOrHigherSelector,
  hasViewOnlyPermissionsOrHigherSelector,
  isUdacityStaffSelector,
} from 'app/selectors/me';

import CONFIG from 'app/config';
import { CompanySwitcher } from './company-switcher';
import { IconBeta } from 'app/elements/icon-beta';
import { Logout } from './logout';
import { NavItem } from './nav-item';
import { NavSwitch } from './nav-switch';
import { ParentCompanyNavigation } from './parent-company';
import classNames from 'classnames';
import { currentCompanySelector } from 'app/selectors/companies/companies';
import { fetchCompanies } from 'app/actions/companies';
import { getClassroomUrl } from 'app/utils/classroom';
import { getCompanyAdminPath } from 'app/utils/router/paths';
import { isMobileWidth } from 'app/utils/component';
import { persistUtmParams } from 'app/utils/router/query-params';
import styles from './styles.module.scss';
import { track } from 'app/utils/analytics';
import { useAppDispatch } from 'app/hooks/use-app-dispatch';
import { useCatalogPath } from 'app/hooks/use-catalog-path';
import { useCurrentCompanySlug } from 'app/hooks/use-current-company-slug';
import { useLocalStorage } from 'app/hooks/use-local-storage';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const headerZidx = 100000;
const EXPLORER_URL = 'https://udacityexplorer.zendesk.com/hc/en-us';

export const Navigation: React.FC = () => {
  const { t } = useTranslation();
  const isUdacityStaff = useSelector(isUdacityStaffSelector);
  const company = useSelector(currentCompanySelector);
  const isCompanyAdmin = useSelector(
    hasCompanyAdminPermissionsOrHigherSelector
  );
  const isViewOnly = useSelector(hasViewOnlyPermissionsOrHigherSelector);
  const currentCompanyLearner = useSelector(
    emcLearnerForCurrentCompanySelector
  );
  const dispatch = useAppDispatch();
  const isMobile = isMobileWidth();
  const { getCatalogPath } = useCatalogPath();
  const companySlug = useCurrentCompanySlug();
  const companyId = company?.id;

  const showsReports =
    companyShowsReports(company?.companySettings || []) && isViewOnly;
  const showCatalogNav = companyShowsCatalog(company?.companySettings || []);
  const showTalentPlans = companyShowsTalentPlans(
    company?.companySettings || []
  );
  const showsExplorerLink =
    companySlug &&
    isViewOnly &&
    companyShowsExplorer(company.companySettings || []);
  const shouldShowViewOnly = isViewOnly && companySlug;
  const shouldShowTalentPlans = isViewOnly && showTalentPlans;
  const shouldShowReports = showsReports || isUdacityStaff;
  const shouldShowAnalyticsBeta =
    shouldShowViewOnly &&
    companyIsEmc2BetaTester(company?.companySettings || []);
  const shouldShowMyPrograms = companySlug && Boolean(currentCompanyLearner);
  const shouldShowCatalog = companySlug && (isUdacityStaff || showCatalogNav);
  const isStaffOnlyCatalog = !showCatalogNav && company?.companySettings;
  const shouldShowTranscript = companySlug && Boolean(currentCompanyLearner);
  const shouldShowCompanySettings = companySlug && isCompanyAdmin;
  const isCompanyLoaded = companySlug && company.id;
  const parentCompanyMatch = useRouteMatch<{ companySlug?: string }>(
    '/pc/:companySlug'
  );
  const parentCompanySlug = parentCompanyMatch?.params.companySlug;

  const [isExpanded, setExpanded] = useLocalStorage<boolean>(
    'navPreference',
    !isMobile
  );

  useEffect(() => {
    if (isUdacityStaff) {
      dispatch(fetchCompanies());
    }
  }, [dispatch, isUdacityStaff]);

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleOutsideClick: EventListener = (e: Event) => {
      const el = containerRef.current;
      const isClickInside = el && el.contains(e.target as Node);

      if (!isClickInside && isExpanded) {
        setExpanded(false);
      }
    };

    if (isMobile) {
      document.addEventListener('click', handleOutsideClick);
    }

    return (): void =>
      document.removeEventListener('click', handleOutsideClick);
  }, [isExpanded, isMobile, setExpanded]);

  // Collapse menu when screen is resized to mobile
  useEffect(() => {
    const handleResize: EventListener = (e: Event) => {
      if (isMobileWidth()) {
        setExpanded(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return (): void => document.removeEventListener('resize', handleResize);
  }, [setExpanded]);

  const handleToggleClick = useCallback(
    () => setExpanded(!isExpanded),
    [isExpanded, setExpanded]
  );

  const handleNavItemClicked = useCallback(() => {
    if (isMobile) {
      setExpanded(false);
    }
  }, [isMobile, setExpanded]);
  const handleClickExplorer = () => {
    track('ExplorerClicked', { companyId });
  };

  return (
    <aside
      className={classNames(styles.cabinetNavContainer, {
        [styles.expanded]: isExpanded,
      })}
      id="navbar-container"
    >
      <NavSwitch
        handleToggleClick={handleToggleClick}
        isMobile={isMobile}
        isExpanded={isExpanded}
      />
      <div className={styles.navbarContainer} ref={containerRef}>
        <nav
          className={classNames(styles.navSection, styles.logoNavContainer)}
          aria-label={t('emc.mainCompanyNavLabel')}
        >
          <CompanySwitcher isExpanded={isExpanded} />
          {parentCompanySlug ? (
            <ParentCompanyNavigation
              isExpanded={isExpanded}
              slug={parentCompanySlug}
              handleNavItemClicked={handleNavItemClicked}
            />
          ) : (
            <>
              {shouldShowViewOnly && (
                <NavItem
                  Icon={IconGauge}
                  to={`/c/${companySlug}/dashboard`}
                  label={t('common.dashboard')}
                  isExpanded={isExpanded}
                  onClick={handleNavItemClicked}
                />
              )}

              {isCompanyLoaded && (
                <>
                  {shouldShowTalentPlans && (
                    <NavItem
                      Icon={IconClipboard}
                      to={`/c/${companySlug}/talent-plans`}
                      label={t('emc.talentPlans')}
                      isExpanded={isExpanded}
                      onClick={handleNavItemClicked}
                    />
                  )}
                  {shouldShowReports && (
                    <NavItem
                      Icon={IconReports}
                      to={`/c/${companySlug}/reports`}
                      label={
                        <span>
                          {t('common.reports')}
                          {!showsReports && company?.companySettings && (
                            <span className={styles.red}> (Staff Only)</span>
                          )}
                        </span>
                      }
                      isExpanded={isExpanded}
                      onClick={handleNavItemClicked}
                    />
                  )}
                </>
              )}
              {shouldShowAnalyticsBeta && (
                <NavItem
                  Icon={IconBeta}
                  to={{
                    pathname: `${CONFIG.REACT_APP_MANAGE_WEB_URL}/c/${companySlug}/analytics`,
                  }}
                  label={t('common.analytics')}
                  isExpanded={isExpanded}
                  target="_blank"
                  rel="noopener"
                />
              )}
              {shouldShowViewOnly && (
                <>
                  <NavItem
                    Icon={IconReviews}
                    to={`/c/${companySlug}/assessments`}
                    label={t('common.assessments')}
                    isExpanded={isExpanded}
                    onClick={handleNavItemClicked}
                  />
                  <NavItem
                    Icon={IconStudent}
                    to={`/c/${companySlug}/learners`}
                    label={t('common.learners')}
                    isExpanded={isExpanded}
                    onClick={handleNavItemClicked}
                  />
                  <NavItem
                    Icon={IconCohort}
                    to={`/c/${companySlug}/cohorts`}
                    label={t('common.cohorts')}
                    isExpanded={isExpanded}
                    onClick={handleNavItemClicked}
                  />
                  <NavItem
                    Icon={IconClipboard}
                    to={`/c/${companySlug}/user-permissions`}
                    label={t('common.userPermissions')}
                    isExpanded={isExpanded}
                    onClick={handleNavItemClicked}
                  />
                </>
              )}
              {shouldShowMyPrograms && (
                <NavItem
                  Icon={IconBookOpen}
                  to={{ pathname: getClassroomUrl() }}
                  label={t('emc.myPrograms')}
                  isExpanded={isExpanded}
                  target="_blank"
                  rel="noopener"
                />
              )}
              {shouldShowCatalog && (
                <NavItem
                  Icon={IconCompass}
                  to={getCatalogPath()}
                  label={
                    <span>
                      {t('common.catalog')}
                      {isStaffOnlyCatalog ? (
                        <span className={styles.red}> (Staff Only)</span>
                      ) : (
                        ''
                      )}
                    </span>
                  }
                  isExpanded={isExpanded}
                  onClick={handleNavItemClicked}
                />
              )}
              {shouldShowTranscript && (
                <NavItem
                  Icon={IconFile}
                  to={persistUtmParams(`/c/${companySlug}/me`)}
                  label={t('common.transcript')}
                  isExpanded={isExpanded}
                  onClick={handleNavItemClicked}
                />
              )}
              {shouldShowCompanySettings && (
                <NavItem
                  Icon={IconSettings}
                  to={`/c/${companySlug}/settings`}
                  label={t('common.settings')}
                  isExpanded={isExpanded}
                  onClick={handleNavItemClicked}
                />
              )}
              {showsExplorerLink && (
                <NavItem
                  Icon={IconInfo}
                  to={{ pathname: EXPLORER_URL }}
                  onClick={handleClickExplorer}
                  label={t('common.explorer')}
                  isExpanded={isExpanded}
                  target="_blank"
                  rel="noopener"
                />
              )}
            </>
          )}
        </nav>
        <nav className={styles.navSection} aria-label="additional navigation">
          {isUdacityStaff && (
            <>
              {companyId && !parentCompanySlug && (
                <NavItem
                  Icon={IconCareers}
                  to={getCompanyAdminPath(companyId)}
                  label={t('emc.companyId', { companyId })}
                  isExpanded={isExpanded}
                  onClick={handleNavItemClicked}
                />
              )}
              <NavItem
                Icon={IconNotebook}
                to="/admin"
                label={t('common.admin')}
                exact
                isExpanded={isExpanded}
                onClick={handleNavItemClicked}
              />
            </>
          )}
          <Logout isExpanded={isExpanded} />
        </nav>
      </div>
    </aside>
  );
};
