import { setEnrollableContractTermIds } from 'app/actions/contract-terms/enrollable-contract-terms';

export type EnrollableContractTermSessionState = Record<string, string[]>;

export default function enrollableContractTerms(
  state: EnrollableContractTermSessionState = {},
  action: ReturnType<typeof setEnrollableContractTermIds>
): EnrollableContractTermSessionState {
  switch (action.type) {
    case 'SET_ENROLLABLE_CONTRACT_TERMS': {
      const { companyId, contractTermIds } = action.payload;
      return { ...state, [companyId]: contractTermIds };
    }

    default: {
      return state;
    }
  }
}
