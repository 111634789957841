import CONFIG from '@emc/config';
import { api } from './api-service';

export const registrarService = {
  fetch(
    path: string,
    params?: { [key: string]: string | number | boolean | undefined }
  ) {
    return api.request({
      method: 'GET',
      url: `${CONFIG.REACT_APP_REGISTRAR_API_URL}${path}`,
      params,
    });
  },
};
