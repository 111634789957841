import React, { useEffect } from 'react';

import { FullPageLoading } from '@emc/elements/loading';
import { RouteComponentProps } from 'react-router-dom';
import { addFlashMessage } from 'app/actions/flash';
import { createLearnerSelfServe } from 'app/actions/learner-self-serve';
import { isUdacityStaffSelector } from 'app/selectors/me';
import queryString from 'query-string';
import { updateWindowHref } from 'app/utils/router/paths';
import { useAppDispatch } from 'app/hooks/use-app-dispatch';
import { useSelector } from 'react-redux';

export const LearnerSelfServeRoute: React.FC<
  RouteComponentProps<{ token: string }>
> = (props) => {
  const { location, match, history } = props;
  const isUdacityStaff = useSelector(isUdacityStaffSelector);
  const { token } = match.params;
  const queryParams = queryString.parse(location.search);
  const redirectionRoute = decodeURIComponent(queryParams.next as string);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // If udacity staff then redirect to the url
    let successfulRedirect = true;
    if (isUdacityStaff) {
      successfulRedirect = updateWindowHref(redirectionRoute);
    } else if (token) {
      // If not staff then add the learner to the company before redirecting
      createLearnerSelfServe(token).then(({ error, learner }) => {
        // If there was an error adding learner to the company then redirect them to unauthorized
        if (!error && learner) {
          successfulRedirect = updateWindowHref(redirectionRoute);
        } else history.replace('/unauthorized');
      });
    }
    if (!successfulRedirect) {
      dispatch(
        addFlashMessage(
          'error',
          `Unable to redirect to ${redirectionRoute} - not a Udacity route.`
        )
      );
      history.replace('/');
    }
  }, [token, isUdacityStaff, history, redirectionRoute, dispatch]);

  return <FullPageLoading />;
};
