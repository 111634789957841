import { lazy } from 'react';

export const AssessmentCohortOverview = lazy(() =>
  import('@emc/assessments-views').then((module) => ({
    default: module.AssessmentCohortOverview,
  }))
);

export const AssessmentLanding = lazy(() =>
  import('@emc/assessment-views').then((module) => ({
    default: module.AssessmentLanding,
  }))
);

export const AssessmentsForm = lazy(() =>
  import('@emc/assessments-views/new/main/form').then((module) => ({
    default: module.AssessmentsForm,
  }))
);

export const AssessmentsNew = lazy(() =>
  import('@emc/assessments-views').then((module) => ({
    default: module.AssessmentsNew,
  }))
);

export const AssessmentsNewSuccess = lazy(() =>
  import('@emc/assessments-views').then((module) => ({
    default: module.AssessmentsNewSuccess,
  }))
);

export const CompanyCatalog = lazy(() =>
  import('@emc/company-views/catalog').then((module) => ({
    default: module.CompanyCatalog,
  }))
);

export const CompanyLanding = lazy(() =>
  import('@emc/company-views').then((module) => ({
    default: module.CompanyLanding,
  }))
);

export const CompanySettings = lazy(() =>
  import('@emc/company-views').then((module) => ({
    default: module.CompanySettings,
  }))
);

export const Dashboard = lazy(() =>
  import('@emc/company-views').then((module) => ({
    default: module.Dashboard,
  }))
);

export const EnrollmentCohorts = lazy(() =>
  import('@emc/company-views/enrollment-cohorts').then((module) => ({
    default: module.EnrollmentCohorts,
  }))
);

export const ExpressInterest = lazy(() =>
  import('@emc/company-views/catalog/express-interest').then((module) => ({
    default: module.ExpressInterest,
  }))
);

export const GeneralNominationForm = lazy(() =>
  import('@emc/forms/nomination').then((module) => ({
    default: module.NominationForm,
  }))
);

export const LearnerMe = lazy(() =>
  import('@emc/learner-views').then((module) => ({
    default: module.LearnerMe,
  }))
);

export const LearnerLearningPaths = lazy(() =>
  import('@emc/learner-views').then((module) => ({
    default: module.LearnerLearningPaths,
  }))
);

export const LearnerTranscript = lazy(() =>
  import('@emc/learner-views').then((module) => ({
    default: module.LearnerTranscript,
  }))
);

export const Learners = lazy(() =>
  import('@emc/learners-views').then((module) => ({
    default: module.Learners,
  }))
);

export const NotFound = lazy(() =>
  import('@emc/error-views').then((module) => ({
    default: module.NotFound,
  }))
);

export const Reports = lazy(() =>
  import('@emc/company-views').then((module) => ({
    default: module.Reports,
  }))
);
