import {
  CompanyAdminRoute,
  CompanyLearnerRoute,
  StaffRoute,
  ViewOnlyRoute,
} from '@emc/auth';
import React, { useEffect } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { BatchProgressCards } from 'app/elements/progress-bar/batch-progress';
import { NotFound } from 'app/views/error';
import { ParentCompanyLanding } from 'app/views/parent-company/landing';
import { fetchCompanyBySlug } from 'app/actions/companies';
import { getCompanySlugFromPathname } from 'app/utils/router/paths';
import { useAppDispatch } from 'app/hooks/use-app-dispatch';

export const ParentCompanyRoutes: React.FC<
  RouteComponentProps<{ companySlug: string }>
> = (props) => {
  const {
    match: { url },
  } = props;
  const pathname = window.location.pathname;
  const companySlug = getCompanySlugFromPathname(pathname);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCompanyBySlug(companySlug));
  }, [companySlug, dispatch]);

  return (
    <>
      <Switch>
        {/* Redirection */}
        <CompanyLearnerRoute
          component={ParentCompanyLanding}
          exact
          path={`${url}/:companySlug`}
        />

        <StaffRoute path={`${url}/:companySlug/contracts/create`} />

        <ViewOnlyRoute path={`${url}/:companySlug/home`} />
        <ViewOnlyRoute path={`${url}/:companySlug/user-permissions`} />
        <CompanyAdminRoute path={`${url}/:companySlug/contracts`} />
        <StaffRoute path={`${url}/:companySlug/edit`} />

        <Route component={NotFound} path="**" />
      </Switch>
      <BatchProgressCards />
    </>
  );
};
