import { NavLink, NavLinkProps } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { IconWithoutChildrenProps } from '@udacity/veritas-icons';
import classNames from 'classnames';
import styles from './styles.module.scss';

type NavItemProps = Pick<NavLinkProps, 'to' | 'target' | 'onClick' | 'rel'> & {
  Icon: React.FC<IconWithoutChildrenProps>;
  isExpanded: boolean;
  label: React.ReactNode;
  exact?: boolean;
};

export const NavItem: React.FC<NavItemProps> = ({
  label,
  Icon,
  isExpanded,
  to,
  exact,
  onClick,
  target,
  rel,
}) => {
  const [isShrunkLabel, setShrunkLabel] = useState(!isExpanded);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isExpanded) {
      timeout = setTimeout(() => setShrunkLabel(false), 200);
    } else {
      setShrunkLabel(true);
    }
    return (): void => {
      clearTimeout(timeout);
    };
  }, [isExpanded]);

  return (
    <NavLink
      activeClassName={styles.activeNavItem}
      className={classNames(styles.navItem, {
        [styles.expanded]: isExpanded,
      })}
      exact={exact}
      to={to}
      onClick={onClick}
      target={target}
      rel={rel}
    >
      <span
        className={classNames(
          styles.navLabel,
          isShrunkLabel && styles.shrunkLabel
        )}
      >
        {label}
      </span>{' '}
      <Icon size="lg" />
    </NavLink>
  );
};
