import { AppThunk } from 'app/types/shared';
import { BatchProgressKeys } from '../polling';
import { pollForLearnerLifecycleProgress } from '../enrollments/learner-lifecycles';

export function restartProgressFetch(
  batchIds: string[],
  progressKey: BatchProgressKeys
): AppThunk {
  return (dispatch) => {
    switch (progressKey) {
      case BatchProgressKeys.enrollments:
      case BatchProgressKeys.unenrollments:
        return dispatch(pollForLearnerLifecycleProgress(batchIds, progressKey));
      default:
        return;
    }
  };
}
