import {
  AdmissionQuestionInput,
  AdmissionQuestionType,
  EnrollmentCohort,
  EnrollmentCohortCohortType,
  LearnerInterestProgramType,
  LearningPathNodeType,
  ResourceType,
  Status,
} from 'app/types/generated/emc';
import { IFilter, IOption } from 'app/types/options';

import { ALL } from '../constants';
import { ClassroomCatalog } from 'app/types/programs';
import { FormikErrors } from 'formik';
import { QuestionSetFormQuestionInput } from 'app/forms/question-set/question-set-form';
import dayjs from 'dayjs';

const draftOption = { label: 'Draft', value: Status.Draft };
const approvedOption = { label: 'Approved', value: Status.Approved };
const suspendedOption = {
  label: 'Suspended',
  value: Status.Suspended,
};

export const validStatuses = [draftOption, approvedOption, suspendedOption];

export const getValidStatuses = (status?: Status): IOption<Status>[] => {
  if (!status || status === Status.Draft) {
    return validStatuses;
  }
  return [approvedOption, suspendedOption];
};

export const getValidContractTermStatuses = (
  contractStatus: Status | undefined,
  contractTermStatus: Status,
  isStrictlyManaged: boolean,
  isLegalFinance: boolean
): IOption<Status>[] => {
  const hasRestrictedAccess = isStrictlyManaged && !isLegalFinance;

  if (
    contractStatus === Status.Draft ||
    (contractTermStatus === Status.Draft && hasRestrictedAccess)
  ) {
    return [draftOption, suspendedOption];
  }

  if (
    contractStatus === Status.Suspended ||
    (contractTermStatus === Status.Suspended && hasRestrictedAccess)
  ) {
    return [suspendedOption];
  }

  return getValidStatuses(contractTermStatus);
};

export const isApproved = (status: string | null | undefined): boolean =>
  status?.toUpperCase() === Status.Approved;
export const isSuspended = (status: string | null | undefined): boolean =>
  status?.toUpperCase() === Status.Suspended;
export const isDraft = (status: string | null | undefined): boolean =>
  status?.toUpperCase() === Status.Draft;
export const isItemExpired = (item: { endDate?: string }): boolean => {
  if (!item.endDate) {
    return false;
  } else {
    const expirationDate = dayjs.utc(item.endDate);
    return expirationDate.diff(dayjs.utc(), 'days', true) < 0;
  }
};

export function isConnectCohort(
  cohort: Pick<EnrollmentCohort, 'cohortType'> | undefined
): boolean {
  return (
    cohort?.cohortType?.toUpperCase() === EnrollmentCohortCohortType.Connect
  );
}

export function isUhomeCohort(
  cohort: Pick<EnrollmentCohort, 'cohortType'> | undefined
): boolean {
  return cohort?.cohortType?.toUpperCase() === EnrollmentCohortCohortType.Uhome;
}

export function validateQuestionInput(question: QuestionSetFormQuestionInput) {
  const errors: FormikErrors<AdmissionQuestionInput> = {};
  if (!question.label) {
    errors.label = 'fill in the question prompt';
  }
  if (
    [
      AdmissionQuestionType.Checkbox,
      AdmissionQuestionType.Radio,
      AdmissionQuestionType.Select,
    ].includes(question.type as AdmissionQuestionType) &&
    question.options?.some((option) => !option)
  ) {
    errors.options = 'answer all fields';
  }
  if (question.isInterestFilter && !question.filterLabel) {
    errors.filterLabel = 'provide a filter label';
  }
  return errors;
}

/** *
 * Gets depth of DAG based on rendered SVG
 * @param {string} dagId - the id prop on the target Dag component
 */
export function getDagDepth(dagId: string) {
  const dagSvg = document.getElementById(dagId);
  const nodes = dagSvg?.getElementsByClassName('node');
  const columns = new Set();
  for (let i = 0; i < (nodes?.length || 0); i++) {
    const transform = (nodes || [])[i].getAttribute('transform') || '';
    columns.add(transform.split(',')[0]);
  }
  return columns.size;
}

export const getLPNodeType = (
  programKey: string,
  classroomCatalog: ClassroomCatalog
): LearningPathNodeType => {
  const program = classroomCatalog[programKey];
  return program?.semanticType === ResourceType.Part
    ? LearningPathNodeType.Part
    : LearningPathNodeType.Nanodegree;
};

const programTypeOptions = [
  {
    id: 'nanodegree',
    label: 'Nanodegree',
    value: ResourceType.Degree,
  },
  {
    id: 'course',
    label: 'Course',
    value: ResourceType.Part,
  },
  {
    id: 'foundationalCourse',
    label: 'Foundational Course',
    value: ResourceType.Course,
  },
];

export function getProgramTypesFilter(): IFilter {
  const options = [
    ...programTypeOptions,
    {
      id: 'all',
      label: 'All',
      value: ALL,
    },
  ];
  const programTypeFilter = {
    id: 'programType',
    label: 'Program Type',
    options,
  };
  return programTypeFilter;
}

export function getContentTypeFilter(showLearningPaths: boolean) {
  const options: IOption[] = [
    {
      id: 'nanodegree',
      label: 'Nanodegree',
      value: LearnerInterestProgramType.Nanodegree,
    },
    { id: 'course', label: 'Course', value: LearnerInterestProgramType.Part },
  ];

  if (showLearningPaths) {
    options?.unshift({
      id: 'learningPath',
      label: 'Learning Path',
      value: LearnerInterestProgramType.LearningPath,
    });
  }

  options.push({
    id: 'foundationalCourse',
    label: 'Foundational Course',
    value: LearnerInterestProgramType.Course,
  });

  return {
    id: 'contentType',
    label: 'Content Type',
    multi: true,
    options,
  };
}
