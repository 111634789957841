import { isEmpty, prop } from 'ramda';

import { FlashState } from '@emc/reducer/session/flash';
import { IRootStore } from '@emc/reducer/types';
import { LoadingState } from '@emc/reducer/session/loading';
import { createSelector } from 'reselect';

export const flashSelector = (state: IRootStore) => state.session.flash;

export const maintenanceReasonSelector = createSelector<
  [(state: IRootStore) => FlashState],
  FlashState['maintenanceReason']
>(flashSelector, prop('maintenanceReason'));

export const loadingSelector = (state: IRootStore) => state.session.loading;

export const isLoadingSelector = createSelector<
  [(state: IRootStore) => LoadingState],
  boolean
>(loadingSelector, (loading) => !isEmpty(loading));

export const catalogAccessSelector = (state: IRootStore) =>
  state.session.catalogAccess;
