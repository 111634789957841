import {
  AdmissionQuestionResponseInput,
  AnswerAdmissionQuestionsMutation,
  CreateQuestionSetMutation,
  GetCompanyAdmissionQuestionsQuery,
  GetCompanyNominationQuestionSetQuery,
  GetCompanyQuestionSetQuery,
  GetQuestionSetsQuery,
  LearnerInterest,
  LearnerInterestStatus,
  QuestionSet,
  QuestionSetInput,
  UpdateQuestionSetMutation,
  UpdateQuestionSetMutationVariables,
} from 'app/types/generated/emc';
import { AppThunk, IGqlResponse } from 'app/types/shared';
import { addCompanies, updateCompany } from '../companies';
import { addFlashMessage, flashGraphqlError } from '../flash';
import {
  fetchCompanyGraphLearningPath,
  updateCompanyGraphLearningPath,
} from '../learning-paths/company';
import { fetchProgramByKey, updateProgram } from '../programs';
import {
  getPaginatedItems,
  getQueryString,
} from 'app/utils/graphql/serializer';
import { indexBy, pluck, prop } from 'ramda';
import { startLoading, stopLoading } from '../loading';
import { updateLearnerInterest, updateLearnerInterestById } from '../interest';

import { NormalizedCompany } from 'app/types/companies';
import { QuestionSetsReduxState } from 'app/reducer/data/types';
import { batch } from 'react-redux';
import { cacheCompanyQuestionSet } from '../cache';
import { createAction } from 'app/utils/actions';
import { emcService } from 'app/services';
import { getGraphQLErrorMessage } from 'app/utils/errors';
import { hasViewOnlyPermissionsOrHigherSelector } from 'app/selectors/me';
import { isCacheValid } from 'app/utils/cache/is_valid';
import { loader } from 'graphql.macro';
import { loadingSelector } from 'app/selectors/session';

export function addQuestionSets(questionSets: QuestionSetsReduxState) {
  return createAction('ADD_QUESTION_SETS', questionSets);
}

export function fetchCompanyQuestionSet(companyId: string): AppThunk {
  return (dispatch, getState) => {
    const isLoadingQuestionSet = loadingSelector(getState()).QUESTION_SETS;
    const alreadyFetched = isCacheValid(getState, [
      'cache',
      'companyQuestionSets',
      companyId,
    ]);

    if (isLoadingQuestionSet || alreadyFetched) return;

    dispatch(startLoading('QUESTION_SETS'));
    const isManager = hasViewOnlyPermissionsOrHigherSelector(getState());
    return emcService
      .gql(
        getQueryString(loader('@emc/queries/emc/get-company-question-set.gql')),
        {
          companyId,
          isManager,
        }
      )
      .then((res: IGqlResponse<GetCompanyQuestionSetQuery>) => {
        dispatch(cacheCompanyQuestionSet(companyId));
        const questionSet = res.data.data?.company?.questionSet;
        const questionSetId = questionSet?.id;
        if (questionSetId) {
          batch(() => {
            dispatch(
              addQuestionSets({ [questionSetId]: questionSet as QuestionSet })
            );
            dispatch(
              addCompanies({
                [companyId]: { questionSetId } as NormalizedCompany,
              })
            );
          });
        }
        dispatch(stopLoading('QUESTION_SETS'));
      });
  };
}

export function fetchCompanyAdmissionQuestions(companyId: string): AppThunk {
  return (dispatch) => {
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/get-company-admission-questions.gql')
        ),
        {
          companyId,
        }
      )
      .then((res: IGqlResponse<GetCompanyAdmissionQuestionsQuery>) => {
        const questionSets = getPaginatedItems(
          res.data.data?.questionSets
        ) as QuestionSet[];
        const indexedQuestionSets = indexBy(prop('id'), questionSets);
        batch(() => {
          dispatch(addQuestionSets(indexedQuestionSets));
          dispatch(
            addCompanies({
              [companyId]: {
                allQuestionSetIds: pluck('id')(questionSets),
              } as NormalizedCompany,
            })
          );
        });
      });
  };
}

export function fetchCompanyNominationQuestionSet(companyId: string): AppThunk {
  return (dispatch, getState) => {
    dispatch(startLoading('NOMINATION_QUESTION_SETS'));
    const isManager = hasViewOnlyPermissionsOrHigherSelector(getState());
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/get-company-nomination-question-set.gql')
        ),
        {
          companyId,
          isManager,
        }
      )
      .then((res: IGqlResponse<GetCompanyNominationQuestionSetQuery>) => {
        const nominationQuestionSet =
          res.data.data?.company?.nominationQuestionSet;
        const nominationQuestionSetId = nominationQuestionSet?.id;
        if (nominationQuestionSetId) {
          batch(() => {
            dispatch(
              addQuestionSets({
                [nominationQuestionSetId]: nominationQuestionSet as QuestionSet,
              })
            );
            dispatch(
              addCompanies({
                [companyId]: { nominationQuestionSetId } as NormalizedCompany,
              })
            );
          });
        }
        dispatch(stopLoading('NOMINATION_QUESTION_SETS'));
      });
  };
}

export function createQuestionSet(
  companyId: string,
  questionSet: QuestionSetInput
): AppThunk<Promise<string | undefined>> {
  return (dispatch) => {
    dispatch(startLoading('QUESTION_SETS'));
    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/create-question-set.gql')), {
        companyId,
        questionSet,
        isManager: true,
      })
      .then((res: IGqlResponse<CreateQuestionSetMutation>) => {
        const questionSet = res.data.data?.createQuestionSet;
        const questionSetId = questionSet?.id;
        if (questionSetId) {
          dispatch(
            addQuestionSets({ [questionSetId]: questionSet as QuestionSet })
          );
        }
        if (res.data.errors) {
          dispatch(flashGraphqlError(res.data.errors));
        }
        dispatch(stopLoading('QUESTION_SETS'));
        return questionSetId;
      });
  };
}

export function createCompanyQuestionSet(
  companyId: string,
  questionSet: QuestionSetInput
): AppThunk {
  return (dispatch) => {
    return dispatch(createQuestionSet(companyId, questionSet)).then(
      (questionSetId) => {
        if (questionSetId) {
          dispatch(updateCompany({ id: companyId, questionSetId }));
        }
      }
    );
  };
}

export function updateQuestionSet(
  data: UpdateQuestionSetMutationVariables
): AppThunk<Promise<string | undefined>> {
  return (dispatch) => {
    dispatch(startLoading('QUESTION_SETS'));
    return emcService
      .gql(getQueryString(loader('@emc/queries/emc/update-question-set.gql')), {
        ...data,
        isManager: true,
      })
      .then((res: IGqlResponse<UpdateQuestionSetMutation>) => {
        const questionSet = res.data.data?.updateQuestionSet;
        const questionSetId = questionSet?.id;
        if (questionSetId) {
          dispatch(
            addQuestionSets({ [questionSetId]: questionSet as QuestionSet })
          );
          dispatch(addFlashMessage('success', 'Application updated.'));
        }
        questionSet?.companyPrograms?.forEach((program) => {
          program?.programKey &&
            dispatch(fetchProgramByKey(program.companyId, program.programKey));
        });
        questionSet?.learningPaths?.edges.forEach((lp) => {
          lp?.node?.id && dispatch(fetchCompanyGraphLearningPath(lp?.node?.id));
        });
        if (res.data.errors) {
          dispatch(flashGraphqlError(res.data.errors));
        }
        dispatch(stopLoading('QUESTION_SETS'));
        return questionSetId;
      });
  };
}

export function createCompanyProgramQuestionSet(
  companyId: string,
  questionSet: QuestionSetInput,
  programKey: string
): AppThunk {
  return (dispatch) => {
    return dispatch(createQuestionSet(companyId, questionSet)).then(
      (questionSetId) => {
        if (questionSetId) {
          dispatch(
            updateProgram({ companyId, questionSetId, programKey })
          ).then((isSuccess) => {
            if (isSuccess) {
              dispatch(
                addFlashMessage(
                  'success',
                  `Application form created for Program ${programKey}.`
                )
              );
            }
          });
        }
      }
    );
  };
}

export function createLearningPathQuestionSet(
  companyId: string,
  questionSet: QuestionSetInput,
  learningPathId: string,
  isAvailable: boolean
): AppThunk {
  return (dispatch) => {
    return dispatch(createQuestionSet(companyId, questionSet)).then(
      (questionSetId) => {
        if (questionSetId) {
          dispatch(
            updateCompanyGraphLearningPath(
              learningPathId,
              Boolean(isAvailable),
              undefined,
              undefined,
              questionSetId
            )
          );
        }
      }
    );
  };
}

export function fetchAllQuestionSets(): Promise<QuestionSet[]> {
  return emcService
    .gql(getQueryString(loader('@emc/queries/emc/get-question-sets.gql')), {
      isManager: true,
    })
    .then((res: IGqlResponse<GetQuestionSetsQuery>) => {
      return getPaginatedItems(res.data.data.questionSets) as QuestionSet[];
    });
}

export function answerAdmissionsQuestions(
  learnerInterestId: string,
  responses: AdmissionQuestionResponseInput[]
): AppThunk<Promise<{ ok: boolean; error: string }>> {
  return (dispatch) => {
    dispatch(startLoading('QUESTION_SETS'));
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/answer-admissions-questions.gql')
        ),
        { learnerInterestId, responses }
      )
      .then((res: IGqlResponse<AnswerAdmissionQuestionsMutation>) => {
        if (res.data.errors) {
          dispatch(stopLoading('QUESTION_SETS'));
          return { ok: false, error: getGraphQLErrorMessage(res.data.errors) };
        }
        const questionResponse = (res.data?.data?.answerAdmissionQuestions ||
          [])[0];
        const learnerInterest = questionResponse?.learnerInterest;
        const learnerId = learnerInterest?.learnerId;
        learnerId &&
          dispatch(
            updateLearnerInterest(
              learnerId,
              learnerInterest as Partial<LearnerInterest>
            )
          );
        if (
          learnerInterest?.status?.toLowerCase() ===
          LearnerInterestStatus.Interested.toLowerCase()
        ) {
          dispatch(
            updateLearnerInterestById({
              id: learnerInterest.id,
              status: LearnerInterestStatus.Draft,
            })
          );
        }
        dispatch(stopLoading('QUESTION_SETS'));
        return { ok: true, error: '' };
      })
      .catch((err) => ({ ok: false, error: err }));
  };
}
