import React, { useCallback, useMemo, useState } from 'react';
import {
  emcUserLearnersSelector,
  isUdacityStaffSelector,
} from 'app/selectors/me';
import { sortBy, values } from 'ramda';

import { Heading } from '@udacity/veritas-components';
import { IOption } from 'app/types/options';
import { Modal } from '@emc/elements/modal';
import { Select } from '@emc/elements/select';
import { UdacityLogo } from 'app/icons/udacity-logo';
import { UnstyledButton } from '@emc/elements/unstyled-button';
import { companiesSelector } from 'app/selectors/companies/companies';
import { isMobileWidth } from 'app/utils/component';
import { parentCompaniesSelector } from 'app/selectors/parent-companies';
import styles from './styles.module.scss';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const CompanySwitcher: React.FC<{ isExpanded: boolean }> = ({
  isExpanded,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const companies = useSelector(companiesSelector);
  const parentCompanies = useSelector(parentCompaniesSelector);
  const userLearners = useSelector(emcUserLearnersSelector);
  const isUdacityStaff = useSelector(isUdacityStaffSelector);
  const isMobile = isMobileWidth();
  const history = useHistory();
  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleCompanyChange = useCallback(
    (option: IOption<string>) => {
      history.push({ pathname: option.value });
      handleModalClose();
    },
    [handleModalClose, history]
  );

  const companyOptions = useMemo(() => {
    const unsortedOptions = isUdacityStaff
      ? values(companies).map((company) => ({
          value: `/admin/companies/${company.id}`,
          label: company.name,
        }))
      : userLearners.map((learner) => {
          const routeTo = learner.roles?.length
            ? `/c/${learner.company?.slug}/dashboard`
            : `/c/${learner.company?.slug}/me`;
          return {
            value: routeTo,
            label: learner.company?.name || '',
          };
        });
    return sortBy((option) => option.label?.toLowerCase(), unsortedOptions);
  }, [companies, userLearners, isUdacityStaff]);

  const parentCompanyOptions = sortBy(
    (option) => option.label?.toLowerCase(),
    values(parentCompanies).map((parentCompany) => ({
      value: `/pc/${parentCompany.slug}/home`,
      label: parentCompany.name,
    }))
  );

  const hasMultipleCompanyOptions = companyOptions.length > 1;

  const handleLogoClick = useCallback(() => {
    if (hasMultipleCompanyOptions) {
      setModalOpen(true);
    }
  }, [hasMultipleCompanyOptions]);
  const logoProps = useMemo(() => {
    if (isMobile) {
      return {
        width: 35,
        height: 35,
        viewBox: '0 0 180 180',
      };
    }
    if (isExpanded) {
      return {
        showLabel: true,
        width: 300,
        height: 35,
      };
    }
    return {
      width: 40,
      height: 35,
      viewBox: '0 0 100 180',
    };
  }, [isExpanded, isMobile]);

  return (
    <>
      <UnstyledButton onClick={handleLogoClick} testId="company-switcher">
        <div className={styles.logoContainer}>
          <UdacityLogo {...logoProps} inverted />
        </div>
      </UnstyledButton>
      {hasMultipleCompanyOptions && (
        <Modal isOpen={isModalOpen} onClose={handleModalClose}>
          {isUdacityStaff ? (
            <>
              <Heading size="h3">
                {t('emc.switchCompaniesAndParentCompanies')}
              </Heading>
              <div className={styles.dropdownContainer}>
                <div className={styles.switcherDropdown}>
                  <Select
                    labelWeight="regular"
                    id="company-selector"
                    label={t('emc.companies')}
                    onChange={handleCompanyChange}
                    options={companyOptions}
                    placeholder={t('emc.selectACompany')}
                  />
                </div>
                <span className={styles.or}>OR</span>
                <div className={styles.switcherDropdown}>
                  <Select
                    labelWeight="regular"
                    id="parent-company-selector"
                    label={t('emc.parentCompanies')}
                    onChange={handleCompanyChange}
                    options={parentCompanyOptions}
                    placeholder={t('emc.selectAParentCompany')}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <Heading size="h3">{t('emc.switchCompanies')}</Heading>
              <Select
                hiddenLabel
                id="company-selector"
                label={t('emc.selectACompany')}
                onChange={handleCompanyChange}
                options={companyOptions}
                placeholder={t('common.select')}
              />
            </>
          )}
        </Modal>
      )}
    </>
  );
};
