import React, { useEffect } from 'react';
import { Route, RouteProps, useHistory, useRouteMatch } from 'react-router-dom';
import {
  authenticateCurrentUser,
  fetchCatalogAccessStatus,
  fetchEMCLearnersForCurrentUser,
  identifyCurrentUser,
} from 'app/actions/me';

import { FullPageLoading } from '@emc/elements/loading/full-page';
import { Maintenance } from '@emc/error-views/maintenance';
import { getQueryParams } from 'app/utils/router/query-params';
import { hydrateCompanyCatalogPage } from 'app/actions/catalog';
import { isAuthenticatedSelector } from 'app/selectors/me';
import { maintenanceReasonSelector } from 'app/selectors/session';
import { useAppDispatch } from 'app/hooks/use-app-dispatch';
import { useSelector } from 'react-redux';

export const AuthenticatedRoute: React.FC<RouteProps> = (props) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const maintenanceError = useSelector(maintenanceReasonSelector);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const catalogMatch = useRouteMatch<{ companySlug: string; token?: string }>(
    '/c/:companySlug/catalog/:token?'
  );
  const catalogCompanySlug = catalogMatch?.params.companySlug;
  const catalogToken = catalogMatch?.params.token || getQueryParams()?.token;
  const isUnauthorizedCatalogPage = catalogToken === 'unauthorized';
  const linkMatch = useRouteMatch('/link/:token');
  const isLinkingNewAccount = Boolean(linkMatch);
  const isCatalogPage = Boolean(catalogMatch);
  const shouldAuthUser = !isAuthenticated && !isCatalogPage;

  useEffect(() => {
    if (shouldAuthUser) {
      authenticateCurrentUser(isLinkingNewAccount);
    }
  }, [shouldAuthUser, isLinkingNewAccount, dispatch]);

  useEffect(() => {
    if (catalogCompanySlug) {
      if (!isUnauthorizedCatalogPage) {
        dispatch(
          fetchCatalogAccessStatus(catalogToken || '', catalogCompanySlug)
        ).then(({ hasAccess, requestId }) => {
          if (hasAccess) {
            dispatch(hydrateCompanyCatalogPage(catalogCompanySlug));
            if (isAuthenticated) {
              identifyCurrentUser();
            }
          } else {
            history.push(
              `/c/${catalogCompanySlug}/catalog/unauthorized/${requestId}`
            );
          }
        });
      }
    } else {
      dispatch(fetchEMCLearnersForCurrentUser());
    }
  }, [
    dispatch,
    isAuthenticated,
    catalogToken,
    isLinkingNewAccount,
    catalogCompanySlug,
    history,
    isUnauthorizedCatalogPage,
  ]);

  if (maintenanceError) {
    return <Maintenance error={maintenanceError} />;
  }

  if (isAuthenticated || catalogMatch) {
    return <Route {...props} />;
  }

  return <FullPageLoading />;
};
