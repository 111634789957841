import { omit, test } from 'ramda';

import { history } from '@emc/history';
import queryString from 'query-string';
import reduce from 'lodash/reduce';

export function getQueryParams(): {
  [key: string]: string;
} {
  const query = window.location.search;
  return queryString.parse(query) as {
    [key: string]: string;
  };
}

/*
  history.replace calls the @@router/LOCATION_CHANGE action
  which updates state.router.location.search
*/

export function updateQueryParam(
  property: string,
  value: string | number | boolean
) {
  const parsedQuery = getQueryParams();
  const formattedQuery = { ...parsedQuery, [property]: value };
  const stringifiedQuery = queryString.stringify(formattedQuery);

  history.replace({
    pathname: window.location.pathname,
    search: stringifiedQuery,
  });
}

export function removeQueryParam(property: string) {
  const parsedQuery = getQueryParams();
  const formattedQuery = omit([property])(parsedQuery);
  const stringifiedQuery = queryString.stringify(formattedQuery);

  history.replace({
    pathname: window.location.pathname,
    search: stringifiedQuery,
  });
}

export function removeAllQueryParams() {
  history.replace({
    pathname: window.location.pathname,
    search: '',
  });
}

export function resetAllActiveIndexQueryParams() {
  const parsedQuery = getQueryParams();
  const formattedQuery = reduce(
    parsedQuery,
    (formattedQueryParams, value, label) => {
      if (test(/activeIndex/, label)) {
        return formattedQueryParams;
      }
      formattedQueryParams[label] = value;
      return formattedQueryParams;
    },
    {}
  );

  const stringifiedQuery = queryString.stringify(formattedQuery);

  history.replace({
    pathname: window.location.pathname,
    search: stringifiedQuery,
  });
}

export function persistUtmParams(path: string) {
  const windowSearchParams = new URLSearchParams(window.location.search);
  const resultingParams = new URLSearchParams();
  windowSearchParams.forEach((value, key) => {
    if (key.includes('utm_')) {
      resultingParams.append(key, value);
    }
  });
  const search = resultingParams.toString();
  return search ? `${path}?${search}` : path;
}
