import { IconClipboard, IconGauge, IconTasklist } from '@udacity/veritas-icons';

import { NavItem } from './nav-item';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ParentCompanyNavigation: React.FC<{
  isExpanded: boolean;
  slug: string;
  handleNavItemClicked: VoidFunction;
}> = ({ isExpanded, slug, handleNavItemClicked }) => {
  const { t } = useTranslation();

  return (
    <>
      <NavItem
        Icon={IconGauge}
        to={`/pc/${slug}/home`}
        label={t('common.home')}
        isExpanded={isExpanded}
        onClick={handleNavItemClicked}
      />
      <NavItem
        Icon={IconClipboard}
        to={`/pc/${slug}/user-permissions`}
        label={t('common.userPermissions')}
        isExpanded={isExpanded}
        onClick={handleNavItemClicked}
      />
      <NavItem
        Icon={IconTasklist}
        to={`/pc/${slug}/contracts`}
        label={t('common.contracts')}
        isExpanded={isExpanded}
        onClick={handleNavItemClicked}
      />
    </>
  );
};
