import { CatalogLayoutSetting } from 'app/types/settings';
import { CompanySettingObject } from 'app/types/generated/emc';
import { getCatalogLayout } from './catalog-layout';

export enum KnownSettings {
  allowReferrals = 'allow_referrals',
  allowSubjectSelfReport = 'allow_subject_self_report',
  autoEnrollEnabled = 'auto_enroll_enabled',
  catalogLayout = 'catalog_layout',
  catalogNominations = 'catalog_nominations',
  challengeEnabled = 'challenge_enabled',
  clientUid = 'client_uid',
  daysInactiveMonitor = 'days_inactive_monitor',
  daysInactiveOffTrack = 'days_inactive_off_track',
  isEmc2BetaTester = 'is_emc_2_beta_tester',
  publicCatalog = 'public_catalog',
  s3InterestCsvDownload = 's3_interest_csv_download',
  serviceModelOverride = 'service_model_override',
  showsCatalog = 'shows_catalog',
  showsExplorer = 'shows_explorer',
  showsNdKey = 'shows_nd_key',
  showsReports = 'shows_reports',
  showsTalentPlans = 'shows_talent_plans',
  uconnectEnabled = 'uconnect_enabled',
}

function getSettingValue(
  settings: CompanySettingObject[],
  settingName: KnownSettings
) {
  const relevantSetting = settings?.find(
    (setting) => setting.name === settingName
  );
  return relevantSetting?.value;
}

function isSettingEnabled(
  settings: CompanySettingObject[],
  settingName: KnownSettings
) {
  return getSettingValue(settings, settingName) === 'true';
}

export function companyHasClientUid(
  companySettings: CompanySettingObject[] = []
): boolean {
  return isSettingEnabled(companySettings, KnownSettings.clientUid);
}

export function companyIsEmc2BetaTester(
  companySettings: CompanySettingObject[] = []
): boolean {
  return isSettingEnabled(companySettings, KnownSettings.isEmc2BetaTester);
}

export function companyShowsCatalog(
  companySettings: CompanySettingObject[] = []
): boolean {
  return isSettingEnabled(companySettings, KnownSettings.showsCatalog);
}

export function companyShowsProgramKey(
  companySettings: CompanySettingObject[] = []
): boolean {
  return isSettingEnabled(companySettings, KnownSettings.showsNdKey);
}

export function companyShowsReports(
  companySettings: CompanySettingObject[] = []
): boolean {
  return isSettingEnabled(companySettings, KnownSettings.showsReports);
}

export function companyShowsExplorer(
  companySettings: CompanySettingObject[] = []
): boolean {
  return isSettingEnabled(companySettings, KnownSettings.showsExplorer);
}

export function companyDaysInactiveMonitor(
  companySettings: CompanySettingObject[] = []
): number {
  const monitorValue = getSettingValue(
    companySettings,
    KnownSettings.daysInactiveMonitor
  );
  return Number(monitorValue || 7);
}

export function companyDaysInactiveOffTrack(
  companySettings: CompanySettingObject[] = []
): number {
  const offTrackValue = getSettingValue(
    companySettings,
    KnownSettings.daysInactiveOffTrack
  );
  return Number(offTrackValue || 14);
}

export function companyIsChallengeEnabled(
  companySettings: CompanySettingObject[] = []
): boolean {
  return isSettingEnabled(companySettings, KnownSettings.challengeEnabled);
}

export function companyIsUconnectEnabled(
  companySettings: CompanySettingObject[] = []
): boolean {
  return isSettingEnabled(companySettings, KnownSettings.uconnectEnabled);
}

export function companyHasPublicCatalog(
  companySettings: CompanySettingObject[]
): boolean {
  return isSettingEnabled(companySettings, KnownSettings.publicCatalog);
}
export function companyAllowsNominations(
  companySettings: CompanySettingObject[]
): boolean {
  return isSettingEnabled(companySettings, KnownSettings.catalogNominations);
}

export function getCatalogLayoutSetting(
  companySettings: CompanySettingObject[] = []
): CatalogLayoutSetting {
  const value = getSettingValue(companySettings, KnownSettings.catalogLayout);
  return getCatalogLayout(value || '');
}

export function companyIsAutoEnrollEnabled(
  companySettings: CompanySettingObject[] = []
): boolean {
  return isSettingEnabled(companySettings, KnownSettings.autoEnrollEnabled);
}

export function companyAllowsReferrals(
  companySettings: CompanySettingObject[] = []
): boolean {
  return isSettingEnabled(companySettings, KnownSettings.allowReferrals);
}

export function companyAllowsSubjectSelfReport(
  companySettings: CompanySettingObject[] = []
): boolean {
  return isSettingEnabled(
    companySettings,
    KnownSettings.allowSubjectSelfReport
  );
}

export function companyShowsTalentPlans(
  companySettings: CompanySettingObject[] = []
): boolean {
  return isSettingEnabled(companySettings, KnownSettings.showsTalentPlans);
}

export function companyUsesS3Download(
  companySettings: CompanySettingObject[] = []
): boolean {
  return isSettingEnabled(companySettings, KnownSettings.s3InterestCsvDownload);
}
