import {
  AuthenticatedRoute,
  IndividualLearnerRoute,
  LearnerSelfServeRoute,
} from '@emc/auth';
import { NotFound, Unauthorized } from '@emc/error-views';
import React, { Suspense, lazy } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { store as emcStore, persistor } from '@emc/store';

import { BackgroundLoading } from 'app/elements/loading/background';
import { ErrorBoundary } from './error-boundary';
import { Flash } from './flash';
import { FullPageLoading } from '@emc/elements/loading';
import { LearnerLearningPaths } from './company/lazy-loads';
import { ParentCompanyRoutes } from './parent-company';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import { RedirectRoute } from '@emc/auth';
import RouteContainer from './container';
import { history } from '@emc/history';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const AdminRoutes = lazy(() => import('./admin'));
const CompanyRoutes = lazy(() => import('./company'));

export const Routes = ({ store = emcStore }) => {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <ErrorBoundary>
            <Suspense fallback={<FullPageLoading />}>
              <Switch>
                {/* Paths that don't want emc navigation */}
                <IndividualLearnerRoute
                  component={LearnerLearningPaths}
                  exact
                  path="/my-learning-paths"
                />

                {/* Navigation container */}
                <RouteContainer>
                  <Flash />
                  <BackgroundLoading />
                  <AuthenticatedRoute
                    path="/"
                    render={(props) => {
                      const {
                        location: { pathname },
                      } = props;

                      if (pathname.includes('/admin')) {
                        return <Route component={AdminRoutes} path="/admin" />;
                      }

                      if (pathname.includes('/c/')) {
                        return <Route component={CompanyRoutes} path="/c" />;
                      }

                      if (pathname.includes('/pc/')) {
                        return (
                          <Route component={ParentCompanyRoutes} path="/pc" />
                        );
                      }

                      return (
                        <div className={styles.base}>
                          <Switch>
                            <RedirectRoute
                              component={FullPageLoading}
                              exact
                              path="/"
                            />
                            <Route
                              component={Unauthorized}
                              exact
                              path="/unauthorized"
                            />
                            <Route
                              component={FullPageLoading}
                              exact
                              path="/logout"
                            />
                            <Route
                              component={LearnerSelfServeRoute}
                              exact
                              path="/link/:token"
                            />
                            <Route component={NotFound} path="**" />
                          </Switch>
                        </div>
                      );
                    }}
                  />
                </RouteContainer>
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </Router>
      </PersistGate>
    </Provider>
  );
};
