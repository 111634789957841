import { RouteComponentProps } from 'react-router-dom';
import { hasViewOnlyPermissionsOrHigherSelector } from 'app/selectors/me';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const ParentCompanyLanding: React.FC<
  RouteComponentProps<{ companySlug: string }>
> = ({ history, match }) => {
  const companySlug = match.params.companySlug;
  const isManager = useSelector(hasViewOnlyPermissionsOrHigherSelector);

  const redirectionPath = isManager
    ? `/pc/${companySlug}/home`
    : '/unauthorized';

  useEffect(() => {
    history.replace({ pathname: redirectionPath });
  }, [history, redirectionPath]);

  return null;
};
