import { IconClose } from '@udacity/veritas-icons';
import { NoScroll } from '../../no-scroll';
import React from 'react';
import ReactModal from 'react-modal';
import { UnstyledButton } from '../../unstyled-button';
import styles from './styles.module.scss';

type Props = {
  isOpen: boolean;
  onClose(): void;
  maxWidth?: number;
  removePadding?: boolean;
  innerCloseIcon?: boolean;
};

export const Modal: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  maxWidth = 650,
  removePadding,
  innerCloseIcon,
}) => {
  return (
    <ReactModal
      appElement={document.getElementById('app') || undefined}
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(46, 60, 73, 0.7)',
          bottom: 0,
          left: 0,
          overflowY: 'scroll',
          paddingBottom: '3rem',
          position: 'fixed',
          right: 0,
          top: 0,
          zIndex: 1000000,
        },
        content: {
          border: 'none',
          borderRadius: '6px',
          bottom: 'auto',
          height: 'auto',
          left: '50%',
          maxWidth,
          outline: 'none',
          overflow: 'visible',
          right: 'auto',
          transform: 'translateX(-50%)',
          width: '80%',
          padding: removePadding ? '0' : '1rem',
        },
      }}
    >
      {innerCloseIcon ? (
        <UnstyledButton
          className={styles['close-inner']}
          onClick={onClose}
          data-testid="modalCloseButton"
        >
          <IconClose color="black" />
        </UnstyledButton>
      ) : (
        <UnstyledButton
          className={styles['close-outer']}
          onClick={onClose}
          data-testid="modalCloseButton"
        >
          <IconClose color="white" />
        </UnstyledButton>
      )}
      <div
        className={
          removePadding
            ? styles['modal-content-condensed']
            : styles['modal-content']
        }
      >
        {children}
      </div>
      <NoScroll shouldPreventScroll={isOpen} />
    </ReactModal>
  );
};
