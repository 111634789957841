import { AppThunk, IGqlResponse } from 'app/types/shared';
import {
  Company,
  CreateLearnerInterestMutation,
  CreateLearnerInterestMutationVariables,
  DeleteLearnerInterestMutation,
  GenerateLearnerInterestCsvMutation,
  GetBatchProgressQuery,
  GetCompanyLearnerInterestsQuery,
  LearnerInterest,
  LearnerInterestBulkSelectionFilterField,
  LearnerInterestProgramType,
  LearnerInterestStatus,
  ProcessLearnerInterestsMutation,
  PublicMutationProcessLearnerInterestsArgs,
  QuestionSet,
  ResourceType,
  UpdateLearnerInterestMutation,
  UpdateLearnerInterestMutationVariables,
} from 'app/types/generated/emc';
import {
  CompanyNormalizedEntities,
  companyNormSchema,
} from 'app/utils/normalize/company';
import { PaginationProps, getPaginationData } from 'app/selectors/pagination';
import { PollingKeys, updatePollingProperty } from './polling';
import { addFlashMessage, addServerError, flashGraphqlError } from './flash';
import {
  getLearnerInterestBulkFilters,
  getQuestionsAnsweredFilters,
} from 'app/utils/format/batch-filter';
import {
  getPaginatedItems,
  getQueryString,
} from 'app/utils/graphql/serializer';
import { startLoading, stopLoading } from './loading';

import { CsvColumnQuery } from 'app/services/emc-service';
import { LearnerInterestReduxState } from 'app/reducer/data/types';
import { LoadingType } from '@emc/loading-types';
import { NONE_OPTION } from 'app/types/options';
import { PaginationTypes } from 'app/reducer/session/pagination';
import { addCompanies } from './companies';
import { addGroups } from './groups';
import { addLearners } from './learners/fetch';
import { batch } from 'react-redux';
import { classroomCatalogSelector } from 'app/selectors/catalog';
import { companyPollingSelector } from 'app/selectors/companies/companies';
import { createAction } from 'app/utils/actions';
import { dateFormatter } from 'app/utils/dates/format';
import { downloadCsvString } from 'app/utils/component';
import { emcService } from '@emc/services';
import { filterOutAllTimeQueryParam } from 'app/utils/dates/defaults';
import { getGraphQLErrorMessage } from 'app/utils/errors';
import { getQueryParams } from 'app/utils/router/query-params';
import { hasCompanyAdminPermissionsOrHigherSelector } from 'app/selectors/me';
import { loader } from 'graphql.macro';
import { normalize } from 'normalizr';
import { poll } from './util';
import { updatePagination } from './reports';

// Sync

export function addCompanyLearnerInterests(
  interests: LearnerInterestReduxState
) {
  return createAction('ADD_COMPANY_LEARNER_INTERESTS', interests);
}

export function addLearnerInterest(interest: LearnerInterest) {
  return createAction('ADD_LEARNER_INTEREST', interest);
}

export function removeLearnerInterest(learnerId: string, interestId: string) {
  return createAction('REMOVE_LEARNER_INTEREST', { learnerId, interestId });
}

export function updateLearnerInterest(
  learnerId: string,
  learnerInterest: Partial<LearnerInterest>
) {
  return createAction('UPDATE_LEARNER_INTEREST', {
    learnerId,
    learnerInterest,
  });
}

export function setLearnerInterests(
  interests: LearnerInterest[],
  learnerId: string
) {
  return createAction('SET_LEARNER_INTERESTS', { interests, learnerId });
}

// Async

export const interestPaginationProps: PaginationProps = {
  tableName: PaginationTypes.interestList,
  defaultSizePerPage: 10,
};

export function fetchCompanyLearnerInterests(companyId: string): AppThunk {
  const paginationProps = interestPaginationProps;
  return (dispatch, getState) => {
    dispatch(startLoading('INTEREST'));
    const paginationData = getPaginationData(paginationProps);
    const selectionFilters = getLearnerInterestBulkFilters();
    const questionsAnswered = getQuestionsAnsweredFilters();
    const isStaffOrAdmin = hasCompanyAdminPermissionsOrHigherSelector(
      getState()
    );
    const nominationTypes = getQueryParams().nominationType?.split(',');
    const programTypes = getQueryParams().contentType?.split(',');
    const contracted = getQueryParams().programAvailability;
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/get-company-learner-interests.gql')
        ),
        {
          companyId,
          ...paginationData,
          selectionFilters,
          forCsv: false,
          includeHistory: isStaffOrAdmin,
          nominationTypes:
            nominationTypes && nominationTypes.find((x) => x === NONE_OPTION)
              ? []
              : nominationTypes,
          contracted: contracted ? contracted === 'true' : undefined,
          programTypes,
          questionsAnswered,
        }
      )
      .then((res: IGqlResponse<GetCompanyLearnerInterestsQuery>) => {
        const normalizedData = normalize<Company, CompanyNormalizedEntities>(
          res.data.data?.company,
          companyNormSchema
        );
        const companies = normalizedData.entities.companies || {};
        const company = companies[companyId];
        const interestIds = getPaginatedItems(
          company.paginatedLearnerInterests
        );
        batch(() => {
          dispatch(
            updatePagination(
              paginationProps.tableName,
              company?.paginatedLearnerInterests?.totalCount || 0,
              interestIds
            )
          );
          dispatch(addCompanies(companies));
          dispatch(addLearners(normalizedData.entities.learners || {}));
          dispatch(addGroups(normalizedData.entities.groups || {}));
          dispatch(
            addCompanyLearnerInterests(
              normalizedData.entities.learnerInterests || {}
            )
          );
        });
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading('INTEREST')));
  };
}

export function createLearnerInterest({
  learnerId,
  companyId,
  programKey,
  programType,
  status,
  customData,
  companyGraphLearningPathId,
  reapply = false,
}: {
  learnerId: string;
  companyId: string;
  programKey?: string;
  programType?: ResourceType;
  status?: LearnerInterestStatus;
  customData?: string;
  companyGraphLearningPathId?: string;
  reapply?: boolean;
}): AppThunk<Promise<string | undefined>> {
  return (dispatch, getState) => {
    const catalog = classroomCatalogSelector(getState());
    programType = programType || catalog[programKey || '']?.semanticType;
    dispatch(startLoading('LEARNER_INTEREST'));
    const data: CreateLearnerInterestMutationVariables = {
      learnerId,
      companyId,
      programKey,
      companyGraphLearningPathId,
      customData,
      status,
      isManager: false,
      programType,
      reapply,
    };
    return emcService
      .gql(
        getQueryString(loader('@emc/queries/emc/create-learner-interest.gql')),
        data
      )
      .then((res: IGqlResponse<CreateLearnerInterestMutation>) => {
        const newInterest = res.data?.data?.createLearnerInterest;
        if (newInterest) {
          dispatch(addLearnerInterest(newInterest as LearnerInterest));
        } else {
          throw new Error(
            res.data?.errors?.[0].message ||
              'Something went wrong creating your learner interest'
          );
        }
        dispatch(stopLoading('LEARNER_INTEREST'));
        return newInterest?.id;
      })
      .catch((error) => {
        dispatch(addServerError(error));
        dispatch(stopLoading('LEARNER_INTEREST'));
        return '';
      });
  };
}

export function deleteLearnerInterest(interest: LearnerInterest): AppThunk {
  return (dispatch) => {
    const {
      learnerId,
      programKey,
      programType,
      id: interestId,
      companyGraphLearningPathId,
    } = interest;
    const loadingType = makeInterestLoadingState(programKey as string);
    dispatch(startLoading(loadingType as LoadingType));
    return emcService
      .gql(
        getQueryString(loader('@emc/queries/emc/delete-learner-interest.gql')),
        {
          learnerId,
          programKey,
          programType,
          companyGraphLearningPathId,
        }
      )
      .then((res: IGqlResponse<DeleteLearnerInterestMutation>) => {
        const success = res.data?.data?.deleteLearnerInterest?.ok;
        if (res.data.errors) {
          dispatch(flashGraphqlError(res.data.errors));
        }
        if (success) {
          dispatch(removeLearnerInterest(learnerId, interestId));
        }
      })
      .catch((error) => dispatch(addServerError(error)))
      .then(() => dispatch(stopLoading(loadingType as LoadingType)));
  };
}

export function updateLearnerInterestById(
  variables: UpdateLearnerInterestMutationVariables
): AppThunk<Promise<boolean>> {
  return (dispatch) => {
    return emcService
      .gql(
        getQueryString(loader('@emc/queries/emc/update-learner-interest.gql')),
        variables
      )
      .then((res: IGqlResponse<UpdateLearnerInterestMutation>) => {
        if (res.data.errors) {
          dispatch(flashGraphqlError(res.data.errors));
          return false;
        }
        return true;
      })
      .catch((err) => {
        return false;
      });
  };
}

// Util

export function makeInterestLoadingState(programKey: string) {
  return `INTEREST_${programKey}`;
}

function pollForInterestCsvProgress(
  companyId: string,
  batchId: string
): AppThunk<Promise<boolean>> {
  return (dispatch, getState) => {
    const key = PollingKeys.learnerInterestCSV;
    const state = getState();

    const isPolling = companyPollingSelector(state)[key];

    if (isPolling) {
      return Promise.resolve(false);
    }

    dispatch(updatePollingProperty(companyId, key, true));

    return poll(
      () => {
        return emcService
          .gql(
            getQueryString(loader('@emc/queries/emc/get-batch-progress.gql')),
            {
              batchId,
            }
          )
          .then((progress: IGqlResponse<GetBatchProgressQuery>) => {
            const { succeeded, total } =
              progress.data.data.progressSummary || {};
            const isComplete = succeeded === total;
            if (isComplete) {
              dispatch(updatePollingProperty(companyId, key, false));
            }
            return isComplete;
          })
          .catch((err) => {
            dispatch(addServerError(err));
            return false;
          });
      },
      undefined,
      500
    );
  };
}

export function generateLearnerInterestCsv(
  companyId: string,
  companySlug: string
): AppThunk {
  return (dispatch) => {
    dispatch(startLoading('LEARNER_INTEREST_CSV_DOWNLOAD'));
    const queryParams = getQueryParams();
    const {
      assessmentScoreLower,
      assessmentScoreUpper,
      companyGraphLearningPathId,
      eligibility,
      programKey,
      programType,
      contentType,
    } = queryParams;
    const status = filterOutAllTimeQueryParam(
      (queryParams.status as string) || LearnerInterestStatus.Open
    );
    return emcService
      .gql(
        getQueryString(
          loader('@emc/queries/emc/generate-learner-interest-csv.gql')
        ),
        {
          companyId,
          assessmentScoreLower,
          assessmentScoreUpper,
          companyGraphLearningPathId,
          eligibility,
          programKey,
          programType,
          status,
          contentTypes: contentType?.split(','),
        }
      )
      .then((res: IGqlResponse<GenerateLearnerInterestCsvMutation>) => {
        const { batchId, s3ObjectKey } =
          res.data.data?.generateLearnerInterestCsv || {};
        dispatch(pollForInterestCsvProgress(companyId, batchId || ''))
          .then((ok) => {
            if (ok && s3ObjectKey) {
              return emcService.fetch(`/export/${companyId}/interest-list`, {
                s3_object_key: s3ObjectKey,
              });
            }
            throw new Error('Error Downloading CSV');
          })
          .then((res: { data: string }) => {
            const date = dateFormatter.numeric(new Date());
            downloadCsvString(
              res.data,
              `${companySlug}-learner-interests-${date}.csv`
            );
            dispatch(stopLoading('LEARNER_INTEREST_CSV_DOWNLOAD'));
          })
          .catch((err) => {
            dispatch(addFlashMessage('error', err.message));
            dispatch(stopLoading('LEARNER_INTEREST_CSV_DOWNLOAD'));
          });
      });
  };
}

export function downloadLearnerInterestListCsv(
  companyId: string,
  companySlug: string,
  exampleInterest: LearnerInterest | undefined,
  shouldShowNominations: boolean,
  companyQuestionSet?: QuestionSet
): AppThunk {
  const selectionFilters = getLearnerInterestBulkFilters();
  const queryParams = getQueryParams();
  const search = queryParams.search || '';
  const questionsAnswered = getQuestionsAnsweredFilters();
  const nominationTypes = getQueryParams().nominationType?.split(',');
  const programTypes = getQueryParams().contentType?.split(',');
  const programFilter = selectionFilters.find((filter) => {
    return (
      filter.field ===
        LearnerInterestBulkSelectionFilterField.CompanyGraphLearningPathId ||
      filter.field === LearnerInterestBulkSelectionFilterField.ProgramKey
    );
  });
  const shouldIncludeLpAndProgramInterests =
    (programTypes?.includes(LearnerInterestProgramType.LearningPath) &&
      programTypes?.length > 1) ||
    (!programTypes && !programFilter);
  let programType = programTypes?.[0];
  if (!programType && programFilter) {
    // infer programType if lp/program filter is active
    programType =
      programFilter.field ===
      LearnerInterestBulkSelectionFilterField.CompanyGraphLearningPathId
        ? LearnerInterestProgramType.LearningPath
        : LearnerInterestProgramType.Nanodegree;
  }
  const assessmentSubjects =
    exampleInterest?.assessmentResult?.evaluationObject?.subjects;
  let assessmentSubjectColumnQueries: CsvColumnQuery[] = [];
  const subjectIds: string[] = [];
  if (assessmentSubjects && programFilter?.value) {
    assessmentSubjectColumnQueries = assessmentSubjects.map<CsvColumnQuery>(
      (subject, idx) => {
        subjectIds.push(subject.subjectId as string);
        return {
          title: (subject.name || subject.subjectId) as string,
          lookup: ['node', 'subjectScoresForIds', idx, 'mastery'],
        };
      }
    );
  }

  const assessmentOutcomeColumnQueries = makeColumnsForInterestType(
    programType,
    shouldIncludeLpAndProgramInterests,
    [
      {
        title: 'Assessment Status',
        lookup: ['node', 'assessmentReadiness'],
      },
      {
        title: 'Eligible Programs',
        lookup: ['node', 'assessmentEligiblePrograms'],
      },
    ],
    []
  );

  const companyQuestions = companyQuestionSet?.admissionQuestions?.filter(
    (question) => question?.type !== 'section'
  );
  const companyQuestionSetColumnQueries: CsvColumnQuery[] = (
    companyQuestions || []
  ).map<CsvColumnQuery>((question) => {
    return {
      title: question?.label as string,
      lookup: [
        'node',
        'companyQuestionSetResponses',
        question?.order as number,
        'response',
      ],
    };
  });

  // Include specific question set response only when the specific LP/ND is filtered
  const specificQuestions =
    exampleInterest?.specificQuestionSet?.admissionQuestions?.filter(
      (question) => question?.type !== 'section'
    ) || [];
  let specificQuestionSetColumnQueries: CsvColumnQuery[] = [];
  if (specificQuestions?.length && programFilter?.value) {
    specificQuestionSetColumnQueries =
      specificQuestions?.map<CsvColumnQuery>((question) => {
        return {
          title: question?.label as string,
          lookup: [
            'node',
            'specificQuestionSetResponses',
            question?.order as number,
            'response',
          ],
        };
      }) || [];
  }

  const contentInfo = [
    {
      title: 'Content Title',
      lookup: ['node', 'contentTitle'],
    },
    {
      title: 'Content Type',
      lookup: ['node', 'contentType'],
    },
  ];

  const nominationsCol = shouldShowNominations
    ? [
        {
          title: 'Nominator Email',
          lookup: ['node', 'nominations', 0, 'nominatorUser', 'email'],
        },
        {
          title: 'Nominator First Name',
          lookup: ['node', 'nominations', 0, 'nominatorUser', 'firstName'],
        },
        {
          title: 'Nominator Last Name',
          lookup: ['node', 'nominations', 0, 'nominatorUser', 'lastName'],
        },
      ]
    : [];

  return (dispatch) => {
    dispatch(startLoading('LEARNER_INTEREST_CSV_DOWNLOAD'));

    return emcService
      .gqlCsv(
        getQueryString(
          loader('@emc/queries/emc/get-company-learner-interests.gql')
        ),
        {
          companyId,
          selectionFilters,
          subjectIds,
          forCsv: true,
          includeHistory: false,
          search,
          nominationTypes,
          programTypes,
          questionsAnswered,
        },
        ['data', 'company', 'paginatedLearnerInterests', 'edges'],
        [
          {
            title: 'Interest submission ID',
            lookup: ['node', 'id'],
          },
          ...contentInfo,
          {
            title: 'First Name',
            lookup: ['node', 'learner', 'user', 'firstName'],
          },
          {
            title: 'Last Name',
            lookup: ['node', 'learner', 'user', 'lastName'],
          },
          { title: 'Email', lookup: ['node', 'learner', 'user', 'email'] },
          { title: 'Create date', lookup: ['node', 'createDate'] },
          { title: 'Update date', lookup: ['node', 'updateDate'] },
          { title: 'Delete date', lookup: ['node', 'deleteDate'] },
          { title: 'Status', lookup: ['node', 'status'] },
          { title: 'Assessment Score', lookup: ['node', 'assessmentScore'] },
          ...assessmentSubjectColumnQueries,
          ...assessmentOutcomeColumnQueries,
          ...companyQuestionSetColumnQueries,
          ...specificQuestionSetColumnQueries,
          ...nominationsCol,
        ]
      )
      .then((res: { data: string }) => {
        const date = dateFormatter.numeric(new Date());
        downloadCsvString(
          res.data,
          `${companySlug}-learner-interests-${date}.csv`
        );
        dispatch(stopLoading('LEARNER_INTEREST_CSV_DOWNLOAD'));
      })
      .catch((err) => {
        dispatch(addFlashMessage('error', err.message));
        dispatch(stopLoading('LEARNER_INTEREST_CSV_DOWNLOAD'));
      });
  };
}

// util until generateLearnerInterestCsv is ready for full rollout (INST-237)
//   lp or program: only include the appropriate columns
//   both or neither: include all columns
function makeColumnsForInterestType(
  programType: string,
  shouldIncludeLpAndProgramInterests: boolean,
  lpColumns: CsvColumnQuery[],
  programColumns: CsvColumnQuery[]
): CsvColumnQuery[] {
  if (shouldIncludeLpAndProgramInterests) {
    return [...lpColumns, ...programColumns];
  }
  if (programType === LearnerInterestProgramType.LearningPath) {
    return lpColumns;
  }
  return programColumns;
}

export function processLearnerInterests(
  data: PublicMutationProcessLearnerInterestsArgs
) {
  return emcService
    .gql(
      getQueryString(loader('@emc/queries/emc/process-learner-interests.gql')),
      data
    )
    .then((res: IGqlResponse<ProcessLearnerInterestsMutation>) => {
      const success = res.data.data.processLearnerInterests?.ok;
      const error = getGraphQLErrorMessage(res.data.errors);
      return { success, error };
    });
}
