import { addCatalogTokens, addCompanies } from 'app/actions/companies';

import { CompaniesReduxState } from './types';
import { addCompanyAssessment } from 'app/actions/assessments/assessments';
import { mergeDeepRight } from 'ramda';

export default function companies(
  state: CompaniesReduxState = {},
  action:
    | ReturnType<typeof addCompanies>
    | ReturnType<typeof addCompanyAssessment>
    | ReturnType<typeof addCatalogTokens>
): CompaniesReduxState {
  switch (action.type) {
    case 'ADD_COMPANIES': {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return mergeDeepRight(state, action.payload as any);
    }

    case 'ADD_COMPANY_ASSESSMENT': {
      const { payload } = action;

      const oldAssessments = state[payload.companyId]?.assessments?.edges || [];
      const newState = {
        [payload.companyId]: {
          assessments: { edges: oldAssessments.concat({ node: payload.id }) },
        },
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return mergeDeepRight(state, newState as any);
    }

    case 'ADD_COMPANY_CATALOG_TOKENS': {
      const { payload } = action;
      const { companyId, tokens } = payload;
      const catalogTokens = tokens;
      const newTokens = {
        [companyId]: {
          catalogTokens,
        },
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return mergeDeepRight(state, newTokens as any);
    }

    default: {
      return state;
    }
  }
}
