import { BatchProgressKeys, PollingKeys } from 'app/actions/polling';
import {
  CompaniesReduxState,
  CompanyTypesReduxState,
} from 'app/reducer/data/types';
import { CompanyType, Status } from 'app/types/generated/emc';
import { allPass, pluck, propOr, toLower, values } from 'ramda';
import {
  getCompanyIdFromAdminPathname,
  getCompanySlugFromPathname,
} from 'app/utils/router/paths';

import { ALL } from 'app/utils/constants';
import { ActiveCompanyState } from '@emc/reducer/session/active-company';
import { BatchProgressState } from 'app/reducer/session/batch-progress';
import { IPollingState } from '@emc/reducer/session/polling';
import { IRootStore } from '@emc/reducer/types';
import { LoadingState } from 'app/reducer/session/loading';
import { NormalizedCompany } from 'app/types/companies';
import { PollingProgress } from 'app/types/shared';
import { createSelector } from 'reselect';
import { dateFormatter } from 'app/utils/dates/format';
import includes from 'lodash/includes';
import isString from 'lodash/isString';
import { loadingSelector } from '../session';

export const companiesSelector = (state: IRootStore): CompaniesReduxState =>
  state.data.companies;
export const pollingSelector = (state: IRootStore): IPollingState =>
  state.session.polling;
export const batchProgressSelector = (state: IRootStore): BatchProgressState =>
  state.session.batchProgress;
export const companyTypesSelector = (
  state: IRootStore
): CompanyTypesReduxState => state.data.companyTypes;

export const activeCompaniesSelector = (
  state: IRootStore
): ActiveCompanyState => state.session.activeCompany;

export const currentCompanySelector = createSelector(
  [
    (state: IRootStore) => activeCompaniesSelector(state),
    (state: IRootStore) => companiesSelector(state),
  ],
  (activeCompanies, companies) => {
    const pathname = window?.location?.pathname;
    const slug = getCompanySlugFromPathname(pathname);
    const activeCompany = activeCompanies[slug] || {};
    const companyId =
      getCompanyIdFromAdminPathname(pathname) || activeCompany?.id;

    return companies[companyId] || { id: companyId, slug };
  }
);

export const currentCompanyIdSelector = (state: IRootStore): string => {
  const company = currentCompanySelector(state);
  return company?.id;
};

export const currentCompanyTypeSelector = createSelector<
  [
    (state: IRootStore) => NormalizedCompany,
    (state: IRootStore) => CompanyTypesReduxState
  ],
  CompanyType
>(
  currentCompanySelector,
  companyTypesSelector,
  (company, companyTypes) =>
    companyTypes.find((type) => type.id === company?.companyTypeId) ||
    ({} as CompanyType)
);

export const isLoadingCompanySelector = createSelector<
  [(state: IRootStore) => LoadingState],
  boolean
>(loadingSelector, propOr(false, 'COMPANY'));

export const companyPollingSelector = createSelector<
  [(state: IRootStore) => IPollingState, (state: IRootStore) => string],
  { [key in PollingKeys]?: boolean } & { [key in BatchProgressKeys]?: boolean }
>(
  pollingSelector,
  currentCompanyIdSelector,
  (pollingData, companyId) => pollingData[companyId] || {}
);

export const companyBatchProgressSelector = createSelector<
  [(state: IRootStore) => BatchProgressState, (state: IRootStore) => string],
  { [key in BatchProgressKeys]?: PollingProgress }
>(
  batchProgressSelector,
  currentCompanyIdSelector,
  (batchProgress, companyId) => batchProgress[companyId] || {}
);

export const filteredCompaniesSelector = createSelector<
  [
    (
      state: IRootStore,
      queryParams: Record<string, string>
    ) => Record<string, string>,
    (state: IRootStore) => CompaniesReduxState,
    (state: IRootStore) => CompanyType[]
  ],
  (NormalizedCompany & { type: string })[]
>(
  (x, queryParams) => queryParams,
  companiesSelector,
  companyTypesSelector,
  (queryParams, companies, companyTypes) => {
    const companySearch = queryParams.companySearch || '';
    const companyStatus = queryParams.status || Status.Approved;
    const country = queryParams.country || ALL;
    const type = queryParams.type || ALL;

    function matchesStatus(company: NormalizedCompany) {
      return (
        companyStatus === ALL || company.status?.toUpperCase() === companyStatus
      );
    }

    function matchesCountry(company: NormalizedCompany) {
      return country === ALL || company.country === country;
    }

    function matchesType(company: NormalizedCompany) {
      return type === ALL || company.companyTypeId === type;
    }

    function matchesSearch(company: NormalizedCompany) {
      const stringVals = values(company).filter(isString);
      return stringVals.some((property = '') =>
        includes(toLower(property), toLower(companySearch))
      );
    }
    const companiesMatchingFilter = values(companies).filter(
      allPass([matchesCountry, matchesType, matchesStatus, matchesSearch])
    );

    return companiesMatchingFilter.map((company) => ({
      ...company,
      updateDate: dateFormatter.shortUTC(company.updateDate),
      createDate: dateFormatter.shortUTC(company.createDate),
      type:
        companyTypes.find((type) => type.id === company.companyTypeId)?.name ||
        '-',
    }));
  }
);

export const companyCountriesFilterSelector = createSelector(
  companiesSelector,
  (companies) => {
    const countries = new Set(pluck('country', values(companies)));

    const countryOptions = Array.from(countries).map((c) => ({
      id: c || 'country',
      label: c || 'null',
      value: c || 'null',
    }));

    return {
      id: 'country',
      label: 'Country',
      options: [
        ...countryOptions,
        {
          id: `countries-all`,
          label: 'All',
          value: ALL,
        },
      ],
    };
  }
);

export const companyTypeFilterSelector = createSelector(
  companyTypesSelector,
  (companyTypes) => {
    const typeOptions = companyTypes.map((type) => ({
      id: type.id,
      label: type.name,
      value: type.id,
    }));

    return {
      id: 'type',
      label: 'Business Unit',
      options: [
        ...typeOptions,
        {
          id: `types-all`,
          label: 'All',
          value: ALL,
        },
      ],
    };
  }
);
